import {
  OBJECT_MODERATION_AI_REVIEW,
  OBJECT_MODERATION_REVIEW_STATUS,
  ObjectVisibleStatus,
} from 'src/lib/types/objectModeration';

export const reviewStatusLabel: Record<
  OBJECT_MODERATION_REVIEW_STATUS,
  string
> = {
  [OBJECT_MODERATION_AI_REVIEW.AI_APPROVED]: 'AI Approved',
  [OBJECT_MODERATION_AI_REVIEW.AI_REJECTED]: 'AI Rejected',
  [OBJECT_MODERATION_REVIEW_STATUS.UNREVIEWED]: 'Unreviewed',
  [OBJECT_MODERATION_AI_REVIEW.AI_INCONCLUSIVE]: 'AI Inconclusive',
  [OBJECT_MODERATION_REVIEW_STATUS.FULL_APPROVAL]: 'Full Approval',
  [OBJECT_MODERATION_REVIEW_STATUS.LIMITED_APPROVAL]: 'Limited Approval',
  [OBJECT_MODERATION_REVIEW_STATUS.REJECTION]: 'Rejection',
  [OBJECT_MODERATION_REVIEW_STATUS.PRIVATE]: 'Private',
};

export const visibleStatusLabel: Record<ObjectVisibleStatus, string> = {
  [ObjectVisibleStatus.IN_REVIEW]: 'In Review',
  [ObjectVisibleStatus.PRIVATE]: 'Private',
  [ObjectVisibleStatus.PUBLIC]: 'Public',
  [ObjectVisibleStatus.NEEDS_REVISION]: 'Everywhere',
};
