import { useNavigate, useParams } from 'react-router';
import Routes from 'src/routes/Routes';
import useTabs from 'src/template/hooks/useTabs';
import { fetchChannelDetails } from '../../services/fetchChannelDetails';
import { ChannelTabs } from './channelTabs';

const DEFAULT_TAB = ChannelTabs.MAIN;

export default function useChannelDetailsView() {
  const { channelUuid } = useParams<'channelUuid'>();
  const navigate = useNavigate();
  if (!channelUuid) navigate(Routes.channel.channels);

  const { currentTab, onChangeTab } = useTabs(DEFAULT_TAB, {
    resetOnChange: true,
  });

  const { data: channelDetails } = fetchChannelDetails(channelUuid!);

  return {
    channelDetails,
    currentTab,
    onChangeTab,
  };
}
