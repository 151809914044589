import {
  Box,
  Button,
  Card,
  IconButton,
  InputAdornment,
  Link,
  MenuItem,
  Stack,
  Tab,
  Tabs,
  TextField,
} from '@mui/material';
import { useState } from 'react';
import Flex from 'src/components/shared/Flex';
import DebouncedField from 'src/components/shared/Inputs/DebouncedField';
import FileDisplay from 'src/components/shared/Media/FileDisplay';
import SectionDivider from 'src/components/shared/SectionDivider';
import useDebounce from 'src/lib/hooks/useDebounce';
import { fetchNftOwnershipVerification } from 'src/lib/services/nft';
import { NFT } from 'src/lib/types/nft';
import HeaderBreadcrumbs from 'src/template/components/HeaderBreadcrumbs';
import Iconify from 'src/template/components/Iconify';
import Logo from 'src/template/components/Logo';
import Scrollbar from 'src/template/components/Scrollbar';
import useSettings from 'src/template/hooks/useSettings';
import useTabs from 'src/template/hooks/useTabs';
import AllStickyNftsPreloaded from './AllStickyNftsPreloaded';
import AllStickyNftsView from './AllStickyNftsView';
import NftsTable from './NftsTable/NftsTable';
import useStickyNftsTable, {
  SearchForOptions,
} from './NftsTable/useStickyNftsTable';
import './style.css';

const SEARCH_TYPE_TABS = [
  { value: 'SingleNft', label: 'Single NFT' },
  { value: 'AllStickyNftsRealTime', label: 'All Sticky NFTs (Real-Time)' },
  {
    value: 'AllStickyNftsPreloaded',
    label: '[Experimental]: All Sticky NFTs (Preloaded)',
  },
  // { value: 'AllStickyNftsPreloadedBackup', label: '(Backup) All Sticky NFTs (Preloaded)' },
];

const SEARCH_OPTIONS = [
  {
    label: 'Nft uuid',
    value: 'uuid',
  },
];

const blockchains = {
  POLYGON: 'polygon',
  ETHEREUM: 'ethereum',
  STICKY: 'sticky',
};
const contractTypes = {
  ERC721: 'ERC721',
  ERC1155: 'ERC1155',
};

const isTestNetEnv = false; // process.env.CRYPTO_TESTNET;

function getTokenScan(nft: NFT) {
  if (
    !nft?.blockchain ||
    !nft?.mintToken ||
    !nft?.mintTokenId ||
    nft?.blockchain === blockchains.STICKY
  )
    return undefined;
  if (nft?.blockchain === blockchains.POLYGON)
    return `https://${isTestNetEnv ? 'mumbai.' : ''}polygonscan.com/address/${
      nft?.mintToken
    }`;
  if (nft?.blockchain === blockchains.ETHEREUM)
    return `https://${isTestNetEnv ? 'goerli.' : ''}etherscan.io/address/${
      nft?.mintToken
    }`;
  return undefined;
}

function getNftScan(nft: NFT) {
  if (
    !nft?.blockchain ||
    !nft?.mintToken ||
    !nft?.mintTokenId ||
    nft?.blockchain === blockchains.STICKY
  )
    return undefined;
  if (nft?.blockchain === blockchains.POLYGON)
    return `https://${isTestNetEnv ? 'mumbai.' : ''}polygonscan.com/nft/${
      nft?.mintToken
    }/${nft?.mintTokenId}`;
  if (nft?.blockchain === blockchains.ETHEREUM)
    return `https://${isTestNetEnv ? 'goerli.' : ''}eth.nftscan.com/${
      nft?.mintToken
    }/${nft?.mintTokenId}`;
  return undefined;
}

function getOpenSea(nft: NFT) {
  let openSeaBlockchain;

  if (nft?.blockchain === blockchains.POLYGON) openSeaBlockchain = 'matic';
  if (nft?.blockchain === blockchains.ETHEREUM) openSeaBlockchain = 'ethereum';
  return {
    link: `https://opensea.io/assets/${openSeaBlockchain}/${nft?.mintToken}/${nft?.mintTokenId}`,
    icon: 'simple-icons:opensea',
  };
}

function getBlockchainIcon(nft: NFT) {
  if (nft?.blockchain === blockchains.ETHEREUM)
    return {
      icon: 'ph:currency-eth',
      color: '#627feb',
    };
  if (nft?.blockchain === blockchains.POLYGON)
    return {
      icon: 'cryptocurrency:matic',
      color: '#8347e6',
    };
  return {
    icon: '',
    color: '#000000',
  };
}

const pageColors = {
  light: {
    greenWarning: `#bbffbb`,
    redWarning: `#ffbbbb`,
  },
  dark: {
    greenWarning: `#175d01`,
    redWarning: `#5c0101`,
  },
};

export default function NftOwnership() {
  const themeSettings = useSettings();

  const [isExpanded, setIsExpanded] = useState(false);

  const { currentTab: reviewStatus, onChangeTab: onChangeReviewStatus } =
    useTabs('AllStickyNftsPreloaded');

  const { orderBy, order, page, query, size } = useStickyNftsTable(
    (state) => state.filter
  );
  const searchFor = 'uuid';

  const [setQuery, setSearchFor] = useStickyNftsTable((state) => [
    state.setQuery,
    state.setSearchFor,
  ]);

  const filter: any = {};
  const filterQuery = useDebounce(query, 500);
  if (searchFor && filterQuery !== '') {
    filter[searchFor] = filterQuery;
  }
  const {
    data: nftOwnershipVerification,
    loading,
    error,
  } = fetchNftOwnershipVerification({
    filter,
    page,
    size,
  });

  const nft = nftOwnershipVerification?.nft;
  const balanceVerification = nftOwnershipVerification?.balanceVerification;
  const stickyDbOwnership = nftOwnershipVerification?.stickyDbOwnership;

  /* Mocked blockchain verification to test locally without running the BlockchainService */

  // const balanceVerification = {
  //   "stickyBalance": 9,
  //   "blockchainBalance": 10,
  //   "blockchainMetadata": "",
  //   // "blockchainOwner": "ERC1155 does not support Owner verification",
  //   "blockchainOwner": '0xa26777f009364d62aE47D79029b26233860375Dd',
  //   "isStickyBalanceDifferentFromBlockchain": false,
  //   "hasUpdatedStickyOwership": false
  // }
  // if (nftOwnershipVerification)
  //   nftOwnershipVerification.balanceVerification = balanceVerification;

  /* end of Mocked blockchain verification */

  return (
    <>
      <HeaderBreadcrumbs
        heading="Nft Ownership"
        links={[{ name: 'Nft Ownership' }]}
      />
      <Tabs
        allowScrollButtonsMobile
        variant="scrollable"
        scrollButtons="auto"
        value={reviewStatus}
        onChange={onChangeReviewStatus}
        sx={{ px: 2, bgcolor: 'background.neutral' }}
      >
        {SEARCH_TYPE_TABS.map((tab) => (
          <Tab
            disableRipple
            key={tab.value}
            label={tab.label}
            value={tab.value}
          />
        ))}
      </Tabs>
      {reviewStatus == 'SingleNft' && (
        <Card>
          <Stack
            spacing={2}
            direction={{ xs: 'column', sm: 'row' }}
            sx={{ py: 2.5, px: 3 }}
          >
            <TextField
              fullWidth
              select
              label="Search for"
              value={searchFor}
              onChange={(e) => setSearchFor(e.target.value as SearchForOptions)}
              SelectProps={{
                MenuProps: {
                  sx: { '& .MuiPaper-root': { maxHeight: 260 } },
                },
              }}
              sx={{
                maxWidth: { sm: 240 },
                textTransform: 'capitalize',
              }}
            >
              {SEARCH_OPTIONS.map((option) => (
                <MenuItem
                  key={option.value}
                  value={option.value}
                  sx={{
                    mx: 1,
                    my: 0.5,
                    borderRadius: 0.75,
                    typography: 'body2',
                    textTransform: 'capitalize',
                  }}
                >
                  {option.label}
                </MenuItem>
              ))}
            </TextField>

            <DebouncedField
              fullWidth
              onDebounceChange={(e) => setQuery(e)}
              placeholder="Search Nft (Verify ownership)..."
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <Iconify
                      icon="eva:search-fill"
                      sx={{ color: 'text.disabled', width: 20, height: 20 }}
                    />
                  </InputAdornment>
                ),
              }}
            />
          </Stack>

          <Card
            sx={{
              textAlign: 'center',
              alignItems: 'center',
              alignContent: 'center',
              justifyContent: 'center',
              margin: '0 auto',
            }}
          >
            {nft && (
              <Card>
                <Stack
                  sx={{
                    textTransform: 'capitalize',
                    alignItems: 'right',
                    justifyContent: 'right',
                  }}
                >
                  Blockchain: {nft?.blockchain}
                </Stack>
                {nft?.blockchain != 'sticky' && (
                  <Stack>Contract type: {nft?.mintContract}</Stack>
                )}
              </Card>
            )}

            {nft && (
              <>
                <Stack>
                  <Link
                    href={getTokenScan(nft)}
                    color={getBlockchainIcon(nft).color}
                  >
                    Token Scan
                    <Iconify
                      icon={getBlockchainIcon(nft).icon}
                      sx={{ fontSize: '1.5rem' }}
                    />
                  </Link>
                </Stack>
                <Stack>
                  <Link
                    href={getNftScan(nft)}
                    color={getBlockchainIcon(nft).color}
                  >
                    NFT Scan
                    <Iconify
                      icon={getBlockchainIcon(nft).icon}
                      sx={{ fontSize: '1.5rem' }}
                    />
                  </Link>
                </Stack>
                <Stack>
                  <Link
                    href={getOpenSea(nft)?.link}
                    // color='#000000' // {getBlockchainIcon(nft).color}
                  >
                    OpenSea
                    <Iconify
                      icon={getOpenSea(nft)?.icon}
                      sx={{ fontSize: '1.5rem' }} // , color: '#000000' }}
                      className="pulse-icon-discretely"
                    />
                  </Link>
                </Stack>
                <Stack>{`Contract Address: ${nft?.mintToken}`}</Stack>
              </>
            )}

            {nft?.mintContract === contractTypes.ERC721 &&
              nftOwnershipVerification?.balanceVerification &&
              (balanceVerification?.isStickyBalanceDifferentFromBlockchain ? (
                <Card
                  sx={{
                    backgroundColor:
                      pageColors[themeSettings.themeMode].redWarning,
                  }}
                >
                  {/* <Stack>{`Contract Address: ${nft?.mintToken}`}</Stack> */}
                  <Stack>{`Owner's address according to ${nft?.blockchain}: ${balanceVerification?.blockchainOwner}`}</Stack>
                  <Stack>{`Does not match Sticky's address ( 0xa26777f009364d62aE47D79029b26233860375Dd )`}</Stack>
                </Card>
              ) : (
                <Card
                  sx={{
                    backgroundColor:
                      pageColors[themeSettings.themeMode].greenWarning,
                  }}
                >
                  <Stack>{`Owner's address according to ${nft?.blockchain}: ${balanceVerification?.blockchainOwner}`}</Stack>
                  <Stack>{`Matches Sticky's address`}</Stack>
                </Card>
              ))}

            {nft?.mintContract === contractTypes.ERC1155 &&
              nftOwnershipVerification?.balanceVerification && (
                // balanceVerification && (
                <Card
                  sx={{
                    backgroundColor:
                      balanceVerification?.blockchainBalance ==
                      stickyDbOwnership
                        ? pageColors[themeSettings.themeMode].greenWarning
                        : pageColors[themeSettings.themeMode].redWarning,
                  }}
                >
                  <Stack>{`Editions owned by Sticky:`}</Stack>
                  {/* <Stack>{`* According to sticky: ${stickyDbOwnership}`}</Stack> */}
                  <Flex
                    sx={{
                      textAlign: 'center',
                      alignItems: 'center',
                      alignContent: 'center',
                      justifyContent: 'center',
                      margin: '0 auto',
                    }}
                  >
                    {/* href="%PUBLIC_URL%/favicon/sticky-admin-icon.png" */}
                    <Logo disabledLink sx={{ height: '1.8rem' }} />
                    <Iconify
                      icon={'%PUBLIC_URL%/favicon/sticky-admin-icon.png'}
                      sx={{ fontSize: '1.0rem' }}
                    />
                    {`: According to sticky's DB: ${stickyDbOwnership}`}
                  </Flex>
                  <Flex
                    sx={{
                      textAlign: 'center',
                      alignItems: 'center',
                      alignContent: 'center',
                      justifyContent: 'center',
                      margin: '0 auto',
                    }}
                  >
                    <Iconify
                      icon={getBlockchainIcon(nft).icon}
                      sx={{
                        fontSize: '1.5rem',
                        color: getBlockchainIcon(nft).color,
                      }}
                    />
                    {`: According to ${nft?.blockchain}'s blockchain: ${balanceVerification?.blockchainBalance}`}
                  </Flex>
                </Card>
                // :
                // <Card sx={{ backgroundColor:`#bbffbb`}}>
                //   <Stack>{`Owner's address according to ${nft?.blockchain}: ${balanceVerification?.blockchainOwner}`}</Stack>
                //   <Stack sx={{ color:`#006600`}}>{`Matches Sticky's address`}</Stack>
                // </Card>
              )}
          </Card>

          {nft && (
            <>
              <SectionDivider section={'NFT INFO:'} />
              <Scrollbar>
                <NftsTable data={nft ? [nft] : undefined} loading={loading} />
              </Scrollbar>
            </>
          )}

          {/* <NftsTableFooter total={total || 0} page={page} rowsPerPage={size} /> */}

          {nft && (
            <Card
              sx={{
                textAlign: 'center',
                alignItems: 'center',
                alignContent: 'center',
                justifyContent: 'center',
                margin: '0 auto',
              }}
            >
              <Button
                onClick={() => setIsExpanded(!isExpanded)}
                sx={{
                  textAlign: 'center',
                  alignItems: 'center',
                  alignContent: 'center',
                  justifyContent: 'center',
                  margin: '0 auto',
                }}
              >
                {isExpanded ? 'Hide NFT' : 'Preview NFT'}
                {/* <i className={`fas fa-chevron-${isExpanded ? 'up' : 'down'}`} /> */}
              </Button>
              {
                <IconButton onClick={() => setIsExpanded(!isExpanded)}>
                  {
                    isExpanded ? (
                      <Iconify
                        icon="grommet-icons:up"
                        sx={{ fontSize: '1.0rem' }}
                      />
                    ) : (
                      // <Iconify icon="grommet-icons:up" sx={{ fontSize: '0.7rem' }} />
                      // <Iconify icon="mingcute:up-fill" sx={{ fontSize: '0.9rem' }} />
                      <Iconify
                        icon="grommet-icons:down"
                        sx={{ fontSize: '1.0rem' }}
                      />
                    )
                    // <Iconify icon="grommet-icons:down" sx={{ fontSize: '0.7rem' }} />
                    // <Iconify icon="mingcute:down-fill" sx={{ fontSize: '0.9rem' }} />
                  }
                </IconButton>
              }
            </Card>
          )}
          {nft && isExpanded && (
            <>
              <SectionDivider section={''} />
              {/* <Grid item xs={12} md={7}> */}
              <Box
                sx={{
                  alignItems: 'center',
                  alignContent: 'center',
                  justifyContent: 'center',
                  margin: '0 auto',
                  marginBottom: 3.5,
                }}
              >
                <Card
                  sx={{
                    alignItems: 'center',
                    alignContent: 'center',
                    justifyContent: 'center',
                    margin: '0 auto',
                  }}
                >
                  <FileDisplay
                    fileUrl={nft?.fileUrl}
                    width="50%"
                    height="auto"
                    alignContent={'center'}
                    justifyContent={'center'}
                    margin={'0 auto'}
                  />
                </Card>
              </Box>
              {/* </Grid> */}
            </>
          )}
        </Card>
      )}
      {/* {reviewStatus == 'AllStickyNfts' && <AllStickyNftsView />} */}
      {reviewStatus == 'AllStickyNftsRealTime' && <AllStickyNftsView />}
      {reviewStatus == 'AllStickyNftsPreloaded' && <AllStickyNftsPreloaded />}
      {/* {reviewStatus == 'AllStickyNftsPreloadedBackup' && <AllStickyNftsPreloadedBackup />} */}
    </>
  );
}
