import HeaderBreadcrumbs from 'src/template/components/HeaderBreadcrumbs';
import UserSearchesTable from '../../components/SearchesList/UserSearchesTable';
import { useUserSearchesTable } from '../../components/SearchesList/useListSearchesTable';

type UserSearchListProps = {
  searchTerm?: string;
};

export default function UserSearchList({ searchTerm }: UserSearchListProps) {
  const { data, isLoading, params } = useUserSearchesTable();

  return (
    <>
      <HeaderBreadcrumbs
        heading="User Searches"
        links={[{ name: 'User Searches' }]}
      />

      <UserSearchesTable
        data={data?.body || []}
        isLoading={isLoading}
        params={params}
        totalCount={data?.meta.totalCount || 0}
        searchTerm={searchTerm}
      />
    </>
  );
}
