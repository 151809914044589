import { DEFAULT_INITIAL_PAGE_SIZE } from 'src/lib/constants/pagination';
import useURLSearchParams from 'src/lib/hooks/useURLSearchParams';
import { IncludeExcludeAll } from 'src/lib/utils/includeExcludeFilterUtils';
import { fetchDevices } from '../../../../Devices/services/fetchDevices';

export default function useDevicesView() {
  const { allSearchParams } = useURLSearchParams({
    page: '0',
    size: String(DEFAULT_INITIAL_PAGE_SIZE),
    AnonymousUsers: IncludeExcludeAll.Exclude,
  });

  const { data, isLoading } = fetchDevices({
    page: allSearchParams.page,
    size: allSearchParams.size,
    filter: {
      AnonymousUsers: allSearchParams.AnonymousUsers as IncludeExcludeAll,
    },
  });

  return { data, isLoading };
}
