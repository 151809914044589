// @mui
import { ButtonBase, IconButton } from '@mui/material';
import Iconify from '../Iconify';
import MenuPopover from '../MenuPopover';
//

// ----------------------------------------------------------------------

type Props = {
  actions: React.ReactNode;
  open?: HTMLElement | null;
  onClose?: (event: React.MouseEvent<HTMLElement>) => void;
  onOpen?: (event: React.MouseEvent<HTMLElement>) => void;
  renderOpenButton?: React.ReactNode;
};

export default function TableMoreMenu({
  actions,
  open,
  onClose,
  onOpen,
  renderOpenButton,
}: Props) {
  return (
    <>
      {renderOpenButton ? (
        <ButtonBase onClick={onOpen}> {renderOpenButton} </ButtonBase>
      ) : (
        <IconButton onClick={onOpen}>
          <Iconify icon="eva:more-vertical-fill" width={20} height={20} />
        </IconButton>
      )}

      <MenuPopover
        open={Boolean(open)}
        anchorEl={open}
        onClose={onClose}
        anchorOrigin={{ vertical: 'top', horizontal: 'left' }}
        transformOrigin={{ vertical: 'top', horizontal: 'right' }}
        arrow="right-top"
        sx={{
          mt: -1,
          width: 'max-content',
          '& .MuiMenuItem-root': {
            px: 1,
            typography: 'body2',
            borderRadius: 0.75,
            '& svg': { mr: 2, width: 20, height: 20 },
          },
        }}
      >
        {actions}
      </MenuPopover>
    </>
  );
}
