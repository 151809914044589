import { Check, ContentCopy, Delete, Edit } from '@mui/icons-material';
import {
  Autocomplete,
  Box,
  InputAdornment,
  MenuItem,
  TextField,
} from '@mui/material';
import { GridSortDirection } from '@mui/x-data-grid';
import { capitalCase } from 'change-case';
import { debounce } from 'lodash';
import CopyToClipboard from 'react-copy-to-clipboard';
import { reviewStatusColumns } from 'src/components/modules/Review/reviewStatusColumn';
import Flex from 'src/components/shared/Flex';
import Link from 'src/components/shared/Link';
import MagicTable from 'src/components/shared/MagicTable/MagicTable';
import MagicTableUserCell from 'src/components/shared/MagicTable/UserCell';
import MoreMenu from 'src/components/shared/MoreMenu';
import useURLSearchParams from 'src/lib/hooks/useURLSearchParams';
import { ChannelWithAdminAnalytics } from 'src/lib/types/channelWithAdminAnalytics';
import { formatMilliseconds } from 'src/lib/utils/formatMilliseconds';
import { formatUTCDate } from 'src/lib/utils/formatUTCDate';
import Routes from 'src/routes/Routes';
import Iconify from 'src/template/components/Iconify';
import { ListChannelsParams } from '../../view/List/useView';
import useChannelTable from './useView';

interface ChannelsTableProps {
  data: ChannelWithAdminAnalytics[];
  isLoading: boolean;
  params: ListChannelsParams;
  handleOpenUpdateModal?: (channel: ChannelWithAdminAnalytics) => void;
  handleOpenDeleteModal?: (uuid: string) => void;
}

const searchOptions = [
  {
    label: 'Uuid',
    query: 'uuid',
  },
  {
    label: 'Name',
    query: 'name',
  },
  {
    label: 'Owner',
    query: 'owner',
  },
];

export default function ChannelsTable({
  data,
  isLoading,
  params,
  handleOpenUpdateModal,
  handleOpenDeleteModal,
}: ChannelsTableProps) {
  const { addParam, removeParam } = useURLSearchParams();
  const { handleChangeChannelIsStickyModal } = useChannelTable();

  function handleChangeSearch(value: string, param: string = 'query') {
    if (!value) removeParam(param);
    else addParam(param, value);
  }

  const debouncedSearch = debounce(handleChangeSearch, 300);

  return (
    <MagicTable.Container>
      <Flex gap={2} p={2}>
        <Autocomplete
          options={searchOptions}
          fullWidth
          renderInput={(_params) => (
            <TextField label="Search By" {..._params} />
          )}
          getOptionLabel={(p) => p.label}
          sx={{ maxWidth: 250 }}
          defaultValue={searchOptions.find((o) => o.query === params.searchBy)}
          key={params.searchBy}
          onChange={(_, value) => {
            if (!value) removeParam('searchBy');
            else addParam('searchBy', value.query);
          }}
        />

        <TextField
          fullWidth
          label="Search"
          defaultValue={params.query}
          type="search"
          onChange={(e) => {
            const { value } = e.target;
            debouncedSearch(value);
          }}
          key={params.query}
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <Iconify
                  icon="eva:search-fill"
                  sx={{ color: 'text.disabled', width: 20, height: 20 }}
                />
              </InputAdornment>
            ),
          }}
        />
      </Flex>

      <Box px={2}>
        <MagicTable
          rowHeight={64}
          rows={data}
          getRowId={(r) => r.uuid}
          loading={isLoading}
          paginationMode="server"
          pageSize={params.size}
          page={params.page}
          onPageChange={(newPage) => {
            addParam('page', newPage);
          }}
          onPageSizeChange={(newSize) => {
            addParam('size', newSize);
          }}
          sortModel={[
            { field: params.sortBy, sort: params.order as GridSortDirection },
          ]}
          onSortModelChange={(model) => {
            const currentModel = model[0];
            if (currentModel) {
              addParam('sortBy', currentModel.field);
              if (currentModel.sort) addParam('order', currentModel.sort);
            } else {
              removeParam('order');
              removeParam('sortBy');
            }
          }}
          columns={[
            {
              field: 'name',
              headerName: 'Name',
              sortable: true,
              sortingOrder: ['asc', 'desc'],
              renderCell({ row }) {
                return (
                  <Link to={Routes.channel.channel(row.uuid)}>{row.name}</Link>
                );
              },
            },
            {
              field: 'owner',
              headerName: 'Owner',
              sortable: false,
              renderCell({ row }) {
                const hasOwner = !!row.owner;
                return hasOwner ? (
                  <MagicTableUserCell user={row.owner} />
                ) : (
                  '--'
                );
              },
            },
            {
              field: 'type',
              headerName: 'Type',
              sortable: true,
              valueGetter: (_params) => capitalCase(_params.value),
            },
            ...reviewStatusColumns,
            {
              field: 'followers',
              headerName: 'Followers',
              sortable: true,
            },
            {
              field: 'views',
              headerName: 'Views',
              sortable: true,
            },
            {
              field: 'secondsWatched',
              headerName: 'Time Watched',
              sortable: true,
              valueGetter: (_params) =>
                formatMilliseconds(_params.value * 1000, 'minutes'),
            },
            {
              field: 'createdAt',
              headerName: 'Created At',
              sortable: true,
              valueGetter: (_params) => formatUTCDate(_params.value),
            },
            {
              field: 'updatedAt',
              headerName: 'Updated At',
              sortable: true,
              valueGetter: (_params) => formatUTCDate(_params.value),
            },
            /*
            {
              field: 'deletedAt',
              headerName: 'Deleted At',
              sortable: true,
              valueGetter: (params) => params.value ? formatUTCDate(params.value) : '--',
            },
            */
            {
              field: 'actions',
              headerName: 'Actions',
              align: 'center',
              sortable: false,
              flex: 0,
              renderCell({ row }) {
                return (
                  <MoreMenu>
                    <CopyToClipboard text={row.uuid}>
                      <MenuItem aria-label="copy feed uuid">
                        <ContentCopy />
                        Copy UUID
                      </MenuItem>
                    </CopyToClipboard>
                    <MenuItem
                      onClick={() => {
                        handleChangeChannelIsStickyModal(
                          row.uuid,
                          !row.isSticky
                        );
                      }}
                    >
                      <Check />
                      {`${row.isSticky ? 'Unmark' : 'Mark'} as by Sticky`}
                    </MenuItem>
                    {handleOpenUpdateModal && (
                      <MenuItem
                        onClick={() => {
                          handleOpenUpdateModal(row);
                        }}
                      >
                        <Edit />
                        Update
                      </MenuItem>
                    )}
                    {handleOpenDeleteModal && (
                      <MenuItem
                        onClick={() => {
                          handleOpenDeleteModal(row.uuid);
                        }}
                      >
                        <Delete />
                        Delete
                      </MenuItem>
                    )}
                  </MoreMenu>
                );
              },
            },
          ]}
        />
      </Box>
    </MagicTable.Container>
  );
}
