import { useState } from 'react';
import { SubmitHandler, useForm } from 'react-hook-form';
import useNotify from 'src/lib/hooks/useNotify';
import useModalStore from 'src/lib/stores/useModalStore';
import { GameTag } from 'src/lib/types/gameTag';
import { uploadToStorage } from 'src/lib/utils/upload';

import { GameTagEmitter } from '../../../events/Emitter';
import { CreateGameTag } from '../../../services/create';
import { UpdateGameTag } from '../../../services/update';

type GameTagForm = GameTag;

export function useCreateGameTagView(item?: GameTag) {
  const notify = useNotify();
  const form = useForm<GameTagForm>({
    defaultValues: item,
  });

  const [isSubmitting, setIsSubmitting] = useState<boolean>(false);

  const onSubmitForm: SubmitHandler<GameTagForm> = async (data) => {
    setIsSubmitting(true);

    try {
      if (item) {
        const { data: gameTag, error } = await UpdateGameTag(data);
        if (error) {
          notify(error, { variant: 'error' });
        } else {
          notify('Game GameTag updated!', { variant: 'success' });
          GameTagEmitter.emit('GameTagUpdated', gameTag?.uuid);
        }
      } else {
        const { data: gameTag, error } = await CreateGameTag(data);
        if (error) {
          notify(error, { variant: 'error' });
        } else {
          notify('Game GameTag created!', { variant: 'success' });
          GameTagEmitter.emit('GameTagCreated', gameTag?.uuid);
        }
      }
    } catch (error: any) {
      notify(error?.message || error?.description, { variant: 'error' });
    } finally {
      setIsSubmitting(false);
      handleCloseModal();
    }
  };

  const onClose = useModalStore((state) => state.closeModal);

  const handleCloseModal = () => {
    onClose();
  };

  const [isUpdatingContent, setIsUpdatingContent] = useState<boolean>(false);
  const onUpdateContent = async (file: any, fieldName: keyof GameTag) => {
    setIsUpdatingContent(true);

    try {
      const url = await uploadToStorage('game-tag/content', file);

      form.setValue(fieldName, url as string);
    } finally {
      setIsUpdatingContent(false);
    }
  };

  return {
    onSubmitForm,
    form,
    handleCloseModal,
    isSubmitting,
    isUpdatingContent,
    onUpdateContent,
  };
}
