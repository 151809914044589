import { DeepPartial } from 'react-hook-form';
import { getMessageFromError } from 'src/lib/utils/error';
import { parseSearchParams } from 'src/lib/utils/parseSearchParams';
import useSWR, { SWRResponse } from 'swr';
import { ConsumableReportResults } from '../types/Consumable';

type PossibleParams = {
  startDate: string;
  endDate: string;
  country: string;
};

export function fetchConsumablesReport(
  params?: DeepPartial<PossibleParams>
): SWRResponse<ConsumableReportResults> {
  const parsedParams = parseSearchParams(params ?? {});
  const { error, ...response } = useSWR(
    `admin/revenue-events/consumables-report?${parsedParams}`
  );

  return {
    ...response,
    error: error ? getMessageFromError(error) : undefined,
  };
}
