import { fetchBlockchainServiceHealth } from '../../../services/fetchBlockchainServiceHealth';
import { fetchEMSHealth } from '../../../services/fetchEMSHealth';
import { Status } from '../types';

const refreshInterval = 10000; // 10 secs

export function useBlockchainHealthCheckView(): UseHealthCheckView {
  const {
    isLoading: blockchainIsLoading,
    data: blockchainData,
    error: blockchainError,
  } = fetchBlockchainServiceHealth({
    refreshInterval,
  });
  const {
    isLoading: emsIsLoading,
    data: emsData,
    error: emsError,
  } = fetchEMSHealth({
    refreshInterval,
  });

  return {
    statuses: [
      {
        isLoading: blockchainIsLoading,
        name: 'Blockchain Polygon Service',
        error: blockchainError,
        workers: blockchainData
          ? blockchainData.polygon.map((data) => ({
              isWorking: data.isWorking,
              lastChecked: data.lastCheck,
              name: data.parsedName,
            }))
          : [],
      },
      {
        isLoading: blockchainIsLoading,
        name: 'Blockchain Ethereum Service',
        error: blockchainError,
        workers: blockchainData
          ? blockchainData.ethereum.map((data) => ({
              isWorking: data.isWorking,
              lastChecked: data.lastCheck,
              name: data.parsedName,
            }))
          : [],
      },
      {
        isLoading: emsIsLoading,
        name: 'External Market Service',
        error: emsError,

        workers: emsData
          ? emsData.map((data) => ({
              isWorking: data.isWorking,
              lastChecked: data.lastCheck,
              name: data.parsedName,
            }))
          : [],
      },
    ],
  };
}

type UseHealthCheckView = {
  statuses: Status[];
};
