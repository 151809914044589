import { useMemo } from 'react';
import { useSearchParams, type URLSearchParamsInit } from 'react-router-dom';

export default function useURLSearchParams<T extends string = string>(
  defaultValues?: URLSearchParamsInit
) {
  const [urlSearchParams, setUrlSearchParams] = useSearchParams(defaultValues);
  const allSearchParams: Record<T, string | undefined> = useMemo(
    () =>
      Array.from(urlSearchParams.keys()).reduce(
        (acc, val) => ({ ...acc, [val]: urlSearchParams.get(val) }),
        {} as Record<T, string>
      ),
    [urlSearchParams]
  );

  function handleAddParam(key: T, value: string | number | boolean): void {
    urlSearchParams.set(key, String(value));
    setUrlSearchParams(urlSearchParams);
  }

  function handleAddParams(params: Record<T, string | boolean>): void {
    Object.keys(params).forEach((key) => {
      urlSearchParams.set(key, String(params[key as T]));
    });
    setUrlSearchParams(urlSearchParams);
  }

  function handleRemoveParam(key: T): void {
    urlSearchParams.delete(key);
    setUrlSearchParams(urlSearchParams);
  }

  function handleRemoveParams(keys: T[]): void {
    keys.forEach((key) => {
      urlSearchParams.delete(key);
    });
    setUrlSearchParams(urlSearchParams);
  }

  function handleRemoveAllParams(): void {
    setUrlSearchParams(new URLSearchParams());
  }

  function getParam(key: T): string | null | undefined {
    return urlSearchParams.get(String(key));
  }

  return {
    addParam: handleAddParam,
    removeParam: handleRemoveParam,
    removeAllParams: handleRemoveAllParams,
    getParam,
    addParams: handleAddParams,
    removeParams: handleRemoveParams,
    allSearchParams,
  };
}
