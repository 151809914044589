import useNotify from 'src/lib/hooks/useNotify';
import useModalStore from 'src/lib/stores/useModalStore';
import { AppVideoFeedEmitter } from '../../events/AppVideoFeedEmitter';
import { DeleteAppVideoFeed } from '../../services/deleteAppVideoFeed';

export default function useDeleteAppVideoFeed() {
  const closeModal = useModalStore((state) => state.closeModal);
  const notify = useNotify();

  const handleDeleteAppVideoFeed = async (uuid: string) => {
    const { data, error } = await DeleteAppVideoFeed(uuid);
    if (error) {
      notify(error, { variant: 'error' });
      closeModal();
    } else {
      notify('App Video Feed deleted!', { variant: 'success' });
      closeModal();
      AppVideoFeedEmitter.emit('AppVideoFeedDeleted', data);
    }
  };

  return {
    handleDeleteAppVideoFeed,
  };
}
