import { debounce } from 'lodash';
import { CommonDateStrings } from 'src/lib/constants/CommonDates';
import useURLSearchParams from 'src/lib/hooks/useURLSearchParams';
import { fetchMakerStats } from '../../services/fetchMakerStats';

export default function useMakerStatistics(userAuthId?: string) {
  const { addParam, removeParam, allSearchParams } =
    useURLSearchParams(initialParams);

  const params = {
    startDate: allSearchParams.startDate ?? initialParams.startDate,
    endDate: allSearchParams.endDate ?? initialParams.endDate,
  };

  function handleChangeField(value: string, field: string) {
    addParam(field, value);
  }

  const debouncedChangeField = debounce(handleChangeField, 400);

  const { data: statsData, isLoading: isStatsLoading } = fetchMakerStats({
    filter: {
      startDate: `${params.startDate}T00:00:00.000Z`,
      endDate: `${params.endDate}T23:59:59.999Z`,
      ...(userAuthId && { userUuid: userAuthId }),
    },
  });

  return {
    statsData,
    isStatsLoading,
    params,
    removeParam,
    debouncedChangeField,
  };
}

const initialParams = {
  startDate: CommonDateStrings.sevenDaysAgo.dateTime.split('T')[0],
  endDate: CommonDateStrings.today.endOfDay.split('T')[0],
};

export type FetchMakerStatisticsQueryParams = {
  startDate: string;
  endDate: string;
};
