import { OpenInNew } from '@mui/icons-material';
import { Alert, Autocomplete, IconButton, TextField } from '@mui/material';
import Link from 'src/components/shared/Link';
import MagicTable from 'src/components/shared/MagicTable/MagicTable';
import MagicTableUserCell from 'src/components/shared/MagicTable/UserCell';
import useMagicTable from 'src/components/shared/MagicTable/useMagicTable';
import Routes from 'src/routes/Routes';
import Label from 'src/template/components/Label';
import useConsumablesListView from './useView';

const searchOptions = [
  { id: 'user.name', label: 'User Name' },
  {
    id: 'user.slug',
    label: 'User Slug',
  },
  {
    id: 'user.email',
    label: 'User Email',
  },
  {
    id: 'user.uuid',
    label: 'User UUID',
  },
  {
    id: 'product.name',
    label: 'Product Name',
  },
  {
    id: 'product.uuid',
    label: 'Product Uuid',
  },
  {
    id: 'product.productId',
    label: 'Product ID (apple)',
  },
];
export default function ConsumablesListView(): JSX.Element {
  const props = useMagicTable();
  const { data, error, isLoading, onChangeFilter, totalCount, initialParams } =
    useConsumablesListView();

  if (error) {
    return <Alert severity="error">{error}</Alert>;
  }
  return (
    <MagicTable.Container>
      <MagicTable.FilterContainer>
        <Autocomplete
          options={searchOptions}
          fullWidth
          renderInput={(params) => <TextField label="Search By" {...params} />}
          sx={{ maxWidth: 300 }}
          loading={isLoading}
          getOptionLabel={(op) => op.label}
          onChange={(e, value) => {
            onChangeFilter('searchBy', value?.id);
          }}
          defaultValue={searchOptions.find(
            (opt) => opt.id === initialParams.searchBy
          )}
        />
        <TextField
          label="Search for"
          fullWidth
          onChange={(e) => {
            const { value } = e.target;
            if (!value) onChangeFilter('query', undefined);
            onChangeFilter('query', `${value}%`);
          }}
          defaultValue={initialParams.query}
        />
      </MagicTable.FilterContainer>
      <MagicTable.Wrapper>
        <MagicTable
          loading={isLoading}
          rows={data}
          paginationMode="server"
          rowCount={totalCount}
          columns={[
            {
              field: 'eventDate',
              headerName: 'Purchase Date',
              flex: 1 / 2,
              renderCell({ row }) {
                return row.purchaseDate;
              },
            },
            {
              field: 'user',
              headerName: 'User',
              sortable: false,
              renderCell({ value }) {
                if (!value) return '--';
                return <MagicTableUserCell user={value} displayUserStatus />;
              },
            },
            {
              field: 'product.name',
              headerName: 'Product',
              renderCell({ row }) {
                return row.product;
              },
            },
            {
              field: 'product.priceUSD',
              headerName: 'Price',
              align: 'right',
              flex: 0,
              renderCell({ row }) {
                return row.price;
              },
            },
            {
              field: 'delivered',
              headerName: 'Delivered',
              flex: 0,
              sortable: false,
              align: 'center',
              renderCell({ value }) {
                return (
                  <Label color={value ? 'success' : 'error'}>
                    {value ? 'Delivered' : 'Not Delivered'}
                  </Label>
                );
              },
            },
            {
              field: 'actions',
              headerName: '',
              sortable: false,
              flex: 0,
              align: 'center',
              renderCell({ row }) {
                return (
                  <IconButton
                    component={Link}
                    to={Routes.consumable(
                      row.originalTransactionId,
                      row.user?.uuid
                    )}
                  >
                    <OpenInNew />
                  </IconButton>
                );
              },
            },
          ]}
          {...props}
        />
      </MagicTable.Wrapper>
    </MagicTable.Container>
  );
}
