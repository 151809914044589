import { DeepPartial } from 'react-hook-form';
import { NFTForPack } from 'src/lib/types/nft';
import { getMessageFromError } from 'src/lib/utils/error';
import { parseSearchParams } from 'src/lib/utils/parseSearchParams';
import useSWR, { SWRResponse } from 'swr';

type PossibleParams = {
  size: number | string;
  page: number | string;
  order: string;
  orderBy: string;
  query?: string;
  searchBy?: string;
};

export function fetchNFTSForPack(
  filters?: DeepPartial<PossibleParams>
): SWRResponse<
  Partial<NFTForPack[]> &
    {
      collectionName: string;
      ownedEditions: number;
    }[]
> {
  const parsedParams = parseSearchParams(filters ?? {});

  const { error, ...response } = useSWR(
    `admin/nfts/available-for-nft-pack?${parsedParams}`
  );
  return {
    ...response,
    error: error ? getMessageFromError(error) : undefined,
  };
}
