import {
  AccessAlarm,
  AccessTime,
  AccessTimeFilled,
  AttachMoney,
  CreditCard,
  Paid,
  QrCodeRounded,
  TroubleshootRounded,
} from '@mui/icons-material';
import {
  Card,
  CardContent,
  CardHeader,
  Icon,
  Stack,
  Typography,
} from '@mui/material';
import { capitalCase } from 'change-case';
import StickyCoin from 'src/assets/StickyCoin';
import { DEFAULT_PAYMENT_CURRENCY } from 'src/lib/config/payment';
import { AboutData } from 'src/lib/types/aboutData';
import { PaymentRequest } from 'src/lib/types/paymentRequest';
import { formatDate } from 'src/lib/utils/formatters';
import Iconify from 'src/template/components/Iconify';
import Label from 'src/template/components/Label';
import { getPaymentRequestLabelColor } from '../utils';

export default function PaymentRequestInfo({
  paymentRequest,
}: {
  paymentRequest: PaymentRequest;
}) {
  const {
    uuid,
    amount,
    status,
    value,
    currency,
    rejectedReason,
    createdAt,
    scheduledAt,
    executedAt,
    user,
    updatedAt,
  } = paymentRequest;

  const paymentRequestData: AboutData[] = [
    {
      label: 'UUID',
      value: uuid,
      icon: <QrCodeRounded />,
      sx: { fontSize: '12px' },
    },
    {
      label: 'Type',
      value: 'PayPal',
      icon: <CreditCard />,
    },
    {
      label: 'Currency',
      value: currency || DEFAULT_PAYMENT_CURRENCY,
      icon: <AttachMoney />,
    },
    {
      label: 'Value',
      value,
      icon: <Paid />,
    },
    {
      label: 'Coins',
      value: amount,
      icon: (
        <Icon fontSize="small">
          <StickyCoin />
        </Icon>
      ),
    },
    {
      label: 'Status',
      value: (
        <Label
          ml={4}
          color={getPaymentRequestLabelColor(status)}
          fontWeight="bold"
        >
          {capitalCase(status)}
        </Label>
      ),
      icon: <TroubleshootRounded />,
    },
    {
      label: 'Rejected Reason',
      value: rejectedReason ?? '--',
      icon: <Iconify icon="gg:block" fontSize="24px" />,
    },
    {
      label: 'Scheduled At',
      value: formatDate(scheduledAt),
      icon: <AccessAlarm />,
    },
    {
      label: 'Updated At',
      value: formatDate(updatedAt),
      icon: <AccessTimeFilled />,
    },
    {
      label: 'Created At',
      value: formatDate(createdAt),
      icon: <AccessTime />,
    },
  ];

  return (
    <Card
      sx={{
        width: '100%',
      }}
    >
      <CardHeader title="Payment Request Details" />
      <CardContent>
        <Stack spacing={1}>
          {paymentRequestData.map((data) => (
            <Stack
              direction="row"
              spacing={2}
              justifyContent="space-between"
              alignItems="center"
              key={data.label}
            >
              <Stack direction="row" spacing={1} alignItems="center">
                {data.icon}
                <Typography fontSize="14px">{data.label}:</Typography>
              </Stack>
              <Typography
                fontSize="14px"
                fontWeight="bold"
                textAlign="right"
                sx={{ ...data.sx }}
              >
                {data.value}
              </Typography>
            </Stack>
          ))}
        </Stack>
      </CardContent>
    </Card>
  );
}
