import { DeepPartial } from 'react-hook-form';
import { Operators } from 'src/lib/types/Operators';
import { PaginatedResponse } from 'src/lib/types/PaginatedResponse';
import { WithProduct } from 'src/lib/types/product';
import { WithOptionalUser } from 'src/lib/types/users';
import { getMessageFromError } from 'src/lib/utils/error';
import { formatUTCDate } from 'src/lib/utils/formatUTCDate';
import { parseSearchParams } from 'src/lib/utils/parseSearchParams';
import useSWR, { SWRConfiguration, SWRResponse } from 'swr';
import { ConsumableListItem } from '../types/Consumable';
import {
  RevenueEvent,
  WithDelivered,
  WithDeliveryPools,
} from '../types/RevenueEvent';

function parseData(
  data: Array<
    WithOptionalUser<
      WithDeliveryPools<WithProduct<WithDelivered<RevenueEvent>>>
    >
  >
): ConsumableListItem[] {
  return data.map((item) => ({
    uuid: item.uuid,
    purchaseDate: formatUTCDate(item.eventDate),
    delivered: item.delivered,
    price: item.product.priceUSD,
    product: item.product.name || item.product.productId || '--',
    user: item.user,
    originalTransactionId: item.originalTransactionId,
  }));
}

type PossibleParams = {
  page: number | string;
  size: number | string;
  order: Record<string, string>;
  filter: Record<string, string | Operators>;
};
export function fetchConsumableList(
  params?: DeepPartial<PossibleParams>,
  config?: SWRConfiguration
): SWRResponse<PaginatedResponse<ConsumableListItem[]>> {
  const parsedParams = parseSearchParams(params ?? {});
  const { error, data, ...response } = useSWR(
    `admin/revenue-events/consumables?${parsedParams}`,
    config
  );
  return {
    ...response,
    data: {
      body: parseData(data?.body ?? []),
      meta: data?.meta,
    },
    error: error ? getMessageFromError(error) : undefined,
  };
}
