import { Stack } from '@mui/material';
import CardInfo from 'src/components/shared/CardInfo/CardInfo';
import NumberInfo from 'src/components/shared/NumberInfo/NumberInfo';
import { formatMilliseconds } from 'src/lib/utils/formatMilliseconds';

interface GameStatsCardInfosProps {
  totalSessions: number;
  totalSessionTime: number;
  averageSessionDuration: number;
  averageMatches?: number;
  uniqueUsers: number;
  sessionsByUser: number;
  positiveRatings: number;
  negativeRatings: number;
  averageTimeByUser: number;
  usersByGame?: number;
}

export default function GameStatsCardInfos({
  totalSessionTime,
  averageMatches,
  averageSessionDuration,
  totalSessions,
  uniqueUsers,
  sessionsByUser,
  positiveRatings,
  negativeRatings,
  averageTimeByUser,
  usersByGame,
}: GameStatsCardInfosProps) {
  return (
    <Stack
      direction="row"
      spacing={3}
      alignItems="center"
      justifyContent="center"
      my={2}
    >
      <NumberInfo title="Unique Users" total={uniqueUsers} />
      {!!usersByGame && (
        <NumberInfo title="Users by game" decimals={2} total={usersByGame} />
      )}
      <NumberInfo title="Total Sessions" total={totalSessions} />
      <NumberInfo
        title="Sessions By User"
        total={sessionsByUser}
        decimals={2}
      />
      <CardInfo
        title="Total Session Time"
        text={formatMilliseconds(totalSessionTime, 'minutes')}
      />
      <CardInfo
        title="Average Session Time"
        text={formatMilliseconds(averageSessionDuration)}
      />
      <CardInfo
        title="Session Time By User"
        text={formatMilliseconds(averageTimeByUser)}
      />

      <NumberInfo title="Positive Ratinkgs" total={positiveRatings} />
      <NumberInfo title="Negative Ratings" total={negativeRatings} />
      {/* <NumberInfo title="Average Matches" decimals={2} total={averageMatches} /> */}
    </Stack>
  );
}
