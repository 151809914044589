import { DeepPartial } from 'react-hook-form';
import { PaginatedResponse } from 'src/lib/types/PaginatedResponse';
import { ClubsList } from 'src/lib/types/clubWithAdminAnalytics';
import { parseSearchParams } from 'src/lib/utils/parseSearchParams';
import useSWR, { SWRResponse } from 'swr';

export const fetchClubsWithCount = (
  filters?: DeepPartial<PossibleParams>,
  shouldFetch = true
): SWRResponse<PaginatedResponse<ClubsList[]>> => {
  const response = useSWR<PaginatedResponse<ClubsList[]>>(
    shouldFetch ? `admin/clubs/list?${parseSearchParams(filters ?? {})}` : null
  );

  return response;
};

type PossibleParams = {
  page: number | string;
  size: number | string;
  filter: {
    status: string;
    name: {
      like: string;
    };

    memberUuid: string;
    adminUuid: string;
    createdByUserUuid: string;
  };
  order: Record<string, string>;
};
