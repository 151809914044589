import { Add, Clear } from '@mui/icons-material';
import {
  Button,
  Card,
  CardContent,
  IconButton,
  Stack,
  TextField,
} from '@mui/material';
import { KeyValueFeedFilter } from './types';

interface KeyValueFiltersCardProps {
  filterList: KeyValueFeedFilter[];
  changeFilterList: (
    index: number,
    newFilter: KeyValueFeedFilter | null
  ) => void;
}

export default function KeyValueFiltersCard({
  filterList,
  changeFilterList,
}: KeyValueFiltersCardProps): JSX.Element {
  return (
    <Card>
      <CardContent
        sx={{
          overflowY: 'auto',
          height: 'calc(95vh - 320px);',
        }}
      >
        <Stack spacing={2}>
          {filterList.map((filter, index) => (
            <Stack direction="row" spacing={3}>
              <TextField
                label="Key"
                placeholder="Key"
                value={filter.key || ''}
                onChange={(event) => {
                  event.preventDefault();
                  changeFilterList(
                    index,
                    event.target.value
                      ? {
                          ...filter,
                          key: event.target.value,
                        }
                      : {}
                  );
                }}
                sx={{ width: '100%' }}
              />
              <TextField
                label="Value"
                placeholder="Value"
                disabled={!filter.key}
                value={filter.value || ''}
                onChange={(event) =>
                  filter.key &&
                  changeFilterList(index, {
                    ...filter,
                    value: event.target.value || undefined,
                  })
                }
                sx={{ width: '100%' }}
              />
              <Stack direction="row">
                <IconButton
                  onClick={() => changeFilterList(index, null)}
                  aria-label="delete filter"
                  size="small"
                  disabled={index === 0}
                  sx={{
                    opacity: index === 0 ? 0.5 : 1,
                  }}
                >
                  <Clear color="error" />
                </IconButton>
              </Stack>
            </Stack>
          ))}
          <Button
            startIcon={<Add />}
            variant="outlined"
            onClick={() => changeFilterList(filterList.length, {})}
            color="secondary"
          >
            Add
          </Button>
        </Stack>
      </CardContent>
    </Card>
  );
}
