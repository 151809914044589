import {
  Card,
  CardContent,
  CardHeader,
  Stack,
  Typography,
} from '@mui/material';
import { GridSortDirection } from '@mui/x-data-grid';
import { capitalCase } from 'change-case';
import { useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { useNavigate } from 'react-router';
import Button from 'src/components/shared/Buttons/CustomButton';
import Flex from 'src/components/shared/Flex';
import ControllerField from 'src/components/shared/Inputs/ControllerField';
import MagicTable from 'src/components/shared/MagicTable/MagicTable';
import { CommonDateStrings } from 'src/lib/constants/CommonDates';
import useURLSearchParams from 'src/lib/hooks/useURLSearchParams';
import Routes from 'src/routes/Routes';
import { FilterFormValues, PoolItem } from './types';

type Props = {
  pools: PoolItem[];
  isLoading: boolean;
  onFilter: (values: FilterFormValues) => void;
  defaultValues: FilterFormValues;
};

const defaultParams = {
  order: 'desc',
  orderBy: 'uniqueUsersAffecteds',
};

export default function PoolsStatusView({
  pools,
  isLoading,
  onFilter,
  defaultValues,
}: Props): JSX.Element {
  const navigate = useNavigate();
  const { control, handleSubmit, getValues } = useForm<FilterFormValues>({
    defaultValues,
  });
  const { addParam, removeParam, allSearchParams } =
    useURLSearchParams(defaultParams);

  // set param default values

  useEffect(() => {
    addParam('orderBy', defaultParams.orderBy);
    addParam('order', defaultParams.order);
  }, []);

  return (
    <Card>
      <CardHeader
        title="Pools Status"
        action={
          <form onSubmit={handleSubmit(onFilter)}>
            <Flex gap={1}>
              <ControllerField
                control={control}
                name="startDate"
                label="Start Date"
                type="datetime-local"
                InputLabelProps={{ shrink: true }}
                size="small"
                inputProps={{
                  min: CommonDateStrings.firstTransactionAndPool,
                  max: CommonDateStrings.today,
                }}
              />
              <ControllerField
                control={control}
                name="endDate"
                label="End Date"
                type="datetime-local"
                InputLabelProps={{ shrink: true }}
                size="small"
                inputProps={{
                  min: CommonDateStrings.firstTransactionAndPool,
                  max: CommonDateStrings.today,
                }}
              />
              <Button color="primary" variant="contained" type="submit">
                Apply
              </Button>
            </Flex>
          </form>
        }
      />
      <CardContent>
        <MagicTable
          loading={isLoading}
          autoHeight
          hideFooter
          sortModel={
            allSearchParams.orderBy
              ? [
                  {
                    field: allSearchParams.orderBy!,
                    sort: allSearchParams.order! as GridSortDirection,
                  },
                ]
              : []
          }
          onSortModelChange={(model) => {
            const currentModel = model[0];
            if (currentModel) {
              addParam('orderBy', currentModel.field);
              if (currentModel.sort) addParam('order', currentModel.sort);
            } else {
              removeParam('order');
              removeParam('orderBy');
            }
          }}
          onCellClick={(cell) => {
            if (cell.field === 'name' || cell.field === 'uniqueUsersAffecteds')
              return;

            const startDate = getValues('startDate');
            const endDate = getValues('endDate');
            navigate(
              `${Routes.transactions.pools}?tab=${cell.field}&transactionPoolType=${cell.id}&startDate=${startDate}&endDate=${endDate}`
            );
          }}
          columns={[
            {
              field: 'name',
              headerName: 'Pool Name',
              flex: 1,
              valueFormatter({ value }) {
                return capitalCase(value);
              },
            },
            {
              field: 'success',
              headerName: 'Success',
              align: 'center',
              cellClassName: 'pointer',
              renderCell: (value) => <CustomCell value={value.value} />,
              sortComparator: (v1, v2) => v1.quantity - v2.quantity,
            },
            {
              field: 'pending',
              headerName: 'Pending',
              align: 'center',
              cellClassName: 'pointer',
              renderCell: (value) => <CustomCell value={value.value} />,
              sortComparator: (v1, v2) => v1.quantity - v2.quantity,
            },
            {
              field: 'failed',
              headerName: 'Failed',
              align: 'center',
              cellClassName: 'pointer',
              renderCell: (value) => <CustomCell value={value.value} />,
              sortComparator: (v1, v2) => v1.quantity - v2.quantity,
            },
            {
              field: 'rollback',
              headerName: 'Rollback',
              align: 'center',
              cellClassName: 'pointer',
              renderCell: (value) => <CustomCell value={value.value} />,
              sortComparator: (v1, v2) => v1.quantity - v2.quantity,
            },
            {
              field: 'uniqueUsersAffecteds',
              headerName: 'Affected Users',
              align: 'center',
              flex: 1,
              valueFormatter({ value }) {
                return value;
              },
              sortComparator: (v1, v2) => v1 - v2,
            },
          ]}
          rows={pools.map((pool) => ({
            id: pool.name,
            name: pool.name,
            success: pool.success,
            pending: pool.pending,
            failed: pool.failed,
            rollback: pool.rollback,
            uniqueUsersAffecteds: pool.uniqueUsersAffecteds,
          }))}
          getRowId={(r) => r.id}
        />
      </CardContent>
    </Card>
  );
}

type CustomCellProps = {
  value: {
    quantity: number;
    lastOccurrence: number;
  };
};
function CustomCell({ value }: CustomCellProps): JSX.Element {
  const { quantity, lastOccurrence } = value;
  return (
    <Stack component="span" alignItems="center">
      {quantity}
      {lastOccurrence && (
        <Typography fontSize={12} color="GrayText">
          ({lastOccurrence})
        </Typography>
      )}
    </Stack>
  );
}
