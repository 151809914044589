import { capitalCase } from 'change-case';
import ConfirmationDialogRaw from 'src/components/shared/Dialog/ConfirmationDialog';
import useModalStore from 'src/lib/stores/useModalStore';
import { ContestStatus } from 'src/lib/types/contest';
import useChangeContestStatus from './useView';

type Props = {
  uuid: string;
  status: ContestStatus;
};
export default function ChangeContestStatusView({
  uuid,
  status,
}: Props): JSX.Element {
  const { handleChangeContestStatus } = useChangeContestStatus();
  const closeModal = useModalStore((state) => state.closeModal);

  return (
    <ConfirmationDialogRaw
      onClose={closeModal}
      open
      title={`Change Contest status`}
      onConfirm={async () => {
        await handleChangeContestStatus(uuid, status);
      }}
    >
      {`Are you sure you want to set this Contest status as ${capitalCase(
        status
      )}?`}
    </ConfirmationDialogRaw>
  );
}
