import { Container } from '@mui/material';
import HeaderBreadcrumbs from 'src/template/components/HeaderBreadcrumbs';

import FeedTable from '../../components/FeedTable';
import useFeed from './useView';

export default function VideoFeed() {
  const {
    data,
    isLoading,
    params,
    handleOpenDeleteModal,
    handleOpenEditModal,
    onChangeFeedStatus,
  } = useFeed();

  return (
    <Container maxWidth="xl">
      <HeaderBreadcrumbs
        heading="Video Feed"
        links={[{ name: 'Video Feed' }]}
      />

      <FeedTable
        data={data}
        isLoading={isLoading}
        params={params}
        handleOpenDeleteModal={handleOpenDeleteModal}
        onEditClick={handleOpenEditModal}
        onChangeFeedStatus={onChangeFeedStatus}
      />
    </Container>
  );
}
