import useSWR, { mutate } from 'swr';
import { getFormattedError } from '../utils/error';
import getAPIClient from './api/axios.config';

export const executePaymentRequest = async (paymentRequestUuid: string) => {
  try {
    const api = await getAPIClient();
    const res = await api.post(
      `admin/stripe/payment-request/${paymentRequestUuid}`
    );

    mutate(`admin/payment-requests/${paymentRequestUuid}`);

    return {
      data: res.data,
      success: true,
      message: 'The payment request was successfully executed by stripe',
    };
  } catch (err) {
    return {
      success: false,
      ...getFormattedError(err),
    };
  }
};

export const fetchUserAccount = (userUuid: string) => {
  const response = useSWR(`admin/stripe/account/${userUuid}`);

  return response;
};
