import { useCallback, useEffect } from 'react';
import { DEFAULT_INITIAL_PAGE_SIZE } from 'src/lib/constants/pagination';
import useURLSearchParams from 'src/lib/hooks/useURLSearchParams';
import useModalStore from 'src/lib/stores/useModalStore';

import { FeedEmitter } from 'src/components/modules/ChannelContent/events/FeedEmitter';
import UpdateVideoContent from 'src/components/modules/ChannelContent/view/Update';
import useNotify from 'src/lib/hooks/useNotify';
import { FeedItem } from 'src/lib/types/FeedItem';
import { AppVideoFeedEmitter } from '../../events/AppVideoFeedEmitter';
import { fetchFeed } from '../../services/fetchFeed';
import { UpdateFeedStatus } from '../../services/updateFeedStatus';
import DeleteGameView from '../Delete';

export default function useFeed() {
  const openModal = useModalStore((state) => state.openModal);
  const notify = useNotify();
  const handleOpenEditModal = (uuid: string) => {
    const feedItem = data?.find((item) => item.uuid === uuid);
    openModal(<UpdateVideoContent feedItem={feedItem} />);
  };

  const handleOpenDeleteModal = (uuid: string) => {
    openModal(<DeleteGameView uuid={uuid} />);
  };

  const { allSearchParams } = useURLSearchParams(initialParams);

  const params = {
    page: +(allSearchParams.page ?? initialParams.page),
    size: +(allSearchParams.size ?? initialParams.size),
    orderBy: allSearchParams.orderBy ?? initialParams.orderBy,
    sortOrder: allSearchParams.order ?? initialParams.order,
    searchBy: allSearchParams.searchBy ?? initialParams.searchBy,
    query: allSearchParams.query ?? initialParams.query,
  };

  const { data, isLoading, isValidating, mutate } = fetchFeed({
    page: params.page,
    size: params.size,

    order: params.sortOrder,
    orderBy: params.orderBy,

    filter: {
      ...(params.searchBy &&
        params.query && {
          [params.searchBy]: {
            like: `%${params.query}%`,
          },
        }),
    },
  });

  async function onChangeFeedStatus(
    uuid: string,
    currentStatus: 'active' | 'inactive'
  ) {
    const newStatus = currentStatus === 'active' ? 'inactive' : 'active';

    await UpdateFeedStatus(uuid, {
      status: newStatus,
    })
      .then(({ error, ...rest }) => {
        if (error) {
          notify(error, { variant: 'error' });
        } else {
          notify('Changed feed status successfully', {
            variant: 'success',
          });
        }

        const newData = data?.map((feed) => {
          if (feed.uuid === uuid) {
            return {
              ...feed,
              status: newStatus,
            };
          }
          return feed;
        }) as FeedItem[];

        mutate(newData);
      })
      .catch(() => {
        notify('Failed to change game status', {
          variant: 'error',
        });
      });
  }

  const refetchData = useCallback(() => {
    mutate?.();
  }, []);

  useEffect(() => {
    FeedEmitter.on('FeedItemUpdated', refetchData);
    AppVideoFeedEmitter.on('AppVideoFeedCreated', refetchData);
    AppVideoFeedEmitter.on('AppVideoFeedUpdated', refetchData);
    AppVideoFeedEmitter.on('AppVideoFeedDeleted', refetchData);
    return () => {
      FeedEmitter.off('FeedItemUpdated', refetchData);
      AppVideoFeedEmitter.off('AppVideoFeedCreated', refetchData);
      AppVideoFeedEmitter.off('AppVideoFeedUpdated', refetchData);
      AppVideoFeedEmitter.off('AppVideoFeedDeleted', refetchData);
    };
  }, [refetchData]);

  return {
    data,
    isLoading: isLoading || isValidating,
    params,
    handleOpenDeleteModal,
    handleOpenEditModal,
    onChangeFeedStatus,
  };
}

const initialParams = {
  page: '0',
  size: DEFAULT_INITIAL_PAGE_SIZE.toString(),
  order: 'asc',
  orderBy: 'viewOrder',
  searchBy: 'game.name',
  query: '',
};

export type FetchGamesQueryParams = {
  page: number;
  size: number;
  orderBy: string;
  searchBy: string;
  sortOrder: string;
};
