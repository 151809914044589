import useNotify from 'src/lib/hooks/useNotify';
import useModalStore from 'src/lib/stores/useModalStore';
import { PayoutCountryFormValues } from '../../components/Form/types';
import { PayoutCountryEmitter } from '../../events/PayoutCountryEmitter';
import { updatePayoutCountry } from '../../services/updatePayoutCountry';

export default function useUpdatePayoutCountry() {
  const closeModal = useModalStore((state) => state.closeModal);
  const notify = useNotify();

  const handleUpdatePayoutCountry = async (
    uuid: string,
    values: PayoutCountryFormValues
  ) => {
    const { error, data } = await updatePayoutCountry(uuid, {
      ...values,
      active: values.active === 'true',
    });
    if (error) {
      notify(error, { variant: 'error' });
      closeModal();
    } else if (data) {
      notify('Configuration updated!', { variant: 'success' });
      closeModal();
      PayoutCountryEmitter.emit('payoutCountryUpdated', data);
    }
  };

  return {
    handleUpdatePayoutCountry,
  };
}
