import GameCategories from 'src/components/modules/GameCategories/view/List';
import Page from 'src/template/components/Page';

export default function GameCategoriesPage() {
  return (
    <Page title="Game Categories">
      <GameCategories />
    </Page>
  );
}
