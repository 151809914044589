import NFTPackForm from '../../../components/Forms/NftPack';
import useCreateNFTPackView from './useView';

export default function CreateNftPackView(): JSX.Element {
  const { handleCreateNftPack } = useCreateNFTPackView();
  return (
    <NFTPackForm
      defaultValues={{
        name: '',
        amountOfNftsToRecharge: 0,
        type: '',
        productUuid: '',
      }}
      onSubmit={handleCreateNftPack}
      title="Create NFT Pack"
    />
  );
}
