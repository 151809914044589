import { PublishRounded } from '@mui/icons-material';
import { IconButton, Tooltip } from '@mui/material';
import useDisclosure from 'src/lib/hooks/useDisclosure';
import ImportFromOpenSeaModal from './ImportFromOpenSeaModal';

export default function ImportFromOpenSea() {
  const { isOpen, onClose, onOpen } = useDisclosure();

  return (
    <>
      <Tooltip title="Import collections from opensea" placement="top">
        <IconButton
          sx={{
            flex: 1,
            aspectRatio: 1,
          }}
          aria-label="import collection from opensea"
          size="medium"
          onClick={onOpen}
        >
          <PublishRounded />
        </IconButton>
      </Tooltip>
      <ImportFromOpenSeaModal onClose={onClose} open={isOpen} />
    </>
  );
}
