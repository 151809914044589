import {
  Card,
  CardContent,
  CardHeader,
  Divider,
  Stack,
  Typography,
} from '@mui/material';
import { ReactNode } from 'react';
import Button from 'src/components/shared/Buttons/CustomButton';
import Center from 'src/components/shared/Center';
import Flex from 'src/components/shared/Flex';
import Loader from 'src/components/shared/Loader';
import Routes from 'src/routes/Routes';
import HeaderBreadcrumbs from 'src/template/components/HeaderBreadcrumbs';
import Iconify from 'src/template/components/Iconify';
import Label from 'src/template/components/Label';
import UsersTableView from '../../components/Device/UsersTable';
import useDeviceView from './useView';

export default function DeviceView(): JSX.Element {
  const {
    data,
    isLoading,
    firstCreated,
    lastLogin,
    usersLength,
    handleToggleDeviceActivation,
    nonAnonymousUsers,
    anonymousUsers,
    activeUsers,
    inactiveUsers,
    handleUpdateDevice,
  } = useDeviceView();

  return (
    <>
      <HeaderBreadcrumbs
        heading="Device"
        links={[
          { name: 'Devices List', href: Routes.devices },
          { name: 'Device Page' },
        ]}
        action={
          <>
            <Button
              variant="contained"
              loading={isLoading}
              startIcon={
                data?.active ? (
                  <Iconify icon="ic:baseline-block" />
                ) : (
                  <Iconify icon="carbon:checkmark-outline" />
                )
              }
              color={data?.active ? 'error' : 'success'}
              onClick={handleToggleDeviceActivation}
            >
              {data?.active ? 'Block Device' : 'Activate Device'}
            </Button>
            <Button
              variant="contained"
              loading={isLoading}
              color="secondary"
              onClick={handleUpdateDevice}
              startIcon={<Iconify icon="uil:edit" />}
              sx={{
                ml: 1,
              }}
            >
              Update Device
            </Button>
          </>
        }
      />
      <Stack spacing={2}>
        <Flex justifyContent="stretch" alignItems="stretch" gap={2}>
          <Card sx={{ flex: 1 }}>
            <CardHeader title="Additional data" />
            <CardContent>
              {isLoading && (
                <Center>
                  <Loader />
                </Center>
              )}
              {!isLoading && data && (
                <Stack divider={<Divider />}>
                  <LabelValue label="Logged Users" value={nonAnonymousUsers} />
                  <LabelValue label="Anonymous Users" value={anonymousUsers} />
                  <LabelValue label="Active Users" value={activeUsers} />
                  <LabelValue label="Inactive Users" value={inactiveUsers} />
                  <LabelValue label="Most recent login" value={lastLogin} />
                  <LabelValue label="First created" value={firstCreated} />
                </Stack>
              )}
            </CardContent>
          </Card>
          <Card sx={{ flex: 1 }}>
            <CardHeader title="Device Details" />
            <CardContent>
              {isLoading && (
                <Center>
                  <Loader />
                </Center>
              )}
              {!isLoading && data && (
                <Stack divider={<Divider />}>
                  <LabelValue
                    label="Status"
                    value={
                      data.active ? (
                        <Label color="success">Active</Label>
                      ) : (
                        <Label color="error">Inactive</Label>
                      )
                    }
                  />
                  <LabelValue label="Total Users" value={usersLength} />
                  {data.appleAdvertisingId && (
                    <LabelValue
                      label="Apple Advertising ID"
                      value={data.appleAdvertisingId}
                    />
                  )}
                  {data.appleVendorId && (
                    <LabelValue
                      label="Apple Vendor ID"
                      value={data.appleVendorId}
                    />
                  )}
                  {data.webId && (
                    <LabelValue label="Web ID" value={data.webId} />
                  )}
                  {data.androidId && (
                    <LabelValue label="Android ID" value={data.androidId} />
                  )}
                  {data.pushId && (
                    <LabelValue label="Push ID" value={data.pushId} />
                  )}
                  <LabelValue label="Country" value={data.country} />
                  <LabelValue label="Platform" value={data.platform} />
                  <LabelValue
                    label="System Version"
                    value={data.systemVersion}
                  />
                  {data.description && (
                    <LabelValue label="Description" value={data.description} />
                  )}
                  {data.blockReason && !data.active && (
                    <LabelValue label="Block Reason" value={data.blockReason} />
                  )}
                </Stack>
              )}
            </CardContent>
          </Card>
        </Flex>
        <Card>
          <CardHeader title="Users" />
          <CardContent>
            <UsersTableView
              isLoading={isLoading}
              total={usersLength}
              users={data?.users ?? []}
            />
          </CardContent>
        </Card>
      </Stack>
    </>
  );
}

type LabelValueProps = {
  label: ReactNode;
  value: ReactNode;
};
function LabelValue({ label, value }: LabelValueProps): JSX.Element {
  return (
    <Flex py={0.5} gap={2} justifyContent="space-between">
      <Typography width="fit-content">{label}</Typography>
      <Typography
        flex={1}
        textAlign="right"
        component="span"
        sx={{ wordBreak: 'break-all', fontVariantNumeric: 'tabular-nums' }}
      >
        {value}
      </Typography>
    </Flex>
  );
}
