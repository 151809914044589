import { BracketFilterParams } from '../types/bracketFilterParams';

export default function generateSearchRequest({
  orderBy,
  order,
  searchFor,
  query,
  page,
  size,
  custom = {},
}: BracketFilterParams<string, string>) {
  const urlSearchParams = new URLSearchParams();
  urlSearchParams.append(`order[${orderBy}]`, order);
  urlSearchParams.append('page', String(page));
  urlSearchParams.append('size', String(size));

  if (query) {
    urlSearchParams.append(`filter[${searchFor}][like]`, `%${query}%`);
  }

  Object.entries(custom)?.forEach(([field, operators]) => {
    Object.entries(operators)?.forEach(([operator, value]) => {
      if (field && operator && value) {
        urlSearchParams.append(`filter[${field}][${operator}]`, `${value}`);
      }
    });
  });
  return `?${urlSearchParams.toString()}`;
}
