import { TableRow, useTheme } from '@mui/material';
import { ReactElement } from 'react';
import { useNavigate } from 'react-router';
import { EldenTableCellProps } from './EldenTableCell';

export type EldenTableRowProps = {
  linkTo?: string;
  highlight?: boolean;
  children: Array<ReactElement<EldenTableCellProps>>;
};

export default function EldenTableRow({
  linkTo,
  children,
  highlight = false,
}: EldenTableRowProps) {
  const theme = useTheme();
  const navigate = useNavigate();

  return linkTo ? (
    <TableRow
      hover
      style={{
        cursor: 'pointer',
        ...(highlight
          ? {
              backgroundColor: `${theme.palette.info.main}15`,
              border: `1px solid ${theme.palette.info.main}20`,
            }
          : {}),
      }}
      onClick={(e) => {
        e.stopPropagation();
        const { target } = e;
        // @ts-ignore href might exist in the target
        if ('href' in target && target.href) return;
        navigate(linkTo);
      }}
      onAuxClick={(e) => {
        e.stopPropagation();
        window.open(linkTo, '_blank', 'rel=noopener noreferrer');
      }}
    >
      {children}
    </TableRow>
  ) : (
    <TableRow>{children}</TableRow>
  );
}
