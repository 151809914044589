import getAPIClient from 'src/lib/services/api/axios.config';
import { FeedItem } from 'src/lib/types/FeedItem';
import { ServiceResponse } from 'src/lib/types/ServiceResponse';
import { getMessageFromError } from 'src/lib/utils/error';

export type UpdateFeedStatusData = {
  status: 'active' | 'inactive';
};

export async function UpdateFeedStatus(
  uuid: string,
  data: UpdateFeedStatusData
): Promise<ServiceResponse<FeedItem>> {
  try {
    const api = await getAPIClient();
    const res = await api.patch(`admin/app-video-feeds/${uuid}/status`, data);
    return {
      data: res.data,
      error: null,
    };
  } catch (e) {
    return {
      data: null,
      error: getMessageFromError(e),
    };
  }
}
