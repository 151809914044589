import useNotify from 'src/lib/hooks/useNotify';
import useModalStore from 'src/lib/stores/useModalStore';
import { ClubEmitter } from '../../events/ClubEmitter';
import { ExternalAssetsCreationData } from '../../services/createClub';
import { removeAssets } from '../../services/removeAssets';

export default function useDeleteExternalAsset() {
  const closeModal = useModalStore((state) => state.closeModal);
  const notify = useNotify();

  const handleDeleteExternalAsset = async (
    clubUuid: string,
    assetToRemove: ExternalAssetsCreationData
  ) => {
    const { data, error } = await removeAssets(clubUuid, {
      externalAssets: [assetToRemove],
    });
    if (error) {
      notify(error, { variant: 'error' });
      closeModal();
    } else {
      notify('Asset deleted!', { variant: 'success' });
      closeModal();
      ClubEmitter.emit('ClubUpdated', null);
    }
  };

  return {
    handleDeleteExternalAsset,
  };
}
