import { DeepPartial } from 'react-hook-form';
import useSWR from 'swr';
import { OldTransaction } from '../types/OldTransaction';
import { PaginatedResponse } from '../types/PaginatedResponse';
import { TransactionPoolAttributes } from '../types/Pool';
import { TransactionHistoryPossibleTypes } from '../types/TransactionHistory';
import { BracketFilterParams } from '../types/bracketFilterParams';
import { GlobalWallet } from '../types/globalWallet';
import { WithNFT } from '../types/nft';
import {
  NftPack,
  Transaction,
  TransactionPoolStatus,
  TransactionType,
} from '../types/transaction';
import { WithUser } from '../types/users';
import { getFormattedError } from '../utils/error';
import generateSearchRequest from '../utils/generateSearchRequest';
import { parseSearchParams } from '../utils/parseSearchParams';
import { transactionHistoryTableParser } from '../utils/transactions/transactionParser';
import getAPIClient from './api/axios.config';

export const fetchUserTransactionHistory = (
  uuid: string,
  filter: BracketFilterParams,
  status: TransactionPoolStatus | 'all' = 'all'
) => {
  const statusFilter = status === 'all' ? '' : `&filter[status]=${status}`;

  const { data, error } = useSWR<
    PaginatedResponse<TransactionHistoryPossibleTypes[]>,
    Error
  >(
    `admin/users/${uuid}/transactions/${generateSearchRequest(
      filter
    )}${statusFilter}`
  );

  return {
    data: data?.body && transactionHistoryTableParser(data?.body),
    total: data?.meta.totalCount || 0,
    loading: !error && !data,
    error: error?.message,
  };
};

export const fetchUserLiteralTransactions = (
  filter: DeepPartial<{
    page: number;
    size: number;
    userUuidFromScreen?: string;
    transactionType?: TransactionType;
    dateRange: {
      startDate: string;
      endDate: string;
    };
    filter: {
      [key: string]: {
        like: string;
        eq: string;
      };
    };
  }>
) => {
  const { data, error } = useSWR<PaginatedResponse<Transaction[]>, Error>(
    `admin/transactions/list-transactions/?${parseSearchParams(filter)}`
  );

  return {
    data: data?.body ?? [],
    total: data?.meta.totalCount ?? 0,
    loading: !error && !data,
    error: error?.message,
  };
};

export const fetchUserTransactionHistoryAsCsv = async (uuid: string) => {
  try {
    const api = await getAPIClient();
    const { data } = await api.get<string>(
      `admin/users/${uuid}/transactions/get-csv`
    );

    return { data };
  } catch (err) {
    return { error: getFormattedError(err) };
  }
};

export const fetchUserBonuses = (uuid: string, filter: BracketFilterParams) => {
  const { data, error } = useSWR<PaginatedResponse<OldTransaction[]>, Error>(
    `admin/users/${uuid}/transactions/${generateSearchRequest(
      filter
    )}&filter[type][like]=%recharge%`
  );

  return {
    data: data?.body ?? [],
    total: data?.meta.totalCount ?? 0,
    loading: !error && !data,
    error: error?.message,
  };
};

export const fetchPaymentTransactionsFromUser = (
  userId: string,
  filter: BracketFilterParams,
  status: TransactionPoolStatus | 'all' = 'all'
) => {
  const statusFilter =
    status === 'all'
      ? '&filter[transactionPoolType.name][in]=["payoutV1","payment_requestV1","rejected_payoutV1","cancelled_payoutV1"]'
      : `&filter[transactionPoolType.name]=${status}`;

  const { data, error } = useSWR<
    PaginatedResponse<TransactionHistoryPossibleTypes[]>,
    Error
  >(
    `admin/users/${userId}/transactions/${generateSearchRequest(
      filter
    )}${statusFilter}`
  );

  return {
    data: data?.body && transactionHistoryTableParser(data?.body),
    total: data?.meta.totalCount || 0,
    loading: !error && !data,
    error: error?.message,
  };
};

export const fetchTransactions = (
  filter: BracketFilterParams,
  status: TransactionPoolStatus | 'all' = 'all'
) => {
  const statusFilter = status === 'all' ? '' : `&filter[status]=${status}`;

  const { data, error } = useSWR<
    PaginatedResponse<TransactionHistoryPossibleTypes[]>,
    Error
  >(`admin/transactions/${generateSearchRequest(filter)}${statusFilter}`);

  return {
    data: data?.body && transactionHistoryTableParser(data?.body),
    total: data?.meta.totalCount || 0,
    loading: !error && !data,
    error: error?.message,
  };
};

export const fetchOldTransactions = (filter: BracketFilterParams) => {
  const { data, error } = useSWR<PaginatedResponse<OldTransaction[]>, Error>(
    `admin/transactions/old/${generateSearchRequest(filter)}`
  );

  return {
    data: data?.body,
    total: data?.meta.totalCount,
    loading: !error && !data,
    error: error?.message,
  };
};

export type FetchPoolDataResponse = Omit<
  TransactionPoolAttributes,
  'transactions'
> & {
  transactions: Array<
    Transaction & {
      globalWalletFrom: WithUser<GlobalWallet>;
      globalWalletTo: WithUser<GlobalWallet>;
      cardShard: WithNFT<{
        uuid: string;
        nftUuid: string;
        amountToCreate: number;
        rarity: string;
        status: string;
      }>;
    }
  >;
};
export const fetchPoolData = (id: string | undefined) => {
  if (!id) {
    return {
      data: undefined,
      error: 'ID should not be null or undefined',
    };
  }

  const { data, error } = useSWR<FetchPoolDataResponse>(
    `admin/transactions/${id}`
  );

  return {
    data,
    error: error?.message,
  };
};

export const giveNftPackToUser = async (userUuid: string, nftPack: NftPack) => {
  const api = await getAPIClient();

  const res = await api.post(
    `admin/transactions/give-nft-pack/${userUuid}`,
    nftPack
  );

  return res.data;
};
