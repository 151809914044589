import { DeepPartial } from 'react-hook-form';
import { PaginatedResponse } from 'src/lib/types/PaginatedResponse';
import { SGMChatMessage } from 'src/lib/types/sgmChatMessage';
import { parseSearchParams } from 'src/lib/utils/parseSearchParams';
import useSWR, { SWRResponse } from 'swr';

export type PossibleParams = {
  limit: number | string;
  offset: number | string;
};

export const fetchMakerMessages = (
  promptDialogUuid: string,
  filter?: DeepPartial<PossibleParams>
): SWRResponse<PaginatedResponse<SGMChatMessage[]>> => {
  const parsedParams = parseSearchParams(filter ?? {});
  const response = useSWR<PaginatedResponse<SGMChatMessage[]>, Error>(
    `admin/game-maker-chats/${promptDialogUuid}/messages?${parsedParams}`
  );

  return response;
};
