import {
  CircularProgress,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  Tooltip,
} from '@mui/material';
import Center from 'src/components/shared/Center';
import { PaymentRequest } from 'src/lib/types/paymentRequest';
import Iconify from 'src/template/components/Iconify';
import {
  TableHeadCustom,
  TableSelectedActions,
} from 'src/template/components/table';
import { IHeadLabel } from 'src/template/components/table/TableHeadCustom';
import usePaymentsTable from '../../usePaymentsTable';
import PaymentsTableRow from './PaymentsTableRow';

const TABLE_HEAD: IHeadLabel[] = [
  { id: 'createdAt', label: 'Created At' },
  { id: 'user.slug', label: 'User Slug' },
  { id: 'amount', label: 'Coins' },
  { id: 'value', label: 'Value' },
  { id: 'status', label: 'Status', align: 'center' },
  { id: 'isEligible', label: 'Is Eligible', align: 'center' },
  {
    id: 'userHasProSubscription',
    label: 'Has Pro Subscription',
    align: 'center',
  },
  { id: 'updatedAt', label: 'Updated At' },
  { id: 'actions', label: 'Actions', align: 'center' },
];

type Props = {
  data: PaymentRequest[] | undefined;
};

export default function PaymentsTable({ data }: Props) {
  const [selected, toggleSelectAll] = usePaymentsTable((state) => [
    state.selected,
    state.toggleSelectAll,
  ]);

  const [order, orderBy] = usePaymentsTable((state) => [
    state.filter.order,
    state.filter.orderBy,
  ]);

  const onSort = usePaymentsTable((state) => state.onSort);

  const dense = usePaymentsTable((state) => state.dense);

  const onSelectAll = () => {
    toggleSelectAll(data?.map((paymentRequest) => paymentRequest.uuid) || []);
  };

  return (
    <TableContainer sx={{ minWidth: '100%', position: 'relative' }}>
      {selected.length > 0 && (
        <TableSelectedActions
          dense={dense}
          numSelected={selected.length}
          rowCount={data?.length || 0}
          onSelectAllRows={onSelectAll}
          actions={
            <Tooltip title="Delete">
              <IconButton color="primary" onClick={() => {}}>
                <Iconify icon="eva:trash-2-outline" />
              </IconButton>
            </Tooltip>
          }
        />
      )}

      <Table size={dense ? 'small' : 'medium'}>
        <TableHeadCustom
          order={order}
          orderBy={orderBy}
          headLabel={TABLE_HEAD}
          rowCount={data?.length ? 0 : data && data.length}
          numSelected={selected.length}
          onSort={onSort}
        />
        <TableBody>
          {!data ? (
            <TableRow>
              <TableCell colSpan={TABLE_HEAD.length + 1}>
                <Center>
                  <CircularProgress />
                </Center>
              </TableCell>
            </TableRow>
          ) : (
            data &&
            data.map((payment: PaymentRequest) => (
              <PaymentsTableRow key={payment.uuid} payment={payment} />
            ))
          )}
        </TableBody>
      </Table>
    </TableContainer>
  );
}
