import {
  Divider,
  FormControlLabel,
  Stack,
  Switch,
  Typography,
} from '@mui/material';
import { useEffect, useState } from 'react';
import { fetchFilterOperators } from 'src/lib/services/homeFeeds';
import KeyValueFiltersCard from './KeyValueFiltersCard';
import UiFiltersCard from './UiFiltersCard';
import { KeyValueFeedFilter, UiFeedFilter } from './types';
import {
  getKeyValueFiltersListFromUiFiltersList,
  getUiFiltersListFromKeyValueFiltersList,
} from './utils';

interface FiltersCardSelectProps {
  isFilterType: boolean;
  attributeOptions: string[];
  keyValueFilters: KeyValueFeedFilter[];
  setKeyValueFilters: (list: KeyValueFeedFilter[]) => void;
}

export default function FiltersCardSelect({
  isFilterType,
  attributeOptions,
  keyValueFilters,
  setKeyValueFilters,
}: FiltersCardSelectProps): JSX.Element {
  const [isTextMode, setIsTextMode] = useState(false);
  const { data: operators } = fetchFilterOperators();

  const handleKeyValueFiltersChange = (
    index: number,
    newKeyValueFilter: KeyValueFeedFilter | null
  ) => {
    const filtersCopy = [...keyValueFilters];
    if (index >= keyValueFilters.length && newKeyValueFilter) {
      filtersCopy.push(newKeyValueFilter);
    } else if (newKeyValueFilter)
      filtersCopy.splice(index, 1, newKeyValueFilter);
    else filtersCopy.splice(index, 1);
    setKeyValueFilters(filtersCopy);
  };

  const handleUiFiltersChange = (
    index: number,
    newFilter: UiFeedFilter | null
  ) => {
    if (!newFilter || !Object.keys(newFilter || {}).length)
      return handleKeyValueFiltersChange(index, newFilter);
    const newKeyValueFilter = getKeyValueFiltersListFromUiFiltersList([
      newFilter,
    ])[0];
    return handleKeyValueFiltersChange(index, newKeyValueFilter);
  };

  useEffect(() => {
    setIsTextMode(!isFilterType);
  }, [isFilterType]);

  return (
    <>
      <Divider />
      <Stack direction="row" justifyContent="space-between">
        <Typography
          variant="h6"
          fontWeight="bold"
          sx={{
            padding: '2px 10px',
            alignSelf: 'center',
          }}
        >
          Feed Content
        </Typography>

        {isFilterType && (
          <FormControlLabel
            control={
              <Switch
                checked={isTextMode}
                onChange={() => setIsTextMode((tm) => !tm)}
              />
            }
            label="Text Mode"
          />
        )}
      </Stack>

      {isTextMode ? (
        <KeyValueFiltersCard
          filterList={keyValueFilters || []}
          changeFilterList={handleKeyValueFiltersChange}
        />
      ) : (
        <UiFiltersCard
          filterList={
            getUiFiltersListFromKeyValueFiltersList(keyValueFilters || []) || []
          }
          changeFilterList={handleUiFiltersChange}
          attributes={attributeOptions}
          operators={operators || []}
        />
      )}
    </>
  );
}
