import useSWR, { mutate as globalMutate } from 'swr';
import { PaginatedResponse } from '../types/PaginatedResponse';
import { SearchParams } from '../types/SearchParams';
import { BracketFilterParams } from '../types/bracketFilterParams';
import {
  Collection,
  CollectionDetails,
  CollectionReview,
} from '../types/collection';
import { Fetch } from '../types/fetch';
import { NFT } from '../types/nft';
import { User } from '../types/users';
import generateSearchRequest from '../utils/generateSearchRequest';
import { parseSearchParams } from '../utils/parseSearchParams';
import getAPIClient from './api/axios.config';

/**
 * Fetches a list of collections
 * @param {BracketFilterParams} filter - Object that contains the bracket filter parameters
 * @return {Fetch<Collection[]>} returns a list of collections that follows swr's fetch format plus the total count of collections
 */
export const fetchCollections = (
  filter: BracketFilterParams,
  reviewStatus = 'all',
  shouldNotFetch = false
): Fetch<Collection[]> => {
  const statusFilter =
    reviewStatus === 'all' ? '' : `&filter[reviewStatus]=${reviewStatus}`;

  const { data, error } = useSWR<PaginatedResponse<Collection[]>, Error>(
    shouldNotFetch
      ? null
      : `admin/collections/${generateSearchRequest(filter)}${statusFilter}`
  );

  return {
    data: data?.body,
    total: data?.meta.totalCount,
    loading: !error && !data,
    error: error?.message,
  };
};

/**
 * Fetches a collection
 * @param {string} uuid - The collection's uuid
 * @returns {Fetch<Collection>} returns a collection that follows swr's fetch format
 */
export const fetchCollection = (
  uuid: string | null
): Fetch<Collection<User, NFT>> => {
  const { data, error, mutate } = useSWR<Collection, Error>(
    uuid ? `admin/collections/${uuid}` : null
  );
  return {
    data,
    loading: !error && !data,
    error: error?.message,
    mutate,
  };
};

export const fetchCollectionsFromUser = (
  userId: string,
  queryParams: SearchParams
): Fetch<Collection[]> => {
  const parsedParams = parseSearchParams(queryParams);
  const { data, error } = useSWR<Collection[]>(
    `admin/users/${userId}/collections?${parsedParams}`
  );

  return {
    data,
    loading: !error && !data,
    error: error?.message,
  };
};

export const fetchCollectionWithoutSWR = async (uuid: string) => {
  const api = await getAPIClient();

  const { data } = await api.get<Collection>(`admin/collections/${uuid}`);

  return data;
};

export const fetchCollectionDetails = (uuid: string) => {
  const response = useSWR<CollectionDetails>(
    `admin/collections/${uuid}/details`
  );
  return response;
};

/**
 * Review a Collection
 * @param {string} uuid - The collection's uuid
 * @param {CollectionReview} reviewValues - The review values
 */
export const reviewCollection = async (
  uuid: string,
  reviewValues: CollectionReview
) => {
  const api = await getAPIClient();

  const res = await api.patch(`admin/collections/${uuid}/review`, reviewValues);
  globalMutate(`admin/collections/${uuid}`);

  return res;
};

export const createOrUpdateCollection = async (
  collection: Partial<Collection>
) => {
  const api = await getAPIClient();

  const res = await api.post('admin/collections', collection);

  globalMutate(`admin/collections/${collection.uuid}`);
  return res;
};

export const deleteCollection = async (uuid: string) => {
  const api = await getAPIClient();

  const res = await api.delete(`admin/collections/${uuid}`);

  return res;
};

export const addCollectionsToOpenSea = async (
  slugs: string[],
  network: 'matic' | 'ethereum' | 'goerli'
) => {
  const api = await getAPIClient();

  const res = await api.post(
    `admin/collections/add-collections-to-import-from-opensea-queue`,
    {
      slugs,
      network,
    }
  );

  return res;
};
