import useURLSearchParams from 'src/lib/hooks/useURLSearchParams';
import useModalStore from 'src/lib/stores/useModalStore';
import { IncludeExcludeAll } from 'src/lib/utils/includeExcludeFilterUtils';
import UserRankFilter from '../../../components/Rankings/BuyerAndSellerRankTable/Filter';
import {
  NFTOriginFilterValue,
  RankByFilterValue,
  SellerRankByFilterValue,
} from '../../../components/Rankings/BuyerAndSellerRankTable/types';
import {
  INITIAL_FILTERS,
  INITIAL_SEARCH_PARAMS_STATE,
} from '../../../constants/SellersRanking';
import {
  NFTOrigin,
  RankBy,
  fetchSellersRank,
} from '../../../services/fetchSellersRanking';
import { UserType } from '../../../types/UserType';
import { valueIfDifferentOf } from '../../../utils/valueIfDifferentOf';

export default function useUserRankTopSellersView() {
  const { allSearchParams } = useURLSearchParams(INITIAL_SEARCH_PARAMS_STATE);
  const openModal = useModalStore((state) => state.openModal);
  const { data, isLoading } = fetchSellersRank({
    page: allSearchParams.page,
    size: allSearchParams.size,
    rankBy: allSearchParams.rankBy as RankBy,
    userType: valueIfDifferentOf(allSearchParams.userType as UserType, 'all'),
    filter: {
      NftPack: allSearchParams.NftPack as IncludeExcludeAll,
      FreePurchases: allSearchParams.FreePurchases as IncludeExcludeAll,
      InactiveUsers: allSearchParams.InactiveUsers as IncludeExcludeAll,
      country: allSearchParams.country,
      lang: allSearchParams.lang,
      nftOrigin:
        allSearchParams.nftOrigin && allSearchParams.nftOrigin !== 'all'
          ? (allSearchParams.nftOrigin as NFTOrigin)
          : undefined,
      endDate: allSearchParams.endDate
        ? `${allSearchParams.endDate}T23:59:59.000Z`
        : undefined,
      startDate: allSearchParams.startDate
        ? `${allSearchParams.startDate}T00:00:00.000Z`
        : undefined,
    },
  });

  function handleOpenFilter(): void {
    const rankFields: SellerRankByFilterValue[] = [
      'averageSalesPrice',
      'sales',
      'volume',
    ];
    openModal(
      <UserRankFilter
        rankFields={rankFields}
        initialValues={{
          startDate: allSearchParams.startDate,
          endDate: allSearchParams.endDate,
          lang: allSearchParams.lang,
          country: allSearchParams.country,
          nftOrigin: allSearchParams.nftOrigin as NFTOriginFilterValue,
          rankBy: allSearchParams.rankBy as RankByFilterValue,
          NftPack: allSearchParams.NftPack as IncludeExcludeAll,
          FreePurchases: allSearchParams.FreePurchases as IncludeExcludeAll,
          InactiveUsers: allSearchParams.InactiveUsers as IncludeExcludeAll,
          userType: allSearchParams.userType as UserType,
        }}
        defaultFilterValues={INITIAL_FILTERS}
      />
    );
  }

  return {
    data,
    isLoading,
    handleOpenFilter,
    currentPage: Number(
      allSearchParams.page ?? INITIAL_SEARCH_PARAMS_STATE.page
    ),
    currentSize: Number(
      allSearchParams.size ?? INITIAL_SEARCH_PARAMS_STATE.size
    ),
    currentRankBy: allSearchParams.rankBy ?? INITIAL_FILTERS.rankBy,
  };
}
