import { User } from '../types/users';
import { getTruncatedText } from './formatters';

const getUserName = (user: Partial<User>): string => {
  if (user?.slug) return `@${user.slug}`;
  if (user?.name) return user.name;
  if (user?.email) return user.email;
  if (user?.uuid) return getTruncatedText(user.uuid, 5, 4);
  return 'empty';
};

export default getUserName;
