import ConfirmationDialogRaw from 'src/components/shared/Dialog/ConfirmationDialog';
import useModalStore from 'src/lib/stores/useModalStore';
import { ExternalAssetsCreationData } from '../../services/createClub';
import useDeleteExternalAsset from './useView';

type Props = {
  clubUuid: string;
  assetToRemove: ExternalAssetsCreationData;
};
export default function DeleteExternalAssetView({
  clubUuid,
  assetToRemove,
}: Props): JSX.Element {
  const { handleDeleteExternalAsset } = useDeleteExternalAsset();
  const closeModal = useModalStore((state) => state.closeModal);

  return (
    <ConfirmationDialogRaw
      onClose={closeModal}
      open
      title={`Delete this Asset`}
      onConfirm={async () => {
        await handleDeleteExternalAsset(clubUuid, assetToRemove);
      }}
    >
      Are you sure you want to remove this Asset?
    </ConfirmationDialogRaw>
  );
}
