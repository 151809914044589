import { GameCategory } from './gameCategory';
import { GameMode } from './gameMode';
import { GameTag } from './gameTag';
import { GameVersion } from './gameVersion';
import { User } from './users';

export type Game = {
  uuid: string;
  name: string;
  description: string;
  versions?: GameVersion[];
  status: GameStatus;
  createdAt: Date;
  updatedAt: Date;
  developerName: string;

  gamePublisherUuid?: string;
  publisher?: GamePublisher;

  supportingDocumentUrl?: string;
  copyrightLicense?: string;

  categories?: GameCategory[];
  modes?: GameMode[];
  tags?: GameTag[];

  // Available from endpoint GET /admin/game (lists several games) and GET /admin/game/{uuid} (get a single game)
  modesStrings?: string[];
  categoriesStrings?: string[];
  tagsStrings?: string[];

  isMobile: boolean;
  orientation: GameOrientations;
  publishRightsType: GamePublishRights;
  secondaryLink: string;
  hasAgreedTerms: boolean;
  owner?: User;

  promptDialogUuid?: string;
  isAi: boolean;
  hasEditInCode: boolean;
  aiModel: string;
  aiDescription: string;
};

export type WithGame<T> = {
  game: Game;
} & T;

export enum GameStatus {
  ACTIVE = 'active',
  INACTIVE = 'inactive',
}

export enum GameOrientations {
  HORIZONTAL = 'horizontal',
  VERTICAL = 'vertical',
  AUTOMATIC = 'automatic',
}

export enum GamePublishRights {
  DEVELOPER = 'developer',
  HAVE_RIGHTS = 'haveRights',
  OPEN_SOURCE = 'openSource',
}

export type GamePublisher = {
  uuid: string;
  name: string;
  url: string;
  createdAt: Date;
  updatedAt: Date;
};

export enum AgeRestrictions {
  FOUR_PLUS = '4',
  NINE_PLUS = '9',
  TWELVE_PLUS = '12',
  SEVENTEEN_PLUS = '17',
}

/* age Rating
4+
9+
12+
17+ */
