import { GameTag } from 'src/lib/types/gameTag';
import CreateGameTagForm from '../../components/Forms/Create';

export default function CreateGameTag({
  gameTag,
}: {
  gameTag?: GameTag;
}): JSX.Element {
  return <CreateGameTagForm gameTag={gameTag} />;
}
