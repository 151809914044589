import { DeepPartial } from 'react-hook-form';
import { SearchAnalyticsRow } from 'src/lib/types/UserSearch';
import { parseSearchParams } from 'src/lib/utils/parseSearchParams';
import useSWR, { SWRResponse } from 'swr';

export type PossibleParams = {
  page: number | string;
  size: number | string;
  filter: {
    createdAt: {
      gte: string;
      lte: string;
    };
  };
  order: {
    [key: string]: 'asc' | 'desc';
  };
};

export type SearchesAnalyticsResponse = {
  meta: { totalCount: number };
  body: SearchAnalyticsRow[];
};

export const fetchSearchesAnalytics = (
  params: DeepPartial<PossibleParams>
): SWRResponse<SearchesAnalyticsResponse, Error> => {
  const queryString = parseSearchParams(params ?? {});

  const response = useSWR<SearchesAnalyticsResponse, Error>(
    `admin/user-search/analytics?${queryString}`
  );

  return response;
};
