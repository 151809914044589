import { Card, Tab, Tabs } from '@mui/material';
import PaymentsTable from 'src/components/modules/Payments/Components/PaymentsTable/PaymentsTable';
import PaymentsTableFooter from 'src/components/modules/Payments/Components/PaymentsTable/PaymentsTableFooter';
import Scrollbar from 'src/template/components/Scrollbar';
import { ProfileTabProps } from '../../profile.types';
import usePaymentHistory from './useView';

const STATUS_TABS = [
  { value: 'all', label: 'All' },
  { value: 'requested', label: 'Requested' },
  { value: 'scheduled', label: 'Scheduled' },
  { value: 'executed', label: 'Executed' },
  { value: 'rejected', label: 'Rejected' },
  { value: 'canceled', label: 'Canceled' },
];

export default function UserPaymentHistory({ user }: ProfileTabProps) {
  const {
    status,
    onChangeReviewStatus,
    filter,
    setSearchFor,
    setQuery,
    paymentRequests,
    total,
  } = usePaymentHistory(user.uuid);

  return (
    <Card>
      <Tabs
        allowScrollButtonsMobile
        variant="scrollable"
        scrollButtons="auto"
        value={status}
        onChange={onChangeReviewStatus}
        sx={{ px: 2, bgcolor: 'background.neutral', mb: 2 }}
      >
        {STATUS_TABS.map((tab) => (
          <Tab
            disableRipple
            key={tab.value}
            label={tab.label}
            value={tab.value}
          />
        ))}
      </Tabs>

      <Scrollbar>
        <PaymentsTable data={paymentRequests} />
      </Scrollbar>

      <PaymentsTableFooter
        total={total || 0}
        page={filter.page}
        rowsPerPage={filter.size}
      />
    </Card>
  );
}
