import { AppVideoFeed } from './appVideoFeed';
import { Channel, ChannelWithAllInfo } from './channel';
import {
  ChannelContent,
  ChannelContentWithIncludedModels,
} from './channelContent';
import { Club } from './club';
import { Collection } from './collection';
import { Game } from './game';
import { NFT } from './nft';
import { User } from './users';

export type FeedItem = {
  uuid: string;
  numericId: number;

  title: string | null;
  titleUrl: string | null;

  createdByUserUuid: string | null; //* Id do usuário que criou
  createdByUser?: User | null; //* Usuário que criou

  description: string | null;
  internalName: string | null; //* Campo interno, útil para visualizações no admin

  contentUrl: string; //* Alguma URL que aponta para conteúdo estático. Ex: imagem, vídeo, etc.
  contentType: FeedItemContentTypes; //* Diz qual tipo de conteúdo está definido no contentUrl
  videoContent?: any;
  imageContent?: any;

  primaryAction: FeedItemActions; //* É o texto de um botão de ação. Caso seja navigation, o front olha para o itemData
  secondaryAction: FeedItemActions | null; //* É o texto de um botão de ação. Caso seja navigation, o front olha para o itemData

  itemType: FeedItemTypes | null; //* Aponta para alguma tabela do banco, ou nenhuma.
  itemUuid: string | null; //* Quando itemType está preenchido, é o ID da linha do banco.
  itemData: Record<string, any> | null; //* é livre para botarmos o que quisermos no JSON. Útil para navegação, por exemplo.

  pageType: FeedItemPageTypes | null; //* Caso seja uma linha que redireciona para outra tela, é preenchido com a tela que irá redirecionar.

  viewOrder: number; //* Coluna numérica para controlar ordenação na listagem
  requiresStickyPro: boolean;

  status: FeedItemStatus;

  createdAt: Date;
  updatedAt: Date;
  deletedAt: Date | null;

  //* Possible join columns
  nft?: NFT;
  game?: Game;
  collection?: Collection;
  club?: Club;
  appVideoFeed?: AppVideoFeed;
  channel?: Channel;
};

export type FeedItemWithViewsCount = FeedItem & {
  views?: number;
};

export type FeedItemWithAnalytics = FeedItemWithViewsCount & {
  secondsWatched?: number;
  uniqueUsers?: number;
  clicks?: number;
};

export type FeedItemWithAnalyticsFeed = FeedItemWithAnalytics & {
  appVideoFeed: AppVideoFeed;
};

export enum FeedItemStatus {
  ACTIVE = 'active',
  INACTIVE = 'inactive',
  DELETED = 'deleted',
}

export enum FeedItemTypes {
  app = 'app',
  club = 'club',
  collection = 'collection',
  game = 'game',
  nft = 'nft',
}

export enum FeedItemActions {
  play = 'play',
  create = 'create',
  about = 'about',
  navigation = 'navigation',
}

export enum FeedItemContentTypes {
  image = 'image',
  video = 'video',
}

export enum FeedItemPageTypes {
  home = 'home',
  explore = 'explore',
}

export type FeedItemAnalyticsTableRow = FeedItem & {
  creatorChannelContent?: ChannelContent;
  game?: Game;
  nft?: NFT;
  collection?: Collection;
  views: number;
  uniqueUsersViewed: number;
  clicks: number;
  averagePositionOnFeed: number;
  clicksPerUser: number;
  status: FeedItemStatus;
  secondsWatched: number;
};

export type FeedItemWithChannelAndChannelContent = FeedItem & {
  channel: ChannelWithAllInfo;
  creatorChannel: ChannelWithAllInfo;
  channelContent: ChannelContentWithIncludedModels;
};
