import { DeepPartial } from 'react-hook-form';
import { PaginatedResponse } from 'src/lib/types/PaginatedResponse';
import {
  BuyerWithRank,
  ParsedBuyerWithRank,
  SellerBuyerTotalStatistics,
} from 'src/lib/types/UserRank';
import { formatFractionNumber } from 'src/lib/utils/formatFractionNumber';
import formatStickyCoinValue from 'src/lib/utils/formatStickyCoinValue';
import { IncludeExcludeAll } from 'src/lib/utils/includeExcludeFilterUtils';
import { parseSearchParams } from 'src/lib/utils/parseSearchParams';
import useSWR, { SWRResponse } from 'swr';
import { UserType } from '../types/UserType';

const formatter = Intl.NumberFormat('en-US');

function parseData(
  data:
    | PaginatedResponse<BuyerWithRank[], SellerBuyerTotalStatistics>
    | undefined
):
  | PaginatedResponse<ParsedBuyerWithRank[], SellerBuyerTotalStatistics>
  | undefined {
  if (!data) return undefined;
  return {
    ...data,
    body: data.body.map((seller) => ({
      ...seller,
      volume: formatter.format(formatStickyCoinValue(seller.volume)),
      purchases: formatter.format(seller.purchases),
      averagePurchasePrice: formatFractionNumber(
        formatStickyCoinValue(seller.averagePurchasePrice)
      ),
    })),
  };
}

export type RankBy = 'volume' | 'averagePurchasePrice' | 'purchases';
export type NFTOrigin = 'created' | 'purchased' | 'imported' | 'transferred';
type PossibleFilters = {
  rankBy: RankBy;
  size: number | string;
  page: number | string;
  userType: UserType;
  filter: {
    FreePurchases: IncludeExcludeAll;
    InactiveUsers: IncludeExcludeAll;
    NftPack: IncludeExcludeAll;
    startDate: string;
    endDate: string;
    lang: string;
    country: string;
    nftOrigin: NFTOrigin;
  };
};

export function fetchBuyersRank(
  params?: DeepPartial<PossibleFilters>
): SWRResponse<
  PaginatedResponse<ParsedBuyerWithRank[], SellerBuyerTotalStatistics>
> {
  const parsedParams = parseSearchParams(params ?? {});
  const { data, ...response } = useSWR(
    `admin/users/ranking/buyer?${parsedParams}`
  );

  return {
    ...response,
    data: parseData(data),
  };
}
