import { Card, CardContent, CardHeader, Stack } from '@mui/material';
import { Navigate, useParams } from 'react-router';
import SectionDivider from 'src/components/shared/SectionDivider';
import {
  fetchOriginalTransactionIdRefunds,
  fetchUserRefunds,
} from 'src/lib/services/refunds/refunds';
import HeaderBreadcrumbs from 'src/template/components/HeaderBreadcrumbs';

import { useMemo } from 'react';

import Center from 'src/components/shared/Center';
import Loader from 'src/components/shared/Loader';
import NumericTypography from 'src/components/shared/NumericTypography';
import SimpleTable from 'src/components/shared/SimpleTable/SimpleTable';
import { EntityNoteTypes } from 'src/lib/types/entityNote';
import { formatUTCDate } from 'src/lib/utils/formatUTCDate';
import { OrderQuery } from 'src/lib/utils/query/types';
import { usePersistentQueryParams } from 'src/lib/utils/query/usePersistentQueryParams';
import EntityNotesTable from '../EntityNotes/components/EntityNotesTable/EntityNotesTable';
import { useEntityNotesTable } from '../EntityNotes/components/EntityNotesTable/useEntityNotesTable';
import RefundsTable from './RefundsTable';

export default function Refund() {
  const { originalTransactionId, userId } = useParams<{
    originalTransactionId: string;
    userId: string;
  }>();

  if (!originalTransactionId) return <Navigate to="/refunds" replace />;

  const {
    query: { order, orderBy },
    updateQuery,
  } = usePersistentQueryParams<OrderQuery>({
    order: 'desc',
    orderBy: 'eventDate',
  });

  const {
    data: userRefunds,
    loading: isLoadingUserRefunds,
    total: userRefundsTotal,
  } = userId
    ? fetchUserRefunds(userId, {
        order,
        orderBy,
      })
    : {
        data: undefined,
        loading: undefined,
        total: 0,
      };

  const {
    data: originalTransactionIdRefund,
    loading: isLoadingOriginalTransactionIdRefunds,
  } = fetchOriginalTransactionIdRefunds(originalTransactionId, {
    order,
    orderBy,
  });

  const refund = useMemo(
    () =>
      originalTransactionIdRefund?.find(
        (r) => r.originalTransactionId === originalTransactionId
      ),
    [originalTransactionId, originalTransactionIdRefund]
  );

  const {
    data: entityNotesData,
    isLoading: entityNotesIsLoading,
    params: entityNotesParams,
    handleUpdateEntityNote,
    handleDeleteEntityNote,
    handleCreateEntityNote,
  } = useEntityNotesTable({ entityUuid: originalTransactionId });

  return (
    <>
      <HeaderBreadcrumbs
        heading="Refund Event Details"
        links={[
          { name: 'Refunds', href: '/refunds' },
          { name: originalTransactionId ?? '-' },
        ]}
      />
      <Stack>
        <Stack direction="row" width="100%" gap={2}>
          <Card sx={{ flex: 1 }}>
            <CardHeader title="Refund Details" />
            <CardContent>
              {isLoadingUserRefunds ||
                (isLoadingOriginalTransactionIdRefunds && (
                  <Center>
                    <Loader />
                  </Center>
                ))}
              {!isLoadingOriginalTransactionIdRefunds && refund && (
                <SimpleTable
                  border
                  rounded
                  data={[
                    {
                      id: 'totalUSD',
                      label: 'Total Refunded ($)',
                      value: (
                        <NumericTypography
                          formatDecimal
                          formatDigits
                          currency="USD"
                        >
                          {-refund.totalUSD}
                        </NumericTypography>
                      ),
                    },
                    {
                      id: 'refundDate',
                      label: 'Refund Date',
                      value: formatUTCDate(refund.eventDate),
                    },
                    {
                      id: 'totalNotifications',
                      label: 'Purchases / Subscription Renew Times',
                      value: refund.totalNotifications,
                    },
                    {
                      id: 'reason',
                      label: 'Reason',
                      value: refund.reason,
                    },
                  ]}
                />
              )}
            </CardContent>
          </Card>
          <Card sx={{ flex: 1 }}>
            <CardHeader title="Original Purchase Details" />
            <CardContent>
              {isLoadingUserRefunds ||
                (isLoadingOriginalTransactionIdRefunds && (
                  <Center>
                    <Loader />
                  </Center>
                ))}
              {!isLoadingUserRefunds && refund && (
                <SimpleTable
                  border
                  rounded
                  data={[
                    {
                      id: 'originalPurchaseDate',
                      label: 'Purchase Date',
                      value: formatUTCDate(refund.originalPurchaseDate),
                    },
                    {
                      id: 'product',
                      label: 'Product',
                      value: refund.product,
                    },
                    {
                      id: 'productType',
                      label: 'Product Type',
                      value: refund.productType,
                    },
                    /* {
                      id: 'provider',
                      label: 'Provider',
                      value: refund.provider,
                    }, */
                    ...(refund.currency
                      ? [
                          {
                            id: 'currency',
                            label: 'Currency',
                            value: refund.currency,
                          },
                        ]
                      : []),
                  ]}
                />
              )}
            </CardContent>
          </Card>
        </Stack>
        {isLoadingUserRefunds !== undefined ? (
          <>
            <SectionDivider section="User Refund Events" />
            <RefundsTable
              loading={isLoadingUserRefunds}
              query={{
                page: 0,
                size: 100,
              }}
              total={userRefundsTotal}
              data={userRefunds}
              hideSearch
              updateQuery={updateQuery}
              highlightRowId={refund?.uuid}
            />
          </>
        ) : null}
        <>
          <SectionDivider section="Entity Notes" />
          <EntityNotesTable
            showSearch={false}
            data={entityNotesData}
            isLoading={entityNotesIsLoading}
            paras={entityNotesParams}
            handleUpdate={handleUpdateEntityNote}
            handleDelete={handleDeleteEntityNote}
            handleCreate={handleCreateEntityNote(
              EntityNoteTypes.REFUND,
              originalTransactionId
            )}
          />
        </>
      </Stack>
    </>
  );
}
