import { Add } from '@mui/icons-material';
import { Button, Container } from '@mui/material';
import HeaderBreadcrumbs from 'src/template/components/HeaderBreadcrumbs';

import GameCategoryTable from '../../components/Table';
import useGameCategory from './useView';

export default function GameCategories() {
  const {
    data,
    isLoading,
    params,
    handleOpenCreateModal,
    handleOpenDeleteModal,
    handleOpenEditModal,
  } = useGameCategory();

  return (
    <Container maxWidth="xl">
      <HeaderBreadcrumbs
        heading="Game Category"
        links={[{ name: 'Game Category' }]}
        action={
          <Button
            variant="contained"
            color="secondary"
            onClick={handleOpenCreateModal}
            startIcon={<Add />}
          >
            Add Game Category
          </Button>
        }
      />

      <GameCategoryTable
        data={data}
        isLoading={isLoading}
        params={params}
        handleOpenDeleteModal={handleOpenDeleteModal}
        onEditClick={handleOpenEditModal}
      />
    </Container>
  );
}
