import { Card, CardContent, CardHeader } from '@mui/material';
import Flex from 'src/components/shared/Flex';
import SimpleTable from 'src/components/shared/SimpleTable/SimpleTable';
import { SGMChatStatistics } from 'src/lib/types/sgmChatMessage';
import { formatDollar } from 'src/lib/utils/formatDollar';
import { formatPercentage } from 'src/lib/utils/formatPercentage';

interface MakerStatsCardInfosProps {
  statsData?: SGMChatStatistics;
}

export default function MakerStatsCardInfos({
  statsData,
}: MakerStatsCardInfosProps) {
  const {
    uniqueChats,
    uniqueUsers,
    avgChatsPerUser,
    totalCost,
    avgCostPerUser,
    avgCostPerChat,

    totalChatMessages,
    totalUserMessages,
    totalIAMessages,
    avgMessagePerChat,
    avgTokensPer4oMessages,
    avgTokensPerO1Messages,
    avgCostPerMessage,

    totalPublished,
    publishedPercentage,
    totalMessagesFromPublishedGamesChats,
    avgMessagePerPublishedGameChat,

    chatsThatUsed4o,
    chatsThatUsedO1,
    total4oMessages,
    totalO1Messages,

    total4oPromptTokens,
    total4oCompletionTokens,
    totalO1PromptTokens,
    totalO1CompletionTokens,
    estimated4oPromptTokensCost,
    estimated4oCompletionTokensCost,
    estimatedO1PromptTokensCost,
    estimatedO1CompletionTokensCost,
    estimated4oCachedTokensCost,
    estimatedO1CachedTokensCost,
    total4oCachedTokens,
    totalO1CachedTokens,
  } = statsData || {};

  return (
    <Flex
      alignItems={'start'}
      justifyContent={'space-evenly'}
      px={3}
      my={2}
      gap={2}
      flexWrap={'wrap'}
    >
      <Card sx={{ flexGrow: 1 }}>
        <Flex justifyContent="space-between" alignItems="center" pr={2}>
          <CardHeader title="General" />
        </Flex>
        <CardContent>
          <SimpleTable
            border
            rounded
            data={[
              { id: 'uniqueChats', label: 'Chats', value: uniqueChats ?? '-' },
              {
                id: 'uniqueUsers',
                label: 'Users',
                value: uniqueUsers ?? '-',
              },
              {
                id: 'avgChatsPerUser',
                label: 'Chat per User',
                value: avgChatsPerUser ?? '-',
              },
              {
                id: 'totalCost',
                label: 'Total Cost',
                value: formatDollar(totalCost),
              },
              {
                id: 'avgCostPerUser',
                label: 'Cost per User',
                value: formatDollar(avgCostPerUser),
              },
              {
                id: 'avgCostPerChat',
                label: 'Cost per Chat',
                value: formatDollar(avgCostPerChat),
              },
            ]}
          />
        </CardContent>
      </Card>
      <Card sx={{ flexGrow: 1 }}>
        <Flex justifyContent="space-between" alignItems="center" pr={2}>
          <CardHeader title="Messages" />
        </Flex>
        <CardContent>
          <SimpleTable
            border
            rounded
            data={[
              {
                id: 'totalMessages',
                label: 'Total Messages',
                value: totalChatMessages ?? '-',
              },
              {
                id: 'totalUserMessages',
                label: 'Sent by User',
                value: totalUserMessages ?? '-',
              },
              {
                id: 'totalIAMessages',
                label: 'Sent by AI',
                value: totalIAMessages ?? '-',
              },
              {
                id: 'avgMessagePerChat',
                label: 'Message per Chat',
                value: avgMessagePerChat ?? '-',
              },
              {
                id: 'avgTokensPer4oMessages',
                label: 'Tokens per gpt-4o message',
                value: avgTokensPer4oMessages ?? '-',
              },
              {
                id: 'avgTokensPerO1Messages',
                label: 'Tokens per o1-preview message',
                value: avgTokensPerO1Messages ?? '-',
              },
              {
                id: 'avgCostPerMessage',
                label: 'Cost per Message',
                value: formatDollar(avgCostPerMessage),
              },
            ]}
          />
        </CardContent>
      </Card>
      <Card sx={{ flexGrow: 1 }}>
        <Flex justifyContent="space-between" alignItems="center" pr={2}>
          <CardHeader title="Published games" />
        </Flex>
        <CardContent>
          <SimpleTable
            border
            rounded
            data={[
              {
                id: 'totalPublished',
                label: ' Total chats published',
                value: totalPublished ?? '-',
              },
              {
                id: 'publishedPercentage',
                label: '% Chats published',
                value: formatPercentage(publishedPercentage),
              },
              {
                id: 'totalMessagesFromPublishedGamesChats',
                label: 'Total Messages (published chats)',
                value: totalMessagesFromPublishedGamesChats ?? '-',
              },
              {
                id: 'avgMessagePerPublishedGameChat',
                label: 'Message per chat (published chats)',
                value: avgMessagePerPublishedGameChat ?? '-',
              },
            ]}
          />
        </CardContent>
      </Card>
      <Card sx={{ flexGrow: 1 }}>
        <Flex justifyContent="space-between" alignItems="center" pr={2}>
          <CardHeader title="AI Model" />
        </Flex>
        <CardContent>
          <SimpleTable
            border
            rounded
            capitalize={false}
            data={[
              {
                id: 'chatsThatUsed4o',
                label: 'Chats That Used gpt-4o',
                tooltip: [
                  'Chats That have at least one AI message using gpt-4o',
                ],
                value: chatsThatUsed4o ?? '-',
              },
              {
                id: 'chatsThatUsedO1',
                label: 'Chats That Used o1-preview',
                tooltip: [
                  'Chats That have at least one AI message using o1-preview',
                ],
                value: chatsThatUsedO1 ?? '-',
              },
              {
                id: 'total4oMessages',
                label: 'AI Messages From gpt-4o',
                value: total4oMessages ?? '-',
              },
              {
                id: 'totalO1Messages',
                label: 'AI Messages From o1-preview',
                value: totalO1Messages ?? '-',
              },
            ]}
          />
        </CardContent>
      </Card>
      <Card sx={{ flexGrow: 1 }}>
        <Flex justifyContent="space-between" alignItems="center" pr={2}>
          <CardHeader title="gpt-4o Tokens" />
        </Flex>
        <CardContent>
          <SimpleTable
            border
            rounded
            capitalize={false}
            data={[
              {
                id: 'total4oPromptTokens',
                label: 'gpt-4o Prompt Tokens',
                value: total4oPromptTokens ?? '-',
              },
              {
                id: 'total4oCompletionTokens',
                label: 'gpt-4o Completion Tokens',
                value: total4oCompletionTokens ?? '-',
              },
              {
                id: 'total4oCachedTokens',
                label: 'gpt-4o Cached Tokens',
                value: total4oCachedTokens ?? '-',
              },
              {
                id: 'estimated4oPromptTokensCost',
                label: 'gpt-4o Prompt Tokens Cost',
                value: formatDollar(estimated4oPromptTokensCost),
              },
              {
                id: 'estimated4oCompletionTokensCost',
                label: 'gpt-4o Completion Tokens Cost',
                value: formatDollar(estimated4oCompletionTokensCost),
              },
              {
                id: 'estimated4oCachedTokensCost',
                label: 'gpt-4o Cached Tokens Cost',
                value: formatDollar(estimated4oCachedTokensCost),
              },
            ]}
          />
        </CardContent>
      </Card>
      <Card sx={{ flexGrow: 1 }}>
        <Flex justifyContent="space-between" alignItems="center" pr={2}>
          <CardHeader title="o1-preview Tokens" />
        </Flex>
        <CardContent>
          <SimpleTable
            border
            rounded
            capitalize={false}
            data={[
              {
                id: 'totalO1PromptTokens',
                label: 'o1-preview Prompt Tokens',
                value: totalO1PromptTokens ?? '-',
              },
              {
                id: 'totalO1CompletionTokens',
                label: 'o1-preview Completion Tokens',
                value: totalO1CompletionTokens ?? '-',
              },
              {
                id: 'totalO1CachedTokens',
                label: 'o1-preview Cached Tokens',
                value: totalO1CachedTokens ?? '-',
              },
              {
                id: 'estimatedO1PromptTokensCost',
                label: 'o1-preview Prompt Tokens Cost',
                value: formatDollar(estimatedO1PromptTokensCost),
              },
              {
                id: 'estimatedO1CompletionTokensCost',
                label: 'o1-preview Completion Tokens Cost',
                value: formatDollar(estimatedO1CompletionTokensCost),
              },
              {
                id: 'estimatedO1CachedTokensCost',
                label: 'o1-preview Cached Tokens Cost',
                value: formatDollar(estimatedO1CachedTokensCost),
              },
            ]}
          />
        </CardContent>
      </Card>
    </Flex>
  );
}
