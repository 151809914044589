import { GridColumns } from '@mui/x-data-grid';
import Link from 'src/components/shared/Link';
import { SearchAnalyticsRow } from 'src/lib/types/UserSearch';
import { formatUTCDate } from 'src/lib/utils/formatUTCDate';
import Routes from 'src/routes/Routes';

export const tableColumns: GridColumns<SearchAnalyticsRow> = [
  {
    field: 'searchTerm',
    headerName: 'Search term',
    sortable: true,
    renderCell({ row }) {
      return (
        <Link
          to={`${Routes.search.searches}?filterSearchBy=rawSearch&filterQuery=${row.searchTerm}`}
        >
          {row.searchTerm}
        </Link>
      );
    },
  },
  {
    field: 'numberOfSearches',
    headerName: 'Search Count',
    sortable: true,
  },
  {
    field: 'uniqueUsers',
    headerName: 'Unique Users',
    sortable: true,
  },
  {
    field: 'firstSearch',
    headerName: 'First Search',
    sortable: true,
    valueFormatter(params) {
      return params.value ? formatUTCDate(params.value) : '-';
    },
  },
  {
    field: 'lastSearch',
    headerName: 'Last Search',
    sortable: true,
    valueFormatter(params) {
      return params.value ? formatUTCDate(params.value) : '-';
    },
  },
];
