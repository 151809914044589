import { Box, Button, Typography } from '@mui/material';
import { useDropzone } from 'react-dropzone';
import Iconify from '../Iconify';
import { UploadProps } from './type';

export default function UploadFileLine({
  error = false,
  file,
  helperText,
  sx,
  ...other
}: UploadProps): JSX.Element {
  const { getInputProps } = useDropzone({
    multiple: false,
    ...other,
  });
  return (
    <>
      <Button component="label">
        <Iconify
          sx={{
            fontSize: 20,
            mr: 1,
          }}
          icon="ant-design:upload-outlined"
        />
        Document
        <input {...getInputProps()} />
      </Button>
      {helperText ? (
        <Typography>{helperText}</Typography>
      ) : (
        file && (
          <Box display="flex" alignItems="center">
            <Iconify icon="ic:baseline-attach-file" />
            <Typography
              sx={{
                ml: 1,
                overflow: 'hidden',
                textOverflow: 'ellipsis',
                whiteSpace: 'nowrap',
              }}
            >
              {typeof file === 'string' ? file : file[0]?.name}
            </Typography>
          </Box>
        )
      )}
    </>
  );
}
