import { Rarity } from 'src/lib/types/nftPack';
import UpdateNftOnPackForm from '../../../components/Forms/UpdateNftOnPack';
import useUpdateNftOnPackView from './useView';

interface UpdateNftOnPackViewProps {
  packUuid: string;
  nftUuid: string;
  rarities: Rarity[];
  currentStatus: { faceValue: number; rarityUuid: string };
}

export default function UpdateNftOnPackView({
  packUuid,
  nftUuid,
  rarities,
  currentStatus,
}: UpdateNftOnPackViewProps): JSX.Element {
  const { handleUpdateNftOnPack } = useUpdateNftOnPackView();
  return (
    <UpdateNftOnPackForm
      onSubmit={async (values) => {
        await handleUpdateNftOnPack({
          packUuid,
          nftUuid,
          rarityUuid: values.rarityUuid,
          faceValue: values.faceValue * 1000,
        });
      }}
      defaultValues={currentStatus}
      title="Update Nft On Pack"
      raritiesOptions={rarities}
    />
  );
}
