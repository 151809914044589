import ConfirmationDialogRaw from 'src/components/shared/Dialog/ConfirmationDialog';
import useModalStore from 'src/lib/stores/useModalStore';
import useDeleteAssetCollection from './useView';

type Props = {
  uuid: string;
};
export default function DeleteAssetCollectionView({
  uuid,
}: Props): JSX.Element {
  const { handleDeleteAssetCollection } = useDeleteAssetCollection();
  const closeModal = useModalStore((state) => state.closeModal);

  return (
    <ConfirmationDialogRaw
      onClose={closeModal}
      open
      title={`Delete this Asset Collection`}
      onConfirm={async () => {
        await handleDeleteAssetCollection(uuid);
      }}
    >
      This action will also delete all the assets in this collection. Are you
      sure you want to delete this Asset Collection?
    </ConfirmationDialogRaw>
  );
}
