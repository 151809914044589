import {
  CircularProgress,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
} from '@mui/material';
import Center from 'src/components/shared/Center';
import { TableHeadCustom } from 'src/template/components/table';
import { IHeadLabel } from 'src/template/components/table/TableHeadCustom';

import { NFT } from 'src/lib/types/nft';
import NftsTableRow from './NftsTableRow';
import useNftsTable from './useNftsTable';

const TABLE_HEAD: IHeadLabel[] = [
  { id: 'name', label: 'NFT' },
  { id: 'active', label: 'Status' },
  {
    id: 'reviewStatus',
    label: 'Review Status',
    align: 'center',
    sortable: false,
  },
  { id: 'visibility', label: 'Visibility', align: 'center', sortable: false },
  { id: 'blockchain', label: 'Blockchain', align: 'center' },
  { id: 'collection', label: 'Collection' },
  { id: 'user.slug', label: 'Creator' },
  { id: 'updatedAt', label: 'Updated', align: 'right' },
  { id: 'createdAt', label: 'Created', align: 'right' },
  { id: 'actions', label: 'Actions', align: 'center' },
];

export default function NftsTable({
  data,
  loading,
  searchHighlight,
}: {
  data: NFT[] | undefined;
  loading: boolean;
  searchHighlight: { searchColumn: string; searchKeyword: string };
}) {
  const [order, orderBy] = useNftsTable((state) => [
    state.filter.order,
    state.filter.orderBy,
  ]);

  const onSort = useNftsTable((state) => state.onSort);

  const dense = useNftsTable((state) => state.dense);

  return (
    <TableContainer sx={{ minWidth: '100%', position: 'relative' }}>
      <Table size={dense ? 'small' : 'medium'}>
        <TableHeadCustom
          order={order}
          orderBy={orderBy}
          headLabel={TABLE_HEAD}
          rowCount={loading ? 0 : data && data.length}
          onSort={onSort}
        />
        <TableBody>
          {loading || !data ? (
            <TableRow>
              <TableCell colSpan={TABLE_HEAD.length + 1}>
                <Center>
                  <CircularProgress />
                </Center>
              </TableCell>
            </TableRow>
          ) : (
            data &&
            data.map((nft: NFT) => (
              <NftsTableRow
                key={nft.uuid}
                nft={nft}
                searchHighlight={searchHighlight}
              />
            ))
          )}
        </TableBody>
      </Table>
    </TableContainer>
  );
}
