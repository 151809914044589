import { yupResolver } from '@hookform/resolvers/yup';
import { Add, Close } from '@mui/icons-material';
import { Alert, Card, CardContent, CardHeader, Stack } from '@mui/material';
import { capitalCase } from 'change-case';
import { useFieldArray, useForm } from 'react-hook-form';
import Button from 'src/components/shared/Buttons/CustomButton';
import Flex from 'src/components/shared/Flex';
import ControllerField from 'src/components/shared/Inputs/ControllerField';
import Modal from 'src/components/shared/Modal';
import SelectOption from 'src/components/shared/SelectOption';
import useModalStore from 'src/lib/stores/useModalStore';
import { PossibleExternalClubAssets } from 'src/lib/types/club';
import * as yup from 'yup';
import { AddAssetsFormValues } from './types';

const validationSchema = yup.object().shape({
  externalAssets: yup.array().of(
    yup.object().shape({
      assetId: yup.string().required('Asset ID is required'),
      assetType: yup.string().required('Asset Type is required'),
    })
  ),
});

type Props = {
  defaultValues: AddAssetsFormValues;
  onSubmit: (values: AddAssetsFormValues) => Promise<void>;
  title: string;
};

export default function AddAssetsForm({
  defaultValues,
  title,
  onSubmit,
}: Props): JSX.Element {
  const onClose = useModalStore((state) => state.closeModal);
  const {
    control,
    handleSubmit,
    formState: { isSubmitting },
    getFieldState,
  } = useForm<AddAssetsFormValues>({
    defaultValues,
    resolver: yupResolver(validationSchema),
  });

  const handleCloseModal = () => {
    onClose();
  };

  // games

  const {
    fields: selectedAssets,
    remove: removeAsset,
    append: appendAsset,
  } = useFieldArray({
    control,
    name: 'externalAssets',
  });

  const handleAddAsset = () => {
    appendAsset({
      assetId: '',
      assetType: '',
    });
  };

  const handleRemoveAsset = (id: string) => {
    const index = selectedAssets.findIndex((asset) => asset.assetId === id);
    removeAsset(index);
  };

  return (
    <Modal open onClose={handleCloseModal}>
      <Card sx={{ width: '600px', display: 'flex', flexDirection: 'column' }}>
        <CardHeader title={title} />
        <CardContent>
          <form onSubmit={handleSubmit(onSubmit)}>
            <Stack>
              {selectedAssets.map((asset, assetIndex) => (
                <Flex key={asset.id} gap={1} alignItems={'flex-start'} mb={2}>
                  <ControllerField
                    control={control}
                    name={`externalAssets.${assetIndex}.assetId`}
                    fullWidth
                    label="Asset ID"
                  />
                  <ControllerField
                    control={control}
                    name={`externalAssets.${assetIndex}.assetType`}
                    label="Asset Type"
                    fullWidth
                    select
                  >
                    {Object.values(PossibleExternalClubAssets).map((option) => (
                      <SelectOption key={option} value={option}>
                        {capitalCase(option)}
                      </SelectOption>
                    ))}
                  </ControllerField>
                  <Button
                    onClick={() => handleRemoveAsset(asset.assetId)}
                    size="large"
                  >
                    <Close />
                  </Button>
                </Flex>
              ))}

              {getFieldState(`externalAssets`).error?.message && (
                <Alert severity="error">
                  {getFieldState(`externalAssets`).error?.message}
                </Alert>
              )}

              <Button color="success" onClick={handleAddAsset}>
                Add Asset
                <Add />
              </Button>
            </Stack>
            <Stack>
              <Stack
                direction="row"
                justifyContent="space-between"
                spacing={1}
                mt={2}
              >
                <Button color="error" onClick={handleCloseModal} size="large">
                  Cancel
                </Button>

                <Button
                  variant="contained"
                  color="secondary"
                  type="submit"
                  loading={isSubmitting}
                  size="large"
                >
                  Save
                </Button>
              </Stack>
            </Stack>
          </form>
        </CardContent>
      </Card>
    </Modal>
  );
}
