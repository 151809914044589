import { Card, CardContent, debounce } from '@mui/material';
import { GridSortDirection } from '@mui/x-data-grid';
import { ParsedGameStatistic } from 'src/lib/types/gameStatistic';
import { StatisticToAdd } from '../../components/Forms/StatisticGroup/types';
import AddStatisticsTable from '../../components/addStatisticsTable';
import useSelectAssetsForContest from './useView';

const searchOptions = [
  {
    label: 'Name',
    query: 'name',
  },
];

interface SelectStatisticsForGroupProps {
  handleAddStatistic: (statistic: ParsedGameStatistic) => void;
  handleRemoveStatistic: (uuid: string) => void;
  selectedStatistics: StatisticToAdd[];
  gameUuid: string;
}

export default function SelectStatisticsForGroup({
  handleAddStatistic,
  handleRemoveStatistic,
  selectedStatistics,
  gameUuid,
}: SelectStatisticsForGroupProps): JSX.Element {
  const {
    data,
    isLoading,
    order,
    page,
    query,
    searchBy,
    size,
    orderBy,
    setOrder,
    setPage,
    setRowsPerPage,
    setQuery,
    setSearchBy,
    setOrderBy,
  } = useSelectAssetsForContest(gameUuid);

  function handleChangeSearch(value: string) {
    setQuery(value);
  }

  const debouncedSearch = debounce(handleChangeSearch, 300);

  return (
    <Card>
      {/* ... 
      <Flex gap={2} p={2}>
        <Autocomplete
          disableClearable
          options={searchOptions}
          fullWidth
          renderInput={(params) => <TextField label="Search By" {...params} />}
          getOptionLabel={(p) => p.label}
          sx={{ maxWidth: 250 }}
          defaultValue={searchOptions.find((o) => o.query === searchBy)}
          key={searchBy}
          onChange={(_, value) => {
            setSearchBy(value?.query ?? '');
          }}
        />

        <TextField
          fullWidth
          label="Search"
          defaultValue={query}
          type="search"
          onChange={(e) => {
            const { value } = e.target;
            debouncedSearch(value);
          }}
          key={query}
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <Iconify
                  icon="eva:search-fill"
                  sx={{ color: 'text.disabled', width: 20, height: 20 }}
                />
              </InputAdornment>
            ),
          }}
        />
      </Flex>
      */}

      <CardContent>
        <AddStatisticsTable
          data={data ?? []}
          isLoading={isLoading}
          page={page}
          size={size}
          sortBy={orderBy}
          order={order as GridSortDirection}
          setOrder={setOrder as (order: GridSortDirection) => void}
          setSortBy={setOrderBy}
          setPage={setPage}
          setRowsPerPage={setRowsPerPage}
          handleAddStatistic={handleAddStatistic}
          handleRemoveStatistic={handleRemoveStatistic}
          selectedStatistics={selectedStatistics}
        />
      </CardContent>
    </Card>
  );
}
