import { TextField, TextFieldProps } from '@mui/material';
import { useEffect, useState } from 'react';
import useDebounce from 'src/lib/hooks/useDebounce';

type DebouncedFieldProps = TextFieldProps & {
  onDebounceChange: (value: string) => void;
};
export default function DebouncedField({
  onDebounceChange,
  ...rest
}: DebouncedFieldProps): JSX.Element {
  const [fieldValue, setfieldValue] = useState('');
  const debouncedValue = useDebounce(fieldValue, 500);

  useEffect(() => {
    onDebounceChange(debouncedValue);
  }, [debouncedValue]);

  return (
    <TextField
      value={fieldValue}
      onChange={(e) => setfieldValue(e.target.value)}
      {...rest}
    />
  );
}
