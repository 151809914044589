import { ContentCopy, Delete, Edit } from '@mui/icons-material';
import { Avatar, MenuItem, Stack } from '@mui/material';
import { GridSortDirection } from '@mui/x-data-grid';
import CopyToClipboard from 'react-copy-to-clipboard';
import Flex from 'src/components/shared/Flex';
import Link from 'src/components/shared/Link';
import MagicTable from 'src/components/shared/MagicTable/MagicTable';
import MoreMenu from 'src/components/shared/MoreMenu';
import useURLSearchParams from 'src/lib/hooks/useURLSearchParams';
import { GameMakerAssetCollection } from 'src/lib/types/gameMakerAsset';
import { formatUTCDate } from 'src/lib/utils/formatUTCDate';
import Routes from 'src/routes/Routes';
import useAssetCollectionTable from './useView';

type AssetCollectionTableQueryParams = {
  page: number;
  size: number;
  orderBy: string;
  sortOrder: string;
};

type AssetCollectionTableProps = {
  data?: GameMakerAssetCollection[];
  totalCount?: number;
  isLoading: boolean;
  params: AssetCollectionTableQueryParams;
};

export default function AssetCollectionTable({
  data,
  isLoading,
  params: { page, size, orderBy, sortOrder },
  totalCount,
}: AssetCollectionTableProps) {
  const { addParam, removeParam } = useURLSearchParams();
  const { handleOpenDeleteModal, handleOpenUpdateModal } =
    useAssetCollectionTable();

  return (
    <MagicTable
      rowHeight={64}
      loading={isLoading}
      pageSize={size}
      page={page}
      paginationMode="server"
      onPageChange={(newPage) => {
        addParam('page', newPage);
      }}
      onPageSizeChange={(newSize) => {
        addParam('size', newSize);
      }}
      onSortModelChange={(model) => {
        const currentModel = model[0];
        if (currentModel) {
          addParam('orderBy', currentModel.field);
          if (currentModel.sort) addParam('order', currentModel.sort);
        } else {
          removeParam('order');
          removeParam('orderBy');
        }
      }}
      sortModel={[
        {
          field: orderBy,
          sort: sortOrder as GridSortDirection,
        },
      ]}
      rows={data ?? []}
      rowCount={totalCount ?? undefined}
      columns={[
        {
          field: 'name',
          headerName: 'Name',
          renderCell({ row }) {
            return (
              <Flex component="span" gap={2}>
                <Avatar
                  alt={row.name ?? 'Asset Collection'}
                  src={row.assets?.[0]?.url ?? ''}
                />
                <Stack>
                  <Link
                    to={Routes.sgm.assetCollection(row.uuid)}
                    color={row.name ? 'inherit' : 'GrayText'}
                    underline="hover"
                  >
                    {row.name}
                  </Link>
                </Stack>
              </Flex>
            );
          },
        },
        {
          field: 'assetsCount',
          headerName: 'Assets Count',
          flex: 1 / 2,
        },
        {
          field: 'description',
          headerName: 'Description',
          valueFormatter({ value }) {
            return value || '-';
          },
        },
        {
          field: 'updatedAt',
          headerName: 'Updated At',
          sortingOrder: ['desc', 'asc'],
          flex: 1 / 2,
          valueFormatter({ value }) {
            return value ? formatUTCDate(value) : '-';
          },
        },
        {
          field: 'createdAt',
          headerName: 'Created At',
          sortingOrder: ['desc', 'asc'],
          flex: 1 / 2,
          valueFormatter({ value }) {
            return value ? formatUTCDate(value) : '-';
          },
        },
        {
          field: 'actions',
          headerName: 'Actions',
          align: 'center',
          sortable: false,
          flex: 0,
          renderCell({ row }) {
            return (
              <MoreMenu>
                <CopyToClipboard text={row.uuid}>
                  <MenuItem aria-label="copy feed uuid">
                    <ContentCopy />
                    Copy UUID
                  </MenuItem>
                </CopyToClipboard>
                <MenuItem
                  onClick={() => {
                    handleOpenUpdateModal(row);
                  }}
                >
                  <Edit />
                  Update
                </MenuItem>
                <MenuItem
                  onClick={() => {
                    handleOpenDeleteModal(row.uuid);
                  }}
                >
                  <Delete />
                  Delete
                </MenuItem>
              </MoreMenu>
            );
          },
        },
      ]}
    />
  );
}
