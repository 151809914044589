import { DeepPartial } from 'react-hook-form';
import { UserSearch } from 'src/lib/types/UserSearch';
import { parseSearchParams } from 'src/lib/utils/parseSearchParams';
import useSWR, { SWRResponse } from 'swr';

export type PossibleParams = {
  page: number | string;
  size: number | string;
  filter: {
    createdAt: {
      gte: string;
      lte: string;
    };
  };
  order: {
    [key: string]: 'asc' | 'desc';
  };
};

export type UserSearchesResponse = {
  meta: { totalCount: number };
  body: UserSearch[];
};

export const fetchUserSearches = (
  params: DeepPartial<PossibleParams>
): SWRResponse<UserSearchesResponse, Error> => {
  const queryString = parseSearchParams(params ?? {});

  const response = useSWR<UserSearchesResponse, Error>(
    `admin/user-search?${queryString}`
  );

  return response;
};
