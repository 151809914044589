import GameAssets from 'src/components/modules/GameAssets/view/ListOnGame';
import Page from 'src/template/components/Page';

export default function ContestAssetsPage() {
  return (
    <Page title="Constest Assets">
      <GameAssets />
    </Page>
  );
}
