import { Container } from '@mui/material';
import ChannelDetails from 'src/components/modules/Channels/view/Details';
import Page from 'src/template/components/Page';

export default function ChannelPage() {
  return (
    <Page title="Channel">
      <Container maxWidth="xl">
        <ChannelDetails />
      </Container>
    </Page>
  );
}
