import {
  Card,
  CircularProgress,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
} from '@mui/material';
import { useNavigate } from 'react-router';
import Center from 'src/components/shared/Center';
import Link from 'src/components/shared/Link';
import NumericTypography from 'src/components/shared/NumericTypography';
import { FetchRefundsQueryParams } from 'src/lib/services/refunds/types';
import { MostRefundedUsersByQuantityResponse } from 'src/lib/types/revenueEvent';
import Label from 'src/template/components/Label';
import { TableHeadCustom } from 'src/template/components/table';
import MostRefundedUsersTableFooter from './MostRefundedUsersTabletFooter';
import { refundedUsersTableColumns } from './refundedUsersTableColumns';

type RefundedUsersTableProps = {
  data?: MostRefundedUsersByQuantityResponse;
  query: Partial<FetchRefundsQueryParams>;
  updateQuery: (values: Partial<FetchRefundsQueryParams>) => void;
};

export default function RefundedUsersTable({
  data,
  query: { page, size },
  updateQuery,
}: RefundedUsersTableProps) {
  const navigate = useNavigate();

  return (
    <Card sx={{ py: '0.5rem' }}>
      <TableContainer sx={{ minWidth: '100%', position: 'relative' }}>
        <Table>
          <TableHeadCustom headLabel={refundedUsersTableColumns} />
          <TableBody>
            {data ? (
              data.rows.map((row) => (
                <TableRow
                  key={row.userUuid}
                  hover
                  style={{
                    cursor: 'pointer',
                  }}
                  onClick={(e) => {
                    e.stopPropagation();
                    navigate(`/users/${row.userUuid}?tab=Transactions+History`);
                  }}
                  onAuxClick={(e) => {
                    e.stopPropagation();
                    window.open(
                      `/users/${row.userUuid}?tab=Transactions+History`,
                      '_blank',
                      'rel=noopener noreferrer'
                    );
                  }}
                >
                  <TableCell>
                    {row.userUuid ? (
                      <Link to={`/users/${row.userUuid}`}>
                        {row.slug ?? row.name ?? row.email ?? row.userUuid}
                      </Link>
                    ) : (
                      'null'
                    )}
                  </TableCell>
                  <TableCell align="center">
                    {row.userUuid ? (
                      row.active ? (
                        <Label color="success">active</Label>
                      ) : (
                        <Label color="error">blocked</Label>
                      )
                    ) : (
                      <Label>empty</Label>
                    )}
                  </TableCell>
                  <TableCell align="center">{row.refunds}</TableCell>
                  <TableCell align="center">{row.refund_denied}</TableCell>
                  <TableCell align="right">
                    <NumericTypography
                      formatDigits
                      formatDecimal
                      currency="USD"
                      fontWeight="normal"
                      fontSize="14px"
                    >
                      {-row.total_refunded}
                    </NumericTypography>
                  </TableCell>
                </TableRow>
              ))
            ) : (
              <TableRow>
                <TableCell colSpan={refundedUsersTableColumns.length}>
                  <Center>
                    <CircularProgress />
                  </Center>
                </TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
        <MostRefundedUsersTableFooter
          currentPage={page ?? 0}
          rowsPerPage={size ?? 50}
          rowsRendered={data?.rows.length ?? 0}
          onChangeRowsPerPage={updateQuery}
        />
      </TableContainer>
    </Card>
  );
}
