import { Container } from '@mui/material';
import HeaderBreadcrumbs from 'src/template/components/HeaderBreadcrumbs';
import GamesStatsTable from '../../components/GamesStatsTable/GamesStatsTable';
import useGamesStats from './useView';

export default function GamesStats() {
  const { data, isLoading, params, generalStatsData } = useGamesStats();
  return (
    <Container maxWidth="xl">
      <HeaderBreadcrumbs
        heading="Games Analytics"
        links={[{ name: 'Games Analytics' }]}
      />
      <GamesStatsTable
        data={data?.body ?? []}
        totalCount={data?.meta.totalCount ?? 0}
        isLoading={isLoading}
        params={params}
        generalStatsData={generalStatsData}
      />
    </Container>
  );
}
