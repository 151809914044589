import { Container } from '@mui/material';
import SubscriptionDetail from 'src/components/modules/RevenueEvents/view/Subscriptions/Detail';
import useURLSearchParams from 'src/lib/hooks/useURLSearchParams';
import Routes from 'src/routes/Routes';
import HeaderBreadcrumbs from 'src/template/components/HeaderBreadcrumbs';
import Page from 'src/template/components/Page';

export default function SubscriptionPage(): JSX.Element {
  const { allSearchParams } = useURLSearchParams<'user'>();
  const breadcrumbs = [];
  if (allSearchParams.user) {
    breadcrumbs.push({
      name: 'User',
      href: Routes.user(allSearchParams.user),
    });
  } else {
    breadcrumbs.push({
      name: 'Subscriptions List',
      href: Routes.subscriptions_sales_v2(),
    });
  }

  breadcrumbs.push({ name: 'Subscription' });
  return (
    <Page title="Subscriptions">
      <Container maxWidth="xl">
        <HeaderBreadcrumbs heading="Subscription Details" links={breadcrumbs} />
        <SubscriptionDetail />
      </Container>
    </Page>
  );
}
