import Games from 'src/components/modules/Games/view/List';
import Page from 'src/template/components/Page';

export default function GamesPage() {
  return (
    <Page title="Games">
      <Games />
    </Page>
  );
}
