import { Rarity } from 'src/lib/types/nftPack';
import AddNftOnPackForm from '../../../components/Forms/AddNft';
import useAddNftsInPackView from './useView';

interface AddNftsInPackViewProps {
  uuid: string;
  rarities: Rarity[];
}

export default function AddNftsInPackView({
  uuid,
  rarities,
}: AddNftsInPackViewProps): JSX.Element {
  const { handleAddNftsInPack } = useAddNftsInPackView();
  return (
    <AddNftOnPackForm
      onSubmit={async (values) => {
        await handleAddNftsInPack(uuid, values);
      }}
      title="Add NFTS On Pack"
      raritiesOptions={rarities}
    />
  );
}
