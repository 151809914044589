import {
  Card,
  CardContent,
  CardHeader,
  Divider,
  Stack,
  TextField,
} from '@mui/material';
import { Controller } from 'react-hook-form';
import Button from 'src/components/shared/Buttons/CustomButton';
import Modal from 'src/components/shared/Modal';
import { GameCategory } from 'src/lib/types/gameCategory';
import { useCreateGameCategoryView } from './useView';

export default function CreateGameCategoryForm({
  gameCategory,
}: {
  gameCategory?: GameCategory;
}): JSX.Element {
  const {
    handleCloseModal,
    isSubmitting,
    onSubmitForm,
    onUpdateContent,
    isUpdatingContent,
    form: { control, handleSubmit, setValue },
  } = useCreateGameCategoryView(gameCategory);

  return (
    <Modal open onClose={handleCloseModal}>
      <Card
        sx={{
          width: '600px',
        }}
      >
        <CardHeader title="Create a Game Game Category " />
        <CardContent>
          <form onSubmit={handleSubmit(onSubmitForm)}>
            <Stack spacing={2}>
              <Divider textAlign="left">Details</Divider>
              {/* TextField for title */}
              <Controller
                name="name"
                control={control}
                render={({ field }) => (
                  <TextField
                    label="Name"
                    fullWidth
                    {...field}
                    variant="outlined"
                  />
                )}
              />

              {/* TextField for icon */}
              <Controller
                name="icon"
                control={control}
                render={({ field }) => (
                  <TextField
                    label="Icon"
                    fullWidth
                    {...field}
                    variant="outlined"
                  />
                )}
              />

              {/* TextField for description */}
              <Controller
                name="description"
                control={control}
                render={({ field }) => (
                  <TextField
                    label="Description"
                    fullWidth
                    {...field}
                    variant="outlined"
                  />
                )}
              />

              {/*
              <Divider textAlign="left">Content</Divider>

              <Controller
                name="imageUrl"
                control={control}
                render={({ field }) => (
                  <UploadContent
                    {...field}
                    type="image"
                    content={field.value ?? ''}
                    onEdit={(file) => {
                      onUpdateContent(file, 'imageUrl');
                    }}
                    onRemove={() => {
                      setValue('imageUrl', '');
                    }}
                    showEditButton
                    sx={{
                      maxWidth: '300px',
                      maxHeight: '300px',
                      minHeight: '30px',
                      minWidth: '100px',
                    }}
                    isLoading={isUpdatingContent}
                  />
                )}
              />
              */}

              <Button
                variant="contained"
                color="secondary"
                type="submit"
                loading={isSubmitting}
                size="large"
              >
                Save
              </Button>
            </Stack>
          </form>
        </CardContent>
      </Card>
    </Modal>
  );
}
