import HeaderBreadcrumbs from 'src/template/components/HeaderBreadcrumbs';
import UserLinksTable from '../../components/UserLinksTable/UserLinksTable';
import { useUserLinks } from '../../components/UserLinksTable/useUserLinksTable';

export default function UserLinks() {
  const { data, isLoading, params } = useUserLinks();

  return (
    <>
      <HeaderBreadcrumbs
        heading="User-Uploaded Links"
        links={[{ name: 'User Links' }]}
      />
      <UserLinksTable
        data={data?.body || []}
        totalCount={data?.meta.totalCount || 0}
        isLoading={isLoading}
        params={params}
      />
    </>
  );
}
