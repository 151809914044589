import useSWR from 'swr';
import { PaginatedResponse } from '../types/PaginatedResponse';
import { NFT } from '../types/nft';
import { parseSearchParams } from '../utils/parseSearchParams';

export const fetchAdminNftTransfers = (filters: any): any => {
  const { data, error } = useSWR<PaginatedResponse<NFT[]>, Error>(
    `admin/nfts/admin-transfers?${parseSearchParams(filters ?? {})}`
  );

  return {
    data: data?.body,
    meta: data?.meta,
    loading: !error && !data,
    error: error?.message,
  };
};
