import {
  FormControl,
  FormHelperText,
  FormLabel,
  TextField,
  TextFieldProps,
} from '@mui/material';
import { useField } from 'formik';

type FormFieldProps = TextFieldProps & {
  name: string;
  label?: string;
  showErrorMsg?: boolean;
};
export default function FormikField({
  name,
  label,
  showErrorMsg = true,
  ...rest
}: FormFieldProps) {
  const [field, meta] = useField(name);
  const errorText = meta.error && meta.touched ? meta.error : '';
  return (
    <FormControl error={!!errorText}>
      {label && <FormLabel htmlFor={name}>{label}</FormLabel>}
      <TextField {...field} {...rest} />
      {errorText && showErrorMsg && (
        <FormHelperText>{errorText}</FormHelperText>
      )}
    </FormControl>
  );
}
