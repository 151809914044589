import { Divider, Stack, Typography } from '@mui/material';

export default function SectionDivider({ section }: { section: string }) {
  return (
    <Stack
      direction="row"
      spacing={2}
      width="100%"
      alignItems="center"
      mt={2}
      mb={2}
      px={4}
    >
      <Divider
        flexItem
        sx={{
          flex: 1,
          height: '12px',
        }}
      />
      <Typography variant="subtitle2" color="text.secondary">
        {section}
      </Typography>
      <Divider
        flexItem
        sx={{
          flex: 1,
          height: '12px',
        }}
      />
    </Stack>
  );
}
