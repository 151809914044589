import { DeepPartial } from 'react-hook-form';
import { UserLink } from 'src/lib/types/userLink';
import { parseSearchParams } from 'src/lib/utils/parseSearchParams';
import useSWR, { SWRResponse } from 'swr';

export type PossibleParams = {
  page: number | string;
  size: number | string;
  order: Record<string, string>;
  filter: {
    [key: string]: {
      like: string;
      eq: string;
    };
  };
};
export const fetchUserLinks = (
  filter?: DeepPartial<PossibleParams>
): SWRResponse<UserLinksFetchResponse> => {
  const parsedParams = parseSearchParams(filter ?? {});
  const response = useSWR<UserLinksFetchResponse, Error>(
    `admin/userLink?${parsedParams}`
  );

  return response;
};

export type UserLinksFetchResponse = {
  meta: { totalCount: number };
  body: UserLink[];
};
