import { Avatar } from '@mui/material';
import { GridColumns } from '@mui/x-data-grid';
import { ProfileTabs } from 'src/components/modules/Profile/types/ProfileTabs';
import Link from 'src/components/shared/Link';
import {
  AvailableBuyerStatisticsToRank,
  AvailableSellerStatisticsToRank,
  ParsedBuyerWithRank,
  ParsedSellerWithRank,
  SellerBuyerTotalStatistics,
} from 'src/lib/types/UserRank';
import Routes from 'src/routes/Routes';
import Label from 'src/template/components/Label';
import { UserType, UserTypeLabel } from '../../../types/UserType';
import UserRankTableView from './View';

type BaseProps = {
  isLoading: boolean;
  handleOpenFilter: () => void;
  page: number;
  size: number;
  rankBy: string;
};
type Props = BaseProps &
  (
    | {
        rankType: 'buyer';
        data: ParsedBuyerWithRank[] | undefined;
        stats: SellerBuyerTotalStatistics | undefined;
      }
    | {
        rankType: 'seller';
        data: ParsedSellerWithRank[] | undefined;
        stats: SellerBuyerTotalStatistics | undefined;
      }
  );
export default function UserRankTable({
  rankType,
  data,
  stats,
  isLoading,
  handleOpenFilter,
  page,
  rankBy,
  size,
}: Props) {
  const buyerOrSellerFields =
    rankType === 'seller' ? SellerRankColumns : BuyerRankColumns;

  const columns: GridColumns<any> = [
    {
      field: 'pos',
      headerName: 'Pos',
      align: 'left',
      sortable: false,
      flex: 1 / 3,
    },
    {
      field: 'active',
      headerName: 'Active',
      align: 'left',
      sortable: false,
      flex: 1 / 2,
      renderCell: ({ row }) => {
        if (row.active) {
          return <Label color="success">Active</Label>;
        }
        return <Label color="error">Inactive</Label>;
      },
    },
    {
      field: 'userType',
      headerName: 'Type',
      align: 'left',
      sortable: false,
      valueFormatter: ({ value }) => UserTypeLabel[value as UserType],
    },
    {
      field: 'name',
      headerName: 'Name',
      align: 'left',
      sortable: false,
      renderCell: ({ row }) => (
        <>
          <Avatar alt={row.name} src={row.profileImage} sx={{ mr: 2 }} />

          <Link
            to={`${Routes.user(row.uuid)}?tab=${ProfileTabs.STATS}&subtab=${
              rankType === 'seller' ? 'sales' : 'purchases'
            }`}
            color={row.name ? 'inherit' : 'text.secondary'}
            underline="hover"
          >
            {row.name ?? 'empty'}
          </Link>
        </>
      ),
    },
    {
      field: 'slug',
      headerName: 'Slug',
      align: 'left',
      sortable: false,
    },
    {
      field: 'email',
      headerName: 'Email',
      align: 'left',
      sortable: false,
    },
    ...buyerOrSellerFields,
  ];

  return (
    <UserRankTableView
      data={data ?? []}
      stats={stats ?? defaultStats}
      columns={columns}
      isLoading={isLoading}
      handleOpenFilter={handleOpenFilter}
      page={page}
      rankBy={rankBy}
      size={size}
    />
  );
}

const defaultStats = {
  totalCount: 0,
  totalVolume: 0,
  totalTransactions: 0,
  totalAveragePrice: 0,
};

const SellerRankColumns: GridColumns<any> = [
  {
    field: AvailableSellerStatisticsToRank.volume,
    headerName: 'Volume (Coins)',
    align: 'right',
    sortingOrder: ['desc'],
    sortComparator: () => 0,
  },
  {
    field: AvailableSellerStatisticsToRank.sales,
    headerName: 'Sales (# NFTs)',
    align: 'right',
    sortingOrder: ['desc'],
    sortComparator: () => 0,
  },
  {
    field: AvailableSellerStatisticsToRank.averageSalesPrice,
    headerName: 'Average Price',
    align: 'right',
    sortingOrder: ['desc'],
    sortComparator: () => 0,
  },
];

const BuyerRankColumns: GridColumns<any> = [
  {
    field: AvailableBuyerStatisticsToRank.volume,
    headerName: 'Volume (Coins)',
    align: 'right',
    sortingOrder: ['desc'],
    sortComparator: () => 0,
  },
  {
    field: AvailableBuyerStatisticsToRank.purchases,
    headerName: 'Purchases (# NFTs)',
    align: 'right',
    sortingOrder: ['desc'],
    sortComparator: () => 0,
  },
  {
    field: AvailableBuyerStatisticsToRank.averagePurchasePrice,
    headerName: 'Average Price',
    align: 'right',
    sortingOrder: ['desc'],
    sortComparator: () => 0,
  },
];
