import getAPIClient from 'src/lib/services/api/axios.config';
import { ServiceResponse } from 'src/lib/types/ServiceResponse';
import { Channel } from 'src/lib/types/channel';
import { linksToCreate } from 'src/lib/types/objectLink';
import { getMessageFromError } from 'src/lib/utils/error';

export async function UpdateChannel(
  uuid: string,
  data: Partial<Channel> & { objectLinks?: linksToCreate[] }
): Promise<ServiceResponse<Channel>> {
  try {
    const api = await getAPIClient();
    const res = await api.put(`admin/channels/${uuid}`, data);
    return {
      data: res.data,
      error: null,
    };
  } catch (e) {
    return {
      data: null,
      error: getMessageFromError(e),
    };
  }
}
