import { Container } from '@mui/material';
import CoinExpiring from 'src/components/modules/Coins/view/Expiring';
import Page from 'src/template/components/Page';

export default function CoinsExpiringPage() {
  return (
    <Page title="Coins Delivery">
      <Container maxWidth="xl">
        <CoinExpiring />
      </Container>
    </Page>
  );
}
