import { Stack, TextField } from '@mui/material';
import { CommonDateStrings } from 'src/lib/constants/CommonDates';
import { BracketFilterParams } from 'src/lib/types/bracketFilterParams';

interface ReportsFilterProps {
  filter: BracketFilterParams;
  setFilter: (filter: Partial<BracketFilterParams>) => void;
}
function ReportsFilter({ filter, setFilter }: ReportsFilterProps) {
  return (
    <Stack
      sx={{
        m: 4,
      }}
      spacing={2}
    >
      <TextField
        type="date"
        name="startDate"
        label="Start Date"
        fullWidth
        InputLabelProps={{ shrink: true }}
        inputProps={{
          min: CommonDateStrings.firstReport.date,
          max: CommonDateStrings.today.date,
        }}
        defaultValue={CommonDateStrings.firstReport.date}
        onChange={(e) =>
          setFilter({
            ...filter,
            custom: {
              ...filter.custom,
              createdAt: {
                gte: e.target.value,
              },
            },
          })
        }
      />
      <TextField
        type="date"
        name="endDate"
        label="End Date"
        InputLabelProps={{ shrink: true }}
        fullWidth
        inputProps={{
          min: CommonDateStrings.firstReport.date,
          max: CommonDateStrings.today.date,
        }}
        defaultValue={CommonDateStrings.today.date}
        onChange={(e) =>
          setFilter({
            ...filter,
            custom: {
              ...filter.custom,
              createdAt: {
                lte: e.target.value,
              },
            },
          })
        }
      />
    </Stack>
  );
}
export default ReportsFilter;
