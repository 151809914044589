import useNotify from 'src/lib/hooks/useNotify';
import useModalStore from 'src/lib/stores/useModalStore';
import { AssetCollectionFormValues } from '../../components/Forms/AssetCollectionForm/types';
import { AssetCollectionEmitter } from '../../events/AssetCollectionEmitter';
import { CreateAssetCollection } from '../../services/createAssetCollection';

export default function useCreateAssetCollectionView() {
  const closeModal = useModalStore((state) => state.closeModal);
  const notify = useNotify();

  const handleCreateAssetCollection = async (
    values: AssetCollectionFormValues
  ) => {
    const { error, data } = await CreateAssetCollection({
      name: values.name,
      description: values.description,
    });

    if (error) {
      notify(error, { variant: 'error' });
      closeModal();
    } else if (data) {
      notify('Asset Collection Created!', { variant: 'success' });
      closeModal();

      AssetCollectionEmitter.emit('AssetCollectionCreated', null);
    }
  };

  return {
    handleCreateAssetCollection,
  };
}
