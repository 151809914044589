import { DEFAULT_INITIAL_PAGE_SIZE } from 'src/lib/constants/pagination';
import useTable from 'src/template/hooks/useTable';
import { fetchGameAssetsByContestGame } from '../../services/fetchGameAssetsByContestGame';

export default function useSelectAssetsForContest(contestUuid: string) {
  const {
    page,
    rowsPerPage: size,
    order,
    orderBy,
    query,
    searchBy,
    setRowsPerPage,
    setOrder,
    setPage,
    setQuery,
    setSearchBy,
    setOrderBy,
  } = useTable({
    defaultOrderBy: 'name',
    defaultOrder: 'asc',
    defaultCurrentPage: 0,
    defaultRowsPerPage: DEFAULT_INITIAL_PAGE_SIZE,
    defaultSearchBy: 'name',
  });

  const { data, error, isLoading, mutate } = fetchGameAssetsByContestGame(
    contestUuid!,
    {
      page,
      size,
      ...(orderBy &&
        order && {
          order: {
            [orderBy]: order,
          },
        }),
      filter: {
        ...(searchBy &&
          query && {
            [searchBy]: {
              like: `%${query}%`,
            },
          }),
      },
    }
  );

  return {
    data,
    isLoading,
    page,
    size,
    order,
    orderBy,
    searchBy,
    query,
    setPage,
    setRowsPerPage,
    setOrder,
    setQuery,
    setSearchBy,
    setOrderBy,
  };
}
