import { DeepPartial } from 'react-hook-form';
import { PaginatedResponse } from 'src/lib/types/PaginatedResponse';
import { GameSessionStatsListItem } from 'src/lib/types/gameSession';
import { parseSearchParams } from 'src/lib/utils/parseSearchParams';
import useSWR, { SWRResponse } from 'swr';

export type PossibleParams = {
  limit: number | string;
  offset: number | string;
  sortOrder: string;
  orderBy: string;
  startDate: string;
  endDate: string;
};

export const fetchGamesStats = (
  filter?: DeepPartial<PossibleParams>
): SWRResponse<PaginatedResponse<GameSessionStatsListItem[]>> => {
  const parsedParams = parseSearchParams(filter ?? {});
  const response = useSWR<PaginatedResponse<GameSessionStatsListItem[]>, Error>(
    `admin/game-session/game/stats/?${parsedParams}`
  );

  return response;
};
