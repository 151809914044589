import PayoutCountryForm from '../../components/Form';
import { PayoutCountry } from '../../types/PayoutCountries';
import useUpdatePayoutCountry from './useView';

type Props = {
  payoutCountry: PayoutCountry;
};
export default function UpdatePayoutrCountry({
  payoutCountry,
}: Props): JSX.Element {
  const { handleUpdatePayoutCountry } = useUpdatePayoutCountry();
  return (
    <PayoutCountryForm
      defaultValues={{
        name: payoutCountry.name,
        code: payoutCountry.code,
        active: payoutCountry.active ? 'true' : 'false',
      }}
      title={`Update ${payoutCountry.name}`}
      onSubmit={async (values) => {
        await handleUpdatePayoutCountry(payoutCountry.uuid, values);
      }}
    />
  );
}
