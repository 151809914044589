import HeaderBreadcrumbs from 'src/template/components/HeaderBreadcrumbs';
import UsersWithoutWalletTable from '../../components/UsersWithoutWalletTable/UsersWithoutWalletTable';

export default function UsersWithoutWallet() {
  // const [showAddCreditByCSVModal, setShowAddCreditByCSVModal] = useState(false);
  // const [csv, setCSV] = useState<any>(null);

  // const onCreditUserByCSV = async () => {
  //   if (csv === null) return;

  //   await addBonusFromCSV(csv[0]);
  //   setCSV(null);
  //   setShowAddCreditByCSVModal(false);
  // };

  return (
    <>
      <HeaderBreadcrumbs
        heading="Users Without Wallet"
        links={[{ name: 'Users Without Wallet' }]}
      />
      <UsersWithoutWalletTable />
      {/* <Modal  
        open={showAddCreditByCSVModal}
        onClose={() => setShowAddCreditByCSVModal(false)}
      >
        <Card>
          <CardHeader title="Upload CSV" />
          <CardContent>
            <UploadSingleFile file={csv} onDrop={setCSV} />
            <Stack
              direction="row"
              spacing={2}
              alignItems="center"
              justifyContent="space-between"
              mt={2}
            >
              <Button
                color="error"
                onClick={() => {
                  setCSV(null);
                  setShowAddCreditByCSVModal(false);
                }}
              >
                Cancel
              </Button>
              {csv &&
                (csv[0].type === 'text/csv' ? (
                  <Label color="success">{csv[0].name}</Label>
                ) : (
                  <Label color="error">{csv[0].name} is not a CSV file</Label>
                ))}
              <Button
                color="secondary"
                variant="contained"
                onClick={onCreditUserByCSV}
                disabled={!csv || csv[0].type !== 'text/csv'}
              >
                Apply
              </Button>
            </Stack>
          </CardContent>
        </Card>
      </Modal> */}
    </>
  );
}
