import { Container } from '@mui/material';
import Collection from 'src/components/modules/Collections/Collection/view/Collection';

export default function CollectionPage() {
  return (
    <Container maxWidth="xl">
      <Collection />
    </Container>
  );
}
