import { AppVideoFeedEmitter } from 'src/components/modules/VideoFeed/events/AppVideoFeedEmitter';
import { createAppVideoFeed } from 'src/components/modules/VideoFeed/services/createAppVideoFeed';
import useNotify from 'src/lib/hooks/useNotify';
import useModalStore from 'src/lib/stores/useModalStore';

export default function useSendChannelContentToFeedView() {
  const closeModal = useModalStore((state) => state.closeModal);
  const notify = useNotify();

  const handleSendToFeed = async (uuid: string) => {
    const { data, error } = await createAppVideoFeed(uuid);
    if (error) {
      notify(error, { variant: 'error' });
      closeModal();
    } else {
      notify('Sent to feed successfully!', { variant: 'success' });
      closeModal();
      AppVideoFeedEmitter.emit('AppVideoFeedCreated', data);
    }
  };

  return {
    handleSendToFeed,
  };
}
