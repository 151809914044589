import { Container } from '@mui/material';
import HomeFeeds from 'src/components/modules/HomeFeeds';
// import SelectForm from 'src/components/modules/HomeFeeds/components/SelectForm';
import Page from 'src/template/components/Page';

export default function HomeFeedsPage(): JSX.Element {
  return (
    <Page title="Marketplace Carousel">
      <Container maxWidth="xl">
        <HomeFeeds />
        {/* <SelectForm /> */}
      </Container>
    </Page>
  );
}
