import objectEntries from './objectEntries';

export function objectValuesToString<T extends Record<keyof T, unknown>>(
  obj: T
): Record<keyof T, string> {
  return objectEntries(obj).reduce((acc, [currKey, currValue]) => {
    acc[currKey] = String(currValue);
    return acc;
  }, {} as Record<keyof T, string>);
}
