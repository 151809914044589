import { yupResolver } from '@hookform/resolvers/yup';
import {
  AttachMoneyRounded,
  DriveFileRenameOutlineOutlined,
  LocalOfferRounded,
  ShoppingBagRounded,
} from '@mui/icons-material';
import {
  Card,
  CardActions,
  CardContent,
  InputAdornment,
  Stack,
  Typography,
} from '@mui/material';
import { useForm } from 'react-hook-form';
import Button from 'src/components/shared/Buttons/CustomButton';
import Flex from 'src/components/shared/Flex';
import ControllerField from 'src/components/shared/Inputs/ControllerField';
import Modal from 'src/components/shared/Modal';
import { UploadMultiFile } from 'src/template/components/upload';
import { AddNftsFormValidation, AddNftsFormValues } from './types';

type Props = {
  onSubmit: (values: AddNftsFormValues) => Promise<void>;
  defaultValues: Required<AddNftsFormValues>;
  open: boolean;
  onClose: () => void;
};
export default function AddNftsModalView({
  onSubmit,
  defaultValues,
  open,
  onClose,
}: Props) {
  const {
    reset,
    handleSubmit,
    control,
    formState: { isSubmitting },
    setValue,
    watch,
  } = useForm<AddNftsFormValues>({
    defaultValues,
    resolver: yupResolver(AddNftsFormValidation),
  });
  const files = watch('files');

  const handleClose = () => {
    onClose();
    reset();
    setValue('files', []);
  };

  const handleRemoveFile = (file: string | File) => {
    const newFiles = files.filter((f) => f !== file);
    setValue('files', newFiles);
  };

  return (
    <Modal open={open} onClose={handleClose}>
      <Card>
        <form onSubmit={handleSubmit(onSubmit)}>
          <CardContent
            sx={{
              maxHeight: '70vh',
              overflowY: 'auto',
            }}
          >
            <Flex justifyContent="space-between" mb={4}>
              <Typography variant="h4" fontWeight="bold">
                Add NFTs
              </Typography>
              <Flex gap={2}>
                <Button color="error" onClick={handleClose}>
                  Cancel
                </Button>
                <Button
                  variant="contained"
                  color="primary"
                  disabled={files.length === 0}
                  type="submit"
                  loading={isSubmitting}
                >
                  Save
                </Button>
              </Flex>
            </Flex>
            <Stack spacing={2} mb={2} direction="row" width="full">
              <Stack spacing={1}>
                <UploadMultiFile
                  files={files}
                  onDrop={(values) => {
                    setValue('files', values);
                  }}
                  onRemove={handleRemoveFile}
                  accept={{
                    'image/*': ['.jpeg', '.png', '.jpg', '.webp'],
                  }}
                />
              </Stack>
              <Stack spacing={2}>
                <ControllerField
                  control={control}
                  name="nftData.name"
                  label="Name"
                  required
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <DriveFileRenameOutlineOutlined />
                      </InputAdornment>
                    ),
                  }}
                />
                <ControllerField
                  control={control}
                  name="nftData.price"
                  label="Price"
                  placeholder="No ask"
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <AttachMoneyRounded />
                      </InputAdornment>
                    ),
                  }}
                  helperText="This value is going to be multiplied x1000"
                />
                <ControllerField
                  control={control}
                  name="nftData.editions"
                  label="Editions"
                  type="number"
                  required
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <ShoppingBagRounded />
                      </InputAdornment>
                    ),
                  }}
                />
                <ControllerField
                  control={control}
                  name="nftData.tags"
                  label="Tags"
                  placeholder="Ex.: Tag1 Tag2 Tag3"
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <LocalOfferRounded />
                      </InputAdornment>
                    ),
                  }}
                />
              </Stack>
            </Stack>
          </CardContent>
          <CardActions
            sx={{
              display: 'flex',
              justifyContent: 'space-between',
              padding: 2,
            }}
          />
        </form>
      </Card>
    </Modal>
  );
}
