import { OpenInNew } from '@mui/icons-material';
import { IconButton } from '@mui/material';
import {
  GridColumns,
  GridSortDirection,
  GridSortModel,
} from '@mui/x-data-grid';
import Link from 'src/components/shared/Link';
import MagicTable from 'src/components/shared/MagicTable/MagicTable';
import { DEFAULT_INITIAL_PAGE_SIZE } from 'src/lib/constants/pagination';
import useURLSearchParams from 'src/lib/hooks/useURLSearchParams';
import { formatUTCDate } from 'src/lib/utils/formatUTCDate';
import Routes from 'src/routes/Routes';
import Label from 'src/template/components/Label';
import { Device } from '../../types/Device';

const columns: GridColumns<any> = [
  {
    field: 'platformId',
    headerName: 'Platform ID',
    align: 'left',
    sortable: false,
  },
  {
    field: 'active',
    headerName: 'Status',
    align: 'left',
    flex: 0,
    sortable: false,
    renderCell: ({ row }) => {
      if (row.active) {
        return <Label color="success">Active</Label>;
      }
      return <Label color="error">Inactive</Label>;
    },
  },
  {
    field: 'country',
    headerName: 'Country',
    align: 'left',
    sortable: false,
    flex: 0,
  },
  {
    field: 'platform',
    headerName: 'Platform',
    align: 'left',
    sortable: false,
    flex: 0,
  },
  {
    field: 'description',
    headerName: 'Description',
    sortable: false,
  },
  {
    field: 'totalUsers',
    headerName: '# Users',
    align: 'right',
    flex: 1 / 3,
    sortable: true,
  },
  {
    field: 'lastSession',
    headerName: 'Last Session',
    align: 'right',
    flex: 2 / 3,
    sortable: true,
    valueFormatter: ({ value }) => (value ? formatUTCDate(value) : '--'),
  },
  {
    field: 'action',
    headerName: '',
    align: 'center',
    flex: 0,
    sortable: false,
    renderCell: (value) => (
      <IconButton component={Link} to={Routes.device(value.row.uuid)}>
        <OpenInNew />
      </IconButton>
    ),
  },
];
type Props = {
  data: Device[];
  isLoading: boolean;
};
export default function DevicesTable({ data, isLoading }: Props): JSX.Element {
  const { addParam, getParam, removeParam } = useURLSearchParams();
  const page = Number(getParam('page') ?? 0);
  const size = Number(getParam('size') ?? DEFAULT_INITIAL_PAGE_SIZE);
  const orderBy = getParam('orderBy');
  const order = getParam('order');
  const sortModel: GridSortModel | undefined =
    orderBy && order
      ? [{ field: orderBy, sort: order as GridSortDirection }]
      : undefined;

  return (
    <MagicTable
      hideNumberOfRows
      autoHeight
      loading={isLoading}
      columns={columns}
      pageSize={size}
      page={page}
      paginationMode="server"
      onPageChange={(newPage) => {
        addParam('page', newPage);
      }}
      onPageSizeChange={(newSize) => {
        addParam('size', newSize);
      }}
      sortModel={sortModel}
      onSortModelChange={(model) => {
        const currentModel = model[0];
        if (currentModel && currentModel.sort) {
          addParam('orderBy', currentModel.field);
          addParam('order', currentModel.sort);
        } else {
          removeParam('orderBy');
          removeParam('order');
        }
      }}
      getRowId={(r) => r.uuid}
      rows={data}
    />
  );
}
