import { PaginatedResponse } from 'src/lib/types/PaginatedResponse';
import useSWR from 'swr';
import { UserPurge, UserPurgeStatus } from '../../Profile/types/UserPurge';

export const useFetchUserPurgeCountByStatus = (
  status: UserPurgeStatus
): any => {
  const { data, error } = useSWR<PaginatedResponse<UserPurge[]>, Error>(
    `/admin/user-purge/count-by-status/${status}`,
    {
      refreshInterval: 5000,
    }
  );

  return {
    data: data?.body,
    loading: !error && !data,
    error: error?.message,
  };
};
