import ConfirmationDialogRaw from 'src/components/shared/Dialog/ConfirmationDialog';
import useModalStore from 'src/lib/stores/useModalStore';
import useDeleteAsset from './useView';

type Props = {
  contestUuid: string;
  assetUuid: string;
};
export default function DeleteAssetView({
  contestUuid,
  assetUuid,
}: Props): JSX.Element {
  const { handleDeleteAsset } = useDeleteAsset();
  const closeModal = useModalStore((state) => state.closeModal);

  return (
    <ConfirmationDialogRaw
      onClose={closeModal}
      open
      title={`Delete this Asset`}
      onConfirm={async () => {
        await handleDeleteAsset(contestUuid, assetUuid);
      }}
    >
      Are you sure you want to remove this Asset?
    </ConfirmationDialogRaw>
  );
}
