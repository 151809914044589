import {
  ContestAwardDistributionModes,
  ContestFrequency,
} from 'src/lib/types/contest';
import ContestForm from '../../components/Forms/Contest';
import { ContestFormValues } from '../../components/Forms/Contest/types';
import useCreateContestView from './useView';

interface CreateContestViewProps {
  gameUuid: string;
}

export default function CreateContestView({
  gameUuid,
}: CreateContestViewProps): JSX.Element {
  const { handleCreateContest } = useCreateContestView();
  return (
    <ContestForm
      defaultValues={{
        name: '',
        awardDistributionMode: ContestAwardDistributionModes.FIXED,
        awardValue: 0,
        entryPrice: 0,
        frequency: ContestFrequency.DAILY,
        playerEntryLimit: 0,
        totalEntries: 0,
        details: '',
        endDate: '',
        startDate: '',
        gameLevelUuid: '',
        gameUuid,
        primaryColor: '',
        secondaryColor: '',
        positionAwards: [{ value: 75 }, { value: 20 }, { value: 5 }],
        awardWinningStatisticsGroupUuid: '',
      }}
      onSubmit={async (values: ContestFormValues) => {
        await handleCreateContest({
          ...values,
          awardValue:
            values.awardValue &&
            values.awardDistributionMode == ContestAwardDistributionModes.FIXED
              ? values.awardValue * 1000
              : null,
          entryPrice: values.entryPrice * 1000,
          positionAwards: values.positionAwards.map((award) => ({
            value:
              values.awardDistributionMode ==
              ContestAwardDistributionModes.FIXED
                ? award.value * 1000
                : award.value,
          })),
        });
      }}
      title="Create Contest"
    />
  );
}
