import { Card, CardContent, CardHeader, Stack, TextField } from '@mui/material';
import { Controller } from 'react-hook-form';
import Button from 'src/components/shared/Buttons/CustomButton';
import FilterForm from 'src/components/shared/FilterForm';
import Flex from 'src/components/shared/Flex';
import ControllerField from 'src/components/shared/Inputs/ControllerField';
import Modal from 'src/components/shared/Modal';
import SelectOption from 'src/components/shared/SelectOption';
import { CommonDateStrings } from 'src/lib/constants/CommonDates';
import useModalStore from 'src/lib/stores/useModalStore';
import { IncludeExcludeOptions } from 'src/lib/utils/includeExcludeFilterUtils';
import objectEntries from 'src/lib/utils/objectEntries';
import { INITIAL_FILTERS } from '../../../constants/ImporterAndCreatorRanking';
import { fetchRankingFilterOptions } from '../../../services/fetchRankingFilterOptions';
import { UserTypeLabel } from '../../../types/UserType';
import ResetFiltersButton from '../ResetFiltersButton';
import { RankByLabel, RankFilterFormValues } from './types';

type Props = {
  initialValues?: RankFilterFormValues;
};
export default function ImporterAndCreatorRankFilter({
  initialValues,
}: Props): JSX.Element {
  const { data: filterOptions } = fetchRankingFilterOptions();

  const closeModal = useModalStore((state) => state.closeModal);

  function handleCancel(): void {
    closeModal();
  }

  return (
    <Modal open onClose={handleCancel}>
      <FilterForm<RankFilterFormValues>
        defaultValues={initialValues}
        onSubmit={handleCancel}
      >
        {({ control, reset }) => (
          <Card sx={{ minWidth: 500 }}>
            <CardHeader
              title="Filter"
              action={
                <ResetFiltersButton onClick={() => reset(INITIAL_FILTERS)} />
              }
            />
            <CardContent>
              <Stack spacing={2} direction="column">
                <ControllerField
                  control={control}
                  name="InactiveUsers"
                  label="Inactive Users"
                  fullWidth
                  select
                >
                  {IncludeExcludeOptions.map(({ label, value }) => (
                    <SelectOption key={value} value={value}>
                      {label}
                    </SelectOption>
                  ))}
                </ControllerField>
                <Flex gap={2}>
                  <ControllerField
                    control={control}
                    name="startDate"
                    type="date"
                    label="Start Date"
                    InputLabelProps={{ shrink: true }}
                    fullWidth
                    inputProps={{
                      min: CommonDateStrings.firstNFT.date,
                      max: CommonDateStrings.today.date,
                    }}
                  />
                  <ControllerField
                    control={control}
                    name="endDate"
                    type="date"
                    label="End Date"
                    InputLabelProps={{ shrink: true }}
                    inputProps={{
                      min: CommonDateStrings.firstNFT.date,
                      max: CommonDateStrings.today.date,
                    }}
                    fullWidth
                  />
                </Flex>
                <ControllerField
                  control={control}
                  name="rankBy"
                  select
                  label="Rank By"
                >
                  {objectEntries(RankByLabel).map(([value, label]) => (
                    <SelectOption key={value} value={value}>
                      {label}
                    </SelectOption>
                  ))}
                </ControllerField>
                <ControllerField
                  control={control}
                  name="userType"
                  label="User Type"
                  fullWidth
                  select
                >
                  <SelectOption value="all">All</SelectOption>
                  {objectEntries(UserTypeLabel).map(([option, label]) => (
                    <SelectOption key={option} value={option}>
                      {label}
                    </SelectOption>
                  ))}
                </ControllerField>
                <Flex gap={2}>
                  <Controller
                    name="country"
                    control={control}
                    render={({ field: { value, ...field } }) => (
                      <TextField
                        select
                        label="Country"
                        fullWidth
                        value={value || ''}
                        {...field}
                      >
                        {filterOptions?.countries.map((option) => (
                          <SelectOption key={option} value={option}>
                            {option}
                          </SelectOption>
                        ))}
                      </TextField>
                    )}
                  />
                  <Controller
                    name="lang"
                    control={control}
                    render={({ field: { value, ...field } }) => (
                      <TextField
                        select
                        label="Language"
                        fullWidth
                        value={value || ''}
                        {...field}
                      >
                        {filterOptions?.langs.map((option) => (
                          <SelectOption key={option} value={option}>
                            {option}
                          </SelectOption>
                        ))}
                      </TextField>
                    )}
                  />
                </Flex>

                <Flex justifyContent="space-between">
                  <Button onClick={handleCancel} variant="text" color="error">
                    Cancel
                  </Button>
                  <Button type="submit" variant="contained" color="info">
                    Apply
                  </Button>
                </Flex>
              </Stack>
            </CardContent>
          </Card>
        )}
      </FilterForm>
    </Modal>
  );
}
