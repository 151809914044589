import { DEFAULT_INITIAL_PAGE_SIZE } from 'src/lib/constants/pagination';
import useURLSearchParams from 'src/lib/hooks/useURLSearchParams';
import useModalStore from 'src/lib/stores/useModalStore';
import useTabs from 'src/template/hooks/useTabs';
import { fetchCoinDeliveries } from '../../services/fetchCoinDeliveries';

export default function useDeliveriesView() {
  const openModal = useModalStore((state) => state.openModal);
  const { currentTab: status, onChangeTab: onChangeStatus } = useTabs('all', {
    queryName: 'status',
  });
  const { allSearchParams } = useURLSearchParams({
    sortBy: 'activeAt',
    order: 'desc',
    page: '0',
    size: DEFAULT_INITIAL_PAGE_SIZE.toString(),
    searchBy: 'user.slug',
  });
  const page = Number(allSearchParams.page);
  const size = Number(allSearchParams.size);

  const query: any = {};
  if (allSearchParams.searchBy && allSearchParams.query) {
    query[allSearchParams.searchBy] = {
      like: `%${allSearchParams.query}%`,
    };
  }

  const { data, isLoading } = fetchCoinDeliveries({
    filter: {
      status: status === 'all' ? undefined : status,
      'user.active':
        allSearchParams.userStatus === 'all' || !allSearchParams.userStatus
          ? undefined
          : allSearchParams.userStatus === 'active'
          ? 1
          : 0,
      activeAt: {
        gt: allSearchParams.startActiveAt,
        lt: allSearchParams.endActiveAt
          ? `${allSearchParams.endActiveAt}T23:59:59.999Z`
          : undefined,
      },
      createdAt: {
        gt: allSearchParams.startCreatedAt,
        lt: allSearchParams.endCreatedAt
          ? `${allSearchParams.endCreatedAt}T23:59:59.999Z`
          : undefined,
      },
      objectId: allSearchParams.receipt,
      ...query,
    },
    page,
    size,
    ...(allSearchParams.sortBy && {
      order: {
        [allSearchParams.sortBy]: allSearchParams.order,
      },
    }),
  });

  async function handleOpenFilters() {
    const DeliveryFilters = (
      await import('../../components/Deliveries/Filters')
    ).default;
    openModal(<DeliveryFilters />);
  }

  return {
    data,
    isLoading,
    onChangeStatus,
    status,
    page,
    size,
    order: allSearchParams.order,
    sortBy: allSearchParams.sortBy,
    searchBy: allSearchParams.searchBy,
    query: allSearchParams.query,
    handleOpenFilters,
  };
}
