import { WithTimestamp } from './WithTimestamp';

export type ContestEvent = WithTimestamp<{
  uuid: string;
  gameContestUuid: string;
  startDate: Date;
  endDate: Date;
  status: ContestEventStatus;

  entryPrice: number;
  playerEntryLimit: number;
  totalEntries: number;
}>;

export enum ContestEventStatus {
  COMPLETED = 'completed',
  ACTIVE = 'active',
  CANCELED = 'canceled',
}
