import useNotify from 'src/lib/hooks/useNotify';
import useModalStore from 'src/lib/stores/useModalStore';
import { NFTPackFormValues } from '../../../components/Forms/NftPack/types';
import { NftPackEmitter } from '../../../events/NftPackEmitter';
import { UpdateNftpack } from '../../../services/updateNftPack';

export default function useUpdateNFTPackView() {
  const closeModal = useModalStore((state) => state.closeModal);
  const notify = useNotify();

  const handleUpdateNftPack = async (
    uuid: string,
    values: NFTPackFormValues
  ) => {
    const { error, data } = await UpdateNftpack({ ...values, uuid });

    if (error) {
      notify(error, { variant: 'error' });
      closeModal();
    } else if (data) {
      notify('Nft Pack Updated!', { variant: 'success' });
      closeModal();

      NftPackEmitter.emit('NftPackUpdated', data);
    }
  };

  return {
    handleUpdateNftPack,
  };
}
