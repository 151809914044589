/* eslint-disable no-promise-executor-return */
import { ContentCopy, PlayCircle, Reviews } from '@mui/icons-material';
import { Box, MenuItem, Typography } from '@mui/material';

import Flex from 'src/components/shared/Flex';
import Link from 'src/components/shared/Link';
import MagicTable from 'src/components/shared/MagicTable/MagicTable';
import useURLSearchParams from 'src/lib/hooks/useURLSearchParams';

import Routes from 'src/routes/Routes';
import Image from 'src/template/components/Image';

import LinkIcon from '@mui/icons-material/Link';
import CopyToClipboard from 'react-copy-to-clipboard';
import { ObjectsToModerateResponse } from 'src/components/modules/Review/services/fetchObjectsToReview';
import MagicTableChannelCell from 'src/components/shared/MagicTable/ChannelCell';
import MoreMenu from 'src/components/shared/MoreMenu';
import useModalStore from 'src/lib/stores/useModalStore';
import {
  FeedItem,
  FeedItemContentTypes,
  FeedItemTypes,
  FeedItemWithChannelAndChannelContent,
} from 'src/lib/types/FeedItem';
import { OBJECT_MODERATION_OBJECT_TYPE } from 'src/lib/types/objectModeration';
import { formatUTCDate } from 'src/lib/utils/formatUTCDate';
import { reviewStatusLabel } from '../../../utils/enums';
import VideosReviewForm from '../../ReviewForm';

type VideosToReviewTableProps = {
  data?: ObjectsToModerateResponse;
  isLoading: boolean;
  params: { page: number; size: number }; // 'orderBy' | 'sortOrder'
};

export default function VideosToReviewTable(props: VideosToReviewTableProps) {
  const {
    data,
    isLoading,
    params: { page, size },
  } = props;
  const { addParam, removeParam } = useURLSearchParams();

  const { openModal } = useModalStore();
  const openReviewModal = (
    objectUuid: string,
    objectType: OBJECT_MODERATION_OBJECT_TYPE
  ) => {
    openModal(
      <VideosReviewForm objectUuid={objectUuid} objectType={objectType} />
    );
  };

  return (
    <MagicTable.Container>
      <Box px={2}>
        <MagicTable
          loading={isLoading}
          pageSize={size}
          page={page}
          paginationMode="server"
          onPageChange={(newPage) => {
            addParam('page', newPage);
          }}
          onPageSizeChange={(newSize) => {
            addParam('size', newSize);
          }}
          onSortModelChange={(model) => {
            const currentModel = model[0];
            if (currentModel) {
              addParam('orderBy', currentModel.field);
              if (currentModel.sort) addParam('order', currentModel.sort);
            } else {
              removeParam('order');
              removeParam('orderBy');
            }
          }}
          /* sortModel={[
            {
              field: orderBy,
              sort: sortOrder as GridSortDirection,
            },
          ]} */
          rows={data?.body ?? []}
          rowCount={data?.meta.totalCount ?? 0}
          columns={[
            {
              field: 'humanAnalysisNeeded',
              headerName: 'Human Analysis Needed',
              flex: 1 / 2,
              sortable: false,
              renderCell({
                row,
              }: {
                row: FeedItemWithChannelAndChannelContent;
              }) {
                return (
                  <Typography variant="body2" color="text.secondary">
                    {row.channelContent.humanAnalysisNeeded}
                  </Typography>
                );
              },
            },
            {
              field: 'title',
              headerName: 'Title',
              sortable: false,
              flex: 1 / 2,
              renderCell({ row }: { row: FeedItem }) {
                return (
                  <Link to={Routes.channel.content(row.uuid)}>
                    {row.title ? row.title : '--'}
                  </Link>
                );
              },
            },
            {
              field: 'content',
              headerName: 'Content',
              sortable: false,
              flex: 1 / 2,
              renderCell({ row }: { row: FeedItem }) {
                if (
                  row.contentType === FeedItemContentTypes.video &&
                  row.contentUrl
                )
                  return (
                    <Link to={row.contentUrl} target="_blank">
                      <Flex gap={1} alignItems="center">
                        <PlayCircle />
                        Play Video
                      </Flex>
                    </Link>
                  );

                if (
                  row.contentType === FeedItemContentTypes.image &&
                  row.contentUrl
                )
                  return (
                    <Link to={row.contentUrl} target="_blank">
                      <Flex gap={1} alignItems="center">
                        <LinkIcon />
                        Show Image
                      </Flex>
                    </Link>
                  );

                return (
                  <Typography variant="body2" color="text.secondary">
                    No content found
                  </Typography>
                );
              },
            },
            {
              field: 'channel',
              headerName: 'Channel',
              sortable: false,
              flex: 2 / 3,
              renderCell({ row }) {
                return row.channel ? (
                  <MagicTableChannelCell channel={row.channel} />
                ) : (
                  '--'
                );
              },
            },
            {
              field: 'item',
              headerName: 'Linked Item',
              flex: 1 / 2,
              sortable: false,
              renderCell({ row }: { row: FeedItem }) {
                if (row.itemType === FeedItemTypes.game && row.game)
                  return (
                    <Link to={Routes.game_center.game.view(row.game.uuid)}>
                      <Flex gap={2} alignItems="center">
                        {row.game.versions?.length && (
                          <Image
                            src={row.game.versions[0].imageUrl}
                            alt={row.game.name}
                            sx={{ width: 30, height: 30, borderRadius: 1 }}
                          />
                        )}
                        {row.game.name}
                      </Flex>
                    </Link>
                  );

                if (row.itemType === FeedItemTypes.collection && row.collection)
                  return (
                    <Link to={Routes.collection(row.collection.uuid)}>
                      <Flex gap={2} alignItems="center">
                        {row.collection.coverFileUrl && (
                          <Image
                            src={row.collection.coverFileUrl}
                            alt={row.collection.name}
                            sx={{ width: 30, height: 30, borderRadius: 1 }}
                          />
                        )}
                        {row.collection.name}
                      </Flex>
                    </Link>
                  );

                if (row.itemType === FeedItemTypes.nft && row.nft)
                  return (
                    <Link to={Routes.nft(row.nft.uuid)}>
                      <Flex gap={2} alignItems="center">
                        {row.nft.fileUrl && (
                          <Image
                            src={row.nft.fileUrl}
                            alt={row.nft.name}
                            sx={{ width: 30, height: 30, borderRadius: 1 }}
                          />
                        )}
                        {row.nft.name}
                      </Flex>
                    </Link>
                  );

                if (row.itemType === FeedItemTypes.club && row.club)
                  return (
                    <Link
                      to={Routes.comunity.club(row.club.uuid)}
                      target="_blank"
                    >
                      <Flex gap={2} alignItems="center">
                        <LinkIcon />
                        {row.club.name}
                      </Flex>
                    </Link>
                  );

                return (
                  <Typography variant="body2" color="text.secondary">
                    No item
                  </Typography>
                );
              },
            },
            {
              field: 'createdAt',
              headerName: 'Created At',
              flex: 1 / 2,
              sortable: true,
              sortingOrder: ['asc', 'desc'],
              renderCell({ row }: { row: FeedItem }) {
                return (
                  <Typography variant="body2" color="text.secondary">
                    {formatUTCDate(row.updatedAt)}
                  </Typography>
                );
              },
            },
            {
              field: 'userStatus',
              headerName: 'User Status',
              flex: 1 / 2,
              sortable: false,
              renderCell({
                row,
              }: {
                row: FeedItemWithChannelAndChannelContent;
              }) {
                return (
                  <Typography variant="body2" color="text.secondary">
                    {row.channelContent.userStatus}
                  </Typography>
                );
              },
            },
            {
              field: 'reviewStatus',
              headerName: 'Review Status',
              flex: 1 / 2,
              sortable: false,
              renderCell({
                row,
              }: {
                row: FeedItemWithChannelAndChannelContent;
              }) {
                return (
                  <Typography variant="body2" color="text.secondary">
                    {reviewStatusLabel[row.channelContent.reviewStatus]}
                  </Typography>
                );
              },
            },
            /*
            {
              field: 'visibleStatus',
              headerName: 'Status for User',
              flex: 1 / 2,
              sortable: false,
              renderCell({
                row,
              }: {
                row: FeedItemWithChannelAndChannelContent;
              }) {
                return (
                  <Typography variant="body2" color="text.secondary">
                    {visibleStatusLabel[row.channelContent.visibleStatus]}
                  </Typography>
                );
              },
            },
            */
            {
              field: 'actions',
              headerName: 'Actions',
              align: 'center',
              sortable: false,
              flex: 0,
              renderCell({
                row,
              }: {
                row: FeedItemWithChannelAndChannelContent;
              }) {
                return (
                  <MoreMenu>
                    <CopyToClipboard text={row.uuid}>
                      <MenuItem aria-label="copy feed uuid">
                        <ContentCopy />
                        Copy UUID
                      </MenuItem>
                    </CopyToClipboard>
                    {row.channelContent.lastObjectModerationUuid && (
                      <MenuItem
                        onClick={() =>
                          openReviewModal(
                            row.channelContent.lastObjectModerationUuid!,
                            OBJECT_MODERATION_OBJECT_TYPE.VIDEO
                          )
                        }
                      >
                        <Reviews />
                        Review
                      </MenuItem>
                    )}
                  </MoreMenu>
                );
              },
            },
          ]}
        />
      </Box>
    </MagicTable.Container>
  );
}
