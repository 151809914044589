import { add3HoursToDate } from 'src/lib/utils/addHoursToDate';
import { OrderQuery } from 'src/lib/utils/query/types';
import useSWR from 'swr';
import { PaginatedResponse } from '../../types/PaginatedResponse';
import { DateRange } from '../../types/dateRange';
import {
  MostRefundedUsersByQuantityResponse,
  RefundStatsResponse,
} from '../../types/revenueEvent';
import { FetchRefundsQueryParams, RefundsList } from './types';

export const fetchRefunds = ({
  page,
  size,
  order,
  orderBy,
  types,
  reasons,
  upgradeFilter,
  searchFor,
  query,
  startDate,
  endDate,
}: FetchRefundsQueryParams) => {
  const url = '/admin/revenue-events/refunds'.concat(
    `?order=${order}`,
    `&orderBy=${orderBy}`,
    `&page=${page}`,
    `&size=${size}`,
    types ? `&refundTypes=${JSON.stringify(types)}` : '',
    reasons ? `&reasons=${JSON.stringify(reasons)}` : '',
    upgradeFilter ? `&upgradeFilter=${upgradeFilter}` : '',
    searchFor && query ? `&${searchFor}=${query}` : '',
    startDate && endDate
      ? `&startDate=${add3HoursToDate(
          new Date(startDate)
        ).toISOString()}&endDate=${new Date(endDate).toISOString()}`
      : ''
  );

  const { data, error } = useSWR<PaginatedResponse<RefundsList[]>>(url);

  return {
    data: data?.body,
    total: data?.meta.totalCount || 0,
    loading: !error && !data,
    error: error?.message,
  };
};

export const fetchOriginalTransactionIdRefunds = (
  originalTransactionId: string,
  { order, orderBy }: OrderQuery
) =>
  fetchRefunds({
    page: 0,
    size: 1000,
    searchFor: 'originalTransactionId',
    query: originalTransactionId,
    startDate: new Date(0).getTime(),
    endDate: new Date().getTime(),
    order,
    orderBy,
  });

export const fetchUserRefunds = (
  userId: string,
  { order, orderBy }: OrderQuery
) =>
  fetchRefunds({
    page: 0,
    size: 1000,
    searchFor: 'user',
    query: userId,
    startDate: new Date(0).getTime(),
    endDate: new Date().getTime(),
    order,
    orderBy,
  });

export const fetchRefundsStats = (range: DateRange) => {
  const { data, error } = useSWR<RefundStatsResponse>(
    `/admin/revenue-events/refunds/stats?startDate=${range.startDate}&endDate=${range.endDate}`
  );

  return {
    data,
    loading: !error && !data,
    error: error?.message,
  };
};

export const fetchMostRefundedUsersByQuantity = ({
  startDate,
  endDate,
  page,
  size,
}: FetchRefundsQueryParams) => {
  const { data, error } = useSWR<MostRefundedUsersByQuantityResponse>(
    `/admin/revenue-events/refunds/most-refunded-users-by-quantity?startDate=${startDate}&endDate=${endDate}&page=${page}&size=${size}`
  );

  return {
    data,
    loading: !error && !data,
    error: error?.message,
  };
};

export const fetchMostRefundedUsersByAmount = ({
  startDate,
  endDate,
  page,
  size,
}: FetchRefundsQueryParams) => {
  const { data, error } = useSWR<MostRefundedUsersByQuantityResponse>(
    `/admin/revenue-events/refunds/most-refunded-users-by-amount?startDate=${startDate}&endDate=${endDate}&page=${page}&size=${size}`
  );

  return {
    data,
    loading: !error && !data,
    error: error?.message,
  };
};
