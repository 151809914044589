import createUseTable from '../../../../lib/utils/createUseTable';

export type OrderByOptions = 'name' | 'createdAt' | 'updatedAt';
export type SearchForOptions =
  | 'blockchain'
  | 'mintContract'
  | 'mintToken'
  | 'uuid';

const useStickyNftsTable = createUseTable<OrderByOptions, SearchForOptions>({
  searchFor: 'blockchain',
  order: 'desc',
  orderBy: 'createdAt',
  query: '',
  page: 0,
  size: 10,
  hideOption: 'deletedAt',
  customFilter: {
    ownedEditionsDelta: 'All',
  },
  // custom: {
  //   filters: {
  //     ownedEditionsDelta: 'all'
  //   }
  // }
});

export default useStickyNftsTable;
