import { Container } from '@mui/material';
import OldTransactions from 'src/components/modules/OldTransactions/OldTransactions';
import Page from 'src/template/components/Page';

export default function OldTransactionsPage() {
  return (
    <Page title="Old Transactions">
      <Container maxWidth="xl">
        <OldTransactions />
      </Container>
    </Page>
  );
}
