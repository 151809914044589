import {
  Button,
  Card,
  CardContent,
  CardHeader,
  Stack,
  Typography,
} from '@mui/material';
import Iconify from 'src/template/components/Iconify';

const cryptoData = {
  eth: {
    url: `https://etherscan.io/address/0x09134f8b9470AC70F30555F5Be87F9C43b552e5B`,
    icon: 'ph:currency-eth',
  },
  goerli: {
    url: `https://goerli.etherscan.io/address/0xa26777f009364d62ae47d79029b26233860375dd`,
    icon: 'ph:currency-eth',
  },
  polygon: {
    url: `https://polygonscan.com/address/0x09134f8b9470AC70F30555F5Be87F9C43b552e5B`,
    icon: 'cryptocurrency:matic',
  },
  matic: {
    url: `https://mumbai.polygonscan.com/address/0xa26777f009364d62ae47d79029b26233860375dd`,
    icon: 'cryptocurrency:matic',
  },
};

const { eth, goerli, polygon, matic } = cryptoData;

export default function WalletsBalanceView(): JSX.Element {
  return (
    <Card>
      <CardHeader title="Sticky Crypto Wallets" />
      <CardContent>
        <Stack spacing={1} direction="row" alignItems="center">
          <Typography fontSize={16} fontWeight={600}>
            Balances:{' '}
          </Typography>
          <Stack direction="row" gap={1} flexWrap="wrap">
            <Button
              variant="outlined"
              size="small"
              href={eth.url}
              target="_blank"
              startIcon={<Iconify icon={eth.icon} />}
            >
              ETH
            </Button>
            <Button
              variant="outlined"
              size="small"
              href={goerli.url}
              target="_blank"
              startIcon={<Iconify icon={goerli.icon} />}
            >
              Goerli
            </Button>
            <Button
              variant="outlined"
              size="small"
              href={polygon.url}
              target="_blank"
              startIcon={<Iconify icon={polygon.icon} />}
            >
              Polygon
            </Button>
            <Button
              variant="outlined"
              size="small"
              href={matic.url}
              target="_blank"
              startIcon={<Iconify icon={matic.icon} />}
            >
              Mumbai Matic
            </Button>
          </Stack>
        </Stack>
      </CardContent>
    </Card>
  );
}
