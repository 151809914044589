import useSWR, { SWRResponse } from 'swr';
import { CoinsBreakdown } from '../types/CoinBreakdown';

export const fetchCoinBreakdown = (): SWRResponse<CoinsBreakdown[]> => {
  const { data, ...response } = useSWR(`admin/coins/breakdown`);

  return {
    ...response,
    data,
  };
};
