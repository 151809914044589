import { DEFAULT_INITIAL_PAGE_SIZE } from 'src/lib/constants/pagination';
import useModalStore from 'src/lib/stores/useModalStore';
import useTable from 'src/template/hooks/useTable';

export default function useListStatistics() {
  const {
    page,
    rowsPerPage: size,
    order,
    orderBy,
    setRowsPerPage,
    setOrder,
    setPage,
    setOrderBy,
  } = useTable({
    defaultOrderBy: 'name',
    defaultOrder: 'asc',
    defaultCurrentPage: 0,
    defaultRowsPerPage: DEFAULT_INITIAL_PAGE_SIZE,
    defaultSearchBy: 'name',
  });

  const onClose = useModalStore((state) => state.closeModal);

  const handleCloseModal = () => {
    onClose();
  };

  return {
    params: {
      page,
      size,
      sortOrder: order,
      orderBy,
    },
    setPage,
    setRowsPerPage,
    setOrder,
    setOrderBy,
    handleCloseModal,
  };
}
