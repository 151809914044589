import createUseTable from '../../../../lib/utils/createUseTable';

export type OrderByOptions = 'name' | 'createdAt' | 'updatedAt';
export type SearchForOptions =
  | 'name'
  | 'description'
  | 'user.name'
  | 'slug'
  | 'uuid';

const useNftsTable = createUseTable<OrderByOptions, SearchForOptions>({
  searchFor: 'name',
  order: 'desc',
  orderBy: 'createdAt',
  query: '',
  page: 0,
  size: 10,
});

export default useNftsTable;
