import { Suspense, useCallback, useEffect } from 'react';
import { Navigate, useParams } from 'react-router';
import Loader from 'src/components/shared/Loader';
import { fetchUser } from 'src/lib/services/users';
import getUserName from 'src/lib/utils/getUserName';
import Routes from 'src/routes/Routes';
import HeaderBreadcrumbs from 'src/template/components/HeaderBreadcrumbs';
import LoadingScreen from 'src/template/components/LoadingScreen';
import useTabs from 'src/template/hooks/useTabs';
import ProfileHeader from './ProfileHeader';
import { UserEmitter } from './events/UserEmitter';
import { PROFILE_TABS } from './profileHeaderTabs';

export default function Profile(): JSX.Element {
  const { currentTab, onChangeTab } = useTabs('Account Details', {
    resetOnChange: true,
  });
  const { uuid } = useParams<'uuid'>();
  if (!uuid) return <Navigate to={Routes.users.list} replace />;

  const { data: user, loading, mutate } = fetchUser(uuid);
  if (!user && !loading) return <Navigate to={Routes.notFound} replace />;

  const refetchUser = useCallback(() => mutate?.(), [mutate]);

  useEffect(() => {
    UserEmitter.on('userUpdated', refetchUser);
    return () => {
      UserEmitter.off('userUpdated', refetchUser);
    };
  }, []);

  return (
    <>
      {loading && <LoadingScreen />}
      {user && (
        <>
          <HeaderBreadcrumbs
            heading={getUserName(user)}
            links={[
              { name: 'Users', href: '/users' },
              { name: getUserName(user) || user.uuid },
            ]}
          />
          <ProfileHeader
            onChangeTab={onChangeTab}
            currentTab={currentTab}
            user={user}
          />
          <Suspense fallback={<Loader />}>
            {PROFILE_TABS.map(
              (tab, index) =>
                tab.value === currentTab && tab.component(user, index)
            )}
          </Suspense>
        </>
      )}
    </>
  );
}
