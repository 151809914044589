import { format } from 'date-fns';
import { SearchParams } from 'src/lib/types/SearchParams';
import objectEntries from 'src/lib/utils/objectEntries';
import { parseSearchParams } from 'src/lib/utils/parseSearchParams';
import useSWR, { SWRConfiguration, SWRResponse } from 'swr';
import {
  ParsedHealthItemData,
  ParsedPoolHealth,
  PoolHealth,
  PoolHealthItemData,
} from '../types/PoolsHealth';

function parseItem(item: PoolHealthItemData): ParsedHealthItemData {
  return {
    lastOccurrence: item.lastOccurrence
      ? format(item.lastOccurrence, 'dd/MM/yyyy · HH:mm')
      : null,
    quantity: item.qtd,
    pools: item.pools,
  };
}
function parseData(data: PoolHealth | undefined): ParsedPoolHealth | undefined {
  if (!data) return undefined;
  return objectEntries(data).map(([key, value]) => ({
    name: key,
    uniqueUsersAffecteds: value.uniqueUsersAffecteds,
    failed: parseItem(value.failed),
    pending: parseItem(value.pending),
    success: parseItem(value.success),
    rollback: parseItem(value.rollback),
  }));
}

type Args = {
  params?: SearchParams;
  swrConfig?: SWRConfiguration;
};
export function fetchPoolsHealth(args?: Args): SWRResponse<ParsedPoolHealth> {
  const searchParams = parseSearchParams(args?.params ?? {});
  const { data, ...response } = useSWR(
    `admin/health/pools?${searchParams}`,
    null,
    args?.swrConfig
  );

  return {
    ...response,
    data: parseData(data),
  };
}
