import { CommonDateStrings } from 'src/lib/constants/CommonDates';
import { DEFAULT_INITIAL_PAGE_SIZE } from 'src/lib/constants/pagination';
import useURLSearchParams from 'src/lib/hooks/useURLSearchParams';
import { fetchPlayerGeneralStats } from '../../services/fetchGeneralPlayerStats';
import { fetchPlayerStats } from '../../services/fetchPlayerStats';

export default function usePlayerStats() {
  const { allSearchParams } = useURLSearchParams(initialParams);

  const params = {
    page: +(allSearchParams.page ?? initialParams.page),
    size: +(allSearchParams.size ?? initialParams.size),
    orderBy: allSearchParams.orderBy ?? initialParams.orderBy,
    sortOrder: allSearchParams.order ?? initialParams.order,
    searchBy: allSearchParams.searchBy ?? initialParams.searchBy,
    query: allSearchParams.query ?? initialParams.query,
    startDate: allSearchParams.startDate ?? initialParams.startDate,
    endDate: allSearchParams.endDate ?? initialParams.endDate,
  };

  const { data, isLoading } = fetchPlayerStats({
    startDate: `${params.startDate}T00:00:00.000Z`,
    endDate: `${params.endDate}T23:59:59.999Z`,
    limit: params.size,
    offset: params.size * params.page,
    orderBy: params.orderBy,
    sortOrder: params.sortOrder,
  });

  const { data: generalStatsData, isLoading: generalStatsIsLoading } =
    fetchPlayerGeneralStats({
      startDate: `${params.startDate}T00:00:00.000Z`,
      endDate: `${params.endDate}T23:59:59.999Z`,
    });

  return {
    data,
    isLoading,
    params,
    generalStatsData,
  };
}

const initialParams = {
  page: '0',
  size: DEFAULT_INITIAL_PAGE_SIZE.toString(),
  order: 'desc',
  orderBy: 'uniqueGames',
  searchBy: 'name',
  query: '',
  startDate: CommonDateStrings.sevenDaysAgo.dateTime.split('T')[0],
  endDate: CommonDateStrings.today.endOfDay.split('T')[0],
};

export type FetchPlayerStatsQueryParams = {
  page: number;
  size: number;
  orderBy: string;
  searchBy: string;
  sortOrder: string;
  startDate: string;
  endDate: string;
};
