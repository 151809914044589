import { Send } from '@mui/icons-material';
import {
  Avatar,
  Badge,
  Button,
  IconButton,
  Stack,
  Tooltip,
} from '@mui/material';
import { GridColumns, GridSelectionModel } from '@mui/x-data-grid';
import { useState } from 'react';
import { CreateMultiplePurges } from 'src/components/modules/UserPurges/services/CreateMultiplePurges';
import FilterDateDisplayer from 'src/components/shared/FilterDateDisplayer';
import Flex from 'src/components/shared/Flex';
import Link from 'src/components/shared/Link';
import Loader from 'src/components/shared/Loader';
import MagicTable from 'src/components/shared/MagicTable/MagicTable';
import NumberInfo from 'src/components/shared/NumberInfo/NumberInfo';
import useNotify from 'src/lib/hooks/useNotify';
import useURLSearchParams from 'src/lib/hooks/useURLSearchParams';
import Routes from 'src/routes/Routes';
import Iconify from 'src/template/components/Iconify';
import Label from 'src/template/components/Label';
import {
  ImporterAndCreatorTotalStatistics,
  ParsedUserImporterAndCreatorRank,
} from '../../../types/Ranking';
import { UserType, UserTypeLabel } from '../../../types/UserType';

const cardWidth: number = 200;

const columns: GridColumns<any> = [
  {
    field: 'pos',
    headerName: 'Pos',
    align: 'left',
    flex: 1 / 4,
    sortable: false,
  },
  {
    field: 'active',
    headerName: 'Active',
    align: 'left',
    sortable: false,
    flex: 1 / 3,
    renderCell: ({ row }) => {
      if (row.active) {
        return <Label color="success">Active</Label>;
      }
      return <Label color="error">Inactive</Label>;
    },
  },
  {
    field: 'userType',
    headerName: 'Type',
    align: 'left',
    sortable: false,
    valueFormatter: ({ value }) => UserTypeLabel[value as UserType],
  },
  {
    field: 'name',
    headerName: 'Name',
    align: 'left',
    sortable: false,
    renderCell: ({ row }) => (
      <>
        <Avatar alt={row.name} src={row.profileImage} sx={{ mr: 2 }} />

        <Link
          to={Routes.user(row.uuid)}
          color={row.name ? 'inherit' : 'text.secondary'}
          underline="hover"
        >
          {row.name ?? 'empty'}
        </Link>
      </>
    ),
  },
  {
    field: 'slug',
    headerName: 'Slug',
    align: 'left',
    sortable: false,
  },
  {
    field: 'email',
    headerName: 'Email',
    align: 'left',
    sortable: false,
  },
  {
    field: 'created',
    headerName: 'Created',
    align: 'right',
    flex: 1 / 2,
    sortingOrder: ['desc'],
    sortComparator: () => 0,
  },
  {
    field: 'imported',
    headerName: 'Imported',
    align: 'right',
    flex: 1 / 2,
    sortingOrder: ['desc'],
    sortComparator: () => 0,
  },
  {
    field: 'total',
    headerName: 'Total',
    align: 'right',
    flex: 1 / 2,
    sortingOrder: ['desc'],
    sortComparator: () => 0,
  },
];
type Props = {
  data: ParsedUserImporterAndCreatorRank[];
  stats: ImporterAndCreatorTotalStatistics | undefined;
  isLoading: boolean;
  handleOpenFilter: () => void;
  page: number;
  size: number;
  rankBy: string;
};
export default function ImporterAndCreatorRankTableView({
  data,
  stats: { totalCount, totalCreated, totalImported, total } = defaultStats,
  isLoading,
  handleOpenFilter,
  page,
  size,
  rankBy,
}: Props): JSX.Element {
  const { addParam } = useURLSearchParams();
  const notify = useNotify();
  const [selected, setSelected] = useState<string[]>([]);
  const handleSelect = (newSelection: GridSelectionModel) => {
    const selectedUsers =
      newSelection?.map((uuid) => uuid.toString() ?? '') || [];
    setSelected(selectedUsers);
  };

  const [isSendingToPurgeList, setIsSendingToPurgeList] = useState(false);

  const handleSendToPurgeList = async () => {
    setIsSendingToPurgeList(true);

    const { data: purges, error } = await CreateMultiplePurges(selected);
    if (error) {
      notify(error, { variant: 'error' });
      setIsSendingToPurgeList(false);
      return;
    }

    notify(`${purges?.length} users have been sent to the purge list.`, {
      variant: 'success',
    });

    setSelected([]);
    setIsSendingToPurgeList(false);
  };

  return (
    <>
      {selected.length > 0 && (
        <Flex
          justifyContent="flex-end"
          alignItems="center"
          p={2}
          position="fixed"
          bottom={0}
          left={0}
          zIndex={200}
          sx={{
            width: '100%',
            backgroundColor: 'rgba(255, 255, 255, 0.5)',
            height: 70,
            px: 2,
            backdropFilter: 'blur(10px)',
          }}
        >
          <Stack direction="row" spacing={2}>
            <Button
              variant="contained"
              disabled={!selected.length || isSendingToPurgeList}
              startIcon={isSendingToPurgeList ? <Loader size={15} /> : <Send />}
              sx={{ alignSelf: 'flex-end' }}
              color="info"
              onClick={handleSendToPurgeList}
            >
              {isSendingToPurgeList && 'Sending...'}
              {!isSendingToPurgeList && (
                <>
                  Send {selected.length}{' '}
                  {selected.length > 1 ? 'Users' : 'User'} to Purge List
                </>
              )}
            </Button>
            <Button
              variant="outlined"
              onClick={() => setSelected([])}
              color="info"
              disabled={isSendingToPurgeList}
            >
              Clear Selection
            </Button>
          </Stack>
        </Flex>
      )}
      <Stack>
        <Flex justifyContent="space-between" px={2}>
          <FilterDateDisplayer />
          <Tooltip title="Filters" placement="left" arrow>
            <Badge color="info" overlap="circular" variant="dot">
              <IconButton onClick={handleOpenFilter}>
                <Iconify icon="lucide:filter" />
              </IconButton>
            </Badge>
          </Tooltip>
        </Flex>
        <Flex
          width="100%"
          justifyContent="start"
          gap={2}
          flexWrap="wrap"
          px={2}
          pb={2}
        >
          <NumberInfo
            title="Users"
            total={totalCount}
            componentProps={{
              sx: {
                width: cardWidth,
              },
            }}
          />
          <NumberInfo
            title="Created"
            total={totalCreated}
            componentProps={{
              sx: {
                width: cardWidth,
              },
            }}
          />
          <NumberInfo
            title="Imported"
            total={totalImported}
            componentProps={{
              sx: {
                width: cardWidth,
              },
            }}
          />
          <NumberInfo
            title="Total"
            total={total}
            componentProps={{
              sx: {
                width: cardWidth,
              },
            }}
          />
        </Flex>
      </Stack>
      <MagicTable
        autoHeight
        loading={isLoading}
        columns={columns}
        pageSize={size}
        page={page}
        rowCount={totalCount}
        paginationMode="server"
        sortModel={[{ field: rankBy, sort: 'desc' }]}
        onPageChange={(newPage) => {
          addParam('page', newPage);
        }}
        onPageSizeChange={(newSize) => {
          addParam('size', newSize);
        }}
        onSortModelChange={(model) => {
          const currentModel = model[0];
          if (currentModel) {
            addParam('rankBy', currentModel.field);
          }
        }}
        rows={data.map((r) => ({
          ...r,
          id: r.uuid,
        }))}
        checkboxSelection
        onSelectionModelChange={handleSelect}
        selectionModel={selected}
      />
    </>
  );
}

const defaultStats: ImporterAndCreatorTotalStatistics = {
  totalCount: 0,
  totalImported: 0,
  totalCreated: 0,
  total: 0,
};
