import useNotify from 'src/lib/hooks/useNotify';
import useModalStore from 'src/lib/stores/useModalStore';
import { ChannelEmitter } from '../../events/ChannelEmitter';
import { UpdateChannel } from '../../services/updateChannel';

export default function useChangeChannelIsSticky() {
  const closeModal = useModalStore((state) => state.closeModal);
  const notify = useNotify();

  const handleChangeChannelIsSticky = async (
    uuid: string,
    isSticky: boolean
  ) => {
    const { data, error } = await UpdateChannel(uuid, { isSticky });
    if (error) {
      notify(error, { variant: 'error' });
      closeModal();
    } else {
      notify('Channel Updated!', { variant: 'success' });
      closeModal();
      ChannelEmitter.emit('ChannelUpdated', data);
    }
  };

  return {
    handleChangeChannelIsSticky,
  };
}
