import { WithTimestamp } from './WithTimestamp';
import { Product } from './product';

export type NftPack = WithTimestamp<{
  uuid: string;
  productUuid: string;
  name: string;
  type: string;
  status: NftPackStatus;
  amountOfNftsToRecharge: number;
}>;

export type NftPackDetails = WithTimestamp<{
  name: string;
  type: string;
  amountOfNftsToRecharge: number;

  uuid: string;
  product: Product;
  nftQuantity: number;
  pricePerNFT: number;
  status: NftPackStatus;
  rarities: Rarity[];
}>;

export type Rarity = WithNftPackRarity<
  WithTimestamp<{
    uuid: string;
    name: string;
    description: string;
  }>
>;

export type NftPackRarity = WithTimestamp<{
  uuid: string;
  nftPackUuid: string;
  rarityUuid: string;
  proportion: number;
}>;

export type WithNftPackRarity<T> = {
  nftPackRarity: NftPackRarity;
} & T;

export enum NftPackStatus {
  DRAFT = 'draft',
  READY = 'ready',
}
