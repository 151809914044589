import { Container } from '@mui/material';
import HeaderBreadcrumbs from 'src/template/components/HeaderBreadcrumbs';
import SubscriptionsTableView from '../../components/SubscriptionsTable';

export default function SubscriptionsView(): JSX.Element {
  return (
    <Container maxWidth="xl">
      <HeaderBreadcrumbs
        heading="Subscriptions"
        links={[{ name: 'Subscriptions List' }]}
      />
      <SubscriptionsTableView />
    </Container>
  );
}
