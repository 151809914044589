import { DeepPartial } from 'react-hook-form';
import { PlayerGeneralStats } from 'src/lib/types/gameSession';
import { parseSearchParams } from 'src/lib/utils/parseSearchParams';
import useSWR, { SWRResponse } from 'swr';

export type PossibleParams = {
  startDate: string;
  endDate: string;
};

export const fetchPlayerGeneralStats = (
  filter?: DeepPartial<PossibleParams>
): SWRResponse<PlayerGeneralStats> => {
  const parsedParams = parseSearchParams(filter ?? {});
  const response = useSWR<PlayerGeneralStats, Error>(
    `admin/game-session/player/general-stats/?${parsedParams}`
  );

  return response;
};
