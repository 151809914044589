import { useCallback, useEffect } from 'react';
import { DEFAULT_INITIAL_PAGE_SIZE } from 'src/lib/constants/pagination';
import useURLSearchParams from 'src/lib/hooks/useURLSearchParams';
import useModalStore from 'src/lib/stores/useModalStore';
import { NftPack, NftPackStatus } from 'src/lib/types/nftPack';
import useTabs from 'src/template/hooks/useTabs';
import { NftPackEmitter } from '../../../events/NftPackEmitter';
import { fetchNftPacks } from '../../../services/fetchNftPacks';
import ChangeNftPackStatusView from '../ChangeStatus';
import CreateNftPackView from '../Create';
import DeleteNftPackView from '../Delete';
import UpdateNftPackView from '../Update';

export default function useNftPacksView() {
  const openModal = useModalStore((state) => state.openModal);

  const handleOpenCreateModal = async () => {
    openModal(<CreateNftPackView />);
  };
  const handleOpenUpdateModal = async (nftPack: NftPack) => {
    openModal(<UpdateNftPackView nftPack={nftPack} />);
  };
  const handleOpenDeleteModal = async (uuid: string) => {
    openModal(<DeleteNftPackView uuid={uuid} />);
  };
  const handleOpenChangeStatusModal = async (
    uuid: string,
    status: NftPackStatus
  ) => {
    openModal(<ChangeNftPackStatusView uuid={uuid} status={status} />);
  };

  const { currentTab: status, onChangeTab: onChangeStatus } = useTabs('all', {
    queryName: 'status',
  });
  const { allSearchParams } = useURLSearchParams({
    sortBy: 'updatedAt',
    order: 'desc',
    page: '0',
    size: DEFAULT_INITIAL_PAGE_SIZE.toString(),
    searchBy: 'name',
  });
  const page = Number(allSearchParams.page);
  const size = Number(allSearchParams.size);

  const filters = {
    status: status === 'all' ? undefined : status,
  };

  const query: any = {};
  if (allSearchParams.searchBy && allSearchParams.query) {
    query[allSearchParams.searchBy] = {
      like: `%${allSearchParams.query}%`,
    };
  }

  const { data, isLoading, mutate } = fetchNftPacks({
    filter: { ...filters, ...query },
    page,
    size,
    ...(allSearchParams.sortBy && {
      order: {
        [allSearchParams.sortBy]: allSearchParams.order,
      },
    }),
  });

  const refetchData = useCallback(() => {
    mutate?.();
  }, []);

  useEffect(() => {
    NftPackEmitter.on('NftPackCreated', refetchData);
    NftPackEmitter.on('NftPackUpdated', refetchData);
    NftPackEmitter.on('NftPackDeleted', refetchData);

    return () => {
      NftPackEmitter.off('NftPackCreated', refetchData);
      NftPackEmitter.off('NftPackUpdated', refetchData);
      NftPackEmitter.off('NftPackDeleted', refetchData);
    };
  }, [refetchData]);

  return {
    data,
    isLoading,
    onChangeStatus,
    status,
    page,
    size,
    order: allSearchParams.order,
    sortBy: allSearchParams.sortBy,
    searchBy: allSearchParams.searchBy,
    query: allSearchParams.query,
    handleOpenCreateModal,
    handleOpenUpdateModal,
    handleOpenDeleteModal,
    handleOpenChangeStatusModal,
  };
}
