import { Delete, Edit } from '@mui/icons-material';
import { Avatar, MenuItem } from '@mui/material';
import { GridSortDirection } from '@mui/x-data-grid';
import Link from 'src/components/shared/Link';
import MagicTable from 'src/components/shared/MagicTable/MagicTable';
import MoreMenu from 'src/components/shared/MoreMenu';
import useURLSearchParams from 'src/lib/hooks/useURLSearchParams';
import { NftsIntoNftPack } from 'src/lib/types/rarity';
import { getImageURL } from 'src/lib/utils/nft';
import Routes from 'src/routes/Routes';

interface NFTSForPacksTableProps {
  data: NftsIntoNftPack[];
  isLoading: boolean;
  page: number;
  size: number;
  sortBy: string;
  order: GridSortDirection;

  handleDeleteNftFromPack: (nfts: string[]) => void;
  handleUpdateNft: (
    nftUuid: string,
    currentStatus: { faceValue: number; rarityUuid: string }
  ) => void;
}

export default function NFTSInPackTable({
  data,
  isLoading,
  page,
  size,
  sortBy,
  order,
  handleDeleteNftFromPack,
  handleUpdateNft,
}: NFTSForPacksTableProps): JSX.Element {
  const { addParam, removeParam } = useURLSearchParams();

  return (
    <MagicTable
      rows={data}
      getRowId={(r) => r.uuid}
      loading={isLoading}
      paginationMode="client"
      pageSize={size}
      page={page}
      onPageChange={(newPage) => {
        addParam('page', newPage);
      }}
      onPageSizeChange={(newSize) => {
        addParam('size', newSize);
      }}
      sortModel={[{ field: sortBy, sort: order }]}
      onSortModelChange={(model) => {
        const currentModel = model[0];
        if (currentModel) {
          addParam('sortBy', currentModel.field);
          if (currentModel.sort) addParam('order', currentModel.sort);
        } else {
          removeParam('order');
          removeParam('sortBy');
        }
      }}
      columns={[
        {
          field: 'nft',
          headerName: 'Name',
          sortable: true,
          sortComparator: (v1, v2) => v1.name.localeCompare(v2.name),
          renderCell: ({ row }) => (
            <>
              <Avatar
                alt={row.nft.name}
                src={getImageURL(row.nft.fileUrl)}
                variant="rounded"
                sx={{ mr: 2 }}
              />
              <Link
                to={Routes.nft(row.nft.uuid)}
                color={row.nft.name ? 'inherit' : 'text.secondary'}
                underline="hover"
                display="inline-block"
              >
                {row.nft.name ?? 'empty'}{' '}
              </Link>
            </>
          ),
        },
        {
          field: 'rarity',
          headerName: 'Rarity',
          sortable: true,
          sortComparator: (v1, v2) => v1.name.localeCompare(v2.name),
          renderCell: ({ row }) => row.rarity.name,
        },
        {
          field: 'faceValue',
          headerName: 'Face Value',
          sortable: true,
          sortComparator: (v1, v2) => v1 - v2,
          valueFormatter: ({ value }) => `${value / 1000}`,
        },
        {
          field: 'actions',
          headerName: 'Actions',
          align: 'center',
          sortable: false,
          flex: 0,
          renderCell({ row }) {
            return (
              <MoreMenu>
                <MenuItem
                  onClick={() => {
                    handleUpdateNft(row.nft.uuid, {
                      faceValue: row.faceValue / 1000,
                      rarityUuid: row.rarity.uuid,
                    });
                  }}
                >
                  <Edit />
                  Update
                </MenuItem>
                <MenuItem
                  onClick={() => {
                    handleDeleteNftFromPack([row.nft.uuid]);
                  }}
                >
                  <Delete />
                  Remove
                </MenuItem>
              </MoreMenu>
            );
          },
        },
      ]}
    />
  );
}
