import { useEffect, useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import { PersistentQueryParser } from './PersistentQueryParser';
import { QueryParams } from './types';

export const usePersistentQueryParams = <T extends QueryParams>(
  initialValues: T
) => {
  const [urlSearchParams, setUrlSearchParams] = useSearchParams(
    PersistentQueryParser.fromQuery(initialValues)
  );

  const [query, setQuery] = useState(
    PersistentQueryParser.toQuery<T>(
      Object.keys(initialValues),
      urlSearchParams
    )
  );

  useEffect(() => {
    const newUrl = PersistentQueryParser.fromQuery(query);

    Object.keys(newUrl).forEach((key) => {
      urlSearchParams.set(key, newUrl[key]);
    });

    setUrlSearchParams(urlSearchParams);
  }, [query]);

  return {
    query,
    setQuery,
    updateQuery: (values: Partial<T>) =>
      setQuery({
        ...query,
        ...values,
      }),
  };
};
