import { DeepPartial } from 'react-hook-form';
import { PaginatedResponse } from 'src/lib/types/PaginatedResponse';
import { SGMChatWithAssets } from 'src/lib/types/sgmChatMessage';
import { parseSearchParams } from 'src/lib/utils/parseSearchParams';
import useSWR, { SWRResponse } from 'swr';

export type PossibleParams = {
  limit: number | string;
  offset: number | string;
  order: Record<string, string>;
  filter: {
    userUuid: string;
    'c.createdAt': {
      gte: string;
      lte: string;
    };
    gpt_4o: {
      gte: number;
      lte: number;
    };
    o1_preview: {
      gte: number;
      lte: number;
    };
    totalUserMessages: {
      gte: number;
      lte: number;
    };
    totalCount: {
      gte: number;
      lte: number;
    };
    title: string;
    published: boolean;
  };
};

export const fetchMakerDialogs = (
  filter?: DeepPartial<PossibleParams>
): SWRResponse<PaginatedResponse<SGMChatWithAssets[]>> => {
  const parsedParams = parseSearchParams(filter ?? {});
  const response = useSWR<PaginatedResponse<SGMChatWithAssets[]>, Error>(
    `admin/game-maker-chats/?${parsedParams}`
  );

  return response;
};
