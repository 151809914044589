import useNotify from 'src/lib/hooks/useNotify';
import useModalStore from 'src/lib/stores/useModalStore';
import { EntityNoteFormValues } from '../../components/Form/types';
import { EntityNoteEmitter } from '../../events/EntityNoteEmitter';
import { CreateEntityNote } from '../../services/createEntityNote';

export default function useCreateEntityNote() {
  const closeModal = useModalStore((state) => state.closeModal);
  const notify = useNotify();

  const handleCreateEntityNote = async (values: EntityNoteFormValues) => {
    const { error, data } = await CreateEntityNote({
      ...values,
    });
    if (error) {
      notify(error, { variant: 'error' });
      closeModal();
    } else if (data) {
      notify('Entity note Created!', { variant: 'success' });
      closeModal();

      EntityNoteEmitter.emit('entityNoteCreated', data);
    }
  };

  return {
    handleCreateEntityNote,
  };
}
