import { WithTimestamp } from './WithTimestamp';
import { GameAssetAction, GameAssetType } from './asset';
import { GameStatistic } from './gameStatistic';

export type GameStatisticGroup = WithTimestamp<
  {
    uuid: string;
    name: string;
  } & (
    | {
        type: StatisticGroupTypes.STATISTIC_LIST;
        statisticsListUuid: string;
        statisticsList?: GameStatisticList;
      }
    | {
        type: StatisticGroupTypes.ASSET_GROUP;
        assetGroupUuid: string;
        assetGroup?: GameAssetGroup;
      }
  )
>;

export enum StatisticGroupTypes {
  STATISTIC_LIST = 'STATISTIC_LIST',
  ASSET_GROUP = 'ASSET_GROUP',
}

export type GameStatisticList = WithTimestamp<{
  uuid: string;
  statistics?: GameStatistic[];
}>;

export type GameAssetGroup = WithTimestamp<{
  uuid: string;
  assetTypeUuid: string;
  assetActionUuid: string;
  assetType: GameAssetType;
  assetAction: GameAssetAction;
}>;
