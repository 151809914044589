import { DeepPartial } from 'react-hook-form';
import { type Fetch } from 'src/lib/types/fetch';
import { parseSearchParams } from 'src/lib/utils/parseSearchParams';
import useSWR from 'swr';
import { PayoutCountry } from '../types/PayoutCountries';

export const fetchPayoutCountries = (
  params?: DeepPartial<PossibleParams>
): Fetch<PayoutCountry[]> => {
  const parsedParams = parseSearchParams(params ?? {});
  const { data, error, isLoading, mutate } = useSWR<PayoutCountry[]>(
    `admin/payout-countries?${parsedParams}`
  );

  return {
    data,
    error,
    loading: isLoading,
    mutate,
  };
};

type PossibleParams = {
  filter: {
    name: {
      like: string;
    };
    code: {
      like: string;
    };
    active: boolean | string;
  };
};
