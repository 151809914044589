import useNotify from 'src/lib/hooks/useNotify';
import useModalStore from 'src/lib/stores/useModalStore';
import { GameAssetEmitter } from '../../events/GameAssetEmitter';
import { removeAssetFromContest } from '../../services/removeAssetFromContest';

export default function useDeleteAsset() {
  const closeModal = useModalStore((state) => state.closeModal);
  const notify = useNotify();

  const handleDeleteAsset = async (contestUuid: string, assetUuid: string) => {
    const { data, error } = await removeAssetFromContest(
      contestUuid,
      assetUuid
    );
    if (error) {
      notify(error, { variant: 'error' });
      closeModal();
    } else {
      notify('Asset deleted!', { variant: 'success' });
      closeModal();
      GameAssetEmitter.emit('GameAssetRemoved', data);
    }
  };

  return {
    handleDeleteAsset,
  };
}
