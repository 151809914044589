/* eslint-disable @typescript-eslint/naming-convention */
/* eslint-disable no-underscore-dangle */
import useSWR, { mutate } from 'swr';
import useSWRInfinite from 'swr/infinite';
import { PaginatedResponse } from '../types/PaginatedResponse';
import { BracketFilterParams } from '../types/bracketFilterParams';
import { Fetch } from '../types/fetch';
import { NFT } from '../types/nft';
import { NftBulkCreate } from '../types/nftBulkCreate';
import generateSearchRequest from '../utils/generateSearchRequest';
import { parseSearchParams } from '../utils/parseSearchParams';
import getAPIClient from './api/axios.config';
import { infiniteListFetcher } from './api/fetcher';

/**
 * Fetches a NFT
 * @param {string} uuid - The NFT's uuid
 * @returns {Fetch<NFT>} returns a NFT that follows swr's fetch format
 */
export const fetchNFT = (uuid: string): Fetch<NFT> => {
  const { data, error } = useSWR<NFT, Error>(`admin/nfts/${uuid}`);

  return {
    data,
    loading: !error && !data,
    error: error?.message,
  };
};

export const fetchNfts = (filters: any, shouldNotFetch = false) => {
  const { data, error } = useSWR<PaginatedResponse<NFT[]>, Error>(
    shouldNotFetch
      ? null
      : `admin/nfts/search?${parseSearchParams(filters ?? {})}`
  );

  return {
    data: data?.body,
    count: data?.meta?.totalCount,
    loading: !error && !data,
    error: error?.message,
  };
};

export const fetchStickyNftsRealTime = (filters: any): any => {
  const { data, error } = useSWR<PaginatedResponse<NFT[]>, Error>(
    `admin/ownership/search?${parseSearchParams(filters ?? {})}`
  );

  return {
    data: data?.body,
    loading: !error && !data,
    error: error?.message,
  };
};

export const fetchStickyNftsPreloaded = (filters: any): any => {
  // const { data, error } = useSWR<PaginatedResponse<NFT[]>, Error>(
  const { data, error } = useSWR<PaginatedResponse<any>, Error>(
    `admin/ownership/list-preloaded?${parseSearchParams(filters ?? {})}`
  );

  return {
    data: data?.body,
    totalCount: data?.meta?.totalCount,
    loading: !error && !data,
    error: error?.message,
  };
};

export const fetchStickyNftsTotalBalance = (filters?: any): any => {
  const { data, error } = useSWR<any, Error>(
    `admin/ownership/total-balance?${parseSearchParams(filters ?? {})}`
  );

  return {
    totalBalance: data?.totalBalance,
    loading: !error && !data,
    error: error?.message,
  };
};

export const refreshStickyNftsPreloadedTable = async (): Promise<any> => {
  // const { data, error } = useSWR<any, Error>(
  //   `admin/ownership/refresh-preloaded-table`
  // );

  const api = await getAPIClient();

  const res = await api.post(
    // const { data, error } = await api.post(
    `admin/ownership/refresh-preloaded-table`
    // nftData
  );

  const data = res?.data;
  const error = res?.data?.error;
  // return res;

  return {
    data,
    loading: !error && !data,
    error: error?.message,
  };
};

export const fetchNftsCount = (filters: any): any => {
  const { data, error } = useSWR<PaginatedResponse<NFT[]>, Error>(
    `admin/nfts/count?${parseSearchParams(filters ?? {})}`
  );

  return {
    data: data?.body,
    total: data?.meta?.totalCount,
    loading: !error && !data,
    error: error?.message,
  };
};

export const fetchStickyNftsCount = (filters: any): any => {
  const { data, error } = useSWR<PaginatedResponse<NFT[]>, Error>(
    `admin/ownership/count?${parseSearchParams(filters ?? {})}`
  );

  return {
    // data: data?.body,
    totalCount: data?.meta?.totalCount,
    loading: !error && !data?.meta,
    error: error?.message,
  };
};

export const fetchNftOwnershipVerification = (filters: any) => {
  const { data, error } = useSWR<PaginatedResponse<any>, Error>(
    `admin/ownership/verify-crypto-ownerships?${parseSearchParams(
      filters ?? {}
    )}`
  );

  return {
    data: data?.body,
    loading: !error && !data,
    error: error?.message,
  };
};

export const fetchNftsFromCollection = (
  collection: string,
  filter: BracketFilterParams
) => {
  const {
    data,
    error,
    setSize,
    size,
    mutate: refetch,
  } = useSWRInfinite(
    (index) =>
      `admin/nfts/${generateSearchRequest({
        ...filter,
        page: index,
      })}&order[collectionOrder]=asc&order[uuid]=asc&filter[collectionUuid][eq]=${collection}`,

    infiniteListFetcher
  );

  const isLoadingInitialData = !data && !error;
  const isLoadingMore = Boolean(
    isLoadingInitialData || (data && data.length < size)
  );
  const hasReachedEnd = Boolean(
    data && data[data.length - 1].length < filter.size
  );

  return {
    data: data ? [].concat(...data) : [],
    setSize,
    loading: isLoadingMore,
    hasMore: !hasReachedEnd,
    error: error?.message,
    refetch,
  };
};

export const bulkCreateNft = async (
  collectionUuid: string,
  nftData: NftBulkCreate
): Promise<NFT[]> => {
  const api = await getAPIClient();

  const res = await api.post(
    `admin/collections/${collectionUuid}/bulk-create-nfts`,
    nftData
  );

  return res.data;
};

export const updateNft = async (data: Partial<NFT>) => {
  const api = await getAPIClient();

  const res = await api.post(`admin/nfts`, {
    ...data,
  });

  mutate(`admin/nfts/${data.uuid}`);
  return res;
};

export const deleteNft = async (uuid: string) => {
  const api = await getAPIClient();

  const res = await api.delete(`admin/nfts/${uuid}`);

  return res;
};
