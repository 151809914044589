export type SystemUptime = {
  uuid: string;
  systemName: System;
  status: 'UP' | 'DOWN';
  startTime: number;
  lastCheck: number;
  endTime?: number;
  createdAt: number;
  updatedAt: number;
};
export type System =
  | 'EMS_MAIN'
  | 'polygon_BLS_verifyDeploy'
  | 'polygon_BLS_MAIN'
  | 'polygon_BLS_verifyMint'
  | 'polygon_BLS_mintNft'
  | 'ethereum_BLS_mintNft'
  | 'ethereum_BLS_MAIN'
  | 'ethereum_BLS_marketplaceBuy'
  | 'polygon_BLS_verifyMarketplaceBuy'
  | 'polygon_BLS_transferNft'
  | 'ethereum_BLS_verifyMarketplaceBuy'
  | 'ethereum_BLS_deploy'
  | 'EMS_importCollections'
  | 'ethereum_BLS_verifyDeploy'
  | 'ethereum_BLS_verifyMint'
  | 'polygon_BLS_deploy'
  | 'EMS_syncWallet'
  | 'polygon_BLS_marketplaceBuy'
  | 'ethereum_BLS_verifyTransfer'
  | 'polygon_BLS_verifyTransfer'
  | 'EMS_streamOrders'
  | 'ethereum_BLS_transferNft';

export const SystemLabel: Record<System, string> = {
  EMS_MAIN: 'EMS Main',
  polygon_BLS_verifyDeploy: 'Polygon Verify Deploy',
  polygon_BLS_MAIN: 'Polygon Main',
  polygon_BLS_verifyMint: 'Polygon Verify Mint',
  polygon_BLS_mintNft: 'Polygon Mint NFT',
  ethereum_BLS_mintNft: 'Ethereum Mint NFT',
  ethereum_BLS_MAIN: 'Ethereum Main',
  ethereum_BLS_marketplaceBuy: 'Ethereum Marketplace Buy',
  polygon_BLS_verifyMarketplaceBuy: 'Polygon Verify Marketplace Buy',
  polygon_BLS_transferNft: 'Polygon Transfer NFT',
  ethereum_BLS_verifyMarketplaceBuy: 'Ethereum Verify Marketplace Buy',
  ethereum_BLS_deploy: 'Ethereum Deploy',
  EMS_importCollections: 'EMS Import Collections',
  ethereum_BLS_verifyDeploy: 'Ethereum Verify Deploy',
  ethereum_BLS_verifyMint: 'Ethereum Verify Mint',
  polygon_BLS_deploy: 'Polygon Deploy',
  EMS_syncWallet: 'EMS Sync Wallet',
  polygon_BLS_marketplaceBuy: 'Polygon Marketplace Buy',
  ethereum_BLS_verifyTransfer: 'Ethereum Verify Transfer',
  polygon_BLS_verifyTransfer: 'Polygon Verify Transfer',
  EMS_streamOrders: 'EMS Stream Orders',
  ethereum_BLS_transferNft: 'Ethereum Transfer NFT',
} as const;
