import React, { ReactElement } from 'react';
import { Game } from 'src/lib/types/game';
import { OBJECT_MODERATION_OBJECT_TYPE } from 'src/lib/types/objectModeration';
import Iconify, { IconifyProps } from 'src/template/components/Iconify';
import GamesSessions from '../ListSessions';
import { GameDescriptionPrompt } from '../PromptDescription';

const GameRankings = React.lazy(
  () => import('src/components/modules/GameRankings/view/GameRankings')
);
const Contests = React.lazy(
  () => import('src/components/modules/Contests/view/List')
);
const StatisticGroups = React.lazy(
  () => import('src/components/modules/GameStatisticGroups/view/List')
);
const GameAnalytics = React.lazy(
  () => import('src/components/modules/Games/view/Analytics')
);
const MainInfo = React.lazy(
  () => import('src/components/modules/Games/view/MainInfo')
);
const ObjectModerationTab = React.lazy(
  () => import('src/components/modules/Review/components/ModerationObjectsTab')
);
const DialogTab = React.lazy(
  () => import('src/components/modules/MakerDialog/view/Details')
);

export enum GameTabs {
  MAIN = 'main_info',
  VERSIONS = 'versions',
  CONTESTS = 'contests',
  RANKINGS = 'rankings',
  STATISTICS_GROUPS = 'statistics_groups',
  ANALYTICS = 'analytics',
  SESSIONS = 'sessions',
  MODERATION = 'moderation',
  CHAT = 'chat',
  PROMPT_DESCRIPTION = 'prompt_description',
}

interface IGameTabs {
  value: GameTabs;
  icon: ReactElement<IconifyProps>;
  component: (game: Game) => JSX.Element;
}

const GAME_TABS: IGameTabs[] = [
  {
    value: GameTabs.MAIN,
    icon: <Iconify icon="mdi:gamepad-variant-outline" width={20} height={20} />,
    component: () => <MainInfo />,
  },
  {
    value: GameTabs.ANALYTICS,
    icon: <Iconify icon="mdi:chart-line" width={20} height={20} />,
    component: () => <GameAnalytics />,
  },
  {
    value: GameTabs.SESSIONS,
    icon: <Iconify icon="mdi:history" width={20} height={20} />,
    component: () => <GamesSessions />,
  },
  {
    value: GameTabs.RANKINGS,
    icon: <Iconify icon="fa6-solid:ranking-star" width={20} height={20} />,
    component: () => <GameRankings />,
  },
  {
    value: GameTabs.CONTESTS,
    icon: <Iconify icon="mdi:medal" width={20} height={20} />,
    component: () => <Contests />,
  },
  {
    value: GameTabs.STATISTICS_GROUPS,
    icon: <Iconify icon="gridicons:stats-up" width={20} height={20} />,
    component: () => <StatisticGroups />,
  },
  {
    value: GameTabs.MODERATION,
    icon: <Iconify icon="mdi:shield-account" width={20} height={20} />,
    component: (game) => (
      <ObjectModerationTab
        objectType={OBJECT_MODERATION_OBJECT_TYPE.GAME_VERSION}
        objectUuid={game.versions?.[0].uuid!}
        showHeader
      />
    ),
  },
  {
    value: GameTabs.CHAT,
    icon: <Iconify icon="mdi:chat" width={20} height={20} />,
    component: (game) => <DialogTab promptDialogUuid={game.promptDialogUuid} />,
  },
  {
    value: GameTabs.PROMPT_DESCRIPTION,
    icon: (
      <Iconify icon="material-symbols:info-outline" width={20} height={20} />
    ),
    component: (game) => (
      <GameDescriptionPrompt
        gameUuid={game.uuid}
        gameDescriptionPrompt={game.aiDescription}
      />
    ),
  },
];

export default GAME_TABS;
