import ContestEvents from 'src/components/modules/ContestEvents/view/List';
import Page from 'src/template/components/Page';

export default function ConstestEventsPage() {
  return (
    <Page title="Constest Events">
      <ContestEvents />
    </Page>
  );
}
