import { IncludeExcludeAll } from 'src/lib/utils/includeExcludeFilterUtils';
import { UserType } from '../../../types/UserType';

export type WalletType = 'crypto' | 'all' | 'sticky';
export const WalletTypeLabel: Record<WalletType, string> = {
  all: 'All',
  crypto: 'Crypto',
  sticky: 'Sticky',
};
export type RankBy = 'receiving' | 'sending';
export const RankByLabel: Record<RankBy, string> = {
  receiving: 'Received (Editions)',
  sending: 'Sent (Editions)',
};

export type RankFilterFormValues = {
  startDate: string;
  endDate: string;
  rankBy: RankBy;
  lang: string;
  country: string;
  SelfTransfers: IncludeExcludeAll;
  InactiveUsers: IncludeExcludeAll;
  receiverWalletType: WalletType;
  senderWalletType: WalletType;
  userType: UserType | 'all';
};
