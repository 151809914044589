import { Edit } from '@mui/icons-material';
import { Button, Card, CardContent, Stack, Tab, Tabs } from '@mui/material';
import { capitalCase } from 'change-case';
import { Suspense } from 'react';
import Flex from 'src/components/shared/Flex';
import Loader from 'src/components/shared/Loader';
import Routes from 'src/routes/Routes';
import HeaderBreadcrumbs from 'src/template/components/HeaderBreadcrumbs';
import LoadingScreen from 'src/template/components/LoadingScreen';
import ASSET_COLLECTION_TABS from './channelTabs';
import useAssetCollectionDetailsView from './useView';

export default function AssetCollectionDetails() {
  const {
    assetCollectionDetails,
    currentTab,
    onChangeTab,
    handleOpenUpdateModal,
  } = useAssetCollectionDetailsView();

  return assetCollectionDetails ? (
    <>
      <HeaderBreadcrumbs
        heading={assetCollectionDetails.name || 'Empty Name'}
        links={[
          { name: 'Asset Collections', href: Routes.sgm.assetCollections },
          { name: assetCollectionDetails.name || 'Empty Name' },
        ]}
        action={
          <Button
            variant="contained"
            color="secondary"
            onClick={handleOpenUpdateModal}
            startIcon={<Edit />}
          >
            Update Asset Collection
          </Button>
        }
      />

      <Card>
        <Stack pb={2}>
          <Flex sx={{ px: 2, bgcolor: 'background.neutral' }} mb={2}>
            <Tabs
              allowScrollButtonsMobile
              variant="scrollable"
              scrollButtons="auto"
              value={currentTab}
              onChange={onChangeTab}
            >
              {ASSET_COLLECTION_TABS.map((tab) => (
                <Tab
                  disableRipple
                  key={tab.value}
                  value={tab.value}
                  icon={tab.icon}
                  label={capitalCase(tab.value)}
                />
              ))}
            </Tabs>
          </Flex>
          <CardContent>
            <Suspense fallback={<Loader />}>
              {ASSET_COLLECTION_TABS.map(
                (tab) =>
                  tab.value === currentTab &&
                  tab.component(assetCollectionDetails)
              )}
            </Suspense>
          </CardContent>
        </Stack>
      </Card>
    </>
  ) : (
    <LoadingScreen />
  );
}
