import { Avatar, Box } from '@mui/material';
import { capitalCase } from 'change-case';
import Link from 'src/components/shared/Link';
import MagicTable from 'src/components/shared/MagicTable/MagicTable';
import { PaymentRequestHistory } from 'src/lib/types/paymentRequest';
import { formatUTCDate } from 'src/lib/utils/formatUTCDate';
import getUserName from 'src/lib/utils/getUserName';
import Routes from 'src/routes/Routes';
import Label from 'src/template/components/Label';
import { getPaymentRequestLabelColor } from '../../utils';

type PaymentRequestHistoryTableProps = {
  data: PaymentRequestHistory[];
  isLoading: boolean;
};

export default function PaymentRequestHistoryTable({
  data,
  isLoading,
}: PaymentRequestHistoryTableProps) {
  return (
    <MagicTable.Container>
      <Box p={2}>
        <MagicTable
          loading={isLoading}
          rows={data ?? []}
          hideNumberOfRows
          hideFooter
          initialState={{
            sorting: {
              sortModel: [
                {
                  field: 'createdAt',
                  sort: 'desc',
                },
              ],
            },
          }}
          columns={[
            {
              field: 'updatedBy.name',
              headerName: 'Updated By',
              sortable: false,
              renderCell({ row }) {
                return (
                  <>
                    <Avatar
                      alt={getUserName(row?.updatedBy)}
                      src={row?.updatedBy?.profileImage ?? ''}
                      sx={{ mr: 2 }}
                    />

                    <Link
                      to={Routes.user(row?.updatedByUser?.uuid)}
                      color={row?.updatedByUser?.name ? 'inherit' : 'GrayText'}
                      underline="hover"
                    >
                      {row?.updatedByUser?.name ||
                        row?.updatedByUser?.slug ||
                        row?.updatedByUser?.email ||
                        'empty'}
                    </Link>
                  </>
                );
              },
            },
            {
              field: 'status',
              headerName: 'Status',
              sortable: false,
              renderCell({ row }) {
                return (
                  <Label
                    ml={4}
                    color={getPaymentRequestLabelColor(row.status)}
                    fontWeight="bold"
                  >
                    {capitalCase(row.status)}
                  </Label>
                );
              },
            },
            {
              field: 'note',
              headerName: 'Note',
              sortable: false,
            },
            {
              field: 'createdAt',
              headerName: 'Created At',
              sortable: true,
              sortingOrder: ['desc', 'asc'],
              sortComparator: (a, b) => a - b,
              valueFormatter({ value }) {
                return value ? formatUTCDate(value) : '-';
              },
            },
          ]}
        />
      </Box>
    </MagicTable.Container>
  );
}
