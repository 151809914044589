export function formatFractionNumber(
  n: number | string,
  options?: Intl.NumberFormatOptions
): string {
  if (Number.isNaN(Number(n))) return 'N/A';
  return Intl.NumberFormat('en-US', {
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
    ...options,
  }).format(Number(n));
}
