import { Delete } from '@mui/icons-material';
import { ListItemIcon, MenuItem } from '@mui/material';
import { useNavigate } from 'react-router';
import ConfirmationDialogRaw from 'src/components/shared/Dialog/ConfirmationDialog';
import useDisclosure from 'src/lib/hooks/useDisclosure';
import useNotify from 'src/lib/hooks/useNotify';
import { deleteNft } from 'src/lib/services/nft';
import { NFT } from 'src/lib/types/nft';
import { getMessageFromError } from 'src/lib/utils/error';
import Routes from 'src/routes/Routes';

interface DeleteNFTProps {
  nft: NFT;
  setLoading: (isLoading: boolean) => void;
  handleCloseMenu: () => void;
}
export default function DeleteNFT({
  nft,
  setLoading,
  handleCloseMenu,
}: DeleteNFTProps): JSX.Element {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const notify = useNotify();
  const navigate = useNavigate();
  const handleDeleteNFT = async () => {
    setLoading(true);
    try {
      handleCloseMenu();
      await deleteNft(nft.uuid);
      navigate(Routes.collection(nft.collectionUuid));
    } catch (e) {
      notify(getMessageFromError(e));
    }
    setLoading(false);
  };
  return (
    <>
      <MenuItem sx={{ color: 'red' }} onClick={onOpen}>
        <ListItemIcon>
          <Delete />
        </ListItemIcon>
        Delete NFT
      </MenuItem>
      <ConfirmationDialogRaw
        onClose={onClose}
        open={isOpen}
        onConfirm={handleDeleteNFT}
        title="Delete NFT"
      >
        Are you sure you want to delete this NFT?
      </ConfirmationDialogRaw>
    </>
  );
}
