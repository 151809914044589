import { yupResolver } from '@hookform/resolvers/yup';
import { useState } from 'react';
import { useFieldArray, useForm } from 'react-hook-form';
import { useUserSearch } from 'src/components/shared/Form/useUserSearch';
import useModalStore from 'src/lib/stores/useModalStore';
import { ClubJoinMethods } from 'src/lib/types/club';
import { Collection } from 'src/lib/types/collection';
import { Game } from 'src/lib/types/game';
import { ObjectLinkTypes } from 'src/lib/types/objectLink';
import { User } from 'src/lib/types/users';
import getUserName from 'src/lib/utils/getUserName';
import { uploadToStorage } from 'src/lib/utils/upload';
import * as yup from 'yup';
import { ClubFormValues } from './types';

const validationSchema = yup.object().shape({
  name: yup.string().required('Name is required'),
  description: yup.string().optional().nullable(),
  joinMethod: yup.string().required('Join Method is required'),

  landingPageUrl: yup.string().url('Invalid URL').nullable(),
  coverFileUrl: yup.string().url('Invalid URL').nullable(),
  photoFileUrl: yup.string().url('Invalid URL').nullable(),

  createdByUserUuid: yup.string().required('User is required'),

  collections: yup.array().when('joinMethod', {
    is: ClubJoinMethods.COLLECTION_OWNERSHIP,
    then: yup.array().min(1, 'At least one collection is required'),
  }),
  games: yup.array().when('hasGame', {
    is: true,
    then: yup.array().min(1, 'At least one game is required'),
  }),
});

function useClubFormView({
  defaultValues,
  defaultUser,
}: {
  defaultValues: ClubFormValues;
  defaultUser?: User;
}) {
  const onClose = useModalStore((state) => state.closeModal);
  const form = useForm<ClubFormValues>({
    defaultValues,
    resolver: yupResolver(validationSchema),
  });

  const handleCloseModal = () => {
    onClose();
  };

  const joinMethod = form.watch('joinMethod');
  const hasCollection = joinMethod === ClubJoinMethods.COLLECTION_OWNERSHIP;

  const hasGame = form.watch('hasGame');

  const [currentStep, setCurrentStep] = useState(1); // 1: Basic Info, 2: Collection, 3: Games

  // if has collection, add 1 to last step
  // if has game, add 1 to last step

  const lastStep =
    hasCollection && hasGame ? 5 : hasCollection || hasGame ? 3 : 1;

  const isCollectionSelectionStep = hasCollection && currentStep === 2;
  const isCollectionVerificationStep = hasCollection && currentStep === 3;

  const isGameSelectionStep =
    hasGame && hasCollection ? currentStep === 4 : hasGame && currentStep === 2;
  const isGameVerificationStep =
    hasGame && hasCollection ? currentStep === 5 : hasGame && currentStep === 3;

  const handleNextStep = async () => {
    if (currentStep === 1) {
      const isValid = await form.trigger(['name', 'description', 'joinMethod']);
      if (!isValid) return;
    }
    if (isCollectionSelectionStep || isCollectionVerificationStep) {
      const isValid = await form.trigger(['collections']);
      if (!isValid) return;
    }
    if (isGameSelectionStep || isGameVerificationStep) {
      const isValid = await form.trigger(['games']);
      if (!isValid) return;
    }
    setCurrentStep(currentStep + 1);
  };

  const handlePreviousStep = () => {
    setCurrentStep(currentStep - 1);
  };

  // collections

  const {
    fields: selectedCollections,
    remove: removeCollection,
    append: appendCollection,
  } = useFieldArray({
    control: form.control,
    name: 'collections',
  });

  const handleAddCollection = (collection: Collection) => {
    appendCollection({
      uuid: collection.uuid!,
      name: collection.name,
      coverFileUrl: collection.coverFileUrl,
      creatorName: getUserName(collection.user),
    });
  };

  const handleRemoveCollection = (uuid: string) => {
    const index = selectedCollections.findIndex(
      (collection) => collection.uuid === uuid
    );
    if (index > -1) removeCollection(index);
  };

  const handleRemoveCollectionByIndex = (index: number) => {
    removeCollection(index);
  };

  // games

  const {
    fields: selectedGames,
    remove: removeGame,
    append: appendGame,
  } = useFieldArray({
    control: form.control,
    name: 'games',
  });

  const handleAddGame = (game: Game) => {
    appendGame({
      uuid: game.uuid!,
      name: game.name,
    });
  };

  const handleRemoveGame = (uuid: string) => {
    const index = selectedGames.findIndex((game) => game.uuid === uuid);
    removeGame(index);
  };

  const handleRemoveGameByIndex = (index: number) => {
    if (selectedGames.length > 1) removeGame(index);
  };

  // image

  const onUpdateImage = async (
    file: any,
    field: 'photoFileUrl' | 'coverFileUrl'
  ) => {
    const url = await uploadToStorage(defaultValues.clubUuid, file);

    form.setValue(field, url as string);
  };

  const coverFileUrl = form.watch('coverFileUrl');
  const photoFileUrl = form.watch('photoFileUrl');

  // User search

  const { UserSearchComponent, userSearchProps } = useUserSearch({
    form,
    defaultUser,
    userUuidField: 'createdByUserUuid',
  });

  /* Object Links */
  const objectLinkProps = {
    form,
    objectLinksToCreateField: 'objectLinks',
    objectLinksTempField: 'objectLinksTempSearch',
    modelsToSearch: [
      ObjectLinkTypes.COLLECTION,
      ObjectLinkTypes.GAME,
      ObjectLinkTypes.CHANNEL,
    ],
  };

  return {
    form,
    handleCloseModal,
    lastStep,
    currentStep,
    handleNextStep,
    handlePreviousStep,
    isCollectionSelectionStep,
    isCollectionVerificationStep,
    isGameSelectionStep,
    isGameVerificationStep,
    selectedCollections,
    handleAddCollection,
    handleRemoveCollection,
    handleRemoveCollectionByIndex,
    selectedGames,
    handleAddGame,
    handleRemoveGame,
    handleRemoveGameByIndex,
    onUpdateImage,
    coverFileUrl,
    photoFileUrl,
    UserSearchComponent,
    userSearchProps,
    hasGame,
    objectLinkProps,
  };
}

export default useClubFormView;
