import { DeepPartial } from 'react-hook-form';
import { PaginatedResponse } from 'src/lib/types/PaginatedResponse';
import { TransactionHistoryPossibleTypes } from 'src/lib/types/TransactionHistory';
import { parseSearchParams } from 'src/lib/utils/parseSearchParams';
import { transactionHistoryTableParser } from 'src/lib/utils/transactions/transactionParser';
import useSWR from 'swr';

export function fetchTransactions(params: DeepPartial<PossibleParams>) {
  const parsedParams = parseSearchParams(params);

  const { data, error } = useSWR<
    PaginatedResponse<TransactionHistoryPossibleTypes[]>,
    Error
  >(`admin/transactions?${parsedParams}`);

  return {
    data: data?.body && transactionHistoryTableParser(data?.body),
    total: data?.meta.totalCount || 0,
    loading: !error && !data,
    error: error?.message,
  };
}

type PossibleParams = {
  page: number | string;
  size: number | string;
  order: Record<string, string>;
  filter: {
    status: string;
    createdAt: {
      gte: string;
      lte: string;
    };
    uuid: {
      eq: string;
    };
    'transactionPoolType.name': {
      eq: string;
    };
  };
};
