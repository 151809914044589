import { CommonDateStrings } from 'src/lib/constants/CommonDates';
import { DEFAULT_INITIAL_PAGE_SIZE } from 'src/lib/constants/pagination';
import useURLSearchParams from 'src/lib/hooks/useURLSearchParams';
import { fetchSearchesAnalytics } from '../../services/fetchSearchesAnalytics';

export type FetchUserSearchesQueryParams = {
  page: number;
  size: number;
  startDate: string;
  endDate: string;
  filterQuery: string;
  filterSearchBy: string;
  sortBy: string;
  order: 'asc' | 'desc';
};

const initialParams = {
  page: '0',
  size: DEFAULT_INITIAL_PAGE_SIZE.toString(),

  sortBy: 'createdAt',
  order: 'desc',

  startDate: CommonDateStrings.sevenDaysAgo.dateTime.split('T')[0],
  endDate: CommonDateStrings.today.dateTime.split('T')[0],
  filterQuery: '',
  filterSearchBy: 'searchTerm',
};

export function useSearchesAnalyticsTable() {
  const { allSearchParams } = useURLSearchParams(initialParams);

  const params: FetchUserSearchesQueryParams = {
    size: +(allSearchParams?.size ?? initialParams.size),
    page: +(allSearchParams?.page ?? initialParams.page),
    startDate: allSearchParams.startDate ?? initialParams.startDate,
    endDate: allSearchParams.endDate ?? initialParams.endDate,

    filterQuery: allSearchParams.filterQuery ?? initialParams.filterQuery,
    filterSearchBy:
      allSearchParams.filterSearchBy ?? initialParams.filterSearchBy,
    sortBy: allSearchParams.sortBy ?? initialParams.sortBy,
    order: (allSearchParams.order as 'asc' | 'desc') ?? initialParams.order,
  };

  const fetchData = {
    page: params.page,
    size: params.size,
    order: {
      [params.sortBy]: params.order,
    },
    filter: {
      ...(params.filterSearchBy &&
        params.filterQuery && {
          [params.filterSearchBy]: {
            like: `${params.filterQuery}`,
          },
        }),
      ...(params.startDate &&
        params.endDate && {
          createdAt: {
            gte: `${params.startDate}T00:00:00.000Z`,
            lte: `${params.endDate}T23:59:59.999Z`,
          },
        }),
    },
  };

  const { data, isLoading } = fetchSearchesAnalytics(fetchData);

  return {
    data,
    isLoading,
    params,
  };
}
