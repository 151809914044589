import { DeepPartial } from 'react-hook-form';
import { NFTForPackWithTransfersCount } from 'src/lib/types/nft';
import { getMessageFromError } from 'src/lib/utils/error';
import { parseSearchParams } from 'src/lib/utils/parseSearchParams';
import useSWR, { SWRResponse } from 'swr';

type PossibleParams = {
  size: number | string;
  page: number | string;
  order: string;
  orderBy: string;
  query?: string;
  searchBy?: string;
};

export function fetchNFTSForPackWithTransfersCount(
  filters?: DeepPartial<PossibleParams>
): SWRResponse<NFTForPackWithTransfersCount[]> {
  const parsedParams = parseSearchParams(filters ?? {});

  const { error, ...response } = useSWR(
    `admin/nfts/available-for-nft-pack-with-transfers-count?${parsedParams}`
  );

  return {
    ...response,
    error: error ? getMessageFromError(error) : undefined,
  };
}
