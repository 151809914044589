import { DeepPartial } from 'react-hook-form';
import { WithUser } from 'src/lib/types/users';
import { parseSearchParams } from 'src/lib/utils/parseSearchParams';
import useSWR, { SWRResponse } from 'swr';
import {
  CoinExpiration,
  CoinTypes,
  ExpiringCoinsDateFilterRanges,
} from '../types/CoinExpiration';

export const fetchCoinExpiring = (
  filters?: DeepPartial<PossibleParams>
): SWRResponse<WithUser<CoinExpiration>[]> => {
  const { data, ...response } = useSWR(
    `admin/coins/expiring?${parseSearchParams(filters ?? {})}`
  );

  return {
    ...response,
    data,
  };
};

type PossibleParams = {
  dateFilter: ExpiringCoinsDateFilterRanges;
  coinTypeFilter: CoinTypes | 'all';

  page: number | string;
  size: number | string;
  order: Record<string, string>;
};
