import {
  Alert,
  Autocomplete,
  Card,
  FormControl,
  InputLabel,
  Select,
  Stack,
  Tab,
  Tabs,
  TextField,
} from '@mui/material';
import Center from 'src/components/shared/Center';
import Flex from 'src/components/shared/Flex';
import SelectOption from 'src/components/shared/SelectOption';
import { IncludeExcludeOptions } from 'src/lib/utils/includeExcludeFilterUtils';
import DevicesTable from '../../components/DevicesTable';
import { Platforms } from '../../types/Platforms';
import useDevicesView from './useView';

const STATUS_TABS = [
  {
    label: 'All',
    value: 'all',
  },
  {
    label: 'Active',
    value: 'true',
  },
  {
    label: 'Inactive',
    value: 'false',
  },
];
export default function DevicesView(): JSX.Element {
  const {
    data,
    isLoading,
    currentTab,
    onChangeTab,
    handleChangeFilter,
    filterOptions,
    loadingFilterOptions,
    filterValues,
    error,
  } = useDevicesView();

  if (error) {
    return (
      <Center>
        <Alert severity="error">
          Could not load Subscriptions, please try again later.
          <br /> <b>Error:</b> {error}
        </Alert>
      </Center>
    );
  }

  return (
    <Card>
      <Tabs
        allowScrollButtonsMobile
        variant="scrollable"
        scrollButtons="auto"
        value={currentTab}
        onChange={onChangeTab}
        sx={{ px: 2, bgcolor: '#88888830' }}
      >
        {STATUS_TABS.map((tab) => (
          <Tab
            disableRipple
            key={tab.value}
            label={tab.label}
            value={tab.value}
          />
        ))}
      </Tabs>
      <Stack spacing={2} px={2} pt={2}>
        <FormControl fullWidth>
          <InputLabel id="AnonymousUsersLabel">Anonymous User</InputLabel>
          <Select
            labelId="AnonymousUsersLabel"
            name="AnonymousUsers"
            label="Anonymous User"
            fullWidth
            value={filterValues.AnonymousUsers}
            onChange={(e) => {
              handleChangeFilter('AnonymousUsers', e.target.value as string);
            }}
          >
            {IncludeExcludeOptions.map(({ label, value }) => (
              <SelectOption key={value} value={value}>
                {label}
              </SelectOption>
            ))}
          </Select>
        </FormControl>

        <Flex gap={2}>
          <Autocomplete
            fullWidth
            disablePortal
            loading={loadingFilterOptions}
            id="country-selector"
            options={filterOptions?.countries ?? []}
            defaultValue={filterValues.country}
            renderInput={(params) => <TextField {...params} label="Country" />}
            onChange={(e, option) => {
              handleChangeFilter('country', option ?? '');
            }}
          />
          <Autocomplete
            fullWidth
            disablePortal
            loading={loadingFilterOptions}
            id="lang-selector"
            options={Object.keys(Platforms) ?? []}
            defaultValue={filterValues.platform}
            renderInput={(params) => <TextField {...params} label="Platform" />}
            onChange={(e, option) => {
              handleChangeFilter('platform', option ?? '');
            }}
          />
          <TextField
            fullWidth
            type="date"
            label="Start Date"
            defaultValue={filterValues.startDate?.split('T')[0] ?? ''}
            InputLabelProps={{ shrink: true }}
            onChange={(e) => {
              const { value } = e.target;
              handleChangeFilter('startDate', value ? `${value}T00:00:00` : '');
            }}
          />
          <TextField
            fullWidth
            type="date"
            label="End Date"
            defaultValue={filterValues.endDate?.split('T')[0] ?? ''}
            InputLabelProps={{ shrink: true }}
            onChange={(e) => {
              const { value } = e.target;
              handleChangeFilter('endDate', value ? `${value}T23:59:59` : '');
            }}
          />
        </Flex>
        <DevicesTable data={data ?? []} isLoading={isLoading} />
      </Stack>
    </Card>
  );
}
