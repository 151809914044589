import { Avatar, Link, MenuItem, TableCell, TableRow } from '@mui/material';
import { capitalCase } from 'change-case';
import { Link as RouterLink } from 'react-router-dom';
import Routes from 'src/routes/Routes';

import { ContentCopy } from '@mui/icons-material';
import VerifiedIcon from '@mui/icons-material/Verified';
import CopyToClipboard from 'react-copy-to-clipboard';
import LinkToUser from 'src/components/shared/LinkToUser';
import MoreMenu from 'src/components/shared/MoreMenu';
import { Collection } from 'src/lib/types/collection';
import { formatAppPlatform, formatDate } from 'src/lib/utils/formatters';
import Label from 'src/template/components/Label';
import { reviewStatusColumns } from '../../Review/reviewStatusColumn';

export default function CollectionTableRow({
  collection,
}: {
  collection: Collection;
}) {
  return (
    <TableRow key={collection.uuid} hover>
      <TableCell sx={{ display: 'flex', alignItems: 'center' }}>
        <Avatar
          alt={collection.name}
          src={collection.coverFileUrl}
          variant="rounded"
          sx={{ mr: 2 }}
        />
        <Link
          component={RouterLink}
          to={Routes.collection(collection.uuid)}
          color={collection.name ? 'inherit' : 'text.secondary'}
          underline="hover"
          display="inline-block"
        >
          {collection.name ?? 'empty'}{' '}
          {collection.verified && (
            <VerifiedIcon
              fontSize="inherit"
              color="info"
              sx={{
                verticalAlign: 'text-top',
              }}
            />
          )}
        </Link>
      </TableCell>
      <TableCell align="center">
        <Label
          variant="ghost"
          color={collection.active ? 'success' : 'error'}
          sx={{ textTransform: 'capitalize' }}
        >
          {collection.active ? 'Active' : 'Inactive'}
        </Label>
      </TableCell>
      <TableCell align="center">{collection.nftsCount}</TableCell>
      <TableCell>
        <LinkToUser
          user={collection.user}
          blockchain={collection.blockchain}
          externalWallet={collection.externalWallet}
        />
      </TableCell>
      <TableCell align="center">
        {reviewStatusColumns[0].renderCell({ row: collection })}
      </TableCell>
      <TableCell align="center">
        {reviewStatusColumns[1].renderCell({ row: collection })}
      </TableCell>
      <TableCell align="center">{capitalCase(collection.blockchain)}</TableCell>
      <TableCell align="center">
        {formatAppPlatform(collection.platform)}
      </TableCell>
      <TableCell align="right">{formatDate(collection.updatedAt)}</TableCell>
      <TableCell align="right">{formatDate(collection.createdAt)}</TableCell>
      <TableCell align="center">
        <MoreMenu>
          <CopyToClipboard text={collection.uuid}>
            <MenuItem aria-label="copy feed uuid">
              <ContentCopy />
              Copy UUID
            </MenuItem>
          </CopyToClipboard>
        </MoreMenu>
      </TableCell>
    </TableRow>
  );
}
