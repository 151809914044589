import GameDetails from 'src/components/modules/Games/view/Details';
import Page from 'src/template/components/Page';

export default function GamePage() {
  return (
    <Page title="Game">
      <GameDetails />
    </Page>
  );
}
