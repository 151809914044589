import getAPIClient from 'src/lib/services/api/axios.config';
import { getMessageFromError } from 'src/lib/utils/error';

export async function StopPurges() {
  try {
    const api = await getAPIClient();
    const res = await api.post(`admin/user-purge/stop`);
    return {
      data: res.data,
      error: null,
    };
  } catch (e) {
    return {
      data: null,
      error: getMessageFromError(e),
    };
  }
}
