import { Card, CardContent, CardHeader } from '@mui/material';
import { useForm } from 'react-hook-form';
import Button from 'src/components/shared/Buttons/CustomButton';
import Flex from 'src/components/shared/Flex';
import ControllerField from 'src/components/shared/Inputs/ControllerField';
import Modal from 'src/components/shared/Modal';
import useModalStore from 'src/lib/stores/useModalStore';
import { UpdateDeviceFormValues } from './types';

type Props = {
  onSubmit(values: UpdateDeviceFormValues): void;
  initialValues: UpdateDeviceFormValues;
};
export default function UpdateDeviceFormView({
  onSubmit,
  initialValues,
}: Props): JSX.Element {
  const closeModal = useModalStore((state) => state.closeModal);
  const {
    handleSubmit,
    control,
    formState: { isSubmitting },
  } = useForm<UpdateDeviceFormValues>({
    defaultValues: initialValues,
  });

  return (
    <Modal onClose={closeModal} open>
      <Card sx={{ minWidth: 400 }}>
        <CardHeader title="Update Device" />
        <CardContent>
          <form onSubmit={handleSubmit(onSubmit)}>
            <ControllerField
              control={control}
              name="description"
              label="Description"
              placeholder="Type a description"
              fullWidth
            />
            <Flex justifyContent="space-between" mt={3}>
              <Button
                variant="contained"
                onClick={closeModal}
                color="error"
                sx={{
                  width: 80,
                }}
              >
                Cancel
              </Button>
              <Button
                variant="contained"
                type="submit"
                loading={isSubmitting}
                color="secondary"
                sx={{
                  width: 80,
                }}
              >
                Save
              </Button>
            </Flex>
          </form>
        </CardContent>
      </Card>
    </Modal>
  );
}
