import { Card } from '@mui/material';
import { debounce } from 'lodash';
import Flex from 'src/components/shared/Flex';
import MagicTable from 'src/components/shared/MagicTable/MagicTable';
import useURLSearchParams from 'src/lib/hooks/useURLSearchParams';
import { SearchAnalyticsRow } from 'src/lib/types/UserSearch';
import { tableColumns } from './columns';
import { UserSearchesTableDateFilters } from './filters/DateFilter';
import { SearchesAnalyticsTableSearchFilters } from './filters/SearchFilter';
import { FetchUserSearchesQueryParams } from './useSearchesAnalyticsTable';

export default function SearchesAnalyticsTable({
  data,
  isLoading,
  totalCount,
  params,
  searchTerm,
}: SearchesAnalyticsTableProps) {
  const { addParam, removeParam } = useURLSearchParams();

  // Untested code. Once we implement the analytics table, check this.
  if (searchTerm) {
    removeParam('filterSearchBy');
    removeParam('filterQuery');
    addParam('filterSearchBy', 'rawSearch');
    addParam('filterQuery', searchTerm);
  }

  function handleChangeField(value: string, field: string) {
    addParam(field, value);
  }
  const debouncedChangeField = debounce(handleChangeField, 400);

  return (
    <Card>
      <Flex p={2} justifyContent={'space-between'}>
        <Flex gap={2} justifyContent={'space-between'} width={'50%'}>
          {/* User Filter */}
          <SearchesAnalyticsTableSearchFilters
            addParam={addParam}
            debouncedChangeField={debouncedChangeField}
            propParams={params}
            removeParam={removeParam}
          />
        </Flex>

        {/* Date Filter */}
        <Flex gap={2} justifyContent={'space-between'}>
          <UserSearchesTableDateFilters
            addParam={addParam}
            debouncedChangeField={debouncedChangeField}
            propParams={params}
            removeParam={removeParam}
          />
        </Flex>
      </Flex>
      <MagicTable.Container>
        <MagicTable
          loading={isLoading}
          pageSize={params.size}
          page={params.page}
          rowCount={totalCount}
          paginationMode="server"
          onSortModelChange={(model) => {
            const currentModel = model[0];
            if (currentModel) {
              addParam('sortBy', currentModel.field);
              if (currentModel.sort) addParam('order', currentModel.sort);
            } else {
              removeParam('order');
              removeParam('sortBy');
            }
          }}
          onPageChange={(newPage) => {
            addParam('page', newPage);
          }}
          onPageSizeChange={(newSize) => {
            addParam('size', newSize);
          }}
          rows={data}
          columns={tableColumns}
          getRowId={(row) => row.searchTerm}
        />
      </MagicTable.Container>
    </Card>
  );
}

type SearchesAnalyticsTableProps = {
  data: SearchAnalyticsRow[];
  isLoading: boolean;
  totalCount: number;
  params: FetchUserSearchesQueryParams;
  searchTerm?: string;
};
