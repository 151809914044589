import { Add } from '@mui/icons-material';
import { Button, Container } from '@mui/material';
import HeaderBreadcrumbs from 'src/template/components/HeaderBreadcrumbs';

import GameTagTable from '../../components/Table';
import useGameTag from './useView';

export default function GameTags() {
  const {
    data,
    isLoading,
    params,
    handleOpenCreateModal,
    handleOpenDeleteModal,
    handleOpenEditModal,
  } = useGameTag();

  return (
    <Container maxWidth="xl">
      <HeaderBreadcrumbs
        heading="Game Tag"
        links={[{ name: 'Game Tag' }]}
        action={
          <Button
            variant="contained"
            color="secondary"
            onClick={handleOpenCreateModal}
            startIcon={<Add />}
          >
            Add Game Tag
          </Button>
        }
      />

      <GameTagTable
        data={data}
        isLoading={isLoading}
        params={params}
        handleOpenDeleteModal={handleOpenDeleteModal}
        onEditClick={handleOpenEditModal}
      />
    </Container>
  );
}
