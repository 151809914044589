import { Autocomplete, InputAdornment, TextField } from '@mui/material';
import Iconify from 'src/template/components/Iconify';
import { FilterProps } from './types';

export function UserSearchesTableSearchFilters({
  addParam,
  removeParam,
  debouncedChangeField,
  propParams,
}: FilterProps) {
  const userSearchesSearchOptions = [
    {
      label: 'User Uuid',
      query: 'userUuid',
    },
    {
      label: 'User Name',
      query: 'userName',
    },
    {
      label: 'User Slug',
      query: 'userSlug',
    },
    {
      label: 'User Email',
      query: 'userEmail',
    },
    {
      label: 'Search term',
      query: 'rawSearch',
    },
  ];
  return (
    <>
      <Autocomplete
        options={userSearchesSearchOptions}
        fullWidth
        renderInput={(params) => (
          <TextField label="Search by..." {...params} fullWidth />
        )}
        getOptionLabel={(p) => p.label}
        sx={{ maxWidth: 250 }}
        defaultValue={
          userSearchesSearchOptions.find(
            (o) => o.query === propParams.filterSearchBy
          ) || null
        }
        key={'filterSearchBy'}
        onChange={(_, value) => {
          if (!value) removeParam('filterSearchBy');
          else addParam('filterSearchBy', value.query);
        }}
      />
      <TextField
        fullWidth
        label="Search"
        defaultValue={propParams.filterQuery}
        type="search"
        onChange={(e) => {
          const { value } = e.target;
          debouncedChangeField(value, 'filterQuery');
        }}
        key={'filterQuery'}
        InputProps={{
          startAdornment: (
            <InputAdornment position="start">
              <Iconify
                icon="eva:search-fill"
                sx={{ color: 'text.disabled', width: 20, height: 20 }}
              />
            </InputAdornment>
          ),
        }}
      />
    </>
  );
}
