import { Avatar, Link, Stack, TableCell, TableRow } from '@mui/material';
import { capitalCase } from 'change-case';
import { Link as RouterLink } from 'react-router-dom';
import LinkToUser from 'src/components/shared/LinkToUser';
import { NFT } from 'src/lib/types/nft';
import { formatDate } from 'src/lib/utils/formatters';
import { getCollectionHumanAnalysisStatusColor } from 'src/lib/utils/utils';
import Routes from 'src/routes/Routes';
import Label from 'src/template/components/Label';
import { colorByReviewStatus } from '../../Review/utils/utils';

export default function NftsTableRow({ nft }: { nft: NFT }) {
  return (
    <TableRow key={nft.uuid} hover>
      <TableCell sx={{ display: 'flex', alignItems: 'center' }}>
        <Avatar
          alt={nft.name}
          src={nft.fileUrl}
          variant="rounded"
          sx={{ mr: 2 }}
        />
        <Link
          component={RouterLink}
          to={Routes.nft(nft.uuid)}
          color={nft.name ? 'inherit' : 'text.secondary'}
          underline="hover"
          display="inline-block"
        >
          {nft.name ?? 'empty'}{' '}
        </Link>
      </TableCell>
      <TableCell align="center">
        <Label
          variant="ghost"
          color={nft.active ? 'success' : 'error'}
          sx={{ textTransform: 'capitalize' }}
        >
          {nft.active ? 'Active' : 'Inactive'}
        </Label>
      </TableCell>
      <TableCell align="center">
        <Stack alignItems="center" spacing={1}>
          <Label
            variant="ghost"
            color={colorByReviewStatus[nft.reviewStatus]}
            sx={{ textTransform: 'capitalize' }}
          >
            {capitalCase(nft.reviewStatus)}
          </Label>
          {nft.humanAnalysis && (
            <Label
              variant="filled"
              color={getCollectionHumanAnalysisStatusColor(nft.humanAnalysis)}
            >
              {capitalCase(nft.humanAnalysis)}
            </Label>
          )}
        </Stack>
      </TableCell>
      <TableCell align="center">{capitalCase(nft.blockchain)}</TableCell>
      <TableCell sx={{ display: 'flex', alignItems: 'center' }}>
        <Avatar
          alt={nft.collection?.name}
          src={nft.collection?.coverFileUrl}
          variant="rounded"
          sx={{ mr: 2 }}
        />
        <Link
          component={RouterLink}
          to={Routes.collection(nft.collection?.uuid)}
          color={nft.collection?.name ? 'inherit' : 'text.secondary'}
          underline="hover"
          display="inline-block"
        >
          {nft.collection?.name ?? 'empty'}{' '}
        </Link>
      </TableCell>
      <TableCell>
        <LinkToUser
          user={nft.collection?.user}
          blockchain={nft.collection?.blockchain}
          externalWallet={nft.collection?.externalWallet}
        />
      </TableCell>
      <TableCell align="right">{formatDate(nft.updatedAt)}</TableCell>
      <TableCell align="right">{formatDate(nft.createdAt)}</TableCell>
    </TableRow>
  );
}
