import {
  GameStatisticGroup,
  StatisticGroupTypes,
} from 'src/lib/types/statisticGroup';
import StatisticGroupForm from '../../components/Forms/StatisticGroup';
import { StatisticGroupFormValues } from '../../components/Forms/StatisticGroup/types';
import useUpdateStatisticGroupView from './useView';

interface UpdateStatisticGroupViewProps {
  gameUuid: string;
  group: GameStatisticGroup;
}

export default function UpdateStatisticGroupView({
  gameUuid,
  group,
}: UpdateStatisticGroupViewProps): JSX.Element {
  const { handleUpdateStatisticGroup } = useUpdateStatisticGroupView();
  const defaultValues =
    group.type === StatisticGroupTypes.ASSET_GROUP
      ? {
          name: group.name,
          type: group.type,
          actionUuid: group.assetGroup?.assetActionUuid!,
          typeUuid: group.assetGroup?.assetTypeUuid!,
        }
      : {
          name: group.name,
          type: group.type,
          statisticsList: group.statisticsList!.statistics!.map(
            (statistic) => ({
              uuid: statistic.uuid,
              assetType: statistic.asset!.assetType!.name,
              assetAction: statistic!.action!.name,
              assetName: statistic.asset!.name,
            })
          ),
        };
  return (
    <StatisticGroupForm
      gameUuid={gameUuid}
      defaultValues={{
        ...defaultValues,
      }}
      onSubmit={async (values: StatisticGroupFormValues) => {
        await handleUpdateStatisticGroup(group.uuid, {
          ...values,
        });
      }}
      title="Update Statistic Group"
    />
  );
}
