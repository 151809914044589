import { yupResolver } from '@hookform/resolvers/yup';
import {
  Autocomplete,
  Card,
  CardContent,
  CardHeader,
  Stack,
  TextField,
} from '@mui/material';
import { Controller, useForm } from 'react-hook-form';
import { fetchProducts } from 'src/components/modules/RevenueEvents/services/fetchProducts';
import Button from 'src/components/shared/Buttons/CustomButton';
import ControllerField from 'src/components/shared/Inputs/ControllerField';
import Modal from 'src/components/shared/Modal';
import useModalStore from 'src/lib/stores/useModalStore';
import {
  ProductProvider,
  ProductType,
  RechargeType,
} from 'src/lib/types/product';
import * as yup from 'yup';
import { NFTPackFormValues } from './types';

const validationSchema = yup.object().shape({
  name: yup.string().required('Name is required'),
  type: yup.string().required('Type is required'),
  amountOfNftsToRecharge: yup
    .number()
    .min(1, 'Amount must be greater than or equal to 1')
    .required('Amount is required'),
  productUuid: yup.string().required('Product is required'),
});

type Props = {
  defaultValues: NFTPackFormValues;
  onSubmit: (values: NFTPackFormValues) => Promise<void>;
  title: string;
};

export default function NFTPackForm({
  defaultValues,
  title,
  onSubmit,
}: Props): JSX.Element {
  const onClose = useModalStore((state) => state.closeModal);
  const {
    control,
    handleSubmit,
    formState: { isSubmitting },
  } = useForm<NFTPackFormValues>({
    defaultValues,
    resolver: yupResolver(validationSchema),
  });

  const handleCloseModal = () => {
    onClose();
  };

  const { data: productsData } = fetchProducts({
    filter: {
      provider: ProductProvider.APPLE,
      rechargeType: RechargeType.NFT,
      type: ProductType.CONSUMABLE,
    },
    order: {
      name: 'asc',
    },
  });

  return (
    <Modal open onClose={handleCloseModal}>
      <Card
        sx={{
          width: '600px',
        }}
      >
        <CardHeader title={title} />
        <CardContent>
          <form onSubmit={handleSubmit(onSubmit)}>
            <Stack>
              <Stack mb={2} spacing={1.5}>
                <ControllerField
                  control={control}
                  name="name"
                  label="Name"
                  fullWidth
                />
                <ControllerField
                  control={control}
                  name="type"
                  label="Type"
                  fullWidth
                />
                <ControllerField
                  control={control}
                  type="number"
                  name="amountOfNftsToRecharge"
                  label="Amount of NFTs to Recharge"
                  fullWidth
                />
                <Controller
                  control={control}
                  name="productUuid"
                  render={({ field }) => (
                    <Autocomplete
                      options={
                        productsData?.body.map((product) => product.uuid) ?? []
                      }
                      getOptionLabel={(option) =>
                        productsData?.body.find(
                          (product) => product.uuid === option
                        )?.name ?? ''
                      }
                      defaultValue={field.value}
                      onChange={(e, value) => field.onChange(value)}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          {...field}
                          label="Product"
                          fullWidth
                          required
                        />
                      )}
                    />
                  )}
                />
              </Stack>
              <Stack
                direction="row"
                justifyContent="space-between"
                spacing={1}
                mt={2}
              >
                <Button color="error" onClick={handleCloseModal}>
                  Cancel
                </Button>
                <Button
                  variant="contained"
                  color="secondary"
                  type="submit"
                  loading={isSubmitting}
                >
                  Save
                </Button>
              </Stack>
            </Stack>
          </form>
        </CardContent>
      </Card>
    </Modal>
  );
}
