import { Container } from '@mui/material';
import NftPacks from 'src/components/modules/NFTPacks/view/NFTPack/List';
import Page from 'src/template/components/Page';

export default function NFTPacksV3Page() {
  return (
    <Page title="NFT Packs">
      <Container maxWidth="xl">
        <NftPacks />
      </Container>
    </Page>
  );
}
