import { useCallback, useEffect } from 'react';
import { DEFAULT_INITIAL_PAGE_SIZE } from 'src/lib/constants/pagination';
import useURLSearchParams from 'src/lib/hooks/useURLSearchParams';
import { PayoutCountryEmitter } from '../../events/PayoutCountryEmitter';
import { fetchPayoutCountries } from '../../services/fetchPayoutCountries';

export default function useSanctionedCountriesView() {
  const { allSearchParams } = useURLSearchParams({
    page: '0',
    size: DEFAULT_INITIAL_PAGE_SIZE.toString(),
    sortBy: 'code',
    sortOrder: 'desc',
    searchFor: 'name',
  });
  const { data, error, loading, mutate } = fetchPayoutCountries({
    filter: {
      name: {
        like: allSearchParams.name,
      },
      code: {
        like: allSearchParams.code,
      },
      active: allSearchParams.active,
    },
  });

  const refetchData = useCallback(() => {
    mutate?.();
  }, []);

  useEffect(() => {
    PayoutCountryEmitter.on('payoutCountryCreated', refetchData);
    PayoutCountryEmitter.on('payoutCountryUpdated', refetchData);
    PayoutCountryEmitter.on('payoutCountryDeleted', refetchData);

    return () => {
      PayoutCountryEmitter.off('payoutCountryCreated', refetchData);
      PayoutCountryEmitter.off('payoutCountryUpdated', refetchData);
      PayoutCountryEmitter.off('payoutCountryDeleted', refetchData);
    };
  }, [refetchData]);

  return {
    data,
    error,
    loading,
    sortBy: allSearchParams.sortBy!,
    sortOrder: allSearchParams.sortOrder!,
    page: +allSearchParams.page!,
    size: +allSearchParams.size!,
    searchFor: allSearchParams.searchFor!,
  };
}
