import { Box, Tooltip } from '@mui/material';
import MagicTable from 'src/components/shared/MagicTable/MagicTable';
import { DEFAULT_INITIAL_PAGE_SIZE } from 'src/lib/constants/pagination';
import useURLSearchParams from 'src/lib/hooks/useURLSearchParams';
import { Communication } from '../../types/Communication';

type Props = {
  data: Communication[];
  isLoading: boolean;
};
export default function CommunicationsTable({
  data,
  isLoading,
}: Props): JSX.Element {
  const { addParam, getParam } = useURLSearchParams();
  const page = Number(getParam('page') ?? 0);
  const size = Number(getParam('size') ?? DEFAULT_INITIAL_PAGE_SIZE);
  return (
    <MagicTable.Container>
      <MagicTable
        hideNumberOfRows
        rows={data}
        loading={isLoading}
        getRowId={(r) => r.uuid}
        paginationMode="server"
        pageSize={size}
        page={page}
        onPageChange={(newPage) => {
          addParam('page', newPage);
        }}
        onPageSizeChange={(newSize) => {
          addParam('size', newSize);
        }}
        columns={[
          {
            field: 'uuid',
            headerName: 'ID',
            sortable: false,
          },
          {
            field: 'title',
            headerName: 'Title',
            sortable: false,
          },
          {
            field: 'message',
            headerName: 'Message',
            sortable: false,
            renderCell: ({ row }) => (
              <Tooltip title={row.message} placement="top" arrow>
                <Box className="MuiDataGrid-cellContent">{row.message}</Box>
              </Tooltip>
            ),
          },
          {
            field: 'createdAt',
            headerName: 'Created At',
            flex: 1 / 2,
          },
        ]}
      />
    </MagicTable.Container>
  );
}
