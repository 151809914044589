import {
  Card,
  CardContent,
  CardHeader,
  Divider,
  Stack,
  TextField,
} from '@mui/material';
import { Controller } from 'react-hook-form';
import Button from 'src/components/shared/Buttons/CustomButton';
import Modal from 'src/components/shared/Modal';
import { GameTag } from 'src/lib/types/gameTag';
import { useCreateGameTagView } from './useView';

export default function CreateGameTagForm({
  gameTag,
}: {
  gameTag?: GameTag;
}): JSX.Element {
  const {
    handleCloseModal,
    isSubmitting,
    onSubmitForm,
    onUpdateContent,
    isUpdatingContent,
    form: { control, handleSubmit, setValue },
  } = useCreateGameTagView(gameTag);

  return (
    <Modal open onClose={handleCloseModal}>
      <Card
        sx={{
          width: '600px',
        }}
      >
        <CardHeader title="Create a Game Game Tag " />
        <CardContent>
          <form onSubmit={handleSubmit(onSubmitForm)}>
            <Stack spacing={2}>
              <Divider textAlign="left">Details</Divider>
              {/* TextField for title */}
              <Controller
                name="name"
                control={control}
                render={({ field }) => (
                  <TextField
                    label="Name"
                    fullWidth
                    {...field}
                    variant="outlined"
                  />
                )}
              />

              <Divider textAlign="left">Content</Divider>

              <Button
                variant="contained"
                color="secondary"
                type="submit"
                loading={isSubmitting}
                size="large"
              >
                Save
              </Button>
            </Stack>
          </form>
        </CardContent>
      </Card>
    </Modal>
  );
}
