import {
  Autocomplete,
  Card,
  CardContent,
  InputAdornment,
  Tab,
  Tabs,
  TextField,
} from '@mui/material';
import Flex from 'src/components/shared/Flex';
import { SubmissionFormStatuses } from 'src/lib/types/formReviewStatus';
import HeaderBreadcrumbs from 'src/template/components/HeaderBreadcrumbs';
import Iconify from 'src/template/components/Iconify';
import SubmissionsTable from '../../components/Table';
import useSubmissionsView from './useView';

const STATUS_TABS = [
  { value: 'all', label: 'All' },
  { value: SubmissionFormStatuses.REVIEW_APPROVED, label: 'Approved' },
  { value: SubmissionFormStatuses.REVIEW_REJECTED, label: 'Rejected' },
  { value: SubmissionFormStatuses.AWAITING_REVIEW, label: 'Awaiting Review' },
];

const searchOptions = [
  {
    label: 'Uuid',
    query: 'uuid',
  },
  {
    label: 'Item Type',
    query: 'itemType',
  },
  {
    label: 'Name',
    query: 'name',
  },
];

export default function Submissions() {
  const {
    data,
    isLoading,
    onChangeReviewStatus,
    reviewStatus,
    params,
    addParam,
    removeParam,
    debouncedSearch,
  } = useSubmissionsView();

  return (
    <>
      <HeaderBreadcrumbs
        heading="Submissions"
        links={[{ name: 'Submissions' }]}
      />
      <Card>
        <Flex sx={{ px: 2, bgcolor: 'background.neutral' }}>
          <Tabs
            allowScrollButtonsMobile
            variant="scrollable"
            scrollButtons="auto"
            value={reviewStatus}
            onChange={onChangeReviewStatus}
          >
            {STATUS_TABS.map((tab) => (
              <Tab
                disableRipple
                key={tab.value}
                label={tab.label}
                value={tab.value}
              />
            ))}
          </Tabs>
        </Flex>

        <CardContent>
          <Flex gap={2} p={2}>
            <Autocomplete
              options={searchOptions}
              fullWidth
              renderInput={(_params) => (
                <TextField label="Search By" {..._params} />
              )}
              getOptionLabel={(p) => p.label}
              sx={{ maxWidth: 250 }}
              defaultValue={searchOptions.find(
                (o) => o.query === params.searchBy
              )}
              key={params.searchBy}
              onChange={(_, value) => {
                if (!value) removeParam('searchBy');
                else addParam('searchBy', value.query);
              }}
            />

            <TextField
              fullWidth
              label="Search"
              defaultValue={params.query}
              type="search"
              onChange={(e) => {
                const { value } = e.target;
                debouncedSearch(value);
              }}
              key={params.query}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <Iconify
                      icon="eva:search-fill"
                      sx={{ color: 'text.disabled', width: 20, height: 20 }}
                    />
                  </InputAdornment>
                ),
              }}
            />
          </Flex>
          <SubmissionsTable
            data={data?.body ?? []}
            totalCount={data?.meta?.totalCount ?? 0}
            isLoading={isLoading}
            params={params}
          />
        </CardContent>
      </Card>
    </>
  );
}
