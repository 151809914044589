import {
  Autocomplete,
  Box,
  Grid,
  MenuItem,
  Stack,
  TextField,
} from '@mui/material';
import CustomSelect from '../CustomSelect';
import { FeedDetails } from './types';

interface FeedDetailsProps {
  isFilterType: boolean;
  typeOptions: string[];
  feedDetails: FeedDetails | null;
  onChange: (newDetails: Partial<FeedDetails>) => void;
}
export default function FeedDetailsBox({
  isFilterType,
  typeOptions,
  feedDetails,
  onChange,
}: FeedDetailsProps) {
  return (
    <Box
      sx={{
        display: 'grid',
        columnGap: 5,
        rowGap: 2,
        gridTemplateColumns: 'repeat(2, 1fr)',
      }}
    >
      <Grid item>
        <Stack direction="row" spacing={1}>
          <TextField
            label="Feed Order"
            placeholder="Feed Order"
            type="number"
            value={feedDetails?.feedOrder ?? ''}
            onChange={(event) => {
              onChange({
                feedOrder: event.target.value
                  ? Number(event.target.value)
                  : undefined,
              });
            }}
            sx={{ bgcolor: '#fff', flex: 1 }}
          />
          <TextField
            required
            label="Name"
            placeholder="Enter a name"
            value={feedDetails?.name || ''}
            onChange={(event) =>
              onChange({ name: event.target.value || undefined })
            }
            sx={{ bgcolor: '#fff', flex: 1 }}
          />
          <TextField
            required
            label="Slug"
            placeholder="Enter a slug"
            value={feedDetails?.slug || ''}
            onChange={(event) =>
              onChange({ slug: event.target.value || undefined })
            }
            sx={{ bgcolor: '#fff', flex: 1 }}
          />
        </Stack>
      </Grid>

      <Grid item>
        <Stack direction="row" spacing={1}>
          <Autocomplete
            freeSolo
            options={typeOptions}
            inputValue={feedDetails?.type || ''}
            onInputChange={(event, newInputValue) => {
              onChange({ type: newInputValue || undefined });
            }}
            renderInput={(params) => (
              <TextField
                {...params}
                required
                label="Type"
                placeholder="Enter a type"
              />
            )}
            sx={{ bgcolor: '#fff', width: '100%' }}
          />
          <TextField
            label="Card Type"
            placeholder="Enter a card type"
            value={feedDetails?.cardType || ''}
            onChange={(event) =>
              onChange({ cardType: event.target.value || undefined })
            }
            sx={{ bgcolor: '#fff', width: '100%' }}
          />
          <CustomSelect
            label="Status"
            value={feedDetails?.status || ''}
            onChange={(event) =>
              onChange({
                status: event.target.value ? event.target.value : undefined,
              })
            }
          >
            <MenuItem key="active" value="active">
              Active
            </MenuItem>
            <MenuItem key="inactive" value="inactive">
              Inactive
            </MenuItem>
          </CustomSelect>
        </Stack>
      </Grid>
      <Grid item>
        <TextField
          label="Description"
          placeholder="Enter a description"
          value={feedDetails?.description || ''}
          onChange={(event) =>
            onChange({ description: event.target.value || undefined })
          }
          sx={{ bgcolor: '#fff', width: '100%' }}
        />
      </Grid>
      <Grid item>
        <Stack direction="row" spacing={1}>
          <TextField
            label="# items shown"
            placeholder="# items shown"
            type="number"
            disabled={!isFilterType}
            value={feedDetails?.limit ?? ''}
            onChange={(event) => {
              onChange({
                limit: event.target.value
                  ? Number(event.target.value)
                  : undefined,
              });
            }}
            sx={{ bgcolor: '#fff', width: '100%' }}
          />
          <TextField
            label="Start show from"
            placeholder="Start show from"
            type="number"
            disabled={!isFilterType}
            value={feedDetails?.offset ?? ''}
            onChange={(event) => {
              onChange({
                offset: event.target.value
                  ? Number(event.target.value)
                  : undefined,
              });
            }}
            sx={{ bgcolor: '#fff', width: '100%' }}
          />
          <CustomSelect
            label="See All Button"
            value={String(feedDetails?.hasSeeAll ?? '')}
            onChange={(event) =>
              onChange({
                hasSeeAll: event.target.value
                  ? event.target.value === 'true'
                  : undefined,
              })
            }
          >
            <MenuItem key="show" value="true">
              Show
            </MenuItem>
            <MenuItem key="hide" value="false">
              Hide
            </MenuItem>
          </CustomSelect>
        </Stack>
      </Grid>
    </Box>
  );
}
