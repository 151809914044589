import { Container } from '@mui/material';
import DeviceView from 'src/components/modules/Devices/view/Device';
import Page from 'src/template/components/Page';

export default function DevicePage(): JSX.Element {
  return (
    <Page title="Device">
      <Container maxWidth="xl">
        <DeviceView />
      </Container>
    </Page>
  );
}
