import {
  AccessTime,
  FlashOn,
  PauseCircle,
  Remove,
  Stop,
} from '@mui/icons-material';
import {
  Button,
  Card,
  InputAdornment,
  MenuItem,
  Stack,
  Tab,
  Tabs,
  TextField,
  Typography,
} from '@mui/material';
import { useState } from 'react';
import Flex from 'src/components/shared/Flex';
import DebouncedField from 'src/components/shared/Inputs/DebouncedField';
import Loader from 'src/components/shared/Loader';
import useDebounce from 'src/lib/hooks/useDebounce';
import useNotify from 'src/lib/hooks/useNotify';
import { User } from 'src/lib/types/users';
import { formatTimeToHuman } from 'src/lib/utils/formatTimeToHuman';
import HeaderBreadcrumbs from 'src/template/components/HeaderBreadcrumbs';
import Iconify from 'src/template/components/Iconify';
import Label from 'src/template/components/Label';
import Scrollbar from 'src/template/components/Scrollbar';
import useTabs from 'src/template/hooks/useTabs';
import {
  PurgeUseCaseStatuses,
  UserPurgeStatus,
} from '../../Profile/types/UserPurge';
import { PurgeAllUsers } from '../services/PurgeAllUsers';
import { PurgeMultipleUsers } from '../services/PurgeMultipleUsers';
import { StopPurges } from '../services/StopPurges';
import { useFetchUserPurgeCountByStatus } from '../services/useFetchUserPurgeCountByStatus';
import { useFetchUserPurgeStatus } from '../services/useFetchUserPurgeStatus';
import { useFetchUserPurges } from '../services/useFetchUserPurges';
import UserPurgesTable from './Table';
import UserPurgeTableFooter from './Table/Footer';
import useUserPurgesTable, {
  SearchForOptions,
} from './Table/useUserPurgesTable';

const SEARCH_OPTIONS = [
  {
    label: 'UUID',
    value: 'uuid',
  },
  {
    label: `Status`,
    value: 'status',
  },
  {
    label: `User uuid`,
    value: 'userUuid',
  },
  {
    label: `User slug`,
    value: 'user.slug',
  },
  {
    label: `User name`,
    value: 'user.name',
  },
  {
    label: `User email`,
    value: 'user.email',
  },
  {
    label: `Triggered By User (uuid)`,
    value: 'triggeredByUserUuid',
  },
  {
    label: `Triggered By User (slug)`,
    value: 'triggeredByUser.slug',
  },
  {
    label: `Triggered By User (Name)`,
    value: 'triggeredByUser.name',
  },
  {
    label: `Triggered By User (email)`,
    value: 'triggeredByUser.email',
  },
  {
    label: `Created By User (uuid)`,
    value: 'createdByUserUuid',
  },
  {
    label: `Created By User (slug)`,
    value: 'createdByUser.slug',
  },
  {
    label: `Created By User (Name)`,
    value: 'createdByUser.name',
  },
  {
    label: `Created By User (email)`,
    value: 'createdByUser.email',
  },
];

export default function UserPurgesList() {
  const { data: running } = useFetchUserPurgeCountByStatus(
    UserPurgeStatus.running
  );
  const { data: pending } = useFetchUserPurgeCountByStatus(
    UserPurgeStatus.pending
  );
  const { data: completed } = useFetchUserPurgeCountByStatus(
    UserPurgeStatus.completed
  );
  const { data: failed } = useFetchUserPurgeCountByStatus(
    UserPurgeStatus.failed
  );

  const STATUS_TABS = [
    { value: 'waiting', label: `Waiting (${(pending || 0) + (running || 0)})` },
    { value: 'completed', label: `Completed (${completed || 0})` },
    { value: 'failed', label: `Failed (${failed || 0})` },
  ];

  const { currentTab: tabStatus, onChangeTab: onChangeTabStatus } = useTabs(
    'waiting',
    {
      queryName: 'status',
    }
  );

  const isInWaitingTab = tabStatus === 'waiting';

  const notify = useNotify();
  const { data: status } = useFetchUserPurgeStatus();

  const [tableFilter, setQuery, setSearchFor, selected, toggleSelectAll] =
    useUserPurgesTable((state) => [
      state.filter,
      state.setQuery,
      state.setSearchFor,
      state.selected,
      state.toggleSelectAll,
    ]);

  const { page, query, searchFor, size } = tableFilter;

  const filter: any = {};
  const filterQuery = useDebounce(query, 500);
  if (searchFor && filterQuery !== '') {
    filter[searchFor] = {
      like: `%${filterQuery}%`,
    };
  }
  const {
    data: userPurges,
    meta,
    loading,
  } = useFetchUserPurges(
    {
      filter,
      page,
      size,
    },
    tabStatus
  );

  const total = meta?.totalCount || 0;

  const searchHighlight = {
    searchColumn: searchFor,
    searchKeyword: filterQuery,
  };

  const getUserIdentifier = (user: User | undefined) => {
    if (!user) return 'Unknown';
    if (user.slug) return `@${user.slug}`;
    if (user.name) return user.name;
    if (user.email) return user.email;
    return `with UUID: ${user.uuid}`;
  };

  const currentStep = status?.currentPurge?.steps?.find(
    (step) => step.status === 'running'
  );

  const currentStepOrder = currentStep?.order || 0;

  const totalSteps = status?.currentPurge?.steps?.length || 0;

  const [isPurgeTriggered, setIsPurgeTriggered] = useState(false);

  const handlePurgeSelected = async () => {
    setIsPurgeTriggered(true);
    const { error } = await PurgeMultipleUsers(selected);
    if (error) {
      notify(error, { variant: 'error' });
    } else {
      notify('Purge started successfully', { variant: 'success' });
    }
    toggleSelectAll(selected);
    setIsPurgeTriggered(false);
  };

  const handleStopPurges = async () => {
    const { error } = await StopPurges();
    if (error) {
      notify(error, { variant: 'error' });
    } else {
      notify('Purge stop requested', { variant: 'warning' });
    }
    toggleSelectAll(selected);
  };

  const handlePurgeAll = async () => {
    setIsPurgeTriggered(true);
    const { error } = await PurgeAllUsers();
    if (error) {
      notify(error, { variant: 'error' });
    } else {
      notify('Purge started successfully', { variant: 'success' });
    }
    setIsPurgeTriggered(false);
    toggleSelectAll(selected);
  };

  const isPurging = status?.status === PurgeUseCaseStatuses.running;
  const isStopRequested =
    status?.status === PurgeUseCaseStatuses.stop_requested;

  const showPurgeButton = !isPurging && !isStopRequested && isInWaitingTab;

  return (
    <>
      <HeaderBreadcrumbs
        heading="User Purges List"
        links={[{ name: 'User Purges', href: '/user-purges' }]}
      />
      <Stack
        spacing={2}
        sx={{ py: 2.5 }}
        justifyItems="flex-start"
        alignItems="flex-start"
      >
        <Stack
          spacing={2}
          direction="row"
          justifyContent="space-between"
          sx={{ width: '100%' }}
        >
          <Card
            sx={{ p: 2, bgcolor: isPurging ? '#DEEDFF' : 'background.neutral' }}
            variant="outlined"
          >
            {(status?.status === PurgeUseCaseStatuses.running ||
              status?.status === PurgeUseCaseStatuses.stop_requested) && (
              <Stack spacing={2} direction="row" alignItems="center">
                <Flex flex={0}>
                  <Loader size={30} />
                </Flex>
                <Stack>
                  <Typography variant="h6" sx={{ flexGrow: 1 }}>
                    Purge in progress for user{' '}
                    {getUserIdentifier(status?.currentPurge?.user)}. Left{' '}
                    {status?.status === PurgeUseCaseStatuses.stop_requested
                      ? '(STOPPING...)'
                      : `${status.totalPurgesLeft} users.`}
                  </Typography>
                  <Typography variant="body2">
                    Step {currentStepOrder} of {totalSteps}. {currentStep?.name}{' '}
                    : {currentStep?.executed || 0} of {currentStep?.total || 0}{' '}
                    items.{' '}
                    {/* <Link to={`/user-purges/${status.currentPurge?.uuid}`}>
                      Show details.
                    </Link> */}
                  </Typography>
                </Stack>
              </Stack>
            )}

            {status?.status === PurgeUseCaseStatuses.stopped && (
              <Stack spacing={2} direction="row" alignItems="center">
                <PauseCircle sx={{ fontSize: 40 }} />
                <Stack>
                  <Typography variant="h6" sx={{ flexGrow: 1 }}>
                    Purge stopped
                  </Typography>
                  <Typography variant="body2">
                    {selected.length > 0
                      ? `${selected.length} Users waiting for purge`
                      : 'Select users to purge in the list below.'}
                  </Typography>
                </Stack>
              </Stack>
            )}
          </Card>

          <Stack spacing={1} direction="row">
            {showPurgeButton && (
              <>
                {selected?.length === 0 && (
                  <Button
                    variant="contained"
                    color="error"
                    sx={{ minWidth: 160 }}
                    disabled={!total || isPurging || isPurgeTriggered}
                    onClick={handlePurgeAll}
                    startIcon={isPurging ? <Loader size={12} /> : <FlashOn />}
                  >
                    {isPurging ? 'Purging...' : `Purge All ${total} users`}
                  </Button>
                )}
                {selected?.length > 0 && (
                  <Button
                    variant="contained"
                    color="error"
                    sx={{ minWidth: 160 }}
                    disabled={!selected.length || isPurging || isPurgeTriggered}
                    onClick={handlePurgeSelected}
                    startIcon={isPurging ? <Loader size={12} /> : <FlashOn />}
                  >
                    {isPurging
                      ? 'Purging...'
                      : `Purge ${selected.length} users`}
                  </Button>
                )}
              </>
            )}
            {(status?.status === PurgeUseCaseStatuses.running ||
              status?.status === PurgeUseCaseStatuses.stop_requested) && (
              <Button
                variant="contained"
                color="warning"
                sx={{ minWidth: 160 }}
                disabled={
                  status?.status === PurgeUseCaseStatuses.stop_requested ||
                  status?.status !== PurgeUseCaseStatuses.running
                }
                onClick={handleStopPurges}
              >
                <Stop />{' '}
                {status?.status === PurgeUseCaseStatuses.stop_requested
                  ? 'Stopping...'
                  : `Stop Purge${selected.length > 0 ? 's' : ''}`}
              </Button>
            )}
            {selected.length > 0 && !isPurging && (
              <Button
                variant="outlined"
                color="error"
                onClick={() => toggleSelectAll(selected)}
                startIcon={<Remove />}
                disabled={
                  isPurging || isPurgeTriggered || selected.length === 0
                }
              >
                Unselect All
              </Button>
            )}
          </Stack>
        </Stack>
        {status?.status === PurgeUseCaseStatuses.running && (
          <Label color="info" sx={{ minWidth: 160, p: 2 }}>
            <AccessTime sx={{ mr: 1 }} />
            Estimated remaining time: {formatTimeToHuman(status.remainingTime)}
          </Label>
        )}
      </Stack>

      <Flex sx={{ px: 3, bgcolor: 'background.neutral' }}>
        <Tabs
          allowScrollButtonsMobile
          variant="scrollable"
          scrollButtons="auto"
          value={tabStatus}
          onChange={onChangeTabStatus}
        >
          {STATUS_TABS.map((tab) => (
            <Tab
              disableRipple
              key={tab.value}
              label={tab.label}
              value={tab.value}
            />
          ))}
        </Tabs>
      </Flex>

      <Card sx={{ borderRadius: 0 }}>
        <Stack
          spacing={2}
          direction={{ xs: 'column', sm: 'row' }}
          sx={{ py: 2.5, px: 3 }}
        >
          <TextField
            fullWidth
            select
            label="Search for"
            value={searchFor}
            onChange={(e) => setSearchFor(e.target.value as SearchForOptions)}
            SelectProps={{
              MenuProps: {
                sx: { '& .MuiPaper-root': { maxHeight: 260 } },
              },
            }}
            sx={{
              maxWidth: { sm: 240 },
              textTransform: 'capitalize',
            }}
          >
            {SEARCH_OPTIONS.map((option) => (
              <MenuItem
                key={option.value}
                value={option.value}
                sx={{
                  mx: 1,
                  my: 0.5,
                  borderRadius: 0.75,
                  typography: 'body2',
                  textTransform: 'capitalize',
                }}
              >
                {option.label}
              </MenuItem>
            ))}
          </TextField>

          <DebouncedField
            fullWidth
            onDebounceChange={(e) => setQuery(e)}
            placeholder="Type here your search text..."
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <Iconify
                    icon="eva:search-fill"
                    sx={{ color: 'text.disabled', width: 20, height: 20 }}
                  />
                </InputAdornment>
              ),
            }}
          />
        </Stack>

        <Scrollbar>
          <UserPurgesTable
            data={userPurges}
            loading={loading}
            searchHighlight={searchHighlight}
          />
        </Scrollbar>

        <UserPurgeTableFooter
          total={total || 0}
          page={page}
          rowsPerPage={size}
        />
      </Card>
    </>
  );
}
