import useNotify from 'src/lib/hooks/useNotify';
import useModalStore from 'src/lib/stores/useModalStore';
import { ClubJoinMethods } from 'src/lib/types/club';
import { ClubFormValues } from '../../components/Forms/Club/types';
import { ClubEmitter } from '../../events/ClubEmitter';
import { CreateClub } from '../../services/createClub';

export default function useCreateClubView() {
  const closeModal = useModalStore((state) => state.closeModal);
  const notify = useNotify();

  const handleCreateClub = async (values: ClubFormValues) => {
    const { error, data } = await CreateClub({
      clubData: {
        name: values.name,
        description: values.description,
        joinMethod: values.joinMethod,
        coverFileUrl: values.coverFileUrl,
        landingPageUrl: values.landingPageUrl,
        photoFileUrl: values.photoFileUrl,
        createdByUserUuid: values.createdByUserUuid,
        ...(values.objectLinks && { objectLinks: values.objectLinks }),
      },
      collectionUuids:
        values.joinMethod === ClubJoinMethods.COLLECTION_OWNERSHIP
          ? values.collections.map((collection) => collection.uuid)
          : [],
      gameUuids: values.hasGame ? values.games.map((game) => game.uuid) : [],
      externalAssets: [],
    });

    if (error) {
      notify(error, { variant: 'error' });
      closeModal();
    } else if (data) {
      notify('Club Created!', { variant: 'success' });
      closeModal();

      ClubEmitter.emit('ClubCreated', data);
    }
  };

  return {
    handleCreateClub,
  };
}
