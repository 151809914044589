/* eslint-disable no-promise-executor-return */
import { Add } from '@mui/icons-material';
import {
  Autocomplete,
  Button,
  InputAdornment,
  Stack,
  TextField,
} from '@mui/material';
import { debounce } from 'lodash';

import Flex from 'src/components/shared/Flex';
import useURLSearchParams from 'src/lib/hooks/useURLSearchParams';

import { LabelValue } from 'src/lib/types/labelValue';
import Iconify from 'src/template/components/Iconify';

import {
  IncludeExcludeDefaultOption,
  IncludeExcludeOptions,
} from 'src/lib/utils/includeExcludeFilterUtils';
import { FetchChannelContentQueryParams } from '../../view/List/useView';

type ChannelContentTableHeaderProps = {
  params: FetchChannelContentQueryParams;
  handleOpenCreateContentModal?: () => void;
};

export default function ChannelContentTableHeader({
  params: { searchBy, onFeed },
  handleOpenCreateContentModal,
}: ChannelContentTableHeaderProps) {
  const { addParam, removeParam, allSearchParams } = useURLSearchParams();

  function handleChangeSearch(value: string, param: string = 'query') {
    if (!value) removeParam(param);
    else addParam(param, value);
  }

  const debouncedSearch = debounce(handleChangeSearch, 1000);

  return (
    <>
      {handleOpenCreateContentModal && (
        <Flex px={2} pt={2} justifyContent={'flex-end'}>
          <Button
            variant="contained"
            color="secondary"
            onClick={handleOpenCreateContentModal}
            startIcon={<Add />}
          >
            Add Content
          </Button>
        </Flex>
      )}
      <Stack spacing={2} p={2} justifyContent={'flex-end'}>
        <Flex gap={2} justifyContent={'flex-end'}>
          <Autocomplete
            options={SEARCH_OPTIONS}
            fullWidth
            renderInput={(params) => (
              <TextField label="Search By" {...params} />
            )}
            getOptionLabel={(p) => p.label}
            sx={{ maxWidth: 250 }}
            defaultValue={SEARCH_OPTIONS.find((o) => o.value === searchBy)}
            key={searchBy}
            onChange={(_, value) => {
              if (!value) removeParam('searchBy');
              else addParam('searchBy', value.value);
            }}
          />
          <TextField
            fullWidth
            label="Search"
            defaultValue={allSearchParams.query}
            type="search"
            onChange={(e) => {
              const { value } = e.target;
              debouncedSearch(value);
            }}
            key={allSearchParams.query}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <Iconify
                    icon="eva:search-fill"
                    sx={{ color: 'text.disabled', width: 20, height: 20 }}
                  />
                </InputAdornment>
              ),
            }}
          />
          <Autocomplete
            sx={{
              width: '200px',
            }}
            disableClearable
            id="onFeed-filter"
            options={IncludeExcludeOptions}
            isOptionEqualToValue={(item, value) => item?.value === value?.value}
            value={
              IncludeExcludeOptions.find((o) => o.value === onFeed) ??
              IncludeExcludeDefaultOption
            }
            renderInput={(params) => (
              <TextField {...params} label="Feed Filter" />
            )}
            onChange={(_, option) => {
              if (option) addParam('onFeed', option.value);
              else removeParam('onFeed');
            }}
          />
        </Flex>
      </Stack>
    </>
  );
}

const SEARCH_OPTIONS: LabelValue[] = [
  {
    label: 'Title',
    value: 'title',
  },
  {
    label: 'Channel',
    value: 'channel',
  },
  {
    label: 'Creator',
    value: 'creator',
  },
  {
    label: 'Item Name',
    value: 'itemName',
  },
];
