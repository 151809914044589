import CloseIcon from '@mui/icons-material/Close';
import {
  Box,
  IconButton,
  List,
  ListItem,
  Slide,
  Typography,
} from '@mui/material';
import React, { useCallback, useState } from 'react';

interface CustomDrawerProps {
  boxProps?: React.ComponentProps<typeof Box>;
  boxSx?: React.CSSProperties;
  title?: string;
  nodes: React.ReactNode[];
  isOpen: boolean;
  onClose?: () => void;
  slideDirection?: 'left' | 'right';
}

export function CustomDrawer({
  boxProps,
  boxSx,
  title,
  nodes,
  isOpen,
  onClose,
  slideDirection = 'right',
}: CustomDrawerProps) {
  const isRight = slideDirection === 'right';
  return (
    <Slide
      direction={slideDirection}
      in={isOpen}
      timeout={300}
      mountOnEnter
      unmountOnExit
    >
      <Box
        sx={{
          width: 240,
          flexShrink: 0,
          bgcolor: 'background.paper',
          borderRight: isRight ? '1px solid #ddd' : 'none',
          borderLeft: !isRight ? '1px solid #ddd' : 'none',
          maxHeight: '90vh',
          display: 'flex',
          flexDirection: 'column',
          ...boxSx,
        }}
        {...boxProps}
      >
        {title && (
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'center',
              py: 2,
              px: 2,
            }}
          >
            <Typography variant="h6">{title}</Typography>
            {onClose && (
              <IconButton onClick={onClose} aria-label="Close">
                <CloseIcon />
              </IconButton>
            )}
          </Box>
        )}

        <List
          sx={{
            flexGrow: 1,
            overflowY: 'auto',
          }}
        >
          {nodes.map((node, index) => (
            // eslint-disable-next-line react/no-array-index-key
            <ListItem key={index} disablePadding>
              {node}
            </ListItem>
          ))}
        </List>
      </Box>
    </Slide>
  );
}

export function useDrawer(initialState: boolean = false) {
  const [isDrawerOpen, setIsDrawerOpen] = useState(initialState);

  const openDrawer = useCallback(() => {
    setIsDrawerOpen(true);
  }, []);

  const closeDrawer = useCallback(() => {
    setIsDrawerOpen(false);
  }, []);

  const toggleDrawer = useCallback(() => {
    setIsDrawerOpen((prev) => !prev);
  }, []);

  return {
    isDrawerOpen,
    openDrawer,
    closeDrawer,
    toggleDrawer,
  };
}
