import { Card, CardContent } from '@mui/material';
import { GridSortDirection } from '@mui/x-data-grid';
import Modal from 'src/components/shared/Modal';
import { GameStatistic } from 'src/lib/types/gameStatistic';
import StatisticsTable from '../../components/statisticsTable';
import useListStatistics from './useView';

interface ListStatisticsProps {
  statistics: GameStatistic[];
}

export default function ListStatisticsView({
  statistics,
}: ListStatisticsProps): JSX.Element {
  const {
    page,
    size,
    order,
    orderBy,
    setPage,
    setRowsPerPage,
    setOrder,
    setOrderBy,
    handleCloseModal,
  } = useListStatistics();

  return (
    <Modal open onClose={handleCloseModal}>
      <Card>
        <CardContent>
          <StatisticsTable
            data={statistics ?? []}
            page={page}
            size={size}
            sortBy={orderBy}
            order={order as GridSortDirection}
            setOrder={setOrder as (order: GridSortDirection) => void}
            setSortBy={setOrderBy}
            setPage={setPage}
            setRowsPerPage={setRowsPerPage}
          />
        </CardContent>
      </Card>
    </Modal>
  );
}
