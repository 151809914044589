import { Add, FilterList } from '@mui/icons-material';
import {
  Badge,
  Card,
  IconButton,
  InputAdornment,
  MenuItem,
  Stack,
  Tab,
  Tabs,
  TextField,
  Tooltip,
} from '@mui/material';
import { useState } from 'react';
import DebouncedField from 'src/components/shared/Inputs/DebouncedField';
import useDebounce from 'src/lib/hooks/useDebounce';
import { fetchCollections } from 'src/lib/services/collections';
import { CustomFilters } from 'src/lib/types/bracketFilterParams';
import HeaderBreadcrumbs from 'src/template/components/HeaderBreadcrumbs';
import Iconify from 'src/template/components/Iconify';
import Scrollbar from 'src/template/components/Scrollbar';
import useTabs from 'src/template/hooks/useTabs';
import { MODERATED_OBJECT_STATUS_TABS } from '../Review/utils/utils';
import CreateOrUpdateCollectionModal from './Collection/components/CreateOrUpdateCollection/CreateOrUpdateCollectionModal';
import CollectionsTableFooter from './CollectionsTable/CollectionTableFooter';
import CollectionsTable from './CollectionsTable/CollectionsTable';
import useCollectionsTable, {
  SearchForOptions,
} from './CollectionsTable/useCollectionsTable';
import CustomFiltersCollectionModal from './CustomFiltersCollectionModal';
import ImportFromOpenSea from './ImportFromOpenSea/ImportFromOpenSea';

const REVIEW_STATUS_TABS = MODERATED_OBJECT_STATUS_TABS;

const SEARCH_OPTIONS = [
  {
    label: 'Name',
    value: 'name',
  },
  {
    label: 'UUID',
    value: 'uuid',
  },
  {
    label: 'Description',
    value: 'description',
  },
  {
    label: 'Creator Name',
    value: 'user.name',
  },
  {
    label: 'Slug',
    value: 'slug',
  },
  {
    label: 'User Email',
    value: 'user.email',
  },
  {
    label: 'Blockchain',
    value: 'blockchain',
  },
];

export default function Collections() {
  const { currentTab, onChangeTab: onChangeReviewStatus } = useTabs('all');

  const { orderBy, order, page, query, searchFor, size } = useCollectionsTable(
    (state) => state.filter
  );

  const [setQuery, setSearchFor] = useCollectionsTable((state) => [
    state.setQuery,
    state.setSearchFor,
  ]);

  const [showCreateOrUpdateCollection, setShowCreateOrUpdateCollection] =
    useState(false);

  let humanAnalysisNeededFilter: any = {};
  if (currentTab == 'humanAnalysisNeeded') {
    humanAnalysisNeededFilter = {
      humanAnalysisNeeded: {
        ne: 'False',
      },
    };
  }

  const [customFilters, setCustomFilters] = useState<CustomFilters>({});
  const [showCustomFiltersModal, setShowCustomFiltersModal] = useState(false);
  const {
    data: collections,
    total,
    loading,
  } = fetchCollections(
    {
      query: useDebounce(query, 500),
      page,
      order,
      orderBy,
      searchFor,
      size,
      custom: { ...customFilters, ...humanAnalysisNeededFilter },
    },
    ['all', 'humanAnalysisNeeded'].includes(currentTab ?? '')
      ? 'all'
      : currentTab
  );

  return (
    <>
      <HeaderBreadcrumbs
        heading="Collections"
        links={[{ name: 'Collections' }]}
        action={
          <Stack direction="row" spacing={1}>
            <Tooltip title="Create collection" placement="top">
              <IconButton
                aria-label="add collection"
                onClick={() => setShowCreateOrUpdateCollection(true)}
              >
                <Add />
              </IconButton>
            </Tooltip>
            <ImportFromOpenSea />
          </Stack>
        }
      />
      <Card>
        <Tabs
          allowScrollButtonsMobile
          variant="scrollable"
          scrollButtons="auto"
          value={currentTab}
          onChange={onChangeReviewStatus}
          sx={{ px: 2, bgcolor: 'background.neutral' }}
        >
          {REVIEW_STATUS_TABS.map((tab) => (
            <Tab
              disableRipple
              key={tab.value}
              label={tab.label}
              value={tab.value}
            />
          ))}
        </Tabs>
        <Stack
          spacing={2}
          direction={{ xs: 'column', sm: 'row' }}
          sx={{ py: 2.5, px: 3 }}
        >
          <TextField
            fullWidth
            select
            label="Search for"
            value={searchFor}
            onChange={(e) => setSearchFor(e.target.value as SearchForOptions)}
            SelectProps={{
              MenuProps: {
                sx: { '& .MuiPaper-root': { maxHeight: 260 } },
              },
            }}
            sx={{
              maxWidth: { sm: 240 },
              textTransform: 'capitalize',
            }}
          >
            {SEARCH_OPTIONS.map((option) => (
              <MenuItem
                key={option.value}
                value={option.value}
                sx={{
                  mx: 1,
                  my: 0.5,
                  borderRadius: 0.75,
                  typography: 'body2',
                  textTransform: 'capitalize',
                }}
              >
                {option.label}
              </MenuItem>
            ))}
          </TextField>

          <DebouncedField
            fullWidth
            onDebounceChange={(e) => setQuery(e)}
            placeholder="Search collection..."
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <Iconify
                    icon="eva:search-fill"
                    sx={{ color: 'text.disabled', width: 20, height: 20 }}
                  />
                </InputAdornment>
              ),
            }}
          />
          <IconButton
            aria-label="cart"
            sx={{ height: 'fit-content', alignSelf: 'center' }}
            onClick={() => setShowCustomFiltersModal(true)}
          >
            <Badge
              badgeContent={Object.values(customFilters).reduce(
                (prev, operators) =>
                  prev +
                  (Object.values(operators)?.reduce(
                    (p, val) => p + (val ? 1 : 0),
                    0
                  ) || 0),
                0
              )}
              color="primary"
            >
              <FilterList />
            </Badge>
          </IconButton>
          <CustomFiltersCollectionModal
            customFilters={customFilters}
            setCustomFilters={setCustomFilters}
            open={showCustomFiltersModal}
            onClose={() => setShowCustomFiltersModal(false)}
          />
        </Stack>

        <Scrollbar>
          <CollectionsTable data={collections} loading={loading} />
        </Scrollbar>

        <CollectionsTableFooter
          total={total || 0}
          page={page}
          rowsPerPage={size}
        />
      </Card>
      <CreateOrUpdateCollectionModal
        open={showCreateOrUpdateCollection}
        onClose={() => setShowCreateOrUpdateCollection(false)}
      />
    </>
  );
}
