import { DeepPartial } from 'react-hook-form';
import { Club } from 'src/lib/types/club';
import { parseSearchParams } from 'src/lib/utils/parseSearchParams';
import useSWR, { SWRResponse } from 'swr';

export const fetchClubs = (
  filters?: DeepPartial<PossibleParams>,
  shouldFetch = true
): SWRResponse<Club[]> => {
  const response = useSWR(
    shouldFetch ? `admin/clubs?${parseSearchParams(filters ?? {})}` : null
  );

  return response;
};

type PossibleParams = {
  page: number | string;
  size: number | string;
  filter: {
    status: string;
    name: {
      like: string;
    };
  };
  order: Record<string, string>;
};
