import { DeepPartial } from 'react-hook-form';
import { getMessageFromError } from 'src/lib/utils/error';
import { IncludeExcludeAll } from 'src/lib/utils/includeExcludeFilterUtils';
import { parseSearchParams } from 'src/lib/utils/parseSearchParams';
import useSWR, { SWRResponse } from 'swr';
import { Device } from '../types/Device';

type PossibleParams = {
  page: number | string;
  size: number | string;
  orderBy: string;
  sortOrder: string;
  filter: {
    AnonymousUsers: IncludeExcludeAll;
    country: string;
    platform: string;
    active: boolean | string;
    startDate: string;
    endDate: string;
  };
};

export function parseData(data: Device[] | undefined): Device[] {
  if (!data) {
    return [];
  }
  return data.map((device) => {
    const { appleAdvertisingId, appleVendorId, androidId, webId, pushId } =
      device;
    let platformId: string;
    if (appleAdvertisingId) platformId = appleAdvertisingId;
    else if (appleVendorId) platformId = appleVendorId;
    else if (webId) platformId = webId;
    else if (androidId) platformId = androidId;
    else if (pushId) platformId = pushId;

    return {
      ...device,
      platformId: platformId!,
    };
  });
}

export function fetchDevices(
  params?: DeepPartial<PossibleParams>
): SWRResponse<Device[]> {
  const parsedParams = parseSearchParams(params ?? {});
  const { data, error, ...response } = useSWR(
    `admin/users/ranking/users-per-device?${parsedParams}`
  );
  return {
    ...response,
    data: parseData(data),
    error: error ? getMessageFromError(error) : undefined,
  };
}
