import MakerDialogs from 'src/components/modules/MakerDialog/view/List';
import Page from 'src/template/components/Page';

export default function MakerDialogsPage() {
  return (
    <Page title="SGM Chats">
      <MakerDialogs />
    </Page>
  );
}
