import { useCallback, useEffect } from 'react';
import { DEFAULT_INITIAL_PAGE_SIZE } from 'src/lib/constants/pagination';
import useURLSearchParams from 'src/lib/hooks/useURLSearchParams';

import useNotify from 'src/lib/hooks/useNotify';
import { FeedItemAnalyticsTableRow } from 'src/lib/types/FeedItem';
import { OBJECT_MODERATION_REVIEW_STATUS } from 'src/lib/types/objectModeration';
import { IncludeExcludeAll } from 'src/lib/utils/includeExcludeFilterUtils';
import useTabs from 'src/template/hooks/useTabs';
import { FeedEmitter } from '../../events/FeedEmitter';
import { fetchChannelContents } from '../../services/fetchChannelContents';
import { updateChannelContentStatus } from '../../services/updateChannelContentStatus';

export default function useChannelVideos() {
  const notify = useNotify();

  const { currentTab, onChangeTab } = useTabs('all', {
    queryName: 'tab',
  });

  const { allSearchParams } = useURLSearchParams(initialParams);

  const params = {
    page: +(allSearchParams.page ?? initialParams.page),
    size: +(allSearchParams.size ?? initialParams.size),
    orderBy: allSearchParams.orderBy ?? initialParams.orderBy,
    sortOrder: allSearchParams.order ?? initialParams.order,
    searchBy: allSearchParams.searchBy ?? initialParams.searchBy,
    query: allSearchParams.query ?? initialParams.query,
    onFeed: (allSearchParams.onFeed ??
      initialParams.onFeed) as IncludeExcludeAll,
  };

  const { data, isLoading, isValidating, mutate } = fetchChannelContents({
    page: params.page,
    size: params.size,

    order: params.sortOrder,
    orderBy: params.orderBy,

    filter: {
      ...(params.searchBy &&
        params.query && {
          [params.searchBy]: {
            like: `%${params.query}%`,
          },
        }),
      onFeed: params.onFeed as IncludeExcludeAll,
      ...(!['all', 'humanAnalysisNeeded'].includes(currentTab ?? '') && {
        reviewStatus: currentTab as OBJECT_MODERATION_REVIEW_STATUS,
      }),
      ...(currentTab == 'humanAnalysisNeeded' && {
        humanAnalysisNeeded: true,
      }),
    },
  });

  async function onChangeChannelContentStatus(
    uuid: string,
    channelUuid: string,
    currentStatus: 'active' | 'inactive'
  ) {
    const newStatus = currentStatus === 'active' ? 'inactive' : 'active';

    await updateChannelContentStatus(uuid, channelUuid, {
      status: newStatus,
    })
      .then(({ error, ...rest }) => {
        if (error) {
          notify(error, { variant: 'error' });
        } else {
          notify('Changed feed status successfully', {
            variant: 'success',
          });
        }

        const newData = data?.body.map((feed) => {
          if (feed.uuid === uuid) {
            return {
              ...feed,
              status: newStatus,
            };
          }
          return feed;
        }) as FeedItemAnalyticsTableRow[];

        mutate({ body: newData, meta: data?.meta! });
      })
      .catch(() => {
        notify('Failed to change game status', {
          variant: 'error',
        });
      });
  }

  const refetchData = useCallback(() => {
    mutate?.();
  }, []);

  useEffect(() => {
    FeedEmitter.on('FeedItemCreated', refetchData);
    FeedEmitter.on('FeedItemUpdated', refetchData);
    FeedEmitter.on('FeedItemDeleted', refetchData);
    return () => {
      FeedEmitter.off('FeedItemCreated', refetchData);
      FeedEmitter.off('FeedItemUpdated', refetchData);
      FeedEmitter.off('FeedItemDeleted', refetchData);
    };
  }, [refetchData]);

  return {
    data,
    isLoading: isLoading || isValidating,
    params,
    onChangeChannelContentStatus,
    currentTab,
    onChangeTab,
  };
}

const initialParams = {
  page: '0',
  size: DEFAULT_INITIAL_PAGE_SIZE.toString(),
  order: 'desc',
  orderBy: 'updatedAt',
  searchBy: '',
  query: '',
  onFeed: IncludeExcludeAll.All,
};

export type FetchChannelContentQueryParams = {
  page: number;
  size: number;
  orderBy: string;
  searchBy: string;
  sortOrder: string;
  query: string;
  onFeed: IncludeExcludeAll;
};
