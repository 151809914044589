import { StatisticGroupTypes } from 'src/lib/types/statisticGroup';
import StatisticGroupForm from '../../components/Forms/StatisticGroup';
import { StatisticGroupFormValues } from '../../components/Forms/StatisticGroup/types';
import useCreateStatisticGroupView from './useView';

interface CreateStatisticGroupViewProps {
  gameUuid: string;
}

export default function CreateStatisticGroupView({
  gameUuid,
}: CreateStatisticGroupViewProps): JSX.Element {
  const { handleCreateStatisticGroup } = useCreateStatisticGroupView();
  return (
    <StatisticGroupForm
      gameUuid={gameUuid}
      defaultValues={{
        name: '',
        type: StatisticGroupTypes.ASSET_GROUP,
        actionUuid: '',
        typeUuid: '',
      }}
      onSubmit={async (values: StatisticGroupFormValues) => {
        await handleCreateStatisticGroup({
          ...values,
        });
      }}
      title="Create Statistic Group"
    />
  );
}
