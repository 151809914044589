import { Card, CardContent, IconButton, Tab, Tabs } from '@mui/material';
import { GridSortDirection } from '@mui/x-data-grid';
import Flex from 'src/components/shared/Flex';
import NumberInfo from 'src/components/shared/NumberInfo/NumberInfo';
import HeaderBreadcrumbs from 'src/template/components/HeaderBreadcrumbs';
import Iconify from 'src/template/components/Iconify';
import CoinsExpiringTable from '../../components/Expiring/Table';
import { ExpiringCoinsTabs } from '../../types/CoinExpiration';
import useExpiringView from './useView';

const cardWidth: number = 200;
const EXPIRATION_TABS = [
  { value: ExpiringCoinsTabs.NextExpiring, label: 'To Expire' },
  { value: ExpiringCoinsTabs.LatestExpired, label: 'Expired' },
  { value: ExpiringCoinsTabs.Blocked, label: 'Blocked' },
  { value: ExpiringCoinsTabs.Failed, label: 'Failed' },
];

export default function CoinExpiring() {
  const {
    data,
    breakdownData,
    isLoading,
    page,
    size,
    order,
    sortBy,
    expirationType,
    coinType,
    onChangeExpirationType,
    handleOpenFilters,
    handleOpenExpireModal,
  } = useExpiringView();

  const actualBreakdownData = breakdownData?.find((bd) => bd.type === coinType);

  return (
    <>
      <HeaderBreadcrumbs
        heading="Coins Expiring"
        links={[{ name: 'Coins Expiring' }]}
        action={
          <Flex
            width="100%"
            justifyContent="start"
            gap={2}
            flexWrap="wrap"
            p={2}
          >
            <NumberInfo
              title="Total Expired"
              total={Number(actualBreakdownData?.total_expired) / 1000}
              componentProps={{
                sx: {
                  width: cardWidth,
                },
              }}
            />
            <NumberInfo
              title="Expiring in One Day"
              total={Number(actualBreakdownData?.expiring_one_day) / 1000}
              componentProps={{
                sx: {
                  width: cardWidth,
                },
              }}
            />
            <NumberInfo
              title="Expiring in One Week"
              total={Number(actualBreakdownData?.expiring_one_week) / 1000}
              componentProps={{
                sx: {
                  width: cardWidth,
                },
              }}
            />
            <NumberInfo
              title="Expiring in One Month"
              total={Number(actualBreakdownData?.expiring_one_month) / 1000}
              componentProps={{
                sx: {
                  width: cardWidth,
                },
              }}
            />
            <NumberInfo
              title="Expiring in One Year"
              total={Number(actualBreakdownData?.expiring_one_year) / 1000}
              componentProps={{
                sx: {
                  width: cardWidth,
                },
              }}
            />
            <NumberInfo
              title="More Than One Year"
              total={
                Number(actualBreakdownData?.expiring_more_than_one_year) / 1000
              }
              componentProps={{
                sx: {
                  width: cardWidth,
                },
              }}
            />
          </Flex>
        }
      />
      <Card>
        <Flex sx={{ px: 2, bgcolor: 'background.neutral' }}>
          <Tabs
            allowScrollButtonsMobile
            variant="scrollable"
            scrollButtons="auto"
            value={expirationType}
            onChange={onChangeExpirationType}
          >
            {EXPIRATION_TABS.map((tab) => (
              <Tab
                disableRipple
                key={tab.value}
                label={tab.label}
                value={tab.value}
              />
            ))}
          </Tabs>
          <IconButton sx={{ ml: 'auto' }} onClick={handleOpenFilters}>
            <Iconify icon="lucide:filter" />
          </IconButton>
        </Flex>
        <CardContent>
          <CoinsExpiringTable
            isLoading={isLoading}
            data={data ?? []}
            page={page}
            size={size}
            order={order as GridSortDirection}
            sortBy={sortBy!}
            handleOpenExpireModal={
              expirationType === ExpiringCoinsTabs.NextExpiring
                ? handleOpenExpireModal
                : undefined
            }
          />
        </CardContent>
      </Card>
    </>
  );
}
