import { Container } from '@mui/material';
import ToReview from 'src/components/modules/Review/view/ListToReview';
import Page from 'src/template/components/Page';

export default function ToReviewPage() {
  return (
    <Page title="To Review">
      <Container maxWidth="xl">
        <ToReview />
      </Container>
    </Page>
  );
}
