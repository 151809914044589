import { useCallback, useEffect } from 'react';
import { useNavigate, useParams } from 'react-router';
import { DEFAULT_INITIAL_PAGE_SIZE } from 'src/lib/constants/pagination';
import useNotify from 'src/lib/hooks/useNotify';
import useURLSearchParams from 'src/lib/hooks/useURLSearchParams';
import useModalStore from 'src/lib/stores/useModalStore';
import Routes from 'src/routes/Routes';
import { SubmissionEmitter } from '../../events/SubmissionEmitter';
import { fetchSubmissionDetails } from '../../services/fetchSubmissionDetails';
import ReviewSubmission from '../ReviewSubmission';

export default function useSubmissionDetailsView() {
  const openModal = useModalStore((state) => state.openModal);
  const notify = useNotify();
  const { submissionUuid } = useParams<'submissionUuid'>();
  const navigate = useNavigate();
  if (!submissionUuid) navigate(Routes.submission.submissions);

  const handleOpenChangeStatusModal = () => {
    openModal(<ReviewSubmission submissionUuid={submissionUuid!} />);
  };

  const { data: submissionDetails, mutate } = fetchSubmissionDetails(
    submissionUuid!
  );

  // Params

  const { allSearchParams } = useURLSearchParams(initialParams);

  const params = {
    page: +(allSearchParams.page ?? initialParams.page),
    size: +(allSearchParams.size ?? initialParams.size),
    orderBy: allSearchParams.orderBy ?? initialParams.orderBy,
    sortOrder: allSearchParams.order ?? initialParams.order,
    searchBy: allSearchParams.searchBy ?? initialParams.searchBy,
    query: allSearchParams.query ?? initialParams.query,
  };

  const refetchData = useCallback(() => {
    mutate?.();
  }, []);

  useEffect(() => {
    SubmissionEmitter.on('SubmissionCreated', refetchData);
    SubmissionEmitter.on('SubmissionUpdated', refetchData);
    SubmissionEmitter.on('SubmissionDeleted', refetchData);
    return () => {
      SubmissionEmitter.off('SubmissionCreated', refetchData);
      SubmissionEmitter.off('SubmissionUpdated', refetchData);
      SubmissionEmitter.off('SubmissionDeleted', refetchData);
    };
  }, [refetchData]);

  return {
    submissionDetails,
    params,
    handleOpenChangeStatusModal,
  };
}

const initialParams = {
  page: '0',
  size: DEFAULT_INITIAL_PAGE_SIZE.toString(),
  order: 'desc',
  orderBy: 'updatedAt',
  searchBy: '',
  query: '',
};
