import {
  NotificationSubType,
  NotificationType,
} from '../types/NotificationType';

export const DEFAULT_NOT_MAPPED_MESSAGE =
  'Notification Type and Subtype must be mapped';

export function getPurchaseDescription(
  notificationType: NotificationType,
  notificationSubType: NotificationSubType | null | undefined
): string {
  if (notificationType === NotificationType.RENEW) {
    if (notificationSubType === NotificationSubType.BILLING_RECOVERY) {
      return 'The expired subscription that previously failed to renew has successfully renewed.';
    }
    return "The active subscription has successfully auto-renewed for a new transaction period. Provide the customer with access to the subscription's content or service.";
  }

  if (notificationType === NotificationType.SUBSCRIPTION) {
    if (notificationSubType === NotificationSubType.INITIAL_BUY) {
      return 'User either purchased or received access through Family Sharing to the subscription for the first time.';
    }
    if (notificationSubType === NotificationSubType.RESUBSCRIBE) {
      return 'User resubscribed or received access through Family Sharing to the same subscription or to another subscription within the same subscription group.';
    }
    return DEFAULT_NOT_MAPPED_MESSAGE;
  }

  if (notificationType === NotificationType.DID_CHANGE_RENEWAL_STATUS) {
    if (notificationSubType === NotificationSubType.AUTO_RENEW_ENABLED) {
      return 'User reenabled subscription auto-renewal.';
    }
    if (notificationSubType === NotificationSubType.AUTO_RENEW_DISABLED) {
      return 'User disabled subscription auto-renewal, or the App Store disabled subscription auto-renewal after the user requested a refund.';
    }
    return DEFAULT_NOT_MAPPED_MESSAGE;
  }

  if (notificationType === NotificationType.EXPIRED) {
    if (notificationSubType === NotificationSubType.VOLUNTARY) {
      return 'Subscription expired after the user disabled subscription renewal.';
    }
    if (notificationSubType === NotificationSubType.BILLING_RETRY) {
      return 'Subscription expired because the billing retry period ended without a successful billing transaction.';
    }
    if (notificationSubType === NotificationSubType.PRICE_INCREASE) {
      return "Subscription expired because the user didn't consent to a price increase that requires user consent.";
    }
    if (notificationSubType === NotificationSubType.PRODUCT_NOT_FOR_SALE) {
      return "Subscription expired because the product wasn't available for purchase at the time the subscription attempted to renew.";
    }
    return 'Subscription expired for some other reason.';
  }

  if (notificationType === NotificationType.DID_FAIL_TO_RENEW) {
    if (notificationSubType === NotificationSubType.GRACE_PERIOD) {
      return 'A notification type that, along with its subtype, indicates that the subscription failed to renew due to a billing issue. If the subtype is GRACE_PERIOD, continue to provide service through the grace period.';
    }

    return "The subscription enters the billing retry period. The subscription isn't in a grace period and you can stop providing the subscription service.";
  }

  if (notificationType === NotificationType.DID_CHANGE_RENEWAL_PREF) {
    if (notificationSubType === NotificationSubType.UPGRADE) {
      return 'User upgraded their subscription, or cross-graded to a subscription with the same duration. The upgrade goes into effect immediately, starting a new billing period, and the user receives a prorated refund for the unused portion of the previous period.';
    }
    if (notificationSubType === NotificationSubType.DOWNGRADE) {
      return "User downgraded their subscription or cross-graded to a subscription with a different duration. Downgrades take effect at the next renewal date and don't affect the currently active plan.";
    }

    return 'User changed their renewal preference back to the current subscription, effectively canceling a downgrade.';
  }

  if (notificationType === NotificationType.CONSUMPTION_REQUEST) {
    return 'A notification type that indicates that the customer initiated a refund request for a consumable in-app purchase, and the App Store is requesting that you provide consumption data.';
  }

  if (notificationType === NotificationType.REFUND) {
    return 'A notification type that indicates that the App Store successfully refunded a transaction for a consumable in-app purchase, a non-consumable in-app purchase, an auto-renewable subscription, or a non-renewing subscription.';
  }

  if (notificationType === NotificationType.GRACE_PERIOD_EXPIRED) {
    return 'A notification type that indicates that the billing grace period has ended without renewing the subscription, so you can turn off access to the service or content.';
  }

  if (notificationType === NotificationType.OFFER_REDEEMED) {
    if (notificationSubType === NotificationSubType.INITIAL_BUY) {
      return 'User redeemed the offer for a first-time purchase.';
    }
    if (notificationSubType === NotificationSubType.RESUBSCRIBE) {
      return 'User redeemed an offer to resubscribe to an inactive subscription.';
    }
    if (notificationSubType === NotificationSubType.UPGRADE) {
      return 'User redeemed an offer to upgrade their active subscription, which goes into effect immediately.';
    }
    if (notificationSubType === NotificationSubType.DOWNGRADE) {
      return 'User redeemed an offer to downgrade their active subscription, which goes into effect at the next renewal date.';
    }

    return 'User redeemed an offer for their active subscription';
  }

  if (notificationType === NotificationType.PRICE_INCREASE) {
    if (notificationSubType === NotificationSubType.PENDING) {
      return "User hasn't responded to the price increase";
    }
    if (notificationSubType === NotificationSubType.ACCEPTED) {
      return "User has consented to the price increase. If the price increase doesn't require user consent, the subtype is ACCEPTED.";
    }
    return DEFAULT_NOT_MAPPED_MESSAGE;
  }

  if (notificationType === NotificationType.REFUND_DECLINED) {
    return 'A notification type that indicates the App Store declined a refund request initiated by the app developer using any of the following methods: beginRefundRequest(for:in:), beginRefundRequest(in:), beginRefundRequest(for:in:), beginRefundRequest(in:), and refundRequestSheet(for:isPresented:onDismiss:).';
  }

  if (notificationType === NotificationType.REFUND_REVERSED) {
    return 'A notification type that indicates the App Store reversed a previously granted refund due to a dispute that the customer raised. If your app revoked content or services as a result of the related refund, it needs to reinstate them. This notification type can apply to any in-app purchase type: consumable, non-consumable, non-renewing subscription, and auto-renewable subscription. For auto-renewable subscriptions, the renewal date remains unchanged when the App Store reverses a refund.';
  }

  if (notificationType === NotificationType.RENEWAL_EXTENDED) {
    return 'A notification type that indicates the App Store extended the subscription renewal date for a specific subscription.';
  }

  if (notificationType === NotificationType.RENEWAL_EXTENSION) {
    if (notificationSubType === NotificationSubType.SUMMARY) {
      return 'App Store completed extending the renewal date for all eligible subscribers.';
    }
    if (notificationSubType === NotificationSubType.FAILURE) {
      return "Renewal date extension didn't succeed for a specific subscription.";
    }
    return DEFAULT_NOT_MAPPED_MESSAGE;
  }

  if (notificationType === NotificationType.REVOKE) {
    return 'A notification type that indicates that an in-app purchase the user was entitled to through Family Sharing is no longer available through sharing. The App Store sends this notification when a purchaser disabled Family Sharing for a product, the purchaser (or family member) left the family group, or the purchaser asked for and received a refund.';
  }

  if (notificationType === NotificationType.TEST) {
    return 'A notification type that the App Store server sends when you request it by calling the Request a Test Notification endpoint.';
  }

  return DEFAULT_NOT_MAPPED_MESSAGE;
}
