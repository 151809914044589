import { useState } from 'react';
import { useForm } from 'react-hook-form';
import { fetchClubs } from 'src/components/modules/Clubs/services/fetchClubs';
import { fetchGames } from 'src/components/modules/Games/services/fetchGames';
import useDebounce from 'src/lib/hooks/useDebounce';
import { fetchCollections } from 'src/lib/services/collections';
import { fetchNfts } from 'src/lib/services/nft';
import useModalStore from 'src/lib/stores/useModalStore';
import { FeedItem, FeedItemTypes } from 'src/lib/types/FeedItem';
import { uploadToStorage } from 'src/lib/utils/upload';
import { AutoCompleteOption } from './components/Autocomplete';

export type FeedItemForm = FeedItem & {
  contentOrientation?: string;
  contentObjectFit?: string;
  sendToAppFeed?: boolean;
  channelUuid?: string;
};

export function useCreateFeedItemView(item?: FeedItem) {
  const form = useForm<FeedItemForm>({
    defaultValues: { ...item },
  });

  const getAutoCompleteDefaultValue: () => AutoCompleteOption = () => {
    const currentItemType = form.watch('itemType');
    switch (currentItemType) {
      case FeedItemTypes.game:
        return {
          label: item?.game?.name || '',
          img: item?.game?.versions?.length
            ? item.game.versions[0].imageUrl
            : '',
          uuid: item?.game?.uuid || '',
        };
      case FeedItemTypes.collection:
        return {
          label: item?.collection?.name || '',
          img: item?.collection?.coverFileUrl || '',
          uuid: item?.collection?.uuid || '',
        };
      case FeedItemTypes.nft:
        return {
          label: item?.nft?.name || '',
          img: item?.nft?.fileUrl || '',
          uuid: item?.nft?.uuid || '',
        };
      case FeedItemTypes.club:
        return {
          label: item?.club?.name || '',
          img: item?.club?.coverFileUrl || '',
          uuid: item?.club?.uuid || '',
        };
      default:
        return {
          label: '',
          img: '',
          uuid: '',
        };
    }
  };

  const onClose = useModalStore((state) => state.closeModal);

  const handleCloseModal = () => {
    onClose();
  };

  const [searchText, setSearchText] = useState<string>('');
  const handleInputChange = (event: React.ChangeEvent<{}>, newValue: any) => {
    setSearchText(newValue);
  };

  const currentItemType = form.watch('itemType');

  const { data: games, isLoading: isLoadingGames } = fetchGames(
    {
      page: 0,
      size: 5,
      filter: {
        ...(searchText && {
          name: {
            like: `%${searchText}%`,
          },
        }),
      },
      order: {
        createdAt: 'desc',
      },
    },
    currentItemType === FeedItemTypes.game
  );

  const { data: collections, loading: isLoadingCollections } = fetchCollections(
    {
      orderBy: 'createdAt',
      order: 'desc',
      searchFor: 'name',
      query: useDebounce(searchText, 500),
      page: 0,
      size: 5,
    },
    'all',
    currentItemType !== FeedItemTypes.collection
  );

  const { data: nfts, loading: isLoadingNfts } = fetchNfts(
    {
      page: 0,
      size: 5,
      filter: {
        ...(searchText && {
          name: {
            like: `%${searchText}%`,
          },
        }),
      },
      order: {
        createdAt: 'desc',
      },
    },
    currentItemType !== FeedItemTypes.nft
  );

  const { data: clubs, isLoading: isLoadingClubs } = fetchClubs(
    {
      page: 0,
      size: 5,
      filter: {
        ...(searchText && {
          name: {
            like: `%${searchText}%`,
          },
        }),
      },
      order: {
        createdAt: 'desc',
      },
    },
    currentItemType === FeedItemTypes.club
  );

  let autoCompleteOptions: AutoCompleteOption[];
  switch (currentItemType) {
    case FeedItemTypes.game:
      autoCompleteOptions = games
        ? games.rows.map((game) => ({
            label: game.name,
            img: game.versions?.length ? game.versions[0].imageUrl : '',
            uuid: game.uuid,
          }))
        : [];
      break;
    case FeedItemTypes.collection:
      autoCompleteOptions = collections
        ? collections.map((collection) => ({
            label: collection.name,
            img: collection.coverFileUrl || '',
            uuid: collection.uuid,
          }))
        : [];
      break;
    case FeedItemTypes.nft:
      autoCompleteOptions = nfts
        ? nfts.map((nft) => ({
            label: nft.name,
            img: nft.fileUrl || '',
            uuid: nft.uuid,
          }))
        : [];
      break;
    case FeedItemTypes.club:
      autoCompleteOptions = clubs
        ? clubs.map((club) => ({
            label: club.name,
            img: club.coverFileUrl || '',
            uuid: club.uuid,
          }))
        : [];
      break;
    default:
      autoCompleteOptions = [];
      break;
  }

  let autoCompleteLabel = '';
  switch (currentItemType) {
    case FeedItemTypes.game:
      autoCompleteLabel = 'Search for a game';
      break;
    case FeedItemTypes.collection:
      autoCompleteLabel = 'Search for a collection';
      break;
    case FeedItemTypes.nft:
      autoCompleteLabel = 'Search for an NFT';
      break;
    default:
      autoCompleteLabel = 'Select an item type above';
      break;
  }

  const disableAutoComplete = !currentItemType;

  const [isUpdatingContent, setIsUpdatingContent] = useState<boolean>(false);
  const onUpdateContent = async (file: any, fieldName: keyof FeedItem) => {
    setIsUpdatingContent(true);

    try {
      const url = await uploadToStorage('feed-content', file);

      form.setValue(fieldName, url as string);
    } finally {
      setIsUpdatingContent(false);
    }
  };

  const currentContentType = form.watch('contentType');

  return {
    autoCompleteOptions,
    autoCompleteLabel,
    autoCompleteDefaultValue: getAutoCompleteDefaultValue(),
    showItemUuidField: currentItemType !== FeedItemTypes.app,
    disableAutoComplete,
    isLoading:
      isLoadingCollections || isLoadingGames || isLoadingNfts || isLoadingClubs,
    handleInputChange,
    form,
    handleCloseModal,
    isUpdatingContent,
    onUpdateContent,
    currentContentType,
  };
}
