import { DeepPartial } from 'react-hook-form';
import { UserBlock } from 'src/lib/types/UserBlock';
import { parseSearchParams } from 'src/lib/utils/parseSearchParams';
import useSWR, { SWRResponse } from 'swr';

type PossibleParams = {
  page: number | string;
  size: number | string;
  order: Record<string, string>;
  filter: {
    active: string | boolean;
    'user.uuid': string;
    'user.name': string;
    'user.email': string;
    'user.authId': string;
  };
};
export const fetchUserUnblocks = (
  filter?: DeepPartial<PossibleParams>
): SWRResponse<UserBlock[]> => {
  const parsedParams = parseSearchParams(filter ?? {});
  const response = useSWR<UserBlock[], Error>(
    `admin/user-block/finished?${parsedParams}`
  );

  return response;
};
