import { GridColumns } from '@mui/x-data-grid';
import { capitalCase } from 'change-case';
import Link from 'src/components/shared/Link';
import {
  FeedItemAnalyticsTableRow,
  FeedItemStatus,
  FeedItemTypes,
} from 'src/lib/types/FeedItem';
import Routes from 'src/routes/Routes';
import Label from 'src/template/components/Label';

export const tableColumns: GridColumns<FeedItemAnalyticsTableRow> = [
  {
    field: 'title',
    headerName: 'Title',
    sortable: true,
  },
  {
    field: 'status',
    headerName: 'Status',
    sortable: true,
    maxWidth: 100,
    renderCell: ({ row }) => (
      <Label
        variant="ghost"
        color={row.status == FeedItemStatus.ACTIVE ? 'success' : 'error'}
        sx={{ textTransform: 'capitalize' }}
      >
        {capitalCase(row.status)}
      </Label>
    ),
  },
  {
    field: 'itemUuid',
    headerName: 'Related Item',
    sortable: false,
    renderCell({ row }) {
      return <FeedItemAnalyticsRelatedItemCell row={row} />;
    },
  },
  {
    field: 'itemType',
    headerName: 'Item Type',
    maxWidth: 100,
    sortable: true,
  },
  {
    field: 'contentUrl',
    headerName: 'Content URL',
    sortable: false,
    renderCell({ row }) {
      return (
        <a href={row.contentUrl} target="_blank" rel="noreferrer">
          {row.title ?? 'No title'}
        </a>
      );
    },
  },
  {
    field: 'views',
    headerName: 'Views',
    maxWidth: 100,
    sortable: true,
  },
  {
    field: 'clicks',
    headerName: 'Clicks',
    maxWidth: 100,
    sortable: true,
  },
  {
    field: 'uniqueUsersViewed',
    headerName: 'Unique Users Viewed',
    sortable: true,
  },
  {
    field: 'clicksPerUser',
    headerName: 'Clicks / Users',
    sortable: true,
    renderCell({ row }) {
      // const data = row.clicks / row.uniqueUsersViewed;
      const data = row.clicksPerUser;
      return row.uniqueUsersViewed ? `${(data * 100).toFixed(2)}%` : '--';
    },
  },
  {
    field: 'averagePositionOnFeed',
    headerName: 'Average Position On Feed',
    sortable: true,
    renderCell({ row }) {
      return (
        <p>
          {row.averagePositionOnFeed === null
            ? '--'
            : row.averagePositionOnFeed.toFixed(2)}
        </p>
      );
    },
  },
];

function FeedItemAnalyticsRelatedItemCell({
  row,
}: {
  row: FeedItemAnalyticsTableRow;
}) {
  const { itemUuid, itemType, game, nft, collection } = row;

  if (!itemUuid) return null;
  let itemUrl: string;
  let itemName: string;
  const defaultValue = '--';

  switch (itemType) {
    case FeedItemTypes.game:
      itemUrl = `${Routes.game_center.game.view(itemUuid)}` ?? defaultValue;
      itemName = game?.name ?? game?.uuid.substring(0, 5) ?? defaultValue;
      break;
    case FeedItemTypes.collection:
      itemUrl = `${Routes.collection(itemUuid)}` ?? defaultValue;
      itemName =
        collection?.name ?? collection?.uuid.substring(0, 5) ?? defaultValue;
      break;
    case FeedItemTypes.nft:
      itemUrl = `${Routes.collection(itemUuid)}`;
      itemName = nft?.name ?? nft?.uuid.substring(0, 5) ?? defaultValue;
      break;
    default:
      itemUrl = defaultValue;
      itemName = defaultValue;
      break;
  }
  return (
    <Link to={itemUrl} color={'GrayText'} underline="hover">
      {itemName}
    </Link>
  );
}
