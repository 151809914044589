import getAPIClient from 'src/lib/services/api/axios.config';
import { ServiceResponse } from 'src/lib/types/ServiceResponse';
import { getMessageFromError } from 'src/lib/utils/error';

export type AddAssetsInContestData = {
  uuid: string;
  assets: {
    uuid: string;
  }[];
};

export async function AddAssetsInContest(
  data: AddAssetsInContestData
): Promise<ServiceResponse<string>> {
  try {
    const api = await getAPIClient();
    const res = await api.post(`admin/game-contest/${data.uuid}/assets`, {
      assets: data.assets,
    });
    return {
      data: res.data,
      error: null,
    };
  } catch (e) {
    return {
      data: null,
      error: getMessageFromError(e),
    };
  }
}
