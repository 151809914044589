import {
  Autocomplete,
  Card,
  CardContent,
  Container,
  IconButton,
  InputAdornment,
  Stack,
  TextField,
} from '@mui/material';
import Flex from 'src/components/shared/Flex';
import { LabelValue } from 'src/lib/types/labelValue';
import HeaderBreadcrumbs from 'src/template/components/HeaderBreadcrumbs';
import Iconify from 'src/template/components/Iconify';
import MakerMostActiveUsersTable from '../../components/MakerMostActiveUsersTable';
import useMakerMostActiveUsers from './useView';

export default function MakerMostActiveUsers() {
  const {
    data,
    isLoading,
    params,
    handleOpenFilters,
    addParam,
    removeParam,
    debouncedSearch,
  } = useMakerMostActiveUsers();

  return (
    <Container maxWidth="xl">
      <HeaderBreadcrumbs
        heading="SGM Most Active Users"
        links={[{ name: 'SGM Most Active Users' }]}
      />

      <Card>
        <Flex sx={{ px: 2, bgcolor: 'background.neutral' }}>
          <IconButton sx={{ ml: 'auto' }} onClick={handleOpenFilters}>
            <Iconify icon="lucide:filter" />
          </IconButton>
        </Flex>
        <CardContent>
          <Stack spacing={2} p={2} justifyContent={'flex-end'}>
            <Flex gap={2} justifyContent={'flex-end'}>
              <Autocomplete
                options={SEARCH_OPTIONS}
                fullWidth
                renderInput={(_params) => (
                  <TextField label="Search By" {..._params} />
                )}
                getOptionLabel={(p) => p.label}
                sx={{ maxWidth: 250 }}
                defaultValue={SEARCH_OPTIONS.find(
                  (o) => o.value === params.searchBy
                )}
                key={params.searchBy}
                onChange={(_, value) => {
                  if (!value) removeParam('searchBy');
                  else addParam('searchBy', value.value);
                }}
                disableClearable
              />
              <TextField
                fullWidth
                label="Search"
                defaultValue={params.query}
                type="search"
                onChange={(e) => {
                  const { value } = e.target;
                  debouncedSearch(value);
                }}
                key={params.query}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <Iconify
                        icon="eva:search-fill"
                        sx={{ color: 'text.disabled', width: 20, height: 20 }}
                      />
                    </InputAdornment>
                  ),
                }}
              />
            </Flex>
          </Stack>
          <MakerMostActiveUsersTable
            data={data?.body || []}
            totalCount={data?.meta?.totalCount || 0}
            isLoading={isLoading}
            params={params}
          />
        </CardContent>
      </Card>
    </Container>
  );
}

const SEARCH_OPTIONS: LabelValue[] = [
  {
    label: 'User Auth ID',
    value: 'userUuid',
  },
];
