import { useCallback, useEffect } from 'react';
import { DEFAULT_INITIAL_PAGE_SIZE } from 'src/lib/constants/pagination';
import useURLSearchParams from 'src/lib/hooks/useURLSearchParams';
import useModalStore from 'src/lib/stores/useModalStore';
import { OBJECT_MODERATION_OBJECT_TYPE } from 'src/lib/types/objectModeration';
import { ReviewEmitter } from '../../events/ReviewEmitter';
import { fetchObjectModerations } from '../../services/fetchObjectModerations';
import ReviewForm from '../ReviewForm';

export default function useObjectModerationTab(
  objectType: OBJECT_MODERATION_OBJECT_TYPE,
  objectUuid: string
) {
  const { openModal } = useModalStore();
  const openReviewModal = (
    uuid: string,
    type: OBJECT_MODERATION_OBJECT_TYPE
  ) => {
    openModal(<ReviewForm objectUuid={uuid} objectType={type} />);
  };

  const { allSearchParams } = useURLSearchParams(initialParams);

  const params = {
    page: +(allSearchParams.page ?? initialParams.page),
    size: +(allSearchParams.size ?? initialParams.size),
    orderBy: allSearchParams.orderBy ?? initialParams.orderBy,
    sortOrder: allSearchParams.sortOrder ?? initialParams.order,
  };

  const { data, isLoading, mutate } = fetchObjectModerations(
    objectUuid,
    objectType,
    {
      page: params.page,
      size: params.size,
      order: {
        [params.orderBy]: params.sortOrder,
      },
    }
  );

  const refetchData = useCallback(() => {
    mutate?.();
  }, []);

  useEffect(() => {
    ReviewEmitter.on('ReviewCreated', refetchData);
    return () => {
      ReviewEmitter.off('ReviewCreated', refetchData);
    };
  }, [refetchData]);

  return {
    data,
    isLoading,
    params,
    openReviewModal,
  };
}

const initialParams = {
  page: '0',
  size: DEFAULT_INITIAL_PAGE_SIZE.toString(),
  order: 'desc',
  orderBy: 'createdAt',
};

export type FetchGamesSessionsQueryParams = {
  page: number;
  size: number;
  orderBy: string;
  sortOrder: string;
};
