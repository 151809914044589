import { Autocomplete, TextField } from '@mui/material';
import { LabelValue } from 'src/lib/types/labelValue';
import { SearchQuery } from 'src/lib/utils/query/types';
import SmartTableTextInput from '../../SmartTable/SmartTableTextInput';

export type EldenTableSearchBarProps = {
  onSearch: (params: Partial<SearchQuery>) => void;
  searchForOptions: LabelValue[];
  defaultSearchFor: string;
  initialValue?: string;
};

const getSearchForLabelId = (searchForOptions: LabelValue[], id: string) => {
  const labelValue = searchForOptions.find((s) => s.value === id)?.label;

  return {
    id,
    label: labelValue,
  };
};

export default function EldenTableSearchBar({
  initialValue,
  onSearch,
  searchForOptions,
  defaultSearchFor,
}: EldenTableSearchBarProps) {
  return (
    <>
      <Autocomplete
        sx={{
          minWidth: '250px',
        }}
        renderInput={(params) => <TextField {...params} label="Search For" />}
        value={getSearchForLabelId(searchForOptions, defaultSearchFor)}
        autoHighlight
        onChange={(_, option) => {
          if (!option) return;
          onSearch({ searchFor: option.id });
        }}
        isOptionEqualToValue={(a, b) => a.id === b.id}
        options={searchForOptions
          .sort((a, b) => a.label.localeCompare(b.label))
          .map((option) => ({
            id: option.value,
            label: option.label,
          }))}
      />
      <SmartTableTextInput
        defaultValue={initialValue}
        setValue={(value) => onSearch({ query: value })}
      />
    </>
  );
}
