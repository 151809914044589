/* eslint-disable import/prefer-default-export */

import { CollectionReview } from '../types/collection';
import { NFT } from '../types/nft';

export const fillUndefinedWithZero = <T>(arr: T[]) =>
  Array.from(arr, (x) => x || 0);

type GetImageUrlOptions = {
  forceStatic?: boolean;
  size?: number;
  width?: number;
  height?: number;
};
/**
 * get image url through proxy
 * @param url image url
 * @param options options for image url
 * @returns image url through proxy
 */
export function getProxyImageUrl(
  url: string,
  options: GetImageUrlOptions
): string {
  const { forceStatic, size, width, height } = options;
  const imageHeight = size ?? height;
  const imageWidth = size ?? width;
  const isStatic = forceStatic ?? true;
  return `https://proxy.getstic.ky/resize?url=${encodeURIComponent(
    url
  )}&forceStatic=${isStatic}&width=${imageWidth}&height=${imageHeight}`;
}

/**
 * Returns nft name that should be displayed
 * @param nft nft to render name
 * @returns nft name
 */
export const getNFTName = (nft: NFT, collectionName?: string): string => {
  const { name, collection, collectionOrder } = nft;
  if (name) return name;
  return `#${collectionOrder + 1} ${
    collectionName ?? collection?.name ?? 'Empty Name'
  }`;
};

/**
 * Get last item from clipboard
 * @returns last item from clipboard
 */
export const getClipboard = async (): Promise<string> => {
  const clipboard = await navigator.clipboard.readText();
  return clipboard;
};

/**
 * Returns label color for collection review status
 * @param reviewStatus review status of collection
 * @returns label color for collection review status
 */
export const getCollectionReviewStatusColor = (
  reviewStatus: CollectionReview['reviewStatus']
) => {
  switch (reviewStatus) {
    case 'approved':
      return 'success';
    case 'rejected':
      return 'error';
    case 'awaiting_review':
      return 'warning';
    default:
      return 'secondary';
  }
};

/**
 * Returns label color for collection human analysis status
 * @param {CollectionReview['humanAnalysis']} humanAnalysisStatus human analysis status of collection
 * @returns label color for collection human analysis status
 */
export const getCollectionHumanAnalysisStatusColor = (
  humanAnalysisStatus: CollectionReview['humanAnalysis']
) => {
  switch (humanAnalysisStatus) {
    case 'everywhere':
      return 'success';
    case 'internal':
      return 'warning';
    case 'public_profile':
      return 'secondary';
    case 'not_set':
      return 'default';
    case 'private_profile':
      return 'secondary';
    default:
      return 'error';
  }
};

/**
 * Returns web app url based on environment, if it's production it returns process.env.REACT_APP_WEB_URL,
 * otherwise it returns process.env.REACT_APP_DEV_WEB_URL
 * @returns web app url
 */
export const getWebAppUrl = (): string => {
  if (process.env.NODE_ENV === 'production') {
    return process.env.REACT_APP_WEB_URL;
  }
  return process.env.REACT_APP_DEV_WEB_URL;
};

interface GetPlayGameUrlOptionsBase {
  closeButton?: boolean;
}

interface GameOption extends GetPlayGameUrlOptionsBase {
  game: string; // ou outro tipo que o game precise ser
  gameUrl?: never;
}

interface GameUrlOption extends GetPlayGameUrlOptionsBase {
  gameUrl: string;
  game?: never;
}

type GetPlayGameUrlOptions = GameOption | GameUrlOption;

export const getPlayGameUrl = ({
  closeButton = false,
  gameUrl,
  game,
}: GetPlayGameUrlOptions) =>
  `${getWebAppUrl()}/play-game?closeButton=${closeButton}&${
    gameUrl ? `gameUrl=${gameUrl}` : `game=${game}`
  }`;
