import { ContentCopy } from '@mui/icons-material';
import {
  Autocomplete,
  Card,
  CardContent,
  InputAdornment,
  MenuItem,
  Stack,
  TextField,
} from '@mui/material';
import { debounce } from 'lodash';
import CopyToClipboard from 'react-copy-to-clipboard';
import Flex from 'src/components/shared/Flex';
import Link from 'src/components/shared/Link';
import MagicTable from 'src/components/shared/MagicTable/MagicTable';
import MagicTableUserCell from 'src/components/shared/MagicTable/UserCell';
import MoreMenu from 'src/components/shared/MoreMenu';
import useURLSearchParams from 'src/lib/hooks/useURLSearchParams';
import { GameSession } from 'src/lib/types/gameSession';
import { formatMilliseconds } from 'src/lib/utils/formatMilliseconds';
import { formatUTCDate } from 'src/lib/utils/formatUTCDate';
import Routes from 'src/routes/Routes';
import Iconify from 'src/template/components/Iconify';
import { FetchGamesSessionsQueryParams } from '../../view/ListSessions/useView';

type GamesTableProps = {
  data?: GameSession[];
  totalCount: number;
  isLoading: boolean;
  params: FetchGamesSessionsQueryParams;
  showGameFilter?: boolean;
  showUserFilter?: boolean;
};

const userSearchOptions = [
  {
    label: 'User Name',
    query: 'user.name',
  },
  {
    label: 'User Slug',
    query: 'user.slug',
  },
  {
    label: 'User Email',
    query: 'user.email',
  },
  {
    label: 'User UUID',
    query: 'user.uuid',
  },
];

const gameSearchOptions = [
  {
    label: 'Game Name',
    query: 'gameVersion.game.name',
  },
  {
    label: 'Game UUID',
    query: 'gameVersion.gameUuid',
  },
];

export default function GamesSessionsTable({
  data,
  totalCount,
  isLoading,
  params: {
    page,
    size,
    startDate,
    endDate,
    userQuery,
    gameQuery,
    userSearchBy,
    gameSearchBy,
  },
  showGameFilter = true,
  showUserFilter = true,
}: GamesTableProps) {
  const { addParam, removeParam } = useURLSearchParams();

  function handleChangeField(value: string, field: string) {
    addParam(field, value);
  }

  const debouncedChangeField = debounce(handleChangeField, 400);

  return (
    <Card>
      <Flex gap={2} p={2} justifyContent={'space-between'} alignItems={'start'}>
        <Stack minWidth={'50%'} gap={2}>
          {showUserFilter && (
            <Flex gap={2}>
              <Autocomplete
                options={userSearchOptions}
                fullWidth
                renderInput={(params) => (
                  <TextField label="Search User By" {...params} fullWidth />
                )}
                getOptionLabel={(p) => p.label}
                sx={{ maxWidth: 250 }}
                defaultValue={userSearchOptions.find(
                  (o) => o.query === userSearchBy
                )}
                key={'userSearchBy'}
                onChange={(_, value) => {
                  if (!value) removeParam('userSearchBy');
                  else addParam('userSearchBy', value.query);
                }}
              />
              <TextField
                fullWidth
                label="Search"
                defaultValue={userQuery}
                type="search"
                onChange={(e) => {
                  const { value } = e.target;
                  debouncedChangeField(value, 'userQuery');
                }}
                key={userQuery}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <Iconify
                        icon="eva:search-fill"
                        sx={{ color: 'text.disabled', width: 20, height: 20 }}
                      />
                    </InputAdornment>
                  ),
                }}
              />
            </Flex>
          )}

          {showGameFilter && (
            <Flex gap={2} justifyContent={'space-between'}>
              <Autocomplete
                options={gameSearchOptions}
                fullWidth
                renderInput={(params) => (
                  <TextField label="Search Game By" {...params} fullWidth />
                )}
                getOptionLabel={(p) => p.label}
                sx={{ maxWidth: 250 }}
                defaultValue={userSearchOptions.find(
                  (o) => o.query === gameSearchBy
                )}
                key={'gameSearchBy'}
                onChange={(_, value) => {
                  if (!value) removeParam('gameSearchBy');
                  else addParam('gameSearchBy', value.query);
                }}
              />
              <TextField
                fullWidth
                label="Search"
                defaultValue={gameQuery}
                type="search"
                onChange={(e) => {
                  const { value } = e.target;
                  debouncedChangeField(value, 'gameQuery');
                }}
                key={gameQuery}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <Iconify
                        icon="eva:search-fill"
                        sx={{ color: 'text.disabled', width: 20, height: 20 }}
                      />
                    </InputAdornment>
                  ),
                }}
              />
            </Flex>
          )}
        </Stack>

        <Flex gap={2}>
          <TextField
            type="date"
            label="Start Date"
            InputLabelProps={{ shrink: true }}
            defaultValue={startDate}
            onChange={(e) => {
              const { value } = e.target;
              if (value) {
                debouncedChangeField(value, 'startDate');
              } else removeParam('startDate');
            }}
          />
          <TextField
            type="date"
            label="End Date"
            InputLabelProps={{ shrink: true }}
            defaultValue={endDate}
            onChange={(e) => {
              const { value } = e.target;
              if (value) {
                debouncedChangeField(value, 'endDate');
              } else removeParam('endDate');
            }}
          />
        </Flex>
      </Flex>
      <CardContent>
        <MagicTable
          rows={data ?? []}
          rowCount={totalCount}
          getRowId={(r) => r.uuid}
          loading={isLoading}
          paginationMode="server"
          pageSize={size}
          page={page}
          onPageChange={(newPage) => {
            addParam('page', newPage);
          }}
          onPageSizeChange={(newSize) => {
            addParam('size', newSize);
          }}
          onSortModelChange={(model) => {
            const currentModel = model[0];
            if (currentModel) {
              addParam('orderBy', currentModel.field);
              if (currentModel.sort) addParam('sortOrder', currentModel.sort);
            } else {
              removeParam('sortOrder');
              removeParam('orderBy');
            }
          }}
          columns={[
            {
              field: 'user.name',
              headerName: 'User',
              renderCell: ({ row }) => (
                <MagicTableUserCell
                  user={row.user}
                  linkUrl={`${Routes.user(row.user.uuid)}?tab=Game%20Sessions`}
                />
              ),
              sortable: true,
            },
            {
              field: 'gameVersion.game.name',
              headerName: 'Game',
              renderCell({ row }) {
                return (
                  <Link
                    to={`${Routes.game_center.game.view(
                      row.gameVersion.gameUuid
                    )}/?tab=analytics`}
                  >
                    {row.gameVersion.game.name}
                  </Link>
                );
              },
            },
            {
              field: 'gameVersion.version',
              headerName: 'Version',
              renderCell({ row }) {
                return row.gameVersion.version;
              },
            },
            {
              field: 'durationMillis',
              headerName: 'Duration',
              valueFormatter({ value }) {
                return formatMilliseconds(value);
              },
            },
            {
              field: 'startDate',
              headerName: 'Started At',
              valueFormatter({ value }) {
                return formatUTCDate(value);
              },
            },
            {
              field: 'endDate',
              headerName: 'Ended At',
              valueFormatter({ value }) {
                return formatUTCDate(value);
              },
            },
            {
              field: 'actions',
              headerName: 'Actions',
              align: 'center',
              sortable: false,
              flex: 0,
              renderCell({ row }) {
                return (
                  <MoreMenu>
                    <CopyToClipboard text={row.uuid}>
                      <MenuItem aria-label="copy feed uuid">
                        <ContentCopy />
                        Copy UUID
                      </MenuItem>
                    </CopyToClipboard>
                  </MoreMenu>
                );
              },
            },
          ]}
        />
      </CardContent>
    </Card>
  );
}
