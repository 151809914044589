import useNotify from 'src/lib/hooks/useNotify';
import useModalStore from 'src/lib/stores/useModalStore';
import { GameCategoryEmitter } from '../../events/Emitter';
import { DeleteGameCategory } from '../../services/delete';

export default function useDeleteGameCategory() {
  const closeModal = useModalStore((state) => state.closeModal);
  const notify = useNotify();

  const handleDeleteGame = async (uuid: string) => {
    const { data, error } = await DeleteGameCategory(uuid);
    if (error) {
      notify(error, { variant: 'error' });
      closeModal();
    } else {
      notify('Game GameCategory deleted!', { variant: 'success' });
      closeModal();
      GameCategoryEmitter.emit('GameCategoryDeleted', data);
    }
  };

  return {
    handleDeleteGame,
  };
}
