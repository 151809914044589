import { sgmFetcher } from 'src/lib/services/sgm/fetcher';
import { SGMPrompt } from 'src/lib/types/sgmPrompt';
import useSWR, { SWRResponse } from 'swr';

export const fetchMakerPromptHistory = (
  type: string
): SWRResponse<SGMPrompt[]> => {
  const response = useSWR<SGMPrompt[], Error>(
    `/api/admin/prompt/${type}/deleted
`,
    sgmFetcher
  );

  return response;
};
