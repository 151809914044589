import useNotify from 'src/lib/hooks/useNotify';
import useModalStore from 'src/lib/stores/useModalStore';
import { EntityNoteFormValues } from '../../components/Form/types';
import { EntityNoteEmitter } from '../../events/EntityNoteEmitter';
import { updateEntityNote } from '../../services/updateEntityNote';

export default function useUpdateEntityNote() {
  const closeModal = useModalStore((state) => state.closeModal);
  const notify = useNotify();

  const handleUpdateEntityNote = async (
    uuid: string,
    values: EntityNoteFormValues
  ) => {
    const { error, data } = await updateEntityNote(uuid, {
      ...values,
    });
    if (error) {
      notify(error, { variant: 'error' });
      closeModal();
    } else if (data) {
      notify('Entity note updated!', { variant: 'success' });
      closeModal();

      EntityNoteEmitter.emit('entityNoteUpdated', data);
    }
  };

  return {
    handleUpdateEntityNote,
  };
}
