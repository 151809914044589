export enum IncludeExcludeAll {
  All = 'all',
  Exclude = 'exclude',
  Include = 'include',
}

export type IncludeExcludeOption = {
  value: IncludeExcludeAll;
  label: string;
};

export const IncludeExcludeOptions: IncludeExcludeOption[] = Object.values(
  IncludeExcludeAll
).map((value) => ({
  value,
  label:
    value === IncludeExcludeAll.Include
      ? 'Only'
      : value.charAt(0).toUpperCase() + value.slice(1),
}));

export const IncludeExcludeDefaultOption = IncludeExcludeOptions[0];
