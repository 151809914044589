import useURLSearchParams from 'src/lib/hooks/useURLSearchParams';
import { SGMMostActiveUsersFilterFormValues } from './types';

export default function useDeliveryFiltersView() {
  const { allSearchParams } = useURLSearchParams();

  const defaultValues: SGMMostActiveUsersFilterFormValues = {
    startMessagesCreatedBetween:
      allSearchParams.startMessagesCreatedBetween ?? undefined,
    endMessagesCreatedBetween:
      allSearchParams.endMessagesCreatedBetween ?? undefined,
    minTotalChats: allSearchParams.minTotalChats
      ? parseInt(allSearchParams.minTotalChats, 10)
      : 0,
    maxTotalChats: allSearchParams.maxTotalChats
      ? parseInt(allSearchParams.maxTotalChats, 10)
      : undefined,
    minTotalMessages: allSearchParams.minTotalMessages
      ? parseInt(allSearchParams.minTotalMessages, 10)
      : 0,
    maxTotalMessages: allSearchParams.maxTotalMessages
      ? parseInt(allSearchParams.maxTotalMessages, 10)
      : undefined,
  };

  return {
    defaultValues,
  };
}
