import { DeepPartial, Path } from 'react-hook-form';
import { Operators } from 'src/lib/types/Operators';
import { User } from 'src/lib/types/users';
import { getMessageFromError } from 'src/lib/utils/error';
import { parseSearchParams } from 'src/lib/utils/parseSearchParams';
import useSWR, { SWRResponse } from 'swr';
import { RevenueEvent } from '../types/RevenueEvent';

type FilterKeys = Path<{ user: User }> | keyof RevenueEvent;

type PossibleParams = {
  filter: Record<FilterKeys, string | number | Operators>;
  size: number | string;
  page: number | string;
  order: Record<string, string>;
};

export function fetchInconsistentRevenueEvents(
  params?: DeepPartial<PossibleParams>
): SWRResponse<
  (RevenueEvent & {
    userName: string;
    userSlug: string;
    userEmail: string;
    userActive: boolean;
    userProfileImage: string;
    productDisplayName: string;
    inconsistencyType: 'notExists' | 'failed';
    productName: string;
    productId: string;
  })[]
> {
  const parsedParams = parseSearchParams(params ?? {});
  const { error, ...response } = useSWR(
    `admin/revenue-events/inconsistencies?${parsedParams}`
  );

  return {
    ...response,
    error: error ? getMessageFromError(error) : undefined,
  };
}
