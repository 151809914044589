import { Avatar, Checkbox } from '@mui/material';
import { GridSortDirection } from '@mui/x-data-grid';
import Link from 'src/components/shared/Link';
import MagicTable from 'src/components/shared/MagicTable/MagicTable';
import { NFTForPack } from 'src/lib/types/nft';
import { getImageURL } from 'src/lib/utils/nft';
import Routes from 'src/routes/Routes';
import { NftToAdd } from '../../../NFTPacks/components/Forms/AddNft/types';

interface AddNftsTableProps {
  data: NFTForPack[];
  isLoading: boolean;
  page: number;
  size: number;
  sortBy: string;
  order: GridSortDirection;
  setPage: (page: number) => void;
  setRowsPerPage: (size: number) => void;
  setOrder: (order: GridSortDirection) => void;
  setSortBy: (sortBy: string) => void;

  selectedNfts: NftToAdd[];
  handleAddNft: (nft: NFTForPack) => void;
  handleRemoveNft: (uuid: string) => void;
}

export default function AddNftsTable({
  data,
  isLoading,
  page,
  size,
  sortBy,
  order,
  setPage,
  setRowsPerPage,
  setOrder,
  setSortBy,

  selectedNfts,
  handleAddNft,
  handleRemoveNft,
}: AddNftsTableProps): JSX.Element {
  return (
    <MagicTable
      rows={data.map((nft) => nft)}
      hideNumberOfRows
      getRowId={(r) => r.uuid}
      loading={isLoading}
      paginationMode="server"
      pageSize={size}
      page={page}
      onPageChange={(newPage) => {
        setPage(newPage);
      }}
      onPageSizeChange={(newSize) => {
        setRowsPerPage(newSize);
      }}
      sortModel={[{ field: sortBy, sort: order }]}
      onSortModelChange={(model) => {
        const currentModel = model[0];
        if (currentModel) {
          setSortBy(currentModel.field);
          if (currentModel.sort) setOrder(currentModel.sort);
        } else {
          setOrder('asc');
          setSortBy('');
        }
      }}
      columns={[
        {
          field: 'checkbox',
          headerName: '',
          sortable: false,
          flex: 0,
          renderCell: ({ row }) => (
            <Checkbox
              checked={selectedNfts.some((nft) => nft.uuid === row.uuid)}
              onChange={() => {
                if (selectedNfts.some((nft) => nft.uuid === row.uuid)) {
                  handleRemoveNft(row.uuid);
                } else {
                  handleAddNft(row);
                }
              }}
            />
          ),
        },
        {
          field: 'name',
          headerName: 'Name',
          sortable: false,
          renderCell: ({ row }) => (
            <>
              <Avatar
                alt={row.name}
                src={getImageURL(row.fileUrl)}
                variant="rounded"
                sx={{ mr: 2 }}
              />
              <Link
                to={Routes.nft(row.uuid)}
                color={row.name ? 'inherit' : 'text.secondary'}
                underline="hover"
                display="inline-block"
              >
                {row.name ?? 'empty'}{' '}
              </Link>
            </>
          ),
        },
        {
          field: 'collectionName',
          headerName: 'Collection',
          sortable: false,
          renderCell: ({ row }) => (
            <Link to={Routes.collection(row.collectionUuid)}>
              {row.collectionName}
            </Link>
          ),
        },
      ]}
    />
  );
}
