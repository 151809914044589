import { DeepPartial } from 'react-hook-form';
import { PaginatedResponse } from 'src/lib/types/PaginatedResponse';
import { SubmissionFormStatuses } from 'src/lib/types/formReviewStatus';
import { Submission } from 'src/lib/types/submission';
import { parseSearchParams } from 'src/lib/utils/parseSearchParams';
import useSWR, { SWRResponse } from 'swr';

export const fetchSubmissions = (
  filters?: DeepPartial<PossibleParams>,
  shouldFetch = true
): SWRResponse<PaginatedResponse<Submission[]>> => {
  const response = useSWR(
    shouldFetch
      ? `admin/submission-form?${parseSearchParams(filters ?? {})}`
      : null
  );

  return response;
};

type PossibleParams = {
  page: number | string;
  size: number | string;
  filter: {
    status: string;
    name: {
      like: string;
    };
    latestStatus: SubmissionFormStatuses;
  };
  order: Record<string, string>;
};
