import { ContentCopy, Delete } from '@mui/icons-material';
import { MenuItem } from '@mui/material';
import CopyToClipboard from 'react-copy-to-clipboard';
import MagicTable from 'src/components/shared/MagicTable/MagicTable';
import MoreMenu from 'src/components/shared/MoreMenu';
import useURLSearchParams from 'src/lib/hooks/useURLSearchParams';
import { ExternalClubAsset } from 'src/lib/types/club';
import { QueryParams } from 'src/lib/utils/query/types';
import { ExternalAssetsCreationData } from '../../services/createClub';

interface ExternalAssetsTableProps {
  data: ExternalClubAsset[];
  isLoading: boolean;

  params: QueryParams;

  handleRemoveAsset?: (
    clubUuid: string,
    assetToRemove: ExternalAssetsCreationData
  ) => void;
}

export default function ExternalAssetsTable({
  data,
  isLoading,
  params,
  handleRemoveAsset,
}: ExternalAssetsTableProps): JSX.Element {
  const { addParam, removeParam } = useURLSearchParams();

  return (
    <MagicTable
      rows={data}
      getRowId={(r) => r.uuid}
      loading={isLoading}
      paginationMode="client"
      pageSize={params.size as number}
      page={params.page as number}
      onPageChange={(newPage) => {
        addParam('page', newPage);
      }}
      onPageSizeChange={(newSize) => {
        addParam('size', newSize);
      }}
      onSortModelChange={(model) => {
        const currentModel = model[0];
        if (currentModel) {
          addParam('sortBy', currentModel.field);
          if (currentModel.sort) addParam('order', currentModel.sort);
        } else {
          removeParam('order');
          removeParam('sortBy');
        }
      }}
      columns={[
        {
          field: 'assetId',
          headerName: 'Asset ID',
          sortable: true,
        },
        {
          field: 'assetType',
          headerName: 'Type',
          sortable: true,
          valueFormatter({ value }) {
            return value.name;
          },
        },
        {
          field: 'actions',
          headerName: 'Actions',
          align: 'center',
          sortable: false,
          flex: 0,
          renderCell({ row }) {
            return (
              <MoreMenu>
                <CopyToClipboard text={row.uuid}>
                  <MenuItem aria-label="copy feed uuid">
                    <ContentCopy />
                    Copy UUID
                  </MenuItem>
                </CopyToClipboard>
                {handleRemoveAsset && (
                  <MenuItem
                    onClick={() => {
                      handleRemoveAsset(row.clubUuid, {
                        assetId: row.assetId,
                        assetType: row.assetType,
                      });
                    }}
                  >
                    <Delete />
                    Remove
                  </MenuItem>
                )}
              </MoreMenu>
            );
          },
        },
      ]}
    />
  );
}
