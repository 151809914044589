import { PriceChange } from '@mui/icons-material';
import {
  Card,
  CardContent,
  CardHeader,
  Divider,
  InputAdornment,
  Stack,
  TextField,
  Typography,
} from '@mui/material';
import { useState } from 'react';
import Button from 'src/components/shared/Buttons/CustomButton';
import useNotify from 'src/lib/hooks/useNotify';
import { updatePaymentRequestValues } from 'src/lib/services/paymentRequests';
import { PaymentRequest } from 'src/lib/types/paymentRequest';
import { getMessageFromError } from 'src/lib/utils/error';

interface PaymentAmountProps {
  paymentRequest: PaymentRequest;
}
export default function PaymentAmount({
  paymentRequest,
}: PaymentAmountProps): JSX.Element {
  const notify = useNotify();
  const [pricePerCoin, setPricePerCoin] = useState<number>(
    paymentRequest.value / paymentRequest.amount
  );

  const [stickyShare, setStickyShare] = useState(0);
  const [isLoading, setIsLoading] = useState(false);
  const payoutValue =
    Math.round(paymentRequest.amount * pricePerCoin * (100 - stickyShare)) /
    100;

  const handleUpdatePaymentRequest = async () => {
    setIsLoading(true);
    try {
      await updatePaymentRequestValues(paymentRequest.uuid, {
        value: payoutValue,
        stickyValue:
          Math.round(paymentRequest.amount * pricePerCoin) - payoutValue,
      });
      notify('Payment Request Updated', { variant: 'success' });
    } catch (e) {
      notify(getMessageFromError(e), { variant: 'error' });
    }
    setIsLoading(false);
  };

  return (
    <Card
      sx={{
        width: '100%',
      }}
    >
      <CardHeader title=" Custom Payment Amount" />
      <CardContent>
        <Stack spacing={2}>
          <TextField
            label="Price per Coin"
            variant="outlined"
            value={pricePerCoin}
            onChange={(e) => {
              setPricePerCoin(Number(e.target.value));
            }}
            type="number"
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <PriceChange />
                </InputAdornment>
              ),
            }}
          />
          {/*
            <TextField
              label="Sticky Share Percentage"
              placeholder="50"
              variant="outlined"
              value={stickyShare}
              type="number"
              onChange={(e) => {
                const stickyValue = Number(e.target.value);
                if (stickyValue <= 100) {
                  setStickyShare(stickyValue);
                }
              }}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <Percent />
                  </InputAdornment>
                ),
              }}
            />
            */}
          <Stack spacing={1} fontSize={14}>
            <Stack
              direction="row"
              fontSize="inherit"
              justifyContent="space-between"
            >
              <Typography fontSize="inherit" color="text.secondary">
                Approximate Coins Value:
              </Typography>
              <Typography fontSize="inherit" fontWeight="bold">
                {pricePerCoin
                  ? `$${Math.round(paymentRequest.amount * pricePerCoin)}`
                  : 'Not A Valid Number'}
              </Typography>
            </Stack>
            <Stack
              direction="row"
              fontSize="inherit"
              justifyContent="space-between"
            >
              {/*
              <Typography fontSize="inherit" color="text.secondary">
                Sticky Share:
              </Typography>
              <Typography fontSize="inherit" fontWeight="bold">
                {pricePerCoin && stickyShare
                  ? `- $${
                      Math.round(paymentRequest.amount * pricePerCoin) -
                      payoutValue
                    }`
                  : 'Not A Valid Number'}
              </Typography>
                */}
            </Stack>
            <Divider />
            <Stack direction="row" fontSize={16} justifyContent="space-between">
              <Typography fontSize="inherit" color="text.secondary">
                Payout Value:
              </Typography>
              <Typography fontSize="inherit" fontWeight="bold">
                {pricePerCoin // && stickyShare
                  ? `$${payoutValue}`
                  : 'Not A Valid Number'}
              </Typography>
            </Stack>
          </Stack>
          <Button
            variant="contained"
            color="primary"
            size="large"
            loading={isLoading}
            onClick={handleUpdatePaymentRequest}
            disabled={
              paymentRequest.status === 'executed' || !pricePerCoin // || !stickyShare
            }
          >
            Update Value
          </Button>
        </Stack>
      </CardContent>
    </Card>
  );
}
