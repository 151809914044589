import useNotify from 'src/lib/hooks/useNotify';
import useModalStore from 'src/lib/stores/useModalStore';
import { PayoutCountryEmitter } from '../../events/PayoutCountryEmitter';
import { updatePayoutCountry } from '../../services/updatePayoutCountry';
import { PayoutCountry } from '../../types/PayoutCountries';
import UpdateConfiguration from '../../views/Update';

export default function useSanctionedCountriesTableView() {
  const [openModal, closeModal] = useModalStore((state) => [
    state.openModal,
    state.closeModal,
  ]);
  const notify = useNotify();

  const handleUpdateCountry = (configuration: PayoutCountry) => {
    openModal(<UpdateConfiguration payoutCountry={configuration} />);
  };

  const handleToggleCountryStatus = async (
    uuid: string,
    currentStatus: PayoutCountry['active']
  ) => {
    const { data, error } = await updatePayoutCountry(uuid, {
      active: !currentStatus,
    });
    if (error) {
      notify(error, { variant: 'error' });
      closeModal();
    } else if (data) {
      notify('Country updated!', { variant: 'success' });
      closeModal();
      PayoutCountryEmitter.emit('payoutCountryUpdated', data);
    }
  };

  return {
    handleUpdateCountry,
    handleToggleCountryStatus,
  };
}
