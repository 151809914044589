import HeaderBreadcrumbs from 'src/template/components/HeaderBreadcrumbs';
import FeedItemAnalyticsTable from '../../components/FeedItemAnalyticsTable/FeedItemAnalyticsTable';
import { useFeedItemAnalyticsTable } from '../../components/FeedItemAnalyticsTable/useFeedItemAnalyticsTable';

export default function FeedAnalytics() {
  const { data, isLoading, params } = useFeedItemAnalyticsTable();

  return (
    <>
      <HeaderBreadcrumbs
        heading="Feed Analytics"
        links={[{ name: 'Feed Analytics' }]}
      />

      <FeedItemAnalyticsTable
        data={data?.body || []}
        totalCount={data?.meta.totalCount || 0}
        isLoading={isLoading}
        params={params}
      />
    </>
  );
}
