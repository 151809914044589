import { yupResolver } from '@hookform/resolvers/yup';
import { Close } from '@mui/icons-material';
import {
  Alert,
  Card,
  CardContent,
  CardHeader,
  MenuItem,
  Stack,
  Typography,
} from '@mui/material';
import { capitalCase } from 'change-case';
import { useState } from 'react';
import { useFieldArray, useForm } from 'react-hook-form';
import Button from 'src/components/shared/Buttons/CustomButton';
import Flex from 'src/components/shared/Flex';
import ControllerField from 'src/components/shared/Inputs/ControllerField';
import Modal from 'src/components/shared/Modal';
import useModalStore from 'src/lib/stores/useModalStore';
import { ParsedGameStatistic } from 'src/lib/types/gameStatistic';
import { StatisticGroupTypes } from 'src/lib/types/statisticGroup';
import * as yup from 'yup';
import { fetchActionAndAssetTypesByGameUuid } from '../../../services/fetchActionAndAssetTypesByGameUuid';
import SelectStatisticsForGroup from '../../../view/SelectStatistics';
import { StatisticGroupFormValues } from './types';

const validationSchema = yup.object().shape({
  name: yup.string().required('Name is required'),
  type: yup.string().required('Type is required'),

  typeUuid: yup.string().when('type', {
    is: (val: StatisticGroupTypes) => val === StatisticGroupTypes.ASSET_GROUP,
    then: yup.string().required('Type is required'),
  }),

  actionUuid: yup.string().when('type', {
    is: (val: StatisticGroupTypes) => val === StatisticGroupTypes.ASSET_GROUP,
    then: yup.string().required('Action is required'),
  }),

  statisticsList: yup.array().when('type', {
    is: (val: StatisticGroupTypes) =>
      val === StatisticGroupTypes.STATISTIC_LIST,
    then: yup
      .array()
      .of(
        yup.object().shape({
          uuid: yup.string().required('Statistic is required'),
        })
      )
      .required('Statistics List is required')
      .min(1),
  }),
});

type Props = {
  gameUuid: string;
  defaultValues: StatisticGroupFormValues;
  onSubmit: (values: StatisticGroupFormValues) => Promise<void>;
  title: string;
};

export default function StatisticGroupForm({
  gameUuid,
  defaultValues,
  title,
  onSubmit,
}: Props): JSX.Element {
  const onClose = useModalStore((state) => state.closeModal);
  const {
    control,
    handleSubmit,
    formState: { isSubmitting, errors },
    setValue,
    watch,
    getFieldState,
    trigger,
    getValues,
  } = useForm<StatisticGroupFormValues>({
    defaultValues: {
      ...defaultValues,
    },
    resolver: yupResolver(validationSchema),
  });

  const handleCloseModal = () => {
    onClose();
  };

  // watch

  const selectedType = watch('type');

  // step

  const [currentStep, setCurrentStep] = useState(1); // 1: table, 2: position awards

  const handleNextStep = async () => {
    let isValid = false;
    if (currentStep === 1) {
      isValid = await trigger(['name', 'type']);
    }
    if (currentStep === 2) {
      if (selectedType === StatisticGroupTypes.ASSET_GROUP) {
        isValid = await trigger(['typeUuid', 'actionUuid']);
      } else {
        isValid = await trigger(['statisticsList']);
      }
    }

    if (isValid) {
      setCurrentStep(currentStep + 1);
    }
  };

  const handlePreviousStep = () => {
    setCurrentStep(currentStep - 1);
  };

  const { data: actionAndAssetTypesData } =
    fetchActionAndAssetTypesByGameUuid(gameUuid);

  // statistics

  const {
    fields: selectedStatistics,
    remove: removeStatistic,
    append: appendStatistic,
  } = useFieldArray({
    control,
    name: 'statisticsList',
  });

  const handleAddStatistic = (statistic: ParsedGameStatistic) => {
    appendStatistic({
      uuid: statistic.uuid!,
      assetAction: statistic.assetAction,
      assetType: statistic.assetType,
      assetName: statistic.assetName,
    });
  };

  const handleRemoveStatistic = (uuid: string) => {
    const index = selectedStatistics.findIndex(
      (statistic) => statistic.uuid === uuid
    );
    if (index > -1) removeStatistic(index);
  };

  const handleRemoveStatisticByIndex = (index: number) => {
    if (selectedStatistics.length > 1) removeStatistic(index);
  };

  return (
    <Modal open onClose={handleCloseModal}>
      <Card
        sx={{
          ...(currentStep == 2 &&
          selectedType == StatisticGroupTypes.STATISTIC_LIST
            ? {
                width: '80vw',
                minHeight: '50vh',
              }
            : { width: '600px' }),
        }}
      >
        <CardHeader title={title} />
        <CardContent>
          <form onSubmit={handleSubmit(onSubmit)}>
            {currentStep == 1 && (
              <Stack>
                <Stack mb={2} spacing={1.5}>
                  <ControllerField
                    control={control}
                    name="name"
                    label="Name"
                    fullWidth
                  />

                  <ControllerField
                    control={control}
                    name="type"
                    label="Group Type"
                    fullWidth
                    select
                  >
                    {Object.values(StatisticGroupTypes).map((groupType) => (
                      <MenuItem key={groupType} value={groupType}>
                        {capitalCase(groupType)}
                      </MenuItem>
                    ))}
                  </ControllerField>
                </Stack>
              </Stack>
            )}
            {currentStep == 2 &&
              selectedType == StatisticGroupTypes.ASSET_GROUP && (
                <Stack>
                  <Stack mb={2} spacing={1.5}>
                    <ControllerField
                      control={control}
                      name="typeUuid"
                      label="Asset Type"
                      fullWidth
                      select
                    >
                      {Object.values(
                        actionAndAssetTypesData?.assetTypes ?? []
                      ).map((assetType) => (
                        <MenuItem key={assetType.uuid} value={assetType.uuid}>
                          {capitalCase(assetType.name)}
                        </MenuItem>
                      ))}
                    </ControllerField>

                    <ControllerField
                      control={control}
                      name="actionUuid"
                      label="Statistic Action"
                      fullWidth
                      select
                    >
                      {Object.values(
                        actionAndAssetTypesData?.actions ?? []
                      ).map((action) => (
                        <MenuItem key={action.uuid} value={action.uuid}>
                          {capitalCase(action.name)}
                        </MenuItem>
                      ))}
                    </ControllerField>
                  </Stack>
                </Stack>
              )}

            {currentStep == 2 &&
              selectedType == StatisticGroupTypes.STATISTIC_LIST && (
                <SelectStatisticsForGroup
                  handleAddStatistic={handleAddStatistic}
                  handleRemoveStatistic={handleRemoveStatistic}
                  selectedStatistics={selectedStatistics}
                  gameUuid={gameUuid}
                />
              )}
            {currentStep == 3 &&
              selectedType == StatisticGroupTypes.STATISTIC_LIST && (
                <Stack mb={3} spacing={1.5}>
                  {selectedStatistics.map((statistic, statisticIndex) => (
                    <Flex
                      key={statistic.id}
                      gap={6}
                      alignItems={'flex-start'}
                      justifyContent={'space-between'}
                    >
                      <Stack direction={'row'}>
                        <Stack>
                          <Typography variant="body1" noWrap>
                            {statistic.assetName} - {statistic.assetAction}
                          </Typography>
                          <Typography variant="subtitle2" color="grey" noWrap>
                            ({statistic.assetType})
                          </Typography>
                        </Stack>
                      </Stack>

                      <Stack direction={'row'}>
                        <Button
                          onClick={() =>
                            handleRemoveStatisticByIndex(statisticIndex)
                          }
                          size="large"
                        >
                          <Close />
                        </Button>
                      </Stack>
                    </Flex>
                  ))}

                  {getFieldState(`statisticsList`).error?.message && (
                    <Alert severity="error">
                      {getFieldState(`statisticsList`).error?.message}
                    </Alert>
                  )}
                </Stack>
              )}
            <Stack
              direction="row"
              justifyContent="space-between"
              spacing={1}
              mt={2}
            >
              {currentStep === 1 ? (
                <Button color="error" onClick={handleCloseModal} size="large">
                  Cancel
                </Button>
              ) : (
                <Button
                  variant="contained"
                  color="error"
                  type="button"
                  onClick={handlePreviousStep}
                  size="large"
                >
                  Previous
                </Button>
              )}
              {(currentStep === 2 &&
                selectedType === StatisticGroupTypes.ASSET_GROUP) ||
              (currentStep === 3 &&
                selectedType === StatisticGroupTypes.STATISTIC_LIST) ? (
                <Button
                  variant="contained"
                  color="secondary"
                  type="submit"
                  loading={isSubmitting}
                  size="large"
                >
                  Save
                </Button>
              ) : (
                <Button
                  variant="contained"
                  color="secondary"
                  type="button"
                  onClick={(e) => {
                    e.preventDefault();

                    handleNextStep();
                  }}
                  size="large"
                >
                  Next
                </Button>
              )}
            </Stack>
          </form>
        </CardContent>
      </Card>
    </Modal>
  );
}
