import MakerDialogDetails from 'src/components/modules/MakerDialog/view/Details';
import Page from 'src/template/components/Page';

export default function MakerDialogPage() {
  return (
    <Page title="SGM Chat">
      <MakerDialogDetails />
    </Page>
  );
}
