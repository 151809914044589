import { WithTimestamp } from 'src/lib/types/WithTimestamp';

export type CoinExpiration = WithTimestamp<{
  uuid: string;
  expiresIn: number;
  walletUuid: string;
  createdByPoolUuid: string | null;
  expiredByPoolUuid: string;
  type: string;
  origin: string;
  totalAmount: number;
  available: number;
  locked: number;
  expired: number;
  failedExpirationAttempts: number;
  payout: number;
  refunded: number;
  reason: string;
  expiresAt: Date;
}>;

export enum ExpiringCoinsDateFilterRanges {
  OneDay = '1d',
  OneWeek = '1w',
  OneMonth = '1m',
  OneYear = '1y',
  MoreThanOneYear = '1y+',
}

export enum ExpiringCoinsTabs {
  LatestExpired = 'latest-expired',
  NextExpiring = 'next-expiring',
  Failed = 'failed',
  Blocked = 'blocked',
}

export enum CoinTypes {
  bonification = 'bonification',
  earning = 'earning',
  buy = 'buy',
}
