import { Card, Stack } from '@mui/material';
import { debounce } from 'lodash';
import Flex from 'src/components/shared/Flex';
import MagicTable from 'src/components/shared/MagicTable/MagicTable';
import useURLSearchParams from 'src/lib/hooks/useURLSearchParams';
import { GameCenterReviewListRow } from 'src/lib/types/gameReview';
import { tableColumns } from './columns';
import { GameRatingsTableDateFilters } from './filters/DateFilters';
import { GameRatingsTableGameFilters } from './filters/GameFilters';
import { GameRatingsTableUserFilters } from './filters/UserFilters';
import { FetchGameRatingsQueryParams } from './useGameRatingsTable';

type GameRatingsTableProps = {
  data: GameCenterReviewListRow[];
  isLoading: boolean;
  totalCount: number;
  params: FetchGameRatingsQueryParams;
  showGameFilter?: boolean;
  showUserFilter?: boolean;
};

export default function GameRatingsTable({
  data,
  isLoading,
  totalCount,
  params,
  showGameFilter = true,
  showUserFilter = true,
}: GameRatingsTableProps) {
  const { addParam, removeParam } = useURLSearchParams();
  function handleChangeField(value: string, field: string) {
    addParam(field, value);
  }

  const debouncedChangeField = debounce(handleChangeField, 650);

  return (
    <Card>
      <Flex gap={2} p={2} justifyContent={'space-between'} alignItems={'start'}>
        <Stack minWidth={'50%'} gap={2}>
          {/* User Filter */}
          {showUserFilter && (
            <Flex gap={2}>
              <GameRatingsTableUserFilters
                addParam={addParam}
                removeParam={removeParam}
                debouncedChangeField={debouncedChangeField}
                propParams={params}
              />
            </Flex>
          )}

          {/* Game Filter */}
          {showGameFilter && (
            <Flex gap={2} justifyContent={'space-between'}>
              <GameRatingsTableGameFilters
                addParam={addParam}
                removeParam={removeParam}
                debouncedChangeField={debouncedChangeField}
                propParams={params}
              />
            </Flex>
          )}
        </Stack>
        {/* Date Filter */}
        <Flex gap={2}>
          <GameRatingsTableDateFilters
            addParam={addParam}
            removeParam={removeParam}
            debouncedChangeField={debouncedChangeField}
            propParams={params}
          />
        </Flex>
      </Flex>

      <MagicTable.Container>
        <MagicTable
          loading={isLoading}
          pageSize={params.size}
          page={params.page}
          rowCount={totalCount}
          paginationMode="server"
          onPageChange={(newPage) => {
            addParam('page', newPage);
          }}
          onPageSizeChange={(newSize) => {
            addParam('size', newSize);
          }}
          onSortModelChange={(model) => {
            const currentModel = model[0];
            if (currentModel) {
              addParam('orderBy', currentModel.field);
              if (currentModel.sort) addParam('order', currentModel.sort);
            } else {
              removeParam('order');
              removeParam('orderBy');
            }
          }}
          rows={data}
          columns={tableColumns}
        />
      </MagicTable.Container>
    </Card>
  );
}
