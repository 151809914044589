import { DeepPartial } from 'react-hook-form';
import { UserFlag } from 'src/lib/types/UserFlag';
import { parseSearchParams } from 'src/lib/utils/parseSearchParams';
import useSWR, { SWRResponse } from 'swr';

type PossibleParams = {
  page: number | string;
  size: number | string;
  order: Record<string, string>;
  filter: {
    active: string | boolean;
    'user.uuid': string;
    'user.name': string;
    'user.email': string;
    'user.authId': string;
  };
};
export const fetchUserFlags = (
  filter?: DeepPartial<PossibleParams>
): SWRResponse<UserFlag[]> => {
  const parsedParams = parseSearchParams(filter ?? {});
  const response = useSWR<UserFlag[], Error>(
    `admin/user-flag/?${parsedParams}`
  );

  return response;
};
