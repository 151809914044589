import { GameMakerAssetCollection } from 'src/lib/types/gameMakerAsset';
import useSWR, { SWRResponse } from 'swr';

export const fetchAssetCollection = (
  uuid: string
): SWRResponse<GameMakerAssetCollection> => {
  const response = useSWR<GameMakerAssetCollection, Error>(
    `admin/game-maker-asset-collection/${uuid}`
  );

  return response;
};
