import { FormControl, InputLabel } from '@mui/material';
import Select, { SelectChangeEvent } from '@mui/material/Select';

interface CustomSelectProps {
  labelId?: string;
  id?: string;
  label: string;
  placeholder?: string;
  value?: string;
  onChange?: (event: SelectChangeEvent) => void;
  disabled?: boolean;
  children: React.ReactNode;
}

function CustomSelect({
  labelId,
  id,
  label,
  value,
  onChange,
  disabled,
  children,
}: CustomSelectProps) {
  return (
    <FormControl fullWidth>
      <InputLabel id={labelId}>{label}</InputLabel>
      <Select
        labelId={labelId}
        id={id}
        label={label}
        value={value || ''}
        onChange={onChange}
        disabled={disabled}
        sx={{ bgcolor: '#fff', width: '100%' }}
      >
        {children}
      </Select>
    </FormControl>
  );
}
export default CustomSelect;
