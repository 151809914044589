import { Add, Delete, Edit } from '@mui/icons-material';
import {
  Autocomplete,
  Avatar,
  Box,
  InputAdornment,
  MenuItem,
  Stack,
  TextField,
} from '@mui/material';
import { GridSortDirection } from '@mui/x-data-grid';
import { capitalCase } from 'change-case';
import { debounce } from 'lodash';
import Button from 'src/components/shared/Buttons/CustomButton';
import Flex from 'src/components/shared/Flex';
import Link from 'src/components/shared/Link';
import MagicTable from 'src/components/shared/MagicTable/MagicTable';
import MoreMenu from 'src/components/shared/MoreMenu';
import useURLSearchParams from 'src/lib/hooks/useURLSearchParams';
import { EntityNote, EntityNoteTypes } from 'src/lib/types/entityNote';
import { LabelValue } from 'src/lib/types/labelValue';
import { User } from 'src/lib/types/users';
import { formatUTCDate } from 'src/lib/utils/formatUTCDate';
import Routes from 'src/routes/Routes';
import Iconify from 'src/template/components/Iconify';
import { EntityNotesFetchResponse } from '../../services/fetchEntityNotes';
import { FetchEntitynotesQueryParams } from './useEntityNotesTable';

type EntityNotesTableProps = {
  data?: EntityNotesFetchResponse[];
  isLoading: boolean;
  paras: FetchEntitynotesQueryParams;
  showSearch?: boolean;
  handleUpdate: (entityNote: EntityNote) => void;
  handleDelete: (uuid: string) => void;
  handleCreate?: () => void;
};

export default function EntityNotesTable({
  data,
  isLoading,
  showSearch = true,
  paras: { page, size, searchBy, orderBy, sortOrder },
  handleUpdate,
  handleDelete,
  handleCreate,
}: EntityNotesTableProps) {
  const { addParam, removeParam, allSearchParams } = useURLSearchParams();

  function handleChangeSearch(value: string, param: string = 'query') {
    if (!value) removeParam(param);
    else addParam(param, value);
  }

  const debouncedSearch = debounce(handleChangeSearch, 1000);

  return (
    <MagicTable.Container>
      <Flex gap={2} p={2} justifyContent={'flex-end'}>
        {showSearch && (
          <>
            <Autocomplete
              options={SEARCH_OPTIONS}
              fullWidth
              renderInput={(params) => (
                <TextField label="Search By" {...params} />
              )}
              getOptionLabel={(p) => p.label}
              sx={{ maxWidth: 250 }}
              defaultValue={SEARCH_OPTIONS.find((o) => o.value === searchBy)}
              key={searchBy}
              onChange={(_, value) => {
                if (!value) removeParam('searchBy');
                else addParam('searchBy', value.value);
              }}
            />
            <TextField
              fullWidth
              label="Search"
              defaultValue={allSearchParams.query}
              type="search"
              onChange={(e) => {
                const { value } = e.target;
                debouncedSearch(value);
              }}
              key={allSearchParams.query}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <Iconify
                      icon="eva:search-fill"
                      sx={{ color: 'text.disabled', width: 20, height: 20 }}
                    />
                  </InputAdornment>
                ),
              }}
            />
          </>
        )}
        {handleCreate && (
          <Button
            variant="contained"
            color="primary"
            onClick={() => handleCreate()}
            sx={{ gap: 1, justifySelf: 'flex-end' }}
            size="large"
          >
            <Add />
            New
          </Button>
        )}
      </Flex>
      <Box px={2}>
        <MagicTable
          loading={isLoading}
          pageSize={size}
          page={page}
          paginationMode="server"
          onPageChange={(newPage) => {
            addParam('page', newPage);
          }}
          onPageSizeChange={(newSize) => {
            addParam('size', newSize);
          }}
          onSortModelChange={(model) => {
            if (!showSearch) return;
            const sortModel = model[0];
            addParam('orderBy', sortModel.field);
            addParam('order', sortModel.sort!);
          }}
          sortModel={[
            {
              field: orderBy,
              sort: sortOrder as GridSortDirection,
            },
          ]}
          rows={data ?? []}
          hideNumberOfRows
          columns={[
            {
              field: 'createdByUser.name',
              headerName: 'Created By',
              sortable: false,
              renderCell({ row }) {
                return (
                  <>
                    <Avatar
                      alt={row?.createdByUser?.name ?? 'user profile picture'}
                      src={row?.createdByUser?.profileImage ?? ''}
                      sx={{ mr: 2 }}
                    />

                    <Link
                      to={Routes.user(row?.createdByUser?.uuid)}
                      color={row?.createdByUser?.name ? 'inherit' : 'GrayText'}
                      underline="hover"
                    >
                      {row?.createdByUser?.name ||
                        row?.createdByUser?.slug ||
                        row?.createdByUser?.email ||
                        'empty'}
                    </Link>
                  </>
                );
              },
            },
            {
              field: 'users',
              headerName: 'Related Users',
              sortable: false,
              renderCell({ row }) {
                return (
                  // eslint-disable-next-line react/jsx-no-useless-fragment
                  <Flex gap={1}>
                    {row?.users?.map((user: User) => (
                      <Stack direction={'row'} alignItems={'center'}>
                        <Avatar
                          alt={user.name ?? 'user profile picture'}
                          src={user.profileImage ?? ''}
                          sx={{ mr: 0.5 }}
                        />

                        <Link
                          key={user.uuid}
                          to={Routes.user(user.uuid)}
                          color={user.name ? 'inherit' : 'GrayText'}
                          underline="hover"
                        >
                          {user.name || user.slug || user.email || 'empty'}
                        </Link>
                      </Stack>
                    ))}
                  </Flex>
                );
              },
            },
            {
              field: 'entityType',
              headerName: 'Entity Type',
              align: 'center',
              renderCell({ row }) {
                return (
                  <Link
                    to={parseEntityTypeLink({
                      entityType: row.entityType,
                      entityUuid: row.entityUuid,
                    })}
                  >
                    {capitalCase(row.entityType)}
                  </Link>
                );
              },
            },
            {
              field: 'note',
              headerName: 'Note',
              sortable: false,
            },
            {
              field: 'tag',
              headerName: 'Tag',
              sortable: false,
              valueGetter: ({ value }) => capitalCase(value?.name),
            },
            {
              field: 'createdAt',
              headerName: 'Created At',
              sortingOrder: ['desc', 'asc'],
              flex: 1 / 2,
              sortComparator: () => 0,
              valueFormatter({ value }) {
                return value ? formatUTCDate(value) : '-';
              },
            },
            {
              field: 'actions',
              headerName: 'Actions',
              align: 'center',
              sortable: false,
              flex: 0,
              renderCell({ row }) {
                return (
                  <MoreMenu>
                    <MenuItem
                      onClick={() => {
                        handleUpdate(row);
                      }}
                    >
                      <Edit />
                      Update
                    </MenuItem>
                    <MenuItem
                      onClick={() => {
                        handleDelete(row.uuid);
                      }}
                    >
                      <Delete />
                      Delete
                    </MenuItem>
                  </MoreMenu>
                );
              },
            },
          ]}
        />
      </Box>
    </MagicTable.Container>
  );
}

const SEARCH_OPTIONS: LabelValue[] = [
  {
    label: 'Entity Type',
    value: 'entityType',
  },
  {
    label: 'Entity Uuid',
    value: 'entityUuid',
  },
];

function parseEntityTypeLink({
  entityType,
  entityUuid,
}: {
  entityType: EntityNoteTypes;
  entityUuid: string;
}) {
  switch (entityType) {
    case EntityNoteTypes.REFUND:
      return Routes.refund(entityUuid);
    case EntityNoteTypes.TRANSACTION_POOL:
      return Routes.transactions.pool(entityUuid);
    case EntityNoteTypes.USER:
      return Routes.user(entityUuid);
    default:
      return '#';
  }
}
