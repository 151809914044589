import { DeepPartial } from 'react-hook-form';
import { EntityNote } from 'src/lib/types/entityNote';
import { parseSearchParams } from 'src/lib/utils/parseSearchParams';
import useSWR, { SWRResponse } from 'swr';

export type PossibleParams = {
  page: number | string;
  size: number | string;
  order: Record<string, string>;
  filter: {
    [key: string]: {
      like: string;
      eq: string;
    };
  };
};
export const fetchUserEntityNotes = (
  userUuid: string,
  filter?: DeepPartial<PossibleParams>
): SWRResponse<EntityNotesFetchResponse[]> => {
  const parsedParams = parseSearchParams(filter ?? {});
  const response = useSWR<EntityNotesFetchResponse[], Error>(
    `admin/entity-notes/user/${userUuid}/?${parsedParams}`
  );

  return response;
};

export type EntityNotesFetchResponse = EntityNote[];
