import useNotify from 'src/lib/hooks/useNotify';
import useModalStore from 'src/lib/stores/useModalStore';
import { ChannelEmitter } from '../../events/ChannelEmitter';
import { DeleteChannel } from '../../services/deleteChannel';

export default function useDeleteChannel() {
  const closeModal = useModalStore((state) => state.closeModal);
  const notify = useNotify();

  const handleDeleteChannel = async (uuid: string) => {
    const { data, error } = await DeleteChannel(uuid);
    if (error) {
      notify(error, { variant: 'error' });
      closeModal();
    } else {
      notify('Channel deleted!', { variant: 'success' });
      closeModal();
      ChannelEmitter.emit('ChannelDeleted', data);
    }
  };

  return {
    handleDeleteChannel,
  };
}
