import { ReactNode } from 'react';
import { Navigate } from 'react-router-dom';
// hooks
import Routes from 'src/routes/Routes';
import LoadingScreen from '../components/LoadingScreen';
import useAuth from '../hooks/useAuth';
// routes
// components

// ----------------------------------------------------------------------

type GuestGuardProps = {
  children: ReactNode;
};

export default function GuestGuard({ children }: GuestGuardProps) {
  const { isAuthenticated, isInitialized } = useAuth();

  if (isAuthenticated) {
    return <Navigate to={Routes.dashboard} />;
  }

  if (!isInitialized) {
    return <LoadingScreen />;
  }

  // eslint-disable-next-line react/jsx-no-useless-fragment
  return <>{children}</>;
}
