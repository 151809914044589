import { DeepPartial } from 'react-hook-form';
import { Contest } from 'src/lib/types/contest';
import { parseSearchParams } from 'src/lib/utils/parseSearchParams';
import useSWR, { SWRResponse } from 'swr';

export type PossibleParams = {
  page: number | string;
  size: number | string;
  order: Record<string, string>;
  filter: {
    [key: string]: {
      like: string;
      eq: string;
    };
  };
};

export const fetchContests = (
  filter?: DeepPartial<PossibleParams>
): SWRResponse<Contest[]> => {
  const parsedParams = parseSearchParams(filter ?? {});
  const response = useSWR<Contest[], Error>(
    `admin/game-contest/?${parsedParams}`
  );

  return response;
};
