import { CurrenciesType } from './currency';
import { User } from './users';

export type PaymentRequest = {
  amount: number;
  createdAt: number;
  currency: CurrenciesType | null;
  executedAt: string;
  scheduledAt: string;
  status: PaymentRequestStatus;
  updatedAt: number;
  rejectedReason: string | null;
  user?: User;
  userUuid: string;
  uuid: string;
  value: number;
  isEligible?: boolean;
  userHasProSubscription?: boolean;
};

export type PaymentRequestValues = {
  stickyValue: number;
  value: number;
};

export type PaymentRequestHistory = {
  uuid: string;
  paymentRequestId: string;
  status: PaymentRequestStatus;
  note: string;
  updatedBy: string;
  createdAt: Date;
  updatedAt: Date;
};

export type WithPaymentRequestHistory<T> = T & {
  history: PaymentRequestHistory[];
};

export enum PaymentRequestStatus {
  REQUESTED = 'requested',
  CANCELED = 'canceled',
  EXECUTED = 'executed',
  REJECTED = 'rejected',
  SCHEDULED = 'scheduled',
}
