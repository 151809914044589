/**
 * Returns valid image url for nfts that have ipfs hash
 * @param url nft url
 * @returns valid image url for nfts that have ipfs hash
 */
export const getImageURL = (
  url: string,
  useProxy = false,
  useWatermark = false
) => {
  let newURL = url;
  const isIPFSUrl = Boolean(url?.includes('ipfs/') || url?.includes('ipfs://'));
  const isSVG = Boolean(url?.endsWith('.svg'));

  if (isIPFSUrl) {
    let ifpsHash;
    let fileName;

    if (url?.includes('ipfs://')) {
      const splittedURL: string[] = url.split('ipfs://')?.[1]?.split('/');
      ifpsHash = splittedURL?.[0];
      fileName = splittedURL?.[1];
    }

    if (ifpsHash) {
      newURL = `https://dweb.link/ipfs/${ifpsHash}`;
      if (fileName) newURL += `/${fileName}/`;
    }
  }

  if (useProxy && !isSVG) {
    const proxyUrl = `https://img.stickermaker.app/resize?url=${encodeURIComponent(
      newURL
    )}&ext=webp&width=300&height=300&forceStatic=false&useWatermark=${useWatermark}`;

    return proxyUrl;
  }

  return newURL;
};
