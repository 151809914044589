import { Box, FormControlLabel, Switch, TablePagination } from '@mui/material';
import usePaymentsTable from '../../usePaymentsTable';

export default function PaymentsTableFooter({
  page,
  rowsPerPage,
  total,
}: {
  page: number;
  rowsPerPage: number;
  total: number;
}) {
  const [dense, onDenseChange] = usePaymentsTable((state) => [
    state.dense,
    state.onDenseChange,
  ]);

  const [onPageChange, onRowsPerPageChange] = usePaymentsTable((state) => [
    state.onPageChange,
    state.onRowsPerPageChange,
  ]);

  return (
    <Box sx={{ position: 'relative' }}>
      <TablePagination
        rowsPerPageOptions={[5, 10, 25]}
        component="div"
        count={total ?? 0}
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={onPageChange}
        onRowsPerPageChange={onRowsPerPageChange}
      />

      <FormControlLabel
        control={<Switch checked={dense} onChange={onDenseChange} />}
        label="Dense View"
        sx={{ px: 3, py: 1.5, top: 0, position: { md: 'absolute' } }}
      />
    </Box>
  );
}
