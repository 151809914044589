import axios, { AxiosRequestHeaders } from 'axios';
import { AUTH } from 'src/template/contexts/FirebaseContext';

const getSGMClient = async () => {
  const token = await AUTH.currentUser?.getIdToken();

  const api = axios.create({
    baseURL: process.env.REACT_APP_SGM_API_URL,
  });

  if (token) {
    api.defaults.headers.common = {
      'Content-Type': 'application/json',
      'X-Application-Token': '12345678910',
      Authorization: token,
    } as AxiosRequestHeaders;
  }

  return api;
};

export default getSGMClient;
