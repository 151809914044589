import { TransactionPoolStatus } from '../../types/transaction';

export const getLabelColor = (status: TransactionPoolStatus | undefined) => {
  switch (status) {
    case 'failed':
      return 'error';
    case 'success':
      return 'success';
    case 'pending':
      return 'warning';
    case 'rollback':
      return 'secondary';
    default:
      return 'secondary';
  }
};
