import { useParams } from 'react-router';
import { DEFAULT_INITIAL_PAGE_SIZE } from 'src/lib/constants/pagination';
import useURLSearchParams from 'src/lib/hooks/useURLSearchParams';
import {
  OBJECT_MODERATION_OBJECT_TYPE,
  OBJECT_MODERATION_REVIEW_STATUS,
} from 'src/lib/types/objectModeration';
import useTabs from 'src/template/hooks/useTabs';
import { fetchModeratedObjects } from '../../services/fetchModeratedObjects';

export default function useChannelsView() {
  const { objectType } = useParams<'objectType'>();
  const ObjectModerationObjectType =
    objectType as OBJECT_MODERATION_OBJECT_TYPE;

  const { currentTab, onChangeTab } = useTabs(
    OBJECT_MODERATION_REVIEW_STATUS.AI_REJECTED,
    {
      queryName: 'tab',
    }
  );

  const { allSearchParams } = useURLSearchParams(initialParams);

  const params: ListObjectsToReviewParams = {
    page: Number(allSearchParams.page) ?? initialParams.page,
    size: Number(allSearchParams.size) ?? initialParams.size,
    sortBy: allSearchParams.sortBy ?? initialParams.sortBy,
    order: allSearchParams.order ?? initialParams.order,
  };

  const { data, isLoading } = fetchModeratedObjects({
    objectType: ObjectModerationObjectType,
    page: params.page,
    size: params.size,

    order: {
      [params.sortBy]: params.order,
    },

    filter: {
      ...(currentTab === 'all'
        ? {}
        : { reviewStatus: currentTab as OBJECT_MODERATION_REVIEW_STATUS }),
    },
  });

  return {
    onChangeTab,
    currentTab: currentTab as OBJECT_MODERATION_REVIEW_STATUS,
    data,
    isLoading,
    params,
    ObjectModerationObjectType,
  };
}

const initialParams = {
  sortBy: 'updatedAt',
  order: 'desc',
  page: '0',
  size: DEFAULT_INITIAL_PAGE_SIZE.toString(),
  searchBy: 'itemType',
  query: '',
};

export type ListObjectsToReviewParams = {
  page: number;
  size: number;
  sortBy: string;
  order: string;
  searchBy?: string;
  query?: string;
};
