import { Box } from '@mui/material';
import { GridSortDirection } from '@mui/x-data-grid';
import Flex from 'src/components/shared/Flex';
import MagicTable from 'src/components/shared/MagicTable/MagicTable';
import SectionDivider from 'src/components/shared/SectionDivider';
import { DEFAULT_INITIAL_PAGE_SIZE } from 'src/lib/constants/pagination';
import useURLSearchParams from 'src/lib/hooks/useURLSearchParams';
import { formatUTCDate } from 'src/lib/utils/formatUTCDate';
import { fetchNotProcessedConsumables } from '../../services/fetchNotProcessedConsumable';

export default function NotProcessedConsumablesTable() {
  const { addParam, allSearchParams } = useURLSearchParams(initialParams);
  const size = +(allSearchParams?.size ?? initialParams.size);
  const page = +(allSearchParams?.page ?? initialParams.page);
  const orderBy = allSearchParams?.orderBy ?? initialParams.orderBy;
  const sortOrder = allSearchParams?.order ?? initialParams.order;

  const { data: consumables, isLoading } = fetchNotProcessedConsumables({
    page,
    size,
    order: {
      [orderBy]: sortOrder,
    },
  });

  return (
    <>
      <SectionDivider section="Dates are in UTC timezone" />
      <MagicTable.Container>
        <Flex gap={2} p={2} />
        <Box px={2}>
          <MagicTable
            loading={isLoading}
            pageSize={size}
            page={page}
            paginationMode="server"
            onPageChange={(newPage) => {
              addParam('page', newPage);
            }}
            onPageSizeChange={(newSize) => {
              addParam('size', newSize);
            }}
            onSortModelChange={(model) => {
              const sortModel = model[0];
              addParam('orderBy', sortModel.field);
              addParam('order', sortModel.sort!);
            }}
            sortModel={[
              {
                field: orderBy,
                sort: sortOrder as GridSortDirection,
              },
            ]}
            rows={consumables ?? []}
            hideNumberOfRows
            columns={[
              {
                field: 'uuid',
                headerName: 'uuid',
                sortable: false,
              },
              {
                field: 'objectProductFullId',
                headerName: 'objectProductFullId',
                sortable: false,
              },
              {
                field: 'userUuid',
                headerName: 'userUuid',
                sortable: false,
              },
              {
                field: 'environment',
                headerName: 'environment',
                sortable: false,
              },
              {
                field: 'createdAt',
                headerName: 'Created At',
                sortingOrder: ['desc', 'asc'],
                flex: 1 / 2,
                sortComparator: () => 0,
                valueFormatter({ value }) {
                  return value ? formatUTCDate(value) : '-';
                },
              },
            ]}
          />
        </Box>
      </MagicTable.Container>
    </>
  );
}

const initialParams = {
  page: '0',
  size: DEFAULT_INITIAL_PAGE_SIZE.toString(),
  order: 'desc',
  orderBy: 'createdAt',
};
