import { useRef } from 'react';
import Flex from 'src/components/shared/Flex';
import { SGMChat, SGMChatMessage } from 'src/lib/types/sgmChatMessage';
import MessageBubble from './MessageBubble';

interface ChatMessagesProps {
  chatInfo: SGMChat;
  messages: SGMChatMessage[];
}

export default function ChatMessages({
  messages,
  chatInfo,
}: ChatMessagesProps) {
  const containerRef = useRef<HTMLDivElement>(null);
  return (
    <Flex
      px={2}
      flex={1}
      flexDirection={'column'}
      width={'100%'}
      gap={4}
      my={1}
      ref={containerRef}
      overflow={'auto'}
      sx={{
        '&::-webkit-scrollbar': {
          width: '5px',
        },
        '&::-webkit-scrollbar-track': {
          background: 'transparent',
        },
        '&::-webkit-scrollbar-thumb': {
          backgroundColor: '#888', // Cor da barra de rolagem
          borderRadius: '5px',
        },
        '&::-webkit-scrollbar-thumb:hover': {
          backgroundColor: '#555', // Cor ao passar o mouse sobre a barra
        },
      }}
    >
      {messages.length > 0 && <Flex flex="1" />}
      {messages.length > 0 &&
        messages.map((msg) => (
          <MessageBubble key={msg.id} chatInfo={chatInfo} message={msg} />
        ))}
    </Flex>
  );
}
