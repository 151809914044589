import { Box } from '@mui/material';
import { QRCodeSVG } from 'qrcode.react';

interface QRCodeGeneratorProps {
  url: string;
  size?: number;
}

export default function QRCodeGenerator({
  url,
  size = 200,
}: QRCodeGeneratorProps) {
  return (
    <Box display="flex" justifyContent="center" alignItems="center" p={2}>
      <QRCodeSVG value={url} size={size} />
    </Box>
  );
}
