import { Container } from '@mui/material';
import SubscriptionSalesView from 'src/components/modules/RevenueEvents/view/Subscriptions/Products';
import HeaderBreadcrumbs from 'src/template/components/HeaderBreadcrumbs';
import Page from 'src/template/components/Page';

export default function SubscriptionSalesPage(): JSX.Element {
  return (
    <Page title="Subscriptions Sales">
      <Container maxWidth="xl">
        <HeaderBreadcrumbs
          heading="Subscription Sales"
          links={[{ name: 'Subscription Sales' }]}
        />
        <SubscriptionSalesView />
      </Container>
    </Page>
  );
}
