import useNotify from 'src/lib/hooks/useNotify';
import useModalStore from 'src/lib/stores/useModalStore';
import { GameStatisticGroupEmitter } from '../../events/GameStatisticGroupEmitter';
import { deleteStatisticGroup } from '../../services/deleteStatisticGroup';

export default function useDeleteStatisticGroup() {
  const closeModal = useModalStore((state) => state.closeModal);
  const notify = useNotify();

  const handleDeleteStatisticGroup = async (statisticGroupUuid: string) => {
    const { data, error } = await deleteStatisticGroup(statisticGroupUuid);
    if (error) {
      notify(error, { variant: 'error' });
      closeModal();
    } else {
      notify('Statistic Group deleted!', { variant: 'success' });
      closeModal();
      GameStatisticGroupEmitter.emit('GameStatisticGroupDeleted', data);
    }
  };

  return {
    handleDeleteStatisticGroup,
  };
}
