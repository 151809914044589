import { DeepPartial } from 'react-hook-form';
import { parseSearchParams } from 'src/lib/utils/parseSearchParams';
import useSWR, { SWRResponse } from 'swr';
import { PurchaseEvent } from '../types/PurchaseEvent';

type PossibleParams = {
  page: number | string;
  size: number | string;
  order: Record<string, string>;
};

export const fetchNotProcessedConsumables = (
  filter?: DeepPartial<PossibleParams>
): SWRResponse<PurchaseEvent[]> => {
  const parsedParams = parseSearchParams(filter ?? {});
  // TODO: change this to the correct endpoint
  const response = useSWR<PurchaseEvent[], Error>(
    `admin/revenue-events/consumables/not-processed?${parsedParams}`
  );

  return response;
};
