import { useCallback, useEffect } from 'react';
import { useParams } from 'react-router';
import { DEFAULT_INITIAL_PAGE_SIZE } from 'src/lib/constants/pagination';
import useURLSearchParams from 'src/lib/hooks/useURLSearchParams';
import useModalStore from 'src/lib/stores/useModalStore';
import { NftPack, NftPackStatus, Rarity } from 'src/lib/types/nftPack';
import { NftPackEmitter } from '../../../events/NftPackEmitter';
import { fetchNftPackDetails } from '../../../services/fetchNftPackDetails';
import { fetchNftsInPack } from '../../../services/fetchNftsInPack';
import AddNftsInPackView from '../AddNft';
import ChangeNftPackStatusView from '../ChangeStatus';
import DeleteNftPackView from '../Delete';
import EditNftPackRaritiesView from '../EditRarities';
import DeleteNftFromPackView from '../RemoveNftFromPack';
import UpdateNftPackView from '../Update';
import UpdateNftOnPackView from '../UpdateNftOnPack';

export default function useNftPackDetailsView() {
  const { uuid: packUuid } = useParams<'uuid'>();
  const openModal = useModalStore((state) => state.openModal);

  const handleOpenUpdateModal = async (nftPack: NftPack) => {
    openModal(<UpdateNftPackView nftPack={nftPack} />);
  };
  const handleOpenChangeStatusModal = async (
    uuid: string,
    status: NftPackStatus
  ) => {
    openModal(<ChangeNftPackStatusView uuid={uuid} status={status} />);
  };
  const handleOpenDeleteModal = async (uuid: string) => {
    openModal(<DeleteNftPackView uuid={uuid} />);
  };
  const handleOpenEditRaritiesModal = async (
    uuid: string,
    rarities: Rarity[]
  ) => {
    openModal(<EditNftPackRaritiesView uuid={uuid} rarities={rarities} />);
  };
  const handleOpenAddNftModal = async ({
    uuid,
    rarities,
  }: {
    uuid: string;
    rarities: Rarity[];
  }) => {
    openModal(<AddNftsInPackView uuid={uuid} rarities={rarities} />);
  };
  const handleOpenDeleteNftFromPackModal = (nfts: string[]) =>
    openModal(<DeleteNftFromPackView uuid={packUuid!} nfts={nfts} />);

  const handleOpenUpdateNftModal =
    (rarities: Rarity[]) =>
    (
      nftUuid: string,
      currentStatus: { faceValue: number; rarityUuid: string }
    ) =>
      openModal(
        <UpdateNftOnPackView
          packUuid={packUuid!}
          nftUuid={nftUuid}
          rarities={rarities}
          currentStatus={currentStatus}
        />
      );

  const { data: nftPackDetails, mutate: mutateDetails } = fetchNftPackDetails(
    packUuid!
  );

  nftPackDetails?.rarities.sort(
    (a, b) => a.nftPackRarity.proportion - b.nftPackRarity.proportion
  );

  const refetchDetailsData = useCallback(() => {
    mutateDetails?.();
  }, []);

  useEffect(() => {
    NftPackEmitter.on('NftPackCreated', refetchDetailsData);
    NftPackEmitter.on('NftPackUpdated', refetchDetailsData);
    NftPackEmitter.on('NftPackRaritiesUpdated', refetchDetailsData);
    NftPackEmitter.on('NftPackNftsUpdated', refetchDetailsData);
    NftPackEmitter.on('NftPackNftsRemoved', refetchDetailsData);

    return () => {
      NftPackEmitter.off('NftPackCreated', refetchDetailsData);
      NftPackEmitter.off('NftPackUpdated', refetchDetailsData);
      NftPackEmitter.off('NftPackRaritiesUpdated', refetchDetailsData);
      NftPackEmitter.off('NftPackNftsUpdated', refetchDetailsData);
      NftPackEmitter.off('NftPackNftsRemoved', refetchDetailsData);
    };
  }, [refetchDetailsData]);

  const { allSearchParams } = useURLSearchParams({
    sortBy: 'name',
    order: 'asc',
    page: '0',
    size: DEFAULT_INITIAL_PAGE_SIZE.toString(),
    searchBy: 'name',
  });

  const page = Number(allSearchParams.page);
  const size = Number(allSearchParams.size);

  const query: any = {};
  if (allSearchParams.searchBy && allSearchParams.query) {
    query[allSearchParams.searchBy] = {
      like: `%${allSearchParams.query}%`,
    };
  }

  const {
    data: nftsInPack,
    isLoading: nftsInPackIsLoading,
    mutate: mutataNftsInPack,
  } = fetchNftsInPack(packUuid!, {
    /*
    filter: { ...query },
    ...(allSearchParams.sortBy && {
      order: {
        [allSearchParams.sortBy]: allSearchParams.order,
      },
    }),
    */
  });

  const refetchNftsInPack = useCallback(() => {
    mutataNftsInPack?.();
  }, []);

  useEffect(() => {
    NftPackEmitter.on('NftPackNftsUpdated', refetchNftsInPack);
    NftPackEmitter.on('NftPackNftsRemoved', refetchNftsInPack);

    return () => {
      NftPackEmitter.off('NftPackNftsUpdated', refetchNftsInPack);
      NftPackEmitter.off('NftPackNftsRemoved', refetchNftsInPack);
    };
  }, [refetchNftsInPack]);

  const params = {
    page,
    size,
    order: allSearchParams.order,
    sortBy: allSearchParams.sortBy,
    searchBy: allSearchParams.searchBy,
    query: allSearchParams.query,
  };

  return {
    handleOpenUpdateModal,
    handleOpenDeleteModal,
    handleOpenEditRaritiesModal,
    handleOpenAddNftModal,
    handleOpenDeleteNftFromPackModal,
    handleOpenUpdateNftModal,
    handleOpenChangeStatusModal,
    nftPackDetails,
    nftsInPack,
    nftsInPackIsLoading,
    params,
  };
}
