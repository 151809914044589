import { Container } from '@mui/material';
import StickyChannels from 'src/components/modules/Channels/view/ListStickyChannels';
import Page from 'src/template/components/Page';

export default function StickyChannelsPage() {
  return (
    <Page title="Channels by sticky">
      <Container maxWidth="xl">
        <StickyChannels />
      </Container>
    </Page>
  );
}
