import useNotify from 'src/lib/hooks/useNotify';
import useModalStore from 'src/lib/stores/useModalStore';
import { GameTagEmitter } from '../../events/Emitter';
import { DeleteGameTag } from '../../services/delete';

export default function useDeleteGameTag() {
  const closeModal = useModalStore((state) => state.closeModal);
  const notify = useNotify();

  const handleDeleteGame = async (uuid: string) => {
    const { data, error } = await DeleteGameTag(uuid);
    if (error) {
      notify(error, { variant: 'error' });
      closeModal();
    } else {
      notify('Game GameTag deleted!', { variant: 'success' });
      closeModal();
      GameTagEmitter.emit('GameTagDeleted', data);
    }
  };

  return {
    handleDeleteGame,
  };
}
