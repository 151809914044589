import { capitalCase } from 'change-case';
import { format } from 'date-fns';
import { WithDevices } from 'src/components/modules/Devices/types/Device';
import useSWR, { mutate as globalMutate } from 'swr';
import useSWRInfinite from 'swr/infinite';
import { OldTransaction } from '../types/OldTransaction';
import { PaginatedResponse } from '../types/PaginatedResponse';
import { WithPaymentInfo } from '../types/PaymentInfo';
import { SearchParams } from '../types/SearchParams';
import {
  GetUserNFTSalesResponse,
  UserNFTSalesParsedData,
} from '../types/UserNFTSale';
import { BuyerRankingParams, BuyerWithRank } from '../types/UserRank';
import { BracketFilterParams } from '../types/bracketFilterParams';
import { WithCollections } from '../types/collection';
import { Fetch } from '../types/fetch';
import { WithGlobalWallets } from '../types/globalWallet';
import { NFT } from '../types/nft';
import { WithNFTOwnership } from '../types/ownership';
import { WithPaymentRequests } from '../types/payment';
import { CommonAttributesForUserAdminLists, User } from '../types/users';
import { WithCryptoWallets } from '../types/wallet/cryptoWallet';
import { LockBalanceBreakDown } from '../types/wallet/walletDetails';
import { UserSales } from '../types/wallet/walletSales';
import { UserSpending } from '../types/wallet/walletSpending';
import { WithWallet } from '../types/wallet/wallets';
import { deepClone } from '../utils/deepClone';
import { getMessageFromError } from '../utils/error';
import formatStickyCoinValue from '../utils/formatStickyCoinValue';
import generateSearchRequest from '../utils/generateSearchRequest';
import { parseSearchParams } from '../utils/parseSearchParams';
import getAPIClient from './api/axios.config';

/**
 * Fetches a list of users
 * @param {BracketFilterParams} filter - Object that contains the bracket filter parameters
 * @return {Fetch<User>} returns a list of users that follows swr's fetch format plus the total count of users
 */
export const fetchUsers = (filter: BracketFilterParams): Fetch<User[]> => {
  const { data, error } = useSWR<User[], Error>(
    `admin/users/${generateSearchRequest(filter)}`
  );

  return {
    data,
    loading: !error && !data,
    error: error?.message,
  };
};

export const fetchRankedBuyers = (
  params: BuyerRankingParams
): Fetch<BuyerWithRank[]> => {
  const { rankBy, country, lang, page, size } = params;
  const urlParams = new URLSearchParams();
  urlParams.append('rankBy', rankBy);
  if (country) urlParams.append('filter[country]', country);
  if (lang) urlParams.append('filter[lang]', lang);
  if (page) urlParams.append('page', page.toString());
  if (size) urlParams.append('size', size.toString());

  const { data, error } = useSWR<PaginatedResponse<BuyerWithRank[]>, Error>(
    `admin/users/ranking/buyer?${urlParams}`
  );

  const roundToFour = (decimal: number): number =>
    +(Math.round(decimal * 10000) / 10000).toFixed(4);

  const parseData = (buyers: BuyerWithRank[]): BuyerWithRank[] =>
    buyers.map((buyer) => ({
      ...buyer,
      volume: formatStickyCoinValue(buyer.volume),
      purchases: buyer.purchases,
      averagePurchasePrice: roundToFour(buyer.averagePurchasePrice / 1000),
    }));

  return {
    data: data ? parseData(data.body) : undefined,
    loading: !error && !data,
    error: error?.message,
  };
};

/**
 * Deletes a user
 * @param {string} uuid - The user's authId
 */
export const deleteUser = async (uuid: string) => {
  try {
    const api = await getAPIClient();
    const res = await api.delete(`admin/users/${uuid}`);
    globalMutate(`admin/users/${uuid}/profile`);

    return {
      data: res.data,
      error: null,
    };
  } catch (e) {
    return {
      data: null,
      error: getMessageFromError(e),
    };
  }
};

/**
 * Blocks / Unblocks a user from the platform based on the current status
 * @param {string} uuid - The user's authId
 */
export async function toggleUserActivationService(uuid: string) {
  try {
    const api = await getAPIClient();
    const res = await api.put(`admin/users/${uuid}/toggle-activation`);
    globalMutate((key: any) => typeof key === 'string' && key.includes(uuid));

    return {
      data: res.data,
      error: null,
    };
  } catch (e) {
    return {
      data: null,
      error: getMessageFromError(e),
    };
  }
}

/**
 * Fetches a user
 * @param {string} uuid - The user's authId
 * @returns {Fetch<User>} returns a user that follows swr's fetch format
 */
export const fetchUser = (
  uuid: string
): Fetch<
  WithGlobalWallets<
    WithDevices<
      WithWallet<
        WithCryptoWallets<
          WithPaymentRequests<WithPaymentInfo<WithCollections<User>>>
        >
      >
    >
  >
> => {
  const { data, error, mutate } = useSWR(`admin/users/${uuid}/profile`);

  return {
    data,
    loading: !error && !data,
    error: error?.message,
    mutate,
  };
};

export const fetchUserLockBalanceDetails = (
  userUuid: string
): Fetch<LockBalanceBreakDown> => {
  const { data, error } = useSWR<LockBalanceBreakDown, Error>(
    `admin/users/${userUuid}/lock-balance`
  );

  const parseData = (
    lockBalanceBreakdown: LockBalanceBreakDown
  ): LockBalanceBreakDown => ({
    available: formatStickyCoinValue(lockBalanceBreakdown.available),
    lockedBid: formatStickyCoinValue(lockBalanceBreakdown.lockedBid),
    lockedPayout: formatStickyCoinValue(lockBalanceBreakdown.lockedPayout),
    lockedTransactions: formatStickyCoinValue(
      lockBalanceBreakdown.lockedTransactions
    ),
  });

  return {
    data: data ? parseData(data) : undefined,
    loading: !error && !data,
    error: error?.message,
  };
};

export const fetchUserNFTSales = (
  userId: string,
  filters: SearchParams
): Fetch<UserNFTSalesParsedData> => {
  const parsedParams = parseSearchParams(filters);
  const { data, error } = useSWR<GetUserNFTSalesResponse, Error>(
    `admin/users/${userId}/nft-sales?${parsedParams}`
  );

  const parseData = (
    sales: GetUserNFTSalesResponse
  ): UserNFTSalesParsedData => {
    const deepCloneSales = deepClone(sales);

    return {
      ...deepCloneSales,
      body: deepCloneSales.body.map((sale) => ({
        ...sale,
        price: formatStickyCoinValue(sale.price),
        soldAt: format(sale.soldAt, 'dd/MM/yy hh:mm'),
        coin_type: sale.coin_type
          .split(',')
          .map((v) => capitalCase(v))
          .join(' / '),
      })),
    };
  };

  return {
    data: data ? parseData(data) : undefined,
    loading: !error && !data,
    error: error?.message,
  };
};

export const fetchWalletWrongValues = (userId: string) => {
  const { data, error } = useSWR<string[]>(
    `admin/users/${userId}/wallet/wrong-values`
  );

  return {
    data,
    loading: !error && !data,
    error,
  };
};

export const fetchUserCollectionDetails = (uuid: string) => {
  const { data, error } = useSWR(`admin/users/${uuid}/collections/details`);

  return {
    data,
    loading: !error && !data,
    error,
  };
};

export const fetchUserTransactionsDetails = (uuid: string) => {
  const { data, error } = useSWR(`admin/users/${uuid}/transactions/details`);

  return {
    data,
    loading: !error && !data,
    error,
  };
};

export const fetchUserPaymentsDetails = (uuid: string) => {
  const { data, error } = useSWR(`admin/users/${uuid}/payments/details`);

  return {
    data,
    loading: !error && !data,
    error,
  };
};
export const fetchUserTransactionHistory = () => {
  const defaultOptions = {
    page: 0,
    limit: 30,
  };
  const getKey = (pageIndex: number, previousPageData: OldTransaction[]) => {
    if (previousPageData && !previousPageData.length) return null;
    return `/transaction/moves?page=${pageIndex}&limit=${defaultOptions.limit}`;
  };

  const { data, error, setSize } = useSWRInfinite(getKey);

  const transactions = data ? [].concat(...data) : [];
  const hasReachedEnd = Boolean(
    data && data[data.length - 1].length < defaultOptions.limit
  );
  return {
    transactions,
    setSize,
    error,
    isLoading: !error && !data,
    hasReachedEnd,
  };
};

type IFetchUserNftsCreatedMeta = {
  totalCount: number;
  totalCopiesSold: number;
  totalUniqueNftsSold: number;
  totalSalesAmount: number;
  totalNftCopies: number;
  uniqueBuyers: number;
};
export const fetchUserNftsCreated = (
  uuid: string,
  filter: BracketFilterParams
) => {
  const { data, error } = useSWR<
    PaginatedResponse<NFT[], IFetchUserNftsCreatedMeta>,
    Error
  >(`admin/users/${uuid}/nfts-created${generateSearchRequest(filter)}`);

  const isLoading = !error && !data;

  return {
    nfts: data?.body ?? ([] as NFT[]),
    totalCount: data?.meta.totalCount ?? 0,
    meta: data?.meta ?? ({} as IFetchUserNftsCreatedMeta),
    isLoading,
    error,
  };
};

export function fetchUserNftsCollected(
  uuid: string,
  filter: BracketFilterParams
) {
  const { data, error, mutate, isLoading, isValidating } = useSWR<
    PaginatedResponse<WithNFTOwnership<NFT>[]>,
    Error
  >(`admin/users/${uuid}/nfts-collected${generateSearchRequest(filter)}`, {
    revalidateOnFocus: false,
  });

  return {
    data: data?.body ?? [],
    totalCount: data?.meta.totalCount ?? 0,
    isLoading,
    error,
    mutate,
    isMutating: isValidating && !isLoading,
  };
}

export const updateUser = async (uuid: string, data: Partial<User>) => {
  try {
    const api = await getAPIClient();
    const res = await api.put(`admin/users/${uuid}`, data);
    globalMutate(`admin/users/${uuid}/profile`);

    return {
      data: res.data,
      error: null,
    };
  } catch (e) {
    return {
      data: null,
      error: getMessageFromError(e),
    };
  }
};

export const fetchUserSales = (uuid: string) => {
  const { data, error } = useSWR<UserSales, Error>(
    `admin/users/${uuid}/wallet/sales`
  );

  const isLoadingInitialData = !data && !error;

  const parseData = (response: UserSales | undefined) => {
    if (!response) return undefined;
    const formattedData = deepClone(response);
    formattedData.topBuyers.forEach((buyer, index) => {
      formattedData.topBuyers[index].coins = formatStickyCoinValue(
        formattedData.topBuyers[index].coins
      );
    });
    formattedData.anonymousBuyer.coins = formatStickyCoinValue(
      formattedData.anonymousBuyer.coins
    );
    if (formattedData.biggestBuyer) {
      formattedData.biggestBuyer.coinsSpent.coins = formatStickyCoinValue(
        formattedData.biggestBuyer.coinsSpent.coins
      );
      formattedData.biggestBuyer.numberOfNfts.coins = formatStickyCoinValue(
        formattedData.biggestBuyer.numberOfNfts.coins
      );
    }
    return formattedData;
  };

  return {
    data: parseData(data),
    isLoading: isLoadingInitialData,
    error,
  };
};

export const fetchUserSpending = (uuid: string) => {
  const { data, error } = useSWR<UserSpending, Error>(
    `admin/users/${uuid}/wallet/spending`
  );

  const isLoadingInitialData = !data && !error;

  const parseData = (response: UserSpending | undefined) => {
    if (!response) return undefined;
    const formattedData = deepClone(response);
    formattedData.topSellers.forEach((_buyer, index) => {
      formattedData.topSellers[index].coins = formatStickyCoinValue(
        formattedData.topSellers[index].coins
      );
    });
    if (formattedData.biggestCreator) {
      formattedData.biggestCreator.coinsSpent.coins = formatStickyCoinValue(
        formattedData.biggestCreator.coinsSpent.coins
      );
      formattedData.biggestCreator.numberOfNfts.coins = formatStickyCoinValue(
        formattedData.biggestCreator.numberOfNfts.coins
      );
    }
    return formattedData;
  };

  return {
    data: parseData(data),
    isLoading: isLoadingInitialData,
    error,
  };
};

export const fetchUserBehavior = (uuid: string) => {
  const { data, error } = useSWR<UserBehaviorReturn, Error>(
    `admin/users/${uuid}/activity/behaviour`
  );

  return {
    data,
    isLoading: !data && !error,
    error,
  };
};

export type UserBehaviorReturn = {
  possibleMultipleAccounts: number;
  devicesAssociated: number;
  distinctUsersInteractedWith: number;
};

export const fetchUserNftActivityTimeline = (
  userId: string,
  year: number,
  month: number
) => {
  const { data, error } = useSWR<UserNftActivityTimeline, Error>(
    `admin/users/${userId}/activity/nft-activity-timeline?desiredYear=${year}&desiredMonth=${month}`
  );

  return {
    data,
    loading: !data && !error,
    error,
  };
};

export type UserNftActivityTimeline = {
  transferTimeline: number[];
  purchasesTimeline: number[];
  salesTimeline: number[];
};

type PossibleParams = {
  page: number;
  size: number;
};
export const fetchPossibleMultipleAccounts = (userId: string) => {
  const { data, error } = useSWR<CommonAttributesForUserAdminLists[], Error>(
    `admin/users/${userId}/activity/behaviour/details/possible-multiple-accounts`
  );

  return {
    data,
    isLoading: !data && !error,
    error,
  };
};

type DeviceForListing = {
  uuid: string;
  description: string | null;
  appleVendorId?: string;
  appleAdvertisingId?: string;
  androidId?: string;
  webId?: string;
  platform: string;
  systemVersion: string;
  country: string;
  active: boolean;
  totalUsers?: number;
  blockReason?: string;
  createdAt?: Date;
  updatedAt?: Date;
};

export const fetchUserMultipleDevices = (
  userId: string,
  params: PossibleParams
) => {
  const parsedParams = parseSearchParams(params ?? {});
  const { data, error } = useSWR<DeviceForListing[], Error>(
    `admin/users/${userId}/activity/behaviour/details/multiple-devices?${parsedParams}`
  );

  return {
    data,
    isLoading: !data && !error,
    error,
  };
};
