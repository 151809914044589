import { Container } from '@mui/material';
import Collections from 'src/components/modules/Collections/Collections';
import Page from 'src/template/components/Page';

export default function CollectionsPage() {
  return (
    <Page title="Collections">
      <Container maxWidth="xl">
        <Collections />
      </Container>
    </Page>
  );
}
