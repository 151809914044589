import { format } from 'date-fns';
import { WithParsedTimestamp, WithTimestamp } from '../types/WithTimestamp';

type Options = {
  formatString: string;
};
const defaultOptions: Options = {
  formatString: 'dd/MM/yyyy',
};
export function parseTimestamp<T>(
  data: WithTimestamp<T>,
  options: Partial<Options> = defaultOptions
): WithParsedTimestamp<T> {
  return {
    ...data,
    createdAt: format(data.createdAt, options.formatString!),
    updatedAt: format(data.updatedAt, options.formatString!),
    deletedAt: data.deletedAt
      ? format(data.deletedAt, options.formatString!)
      : null,
  };
}
