import { Autocomplete, TextField } from '@mui/material';
import { capitalCase } from 'change-case';
import { debounce } from 'lodash';
import Flex from 'src/components/shared/Flex';
import SelectOption from 'src/components/shared/SelectOption';
import { CommonDateStrings } from 'src/lib/constants/CommonDates';
import useURLSearchParams from 'src/lib/hooks/useURLSearchParams';
import { transactionPoolTypes } from 'src/lib/types/transaction';
import { FilterFields } from './types';

export default function PoolsFilter(): JSX.Element {
  const { allSearchParams, addParam, removeParam } = useURLSearchParams({
    searchOption: FilterFields.POOL_TYPE,
    startDate: CommonDateStrings.firstTransactionAndPool.dateTime,
    endDate: CommonDateStrings.today.dateTime,
  });
  function handleChangeField(value: string, field: string) {
    addParam(field, value);
  }

  const debouncedChangeField = debounce(handleChangeField, 400);

  const transactionPoolTypesOptions = transactionPoolTypes.map((tpt) => ({
    label: capitalCase(tpt),
    value: tpt,
  }));

  const searchOptions = [
    {
      field: FilterFields.POOL_TYPE,
      label: 'Pool Type',
      input: (
        <Autocomplete
          fullWidth
          renderInput={(params) => <TextField {...params} label="Pool Type" />}
          value={
            {
              value: allSearchParams.transactionPoolType,
              label: capitalCase(allSearchParams.transactionPoolType ?? ''),
            } ?? { value: '', label: '' }
          }
          onChange={(e, newValue) => {
            if (newValue?.value) {
              addParam(FilterFields.POOL_TYPE, newValue.value ?? '');
            } else {
              removeParam(FilterFields.POOL_TYPE);
            }
          }}
          options={transactionPoolTypesOptions.sort((a, b) =>
            a.label.localeCompare(b.label)
          )}
        />
      ),
    },
    {
      field: 'dateRange',
      label: 'Date',
      input: (
        <>
          <TextField
            type="datetime-local"
            label="Start Date"
            fullWidth
            InputLabelProps={{ shrink: true }}
            defaultValue={allSearchParams.startDate}
            onChange={(e) => {
              const { value } = e.target;
              if (value) {
                debouncedChangeField(value, FilterFields.START_DATE);
              } else removeParam(FilterFields.START_DATE);
            }}
            inputProps={{
              min: CommonDateStrings.firstTransactionAndPool.dateTime,
              max: CommonDateStrings.today.dateTime,
            }}
          />
          <TextField
            type="datetime-local"
            label="End Date"
            fullWidth
            InputLabelProps={{ shrink: true }}
            defaultValue={allSearchParams.endDate}
            inputProps={{
              min: CommonDateStrings.firstTransactionAndPool.dateTime,
              max: CommonDateStrings.today.dateTime,
            }}
            onChange={(e) => {
              const { value } = e.target;
              if (value) {
                debouncedChangeField(value, FilterFields.END_DATE);
              } else removeParam(FilterFields.END_DATE);
            }}
          />
        </>
      ),
    },
  ];

  return (
    <Flex gap={2} p={2}>
      <TextField
        select
        fullWidth
        sx={{ maxWidth: 250 }}
        defaultValue={allSearchParams.searchOption}
        onChange={(e) => {
          addParam('searchOption', e.target.value);
        }}
      >
        {searchOptions.map((searchOption) => (
          <SelectOption key={searchOption.label} value={searchOption.field}>
            {searchOption.label}
          </SelectOption>
        ))}
      </TextField>
      {allSearchParams.searchOption &&
        searchOptions.find((op) => op.field === allSearchParams.searchOption)
          ?.input}
    </Flex>
  );
}
