/* eslint-disable import/prefer-default-export */
import { PaymentRequestStatus } from 'src/lib/types/paymentRequest';
import { LabelColor } from 'src/template/components/Label';
/**
 * Get label color based on payment request status
 * @param {PaymentRequestStatus} status payment request status
 * @returns {LabelColor} label color
 */
export const getPaymentRequestLabelColor = (
  status: PaymentRequestStatus
): LabelColor => {
  switch (status) {
    case 'requested':
      return 'warning';
    case 'canceled':
      return 'error';
    case 'executed':
      return 'success';
    case 'scheduled':
      return 'info';
    case 'rejected':
      return 'error';
    default:
      return 'secondary';
  }
};
