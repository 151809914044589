import {
  Button,
  Card,
  CardContent,
  CardHeader,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  Stack,
  TextField,
} from '@mui/material';
import { useSnackbar } from 'notistack';
import { useCallback, useState } from 'react';
import Modal from 'src/components/shared/Modal';
import { addCollectionsToOpenSea } from 'src/lib/services/collections';
import { getMessageFromError } from 'src/lib/utils/error';

export default function ImportFromOpenSeaModal({
  onClose,
  open,
}: {
  onClose: () => void;
  open: boolean;
}) {
  const { enqueueSnackbar: notify } = useSnackbar();

  const [network, setNetwork] = useState<'ethereum' | 'matic'>('ethereum');
  const [commaSeparetedSlugs, setCommaSeparetedSlugs] = useState<string>('');

  const onSubmit = useCallback(async () => {
    const slugs = commaSeparetedSlugs.split(',').map((slug) => slug.trim());

    try {
      await addCollectionsToOpenSea(slugs, network);
      notify('Collections imported successfully', { variant: 'success' });
    } catch (e) {
      notify(getMessageFromError(e), { variant: 'error' });
    }

    onClose();
  }, [commaSeparetedSlugs, network]);

  return (
    <Modal onClose={onClose} open={open}>
      <Card>
        <CardHeader title="Import Collections From OpenSea" />
        <CardContent
          sx={{
            minWidth: ['100%', 700],
          }}
        >
          <FormControl
            fullWidth
            sx={{
              gap: 2,
              mb: 4,
            }}
          >
            <InputLabel id="network">Network</InputLabel>
            <Select
              labelId="demo-simple-select-label"
              id="demo-simple-select"
              value={network}
              label="Network"
              onChange={(e) =>
                setNetwork(e.target.value as 'ethereum' | 'matic')
              }
            >
              <MenuItem value="ethereum">Ethereum</MenuItem>
              <MenuItem value="matic">Polygon</MenuItem>
              <MenuItem value="goerli">Goerli</MenuItem>
            </Select>

            <TextField
              variant="outlined"
              multiline
              label="Collection Slugs (comma separated)"
              placeholder="bored-ape-kennel-club,pudgypenguins"
              onChange={(e) => setCommaSeparetedSlugs(e.target.value)}
              value={commaSeparetedSlugs}
            />
          </FormControl>
          <Stack spacing={1} direction="row">
            <Button
              color="secondary"
              fullWidth
              size="large"
              type="button"
              variant="outlined"
              onClick={onClose}
            >
              Cancel
            </Button>
            <Button
              color="primary"
              fullWidth
              size="large"
              type="submit"
              variant="contained"
              onClick={onSubmit}
            >
              Import
            </Button>
          </Stack>
        </CardContent>
      </Card>
    </Modal>
  );
}
