import { Box } from '@mui/material';
import Flex from 'src/components/shared/Flex';
import NumberInfo from 'src/components/shared/NumberInfo/NumberInfo';
import BalanceRankTableView from '../../../components/Rankings/BalanceRankTable';
import useTopBalancesView from './useView';

const cardWidth: number = 200;
export default function TopBalancesView(): JSX.Element {
  const {
    data,
    isLoading,
    handleOpenFilter,
    currentPage,
    currentRankBy,
    currentSize,
    currentOrder,
  } = useTopBalancesView();
  return (
    <Box mx={2}>
      <Flex width="100%" justifyContent="start" gap={2} flexWrap="wrap" p={2}>
        <NumberInfo
          title="Total Balance"
          total={(data?.meta?.balanceTotalCounts?.totalBalance ?? 0) / 1000}
          componentProps={{
            sx: {
              width: cardWidth,
            },
          }}
        />
        <NumberInfo
          title="Total Earned available"
          total={
            (data?.meta?.balanceTotalCounts?.totalAvailableEarnings ?? 0) / 1000
          }
          componentProps={{
            sx: {
              width: cardWidth,
            },
          }}
        />
        <NumberInfo
          title="Total Bought available"
          total={
            (data?.meta?.balanceTotalCounts?.totalAvailableBoughtCoins ?? 0) /
            1000
          }
          componentProps={{
            sx: {
              width: cardWidth,
            },
          }}
        />
        <NumberInfo
          title="Total Bonus available"
          total={
            (data?.meta?.balanceTotalCounts?.totalAvailableBonus ?? 0) / 1000
          }
          componentProps={{
            sx: {
              width: cardWidth,
            },
          }}
        />
        <NumberInfo
          title="Total Locked Bonus"
          total={(data?.meta?.balanceTotalCounts?.totalLockedBonus ?? 0) / 1000}
          componentProps={{
            sx: {
              width: cardWidth,
            },
          }}
        />
        <NumberInfo
          title="Total Bought Locked"
          total={
            (data?.meta?.balanceTotalCounts?.totalLockedBoughtCoins ?? 0) / 1000
          }
          componentProps={{
            sx: {
              width: cardWidth,
            },
          }}
        />
        <NumberInfo
          title="Total Earned Locked"
          total={
            (data?.meta?.balanceTotalCounts?.totalLockedEarnings ?? 0) / 1000
          }
          componentProps={{
            sx: {
              width: cardWidth,
            },
          }}
        />
      </Flex>
      <BalanceRankTableView
        data={data?.body ?? []}
        isLoading={isLoading}
        totalCount={data?.meta?.totalCount ?? 0}
        handleOpenFilter={handleOpenFilter}
        page={currentPage}
        rankBy={currentRankBy}
        size={currentSize}
        order={currentOrder}
      />
    </Box>
  );
}
