/* eslint-disable react/jsx-no-duplicate-props */
import {
  AccountBox,
  Collections,
  LocalOffer,
  Notes,
} from '@mui/icons-material';
import {
  Card,
  CardActions,
  CardContent,
  CardHeader,
  InputAdornment,
  MenuItem,
  Stack,
  TextField,
  Typography,
} from '@mui/material';
import { useSnackbar } from 'notistack';
import { useState } from 'react';
import { useForm } from 'react-hook-form';
import { useNavigate } from 'react-router';
import Button from 'src/components/shared/Buttons/CustomButton';
import Modal from 'src/components/shared/Modal';
import { createOrUpdateCollection } from 'src/lib/services/collections';
import { Collection, CollectionReview } from 'src/lib/types/collection';
import { ObjectUserStatus } from 'src/lib/types/objectModeration';
import convertUnderscoredText from 'src/lib/utils/convertUnderscoredText';
import { getMessageFromError } from 'src/lib/utils/error';
import { uploadToStorage } from 'src/lib/utils/upload';
import { UploadFileLine } from 'src/template/components/upload';
import { v4 } from 'uuid';

type CreateOrUpdateCollectionModalProps = {
  collection?: Collection;
  open: boolean;
  onClose: () => void;
};

export type CreateOrUpdateCollectionModalForm = {
  name: string;
  user: string;
  description: string;
  tags: string;
  humanAnalysis: CollectionReview['humanAnalysis'];
  reviewStatus: CollectionReview['reviewStatus'];
  priceType: string;
  hasCertifiedRights: boolean;
  userUuid?: string;
  cover?: string;
  // largeCover?: string;
};

export default function CreateOrUpdateCollectionModal({
  collection,
  open,
  onClose,
}: CreateOrUpdateCollectionModalProps) {
  const navigate = useNavigate();
  const { enqueueSnackbar: notify } = useSnackbar();

  const {
    register,
    getValues,
    formState: { isSubmitting },
    handleSubmit,
  } = useForm<CreateOrUpdateCollectionModalForm>({
    defaultValues: {
      name: collection?.name || '',
      user: collection?.userUuid || '',
      description: collection?.description || '',
      tags: collection?.tags?.join(',') || '',
      humanAnalysis: collection?.humanAnalysis || 'not_set',
      priceType: collection?.priceType || 'free',
      hasCertifiedRights: collection?.hasCertifiedRights || false,
    },
  });

  const [cover, setCover] = useState<any>(null);
  const [bannerImage, setBannerImage] = useState<any>(null);

  const HUMAN_ANALYSIS_OPTIONS = [
    'everywhere',
    'internal',
    'public_profile',
    'private_profile',
    'not_set',
  ];

  const REVIEW_STATUS_OPTIONS = [
    'rejected',
    'approved',
    'reviewing',
    'awaiting_review',
  ];

  const PRICE_TYPE_OPTIONS = ['free', 'paid'];

  const resetFields = () => {
    setCover(null);
    setBannerImage(null);
  };

  const onSave = async () => {
    try {
      const newCollectionUuid = v4();

      let coverFileUrl = '';
      if (cover?.length) {
        coverFileUrl = await uploadToStorage(newCollectionUuid, cover[0]);
      }
      let bannerImageUrl = '';
      if (bannerImage?.length) {
        bannerImageUrl = await uploadToStorage(
          newCollectionUuid,
          bannerImage[0]
        );
      }

      const {
        name,
        description,
        user,
        tags,
        humanAnalysis,
        reviewStatus,
        priceType,
      } = getValues();

      const newCollection: Partial<Collection> = {
        userUuid: user,
        name,
        description,
        humanAnalysis,
        priceType,
        userStatus: ObjectUserStatus.PUBLIC,
        tags: tags.split(','),
        coverFileUrl: coverFileUrl as string,
        bannerImage: bannerImageUrl as string,
      };

      const res = await createOrUpdateCollection(newCollection);

      resetFields();
      navigate(`/collections/${res.data.uuid}`);
      onClose();
    } catch (e) {
      notify(getMessageFromError(e), { variant: 'error' });
    }
  };

  return (
    <Modal open={open} onClose={onClose}>
      <Card>
        <CardHeader
          title={collection ? 'Edit Collection' : 'Create Collection'}
        />
        <form onSubmit={handleSubmit(onSave)}>
          <CardContent>
            <Stack spacing={2} mb={2} direction="row" width="full">
              <Stack spacing={2} flex={1}>
                <TextField
                  label="Collection Name"
                  required
                  {...register('name')}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <Collections />
                      </InputAdornment>
                    ),
                  }}
                />
                <TextField
                  label="User UUID"
                  {...register('user')}
                  required={collection?.blockchain === 'sticky'}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <AccountBox />
                      </InputAdornment>
                    ),
                  }}
                />
                <TextField
                  label="Description"
                  multiline
                  {...register('description')}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <Notes />
                      </InputAdornment>
                    ),
                  }}
                />
                <TextField
                  label="Tags"
                  {...register('tags')}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <LocalOffer />
                      </InputAdornment>
                    ),
                  }}
                />
                <TextField
                  label="Human Analysis"
                  required
                  select
                  defaultValue={collection?.humanAnalysis || ''}
                  {...register('humanAnalysis')}
                >
                  {HUMAN_ANALYSIS_OPTIONS.map((option) => (
                    <MenuItem key={option} value={option}>
                      {convertUnderscoredText(option)}
                    </MenuItem>
                  ))}
                </TextField>
                <TextField
                  label="Review Status"
                  required
                  select
                  defaultValue={collection?.reviewStatus || ''}
                  {...register('reviewStatus')}
                >
                  {REVIEW_STATUS_OPTIONS.map((option) => (
                    <MenuItem key={option} value={option}>
                      {convertUnderscoredText(option)}
                    </MenuItem>
                  ))}
                </TextField>
                <TextField
                  label="Free"
                  required
                  select
                  defaultValue={collection?.priceType || ''}
                  {...register('priceType')}
                >
                  {PRICE_TYPE_OPTIONS.map((option) => (
                    <MenuItem key={option} value={option}>
                      {option}
                    </MenuItem>
                  ))}
                </TextField>
              </Stack>
              <Stack spacing={2} flex={1}>
                <Stack
                  direction="row"
                  alignItems="center"
                  justifyContent="space-between"
                >
                  <Typography>Cover:</Typography>
                  <UploadFileLine file={cover} onDrop={setCover} />
                </Stack>
                <Stack
                  direction="row"
                  alignItems="center"
                  justifyContent="space-between"
                >
                  <Typography>Banner:</Typography>
                  <UploadFileLine file={bannerImage} onDrop={setBannerImage} />
                </Stack>
              </Stack>
            </Stack>
          </CardContent>
          <CardActions
            sx={{
              display: 'flex',
              justifyContent: 'space-between',
              padding: 2,
            }}
          >
            <Button
              onClick={() => {
                onClose();
                resetFields();
              }}
              color="error"
            >
              Cancel
            </Button>
            <Button
              color="secondary"
              variant="contained"
              type="submit"
              loading={isSubmitting}
            >
              Save
            </Button>
          </CardActions>
        </form>
      </Card>
    </Modal>
  );
}
