import { Container } from '@mui/material';
import SubscriptionsView from 'src/components/modules/RevenueEvents/view/Subscriptions/NewState';
import HeaderBreadcrumbs from 'src/template/components/HeaderBreadcrumbs';
import Page from 'src/template/components/Page';

export default function UsersSubscriptionsPage() {
  return (
    <Page title="Users Subscriptions">
      <Container maxWidth="xl">
        <HeaderBreadcrumbs
          heading="Users Subscriptions"
          links={[{ name: 'Subscriptions List' }]}
        />
        <SubscriptionsView />
      </Container>
    </Page>
  );
}
