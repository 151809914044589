import { DeepPartial } from 'react-hook-form';
import { PaginatedResponse } from 'src/lib/types/PaginatedResponse';
import { IncludeExcludeAll } from 'src/lib/utils/includeExcludeFilterUtils';
import { parseSearchParams } from 'src/lib/utils/parseSearchParams';
import useSWR, { SWRResponse } from 'swr';
import {
  FlaggedTransactionsRanking,
  FlaggedTransactionsTotalStatistics,
  ParsedFlaggedTransactionsRanking,
} from '../types/Ranking';
import { UserType } from '../types/UserType';

export type UserBehavior = 'flagged' | 'blocked';

type PossibleParams = {
  page: number | string;
  size: number | string;
  userType: UserType;
  userBehavior: UserBehavior;
  filter: {
    lang: string;
    country: string;
    startDate: string;
    endDate: string;
    InactiveUsers: IncludeExcludeAll;
  };
};

function parseData(
  data:
    | PaginatedResponse<
        FlaggedTransactionsRanking[],
        FlaggedTransactionsTotalStatistics
      >
    | undefined
): PaginatedResponse<
  ParsedFlaggedTransactionsRanking[],
  FlaggedTransactionsTotalStatistics
> {
  if (!data) {
    return {
      meta: {
        totalCount: 0,
        totalTransactions: 0,
      },
      body: [],
    };
  }
  return {
    meta: data.meta,
    body: data.body.map(({ transactions, ...item }) => ({
      ...item,
      transactions: Intl.NumberFormat('en-US').format(transactions),
    })),
  };
}

export function fetchFlaggedTransactionsRanking(
  params?: DeepPartial<PossibleParams>
): SWRResponse<
  PaginatedResponse<
    ParsedFlaggedTransactionsRanking[],
    FlaggedTransactionsTotalStatistics
  >
> {
  const parsedParams = parseSearchParams(params ?? {});
  const { data, ...response } = useSWR(
    `admin/users/ranking/flagged-transactions?${parsedParams}`
  );
  return {
    ...response,
    data: parseData(data),
  };
}
