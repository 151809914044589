import getAPIClient from 'src/lib/services/api/axios.config';
import { ServiceResponse } from 'src/lib/types/ServiceResponse';
import { getMessageFromError } from 'src/lib/utils/error';
import { PayoutCountry } from '../types/PayoutCountries';

type UpdatePayoutCountryData = Partial<
  Pick<PayoutCountry, 'name' | 'code' | 'active'>
>;

export async function updatePayoutCountry(
  uuid: string,
  data: UpdatePayoutCountryData
): Promise<ServiceResponse<PayoutCountry>> {
  try {
    const api = await getAPIClient();
    const res = await api.put(`admin/payout-countries/${uuid}`, data);
    return {
      data: res.data,
      error: null,
    };
  } catch (e) {
    return {
      data: null,
      error: getMessageFromError(e),
    };
  }
}
