import ConfirmationDialogRaw from 'src/components/shared/Dialog/ConfirmationDialog';
import useModalStore from 'src/lib/stores/useModalStore';
import useRemoveFromNftPack from './useView';

type Props = {
  uuid: string;
  nfts: string[];
};
export default function RemoveNftFromPackView({
  uuid,
  nfts,
}: Props): JSX.Element {
  const { handleRemoveNftFromPack } = useRemoveFromNftPack();
  const closeModal = useModalStore((state) => state.closeModal);

  return (
    <ConfirmationDialogRaw
      onClose={closeModal}
      open
      title={`Remove this nft from pack`}
      onConfirm={async () => {
        await handleRemoveNftFromPack(uuid, nfts);
      }}
    >
      Are you sure you want to remove this Nft?
    </ConfirmationDialogRaw>
  );
}
