import { Tab, Tabs, TabsProps } from '@mui/material';
import useURLSearchParams from 'src/lib/hooks/useURLSearchParams';
import { LabelValue } from 'src/lib/types/labelValue';
import useTabs, {
  Config as TabsConfig,
  initialConfig,
} from 'src/template/hooks/useTabs';

type Props = TabsProps & {
  tabs: LabelValue[];
  tabsConfig?: Partial<TabsConfig>;
  defaultTab?: string;
  keepParamOnAll?: boolean;
};
export default function MagicTableTabs({
  tabs,
  tabsConfig,
  defaultTab,
  keepParamOnAll = false,
  ...props
}: Props): JSX.Element {
  const { removeParam } = useURLSearchParams();
  const { currentTab, onChangeTab } = useTabs(defaultTab, tabsConfig);

  return (
    <Tabs
      allowScrollButtonsMobile
      variant="scrollable"
      scrollButtons="auto"
      value={currentTab}
      onChange={(e, value) => {
        if (!keepParamOnAll && value === 'all')
          removeParam(tabsConfig?.queryName ?? initialConfig.queryName);
        else onChangeTab(e, value);
      }}
      {...props}
      sx={{ px: 2, bgcolor: 'background.neutral', ...props.sx }}
    >
      {tabs.map((tab) => (
        <Tab
          disableRipple
          key={tab.value}
          label={tab.label}
          value={tab.value}
        />
      ))}
    </Tabs>
  );
}
