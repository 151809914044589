import {
  Box,
  Card,
  CardActionArea,
  CardContent,
  Icon,
  Link,
  Stack,
  Typography,
} from '@mui/material';
import { Link as RouterLink } from 'react-router-dom';
import FileDisplay from 'src/components/shared/Media/FileDisplay';
import { NFT } from 'src/lib/types/nft';
import formatStickyCoinValue from 'src/lib/utils/formatStickyCoinValue';

type NFTCardProps = {
  data: NFT;
};

export default function NFTCard({ data }: NFTCardProps) {
  return (
    <Card>
      <Link
        component={RouterLink}
        to={`/nfts/${data.uuid}`}
        underline="none"
        height="100%"
      >
        <CardActionArea
          component="div"
          sx={{
            height: '100%',
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'space-between',
          }}
        >
          <FileDisplay
            fileUrl={data.fileUrl}
            sx={{
              width: '100%',
              aspectRatio: '1 / 1',
            }}
          />
          <CardContent
            sx={{
              width: '100%',
              padding: 2,
              flex: 1,
            }}
          >
            <Stack>
              {data.name ? (
                <Typography fontWeight="bold">{data.name}</Typography>
              ) : (
                <Typography color="text.secondary" fontWeight="bold">
                  Empty Name
                </Typography>
              )}
              <Stack
                direction="column"
                alignItems="left"
                justifyContent="space-between"
                spacing={1}
              >
                <Typography color="text.secondary">
                  {data.salesLimit > data.salesCount ? (
                    `${data.salesLimit - data.salesCount}/${data.salesLimit}`
                  ) : (
                    <Stack direction="row" spacing={2}>
                      <Typography color="error">Sold Out</Typography>/
                      {data.salesLimit}
                    </Stack>
                  )}
                </Typography>
                {data.buyNow ? (
                  <Stack direction="row" alignItems="center" spacing={1}>
                    <Box
                      sx={{
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                      }}
                    >
                      <Icon fontSize="small">
                        <img src="/assets/sticky-coin.svg" alt="sticky-coin" />
                      </Icon>
                    </Box>
                    <Typography color="text.secondary">
                      {formatStickyCoinValue(data.floorPrice)}
                    </Typography>
                  </Stack>
                ) : (
                  <Typography color="text.secondary">Not For Sale</Typography>
                )}
              </Stack>
            </Stack>
          </CardContent>
        </CardActionArea>
      </Link>
    </Card>
  );
}
