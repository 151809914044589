import { useCallback, useEffect } from 'react';
import { DEFAULT_INITIAL_PAGE_SIZE } from 'src/lib/constants/pagination';
import useURLSearchParams from 'src/lib/hooks/useURLSearchParams';
import useModalStore from 'src/lib/stores/useModalStore';
import { ChannelWithAdminAnalytics } from 'src/lib/types/channelWithAdminAnalytics';
import { ChannelEmitter } from '../../events/ChannelEmitter';
import { fetchChannels } from '../../services/fetchChannel';
import CreateChannelView from '../Create';
import DeleteChannelView from '../Delete';
import { ListChannelsParams } from '../List/useView';
import UpdateChannelView from '../Update';

export default function useChannelsView() {
  const openModal = useModalStore((state) => state.openModal);

  const handleOpenCreateModal = async () => {
    openModal(<CreateChannelView />);
  };
  const handleOpenUpdateModal = async (channel: ChannelWithAdminAnalytics) => {
    openModal(<UpdateChannelView channel={channel} />);
  };
  const handleOpenDeleteModal = async (uuid: string) => {
    openModal(<DeleteChannelView uuid={uuid} />);
  };

  const { allSearchParams } = useURLSearchParams(initialParams);

  const params: ListChannelsParams = {
    page: Number(allSearchParams.page) ?? initialParams.page,
    size: Number(allSearchParams.size) ?? initialParams.size,
    sortBy: allSearchParams.sortBy ?? initialParams.sortBy,
    order: allSearchParams.order ?? initialParams.order,
    searchBy: allSearchParams.searchBy ?? initialParams.searchBy,
    query: allSearchParams.query ?? initialParams.query,
  };

  const query: any = {};
  if (allSearchParams.searchBy && allSearchParams.query) {
    query[allSearchParams.searchBy] = {
      like: `%${allSearchParams.query}%`,
    };
  }

  const { data, isLoading, mutate } = fetchChannels({
    filter: {
      ...(params.searchBy &&
        params.query && {
          [params.searchBy]: {
            like: `%${params.query}%`,
          },
        }),
      isSticky: 1,
    },
    page: params.page,
    size: params.size,
    order: params.order,
    sortBy: params.sortBy,
  });

  const refetchData = useCallback(() => {
    mutate?.();
  }, []);

  useEffect(() => {
    ChannelEmitter.on('ChannelCreated', refetchData);
    ChannelEmitter.on('ChannelUpdated', refetchData);
    ChannelEmitter.on('ChannelDeleted', refetchData);

    return () => {
      ChannelEmitter.off('ChannelCreated', refetchData);
      ChannelEmitter.off('ChannelUpdated', refetchData);
      ChannelEmitter.off('ChannelDeleted', refetchData);
    };
  }, [refetchData]);

  return {
    data,
    isLoading,
    onChangeReviewStatus: () => {},
    params,
    handleOpenCreateModal,
    handleOpenUpdateModal,
    handleOpenDeleteModal,
    // handleOpenAddAssetModal,
    // handleOpenAssetsListModal,
  };
}

const initialParams = {
  sortBy: 'updatedAt',
  order: 'desc',
  page: '0',
  size: DEFAULT_INITIAL_PAGE_SIZE.toString(),
  searchBy: 'name',
  query: '',
};
