import useNotify from 'src/lib/hooks/useNotify';
import useModalStore from 'src/lib/stores/useModalStore';
import { AssetCollectionEmitter } from '../../events/AssetCollectionEmitter';
import { DeleteAssetCollection } from '../../services/deleteAssetCollection';

export default function useDeleteAssetCollection() {
  const closeModal = useModalStore((state) => state.closeModal);
  const notify = useNotify();

  const handleDeleteAssetCollection = async (uuid: string) => {
    const { data, error } = await DeleteAssetCollection(uuid);
    if (error) {
      notify(error, { variant: 'error' });
      closeModal();
    } else {
      notify('Asset Collection deleted!', { variant: 'success' });
      closeModal();
      AssetCollectionEmitter.emit('AssetCollectionDeleted', data);
    }
  };

  return {
    handleDeleteAssetCollection,
  };
}
