import { DateRangeQuery } from './types';

export const getDateRange = (
  endDate: Date,
  rangeInDays: number
): DateRangeQuery => ({
  startDate: new Date(
    endDate.getFullYear(),
    endDate.getMonth(),
    endDate.getDate() - rangeInDays
  ).getTime(),
  endDate: endDate.getTime(),
});
