import HeaderBreadcrumbs from 'src/template/components/HeaderBreadcrumbs';
import GameRatingsTable from '../../components/GameRatingsTable/GameRatingsTable';
import { useGameRatings } from '../../components/GameRatingsTable/useGameRatingsTable';

interface GameRatingsProps {
  showHeader?: boolean;
}

export default function GameRatings({ showHeader = true }: GameRatingsProps) {
  const { data, isLoading, params, gameUuid, userUuid } = useGameRatings();
  return (
    <>
      {showHeader && (
        <HeaderBreadcrumbs
          heading="Game Ratings"
          links={[{ name: 'Game Ratings' }]}
        />
      )}
      <GameRatingsTable
        data={data?.body || []}
        totalCount={data?.meta.totalCount || 0}
        isLoading={isLoading}
        params={params}
        showGameFilter={!gameUuid}
        showUserFilter={!userUuid}
      />
    </>
  );
}
