export type FormReviewStatus = {
  uuid: string;

  formUuid: string;
  reviewerUuid?: string | null;
  reviewerComment?: string | null;
  status: SubmissionFormStatuses;

  createdAt: Date;
  updatedAt: Date;
};

export type FormReviewStatusCreation = Omit<
  FormReviewStatus,
  'uuid' | 'createdAt' | 'updatedAt' | 'deletedAt'
>;

export enum SubmissionFormStatuses {
  AWAITING_REVIEW = 'awaiting_review',
  REVIEW_REJECTED = 'review_rejected',
  REVIEW_APPROVED = 'review_approved',
}
