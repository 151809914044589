import { Build } from '@mui/icons-material';
import {
  Button,
  Card,
  CardContent,
  IconButton,
  Stack,
  TableCell,
  TableRow,
  Typography,
} from '@mui/material';
import { Navigate, useParams } from 'react-router';
import SmartTable from 'src/components/shared/SmartTable/SmartTable';
import useNotify from 'src/lib/hooks/useNotify';
import {
  fetchAppleReceipt,
  fixAllIaps,
  fixIap,
} from 'src/lib/services/iapTransactions';
import { IAP } from 'src/lib/types/iapTransaction';
import { formatDate } from 'src/lib/utils/formatters';
import Routes from 'src/routes/Routes';
import HeaderBreadcrumbs from 'src/template/components/HeaderBreadcrumbs';

export default function IapAppleReceipt() {
  const { uuid } = useParams<'uuid'>();
  if (!uuid) return <Navigate to={Routes.iap} replace />;
  const { data: receipt, loading } = fetchAppleReceipt(uuid);

  const notify = useNotify();

  const metaInfo = {
    'Environment: ': receipt?.isSandbox ? 'Production' : 'Sandbox',
    ...(receipt?.isFixable
      ? { 'Purchases to Fix: ': receipt?.iapsWithoutTransaction || 0 }
      : {}),
    'Receipt Creation Date: ': formatDate(Number(receipt?.creationDate)),
  };

  const handleFixIap = async (iap: IAP) => {
    const res = await fixIap(uuid, iap);

    if (res.error) {
      notify(res.error.message, { variant: 'error' });
    } else {
      notify(
        `Iap fixed successfully (${res.data ? 1 : 0} transaction created)`,
        {
          variant: 'success',
        }
      );
    }
  };

  const handleFixAllIaps = async () => {
    const res = await fixAllIaps(uuid);

    if (res.error) {
      notify(res.error.message, { variant: 'error', autoHideDuration: 3000 });
    } else {
      notify(`Iaps fixed successfully (${res.total} transactions created)`, {
        variant: 'success',
      });
    }
  };

  return (
    <>
      <HeaderBreadcrumbs
        heading="Apple Receipt"
        links={[
          { name: 'Iap Transactions', href: '/iap' },
          { name: `${uuid}/apple-receipt` || 'Empty Name' },
        ]}
        action={
          receipt?.isFixable && (
            <Button
              variant="contained"
              color="info"
              fullWidth
              disabled={!receipt?.iapsWithoutTransaction}
              startIcon={<Build />}
              onClick={handleFixAllIaps}
            >
              Fix all purchases without transaction
            </Button>
          )
        }
      />

      <Stack spacing={3}>
        <Card>
          <CardContent>
            <Stack direction="row" spacing={5}>
              {Object.entries(metaInfo).map(([key, value]) => (
                <Typography>
                  {key}
                  <Typography
                    component="span"
                    fontWeight="bold"
                    color="info.dark"
                  >
                    {value}
                  </Typography>
                </Typography>
              ))}
            </Stack>
          </CardContent>
        </Card>
        <SmartTable
          data={receipt?.inApp}
          loading={loading}
          total={receipt?.inApp?.length || 0}
          columns={[
            {
              id: 'purchaseDate',
              label: 'Date',
              isSortable: true,
            },
            {
              id: 'productId',
              label: 'Product',
              isSortable: true,
            },
            {
              id: 'quantity',
              label: 'Quantity',
              isSortable: true,
            },
            {
              id: 'isTrialPeriod',
              label: 'Is Trial',
              isSortable: true,
            },
            {
              id: 'isInIntroOfferPeriod',
              label: 'Is In Intro Offer',
              isSortable: true,
            },
            {
              id: 'transaction',
              label: 'Have Transaction',
              isSortable: false,
            },
          ]}
          filter={{
            orderBy: 'createdAt',
            order: 'desc',
            searchFor: 'purchaseDate',
            query: '',
            page: 0,
            size: receipt?.inApp?.length || 0,
            custom: {},
          }}
          setFilter={() => {}}
          searchForOptions={[]}
        >
          {receipt?.inApp?.map((iap) => (
            <TableRow key={iap?.purchaseDate} hover>
              <TableCell>{formatDate(Number(iap?.purchaseDate))}</TableCell>
              <TableCell>{iap?.productId || '-'}</TableCell>
              <TableCell>{iap?.quantity || '-'}</TableCell>
              <TableCell>{iap?.isTrialPeriod || '-'}</TableCell>
              <TableCell>{iap?.isInIntroOfferPeriod || '-'}</TableCell>
              <TableCell>{iap?.transaction ? 'true' : 'false'}</TableCell>
              <TableCell align="right">
                {receipt.isFixable && !iap?.transaction && (
                  <IconButton
                    onClick={() =>
                      handleFixIap({
                        ...(iap ?? {}),
                        isSandbox: receipt.isSandbox,
                      })
                    }
                  >
                    <Build />
                  </IconButton>
                )}
              </TableCell>
            </TableRow>
          ))}
        </SmartTable>
      </Stack>
    </>
  );
}
