import { Container } from '@mui/material';
import ConsumableDetailsView from 'src/components/modules/RevenueEvents/view/Consumables/Details';
import Routes from 'src/routes/Routes';
import HeaderBreadcrumbs from 'src/template/components/HeaderBreadcrumbs';
import Page from 'src/template/components/Page';

export default function ConsumablePage(): JSX.Element {
  return (
    <Page title="Consumable">
      <Container maxWidth="xl">
        <HeaderBreadcrumbs
          heading="Consumable"
          links={[
            { name: 'Consumables List', href: Routes.consumables_sales_list() },
            { name: 'Consumable Details' },
          ]}
        />
        <ConsumableDetailsView />
      </Container>
    </Page>
  );
}
