import { Autocomplete, TextField } from '@mui/material';
import { GridColumns, GridSortDirection } from '@mui/x-data-grid';
import { format } from 'date-fns';
import Flex from 'src/components/shared/Flex';
import MagicTable from 'src/components/shared/MagicTable/MagicTable';
import useURLSearchParams from 'src/lib/hooks/useURLSearchParams';
import Label from 'src/template/components/Label';
import { System, SystemLabel, SystemUptime } from '../../../types/SystemUptime';

type Props = {
  page: number;
  size: number;
  data: SystemUptime[];
  isLoading: boolean;
  totalCount: number;
  sortBy: string;
  sortOrder: string;
};
export default function SystemUptimeTable({
  page,
  size,
  data,
  isLoading,
  totalCount,
  sortBy,
  sortOrder,
}: Props): JSX.Element {
  const { addParam, removeParams, removeParam, allSearchParams } =
    useURLSearchParams();
  return (
    <MagicTable.Container>
      <MagicTable.Tabs
        tabs={statuses}
        defaultTab="all"
        tabsConfig={{
          queryName: 'status',
        }}
      />
      <Flex gap={2} p={2}>
        <Autocomplete
          options={Object.keys(SystemLabel)}
          fullWidth
          renderInput={(params) => <TextField label="System" {...params} />}
          defaultValue={allSearchParams.systemName as System}
          getOptionLabel={(p) => SystemLabel[p as System]}
          onChange={(_, value) => {
            if (value) addParam('systemName', value);
            else removeParam('systemName');
          }}
        />
      </Flex>
      <MagicTable.Wrapper>
        <MagicTable
          loading={isLoading}
          columns={columns}
          pageSize={size}
          page={page}
          rowCount={totalCount}
          paginationMode="server"
          onPageChange={(newPage) => {
            addParam('page', newPage);
          }}
          onPageSizeChange={(newSize) => {
            addParam('size', newSize);
          }}
          onSortModelChange={(model) => {
            const { field, sort } = model[0];
            if (field && sort) {
              addParam('sortOrder', sort);
              addParam('sortBy', field);
            } else {
              removeParams(['sortBy', 'sortOrder']);
            }
          }}
          sortModel={[{ field: sortBy, sort: sortOrder as GridSortDirection }]}
          getRowId={(r) => r.uuid}
          rows={data}
        />
      </MagicTable.Wrapper>
    </MagicTable.Container>
  );
}

const statuses = [
  {
    value: 'all',
    label: 'All',
  },
  {
    value: 'UP',
    label: 'Active',
  },
  {
    value: 'DOWN',
    label: 'Inactive',
  },
];

const columns: GridColumns<SystemUptime> = [
  {
    field: 'status',
    headerName: 'Status',
    flex: 1 / 4,
    sortable: false,
    renderCell: ({ value }) => (
      <Label
        variant="ghost"
        color={value === 'UP' ? 'success' : 'error'}
        sx={{ textTransform: 'capitalize' }}
      >
        {value === 'UP' ? 'Active' : 'Inactive'}
      </Label>
    ),
  },
  {
    field: 'systemName',
    headerName: 'System',
    sortingOrder: ['asc', 'desc'],
    sortComparator: () => 0,
    renderCell: ({ value }) => SystemLabel[value as System],
  },
  {
    field: 'startTime',
    headerName: 'Started At',
    sortingOrder: ['asc', 'desc'],
    sortComparator: () => 0,
    renderCell: ({ value }) => format(value, 'dd/MM/yyyy · HH:mm'),
  },
  {
    field: 'lastCheck',
    headerName: 'Last Check',
    sortingOrder: ['asc', 'desc'],
    sortComparator: () => 0,
    renderCell: ({ value }) => format(value, 'dd/MM/yyyy · HH:mm'),
  },
  {
    field: 'endTime',
    headerName: 'Ended At',
    sortingOrder: ['asc', 'desc'],
    sortComparator: () => 0,
    renderCell: ({ value }) =>
      value ? format(value, 'dd/MM/yyyy · HH:mm') : '--',
  },
];
