import createUseTable from 'src/lib/utils/createUseTable';

export type OrderByOptions =
  | 'code'
  | 'name'
  | 'productFullId'
  | 'androidStickyValue'
  | 'webStickyValue'
  | 'iosStickyValue';
export type SearchForOptions =
  | 'code'
  | 'name'
  | 'productFullId'
  | 'androidStickyValue'
  | 'webStickyValue'
  | 'iosStickyValue';

const useRegionsTable = createUseTable<OrderByOptions, SearchForOptions>({
  searchFor: 'code',
  order: 'asc',
  orderBy: 'code',
  query: '',
  page: 0,
  size: 10,
});

export default useRegionsTable;
