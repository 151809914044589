import { WithProduct } from 'src/lib/types/product';
import { WithOptionalUser } from 'src/lib/types/users';
import { getMessageFromError } from 'src/lib/utils/error';
import useSWR, { SWRResponse } from 'swr';
import { WithAppleNotification } from '../../../../lib/types/AppleNotification';
import { RevenueEvent, WithRevenueEventPools } from '../types/RevenueEvent';

export type SubscriptionDetailsResponse = {
  body: Array<
    WithRevenueEventPools<
      WithAppleNotification<WithProduct<WithOptionalUser<RevenueEvent>>>
    >
  >;
  meta: {
    paymentCount: number;
  };
};

export function fetchSubscription(
  originalTransactionId: string,
  userUuid: string
): SWRResponse<SubscriptionDetailsResponse> {
  const { error, ...response } = useSWR(
    `admin/revenue-events/related/${originalTransactionId}${
      userUuid ? `/${userUuid}` : ''
    }?size=10000`
  );

  return {
    ...response,
    error: error ? getMessageFromError(error) : undefined,
  };
}
