import { Container } from '@mui/material';
import Routes from 'src/routes/Routes';
import HeaderBreadcrumbs from 'src/template/components/HeaderBreadcrumbs';
import GamesSessionsTable from '../../components/GamesSessionsTable/GamesSessionsTable';
import useGamesSessions from './useView';

interface IGamesSessionsProps {
  showHeader?: boolean;
}

export default function GamesSessions({
  showHeader = true,
}: IGamesSessionsProps) {
  const { data, isLoading, params, gameUuid, userUuid } = useGamesSessions();
  return (
    <Container maxWidth="xl">
      {showHeader && (
        <HeaderBreadcrumbs
          heading="Games Sessions"
          links={[
            ...(gameUuid
              ? [{ name: 'Games', href: Routes.game_center.games }]
              : []),
            { name: 'Games Sessions' },
          ]}
        />
      )}
      <GamesSessionsTable
        data={data?.rows ?? []}
        totalCount={data?.count ?? 0}
        isLoading={isLoading}
        params={params}
        showGameFilter={!gameUuid}
        showUserFilter={!userUuid}
      />
    </Container>
  );
}
