import { Stack, Typography } from '@mui/material';
import Flex from 'src/components/shared/Flex';
import { SGMChat } from 'src/lib/types/sgmChatMessage';
import Image from 'src/template/components/Image';

interface ChatHeaderProps {
  chatInfo: SGMChat;
}

export default function ChatHeader({ chatInfo }: ChatHeaderProps) {
  return (
    <Flex
      width="100%"
      alignItems="center"
      justifyContent="flex-start"
      borderBottom="1px solid rgba(255, 255, 255, 0.1)"
      py={3}
      bgcolor="rgba(0, 0, 0, 0.8)"
      position="relative"
      top="0"
      zIndex="2"
    >
      <Flex
        justifyContent="space-between"
        alignItems="center"
        width="full"
        maxWidth={['full', '500px']}
        px={4}
      >
        <Flex gap={2.5} alignItems="center">
          <Image
            src={
              chatInfo?.metadata?.icon || '/static/img/game/ai-game-maker.png'
            }
            alt={chatInfo?.metadata?.title || 'AI Game Maker'}
            sx={{
              width: 35,
              height: 35,
              borderRadius: '8px',
            }}
          />
          <Stack lineHeight={1.7} spacing={0} textAlign="left">
            <Typography color="white" fontWeight={700} fontSize="14px">
              {chatInfo?.metadata?.title || 'AI Game Maker'}
            </Typography>
            <Typography
              color="rgba(255, 255, 255, 0.5)"
              fontWeight={500}
              fontSize="12px"
            >
              In Development
            </Typography>
          </Stack>
        </Flex>
      </Flex>
    </Flex>
  );
}
