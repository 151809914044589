/* eslint-disable no-await-in-loop */
/* eslint-disable no-restricted-syntax */
import { getDownloadURL, ref, uploadBytesResumable } from 'firebase/storage';
import { firebaseStorage } from 'src/template/contexts/FirebaseContext';
import { v4 as uuidv4 } from 'uuid';

export const uploadToStorage = (
  folder: string,
  file: Blob | File,
  fileName?: string
): Promise<string> =>
  new Promise((resolve) => {
    let fileType = '';
    if (file instanceof Blob) {
      fileType = `.${file?.type.split('/')[1]}`;
    }
    if (file instanceof File) {
      fileType = `.${file?.name.split('.').pop()}`;
    }

    const imagesRef = ref(
      firebaseStorage,
      `${folder}/${fileName ?? uuidv4()}${fileType}`
    );

    const metadata = {
      contentType: file?.type,
    };

    const uploadTask = uploadBytesResumable(imagesRef, file!, metadata);

    // Listen for state changes, errors, and completion of the upload.
    uploadTask.on(
      'state_changed',
      () => {},
      () => {},
      () => {
        // Upload completed successfully, now we can get the download URL
        getDownloadURL(uploadTask.snapshot.ref).then((downloadURL) => {
          resolve(downloadURL);
        });
      }
    );
  });

export const uploadMultipleFiles = async (folder: string, files: File[]) => {
  const urls = await Promise.all(
    files.map((file) => uploadToStorage(folder, file))
  );
  return files.map((file, idx) => {
    const { name, type, size } = file;
    return { url: urls[idx], name, type, size };
  });
};

export const upload = (
  folder: string,
  file: File,
  isResize: boolean,
  maxsize: number
) =>
  new Promise((resolve) => {
    if (isResize) {
      const image = new Image();
      image.onload = () => {
        const canvas = document.createElement('canvas');
        const context = canvas.getContext('2d');

        let w = image.width || maxsize;
        let h = image.height || maxsize;
        const resize = maxsize;

        if (image.width > resize || image.height > resize) {
          if (image.height > image.width) {
            h = resize;
            w = (resize * image.width) / image.height;
          } else {
            w = resize;
            h = (resize * image.height) / image.width;
          }
        }

        canvas.width = w;
        canvas.height = h;

        if (context)
          context.drawImage(
            image,
            0,
            0,
            image.width,
            image.height,
            0,
            0,
            canvas.width,
            canvas.height
          );

        canvas.toBlob((blob) => {
          if (blob) {
            uploadToStorage(folder, blob).then((downloadUrl) => {
              resolve(downloadUrl);
            });
          }
        }, file.type);
      };

      image.src = URL.createObjectURL(file);
    } else {
      const image = new Blob([file], { type: file.type });
      uploadToStorage(folder, image).then((downloadUrl) =>
        resolve(downloadUrl)
      );
    }
  });
