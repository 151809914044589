import { Box, Container, Stack } from '@mui/material';
import Flex from 'src/components/shared/Flex';
import HeaderBreadcrumbs from 'src/template/components/HeaderBreadcrumbs';
import { HomeHealthCheck } from '../../components/HealthCheck/Home';
import PoolsStatus from '../../components/PoolsStatus';
import WalletsBalanceView from '../../components/WalletsBalance';

export default function HomeView(): JSX.Element {
  return (
    <Container maxWidth="xl">
      <HeaderBreadcrumbs heading="Sticky Admin" links={[{ name: 'Home' }]} />
      <Stack spacing={3}>
        <Flex gap={3} justifyContent="stretch" alignItems="flex-start">
          <Box flex={1}>
            <HomeHealthCheck />
          </Box>
          <Box flex={1}>
            <WalletsBalanceView />
          </Box>
        </Flex>
        {/* <ConsistenceStatus /> */}
        <PoolsStatus />
      </Stack>
    </Container>
  );
}
