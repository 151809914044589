import create from 'zustand';

interface ISelectStore {
  selected: string[];

  toggleSelected: (uuid: string) => void;
  toggleSelectAll: (selected: string[]) => void;
}

const createSelectStore = () =>
  create<ISelectStore>((set) => ({
    selected: [],

    toggleSelected: (uuid) =>
      set((state) => ({
        selected: state.selected.includes(uuid)
          ? state.selected.filter((id) => id !== uuid)
          : [...state.selected, uuid],
      })),
    toggleSelectAll: (ids: string[]) => {
      set((state) => ({
        selected: state.selected.length > 0 ? [] : ids,
      }));
    },
  }));

export default createSelectStore;
