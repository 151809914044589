import CloseIcon from '@mui/icons-material/Close';
import { Button, Card, IconButton, Stack } from '@mui/material';
import { grey } from '@mui/material/colors';
import Modal from 'src/components/shared/Modal';
import { HomeFeed } from 'src/lib/types/homeFeed';

interface DeleteFeedModalProps {
  open: boolean;
  feedToDelete: HomeFeed | null;
  onDelete: () => void;
  onCancel: () => void;
}
function DeleteFeedModal({
  open,
  feedToDelete,
  onDelete,
  onCancel,
}: DeleteFeedModalProps) {
  return (
    <Modal open={open} onClose={onCancel}>
      <Card
        sx={{
          p: 3,
          position: 'relative',
        }}
      >
        <Stack direction="column" spacing={4}>
          <IconButton
            aria-label="Close modal"
            sx={{ position: 'absolute', top: 0, right: 0 }}
            onClick={onCancel}
          >
            <CloseIcon />
          </IconButton>
          <p style={{ color: grey[700] }}>
            {`Are you sure you want to delete the feed "${feedToDelete?.name}"?`}{' '}
            <br />
            this operation <b>cannot be undone!</b>
          </p>
          <Stack direction="row" spacing={2} justifyContent="end">
            <Button sx={{ color: grey[500] }} onClick={onCancel}>
              cancel
            </Button>
            <Button color="error" variant="contained" onClick={onDelete}>
              Delete
            </Button>
          </Stack>
        </Stack>
      </Card>
    </Modal>
  );
}
export default DeleteFeedModal;
