import useNotify from 'src/lib/hooks/useNotify';
import useModalStore from 'src/lib/stores/useModalStore';
import { StatisticGroupFormValues } from '../../components/Forms/StatisticGroup/types';
import { GameStatisticGroupEmitter } from '../../events/GameStatisticGroupEmitter';
import { CreateStatisticGroup } from '../../services/createStatisticGroup';

export default function useCreateStatisticGroupView() {
  const closeModal = useModalStore((state) => state.closeModal);
  const notify = useNotify();

  const handleCreateStatisticGroup = async (
    values: StatisticGroupFormValues
  ) => {
    const createData =
      values.type === 'STATISTIC_LIST'
        ? {
            name: values.name,
            type: values.type,
            statisticsList: values.statisticsList.map(
              (statistic) => statistic.uuid
            ),
          }
        : {
            name: values.name,
            actionUuid: values.actionUuid,
            typeUuid: values.typeUuid,
            type: values.type,
          };
    const { error, data } = await CreateStatisticGroup({ ...createData });

    if (error) {
      notify(error, { variant: 'error' });
      closeModal();
    } else if (data) {
      notify('Contest Created!', { variant: 'success' });
      closeModal();

      GameStatisticGroupEmitter.emit('GameStatisticGroupCreated', data);
    }
  };

  return {
    handleCreateStatisticGroup,
  };
}
