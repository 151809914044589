import GameRankings from 'src/components/modules/GameRankings/view/GameRankings';
import Page from 'src/template/components/Page';

export default function GameRankingsPage() {
  return (
    <Page title="Game Rankings">
      <GameRankings />
    </Page>
  );
}
