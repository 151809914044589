import getAPIClient from 'src/lib/services/api/axios.config';
import { ServiceResponse } from 'src/lib/types/ServiceResponse';
import { Channel } from 'src/lib/types/channel';
import { linksToCreate } from 'src/lib/types/objectLink';
import { getMessageFromError } from 'src/lib/utils/error';

export type ChannelCreationData = Pick<
  Channel,
  'name' | 'description' | 'type'
> & {
  userUuid: string;
  objectLinks?: linksToCreate[];
};

export async function CreateChannel(
  data: ChannelCreationData
): Promise<ServiceResponse<Channel>> {
  try {
    const api = await getAPIClient();
    const res = await api.post(`admin/channels/`, data);
    return {
      data: res.data,
      error: null,
    };
  } catch (e) {
    return {
      data: null,
      error: getMessageFromError(e),
    };
  }
}
