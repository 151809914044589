import { GridColumns } from '@mui/x-data-grid';
import MagicTableUserCell from 'src/components/shared/MagicTable/UserCell';
import { UserSearch } from 'src/lib/types/UserSearch';
import { User } from 'src/lib/types/users';
import { formatUTCDate } from 'src/lib/utils/formatUTCDate';

export const tableColumns: GridColumns<UserSearch> = [
  {
    field: 'user',
    headerName: 'User',
    renderCell: ({ row }) => MagicTableUserCell({ user: row.user as User }),
    sortable: false,
  },
  {
    field: 'rawSearch',
    headerName: 'Search term',
    sortable: true,
  },
  {
    field: 'createdAt',
    headerName: 'Searched At',
    sortable: true,
    valueFormatter({ value }) {
      return value ? formatUTCDate(value) : '-';
    },
  },
];
