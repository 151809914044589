import { Info, RemoveRedEye } from '@mui/icons-material';
import {
  Alert,
  Badge,
  Box,
  Card,
  CardContent,
  Divider,
  IconButton,
  Stack,
  Tooltip,
  Typography,
} from '@mui/material';
import { GridColumns } from '@mui/x-data-grid';
import { capitalCase } from 'change-case';
import { ReactNode, useMemo } from 'react';
import Flex from 'src/components/shared/Flex';
import Link from 'src/components/shared/Link';
import MagicTable from 'src/components/shared/MagicTable/MagicTable';
import useMagicTable from 'src/components/shared/MagicTable/useMagicTable';
import SectionDivider from 'src/components/shared/SectionDivider';
import { formatUTCDate } from 'src/lib/utils/formatUTCDate';
import getUserName from 'src/lib/utils/getUserName';
import Routes from 'src/routes/Routes';
import Label from 'src/template/components/Label';
import LoadingScreen from 'src/template/components/LoadingScreen';
import { ColorByRevenueEventType } from '../../../constants/ColorByRevenueEventType';
import { SubscriptionDetailsResponse } from '../../../services/fetchSubscription';
import { RevenueEventType } from '../../../types/RevenueEvent';
import { getPurchaseDescription } from '../../../utils/getPurchaseDescription';
import useSubscriptionDetailView from './useView';

type LabelValueProps = {
  label: ReactNode;
  value: ReactNode;
};
function LabelValue({ label, value }: LabelValueProps) {
  return (
    <Flex justifyContent="space-between">
      <Typography component="span" fontWeight={600}>
        {label}
      </Typography>
      <Typography component="span">{value}</Typography>
    </Flex>
  );
}

export default function SubscriptionDetail(): JSX.Element {
  const magicTableProps = useMagicTable();
  const { purchase, isLoading, error, showRelatedPools } =
    useSubscriptionDetailView();

  const columns: GridColumns<SubscriptionDetailsResponse['body'][number]> =
    useMemo(
      () => [
        {
          field: 'productId',
          headerName: 'Product',
          sortable: false,
          flex: 1.5,
          renderCell({ row }) {
            if (row.product.name)
              return (
                <Stack>
                  <Typography>{row.product.name}</Typography>
                  <Typography fontSize="12px" color="GrayText">
                    {row.product.productId}
                  </Typography>
                </Stack>
              );
            return row.product.productId;
          },
        },
        {
          field: 'notificationType',
          headerName: 'Event Type',
          flex: 1,
          renderCell({ row }) {
            const { appleNotification, eventType } = row;
            return (
              <Tooltip
                title={
                  <>
                    <b>
                      TYPE: {appleNotification?.notificationType ?? eventType}
                    </b>
                    <br />
                    {appleNotification?.subtype && (
                      <b>SUBTYPE: {appleNotification.subtype ?? 'N/A'}</b>
                    )}
                    <br />
                    {getPurchaseDescription(
                      appleNotification?.notificationType ?? eventType,
                      appleNotification?.subtype
                    )}
                  </>
                }
                arrow
              >
                <Box>
                  <Label color={ColorByRevenueEventType[row.eventType]}>
                    <Info
                      sx={{
                        fontSize: '14px',
                        mr: 0.5,
                      }}
                    />
                    {capitalCase(row.eventType)}
                  </Label>
                </Box>
              </Tooltip>
            );
          },
        },
        {
          field: 'tags',
          headerName: 'Tags',
          flex: 1 / 1.5,
          sortable: false,
          renderCell({ row }) {
            const tags: ReactNode[] = [];
            if (row.environment === 'Sandbox') {
              tags.push(
                <Label color={'warning'} key="warning">
                  Sandbox
                </Label>
              );
            }
            if (
              row.isFreeTrial &&
              (row.eventType === RevenueEventType.RENEW ||
                row.eventType === RevenueEventType.BUY)
            ) {
              tags.push(
                <Label color="info" key="info">
                  Free Trial
                </Label>
              );
            }

            return <Flex gap={1}>{tags.map((t) => t)}</Flex>;
          },
        },
        {
          field: 'eventDate',
          headerName: 'Event Date',
          renderCell({ row }) {
            return formatUTCDate(row.eventDate, { second: '2-digit' });
          },
        },
        {
          field: 'subscriptionStartDate',
          headerName: 'Start Date',
          renderCell({ row }) {
            return formatUTCDate(row.subscriptionStartDate, {
              second: '2-digit',
            });
          },
        },
        {
          field: 'subscriptionExpiresDate',
          headerName: 'Expiration Date',
          renderCell({ row }) {
            if (row.subscriptionExpiresDate) {
              return formatUTCDate(row.subscriptionExpiresDate, {
                second: '2-digit',
              });
            }
            return '--';
          },
        },
        {
          field: 'relatedPools',
          headerName: 'Related Pools',
          sortable: false,
          flex: 0.5,
          align: 'center',
          renderCell({ row }) {
            const pools: string[] = [];
            if (row.transactionPoolUuid) pools.push(row.transactionPoolUuid);
            row.revenueEventPools.forEach((pool) => {
              pools.push(pool.transactionPoolUuid);
            });

            return (
              <Badge
                badgeContent={pools.length}
                overlap="circular"
                color="info"
              >
                <IconButton
                  onClick={() => {
                    showRelatedPools(row.uuid);
                  }}
                >
                  <RemoveRedEye />
                </IconButton>
              </Badge>
            );
          },
        },
      ],
      []
    );

  if (isLoading || !purchase) {
    return <LoadingScreen />;
  }
  if (error) {
    return <Alert severity="error">{error}</Alert>;
  }

  const { originalPurchaseDate } = purchase.renewals[0];

  const subscriptionIsActive = purchase.isActive;

  const isAutoRenewalActive: boolean = purchase.isAutoRenewalEnabled;

  return (
    <Stack spacing={2}>
      <Card
        sx={{
          maxWidth: '50%',
        }}
      >
        <CardContent>
          <Stack divider={<Divider />} spacing={1}>
            <LabelValue
              label="Subscription Status"
              value={
                <Label color={subscriptionIsActive ? 'success' : 'error'}>
                  {subscriptionIsActive ? 'Active' : 'Expired'}
                </Label>
              }
            />
            <LabelValue
              label="Auto Renewal Status"
              value={
                <Label color={isAutoRenewalActive ? 'success' : 'error'}>
                  {isAutoRenewalActive ? 'Active' : 'Disabled'}
                </Label>
              }
            />
            {purchase.user && (
              <LabelValue
                label="User"
                value={
                  <Flex gap={1}>
                    <Link to={Routes.user(purchase.user.uuid)}>
                      {getUserName(purchase.user)}
                    </Link>
                    <Label color={purchase.user.active ? 'success' : 'error'}>
                      {purchase.user.active ? 'Active' : 'Inactive'}
                    </Label>
                  </Flex>
                }
              />
            )}
            <LabelValue
              label="Original Purchase Date"
              value={formatUTCDate(originalPurchaseDate)}
            />
            <LabelValue label="Payment Count" value={purchase.paymentCount} />
            {purchase.renewals[0].user && (
              <LabelValue
                label="Country"
                value={purchase.renewals[0].user.region}
              />
            )}
          </Stack>
        </CardContent>
      </Card>
      <SectionDivider section="Dates are in UTC timezone" />

      <MagicTable.Container>
        <MagicTable
          {...magicTableProps}
          columns={columns}
          rows={purchase.renewals}
          initialState={{
            sorting: {
              sortModel: [{ sort: 'desc', field: 'eventDate' }],
            },
          }}
        />
      </MagicTable.Container>
    </Stack>
  );
}
