import ChatDetails from 'src/components/modules/Chat/view/Details';
import Page from 'src/template/components/Page';

export default function ChatPage() {
  return (
    <Page title="Chat">
      <ChatDetails />
    </Page>
  );
}
