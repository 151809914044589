import getAPIClient from 'src/lib/services/api/axios.config';
import { getMessageFromError } from 'src/lib/utils/error';

export const downloadGamesCSV = async (): Promise<any> => {
  try {
    const api = await getAPIClient();
    const res = await api.get(`admin/game/generate-csv-active-games`);

    const blob = new Blob([res.data], { type: 'text/csv' });
    const url = window.URL.createObjectURL(blob);
    const a = document.createElement('a');
    a.style.display = 'none';
    a.href = url;
    a.download = 'sticky-games.csv';
    document.body.appendChild(a);
    a.click();
    window.URL.revokeObjectURL(url);
    document.body.removeChild(a);

    return null;
  } catch (e) {
    return getMessageFromError(e);
  }
};
