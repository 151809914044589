import getAPIClient from 'src/lib/services/api/axios.config';
import { getMessageFromError } from 'src/lib/utils/error';

export async function banUserFromClub(userUuid: string, clubUuid: string) {
  try {
    const api = await getAPIClient();
    const res = await api.post(`clubs/ban-user`, {
      userUuid,
      clubUuid,
    });
    return {
      data: res.data,
      error: null,
    };
  } catch (e) {
    return {
      data: null,
      error: getMessageFromError(e),
    };
  }
}
