import { Box } from '@mui/material';
import DeviceRankTableView from '../../../components/Rankings/DeviceRankTable';
import useDevicesView from './useView';

export default function TopDevicesView(): JSX.Element {
  const { data, isLoading } = useDevicesView();
  return (
    <Box mx={2}>
      <DeviceRankTableView data={data ?? []} isLoading={isLoading} />
    </Box>
  );
}
