import { useCallback, useEffect } from 'react';
import { useParams } from 'react-router';
import useModalStore from 'src/lib/stores/useModalStore';
import useTabs from 'src/template/hooks/useTabs';
import { ClubEmitter } from '../../events/ClubEmitter';
import { fetchClub } from '../../services/fetchClub';
import BanUserView from '../BanUser';
import UnbanUserView from '../UnbanUser';
import { ClubTabs } from './clubTabs';

const DEFAULT_TAB = ClubTabs.MEMBERS;

export default function useClubDetails() {
  const { clubUuid } = useParams<'clubUuid'>();

  const openModal = useModalStore((state) => state.openModal);
  const { currentTab, onChangeTab } = useTabs(DEFAULT_TAB, {
    resetOnChange: true,
  });

  const handleBanUserFromClub = async (userUuid: string) => {
    openModal(<BanUserView clubUuid={clubUuid!} userUuid={userUuid} />);
  };

  const handleUnbanUserFromClub = async (userUuid: string) => {
    openModal(<UnbanUserView clubUuid={clubUuid!} userUuid={userUuid} />);
  };

  const { data, isLoading, mutate: mutateDetails } = fetchClub(clubUuid!);

  const refetchDetailsData = useCallback(() => {
    mutateDetails?.();
  }, []);

  useEffect(() => {
    ClubEmitter.on('UserBanned', refetchDetailsData);
    ClubEmitter.on('UserUnbanned', refetchDetailsData);

    return () => {
      ClubEmitter.off('UserBanned', refetchDetailsData);
      ClubEmitter.off('UserUnbanned', refetchDetailsData);
    };
  }, [refetchDetailsData]);

  return {
    club: data,
    isLoading,
    currentTab,
    onChangeTab,
    handleBanUserFromClub,
    handleUnbanUserFromClub,
  };
}
