/* eslint-disable import/prefer-default-export */
import { SubmissionFormStatuses } from 'src/lib/types/formReviewStatus';
import { LabelColor } from 'src/template/components/Label';
/**
 * Get label color based on payment request status
 * @param {ReviewStatus} status payment request status
 * @returns {LabelColor} label color
 */
export const getReviewLabelColor = (
  status: SubmissionFormStatuses
): LabelColor => {
  switch (status) {
    case SubmissionFormStatuses.REVIEW_APPROVED:
      return 'success';
    case SubmissionFormStatuses.REVIEW_REJECTED:
      return 'error';
    case SubmissionFormStatuses.AWAITING_REVIEW:
      return 'warning';
    default:
      return 'secondary';
  }
};
