// eslint-disable-next-line import/no-cycle
import { ObjectLink } from './objectLink';
import { WithObjectModerationProps } from './objectModeration';
import { User } from './users';
import { WithTimestamp } from './WithTimestamp';

export enum ChannelType {
  PERSONAL = 'personal',
  SECONDARY = 'secondary',
}

export enum ChannelStatus {
  ACTIVE = 'active',
  ARCHIVED_BY_USER = 'archived-by-user',
  CLOSED_BY_ADMIN = 'closed-by-admin',
}
export enum ChannelReviewStatus {
  APPROVED = 'approved',
  REJECTED = 'rejected',
  UNREVIEWED = 'unreviewed',
  REVALIDATE = 'revalidate',
}

export type Channel = WithObjectModerationProps<
  WithTimestamp<{
    uuid: string;
    name: string;
    description: string;
    type: ChannelType;
    backgroundImageUrl: string;
    status: ChannelStatus;
    isSticky: boolean;
    ownerUserUuids?: string[];
  }>
>;

export type ChannelWithAllInfo = Channel & {
  followers: number;
  isFollowing: boolean;
  owner: User;
  admins: User[];
  videosCount: number;
  objectLinks?: ObjectLink[];
};
