import { TabContext, TabPanel } from '@mui/lab';
import { Stack, Tab, Tabs } from '@mui/material';
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { useEffect } from 'react';
import { CommonDateStrings } from 'src/lib/constants/CommonDates';
import {
  fetchMostRefundedUsersByAmount,
  fetchMostRefundedUsersByQuantity,
  fetchRefunds,
  fetchRefundsStats,
} from 'src/lib/services/refunds/refunds';
import {
  FetchRefundsQueryParams,
  RefundTypes,
} from 'src/lib/services/refunds/types';
import { refundReasons } from 'src/lib/types/revenueEvent';
import { newDateWith3Hours } from 'src/lib/utils/addHoursToDate';
import { IncludeExcludeAll } from 'src/lib/utils/includeExcludeFilterUtils';
import { getDateRange } from 'src/lib/utils/query/getDateRange';
import { usePersistentQueryParams } from 'src/lib/utils/query/usePersistentQueryParams';
import HeaderBreadcrumbs from 'src/template/components/HeaderBreadcrumbs';
import useTabs from 'src/template/hooks/useTabs';
import RefundsStats from './RefundsStats';
import RefundsTable from './RefundsTable';
import RefundedUsersTable from './Tabs/MostRefundedUsersTable';
import { refundsTabs } from './refundsTabs';

const consolidatedTypes = [RefundTypes.refund];

const deniedTypes = [RefundTypes.refundDenied];

const requestedTypes = [RefundTypes.refundRequest];

const nowDate = new Date();
const millissecondsSinceFirstRevenueEvent =
  nowDate.getTime() -
  new Date(CommonDateStrings.firstRevenueEventV2CreatedAt.date).getTime();
const daysSinceFirstRevenueEvent = Math.ceil(
  millissecondsSinceFirstRevenueEvent / (1000 * 3600 * 24)
);
const daysForEndDateDateRange =
  daysSinceFirstRevenueEvent >= 120 ? 120 : daysSinceFirstRevenueEvent;

const defaultRefundQueryParams: FetchRefundsQueryParams = {
  page: 0,
  size: 50,
  order: 'desc',
  orderBy: 'eventDate',
  types: [RefundTypes.refund, RefundTypes.refundDenied],
  query: '',
  searchFor: 'user',
  reasons: refundReasons.filter((r) => r !== 'Upgraded'),
  upgradeFilter: IncludeExcludeAll.Exclude,
  ...getDateRange(nowDate, daysForEndDateDateRange),
};

export default function Refunds() {
  const { query, updateQuery } =
    usePersistentQueryParams<FetchRefundsQueryParams>(defaultRefundQueryParams);

  const {
    data: refunds,
    loading: isLoadingRefunds,
    total: totalRefunds,
  } = fetchRefunds(query);

  const { currentTab, onChangeTab } = useTabs(
    refundsTabs.consolidated_refunds.value
  );

  const { data: refundsStats, loading: isLoadingRefundStats } =
    fetchRefundsStats({
      startDate: query.startDate,
      endDate: query.endDate,
    });

  useEffect(() => {
    switch (currentTab) {
      case refundsTabs.consolidated_refunds.value:
        updateQuery({
          types: consolidatedTypes,
          reasons: defaultRefundQueryParams.reasons,
          page: 0,
        });
        break;
      case refundsTabs.requested_refunds.value:
        updateQuery({
          types: requestedTypes,
          reasons: defaultRefundQueryParams.reasons,
          page: 0,
        });
        break;
      case refundsTabs.denied_refunds.value:
        updateQuery({
          types: deniedTypes,
          reasons: defaultRefundQueryParams.reasons,
          page: 0,
        });
        break;
      default:
        updateQuery({
          page: 0,
        });
        break;
    }
  }, [currentTab]);

  const { data: mostRefundedUsers } = fetchMostRefundedUsersByQuantity(query);

  const { data: usersWithHighestRefunds } =
    fetchMostRefundedUsersByAmount(query);

  return (
    <>
      <Stack direction="row" justifyContent="space-between">
        <HeaderBreadcrumbs heading="Refunds" links={[{ name: 'Refunds' }]} />
        <Stack direction="row" gap={2}>
          <LocalizationProvider dateAdapter={AdapterDateFns}>
            <DatePicker
              label="Start Date"
              value={new Date(query.startDate)}
              minDate={
                new Date(CommonDateStrings.firstRevenueEventV2CreatedAt.date)
              }
              maxDate={newDateWith3Hours()}
              onChange={(e) => {
                if (!e) return;
                updateQuery({
                  startDate: e.getTime(),
                });
              }}
              format="dd/MM/yyyy"
            />
            <DatePicker
              label="End Date"
              value={new Date(query.endDate)}
              minDate={
                new Date(CommonDateStrings.firstRevenueEventV2CreatedAt.date)
              }
              maxDate={newDateWith3Hours()}
              onChange={(e) => {
                if (!e) return;
                updateQuery({
                  endDate: e.getTime(),
                });
              }}
              format="dd/MM/yyyy"
            />
          </LocalizationProvider>
        </Stack>
      </Stack>
      {currentTab && (
        <TabContext value={currentTab}>
          <Tabs
            allowScrollButtonsMobile
            variant="scrollable"
            scrollButtons="auto"
            TabIndicatorProps={{
              sx: {
                height: '3px',
                borderRadius: '20px',
              },
            }}
            sx={{
              mx: '1rem',
              mb: '1rem',
            }}
            value={currentTab}
            onChange={onChangeTab}
          >
            {Object.values(refundsTabs).map((tab) => (
              <Tab
                disableRipple
                label={tab.label}
                value={tab.value}
                key={tab.label}
                sx={{
                  p: '0.5rem',
                  borderRadius: '13px',
                  fontWeight: 'bold',
                }}
              />
            ))}
          </Tabs>
          <TabPanel value={refundsTabs.consolidated_refunds.value}>
            <RefundsTable
              query={query}
              loading={isLoadingRefunds}
              total={totalRefunds}
              data={refunds}
              updateQuery={updateQuery}
            />
          </TabPanel>
          <TabPanel value={refundsTabs.denied_refunds.value}>
            <RefundsTable
              query={query}
              loading={isLoadingRefunds}
              total={totalRefunds}
              data={refunds}
              updateQuery={updateQuery}
            />
          </TabPanel>
          <TabPanel value={refundsTabs.requested_refunds.value}>
            <RefundsTable
              query={query}
              loading={isLoadingRefunds}
              total={totalRefunds}
              data={refunds}
              updateQuery={updateQuery}
            />
          </TabPanel>
          <TabPanel value={refundsTabs.refunds_stats.value}>
            <RefundsStats data={refundsStats} loading={isLoadingRefundStats} />
          </TabPanel>
          <TabPanel value={refundsTabs.users_with_most_refunds.value}>
            <RefundedUsersTable
              data={mostRefundedUsers}
              query={query}
              updateQuery={updateQuery}
            />
          </TabPanel>
          <TabPanel value={refundsTabs.users_with_highest_refunds.value}>
            <RefundedUsersTable
              data={usersWithHighestRefunds}
              query={query}
              updateQuery={updateQuery}
            />
          </TabPanel>
        </TabContext>
      )}
    </>
  );
}
