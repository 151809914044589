import { Box, BoxProps } from '@mui/material';
import Placeholder from 'src/assets/placeholder.png';
import { getImageURL } from 'src/lib/utils/nft';
import { getProxyImageUrl } from 'src/lib/utils/utils';
import Image from 'src/template/components/Image';
import AudioDisplay from './AudioDisplay';

const videoExtensions = ['mp4'];
const audioExtensions = ['mp3', 'mpeg'];
const svgExtensions = ['svg', 'svg+xml'];
const imageExtensions = ['webp', 'apng', 'gif', 'jpg', 'jpeg', 'png'];
const dimensionExtensions = ['glb', 'gltf'];

type Props = BoxProps & {
  fileUrl: string;
};

export default function FileDisplay({ fileUrl, ...rest }: Props) {
  const regex = /\.([0-9a-z]+)(?:[?#]|$)/i;
  const fileExtension = fileUrl.match(regex)?.[1];

  const renderFile = () => {
    if (!fileExtension || imageExtensions.includes(fileExtension)) {
      return (
        <Image
          src={getImageURL(fileUrl)}
          alt="Empty Name"
          sx={{
            aspectRatio: 'inherit',
            width: '100%',
          }}
        />
      );
    }
    if (videoExtensions.includes(fileExtension)) {
      return (
        <Box
          component="video"
          controls
          sx={{
            width: '100%',
            aspectRatio: 'inherit',
          }}
        >
          <source src={fileUrl} type="video/mp4" />
          <track kind="captions" />
        </Box>
      );
    }
    if (audioExtensions.includes(fileExtension)) {
      return <AudioDisplay fileUrl={fileUrl} />;
    }
    if (svgExtensions.includes(fileExtension)) {
      return (
        <Image
          src={getImageURL(fileUrl)}
          alt="Empty Name"
          sx={{
            aspectRatio: 'inherit',
          }}
        />
      );
    }
    if (dimensionExtensions.includes(fileExtension)) {
      return (
        <Image
          src={getProxyImageUrl(fileUrl, { size: 250 })}
          alt="Empty Name"
          sx={{
            width: '100%',
            height: '100%',
            aspectRatio: 'inherit',
          }}
        />
      );
    }
    return (
      <Image
        src={Placeholder}
        alt="Unsupported file"
        title="Unsupported file"
        sx={{
          width: '100%',
          height: '100%',
        }}
      />
    );
  };

  return <Box {...rest}>{renderFile()}</Box>;
}
