export const refundsTabs = {
  consolidated_refunds: {
    label: 'Consolidated Refunds',
    value: 'consolidated_refunds',
  },
  denied_refunds: {
    label: 'Denied Refunds',
    value: 'denied_refunds',
  },
  requested_refunds: {
    label: 'Requested Refunds',
    value: 'requested_refunds',
  },
  refunds_stats: {
    label: 'Refunds Stats',
    value: 'refunds_stats',
  },
  users_with_most_refunds: {
    label: 'Most Refunded Users',
    value: 'users_with_most_refunds',
  },
  users_with_highest_refunds: {
    label: 'Users with Highest Refunds',
    value: 'users_with_highest_refunds',
  },
};
