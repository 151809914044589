import { DEFAULT_INITIAL_PAGE_SIZE } from '../constants/pagination';
import createFilterStore from '../stores/createFilterStore';

const useNftsGridFilter = createFilterStore({
  orderBy: 'createdAt',
  order: 'desc',
  searchFor: 'name',
  query: '',
  page: 0,
  size: DEFAULT_INITIAL_PAGE_SIZE,
});

export default useNftsGridFilter;
