// eslint-disable-next-line import/no-cycle
import { Channel } from './channel';
import { Club } from './club';
import { Collection } from './collection';
import { Game } from './game';
import { NFT as Nft } from './nft';

export enum ObjectLinkTypes {
  NFT = 'nft',
  COLLECTION = 'collection',
  GAME = 'game',
  CHANNEL = 'channel',
  CLUB = 'club',
}

export type ObjectLink = {
  uuid: string;

  objectFromType: ObjectLinkTypes;
  objectFromUuid: string;

  linksToType: ObjectLinkTypes;
  linksToUuid: string;

  linksToNft?: Nft;
  linksToChannel?: Channel;
  linksToGame?: Game;
  linksToClub?: Club;
  linksToCollection?: Collection;

  createdAt: Date;
  updatedAt: Date;
  deletedAt: Date | null;
};

// eslint-disable-next-line @typescript-eslint/naming-convention
export type linksToCreate = Pick<
  ObjectLinkCreation,
  'linksToType' | 'linksToUuid'
>;
// eslint-disable-next-line @typescript-eslint/naming-convention
export type objectFromData = Pick<
  ObjectLinkCreation,
  'objectFromType' | 'objectFromUuid'
>;

type ObjectLinkCreation = Pick<
  ObjectLink,
  'objectFromType' | 'objectFromUuid' | 'linksToType' | 'linksToUuid'
>;
