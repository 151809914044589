import { Block } from '@mui/icons-material';
import CheckIcon from '@mui/icons-material/Check';
import {
  Alert,
  Box,
  Card,
  CardContent,
  CardHeader,
  Skeleton,
  Stack,
  Tooltip,
  Typography,
} from '@mui/material';
import Flex from 'src/components/shared/Flex';
import objectEntries from 'src/lib/utils/objectEntries';
import usePayoutEligibility, { orderByRule } from '../../usePayoutEligibility';

type Props = {
  userId: string;
};
export default function PayoutEligibility({ userId }: Props): JSX.Element {
  const {
    isLoading,
    isFullyEligible,
    eligibilityMessage,
    labelByRule,
    rules,
    error,
  } = usePayoutEligibility(userId);

  if (error) {
    return <Alert color="error">Could not fetch data</Alert>;
  }

  const sortedRulesByEligibility = rules
    ? objectEntries(rules).sort((a, b) => orderByRule[a[0]] - orderByRule[b[0]])
    : [];

  return (
    <Card>
      <CardHeader title="Payout Eligibility" />
      <CardContent>
        {(isLoading || !rules) && <Skeleton height={200} />}
        {rules && (
          <Stack spacing={2}>
            <Box display="grid" gridTemplateColumns="repeat(2, 1fr)">
              {sortedRulesByEligibility.map(([key, value]) => {
                const isEligible =
                  typeof value === 'boolean' ? value : value.completed;
                return (
                  <BoxValue
                    key={key}
                    condition={isEligible}
                    label={labelByRule[key]}
                    {...(typeof value !== 'boolean' && {
                      current: value.current ?? -1,
                      target: value.target,
                    })}
                  />
                );
              })}
            </Box>
            <Alert
              severity={isFullyEligible ? 'success' : 'error'}
              variant="outlined"
              sx={{ mt: 2 }}
            >
              {eligibilityMessage}
            </Alert>
          </Stack>
        )}
      </CardContent>
    </Card>
  );
}

type BoxValueProps = {
  condition: boolean;
  label: string;
  current?: number | null;
  target?: number | null;
};
function BoxValue({ condition, label, current, target }: BoxValueProps) {
  return (
    <Flex gap={1}>
      <Tooltip title={condition ? 'Eligible' : 'Not Eligible'} placement="left">
        {condition ? <CheckIcon color="success" /> : <Block color="error" />}
      </Tooltip>
      <Typography textTransform="capitalize">{label}</Typography>
      {target && (
        <Typography>
          ({current! < 0 ? 'Null' : current ?? 0} / {target})
        </Typography>
      )}
    </Flex>
  );
}
