import { Avatar } from '@mui/material';
import {
  GridColumns,
  GridSortDirection,
  GridSortModel,
} from '@mui/x-data-grid';
import { format } from 'date-fns';
import Link from 'src/components/shared/Link';
import MagicTable from 'src/components/shared/MagicTable/MagicTable';
import useURLSearchParams from 'src/lib/hooks/useURLSearchParams';
import { User } from 'src/lib/types/users';
import Routes from 'src/routes/Routes';
import Label from 'src/template/components/Label';

type Props = {
  isLoading: boolean;
  total: number;
  users: User[];
};
export default function UsersTableView({
  users,
  isLoading,
  total,
}: Props): JSX.Element {
  const { addParam, getParam, removeParam } = useURLSearchParams();
  const page = Number(getParam('page') ?? 0);
  const size = Number(getParam('size') ?? 100);
  const orderBy = getParam('orderBy');
  const order = getParam('order');
  const sortModel: GridSortModel | undefined =
    orderBy && order
      ? [{ field: orderBy, sort: order as GridSortDirection }]
      : undefined;

  return (
    <MagicTable
      autoHeight
      loading={isLoading}
      columns={columns}
      rowCount={total}
      pageSize={size}
      page={page}
      onPageChange={(newPage) => {
        addParam('page', newPage);
      }}
      onPageSizeChange={(newSize) => {
        addParam('size', newSize);
      }}
      sortModel={sortModel}
      onSortModelChange={(model) => {
        const currentModel = model[0];
        if (currentModel && currentModel.sort) {
          addParam('orderBy', currentModel.field);
          addParam('order', currentModel.sort);
        } else {
          removeParam('orderBy');
          removeParam('order');
        }
      }}
      rows={users}
      getRowId={(r) => r.uuid}
    />
  );
}

const columns: GridColumns<any> = [
  {
    field: 'name',
    headerName: 'Name',
    renderCell: ({ row }) => (
      <>
        <Avatar alt={row.name} src={row.profileImage} sx={{ mr: 2 }} />

        <Link
          to={Routes.user(row.uuid)}
          color={row.name ? 'inherit' : 'text.secondary'}
          underline="hover"
        >
          {row?.name || 'empty'}
        </Link>
      </>
    ),
    align: 'left',
  },
  { field: 'email', headerName: 'Email', align: 'left' },
  { field: 'slug', headerName: 'Slug', align: 'left' },
  {
    field: 'active',
    headerName: 'Status',
    align: 'center',
    flex: 0,
    renderCell: ({ row }) => {
      if (row.active) {
        return <Label color="success">Active</Label>;
      }
      return <Label color="error">Inactive</Label>;
    },
  },
  {
    field: 'lastSession',
    headerName: 'Last Session',
    align: 'left',

    renderCell: ({ row }) =>
      row.lastSession ? format(row.lastSession, 'dd/MM/yyyy · HH:mm') : '--',
  },
  {
    field: 'lastLogin',
    headerName: 'Last Login',
    align: 'left',
    renderCell: ({ row }) =>
      row.lastLogin ? format(row.lastLogin, 'dd/MM/yyyy · HH:mm') : '--',
  },
  {
    field: 'createdAt',
    headerName: 'Created At',
    renderCell: ({ row }) => format(row.createdAt, 'dd/MM/yyyy · HH:mm'),
  },
];
