import { useCallback, useEffect } from 'react';
import { useParams } from 'react-router';
import { DEFAULT_INITIAL_PAGE_SIZE } from 'src/lib/constants/pagination';
import useURLSearchParams from 'src/lib/hooks/useURLSearchParams';
import { ContestEventStatus } from 'src/lib/types/contestEvent';
import { ContestEventEmitter } from '../../events/ContestEventEmitter';
import { fetchContestEventsByContest } from '../../services/fetchContestEventsByContest';

export default function useContestEvents() {
  const { allSearchParams } = useURLSearchParams(initialParams);
  const { contestUuid } = useParams<{
    contestUuid: string;
  }>();

  const params: FetchContestEventsQueryParams = {
    page: +(allSearchParams.page ?? initialParams.page),
    size: +(allSearchParams.size ?? initialParams.size),
    orderBy: allSearchParams.orderBy ?? initialParams.orderBy,
    sortOrder: allSearchParams.sortOrder ?? initialParams.order,
    status: (allSearchParams.status ?? initialParams.status) as
      | ContestEventStatus
      | 'all',
  };

  const { data, isLoading, mutate } = fetchContestEventsByContest(
    contestUuid!,
    {
      page: params.page,
      size: params.size,

      ...(params.orderBy &&
        params.sortOrder && {
          order: {
            [params.orderBy]: params.sortOrder,
          },
        }),

      filter: {
        ...(params.status &&
          params.status != 'all' && {
            status: {
              eq: params.status,
            },
          }),
      },
    }
  );

  const refetchData = useCallback(() => {
    mutate?.();
  }, []);

  useEffect(() => {
    ContestEventEmitter.on('ContestEventUpdated', refetchData);

    return () => {
      ContestEventEmitter.off('ContestEventUpdated', refetchData);
    };
  }, [refetchData]);

  return {
    data,
    isLoading,
    params,
    contestUuid,
  };
}

const initialParams = {
  page: '0',
  size: DEFAULT_INITIAL_PAGE_SIZE.toString(),
  order: 'desc',
  orderBy: 'createdAt',
  status: 'all',
};

export type FetchContestEventsQueryParams = {
  page: number;
  size: number;
  orderBy: string;
  sortOrder: string;
  status: ContestEventStatus | 'all';
};
