import { useParams } from 'react-router';
import ListAssetsModalView from 'src/components/modules/GameAssets/view/ListModal';
import { DEFAULT_INITIAL_PAGE_SIZE } from 'src/lib/constants/pagination';
import useURLSearchParams from 'src/lib/hooks/useURLSearchParams';
import useModalStore from 'src/lib/stores/useModalStore';
import { GameAsset } from 'src/lib/types/asset';
import { fetchContestEventRankings } from '../../services/fetchContestEventRankings';

export default function useContestEventRankings() {
  const { allSearchParams } = useURLSearchParams(initialParams);
  const { eventUuid } = useParams<{
    eventUuid: string;
  }>();
  const openModal = useModalStore((state) => state.openModal);

  const handleOpenListAssetsUsed = async (assets: GameAsset[]) => {
    openModal(<ListAssetsModalView assets={assets} />);
  };

  const params = {
    page: +(allSearchParams.page ?? initialParams.page),
    size: +(allSearchParams.size ?? initialParams.size),
    orderBy: allSearchParams.orderBy ?? initialParams.orderBy,
    sortOrder: allSearchParams.sortOrder ?? initialParams.order,
    searchBy: allSearchParams.searchBy ?? initialParams.searchBy,
    query: allSearchParams.query ?? initialParams.query,
  };

  const fetchParams = {
    page: params.page,
    size: params.size,
    /*
    ...(params.orderBy &&
      params.sortOrder && {
        order: {
          [params.orderBy]: params.sortOrder,
        },
      }),
    */
    filter: {
      ...(params.searchBy &&
        params.query && {
          [params.searchBy]: {
            like: `%${params.query}%`,
          },
        }),
    },
  };

  const { data, isLoading } = fetchContestEventRankings(
    eventUuid!,
    fetchParams
  );

  return {
    data: data?.ranking,
    isLoading,
    params,
    eventUuid,
    handleOpenListAssetsUsed,
  };
}

const initialParams = {
  page: '0',
  size: DEFAULT_INITIAL_PAGE_SIZE.toString(),
  order: 'asc',
  orderBy: 'position',
  searchBy: 'name',
  query: '',
};

export type FetchContestEventRankingsQueryParams = {
  page: number;
  size: number;
  orderBy: string;
  searchBy: string;
  sortOrder: string;
};
