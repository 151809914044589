import { Add } from '@mui/icons-material';
import {
  Autocomplete,
  Box,
  Button,
  Card,
  CardContent,
  Container,
  InputAdornment,
  Stack,
  TextField,
} from '@mui/material';
import Flex from 'src/components/shared/Flex';
import { LabelValue } from 'src/lib/types/labelValue';
import HeaderBreadcrumbs from 'src/template/components/HeaderBreadcrumbs';
import Iconify from 'src/template/components/Iconify';
import AssetCollectionTable from '../../components/AssetCollectionsTable/AssetCollectionTable';
import useGameMakerAssetCollections from './useView';

export default function GameMakerAssetCollections() {
  const {
    data,
    isLoading,
    params,
    debouncedSearch,
    addParam,
    removeParam,
    allSearchParams,
    handleOpenCreateModal,
  } = useGameMakerAssetCollections();
  return (
    <Container maxWidth="xl">
      <HeaderBreadcrumbs
        heading="Asset Collections"
        links={[{ name: 'Asset Collections' }]}
        action={
          <Button
            variant="contained"
            color="secondary"
            onClick={handleOpenCreateModal}
            startIcon={<Add />}
          >
            Add Asset Collection
          </Button>
        }
      />

      <Card>
        <CardContent>
          <Stack p={2} gap={2}>
            <Flex gap={2} justifyContent={'flex-end'}>
              <Autocomplete
                options={SEARCH_OPTIONS}
                fullWidth
                renderInput={(_params) => (
                  <TextField label="Search By" {..._params} />
                )}
                getOptionLabel={(p) => p.label}
                sx={{ maxWidth: 250 }}
                defaultValue={SEARCH_OPTIONS.find(
                  (o) => o.value === params.searchBy
                )}
                key={params.searchBy}
                onChange={(_, value) => {
                  if (!value) removeParam('searchBy');
                  else addParam('searchBy', value.value);
                }}
              />
              <TextField
                fullWidth
                label="Search"
                defaultValue={allSearchParams.query}
                type="search"
                onChange={(e) => {
                  const { value } = e.target;
                  debouncedSearch(value);
                }}
                key={allSearchParams.query}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <Iconify
                        icon="eva:search-fill"
                        sx={{ color: 'text.disabled', width: 20, height: 20 }}
                      />
                    </InputAdornment>
                  ),
                }}
              />
            </Flex>
          </Stack>
          <Box px={2}>
            <AssetCollectionTable
              data={data?.body || []}
              totalCount={data?.meta?.totalCount || 0}
              isLoading={isLoading}
              params={params}
            />
          </Box>
        </CardContent>
      </Card>
    </Container>
  );
}

const SEARCH_OPTIONS: LabelValue[] = [
  {
    label: 'Name',
    value: 'name',
  },
];
