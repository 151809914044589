import ConfigurationForm from '../../components/Form';
import { ParsedConfiguration } from '../../types/Configuration';
import useUpdateConfiguration from './useView';

type Props = {
  configuration: ParsedConfiguration;
};
export default function UpdateConfiguration({
  configuration,
}: Props): JSX.Element {
  const { handleUpdateConfiguration } = useUpdateConfiguration();
  return (
    <ConfigurationForm
      defaultValues={{
        name: configuration.name,
        platform: configuration.platform,
        value: configuration.value,
        description: configuration.description,
      }}
      title={`Update ${configuration.name}`}
      onSubmit={async (values) => {
        await handleUpdateConfiguration(configuration.uuid, values);
      }}
    />
  );
}
