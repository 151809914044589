import HeaderBreadcrumbs from 'src/template/components/HeaderBreadcrumbs';
import NotProcessedConsumablesTable from '../../components/NotProcessedConsumablesTable/NotProcessedConsumableTable';

export default function NotProcessedConsumables() {
  return (
    <>
      <HeaderBreadcrumbs
        heading="Not Processed Consumables"
        links={[{ name: 'Not Processed Consumables' }]}
      />
      <NotProcessedConsumablesTable />
    </>
  );
}
