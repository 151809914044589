import { ArrayOfObjectsToModerate } from 'src/lib/types/arrayOfObjectsToModerate';
import { OBJECT_MODERATION_OBJECT_TYPE } from 'src/lib/types/objectModeration';
import ChannelsToReviewTable from '../../components/Channels/Table';
import GamesToReviewTable from '../../components/Game/Table';
import VideosToReviewTable from '../../components/Videos/Table';
import { ObjectsToModerateResponse } from '../../services/fetchObjectsToReview';
import { ListObjectsToReviewParams } from './useView';

interface ModeratedObjectItem {
  component: (
    data: ArrayOfObjectsToModerate,
    isLoading: boolean,
    params: ListObjectsToReviewParams,
    key: React.Key
  ) => JSX.Element;
}

type ITableByType = Record<OBJECT_MODERATION_OBJECT_TYPE, ModeratedObjectItem>;

export const MODERATED_OBJECTS_TABLES: ITableByType = {
  [OBJECT_MODERATION_OBJECT_TYPE.CHANNEL]: {
    component: (data, isLoading, params, key) => (
      <ChannelsToReviewTable
        key={key}
        data={
          {
            body: data,
            meta: {
              totalCount: data?.length ?? 0,
            },
          } as unknown as ObjectsToModerateResponse
        }
        params={{
          page: params.page,
          size: params.size,
        }}
        isLoading={isLoading}
      />
    ),
  },
  [OBJECT_MODERATION_OBJECT_TYPE.VIDEO]: {
    component: (data, isLoading, params, key) => (
      <VideosToReviewTable
        key={key}
        data={
          {
            body: data,
            meta: {
              totalCount: data?.length ?? 0,
            },
          } as unknown as ObjectsToModerateResponse
        }
        params={{
          page: params.page,
          size: params.size,
        }}
        isLoading={isLoading}
      />
    ),
  },
  [OBJECT_MODERATION_OBJECT_TYPE.GAME_VERSION]: {
    component: (data, isLoading, params, key) => (
      <GamesToReviewTable
        key={key}
        data={
          {
            body: data,
            meta: {
              totalCount: data?.length ?? 0,
            },
          } as unknown as ObjectsToModerateResponse
        }
        params={{
          page: params.page,
          size: params.size,
        }}
        isLoading={isLoading}
      />
    ),
  },

  // TODO
  [OBJECT_MODERATION_OBJECT_TYPE.CLUB]: {
    component: DefaultComponent,
  },
  [OBJECT_MODERATION_OBJECT_TYPE.COLLECTION]: {
    component: DefaultComponent,
  },
  [OBJECT_MODERATION_OBJECT_TYPE.NFT]: {
    component: DefaultComponent,
  },
  [OBJECT_MODERATION_OBJECT_TYPE.USER]: {
    component: DefaultComponent,
  },
};

function DefaultComponent() {
  return <div>Not implemented</div>;
}
