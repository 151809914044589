import useNotify from 'src/lib/hooks/useNotify';
import useModalStore from 'src/lib/stores/useModalStore';
import { ClubEmitter } from '../../events/ClubEmitter';
import { banUserFromClub } from '../../services/banUserFromClub';

export default function useBanUserFromClub() {
  const closeModal = useModalStore((state) => state.closeModal);
  const notify = useNotify();

  const handleBanUserFromClub = async (userUuid: string, clubUuid: string) => {
    const { data, error } = await banUserFromClub(userUuid, clubUuid);
    if (error) {
      notify(error, { variant: 'error' });
      closeModal();
    } else {
      notify('User banned successfuly!', { variant: 'success' });
      closeModal();
      ClubEmitter.emit('UserBanned', data);
    }
  };

  return {
    handleBanUserFromClub,
  };
}
