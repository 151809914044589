import React, { ReactElement } from 'react';
import { FeedItemAnalyticsTableRow } from 'src/lib/types/FeedItem';
import { OBJECT_MODERATION_OBJECT_TYPE } from 'src/lib/types/objectModeration';
import Iconify, { IconifyProps } from 'src/template/components/Iconify';

const MainInfo = React.lazy(
  () => import('src/components/modules/ChannelContent/view/MainInfo')
);
const ObjectModerationTab = React.lazy(
  () => import('src/components/modules/Review/components/ModerationObjectsTab')
);

export enum ChannelContentTab {
  MAIN = 'main_info',
  MODERATION = 'moderation',
}

interface IChannelContentTabs {
  value: ChannelContentTab;
  icon: ReactElement<IconifyProps>;
  component: (channelContentDetails: FeedItemAnalyticsTableRow) => JSX.Element;
}

const CHANNEL_CONTENT_TABS: IChannelContentTabs[] = [
  {
    value: ChannelContentTab.MAIN,
    icon: (
      <Iconify icon="material-symbols:info-outline" width={20} height={20} />
    ),
    component: (channelContentDetails) => (
      <MainInfo channelContentDetails={channelContentDetails} />
    ),
  },
  {
    value: ChannelContentTab.MODERATION,
    icon: <Iconify icon="mdi:shield-account" width={20} height={20} />,
    component: (channelContentDetails) => (
      <ObjectModerationTab
        objectType={OBJECT_MODERATION_OBJECT_TYPE.VIDEO}
        objectUuid={channelContentDetails.creatorChannelContent?.uuid!}
        showHeader
      />
    ),
  },
];

export default CHANNEL_CONTENT_TABS;
