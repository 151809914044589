import useSWR, { SWRResponse } from 'swr';
import { StatusOfPurgeUseCase } from '../../Profile/types/UserPurge';

export const useFetchUserPurgeStatus =
  (): SWRResponse<StatusOfPurgeUseCase> => {
    const response = useSWR<StatusOfPurgeUseCase, Error>(
      `/admin/user-purge/status`,
      {
        refreshInterval: 5000,
      }
    );

    return response;
  };
