/* eslint-disable no-restricted-syntax */
import { OpenInNew } from '@mui/icons-material';
import {
  Alert,
  Autocomplete,
  IconButton,
  Stack,
  TextField,
  Tooltip,
  Typography,
} from '@mui/material';
import { GridColumns } from '@mui/x-data-grid';
import { ProfileTabs } from 'src/components/modules/Profile/types/ProfileTabs';
import Center from 'src/components/shared/Center';
import CloseableAlert from 'src/components/shared/CloseableAlert';
import Flex from 'src/components/shared/Flex';
import Link from 'src/components/shared/Link';
import MagicTable from 'src/components/shared/MagicTable/MagicTable';
import MagicTableUserCell from 'src/components/shared/MagicTable/UserCell';
import useMagicTable from 'src/components/shared/MagicTable/useMagicTable';
import NumberInfo from 'src/components/shared/NumberInfo/NumberInfo';
import SectionDivider from 'src/components/shared/SectionDivider';
import { CommonDateStrings } from 'src/lib/constants/CommonDates';
import { countries } from 'src/lib/constants/UserRegions';
import { ProductProvider, WithProduct } from 'src/lib/types/product';
import { RevenueEvent } from 'src/lib/types/revenueEvent';
import { WithUser } from 'src/lib/types/users';
import { formatUTCDate } from 'src/lib/utils/formatUTCDate';
import Routes from 'src/routes/Routes';
import Label from 'src/template/components/Label';

import { fetchSubscriptions } from 'src/components/modules/Products/services/fetchSubscriptions';
import { InfoIcon } from 'src/template/theme/overrides/CustomIcons';
import { useSubscriptionsFromRevenueEventsV2StateView } from './useView';

const cardWidth: number = 200;

export default function SubscriptionsStateView(): JSX.Element {
  const magicTableProps = useMagicTable();
  const { data, error, isLoading, onChangeFilter, filter } =
    useSubscriptionsFromRevenueEventsV2StateView();

  const { data: productsOptionsResponse, isLoading: isLoadingProducts } =
    fetchSubscriptions();

  const totalCount = data?.meta.totalCount ?? 0;
  const rows = data?.body ?? [];

  const tableData = rows;
  const productOptions =
    productsOptionsResponse?.body
      .filter((p) => p.provider === ProductProvider.APPLE)
      .map((p) => p.productId) ?? [];

  const withUsers = tableData.filter((r) => r?.user?.uuid).length;
  const withoutUsers = tableData.length - withUsers;

  if (error) {
    return (
      <Center>
        <Alert severity="error">
          Could not load Subscriptions, please try again later.
          <br /> <b>Error:</b> {error}
        </Alert>
      </Center>
    );
  }
  return (
    <Stack spacing={3}>
      <CloseableAlert severity="info">
        This page shows all the active subscriptions based on the given ending
        date. The counters below are based on the rows being currently
        presented.
      </CloseableAlert>
      <Flex width="100%" justifyContent="start" gap={2} flexWrap="wrap">
        <NumberInfo
          title="With Users"
          total={withUsers ?? 0}
          componentProps={{
            sx: {
              width: cardWidth,
            },
          }}
        />
        <NumberInfo
          title="Without Users"
          total={withoutUsers ?? 0}
          componentProps={{
            sx: {
              width: cardWidth,
            },
          }}
        />
        <NumberInfo
          title="Total Subscriptions"
          total={tableData.length}
          componentProps={{
            sx: {
              width: cardWidth,
            },
          }}
        />
      </Flex>
      <SectionDivider section="Dates are in UTC timezone" />
      <MagicTable.Container>
        <MagicTable.Tabs
          tabsConfig={{
            queryName: 'isFreeTrial',
          }}
          defaultTab="all"
          tabs={[
            {
              label: 'All',
              value: 'all',
            },
            {
              label: 'Paid',
              value: '0',
            },
            {
              label: 'Free Trial',
              value: '1',
            },
          ]}
        />
        <MagicTable.FilterContainer>
          <Autocomplete
            options={productOptions}
            fullWidth
            renderInput={(params) => <TextField label="Product" {...params} />}
            loading={isLoadingProducts}
            defaultValue={filter.productId}
            getOptionLabel={(opt) => {
              const productSelected = productsOptionsResponse?.body.find(
                (d) => d.productId === opt
              );
              if (!productSelected) return opt;
              if (productSelected.name) {
                return `${productSelected.name} (${productSelected.productId})`;
              }
              return productSelected.productId;
            }}
            onChange={(_, value) => {
              onChangeFilter('productId', value);
            }}
          />
          <Autocomplete
            options={countries}
            fullWidth
            renderInput={(params) => <TextField label="Country" {...params} />}
            loading={isLoading}
            defaultValue={filter.country}
            onChange={(_, value) => {
              onChangeFilter('country', value);
            }}
          />

          <TextField
            label="User UUID"
            fullWidth
            type="text"
            InputLabelProps={{ shrink: true }}
            defaultValue={filter.userUuid}
            onChange={({ target: { value } }) => {
              onChangeFilter('userUuid', value);
            }}
          />
          <TextField
            label={
              <Tooltip
                placement="top-start"
                arrow
                // title="This date will filter subscriptions that where active, which means that the last purchase/renew is contained between the 'Last Purchase / Renewal' date and 'Expires On' date"
                title="This will show subscriptions that were not expired until the selected date. This is done by showing subscriptions that contained the selected date in between the 'Last Purchase / Renewal' and 'Expires On' interval"
              >
                <Stack spacing={1} direction={'row'}>
                  <div>Ending Date</div>
                  <InfoIcon />
                </Stack>
              </Tooltip>
            }
            fullWidth
            type="date"
            InputLabelProps={{
              /* shrink: true, */ style: { pointerEvents: 'auto' },
            }}
            defaultValue={CommonDateStrings.today.date}
            inputProps={{
              min: CommonDateStrings.firstSubscription.date,
              max: CommonDateStrings.today.date,
            }}
            onChange={({ target: { value } }) => {
              onChangeFilter('endDate', value);
            }}
          />
        </MagicTable.FilterContainer>
        <MagicTable.Wrapper>
          <MagicTable
            loading={isLoading}
            rows={tableData}
            paginationMode="server"
            rowCount={totalCount}
            columns={columns}
            // page={Number(page)}
            // pageSize={Number(size)}
            {...magicTableProps}
          />
        </MagicTable.Wrapper>
      </MagicTable.Container>
    </Stack>
  );
}
const columns: GridColumns<WithProduct<WithUser<RevenueEvent>>> = [
  {
    field: 'productId',
    headerName: 'Product',
    sortable: false,
    filterable: false,
    flex: 2.5,
    renderCell({ row, value }) {
      if (row.product)
        return (
          <Stack>
            <Typography>{row.product.name}</Typography>
            <Flex gap={1}>
              <Typography fontSize="12px" color="GrayText">
                {row.product.productId}
              </Typography>
              {row.isFreeTrial && (
                <Label
                  color="info"
                  key="freeTrial"
                  sx={{
                    minWidth: 'unset',
                    width: 'fit-content',
                    fontSize: '12px',
                    height: '17px',
                    px: 1.5,
                  }}
                >
                  Free Trial
                </Label>
              )}
            </Flex>
          </Stack>
        );
      return value;
    },
  },
  {
    field: 'user',
    sortable: false,
    filterable: false,
    headerName: 'User',
    flex: 1.5,
    renderCell({ row }) {
      if (!row?.user?.uuid) {
        return '--';
      }
      return (
        <MagicTableUserCell
          displayUserStatus
          linkUrl={`${Routes.user(row.user.uuid)}?tab=${
            ProfileTabs.SUBSCRIPTIONS
          }`}
          user={row.user}
        />
      );
    },
  },
  {
    field: 'userCountry',
    headerName: 'Country',
    flex: 0,
    align: 'center',
    sortable: false,
    filterable: false,
    renderCell({ row }) {
      return row.user?.region ?? '--';
    },
  },
  {
    field: 'userSystemRegion',
    headerName: 'Sys. Country',
    flex: 0,
    align: 'center',
    sortable: false,
    filterable: false,
    renderCell({ row }) {
      return row.user?.systemRegion ?? '--';
    },
  },
  {
    field: 'paymentCount',
    headerName: 'Payment Count',
    align: 'left',
    sortable: false,
    filterable: false,
  },
  {
    field: 'eventType',
    headerName: 'Type',
    align: 'left',
    sortable: false,
    filterable: false,
  },
  {
    field: 'originalPurchaseDate',
    headerName: 'Original Purchase Date',
    flex: 3 / 2,
    sortable: false,
    filterable: false,
    valueFormatter({ value }) {
      return value ? formatUTCDate(value, { second: '2-digit' }) : '--';
    },
  },
  {
    field: 'eventDate',
    headerName: 'Last Purchase / Renewal',
    flex: 3 / 2,
    sortable: false,
    filterable: false,
    valueFormatter({ value }) {
      return formatUTCDate(value, { second: '2-digit' });
    },
  },
  {
    field: 'subscriptionExpiresDate',
    headerName: 'Expires On',
    flex: 3 / 2,
    sortable: false,
    filterable: false,
    valueFormatter({ value }) {
      return formatUTCDate(value, { second: '2-digit' });
    },
  },

  {
    field: 'action',
    headerName: '',
    align: 'center',
    flex: 1 / 2,
    sortable: false,
    filterable: false,
    renderCell({ row }) {
      const { originalTransactionId, userUuid } = row;

      return (
        <IconButton
          component={Link}
          to={Routes.subscription(originalTransactionId, userUuid)}
        >
          <OpenInNew />
        </IconButton>
      );
    },
  },
];
