import { PaginatedResponse } from 'src/lib/types/PaginatedResponse';
import {
  Product,
  ProductProvider,
  ProductType,
  RechargeType,
} from 'src/lib/types/product';
import { parseSearchParams } from 'src/lib/utils/parseSearchParams';
import useSWR, { SWRResponse } from 'swr';

type PossibleParams = {
  page: number | string;
  size: number | string;
  filter: {
    provider: ProductProvider;
    rechargeType: RechargeType;
    type?: ProductType;
  };
  order: Record<string, string>;
};

export function fetchProducts(
  params?: Partial<PossibleParams>
): SWRResponse<PaginatedResponse<Product[]>> {
  const parsedParams = parseSearchParams(params ?? {});
  const { data, ...response } = useSWR(`admin/products/all?${parsedParams}`);

  return {
    ...response,
    data,
  };
}
