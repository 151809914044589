import { yupResolver } from '@hookform/resolvers/yup';
import {
  Autocomplete,
  Card,
  CardContent,
  CardHeader,
  Stack,
  TextField,
} from '@mui/material';
import { Controller, useForm } from 'react-hook-form';
import Button from 'src/components/shared/Buttons/CustomButton';
import Modal from 'src/components/shared/Modal';
import useModalStore from 'src/lib/stores/useModalStore';
import { Rarity } from 'src/lib/types/nftPack';
import * as yup from 'yup';
import { UpdateNftOnPackFormValues } from './types';

const validationSchema = yup.object().shape({
  rarityUuid: yup.string().required('Rarity is required'),
  faceValue: yup
    .number()
    .min(1)
    .integer('Face value should be a integer')
    .required('Face value is required'),
});

type Props = {
  onSubmit: (values: UpdateNftOnPackFormValues) => Promise<void>;
  raritiesOptions: Rarity[];
  title: string;
  defaultValues: UpdateNftOnPackFormValues;
};

export default function UpdateNftOnPackForm({
  onSubmit,
  raritiesOptions,
  title,
  defaultValues,
}: Props): JSX.Element {
  const onClose = useModalStore((state) => state.closeModal);
  const {
    control,
    handleSubmit,
    formState: { isSubmitting },
  } = useForm<UpdateNftOnPackFormValues>({
    resolver: yupResolver(validationSchema),
    defaultValues,
  });

  const handleCloseModal = () => {
    onClose();
  };

  return (
    <Modal open onClose={handleCloseModal}>
      <Card>
        <CardHeader title={title} />
        <CardContent component={Stack} spacing={3} p={4}>
          <form onSubmit={handleSubmit(onSubmit)}>
            <Stack gap={2}>
              <Controller
                control={control}
                name={`rarityUuid`}
                render={({ field }) => (
                  <Autocomplete
                    sx={{
                      width: '200px',
                    }}
                    options={raritiesOptions?.map((r) => r.uuid) ?? []}
                    getOptionLabel={(option) =>
                      raritiesOptions?.find((r) => r.uuid === option)?.name ??
                      ''
                    }
                    defaultValue={field.value}
                    onChange={(e, value) => field.onChange(value)}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        {...field}
                        label="Rarity"
                        required
                        sx={{
                          width: '200px',
                        }}
                      />
                    )}
                  />
                )}
              />
              <Controller
                control={control}
                name={`faceValue`}
                render={({ field }) => (
                  <TextField
                    {...field}
                    label="Face Value"
                    required
                    type="number"
                    sx={{
                      width: '200px',
                    }}
                  />
                )}
              />
            </Stack>
            <Stack
              direction="row"
              justifyContent="space-between"
              spacing={1}
              mt={2}
            >
              <Button color="error" onClick={handleCloseModal}>
                Cancel
              </Button>
              <Button
                variant="contained"
                color="secondary"
                type="submit"
                loading={isSubmitting}
              >
                Save
              </Button>
            </Stack>
          </form>
        </CardContent>
      </Card>
    </Modal>
  );
}
