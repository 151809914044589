import { Container } from '@mui/material';
import Payment from 'src/components/modules/Payments/Payment/Payment';
import Page from 'src/template/components/Page';

export default function PaymentPage() {
  return (
    <Page title="Payment Request">
      <Container maxWidth="lg">
        <Payment />
      </Container>
    </Page>
  );
}
