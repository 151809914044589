import { EntityNote } from 'src/lib/types/entityNote';
import EntityNoteForm from '../../components/Form';
import useUpdateEntityNote from './useView';

type Props = {
  entityNote: EntityNote;
};
export default function UpdateEntityNote({ entityNote }: Props): JSX.Element {
  const { handleUpdateEntityNote } = useUpdateEntityNote();
  return (
    <EntityNoteForm
      defaultValues={{
        note: entityNote.note,
        tagName: entityNote.tag?.name ?? '',
        entityUuid: entityNote.entityUuid,
      }}
      title={`Update this note`}
      onSubmit={async (values) => {
        await handleUpdateEntityNote(entityNote.uuid, values);
      }}
    />
  );
}
