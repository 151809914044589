import { NftPack } from 'src/lib/types/nftPack';
import NFTPackForm from '../../../components/Forms/NftPack';
import useUpdateNFTPackView from './useView';

type Props = {
  nftPack: NftPack;
};

export default function UpdateNftPackView({ nftPack }: Props): JSX.Element {
  const { handleUpdateNftPack } = useUpdateNFTPackView();
  return (
    <NFTPackForm
      defaultValues={{
        name: nftPack.name,
        amountOfNftsToRecharge: nftPack.amountOfNftsToRecharge,
        type: nftPack.type,
        productUuid: nftPack.productUuid,
      }}
      onSubmit={async (values) => {
        await handleUpdateNftPack(nftPack.uuid, values);
      }}
      title="Update NFT Pack"
    />
  );
}
