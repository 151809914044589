import { DeepPartial } from 'react-hook-form';
import { PaginatedResponse } from 'src/lib/types/PaginatedResponse';
import { IncludeExcludeAll } from 'src/lib/utils/includeExcludeFilterUtils';
import { parseSearchParams } from 'src/lib/utils/parseSearchParams';
import useSWR, { SWRResponse } from 'swr';
import {
  ParsedUserTransferRank,
  TransferTotalStatistics,
  UserTransferRank,
} from '../types/Ranking';
import { UserType } from '../types/UserType';

type WalletType = 'crypto' | 'all' | 'sticky';
type PossibleParams = {
  rankBy: 'receiving' | 'sending';
  page: number | string;
  size: number | string;
  userType: UserType;
  filter: {
    lang: string;
    country: string;
    SelfTransfers: IncludeExcludeAll;
    InactiveUsers: IncludeExcludeAll;
    receiverWalletType: WalletType;
    senderWalletType: WalletType;
    startDate: string;
    endDate: string;
  };
};

function parseData(
  data:
    | PaginatedResponse<UserTransferRank[], TransferTotalStatistics>
    | undefined
): PaginatedResponse<ParsedUserTransferRank[], TransferTotalStatistics> {
  if (!data) {
    return {
      meta: {
        totalCount: 0,
        totalTransferred: 0,
      },
      body: [],
    };
  }
  return {
    meta: data.meta,
    body: data.body.map((user) => ({
      ...user,
      transferred: Number(user.transferred),
    })),
  };
}
export function fetchTransfersRank(
  params?: DeepPartial<PossibleParams>
): SWRResponse<
  PaginatedResponse<ParsedUserTransferRank[], TransferTotalStatistics>
> {
  const parsedParams = parseSearchParams(params ?? {});
  const { data, ...response } = useSWR(
    `admin/users/ranking/transfer?${parsedParams}`
  );
  return {
    ...response,
    data: parseData(data),
  };
}
