import { DEFAULT_INITIAL_PAGE_SIZE } from 'src/lib/constants/pagination';
import useURLSearchParams from 'src/lib/hooks/useURLSearchParams';
import { fetchUserLinks } from '../../services/fetchUserLinks';

export function useUserLinks() {
  const { allSearchParams } = useURLSearchParams(initialParams);
  const size = +(allSearchParams?.size ?? initialParams.size);
  const page = +(allSearchParams?.page ?? initialParams.page);
  const { orderBy } = initialParams;
  const sortOrder = initialParams.order;

  const fetchData = {
    page,
    size,
    order: {
      [orderBy]: sortOrder,
    },
    filter: {},
  };

  const { data, isLoading } = fetchUserLinks(fetchData);

  const params: FetchEntitynotesQueryParams = {
    page,
    size,
    orderBy,
    sortOrder,
  };

  return {
    data,
    isLoading,
    params,
  };
}

const initialParams = {
  page: '0',
  size: DEFAULT_INITIAL_PAGE_SIZE.toString(),
  order: 'desc',
  orderBy: 'createdAt',
};

export type FetchEntitynotesQueryParams = {
  page: number;
  size: number;
  orderBy: string;
  // searchBy: string;
  sortOrder: string;
};
