import { ClubJoinMethods } from 'src/lib/types/club';
import { v4 } from 'uuid';
import ClubForm from '../../components/Forms/Club';
import useCreateClubView from './useView';

export default function CreateClubView(): JSX.Element {
  const { handleCreateClub } = useCreateClubView();
  return (
    <ClubForm
      defaultValues={{
        clubUuid: v4(),
        name: '',
        description: '',
        joinMethod: ClubJoinMethods.PUBLIC_OPT_IN,
        coverFileUrl: '',
        landingPageUrl: '',
        photoFileUrl: '',
        games: [],
        collections: [],
        hasGame: false,
        createdByUserUuid: '',

        objectLinks: [],
        objectLinksTempSearch: '',
      }}
      onSubmit={handleCreateClub}
      title="Create Club"
    />
  );
}
