import {
  CheckBox,
  CheckBoxOutlineBlank,
  ContentCopy,
  OpenInNew,
} from '@mui/icons-material';
import {
  Autocomplete,
  Checkbox,
  MenuItem,
  Stack,
  TextField,
  Tooltip,
} from '@mui/material';
import { capitalCase } from 'change-case';
import CopyToClipboard from 'react-copy-to-clipboard';
import { useNavigate } from 'react-router';
import EldenTable from 'src/components/shared/EldenTable/EldenTable';
import EldenTablePagination from 'src/components/shared/EldenTable/Plugins/EldenTablePagination';
import EldenTableSearchBar from 'src/components/shared/EldenTable/Plugins/EldenTableSearchBar';
import EldenTableCell from 'src/components/shared/EldenTable/Widgets/EldenTableCell';
import EldenTableRow from 'src/components/shared/EldenTable/Widgets/EldenTableRow';
import Link from 'src/components/shared/Link';
import MoreMenu from 'src/components/shared/MoreMenu';
import NumericTypography from 'src/components/shared/NumericTypography';
import {
  FetchRefundsQueryParams,
  RefundReason,
  RefundTypes,
  RefundsList,
} from 'src/lib/services/refunds/types';
import { LabelValue } from 'src/lib/types/labelValue';
import { refundReasons } from 'src/lib/types/revenueEvent';
import { formatDate, getTruncatedText } from 'src/lib/utils/formatters';
import {
  IncludeExcludeDefaultOption,
  IncludeExcludeOptions,
} from 'src/lib/utils/includeExcludeFilterUtils';
import Routes from 'src/routes/Routes';
import Label from 'src/template/components/Label';
import { MaliciousUserLabel } from './MaliciousUserLabel';

const searchForOptions: LabelValue[] = [{ label: 'User', value: 'user' }];

type RefundsTableProps = {
  query: Partial<FetchRefundsQueryParams>;
  data?: RefundsList[];
  total: number;
  loading: boolean;
  hideSearch?: boolean;
  highlightRowId?: string;
  updateQuery: (values: Partial<FetchRefundsQueryParams>) => void;
};

export default function RefundsTable({
  loading,
  query: {
    order,
    orderBy,
    reasons = refundReasons,
    upgradeFilter,
    page,
    size,
    searchFor,
    types = Object.values(RefundTypes),
    query,
  },
  total,
  data: refunds,
  highlightRowId,
  updateQuery,
}: RefundsTableProps) {
  const navigate = useNavigate();
  const selectedReasons = reasons.map((i: string) => ({
    id: i,
    label: capitalCase(i),
  }));

  const shouldHideValueColumns =
    (types[0] === RefundTypes.refundRequest ||
      types[0] === RefundTypes.refundDenied) &&
    types.length === 1;

  return (
    <EldenTable
      data={refunds}
      loading={loading}
      order={order}
      orderBy={orderBy}
      onSort={updateQuery}
      footer={
        <EldenTablePagination
          currentPage={page ?? 0}
          rowsPerPage={size ?? 50}
          rowsRendered={refunds?.length ?? 0}
          onChangeRowsPerPage={updateQuery}
        />
      }
      cardProps={{
        sx: {
          mt: '1rem',
        },
      }}
      header={
        searchFor ? (
          <>
            {!shouldHideValueColumns ? (
              <Autocomplete
                multiple
                disableCloseOnSelect
                isOptionEqualToValue={(item, value) => item?.id === value?.id}
                sx={{
                  width: '1300px',
                }}
                value={selectedReasons}
                renderInput={(params) => (
                  <TextField {...params} label="Reason" />
                )}
                renderOption={(props, option, { selected }) => (
                  <li {...props}>
                    <Checkbox
                      icon={<CheckBoxOutlineBlank />}
                      checkedIcon={<CheckBox />}
                      style={{ marginRight: 8 }}
                      checked={selected}
                    />
                    {option.label}
                  </li>
                )}
                onChange={(_, values) =>
                  updateQuery({
                    reasons: values.map((r) => r.id as RefundReason),
                  })
                }
                options={[
                  ...refundReasons.map((value) => ({
                    label: capitalCase(value),
                    id: value,
                  })),
                ]}
              />
            ) : null}
            <Autocomplete
              sx={{
                width: '500px',
              }}
              disableClearable
              id="upgradeFilter"
              options={IncludeExcludeOptions}
              isOptionEqualToValue={(item, value) =>
                item?.value === value?.value
              }
              value={
                IncludeExcludeOptions.find((o) => o.value === upgradeFilter) ??
                IncludeExcludeDefaultOption
              }
              renderInput={(params) => (
                <TextField {...params} label="Upgrade Filter" />
              )}
              onChange={(_, option) => {
                updateQuery({
                  upgradeFilter: option?.value,
                });
              }}
            />
            <Tooltip
              title="When searching for users, the system will look for its uuid, authId, name, slug or email"
              placement="top"
              arrow
              PopperProps={{
                sx: {
                  textAlign: 'center',
                },
              }}
            >
              <Stack direction="row" gap={2} width="100%">
                <EldenTableSearchBar
                  defaultSearchFor={searchFor}
                  initialValue={query}
                  searchForOptions={searchForOptions}
                  onSearch={updateQuery}
                />
              </Stack>
            </Tooltip>
          </>
        ) : null
      }
    >
      {refunds &&
        refunds.map((r) => (
          <EldenTableRow
            highlight={r.uuid === highlightRowId}
            key={r.uuid}
            linkTo={
              r.userUuid
                ? `/refunds/${r.originalTransactionId}/${r.userUuid}`
                : `/refunds/${r.originalTransactionId}`
            }
          >
            <EldenTableCell id="product" label="Product">
              {r.product}
            </EldenTableCell>
            <EldenTableCell
              id="productType"
              label="Product Type"
              align="center"
            >
              {r.productType}
            </EldenTableCell>
            <EldenTableCell
              id="totalUSD"
              label="Total Refunded ($)"
              align="center"
              hide={shouldHideValueColumns}
              sortable
            >
              {r.eventType === RefundTypes.refund ? (
                <NumericTypography
                  currency="USD"
                  formatDecimal
                  formatDigits
                  fontWeight="normal"
                  fontSize="14px"
                >
                  {-r.totalUSD}
                </NumericTypography>
              ) : (
                <Stack direction="column" gap={1}>
                  <Label variant="ghost" color="default" flex={1}>
                    not appliable
                  </Label>
                </Stack>
              )}
            </EldenTableCell>
            <EldenTableCell
              id="reason"
              label="Reason"
              align="center"
              hide={shouldHideValueColumns}
            >
              {r.reason}
            </EldenTableCell>
            <EldenTableCell
              id="causedByUpgrade"
              label="Caused By Upgrade"
              align="center"
              sortable
            >
              <Stack direction="column" gap={1}>
                <Label color={`${r.causedByUpgrade ? 'success' : 'primary'}`}>
                  {r.causedByUpgrade ? 'Yes' : 'No'}
                </Label>
              </Stack>
            </EldenTableCell>
            {/*
            <EldenTableCell id="provider" label="Provider" align="center">
              {r.provider}
              </EldenTableCell> */}
            <EldenTableCell
              id="user"
              label="User"
              align="center"
              stopPropagation
            >
              {r.userUuid ? (
                <Link to={Routes.user(r.userUuid)}>
                  {r.userSlug ||
                    r.userName ||
                    r.userEmail ||
                    getTruncatedText(r.userUuid, 4, 3)}
                </Link>
              ) : null}
            </EldenTableCell>
            <EldenTableCell id="userStatus" label="User Status" align="center">
              {r.userUuid ? (
                <Stack direction="column" gap={1}>
                  <MaliciousUserLabel
                    isBlocked={r.blocked}
                    isFlagged={r.flagged}
                  />
                </Stack>
              ) : null}
            </EldenTableCell>

            <EldenTableCell
              id="userHasFeedback"
              label="User has feedback"
              align="center"
              stopPropagation
            >
              {r.userHasFeedback ? (
                <Tooltip title="View Feedbacks" placement="top">
                  <Link
                    to={`${Routes.feedbacks}?searchFor=user.uuid&query=${r.userUuid}`}
                    target="_blank"
                  >
                    <Stack direction={'row'} justifyContent={'center'} gap={1}>
                      <Label color={'success'}>{'Yes'}</Label>
                      <OpenInNew />
                    </Stack>
                  </Link>
                </Tooltip>
              ) : (
                <Stack>
                  <Label color={'primary'}>{'No'}</Label>
                </Stack>
              )}
            </EldenTableCell>

            <EldenTableCell
              id="refundHasNote"
              label="Refund Has Note"
              align="center"
            >
              <Stack>
                <Label color={r.refundHasNote ? 'success' : 'primary'}>
                  {r.refundHasNote ? 'Yes' : 'No'}
                </Label>
              </Stack>
            </EldenTableCell>

            <EldenTableCell
              id="originalPurchaseDate"
              label="Original Purchase Date"
              align="center"
            >
              {formatDate(r.originalPurchaseDate)}
            </EldenTableCell>

            <EldenTableCell
              id="purchaseDate"
              label="Purchase Date"
              align="center"
            >
              {formatDate(r.purchaseDate)}
            </EldenTableCell>

            <EldenTableCell
              id="eventDate"
              label="Event Date"
              align="right"
              sortable
            >
              {formatDate(r.eventDate)}
            </EldenTableCell>
            <EldenTableCell
              id="actions"
              label=""
              align="center"
              stopPropagation
              hide={shouldHideValueColumns}
            >
              <MoreMenu>
                <CopyToClipboard text={r.uuid}>
                  <MenuItem aria-label="copy uuid">
                    <ContentCopy />
                    Copy UUID
                  </MenuItem>
                </CopyToClipboard>
              </MoreMenu>
            </EldenTableCell>
          </EldenTableRow>
        ))}
    </EldenTable>
  );
}
