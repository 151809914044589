import ConfirmationDialogRaw from 'src/components/shared/Dialog/ConfirmationDialog';
import useModalStore from 'src/lib/stores/useModalStore';
import useDeleteEntityNote from './useView';

type Props = {
  uuid: string;
};
export default function DeleteEntityNote({ uuid }: Props): JSX.Element {
  const { handleDeleteEntityNote } = useDeleteEntityNote();
  const closeModal = useModalStore((state) => state.closeModal);

  return (
    <ConfirmationDialogRaw
      onClose={closeModal}
      open
      title={`Delete this note`}
      onConfirm={async () => {
        await handleDeleteEntityNote(uuid);
      }}
    >
      Are you sure you want to delete this Entity Note?
    </ConfirmationDialogRaw>
  );
}
