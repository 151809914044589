import GamesStats from 'src/components/modules/Games/view/ListStats';
import Page from 'src/template/components/Page';

export default function GamesAnalyticsPage() {
  return (
    <Page title="Games Analytics">
      <GamesStats />
    </Page>
  );
}
