import { GridValidRowModel, GridValueGetterParams } from '@mui/x-data-grid';
import { format } from 'date-fns';
import { AppPlatforms } from '../types/collection';
import { CurrenciesType } from '../types/currency';
import formatStickyCoinValue from './formatStickyCoinValue';

export const formatOptionalTableData = (data: any) => data.value || '-';

export const formatOptionalTableDataDate = <T extends GridValidRowModel>(
  params: GridValueGetterParams<any, T>
) => {
  const { value } = params;

  return value
    ? new Date(value).toLocaleDateString()
    : formatOptionalTableData(params);
};

export const formatDate = (
  milliseconds: string | number | undefined | null
) => {
  if (!milliseconds) return '-';
  return `${format(+milliseconds, 'dd/MM/yyyy')}`;
};

/**
 * Formats string into a truncated string such as: 'Hello World' => '...llo Wo...'
 * @param {string} id - String to truncate
 * @param {number} left - Amount of characters to show on the left
 * @param {number} right - Amount of characters to show on the right
 */
export const getTruncatedText = (
  id: string | undefined,
  left: number,
  right: number
) =>
  id ? `${id.slice(0, left)}...${id.slice(id.length - right, id.length)}` : '';

const STICKY_COIN_VALUE = 0.1;
/**
 * Convert amount of coins to value in USD
 * @param value amount of coins
 * @returns value in USD
 */
export const convertStickyCoinToUSD = (value: number): number =>
  value * STICKY_COIN_VALUE;

/**
 * Convert amount of coins to value in USD using inflation rate
 * @param value amount of coins
 * @returns value in USD
 */
export const convertStickyCoinToUSDWithInflation = (value: number): string =>
  convertStickyCoinToUSD(formatStickyCoinValue(value)).toLocaleString('en-US', {
    style: 'currency',
    currency: 'USD',
  });

/**
 * Currencies into toLocaleString currency
 * @param {PaymentCurrencies} currency - Currency to convert
 * @returns {string} - Currency in toLocaleString format
 */
export const formatLocaleStringCurrency = (
  currency: CurrenciesType
): string => {
  switch (currency) {
    case 'USD':
      return 'en-US';
    default:
      return 'en-US';
  }
};

/**
 * Format App Platforms into a readable string
 * @param {AppPlatforms} platform - Platform to convert
 * @returns {string} - Platform in readable format
 */
export const formatAppPlatform = (
  platform: AppPlatforms | undefined
): string => {
  switch (platform) {
    case AppPlatforms.web:
      return 'Web';
    case AppPlatforms.ios:
      return 'iOS';
    case AppPlatforms.android:
      return 'Android';
    case AppPlatforms.imported:
      return 'Imported';
    default:
      return '-';
  }
};

/**
 * Formats User's slug
 * @param {string} slug - User's slug
 * @returns {string} - Formatted slug
 */
export const formatUserSlug = (slug: string): string => {
  if (slug === 'null') return 'N/A';
  return `@${slug}`;
};

/**
 * Formats number in compact notation (e.g. 1.2k)
 * @param num Number to format
 * @returns {string} Formatted number in compact notation
 */
const kFormatter = (num: number | string): string =>
  Intl.NumberFormat('en', {
    notation: 'compact',
    maximumFractionDigits: 1,
  })
    .format(Number(num))
    .toLowerCase();

export default kFormatter;
