import useModalStore from 'src/lib/stores/useModalStore';

export default function useModerationDetailsModal() {
  const onClose = useModalStore((state) => state.closeModal);

  const handleCloseModal = () => {
    onClose();
  };

  return {
    handleCloseModal,
  };
}
