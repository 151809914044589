import { Edit } from '@mui/icons-material';
import { Button, Card, CardContent, CardHeader, Stack } from '@mui/material';
import { capitalCase } from 'change-case';
import Flex from 'src/components/shared/Flex';
import MagicTableUserCell from 'src/components/shared/MagicTable/UserCell';
import SectionDivider from 'src/components/shared/SectionDivider';
import SimpleTable from 'src/components/shared/SimpleTable/SimpleTable';
import UrlExibition from 'src/components/shared/UrlExibition';
import { formatUTCDate } from 'src/lib/utils/formatUTCDate';
import Routes from 'src/routes/Routes';
import HeaderBreadcrumbs from 'src/template/components/HeaderBreadcrumbs';
import LoadingScreen from 'src/template/components/LoadingScreen';
import ReviewHistoryTable from '../../components/ReviewHistoryTable';
import useSubmissionDetailsView from './useView';

export default function SubmissionDetails() {
  const { submissionDetails, handleOpenChangeStatusModal } =
    useSubmissionDetailsView();

  return submissionDetails ? (
    <>
      <HeaderBreadcrumbs
        heading={submissionDetails.name || 'Empty Name'}
        links={[
          { name: 'Submissions', href: Routes.submission.submissions },
          { name: submissionDetails.name || 'Empty Name' },
        ]}
        action={
          <Button
            variant="contained"
            color="secondary"
            onClick={handleOpenChangeStatusModal}
            startIcon={<Edit />}
          >
            Review Submission
          </Button>
        }
      />
      <SectionDivider section="Submission" />
      <Stack width="100%" gap={2}>
        <Card sx={{ flex: 1 }}>
          <Flex justifyContent="space-between" alignItems="center" pr={2}>
            <CardHeader title="Details" />
          </Flex>
          <CardContent>
            <SimpleTable
              border
              rounded
              data={[
                {
                  id: 'name',
                  label: 'Name',
                  value: submissionDetails.name,
                },
                {
                  id: 'uuid',
                  label: 'UUID',
                  value: submissionDetails.uuid,
                },
                {
                  id: 'submittedByUser',
                  label: 'Submitted By',
                  value: (
                    <MagicTableUserCell
                      user={submissionDetails.submittedByUser}
                    />
                  ),
                },
                {
                  id: 'latestStatus',
                  label: 'Latest Status',
                  value: capitalCase(submissionDetails.latestStatus),
                },
                {
                  id: 'createdAt',
                  label: 'Created At',
                  value: formatUTCDate(submissionDetails.createdAt),
                },
                {
                  id: 'updatedAt',
                  label: 'Updated At',
                  value: formatUTCDate(submissionDetails.updatedAt),
                },
                {
                  id: 'deletedAt',
                  label: 'Deleted At',
                  value: submissionDetails.deletedAt
                    ? formatUTCDate(submissionDetails.deletedAt)
                    : '--',
                },
              ]}
            />
          </CardContent>
        </Card>
        <SectionDivider section="Item Data" />
        <Card sx={{ flex: 1 }}>
          <Flex justifyContent="space-between" alignItems="center" pr={2}>
            <CardHeader
              title={`${capitalCase(submissionDetails.itemType)} Data`}
            />
          </Flex>
          <CardContent>
            <Stack direction={'row'} gap={2}>
              <SimpleTable
                border
                rounded
                data={[
                  {
                    id: 'gameUrl',
                    label: 'URL',
                    value: (
                      <UrlExibition url={submissionDetails.itemData.gameUrl} />
                    ),
                  },
                  {
                    id: 'secondaryLink',
                    label: 'Secondary Link',
                    value: (
                      <UrlExibition
                        url={submissionDetails.itemData.secondaryLink}
                      />
                    ),
                  },
                  {
                    id: 'gameImageUrl',
                    label: 'Image',
                    value: submissionDetails.itemData.gameImageUrl,
                  },
                  {
                    id: 'description',
                    label: 'Description',
                    value: submissionDetails.itemData.description,
                  },
                  {
                    id: 'tags',
                    label: 'Tags',
                    value: submissionDetails.itemData.tags.join(', '),
                  },
                  {
                    id: 'category',
                    label: 'Category',
                    value: submissionDetails.itemData.category,
                  },
                  {
                    id: 'gameModeUuids',
                    label: 'Game Modes',
                    value: submissionDetails.itemData.gameModeUuids.join(', '),
                  },
                  {
                    id: 'ageRating',
                    label: 'Age Rating',
                    value: submissionDetails.itemData.ageRating,
                  },
                ]}
              />
              <SimpleTable
                border
                rounded
                data={[
                  {
                    id: 'versionName',
                    label: 'Version',
                    value: submissionDetails.itemData.versionName,
                  },
                  {
                    id: 'orientation',
                    label: 'Orientation',
                    value: submissionDetails.itemData.orientation,
                  },
                  {
                    id: 'developersNames',
                    label: 'Developer Name',
                    value: submissionDetails.itemData.developersNames,
                  },
                  {
                    id: 'externalPublisherName',
                    label: 'External Publisher Name',
                    value: submissionDetails.itemData.externalPublisherName,
                  },
                  {
                    id: 'authorizationType',
                    label: 'Authorization Type',
                    value: capitalCase(
                      submissionDetails.itemData.authorizationType
                    ),
                  },
                  {
                    id: 'acceptedTermsAndConditions',
                    label: 'Accepted Terms And Conditions',
                    value: submissionDetails.itemData.acceptedTermsAndConditions
                      ? 'Yes'
                      : 'No',
                  },
                  {
                    id: 'isOptimizedForMobile',
                    label: 'Optimized For Mobile',
                    value: submissionDetails.itemData.isOptimizedForMobile
                      ? 'Yes'
                      : 'No',
                  },
                  {
                    id: 'hasAds',
                    label: 'Has Ads',
                    value: submissionDetails.itemData.hasAds ? 'Yes' : 'No',
                  },
                  {
                    id: 'supportingDocumentUrl',
                    label: 'Supporting Documents',
                    value: (
                      <Stack>
                        {submissionDetails.itemData.supportingDocumentUrl.map(
                          (url: string) => (
                            <UrlExibition key={url} url={url} />
                          )
                        )}
                      </Stack>
                    ),
                  },
                ]}
              />
            </Stack>
          </CardContent>
        </Card>
      </Stack>

      <>
        <SectionDivider section="Review History" />
        <ReviewHistoryTable
          data={submissionDetails.reviewHistory}
          isLoading={false}
        />
      </>
    </>
  ) : (
    <LoadingScreen />
  );
}
