import { DeepPartial } from 'react-hook-form';
import { NftsIntoNftPack } from 'src/lib/types/rarity';
import { parseSearchParams } from 'src/lib/utils/parseSearchParams';
import useSWR, { SWRResponse } from 'swr';

export const fetchNftsInPack = (
  uuid: string,
  filters?: DeepPartial<PossibleParams>
): SWRResponse<NftsIntoNftPack[]> => {
  const response = useSWR(
    `admin/nft-pack/${uuid}/nfts?${parseSearchParams(filters ?? {})}`
  );

  return response;
};

type PossibleParams = {
  filter: {
    name: {
      like: string;
    };
    'collection.name': {
      like: string;
    };
  };
  order: Record<string, string>;
};
