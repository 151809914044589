import { useState } from 'react';
import { CommonDateStrings } from 'src/lib/constants/CommonDates';
import { SearchParams } from 'src/lib/types/SearchParams';
import { fetchPoolsHealth } from '../../services/fetchPoolsHealth';
import { FilterFormValues } from './types';

const refreshInterval = 10000; // 10 secs

const defaultValues: FilterFormValues = {
  startDate: CommonDateStrings.sevenDaysAgo.dateTime,
  endDate: CommonDateStrings.today.dateTime,
};

export default function usePoolsStatusView() {
  const [filters, setFilters] = useState<SearchParams>({});
  const { isLoading, data } = fetchPoolsHealth({
    swrConfig: {
      refreshInterval,
    },
    params: {
      ...defaultValues,
      ...filters,
    },
  });

  function handleFilter({ endDate, startDate }: FilterFormValues): void {
    setFilters({
      startDate,
      endDate,
    });
  }

  return {
    pools: data ?? [],
    isLoadingPools: isLoading,
    handleFilter,
    defaultValues,
  };
}
