import useNotify from 'src/lib/hooks/useNotify';
import { bulkCreateNft } from 'src/lib/services/nft';
import useModalStore from 'src/lib/stores/useModalStore';
import { getMessageFromError } from 'src/lib/utils/error';
import formatStickyCoinValue from 'src/lib/utils/formatStickyCoinValue';
import { uploadMultipleFiles } from 'src/lib/utils/upload';
import { type AddNftsFormValues } from './types';

export default function useAddNftsModal() {
  const notify = useNotify();
  const closeModal = useModalStore((state) => state.closeModal);

  const submitHandler = async (
    values: AddNftsFormValues,
    collectionId: string
  ) => {
    try {
      const { files } = values;
      const { editions, name, price, tags } = values.nftData;
      const fileData = await uploadMultipleFiles(collectionId, files);
      const nfts = await bulkCreateNft(collectionId, {
        nftData: {
          name,
          editions,
          price: price ? formatStickyCoinValue(price, 'mul') : undefined,
          tags: tags.split(','),
        },
        fileData,
      });
      notify('NFTs created successfully', { variant: 'success' });
      return {
        data: nfts,
        error: null,
      };
    } catch (e) {
      const errorMessage = getMessageFromError(e);
      notify(errorMessage, { variant: 'error' });
      return {
        data: null,
        error: errorMessage,
      };
    }
  };

  return {
    submitHandler,
    closeModal,
  };
}
