import getAPIClient from 'src/lib/services/api/axios.config';
import { ServiceResponse } from 'src/lib/types/ServiceResponse';
import { getMessageFromError } from 'src/lib/utils/error';
import { PayoutCountry } from '../types/PayoutCountries';

type CreatePayoutCountryData = Pick<PayoutCountry, 'name' | 'active' | 'code'>;

export async function createPayoutCountry(
  data: CreatePayoutCountryData
): Promise<ServiceResponse<PayoutCountry>> {
  try {
    const api = await getAPIClient();
    const res = await api.post('admin/payout-countries', data);
    return {
      data: res.data,
      error: null,
    };
  } catch (e) {
    return {
      data: null,
      error: getMessageFromError(e),
    };
  }
}
