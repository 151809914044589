import getAPIClient from 'src/lib/services/api/axios.config';
import { FeedItem } from 'src/lib/types/FeedItem';
import { ServiceResponse } from 'src/lib/types/ServiceResponse';
import { getMessageFromError } from 'src/lib/utils/error';

export async function UpdateFeedItem(
  data: FeedItem
): Promise<ServiceResponse<FeedItem>> {
  try {
    const api = await getAPIClient();
    const res = await api.put(`admin/feed-item/${data.uuid}`, data);
    return {
      data: res.data,
      error: null,
    };
  } catch (e) {
    return {
      data: null,
      error: getMessageFromError(e),
    };
  }
}
