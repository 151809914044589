import useNotify from 'src/lib/hooks/useNotify';
import useModalStore from 'src/lib/stores/useModalStore';
import { ContestFormValues } from '../../components/Forms/Contest/types';
import { ContestEmitter } from '../../events/ContestEmitter';
import { CreateContest } from '../../services/createContest';

export default function useCreateContestView() {
  const closeModal = useModalStore((state) => state.closeModal);
  const notify = useNotify();

  const handleCreateContest = async (values: ContestFormValues) => {
    const { error, data } = await CreateContest({
      ...values,
      awardConfiguration: values.positionAwards.map((award) => award.value),
    });

    if (error) {
      notify(error, { variant: 'error' });
      closeModal();
    } else if (data) {
      notify('Contest Created!', { variant: 'success' });
      closeModal();

      ContestEmitter.emit('ContestCreated', data);
    }
  };

  return {
    handleCreateContest,
  };
}
