import { Container } from '@mui/material';
import ModeratedObjects from 'src/components/modules/Review/view/ListModeratedObjects';
import Page from 'src/template/components/Page';

export default function ObjectsToModeratePage() {
  return (
    <Page title="To Review">
      <Container maxWidth="xl">
        <ModeratedObjects />
      </Container>
    </Page>
  );
}
