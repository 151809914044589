import { OpenInNew } from '@mui/icons-material';
import { IconButton, Link } from '@mui/material';
import Flex from './Flex';

export default function UrlExibition({ url }: { url: string }) {
  return (
    <Flex>
      <Link href={url}>{url}</Link>
      <Link href={url} target="_blank" rel="noopener noreferrer">
        <IconButton color="primary" size="small">
          <OpenInNew />
        </IconButton>
      </Link>
    </Flex>
  );
}
