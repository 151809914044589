import { Container } from '@mui/material';
import IapAppleReceipt from 'src/components/modules/IapTransactions/IapAppleReceipt/IapAppleReceipt';
import Page from 'src/template/components/Page';

export default function IapAppleReceiptPage() {
  return (
    <Page title="Apple Receipt">
      <Container maxWidth="xl">
        <IapAppleReceipt />
      </Container>
    </Page>
  );
}
