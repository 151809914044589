import ConfirmationDialogRaw from 'src/components/shared/Dialog/ConfirmationDialog';
import useModalStore from 'src/lib/stores/useModalStore';
import useChangeChannelIsSticky from './useView';

type Props = {
  uuid: string;
  isSticky: boolean;
};
export default function ChangeChannelIsStickyView({
  uuid,
  isSticky,
}: Props): JSX.Element {
  const { handleChangeChannelIsSticky } = useChangeChannelIsSticky();
  const closeModal = useModalStore((state) => state.closeModal);

  return (
    <ConfirmationDialogRaw
      onClose={closeModal}
      open
      title={`${isSticky ? 'Mark' : 'Unmark'} Channel as by Sticky`}
      onConfirm={async () => {
        await handleChangeChannelIsSticky(uuid, isSticky);
      }}
      invertColors={false}
    >
      Are you sure you want to {isSticky ? 'mark' : 'unmark'} this Channel as by
      Sticky?
    </ConfirmationDialogRaw>
  );
}
