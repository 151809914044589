import { DataGrid, DataGridProps } from '@mui/x-data-grid';
import MagicTableContainer from './Container';
import MagicTableFilterContainer from './FilterContainer';
import MagicTableTabs from './Tabs';
import MagicTableWrapper from './Wrapper';

type Props = DataGridProps & {
  hideNumberOfRows?: boolean;
};
function MagicTable({
  columns,
  hideNumberOfRows = false,
  ...props
}: Props): JSX.Element {
  const { pageSize, rows } = props;

  return (
    <DataGrid
      autoHeight
      disableColumnFilter
      hideFooterSelectedRowCount
      sortingOrder={['desc', 'asc']}
      columns={columns.map((c) => {
        const hasToDisableSortComparator = Boolean(
          c.sortable !== false && props.paginationMode === 'server'
        );

        return {
          flex: 1,
          ...(hasToDisableSortComparator && {
            sortComparator: () => 0,
          }),
          ...c,
        };
      })}
      getRowId={(r) => r.uuid}
      {...(hideNumberOfRows && {
        rowCount: Number.MAX_SAFE_INTEGER,
        localeText: {
          MuiTablePagination: {
            labelDisplayedRows: ({ from, to, count }) => {
              if (rows.length === 0) {
                return 'Empty';
              }
              if (pageSize && rows.length < pageSize) {
                return `${from} - ${from + rows.length - 1}`;
              }
              return `${from} - ${to} of ${
                count === Number.MAX_SAFE_INTEGER ? 'many' : count
              }`;
            },
            nextIconButtonProps: {
              disabled:
                rows.length === 0 ||
                Boolean(pageSize && rows.length < pageSize),
            },
          },
        },
      })}
      {...props}
      sx={{
        fontVariantNumeric: 'tabular-nums',
        ...props.sx,
      }}
    />
  );
}
MagicTable.Container = MagicTableContainer;
MagicTable.Tabs = MagicTableTabs;
MagicTable.Wrapper = MagicTableWrapper;
MagicTable.FilterContainer = MagicTableFilterContainer;

export default MagicTable;
