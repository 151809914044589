import {
  AccessTime,
  AccessTimeFilled,
  CollectionsRounded,
  CurrencyBitcoin,
  Devices,
  Difference,
  PriceChange,
  PriceCheck,
  QrCode2Rounded,
  QrCodeRounded,
  TroubleshootRounded,
} from '@mui/icons-material';
import {
  Card,
  CardContent,
  CardHeader,
  Stack,
  SxProps,
  Theme,
  Typography,
} from '@mui/material';
import { capitalCase } from 'change-case';
import { Collection } from 'src/lib/types/collection';
import { NFT } from 'src/lib/types/nft';
import { User } from 'src/lib/types/users';
import formatStickyCoinValue from 'src/lib/utils/formatStickyCoinValue';
import { formatAppPlatform, formatDate } from 'src/lib/utils/formatters';

type AboutData = {
  label: string;
  value: string | number;
  icon?: React.ReactNode;
  sx?: SxProps<Theme> | undefined;
};

export default function CollectionAbout({
  collection,
}: {
  collection: Collection<User, NFT>;
}) {
  const aboutData: AboutData[] = [
    {
      label: 'UUID',
      value: collection.uuid,
      icon: <QrCodeRounded />,
      sx: { fontSize: '12px' },
    },
    {
      label: 'NFTs',
      value: collection.nftsCount,
      icon: <CollectionsRounded />,
    },
    {
      label: 'Price Type',
      value: capitalCase(collection.priceType ?? '-'),
      icon: <PriceCheck />,
    },
    {
      label: 'Floor Price',
      value: formatStickyCoinValue(collection.floorPrice),
      icon: <PriceChange />,
    },
    {
      label: 'Review Status',
      value: capitalCase(collection.reviewStatus ?? '-'),
      icon: <TroubleshootRounded />,
    },
    /*
    {
      label: 'Human Analysis',
      value: capitalCase(collection.humanAnalysis),
      icon: <SupervisedUserCircleRounded />,
    },
    {
      label: 'A.I. Analysis',
      value: capitalCase(collection.aiAnalysis),
      icon: <PhotoFilterRounded />,
    },
    {
      label: 'Revalidate',
      value: `${collection.revalidate}`,
      icon: <PublishedWithChanges />,
    },
    */
    {
      label: 'Blockchain',
      value: capitalCase(collection.blockchain ?? '-'),
      icon: <CurrencyBitcoin />,
    },
    {
      label: 'Contract Address',
      value: collection.mintToken ?? '-',
      icon: <QrCode2Rounded />,
      sx: { fontSize: '12px', wordBreak: 'break-all' },
    },
    {
      label: 'Shared Contract',
      value: String(collection.isSharedCollection),
      icon: <Difference />,
    },
    {
      label: 'Platform',
      value: formatAppPlatform(collection.platform),
      icon: <Devices />,
    },
    {
      label: 'Updated At',
      value: formatDate(collection.updatedAt),
      icon: <AccessTimeFilled />,
    },
    {
      label: 'Created At',
      value: formatDate(collection.createdAt),
      icon: <AccessTime />,
    },
  ];

  return (
    <Card>
      <CardHeader title="About" />
      <CardContent>
        <Stack spacing={1}>
          {aboutData.map((data) => (
            <Stack
              direction="row"
              spacing={2}
              justifyContent="space-between"
              alignItems="center"
              key={data.label}
            >
              <Stack direction="row" spacing={1} alignItems="center">
                {data.icon}
                <Typography fontSize="14px">{data.label}:</Typography>
              </Stack>
              <Typography
                fontSize="14px"
                fontWeight="bold"
                textAlign="right"
                sx={{ ...data.sx }}
              >
                {data.value}
              </Typography>
            </Stack>
          ))}
        </Stack>
      </CardContent>
    </Card>
  );
}
