import ConfirmationDialogRaw from 'src/components/shared/Dialog/ConfirmationDialog';
import useModalStore from 'src/lib/stores/useModalStore';
import useDeleteAppVideoFeed from './useView';

type Props = {
  uuid: string;
};
export default function DeleteAppVideoFeedView({ uuid }: Props): JSX.Element {
  const { handleDeleteAppVideoFeed } = useDeleteAppVideoFeed();
  const closeModal = useModalStore((state) => state.closeModal);

  return (
    <ConfirmationDialogRaw
      onClose={closeModal}
      open
      title={`Delete this Feed`}
      onConfirm={async () => {
        await handleDeleteAppVideoFeed(uuid);
      }}
    >
      Are you sure you want to delete this App Feed?
    </ConfirmationDialogRaw>
  );
}
