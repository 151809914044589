import { useState } from 'react';
import useNotify from 'src/lib/hooks/useNotify';
import useModalStore from 'src/lib/stores/useModalStore';
import SendChannelContentToFeedView from '../../view/SendVideoFeed';

export default function useChannelContentTable(
  onChangeChannelContentStatus: (
    uuid: string,
    channelUuid: string,
    status: 'active' | 'inactive'
  ) => Promise<void>
) {
  const notify = useNotify();
  const openModal = useModalStore((state) => state.openModal);

  const handleOpenSendToAppVideoFeedModal = (uuid: string) => {
    openModal(<SendChannelContentToFeedView uuid={uuid} />);
  };

  // status
  const [rowUuidChangingStatus, setRowUuidChangingStatus] = useState<string>();

  function handleChannelContentStatusChange(
    uuid: string,
    channelUuid: string,
    currentStatus: 'active' | 'inactive'
  ) {
    setRowUuidChangingStatus(uuid);
    onChangeChannelContentStatus?.(uuid, channelUuid, currentStatus).finally(
      () => {
        setRowUuidChangingStatus(undefined);
      }
    );
  }

  const isChangingStatus = !!rowUuidChangingStatus;

  return {
    handleOpenSendToAppVideoFeedModal,
    handleChannelContentStatusChange,
    isChangingStatus,
    rowUuidChangingStatus,
  };
}
