import { format } from 'date-fns';
import { getMessageFromError } from 'src/lib/utils/error';
import objectEntries from 'src/lib/utils/objectEntries';
import useSWR, { SWRConfiguration, SWRResponse } from 'swr';
import {
  EMSHealth,
  EMSHealthWorker,
  ParsedEMSHealth,
} from '../types/EMSHealth';

const emsWorkerLabel: Record<EMSHealthWorker, string> = {
  importCollections: 'Import Collections',
  streamOrders: 'Stream Orders',
  syncWallet: 'Sync Wallet',
};

function parseData(data: EMSHealth | undefined): ParsedEMSHealth | undefined {
  if (!data) return undefined;

  return objectEntries(data).map(([key, value]) => ({
    isWorking: value?.work ?? false,
    lastCheck: value?.lastCheck
      ? format(new Date(value.lastCheck), 'dd/MM/yyyy hh:MM')
      : '--',
    name: key,
    parsedName: emsWorkerLabel[key],
  }));
}

export const fetchEMSHealth = (
  config?: SWRConfiguration
): SWRResponse<ParsedEMSHealth> => {
  const { data, error, ...response } = useSWR(`admin/health/ems`, null, config);

  return {
    ...response,
    data: parseData(data),
    error: error ? getMessageFromError(error) : undefined,
  };
};
