import { ContestEmitter } from 'src/components/modules/Contests/events/ContestEmitter';
import useNotify from 'src/lib/hooks/useNotify';
import useModalStore from 'src/lib/stores/useModalStore';
import { ContestStatus } from 'src/lib/types/contest';
import { ChangeContestStatus } from '../../services/changeContestStatus';

export default function useChangeContestStatus() {
  const closeModal = useModalStore((state) => state.closeModal);
  const notify = useNotify();

  const handleChangeContestStatus = async (
    uuid: string,
    status: ContestStatus
  ) => {
    const { data, error } = await ChangeContestStatus(uuid, status);
    if (error) {
      notify(error, { variant: 'error' });
      closeModal();
    } else {
      notify('Contest status updated succesfully!', { variant: 'success' });
      closeModal();
      ContestEmitter.emit('ContestUpdated', data);
    }
  };

  return {
    handleChangeContestStatus,
  };
}
