import useNotify from 'src/lib/hooks/useNotify';
import useModalStore from 'src/lib/stores/useModalStore';
import { PayoutCountryFormValues } from '../../components/Form/types';
import { PayoutCountryEmitter } from '../../events/PayoutCountryEmitter';
import { createPayoutCountry } from '../../services/createPayoutCountry';

export default function useCreatePayoutCountry() {
  const closeModal = useModalStore((state) => state.closeModal);
  const notify = useNotify();

  const handleCreatePayoutCountry = async (values: PayoutCountryFormValues) => {
    const { error, data } = await createPayoutCountry({
      code: values.code,
      name: values.name,
      active: values.active === 'true',
    });
    if (error) {
      notify(error, { variant: 'error' });
      closeModal();
    } else if (data) {
      notify('Payout Country created!', { variant: 'success' });
      closeModal();
      PayoutCountryEmitter.emit('payoutCountryCreated', data);
    }
  };

  return {
    handleCreatePayoutCountry,
  };
}
