import { ContentCopy, Delete, Edit } from '@mui/icons-material';
import { Box, MenuItem } from '@mui/material';
import { GridSortDirection } from '@mui/x-data-grid';
import { capitalCase } from 'change-case';
import CopyToClipboard from 'react-copy-to-clipboard';
import Link from 'src/components/shared/Link';
import MagicTable from 'src/components/shared/MagicTable/MagicTable';
import MagicTableUserCell from 'src/components/shared/MagicTable/UserCell';
import MoreMenu from 'src/components/shared/MoreMenu';
import useURLSearchParams from 'src/lib/hooks/useURLSearchParams';
import { Submission } from 'src/lib/types/submission';
import { formatUTCDate } from 'src/lib/utils/formatUTCDate';
import Routes from 'src/routes/Routes';
import { ListSubmissionsParams } from '../../view/List/useView';
import useSubmissionTable from './useView';

interface SubmissionsTableProps {
  data: Submission[];
  totalCount: number;
  isLoading: boolean;
  params: ListSubmissionsParams;
  handleOpenUpdateModal?: (Submission: Submission) => void;
  handleOpenDeleteModal?: (uuid: string) => void;
}
export default function SubmissionsTable({
  data,
  totalCount,
  isLoading,
  params,
  handleOpenUpdateModal,
  handleOpenDeleteModal,
}: SubmissionsTableProps) {
  const { addParam, removeParam } = useURLSearchParams();
  const a = useSubmissionTable();

  return (
    <MagicTable.Container>
      <Box px={2}>
        <MagicTable
          rows={data ?? []}
          rowCount={totalCount ?? 0}
          getRowId={(r) => r.uuid}
          loading={isLoading}
          paginationMode="server"
          pageSize={params.size}
          page={params.page}
          onPageChange={(newPage) => {
            addParam('page', newPage);
          }}
          onPageSizeChange={(newSize) => {
            addParam('size', newSize);
          }}
          sortModel={[
            { field: params.sortBy, sort: params.order as GridSortDirection },
          ]}
          onSortModelChange={(model) => {
            const currentModel = model[0];
            if (currentModel) {
              addParam('sortBy', currentModel.field);
              if (currentModel.sort) addParam('order', currentModel.sort);
            } else {
              removeParam('order');
              removeParam('sortBy');
            }
          }}
          columns={[
            {
              field: 'submittedByUser',
              headerName: 'Submited By',
              sortable: false,
              renderCell({ row }) {
                return <MagicTableUserCell user={row.submittedByUser} />;
              },
            },
            {
              field: 'itemType',
              headerName: 'Item Type',
              sortable: true,
              sortingOrder: ['asc', 'desc'],
              valueFormatter({ value }) {
                return capitalCase(value) ?? '--';
              },
            },
            {
              field: 'name',
              headerName: 'Name',
              sortable: true,
              sortingOrder: ['asc', 'desc'],
              renderCell({ row }) {
                return (
                  <Link to={Routes.submission.submission(row.uuid)}>
                    {row.name}
                  </Link>
                );
              },
            },
            {
              field: 'versionName',
              headerName: 'Version',
            },
            {
              field: 'latestStatus',
              headerName: 'Status',
              sortable: false,
              valueFormatter({ value }) {
                return capitalCase(value) ?? '--';
              },
            },
            {
              field: 'createdAt',
              headerName: 'Created At',
              sortable: true,
              valueGetter: (_params) => formatUTCDate(_params.value),
            },
            {
              field: 'updatedAt',
              headerName: 'Updated At',
              sortable: true,
              valueGetter: (_params) => formatUTCDate(_params.value),
            },
            /*
            {
              field: 'deletedAt',
              headerName: 'Deleted At',
              sortable: true,
              valueGetter: (params) => params.value ? formatUTCDate(params.value) : '--',
            },
            */
            {
              field: 'actions',
              headerName: 'Actions',
              align: 'center',
              sortable: false,
              flex: 0,
              renderCell({ row }) {
                return (
                  <MoreMenu>
                    <CopyToClipboard text={row.uuid}>
                      <MenuItem aria-label="copy feed uuid">
                        <ContentCopy />
                        Copy UUID
                      </MenuItem>
                    </CopyToClipboard>
                    {handleOpenUpdateModal && (
                      <MenuItem
                        onClick={() => {
                          handleOpenUpdateModal(row);
                        }}
                      >
                        <Edit />
                        Update
                      </MenuItem>
                    )}
                    {handleOpenDeleteModal && (
                      <MenuItem
                        onClick={() => {
                          handleOpenDeleteModal(row.uuid);
                        }}
                      >
                        <Delete />
                        Delete
                      </MenuItem>
                    )}
                  </MoreMenu>
                );
              },
            },
          ]}
        />
      </Box>
    </MagicTable.Container>
  );
}
