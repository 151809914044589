import { fetchPaymentRequestsByUser } from 'src/components/modules/Payments/services/fetchPaymentByUser';
import usePaymentsTable from 'src/components/modules/Payments/usePaymentsTable';
import { PaymentRequestStatus } from 'src/lib/types/paymentRequest';
import useTabs from 'src/template/hooks/useTabs';

export default function usePaymentHistory(userUuid: string) {
  const { currentTab: status, onChangeTab: onChangeReviewStatus } = useTabs(
    'all',
    {
      queryName: 'subtab',
    }
  );
  const { filter } = usePaymentsTable((state) => state);

  const [setQuery, setSearchFor] = usePaymentsTable((state) => [
    state.setQuery,
    state.setSearchFor,
  ]);

  const { data: paymentRequests, total } = fetchPaymentRequestsByUser(
    userUuid,
    {
      ...filter,
    },
    status as PaymentRequestStatus | 'all'
  );

  return {
    paymentRequests,
    total,
    status,
    filter,
    onChangeReviewStatus,
    setQuery,
    setSearchFor,
  };
}
