import useNotify from 'src/lib/hooks/useNotify';
import useModalStore from 'src/lib/stores/useModalStore';
import { NFTPackFormValues } from '../../../components/Forms/NftPack/types';
import { NftPackEmitter } from '../../../events/NftPackEmitter';
import { CreateNftpack } from '../../../services/createNftPack';

export default function useCreateNFTPackView() {
  const closeModal = useModalStore((state) => state.closeModal);
  const notify = useNotify();

  const handleCreateNftPack = async (values: NFTPackFormValues) => {
    const { error, data } = await CreateNftpack({ ...values });

    if (error) {
      notify(error, { variant: 'error' });
      closeModal();
    } else if (data) {
      notify('Nft Pack Created!', { variant: 'success' });
      closeModal();

      NftPackEmitter.emit('NftPackCreated', data);
    }
  };

  return {
    handleCreateNftPack,
  };
}
