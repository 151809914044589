import { Rarity } from 'src/lib/types/nftPack';
import NFTPackRarityForm from '../../../components/Forms/Rarity';
import useEditNftPackRaritiesView from './useView';

interface EditNftPackRaritiesViewProps {
  uuid: string;
  rarities: Rarity[];
}

export default function EditNftPackRaritiesView({
  uuid,
  rarities,
}: EditNftPackRaritiesViewProps): JSX.Element {
  const { handleEditRaritesFromNftPack } = useEditNftPackRaritiesView();
  return (
    <NFTPackRarityForm
      defaultValues={{
        rarities: rarities.map((rarity) => ({
          uuid: rarity.uuid,
          name: rarity.name,
          proportion: rarity.nftPackRarity.proportion,
        })),
      }}
      onSubmit={async (values) => {
        await handleEditRaritesFromNftPack(uuid, values);
      }}
      title="Edit NFT Pack"
    />
  );
}
