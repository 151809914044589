import {
  Card,
  InputAdornment,
  MenuItem,
  Stack,
  Tab,
  Tabs,
  TextField,
} from '@mui/material';
import { capitalCase } from 'change-case';
import HeaderBreadcrumbs from 'src/template/components/HeaderBreadcrumbs';
import Iconify from 'src/template/components/Iconify';
import Scrollbar from 'src/template/components/Scrollbar';
import PaymentsTable from '../../Components/PaymentsTable/PaymentsTable';
import PaymentsTableFooter from '../../Components/PaymentsTable/PaymentsTableFooter';
import { SearchForOptions } from '../../usePaymentsTable';
import usePayments from './useView';

const STATUS_TABS = [
  { value: 'all', label: 'All' },
  { value: 'requested', label: 'Requested' },
  { value: 'scheduled', label: 'Scheduled' },
  { value: 'executed', label: 'Executed' },
  { value: 'rejected', label: 'Rejected' },
  { value: 'canceled', label: 'Canceled' },
];

const SEARCH_OPTIONS: SearchForOptions[] = [
  'user.slug',
  'user.name',
  'user.email',
  'uuid',
];
export default function Payments() {
  const {
    status,
    onChangeReviewStatus,
    filter,
    setSearchFor,
    setQuery,
    paymentRequests,
    total,
  } = usePayments();

  return (
    <>
      <HeaderBreadcrumbs
        heading="Payment Requests"
        links={[{ name: 'Payment Requests' }]}
      />
      <Card>
        <Tabs
          allowScrollButtonsMobile
          variant="scrollable"
          scrollButtons="auto"
          value={status}
          onChange={onChangeReviewStatus}
          sx={{ px: 2, bgcolor: 'background.neutral' }}
        >
          {STATUS_TABS.map((tab) => (
            <Tab
              disableRipple
              key={tab.value}
              label={tab.label}
              value={tab.value}
            />
          ))}
        </Tabs>
        <Stack
          spacing={2}
          direction={{ xs: 'column', sm: 'row' }}
          sx={{ py: 2.5, px: 3 }}
        >
          <TextField
            fullWidth
            select
            label="Search for"
            value={filter.searchFor}
            onChange={(e) => setSearchFor(e.target.value as SearchForOptions)}
            SelectProps={{
              MenuProps: {
                sx: { '& .MuiPaper-root': { maxHeight: 260 } },
              },
            }}
            sx={{
              maxWidth: { sm: 240 },
              textTransform: 'capitalize',
            }}
          >
            {SEARCH_OPTIONS.map((option) => (
              <MenuItem
                key={option}
                value={option}
                sx={{
                  mx: 1,
                  my: 0.5,
                  borderRadius: 0.75,
                  typography: 'body2',
                  textTransform: 'capitalize',
                }}
              >
                {capitalCase(option)}
              </MenuItem>
            ))}
          </TextField>

          <TextField
            fullWidth
            value={filter.query}
            onChange={(e) => setQuery(e.target.value)}
            placeholder="Search payment request..."
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <Iconify
                    icon="eva:search-fill"
                    sx={{ color: 'text.disabled', width: 20, height: 20 }}
                  />
                </InputAdornment>
              ),
            }}
          />
        </Stack>

        <Scrollbar>
          <PaymentsTable data={paymentRequests} />
        </Scrollbar>

        <PaymentsTableFooter
          total={total || 0}
          page={filter.page}
          rowsPerPage={filter.size}
        />
      </Card>
    </>
  );
}
