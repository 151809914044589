import useNotify from 'src/lib/hooks/useNotify';
import useModalStore from 'src/lib/stores/useModalStore';
import { AddNftOnPackFormValues } from '../../../components/Forms/AddNft/types';
import { NftPackEmitter } from '../../../events/NftPackEmitter';
import { AddNftsOnPack } from '../../../services/addNftsOnPack';

export default function useAddNftsInPackView() {
  const closeModal = useModalStore((state) => state.closeModal);
  const notify = useNotify();

  const handleAddNftsInPack = async (
    uuid: string,
    values: AddNftOnPackFormValues
  ) => {
    const { error } = await AddNftsOnPack({
      nfts: values.nfts.map((nft) => ({
        uuid: nft.uuid,
        rarityUuid: nft.rarityUuid,
        faceValue: nft.faceValue * 1000,
      })),
      uuid,
    });

    if (error) {
      notify(error, { variant: 'error' });
      closeModal();
    } else {
      notify('Nfts added to Pack!', { variant: 'success' });
      closeModal();

      NftPackEmitter.emit('NftPackNftsUpdated', null);
    }
  };

  return {
    handleAddNftsInPack,
  };
}
