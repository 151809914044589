import ContestEventRankings from 'src/components/modules/GameRankings/view/ContestEventRankings';
import Page from 'src/template/components/Page';

export default function ContestEventRankingsPage() {
  return (
    <Page title="Contest Event Rankings">
      <ContestEventRankings />
    </Page>
  );
}
