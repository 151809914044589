import { Contest, ContestAwardDistributionModes } from 'src/lib/types/contest';
import ContestForm from '../../components/Forms/Contest';
import { ContestFormValues } from '../../components/Forms/Contest/types';
import useUpdateContestView from './useView';

interface UpdateContestViewProps {
  contest: Contest;
}

export default function UpdateContestView({
  contest,
}: UpdateContestViewProps): JSX.Element {
  const { handleUpdateContest } = useUpdateContestView();
  return (
    <ContestForm
      defaultValues={{
        ...contest,
        awardValue: contest.awardValue / 1000,
        entryPrice: contest.entryPrice / 1000,
        startDate: new Date(contest.startDate)
          .toISOString()
          .slice(0, 16)
          .replace('T', ' '),
        endDate: contest.endDate
          ? new Date(contest.endDate)
              .toISOString()
              .slice(0, 16)
              .replace('T', ' ')
          : null,
        positionAwards: contest.awardConfigurations
          .sort((a, b) => a.position - b.position)
          .map((award) => ({
            value:
              contest.awardDistributionMode ==
              ContestAwardDistributionModes.FIXED
                ? award.awardValue / 1000
                : award.awardValue,
          })),
      }}
      onSubmit={async (values: ContestFormValues) => {
        await handleUpdateContest(contest.uuid, {
          ...values,
          awardValue:
            values.awardValue &&
            values.awardDistributionMode == ContestAwardDistributionModes.FIXED
              ? values.awardValue * 1000
              : null,
          entryPrice: values.entryPrice * 1000,
          positionAwards: values.positionAwards.map((award) => ({
            value:
              values.awardDistributionMode ==
              ContestAwardDistributionModes.FIXED
                ? award.value * 1000
                : award.value,
          })),
        });
      }}
      title="Update Contest"
    />
  );
}
