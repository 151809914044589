import { useState } from 'react';
import useNotify from 'src/lib/hooks/useNotify';
import useModalStore from 'src/lib/stores/useModalStore';
import { FeedItemForm } from '../../components/Forms/ChannelContent/useView';
import { FeedEmitter } from '../../events/FeedEmitter';
import { UpdateFeedItem } from '../../services/updateFeedItem';

export default function useUpdateFeedItem() {
  const closeModal = useModalStore((state) => state.closeModal);
  const notify = useNotify();

  const [isSubmitting, setIsSubmitting] = useState<boolean>(false);

  const handleUpdateChannelContent = async (data: FeedItemForm) => {
    setIsSubmitting(true);

    try {
      const { data: feedItem, error } = await UpdateFeedItem(data);
      if (error) {
        notify(error, { variant: 'error' });
      } else {
        notify('Feed Item updated!', { variant: 'success' });
        FeedEmitter.emit('FeedItemUpdated', feedItem?.uuid);
      }
    } catch (error: any) {
      notify(error?.message || error?.description, { variant: 'error' });
    } finally {
      setIsSubmitting(false);
      closeModal();
    }
  };

  return {
    handleUpdateChannelContent,
    isSubmitting,
  };
}
