import useNotify from 'src/lib/hooks/useNotify';
import useModalStore from 'src/lib/stores/useModalStore';
import { ConfigurationFormValues } from '../../components/Form/types';
import { ConfigurationEmitter } from '../../events/ConfigurationEmitter';
import { createConfiguration } from '../../services/createConfiguration';

export default function useCreateConfiguration() {
  const closeModal = useModalStore((state) => state.closeModal);
  const notify = useNotify();

  const handleCreateConfiguration = async (values: ConfigurationFormValues) => {
    const { error, data } = await createConfiguration({
      ...values,
      active: true,
    });
    if (error) {
      notify(error, { variant: 'error' });
      closeModal();
    } else if (data) {
      notify('Configuration created!', { variant: 'success' });
      closeModal();
      ConfigurationEmitter.emit('configurationCreated', data);
    }
  };

  return {
    handleCreateConfiguration,
  };
}
