import {
  Email,
  LocationCity,
  LocationOn,
  MailOutline,
  MyLocation,
  Person,
  Phone,
  Public,
} from '@mui/icons-material';
import { Box, CircularProgress, Stack } from '@mui/material';
import UserPaymentHistory from 'src/components/modules/Profile/sections/PaymentHistory/PaymentHistory';
import Center from 'src/components/shared/Center';
import SectionDivider from 'src/components/shared/SectionDivider';
import { fetchPaymentRequestDetails } from 'src/lib/services/paymentRequests';
import { AboutData } from 'src/lib/types/aboutData';
import {
  PaymentRequest,
  WithPaymentRequestHistory,
} from 'src/lib/types/paymentRequest';
import CollectionCreatorCard from '../../../Collections/CollectionCreatorCard';
import PaymentRequestHistoryTable from '../../Components/PaymentHistoryTable';
import PayoutEligibility from './PayoutEligibility';

interface PaymentRequestDetailsProps {
  userUuid: string;
  paymentRequest: WithPaymentRequestHistory<PaymentRequest>;
}

export default function PaymentRequestDetails({
  userUuid,
  paymentRequest,
}: PaymentRequestDetailsProps) {
  const { data: paymentRequestDetails, loading } = fetchPaymentRequestDetails(
    paymentRequest.uuid
  );

  if (loading || !paymentRequestDetails)
    return (
      <Center>
        <CircularProgress />
      </Center>
    );

  const {
    firstName,
    lastName,
    country,
    city,
    postalCode,
    address,
    contactEmail,
    phoneNumber,
    phoneDialCode,
    paypalEmail,
  } = paymentRequestDetails.paymentInfo || {};

  const { cryptoWallets } = paymentRequestDetails.user;

  const userData: AboutData[] = [
    {
      label: 'Full Name',
      value: `${firstName} ${lastName}`,
      icon: <Person />,
    },
    {
      label: 'Phone Number',
      value: `${phoneDialCode} ${phoneNumber}`,
      icon: <Phone />,
    },
    {
      label: 'Account Email',
      value: contactEmail,
      icon: <MailOutline />,
    },
    {
      label: 'Paypal Email',
      value: paypalEmail,
      icon: <Email />,
    },
    {
      label: 'Address',
      value: address,
      icon: <LocationOn />,
    },
    {
      label: 'City',
      value: city,
      icon: <LocationCity />,
    },
    {
      label: 'Postal Code',
      value: postalCode,
      icon: <MyLocation />,
    },
    {
      label: 'Country',
      value: country,
      icon: <Public />,
    },
  ];

  return (
    <Stack spacing={2}>
      <Stack direction="row" spacing={2}>
        <Stack width="100%" spacing={2}>
          <CollectionCreatorCard
            {...{ ...paymentRequestDetails.user, uuid: userUuid }}
          />
          {/*
          <Card>
            <CardHeader title="Account Details" />
            <CardContent>
              <Stack spacing={1}>
                {paymentRequestDetails.paymentInfo ? (
                  userData.map((data) => (
                    <Stack
                      direction="row"
                      spacing={2}
                      justifyContent="space-between"
                      alignItems="center"
                      key={data.label}
                    >
                      <Stack direction="row" spacing={1} alignItems="center">
                        {data.icon}
                        <Typography fontSize="14px">{data.label}:</Typography>
                      </Stack>
                      <Typography
                        fontSize="14px"
                        fontWeight="bold"
                        textAlign="right"
                        sx={{ ...data.sx }}
                      >
                        {data.value}
                      </Typography>
                    </Stack>
                  ))
                ) : (
                  <Typography variant="body2" color="text.secondary">
                    No payment information available
                  </Typography>
                )}
              </Stack>
            </CardContent>
          </Card>
          */}
        </Stack>

        {/*
        <Box width="100%">
          <PaymentRequestWalletDetails userId={userUuid} />
        </Box>
        */}
      </Stack>
      <Box width="100%">
        <PayoutEligibility userId={userUuid} />
      </Box>
      {/*
      <Stack width="100%" spacing={2}>
        <Card
          sx={{
            minHeight: '100%',
          }}
        >
          <CardHeader title="Crypto Wallets" />
          <CardContent>
            <Stack spacing={1}>
              <Stack
                spacing={2}
                justifyContent="space-between"
                alignItems="center"
              >
                {cryptoWallets.length === 0 && (
                  <Label>This user doesn&apos;t have any crypto wallet</Label>
                )}
                {cryptoWallets.map((wallet: any) => (
                  <Typography key={wallet.walletAddress}>
                    {wallet.walletAddress}
                  </Typography>
                ))}
              </Stack>
            </Stack>
          </CardContent>
        </Card>
      </Stack>
        */}
      {paymentRequest.history.length > 0 && (
        <>
          <SectionDivider section="Request Update History" />
          <PaymentRequestHistoryTable
            data={paymentRequest.history}
            isLoading={false}
          />
        </>
      )}
      <Stack width="100%" spacing={2}>
        <SectionDivider section="Previous Payment Requests" />
        <UserPaymentHistory
          user={{ ...paymentRequestDetails.user, uuid: userUuid }}
        />
        {/* <SectionDivider section="User NFT Sales" />
        <UserNFTSales userId={userUuid} /> */}
      </Stack>
    </Stack>
  );
}
