import { ContentCopy, OpenInNew } from '@mui/icons-material';
import {
  IconButton,
  MenuItem,
  TableCell,
  TableRow,
  Tooltip,
  Typography,
} from '@mui/material';
import { useState } from 'react';
import CopyToClipboard from 'react-copy-to-clipboard';
import { useNavigate } from 'react-router';
import Flex from 'src/components/shared/Flex';
import Link from 'src/components/shared/Link';
import { DEFAULT_PAYMENT_CURRENCY } from 'src/lib/config/payment';
import { PaymentRequest } from 'src/lib/types/paymentRequest';
import formatStickyCoinValue from 'src/lib/utils/formatStickyCoinValue';
import {
  formatDate,
  formatLocaleStringCurrency,
} from 'src/lib/utils/formatters';
import getUserName from 'src/lib/utils/getUserName';
import Routes from 'src/routes/Routes';
import Label from 'src/template/components/Label';
import { TableMoreMenu } from 'src/template/components/table';
import usePaymentsTable from '../../usePaymentsTable';
import { getPaymentRequestLabelColor } from '../../utils';

export default function PaymentsTableRow({
  payment,
}: {
  payment: PaymentRequest;
}) {
  const navigate = useNavigate();

  const [selected, toggleSelected] = usePaymentsTable((state) => [
    state.selected,
    state.toggleSelected,
  ]);

  const [openMenu, setOpenMenuActions] = useState<HTMLElement | null>(null);

  const handleOpenMenu = (event: React.MouseEvent<HTMLElement>) => {
    setOpenMenuActions(event.currentTarget);
  };

  const handleCloseMenu = () => {
    setOpenMenuActions(null);
  };
  return (
    <TableRow key={payment.uuid} hover>
      <TableCell>{formatDate(payment.createdAt)}</TableCell>
      <TableCell align="left">
        {payment.user ? (
          <Link to={Routes.user(payment.user.uuid)}>
            {getUserName(payment.user)}
          </Link>
        ) : (
          <Typography>--</Typography>
        )}
      </TableCell>
      <TableCell align="right">
        {formatStickyCoinValue(payment.amount)}
      </TableCell>
      <TableCell align="left">
        {payment.value.toLocaleString(
          formatLocaleStringCurrency(
            payment.currency || DEFAULT_PAYMENT_CURRENCY
          ),
          {
            style: 'currency',
            currency: payment.currency || DEFAULT_PAYMENT_CURRENCY,
          }
        )}
      </TableCell>
      <TableCell align="center">
        <Label
          variant="ghost"
          color={getPaymentRequestLabelColor(payment.status)}
          sx={{ textTransform: 'capitalize' }}
        >
          {payment.status}
        </Label>
      </TableCell>
      <TableCell align="center">
        <Label
          color={payment.isEligible ? 'success' : 'error'}
          sx={{ textTransform: 'capitalize' }}
        >
          {payment.isEligible ? 'Yes' : 'No'}
        </Label>
      </TableCell>
      <TableCell align="center">
        <Label
          color={payment.userHasProSubscription ? 'success' : 'error'}
          sx={{ textTransform: 'capitalize' }}
        >
          {payment.userHasProSubscription ? 'Yes' : 'No'}
        </Label>
      </TableCell>
      <TableCell>{formatDate(payment.updatedAt)}</TableCell>
      <TableCell align="right">
        <Flex justifyContent="center">
          <Tooltip title="More Details" placement="top">
            <IconButton
              onClick={() => navigate(`/payment-requests/${payment.uuid}`)}
            >
              <OpenInNew />
            </IconButton>
          </Tooltip>
          <TableMoreMenu
            open={openMenu}
            onOpen={handleOpenMenu}
            onClose={handleCloseMenu}
            actions={
              <>
                <CopyToClipboard
                  text={payment.uuid}
                  onCopy={() => {
                    handleCloseMenu();
                  }}
                >
                  <MenuItem>
                    <ContentCopy />
                    Copy Payment Request UUID
                  </MenuItem>
                </CopyToClipboard>
                <CopyToClipboard
                  text={payment.user?.uuid || ''}
                  onCopy={() => {
                    handleCloseMenu();
                  }}
                >
                  <MenuItem>
                    <ContentCopy />
                    Copy User UUID
                  </MenuItem>
                </CopyToClipboard>
              </>
            }
          />
        </Flex>
      </TableCell>
    </TableRow>
  );
}
