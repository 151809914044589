import { DeepPartial } from 'react-hook-form';
import { PaginatedResponse } from 'src/lib/types/PaginatedResponse';
import {
  ParsedSellerWithRank,
  SellerBuyerTotalStatistics,
  SellerWithRank,
} from 'src/lib/types/UserRank';
import { formatFractionNumber } from 'src/lib/utils/formatFractionNumber';
import formatStickyCoinValue from 'src/lib/utils/formatStickyCoinValue';
import { IncludeExcludeAll } from 'src/lib/utils/includeExcludeFilterUtils';
import { parseSearchParams } from 'src/lib/utils/parseSearchParams';
import useSWR, { SWRResponse } from 'swr';
import { UserType } from '../types/UserType';

const formatter = Intl.NumberFormat('en-US');

function parseData(
  data:
    | PaginatedResponse<SellerWithRank[], SellerBuyerTotalStatistics>
    | undefined
):
  | PaginatedResponse<ParsedSellerWithRank[], SellerBuyerTotalStatistics>
  | undefined {
  if (!data) return undefined;
  return {
    ...data,
    body: data.body.map((seller) => ({
      ...seller,
      volume: formatter.format(formatStickyCoinValue(seller.volume)),
      sales: formatter.format(seller.sales),
      averageSalesPrice: formatFractionNumber(
        formatStickyCoinValue(seller.averageSalesPrice)
      ),
    })),
  };
}

export type RankBy =
  | 'volume'
  | 'averageSalesPrice'
  | 'sales'
  | 'averagePurchasePrice'
  | 'purchases';

export type NFTOrigin = 'created' | 'purchased' | 'imported' | 'transferred';

type PossibleFilters = {
  rankBy: RankBy;
  size: number | string;
  page: number | string;
  userType: UserType;
  filter: {
    FreePurchases: IncludeExcludeAll;
    InactiveUsers: IncludeExcludeAll;
    NftPack: IncludeExcludeAll;
    startDate: string;
    endDate: string;
    lang: string;
    country: string;
    nftOrigin: NFTOrigin;
  };
};

export function fetchSellersRank(
  params?: DeepPartial<PossibleFilters>
): SWRResponse<
  PaginatedResponse<ParsedSellerWithRank[], SellerBuyerTotalStatistics>
> {
  const parsedParams = parseSearchParams(params ?? {});
  const { data, ...response } = useSWR(
    `admin/users/ranking/seller?${parsedParams}`
  );

  return {
    ...response,
    data: parseData(data),
  };
}
