import { DeepPartial } from 'react-hook-form';
import { IncludeExcludeAll } from 'src/lib/utils/includeExcludeFilterUtils';
import { parseSearchParams } from 'src/lib/utils/parseSearchParams';
import useSWR, { SWRResponse } from 'swr';
import { FeedItemAnalyticsResponse } from '../../FeedAnalytics/services/fetchFeedItemAnalytics';

export type PossibleParams = {
  page: number | string;
  size: number | string;
  order: string;
  orderBy: string;
  filter: {
    onFeed: IncludeExcludeAll;
    channelUuid: string;
  };
};

export const fetchChannelContents = (
  filter?: DeepPartial<PossibleParams>
): SWRResponse<FeedItemAnalyticsResponse> => {
  const parsedParams = parseSearchParams(filter ?? {});
  const response = useSWR<FeedItemAnalyticsResponse, Error>(
    `/admin/feed-item?${parsedParams}`,
    {
      revalidateOnFocus: false,
    }
  );

  return response;
};
