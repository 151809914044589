import getAPIClient from 'src/lib/services/api/axios.config';
import { ServiceResponse } from 'src/lib/types/ServiceResponse';
import { getMessageFromError } from 'src/lib/utils/error';
import { Configuration } from '../types/Configuration';

type UpdateConfigurationData = Partial<
  Pick<Configuration, 'name' | 'platform' | 'value' | 'active' | 'description'>
>;

export async function updateConfiguration(
  configId: string,
  data: UpdateConfigurationData
): Promise<ServiceResponse<Configuration>> {
  try {
    const api = await getAPIClient();
    const res = await api.put(`admin/configurations/${configId}`, data);
    return {
      data: res.data,
      error: null,
    };
  } catch (e) {
    return {
      data: null,
      error: getMessageFromError(e),
    };
  }
}
