import {
  FormControl,
  FormControlProps,
  FormHelperText,
  TextField,
  TextFieldProps,
} from '@mui/material';
import {
  Controller,
  type Control,
  type FieldValues,
  type Path,
} from 'react-hook-form';

type Props<TFormValues extends FieldValues> = TextFieldProps & {
  control: Control<TFormValues>;
  name: Path<TFormValues>;
  wrapperProps?: FormControlProps;
};
export default function ControllerField<TFormValues extends FieldValues>({
  control,
  name,
  children,
  wrapperProps,
  ...rest
}: Props<TFormValues>): JSX.Element {
  return (
    <Controller
      control={control}
      name={name}
      render={({ field, fieldState: { error } }) => (
        <FormControl
          error={!!error}
          fullWidth={rest.fullWidth}
          {...wrapperProps}
        >
          <TextField
            id={name}
            variant="outlined"
            InputLabelProps={{ shrink: rest.type === 'date' || rest.select }}
            {...rest}
            {...field}
          >
            {children}
          </TextField>
          {error?.message && (
            <FormHelperText
              sx={{
                fontWeight: 500,
                ml: 0,
                mt: 0.3,
              }}
            >
              {error.message}
            </FormHelperText>
          )}
        </FormControl>
      )}
    />
  );
}
