import getAPIClient from 'src/lib/services/api/axios.config';
import { ServiceResponse } from 'src/lib/types/ServiceResponse';
import {
  GameStatisticGroup,
  StatisticGroupTypes,
} from 'src/lib/types/statisticGroup';
import { getMessageFromError } from 'src/lib/utils/error';

export type UpdateStatisticGroupData = {
  name: string;
} & (
  | {
      type: StatisticGroupTypes.STATISTIC_LIST;
      statisticsList: string[];
    }
  | {
      type: StatisticGroupTypes.ASSET_GROUP;
      actionUuid: string;
      typeUuid: string;
    }
);

export async function UpdateStatisticGroup(
  groupUuid: string,
  data: UpdateStatisticGroupData
): Promise<ServiceResponse<GameStatisticGroup>> {
  try {
    const api = await getAPIClient();
    const res = await api.put(`admin/game-statistic-group/${groupUuid}`, data);
    return {
      data: res.data,
      error: null,
    };
  } catch (e) {
    return {
      data: null,
      error: getMessageFromError(e),
    };
  }
}
