import { DeepPartial } from 'react-hook-form';
import { GameAsset } from 'src/lib/types/asset';
import { parseSearchParams } from 'src/lib/utils/parseSearchParams';
import useSWR, { SWRResponse } from 'swr';

export type PossibleParams = {
  page: number | string;
  size: number | string;
  order: Record<string, string>;
  filter: {
    [key: string]: {
      like: string;
      eq: string;
    };
  };
};

export const fetchGameAssetsByContestGame = (
  contestUuid: string,
  filter?: DeepPartial<PossibleParams>
): SWRResponse<GameAsset[]> => {
  const parsedParams = parseSearchParams(filter ?? {});
  const response = useSWR<GameAsset[], Error>(
    `/admin/game-contest/${contestUuid}/available-assets/?${parsedParams}`
  );

  return response;
};
