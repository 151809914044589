import { Container } from '@mui/material';
import NftPacksDeliveries from 'src/components/modules/NFTPacks/view/Deliveries';
import Page from 'src/template/components/Page';

export default function NftPacksDeliveryPage() {
  return (
    <Page title="NFT Pack Deliveries">
      <Container maxWidth="xl">
        <NftPacksDeliveries />
      </Container>
    </Page>
  );
}
