import useNotify from 'src/lib/hooks/useNotify';
import useModalStore from 'src/lib/stores/useModalStore';
import { NftPackEmitter } from '../../../events/NftPackEmitter';
import { UpdateNftOnPack } from '../../../services/updateNftOnPack';

export default function useUpdateNftOnPackView() {
  const closeModal = useModalStore((state) => state.closeModal);
  const notify = useNotify();

  const handleUpdateNftOnPack = async ({
    packUuid,
    nftUuid,
    rarityUuid,
    faceValue,
  }: {
    packUuid: string;
    nftUuid: string;
    rarityUuid: string;
    faceValue: number;
  }) => {
    const { error } = await UpdateNftOnPack({
      packUuid,
      nftUuid,
      rarityUuid,
      faceValue,
    });

    if (error) {
      notify(error, { variant: 'error' });
      closeModal();
    } else {
      notify('Nfts rarity updated!', { variant: 'success' });
      closeModal();

      NftPackEmitter.emit('NftPackNftsUpdated', null);
    }
  };

  return {
    handleUpdateNftOnPack,
  };
}
