import VerifiedIcon from '@mui/icons-material/Verified';
import { Avatar, Checkbox, Stack } from '@mui/material';
import { GridSortDirection } from '@mui/x-data-grid';
import { capitalCase } from 'change-case';
import { CollectionToAdd } from 'src/components/modules/Clubs/components/Forms/Club/types';
import Link from 'src/components/shared/Link';
import LinkToUser from 'src/components/shared/LinkToUser';
import MagicTable from 'src/components/shared/MagicTable/MagicTable';
import { Collection } from 'src/lib/types/collection';
import { formatAppPlatform, formatDate } from 'src/lib/utils/formatters';
import {
  getCollectionHumanAnalysisStatusColor,
  getCollectionReviewStatusColor,
} from 'src/lib/utils/utils';
import Routes from 'src/routes/Routes';
import Label from 'src/template/components/Label';

interface AddCollectionsTableProps {
  data: Collection[];
  isLoading: boolean;
  page: number;
  size: number;
  sortBy: string;
  order: GridSortDirection;
  setPage: (page: number) => void;
  setRowsPerPage: (size: number) => void;
  setOrder: (order: GridSortDirection) => void;
  setSortBy: (sortBy: string) => void;

  selectedCollections: CollectionToAdd[];
  handleAddCollection: (collection: Collection) => void;
  handleRemoveCollection: (uuid: string) => void;
}

export default function AddCollectionsTable({
  data,
  isLoading,
  page,
  size,
  sortBy,
  order,
  setPage,
  setRowsPerPage,
  setOrder,
  setSortBy,

  selectedCollections,
  handleAddCollection,
  handleRemoveCollection,
}: AddCollectionsTableProps): JSX.Element {
  return (
    <MagicTable
      rowHeight={64}
      rows={data}
      hideNumberOfRows
      getRowId={(r) => r.uuid}
      loading={isLoading}
      paginationMode="server"
      pageSize={size}
      page={page}
      onPageChange={(newPage) => {
        setPage(newPage);
      }}
      onPageSizeChange={(newSize) => {
        setRowsPerPage(newSize);
      }}
      sortModel={[{ field: sortBy, sort: order }]}
      onSortModelChange={(model) => {
        const currentModel = model[0];
        if (currentModel) {
          setSortBy(currentModel.field);
          if (currentModel.sort) setOrder(currentModel.sort);
        } else {
          setOrder('asc');
          setSortBy('');
        }
      }}
      columns={[
        {
          field: 'checkbox',
          headerName: '',
          sortable: false,
          flex: 0,
          renderCell: ({ row }) => (
            <Checkbox
              checked={selectedCollections.some(
                (collection) => collection.uuid === row.uuid
              )}
              onChange={() => {
                if (
                  selectedCollections.some(
                    (collection) => collection.uuid === row.uuid
                  )
                ) {
                  handleRemoveCollection(row.uuid);
                } else {
                  handleAddCollection(row);
                }
              }}
            />
          ),
        },
        {
          field: 'name',
          headerName: 'Name',
          sortable: false,
          renderCell: ({ row }) => (
            <>
              <Avatar
                alt={row.name}
                src={row.coverFileUrl}
                variant="rounded"
                sx={{ mr: 2 }}
              />
              <Link
                component={Link}
                to={Routes.collection(row.uuid)}
                color={row.name ? 'inherit' : 'text.secondary'}
                underline="hover"
                display="inline-block"
              >
                {row.name ?? 'empty'}{' '}
                {row.verified && (
                  <VerifiedIcon
                    fontSize="inherit"
                    color="info"
                    sx={{
                      verticalAlign: 'text-top',
                    }}
                  />
                )}
              </Link>
            </>
          ),
        },
        {
          field: 'active',
          headerName: 'Status',
          sortable: false,
          renderCell: ({ row }) => (
            <Label
              variant="ghost"
              color={row.active ? 'success' : 'error'}
              sx={{ textTransform: 'capitalize' }}
            >
              {row.active ? 'Active' : 'Inactive'}
            </Label>
          ),
        },
        {
          field: 'nftsCount',
          headerName: 'NFTs',
          sortable: false,
        },
        {
          field: 'user',
          headerName: 'Creator',
          sortable: false,
          renderCell: ({ row }) => (
            <LinkToUser
              user={row.user}
              blockchain={row.blockchain}
              externalWallet={row.externalWallet}
            />
          ),
        },
        {
          field: 'reviewStatus',
          headerName: 'R.S. / H.A',
          sortable: false,
          align: 'center',
          renderCell: ({ row }) => (
            <Stack alignItems="center" spacing={1}>
              <Label
                variant="ghost"
                color={getCollectionReviewStatusColor(row.reviewStatus)}
                sx={{ textTransform: 'capitalize' }}
              >
                {capitalCase(row.reviewStatus)}
              </Label>
              {row.humanAnalysis && (
                <Label
                  variant="filled"
                  color={getCollectionHumanAnalysisStatusColor(
                    row.humanAnalysis
                  )}
                >
                  {capitalCase(row.humanAnalysis)}
                </Label>
              )}
            </Stack>
          ),
        },
        {
          field: 'blockchain',
          headerName: 'Blockchain',
          sortable: false,
          align: 'center',
          valueFormatter: ({ value }) => capitalCase(value),
        },
        {
          field: 'platform',
          headerName: 'Platform',
          sortable: false,
          align: 'center',
          valueFormatter: ({ value }) => formatAppPlatform(value),
        },
        {
          field: 'updatedAt',
          headerName: 'Updated',
          sortable: false,
          align: 'right',
          valueFormatter: ({ value }) => formatDate(value),
        },
        {
          field: 'createdAt',
          headerName: 'Created',
          sortable: false,
          align: 'right',
          valueFormatter: ({ value }) => formatDate(value),
        },
      ]}
    />
  );
}
