import { FeedItem } from 'src/lib/types/FeedItem';
import ChannelContentForm from '../../components/Forms/ChannelContent';
import useUpdateFeedItem from './useView';

export default function UpdateVideoContent({
  feedItem,
}: {
  feedItem?: FeedItem;
}): JSX.Element {
  const { handleUpdateChannelContent, isSubmitting } = useUpdateFeedItem();
  return (
    <ChannelContentForm
      feedItem={feedItem}
      onSubmit={handleUpdateChannelContent}
      isSubmitting={isSubmitting}
    />
  );
}
