import getAPIClient from 'src/lib/services/api/axios.config';
import { ServiceResponse } from 'src/lib/types/ServiceResponse';
import { Club } from 'src/lib/types/club';
import { getMessageFromError } from 'src/lib/utils/error';
import { CreateClubData } from './createClub';

export async function addAssets(
  clubUuid: string,
  data: AddAssetsData
): Promise<ServiceResponse<Club>> {
  try {
    const api = await getAPIClient();
    const res = await api.post(`admin/clubs/${clubUuid}/assets`, data);
    return {
      data: res.data,
      error: null,
    };
  } catch (e) {
    return {
      data: null,
      error: getMessageFromError(e),
    };
  }
}

type AddAssetsData = Partial<
  Pick<CreateClubData, 'externalAssets' | 'collectionUuids' | 'gameUuids'>
>;
