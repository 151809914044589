export enum FilterTypes {
  ORDER = 'order',
  FILTER = 'filter',
}

export enum OrderValues {
  ASC = 'asc',
  DESC = 'desc',
}

export type UiFeedFilter = {
  filterType?: FilterTypes;
  attribute?: string;
  operator?: string;
  value?: OrderValues | string;
};

export type KeyValueFeedFilter = {
  key?: string;
  value?: OrderValues | string;
};

export type FeedDetails = {
  type: string;
  cardType: string;
  name: string;
  slug: string;
  status: string;
  description: string;
  hasSeeAll: boolean;
  feedOrder?: number;
  limit?: number;
  offset?: number;
};
