import {
  Card,
  InputAdornment,
  MenuItem,
  Stack,
  TextField,
} from '@mui/material';
import DebouncedField from 'src/components/shared/Inputs/DebouncedField';
import useDebounce from 'src/lib/hooks/useDebounce';
import {
  fetchStickyNftsCount,
  fetchStickyNftsPreloaded,
  fetchStickyNftsRealTime,
} from 'src/lib/services/nft';
import { NFT } from 'src/lib/types/nft';
import Iconify from 'src/template/components/Iconify';
import Scrollbar from 'src/template/components/Scrollbar';
import useSettings from 'src/template/hooks/useSettings';
import useTabs from 'src/template/hooks/useTabs';
import StickyNftsTable from './NftsTable/StickyNftsTable';
import StickyNftsTableFooter from './NftsTable/StickyNftsTableFooter';
import useStickyNftsTable, {
  SearchForOptions,
} from './NftsTable/useStickyNftsTable';
import { matchingColorsThemed } from './utils';

const blockchains = {
  POLYGON: 'polygon',
  ETHEREUM: 'ethereum',
  STICKY: 'sticky',
};
const contractTypes = {
  ERC721: 'ERC721',
  ERC1155: 'ERC1155',
};

const isTestNetEnv = true; // process.env.CRYPTO_TESTNET;

function getTokenScan(nft: NFT) {
  if (
    !nft?.blockchain ||
    !nft?.mintToken ||
    !nft?.mintTokenId ||
    nft?.blockchain === blockchains.STICKY
  )
    return undefined;
  if (nft?.blockchain === blockchains.POLYGON)
    return `https://${isTestNetEnv ? 'mumbai.' : ''}polygonscan.com/address/${
      nft?.mintToken
    }`;
  if (nft?.blockchain === blockchains.ETHEREUM)
    return `https://${isTestNetEnv ? 'goerli.' : ''}etherscan.io/address/${
      nft?.mintToken
    }`;
  return undefined;
}

function getNftScan(nft: NFT) {
  if (
    !nft?.blockchain ||
    !nft?.mintToken ||
    !nft?.mintTokenId ||
    nft?.blockchain === blockchains.STICKY
  )
    return undefined;
  if (nft?.blockchain === blockchains.POLYGON)
    return `https://${isTestNetEnv ? 'mumbai.' : ''}polygonscan.com/nft/${
      nft?.mintToken
    }/${nft?.mintTokenId}`;
  if (nft?.blockchain === blockchains.ETHEREUM)
    return `https://${isTestNetEnv ? 'goerli.' : ''}eth.nftscan.com/${
      nft?.mintToken
    }`;
  return undefined;
}

function getBlockchainIcon(nft: NFT) {
  if (nft?.blockchain === blockchains.ETHEREUM)
    return {
      icon: 'ph:currency-eth',
      color: '#627feb',
    };
  if (nft?.blockchain === blockchains.POLYGON)
    return {
      icon: 'cryptocurrency:matic',
      color: '#8347e6',
    };
  return {
    icon: '',
    color: '#000000',
  };
}

const REAL_TIME_SEARCH_OPTIONS = [
  {
    label: 'Blockchain',
    value: 'blockchain',
  },
  {
    label: 'Contract Type',
    value: 'mintContract',
  },
];

const PRELOADED_SEARCH_OPTIONS = [
  {
    label: 'Blockchain',
    value: 'blockchain',
  },
  {
    label: 'Contract Type',
    value: 'mintContract',
  },
  {
    label: `Contract Address`,
    value: 'mintToken',
  },
  {
    label: 'Nft name',
    value: 'name',
  },
];

const REAL_TIME_HIDE_OPTIONS = [
  {
    label: 'Show all',
    value: '--',
  },
  {
    label: 'Hide Deleted Nfts',
    value: 'deletedAt',
  },
];

const PRELOADED_HIDE_OPTIONS = [
  {
    label: 'Show all',
    value: '--',
  },
  {
    label: 'Hide Deleted Nfts',
    value: 'deletedAt',
  },
];

const PRELOADED_MATCH_OPTIONS = [
  {
    label: 'All',
    value: '--',
    color: matchingColorsThemed.neutral,
  },
  {
    // label: 'Matches',
    label: 'Match',
    value: { eq: '0' },
    color: matchingColorsThemed.matchGreen,
  },
  {
    // label: `Doesn't match`,
    label: `Don't match`,
    value: { ne: '0' },
    color: matchingColorsThemed.mismatchRed,
  },
  // {
  //   label: `Missing info`,
  //   value: { is: 'null' },
  //   color: matchingColorsThemed.missingInfoGold
  // },
];
// const mapLabelToValue = {
//   All: '--',
//   Matches: { eq: '0' },
//   [`Doesn't match`]: { ne: '0' },
//   [`Missing info`]: { is: 'null' }
// }

// eslint-disable-next-line react/prop-types
export default function AllStickyNfts({ realTimeData = true }) {
  const theme = useSettings().themeMode;

  const { currentTab: reviewStatus, onChangeTab: onChangeReviewStatus } =
    useTabs('AllStickyNfts');

  const {
    orderBy,
    order,
    page,
    query,
    searchFor,
    hideOption,
    size,
    customFilter,
  } = useStickyNftsTable((state) => state.filter);

  const [setQuery, setSearchFor, setHideOptions, setCustomFilter] =
    useStickyNftsTable((state) => [
      state.setQuery,
      state.setSearchFor,
      state.setHideOptions,
      state.setCustomFilter,
    ]);

  // if (custom && custom.ownedEditionsDelta) {
  //   custom.ownedEditionsDelta = mapLabelToValue[custom.ownedEditionsDelta];
  // }
  const filter: any = {
    hideOption,
    // ownedEditionsDelta: 'All',
    // ...customFilter
    // ownedEditionsDelta: mapLabelToValue[customFilter.ownedEditionsDelta]
  };
  const filterQuery = useDebounce(query, 500);
  if (searchFor && filterQuery !== '') {
    filter[searchFor] = {
      like: `%${filterQuery}%`,
    };
  }
  // if (customFilter?.ownedEditionsDelta == `Matches`) {
  if (customFilter?.ownedEditionsDelta == `Match`) {
    // filter.ownedEditionsDelta = {
    //   eq: '0',
    // };
    filter.customLogic = 'Match';
  }
  // else if (customFilter?.ownedEditionsDelta == `Doesn't match`) {
  else if (customFilter?.ownedEditionsDelta == `Don't match`) {
    // filter.ownedEditionsDelta = {
    //   ne: '0',
    // };
    filter.customLogic = `Don't match`;
  } else if (customFilter?.ownedEditionsDelta == `Missing info`) {
    filter.ownedEditionsDelta = {
      is: 'null',
    };
  }
  const {
    data: nfts,
    totalCount: totalCountPreloaded,
    loading,
    error,
  } = realTimeData
    ? fetchStickyNftsRealTime({ filter, page, size })
    : fetchStickyNftsPreloaded({
        filter,
        page,
        size,
        order: { ownedEditionsSticky: 'desc' },
      });
  // } = fetchStickyNfts({
  //   filter,
  //   page,
  //   size,
  // });

  /*
    For now, we're disabling the total count update in pagination to improve
    performance, since the Nfts table has over a million rows
  */
  // const { total } = fetchStickyNftsCount(filter);
  const { totalCount, loadingTotal, errorTotal } = fetchStickyNftsCount({
    filter,
  });

  const total = (realTimeData ? totalCount : totalCountPreloaded) ?? 100;
  // const total = 496;

  const searchHighlight = {
    searchColumn: searchFor,
    searchKeyword: filterQuery,
  };

  const SEARCH_OPTIONS = realTimeData
    ? REAL_TIME_SEARCH_OPTIONS
    : PRELOADED_SEARCH_OPTIONS;
  const HIDE_OPTIONS = realTimeData
    ? REAL_TIME_HIDE_OPTIONS
    : PRELOADED_HIDE_OPTIONS;

  return (
    <Card>
      <Stack
        spacing={2}
        direction={{ xs: 'column', sm: 'row' }}
        sx={{ py: 2.5, px: 3 }}
      >
        <TextField
          fullWidth
          select
          label="Search for"
          value={searchFor}
          onChange={(e) => setSearchFor(e.target.value as SearchForOptions)}
          SelectProps={{
            MenuProps: {
              sx: { '& .MuiPaper-root': { maxHeight: 260 } },
            },
          }}
          sx={{
            maxWidth: { sm: 240 },
            textTransform: 'capitalize',
          }}
        >
          {SEARCH_OPTIONS.map((option) => (
            <MenuItem
              key={option.value}
              value={option.value}
              sx={{
                mx: 1,
                my: 0.5,
                borderRadius: 0.75,
                typography: 'body2',
                textTransform: 'capitalize',
              }}
            >
              {option.label}
            </MenuItem>
          ))}
        </TextField>

        <DebouncedField
          fullWidth
          onDebounceChange={(e) => setQuery(e)}
          placeholder="Search nft..."
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <Iconify
                  icon="eva:search-fill"
                  sx={{ color: 'text.disabled', width: 20, height: 20 }}
                />
              </InputAdornment>
            ),
          }}
        />

        {!realTimeData && (
          <TextField
            fullWidth
            select
            label="Owned Editions"
            value={customFilter?.ownedEditionsDelta}
            onChange={(e) =>
              setCustomFilter(
                'ownedEditionsDelta',
                e.target.value as SearchForOptions
              )
            }
            SelectProps={{
              MenuProps: {
                sx: { '& .MuiPaper-root': { maxHeight: 260 } },
              },
            }}
            sx={{
              maxWidth: { sm: 240 },
              textTransform: 'capitalize',
            }}
          >
            {PRELOADED_MATCH_OPTIONS.map((option) => (
              <MenuItem
                key={option.label}
                // value={option.value}
                value={option.label}
                sx={{
                  mx: 1,
                  my: 0.5,
                  borderRadius: 0.75,
                  typography: 'body2',
                  textTransform: 'capitalize',
                  color: option.color[theme],
                }}
              >
                {option.label}
              </MenuItem>
            ))}
          </TextField>
        )}
        <TextField
          fullWidth
          select
          label="Hide rows"
          value={hideOption}
          onChange={(e) => setHideOptions(e.target.value as SearchForOptions)}
          SelectProps={{
            MenuProps: {
              sx: { '& .MuiPaper-root': { maxHeight: 260 } },
            },
          }}
          sx={{
            maxWidth: { sm: 240 },
            textTransform: 'capitalize',
          }}
        >
          {HIDE_OPTIONS.map((option) => (
            <MenuItem
              key={option.value}
              value={option.value}
              sx={{
                mx: 1,
                my: 0.5,
                borderRadius: 0.75,
                typography: 'body2',
                textTransform: 'capitalize',
              }}
            >
              {option.label}
            </MenuItem>
          ))}
        </TextField>
      </Stack>

      <Scrollbar>
        <StickyNftsTable
          data={nfts}
          loading={loading}
          searchHighlight={searchHighlight}
        />
      </Scrollbar>

      <StickyNftsTableFooter
        total={total || 0}
        page={page}
        rowsPerPage={size}
      />
    </Card>
  );
}
