import { Pause, PlayArrow } from '@mui/icons-material';
import { Box, IconButton, Slider } from '@mui/material';
import { useRef, useState } from 'react';
import SoundPlaceholder from 'src/assets/sound-placeholder.png';

type Props = {
  fileUrl: string;
};
export default function AudioDisplay({ fileUrl }: Props): JSX.Element {
  const [audioState, setAudioState] = useState({
    isPlaying: false,
    currentTime: 0,
    duration: 0,
    volume: 30,
  });
  const audioRef = useRef<HTMLAudioElement>(null);

  function handlePlay() {
    if (audioRef.current) {
      audioRef.current.play();
      setAudioState((prev) => ({
        ...prev,
        isPlaying: true,
      }));
    }
  }

  function handlePause() {
    if (audioRef.current) {
      audioRef.current.pause();
      setAudioState((prev) => ({
        ...prev,
        isPlaying: false,
      }));
    }
  }

  function handleDragSlider(e: any, value: number | number[]) {
    if (audioRef.current) {
      setAudioState((prev) => ({
        ...prev,
        currentTime: value as number,
      }));
      audioRef.current.currentTime = value as number;
    }
  }

  return (
    <Box
      onClick={(e) => {
        e.preventDefault();
        e.stopPropagation();
      }}
      sx={{
        width: '100%',
        height: '100%',
        backgroundImage: `url(${SoundPlaceholder})`,
        backgroundSize: 'cover',
        backgroundPosition: 'center',
        backgroundRepeat: 'no-repeat',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        flexDirection: 'column',
        position: 'relative',
      }}
    >
      {audioState.isPlaying ? (
        <IconButton
          onClick={() => {
            handlePause();
          }}
          sx={{
            fontSize: 50,
          }}
        >
          <Pause fontSize="inherit" />
        </IconButton>
      ) : (
        <IconButton
          onClick={() => {
            handlePlay();
          }}
          sx={{
            fontSize: 50,
          }}
        >
          <PlayArrow fontSize="inherit" />
        </IconButton>
      )}

      <Slider
        value={audioState.currentTime}
        min={0}
        max={audioState.duration}
        onChange={(e, value) => handleDragSlider(e, value)}
        sx={{
          position: 'absolute',
          bottom: 10,
          left: '10%',
          right: '10%',
          width: '80%',
        }}
      />

      <audio
        ref={audioRef}
        src={fileUrl}
        onTimeUpdate={(e) => {
          const time = e.currentTarget?.currentTime;
          setAudioState((prev) => ({
            ...prev,
            currentTime: time,
          }));
        }}
        onLoadedDataCapture={(e) => {
          const duration = e.currentTarget?.duration;
          setAudioState((prev) => ({
            ...prev,
            duration,
          }));
        }}
        style={{
          display: 'none',
        }}
      >
        <track kind="captions" />
      </audio>
    </Box>
  );
}
