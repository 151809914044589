import { format } from 'date-fns';
import { DeepPartial } from 'react-hook-form';
import { type PaginatedResponse } from 'src/lib/types/PaginatedResponse';
import { WithUser } from 'src/lib/types/users';
import { parseSearchParams } from 'src/lib/utils/parseSearchParams';
import { parseTimestamp } from 'src/lib/utils/parseTimestamp';
import useSWR, { SWRResponse } from 'swr';
import {
  NftPackDelivery,
  NftPackDeliveryModel,
} from '../types/NftPackDelivery';

const parsedData = (
  response: PaginatedResponse<WithUser<NftPackDeliveryModel>[]> | undefined
): PaginatedResponse<WithUser<NftPackDelivery>[]> | undefined => {
  if (!response) return undefined;
  return {
    meta: response.meta,
    body: response.body.map((d) => ({
      ...parseTimestamp(d, { formatString: 'dd/MM/yyyy · HH:mm' }),
      activeAt: format(d.activeAt, 'dd/MM/yyyy · HH:mm'),
    })),
  };
};

export const fetchNftPackDeliveries = (
  filters?: DeepPartial<PossibleParams>
): SWRResponse<PaginatedResponse<WithUser<NftPackDelivery>[]>> => {
  const { data, ...response } = useSWR(
    `admin/categories/nft-pack-deliveries?${parseSearchParams(filters ?? {})}`
  );

  return {
    ...response,
    data: parsedData(data),
  };
};

type PossibleParams = {
  page: number | string;
  size: number | string;
  filter: {
    status: string;
    'user.status': boolean;
    'user.uuid'?: string;
    'user.active': 1 | 0;
    activeAt: Record<'gt' | 'lt', string>;
    createdAt: Record<'gt' | 'lt', string>;
    objectId: string;
  };
  order: Record<string, string>;
};
