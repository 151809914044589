import { Add, ContentCopy, History, Settings } from '@mui/icons-material';
import {
  Box,
  Button,
  Card,
  CardContent,
  CircularProgress,
  Container,
  ListItemButton,
  ListItemText,
  MenuItem,
  Typography,
} from '@mui/material';
import { capitalCase } from 'change-case';
import CopyToClipboard from 'react-copy-to-clipboard';
import { CustomDrawer } from 'src/components/shared/CustomDrawer';
import Flex from 'src/components/shared/Flex';
import MoreMenu from 'src/components/shared/MoreMenu';
import { SGMPrompt } from 'src/lib/types/sgmPrompt';
import HeaderBreadcrumbs from 'src/template/components/HeaderBreadcrumbs';
import { MakerPromptViewer } from '../../components/MakerPromptViewer';
import useMakerPrompts from './useView';

export default function MakerPrompts() {
  const {
    data,
    isLoading,
    currentTab,
    onChangeTab,
    handleUpdatePrompt,
    isSubmitting,
    makerPrompts,
    isLoadingMakerPrompts,
    makerPromptHistory,
    isLoadingMakerPromptHistory,
    closeTypeDrawer,
    isTypeDrawerOpen,
    toggleTypeDrawer,
    closeHistoryDrawer,
    isHistoryDrawerOpen,
    toggleHistoryDrawer,
    viewHistory,
    changeViewHisory,
    handleRestoreVersion,
    handleNewPrompt,
  } = useMakerPrompts();

  return (
    <Container maxWidth="xl">
      <HeaderBreadcrumbs
        heading="SGM Prompts"
        links={[{ name: 'SGM Prompts' }]}
      />

      <Card>
        <Flex
          sx={{ px: 2, bgcolor: 'background.neutral' }}
          mb={2}
          justifyContent="space-between"
        >
          <Button variant="text" onClick={toggleTypeDrawer}>
            <Settings />
            {!isTypeDrawerOpen ? 'Open Prompt Types' : 'Close Prompt Types'}
          </Button>
          <Button variant="text" onClick={toggleHistoryDrawer}>
            <History />
            {!isHistoryDrawerOpen
              ? 'Open Prompt Versions'
              : 'Close Prompt Versions'}
          </Button>
        </Flex>
        <CardContent>
          <Box sx={{ display: 'flex', flexDirection: 'row', gap: 2 }}>
            <TypeDrawer
              closeTypeDrawer={closeTypeDrawer}
              currentTab={currentTab!}
              isTypeDrawerOpen={isTypeDrawerOpen}
              makerPrompts={makerPrompts ?? []}
              onChangeTab={onChangeTab}
              handleNewPrompt={handleNewPrompt}
            />

            {/* Main Content */}
            <Box sx={{ flexGrow: 1 }}>
              {isLoading || isSubmitting || isLoadingMakerPrompts ? (
                <Flex justifyContent="center" alignItems="center">
                  <CircularProgress />
                </Flex>
              ) : (
                <MakerPromptViewer
                  key={data?.id ?? currentTab}
                  prompt={viewHistory ?? data!}
                  onEdit={viewHistory ? undefined : handleUpdatePrompt}
                />
              )}
            </Box>

            <HistoryDrawer
              activeMakerPrompt={data}
              closeHistoryDrawer={closeHistoryDrawer}
              isHistoryDrawerOpen={isHistoryDrawerOpen}
              makerPromptHistory={makerPromptHistory ?? []}
              viewHistory={viewHistory}
              changeViewHisory={changeViewHisory}
              handleRestoreVersion={handleRestoreVersion}
            />
          </Box>
        </CardContent>
      </Card>
    </Container>
  );
}

interface HistoryDrawerProps {
  activeMakerPrompt?: SGMPrompt;
  makerPromptHistory: SGMPrompt[];
  isHistoryDrawerOpen: boolean;
  closeHistoryDrawer: () => void;
  viewHistory?: SGMPrompt;
  changeViewHisory: (versionId: string) => void;
  handleRestoreVersion: (uuid: string) => void;
}

function HistoryDrawer({
  activeMakerPrompt,
  makerPromptHistory,
  isHistoryDrawerOpen,
  closeHistoryDrawer,
  viewHistory,
  changeViewHisory,
  handleRestoreVersion,
}: HistoryDrawerProps) {
  if (!activeMakerPrompt) {
    return null;
  }

  const HistoryDrawerNodes = [
    activeMakerPrompt,
    ...(makerPromptHistory ?? []),
  ].map((version) => (
    <ListItemButton
      key={version.id}
      selected={
        viewHistory
          ? viewHistory.id === version.id
          : version.id === activeMakerPrompt.id
      }
      onClick={() => changeViewHisory(version.id)}
    >
      <ListItemText
        primary={`${
          version.id === activeMakerPrompt.id ? '(Active) - ' : ''
        } ${new Date(version.createdAt).toLocaleDateString('en-US', {
          year: 'numeric',
          month: 'numeric',
          day: 'numeric',
          hour: 'numeric',
          minute: 'numeric',
        })}`}
        secondary={version.id}
      />
      <MoreMenu>
        {version.id !== activeMakerPrompt.id && (
          <MenuItem
            aria-label="restore version"
            onClick={(e) => {
              e.preventDefault();
              handleRestoreVersion(version.id);
            }}
          >
            <History />
            Restore Version
          </MenuItem>
        )}

        <CopyToClipboard text={version.id}>
          <MenuItem aria-label="copy version id">
            <ContentCopy />
            Copy Version ID
          </MenuItem>
        </CopyToClipboard>
      </MoreMenu>
    </ListItemButton>
  ));

  return (
    <CustomDrawer
      title="Version History"
      nodes={HistoryDrawerNodes}
      isOpen={isHistoryDrawerOpen}
      onClose={closeHistoryDrawer}
      slideDirection="left"
    />
  );
}

interface TypeDrawerProps {
  makerPrompts: SGMPrompt[];
  currentTab: string;
  onChangeTab: (
    event: React.SyntheticEvent<Element, Event> | null,
    newTab: string
  ) => void;
  isTypeDrawerOpen: boolean;
  closeTypeDrawer: () => void;
  handleNewPrompt: () => void;
}

function TypeDrawer({
  makerPrompts,
  currentTab,
  onChangeTab,
  isTypeDrawerOpen,
  closeTypeDrawer,
  handleNewPrompt,
}: TypeDrawerProps) {
  const typeDrawerNodes = makerPrompts
    .map((prompt) => (
      <ListItemButton
        key={prompt.id}
        selected={currentTab === prompt.type}
        onClick={() => onChangeTab(null, prompt.type)}
      >
        <ListItemText
          primary={capitalCase(prompt.title ?? prompt.type)}
          secondary={prompt.type}
        />
      </ListItemButton>
    ))
    .concat([
      <ListItemButton onClick={handleNewPrompt}>
        <ListItemText
          primary={
            <Flex justifyContent={'center'} alignItems={'center'}>
              <Add />
              <Typography variant="button" sx={{ fontWeight: 'bold' }}>
                New Prompt
              </Typography>
            </Flex>
          }
        />
      </ListItemButton>,
    ]);

  return (
    <CustomDrawer
      title="Prompt Types"
      nodes={typeDrawerNodes}
      isOpen={isTypeDrawerOpen}
      onClose={closeTypeDrawer}
      slideDirection="right"
    />
  );
}
