import {
  Autocomplete,
  Container,
  Stack,
  TextField,
  Typography,
} from '@mui/material';
import { GridColumns } from '@mui/x-data-grid';
import { useState } from 'react';
import { ConsumableReportProductRow } from 'src/components/modules/RevenueEvents/types/Consumable';
import { useConsumablesSalesView } from 'src/components/modules/RevenueEvents/view/Consumables/SalesReport/useView';
import Flex from 'src/components/shared/Flex';
import Link from 'src/components/shared/Link';
import MagicTable from 'src/components/shared/MagicTable/MagicTable';
import useMagicTable from 'src/components/shared/MagicTable/useMagicTable';
import NumberInfo from 'src/components/shared/NumberInfo/NumberInfo';
import SectionDivider from 'src/components/shared/SectionDivider';
import { CommonDateStrings } from 'src/lib/constants/CommonDates';
import { countries } from 'src/lib/constants/UserRegions';
import Routes from 'src/routes/Routes';
import HeaderBreadcrumbs from 'src/template/components/HeaderBreadcrumbs';
import Page from 'src/template/components/Page';

export default function ConsumablesSalesPage(): JSX.Element {
  return (
    <Page title="Consumable Sales">
      <Container maxWidth="xl">
        <HeaderBreadcrumbs
          heading="Consumable Sales"
          links={[{ name: 'Consumable Sales' }]}
        />
        <ConsumableSalesView />
      </Container>
    </Page>
  );
}

export function ConsumableSalesView(): JSX.Element {
  const [startDate, setStartDate] = useState(
    CommonDateStrings.firstRevenueEventV2EventDate.date
  );
  const [endDate, setEndDate] = useState(CommonDateStrings.today.date);
  const [country, setCountry] = useState<string | null>(null);

  const magicTableProps = useMagicTable();
  const { data, isLoading, onChangeFilter } = useConsumablesSalesView({
    startDate,
    endDate,
    country,
  });

  const rows = data?.productRows ?? [];

  return (
    <Stack spacing={3}>
      <Flex gap={2} justifyContent="left">
        <NumberInfo title="Total (Units)" total={Number(data?.total) || 0} />
      </Flex>
      <SectionDivider section="Dates are in UTC timezone" />
      <MagicTable.Container>
        <MagicTable.Container>
          <MagicTable.FilterContainer>
            <Autocomplete
              options={countries}
              fullWidth
              renderInput={(params) => (
                <TextField label="Country" {...params} />
              )}
              loading={false}
              defaultValue={country}
              onChange={(_, value) => {
                onChangeFilter('country', value);
                setCountry(value);
              }}
            />
            <TextField
              label="Starting Date"
              fullWidth
              type="date"
              InputLabelProps={{ shrink: true }}
              defaultValue={startDate}
              inputProps={{
                min: CommonDateStrings.firstRevenueEventV2EventDate.date,
                max: CommonDateStrings.today.date,
              }}
              onChange={({ target: { value } }) => {
                onChangeFilter('startDate', value);
                setStartDate(value);
              }}
            />
            <TextField
              label="Ending Date"
              fullWidth
              type="date"
              InputLabelProps={{ shrink: true }}
              defaultValue={endDate}
              inputProps={{
                min: CommonDateStrings.firstRevenueEventV2EventDate.date,
                max: CommonDateStrings.today.date,
              }}
              onChange={({ target: { value } }) => {
                onChangeFilter('endDate', value);
                setEndDate(value);
              }}
            />
          </MagicTable.FilterContainer>
          <MagicTable.Wrapper>
            <MagicTable
              getRowId={(row) => row.name}
              loading={isLoading}
              rows={rows}
              disableSelectionOnClick
              hideFooterPagination
              columns={columns}
              initialState={{
                sorting: { sortModel: [{ field: 'total', sort: 'desc' }] },
              }}
              {...magicTableProps}
            />
          </MagicTable.Wrapper>
        </MagicTable.Container>
      </MagicTable.Container>
    </Stack>
  );
}

const columns: GridColumns<ConsumableReportProductRow> = [
  {
    field: 'productName',
    headerName: 'Product',
    flex: 2,
    renderCell({ row }) {
      return (
        <Link
          underline="always"
          color="inherit"
          to={Routes.consumables_sales_list(row.productUuid)}
        >
          {row.name ?? '--'}
        </Link>
      );
    },
  },
  {
    field: 'total',
    headerName: 'Total',
    align: 'right',
  },
  {
    field: 'known_users',
    headerName: 'Known Users',
    align: 'right',
    renderCell({ value, row }) {
      return (
        <>
          {value}
          <Typography component="span" fontSize="12px" color="GrayText" ml={1}>
            (
            {Intl.NumberFormat('en-US', {
              style: 'percent',
              maximumFractionDigits: 2,
            }).format(value / row.total)}
            )
          </Typography>
        </>
      );
    },
  },
];
//* end
