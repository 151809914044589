import { Add } from '@mui/icons-material';
import { Button, Card, CardContent, debounce } from '@mui/material';
import useURLSearchParams from 'src/lib/hooks/useURLSearchParams';
import HeaderBreadcrumbs from 'src/template/components/HeaderBreadcrumbs';
import ChannelsTable from '../../components/Table';
import useChannelsView from './useView';

export default function StickyChannels() {
  const {
    data,
    isLoading,
    handleOpenCreateModal,
    handleOpenUpdateModal,
    handleOpenDeleteModal,
    params,
  } = useChannelsView();

  const { addParam, removeParam } = useURLSearchParams();

  function handleChangeSearch(value: string, param: string = 'query') {
    if (!value) removeParam(param);
    else addParam(param, value);
  }

  const debouncedSearch = debounce(handleChangeSearch, 300);

  return (
    <>
      <HeaderBreadcrumbs
        heading="Channels by sticky"
        links={[{ name: 'Channels by sticky' }]}
        action={
          <Button
            variant="contained"
            color="secondary"
            onClick={handleOpenCreateModal}
            startIcon={<Add />}
          >
            Create Channel
          </Button>
        }
      />
      <Card>
        <CardContent>
          <ChannelsTable
            data={data ?? []}
            isLoading={isLoading}
            params={params}
            handleOpenUpdateModal={handleOpenUpdateModal}
            handleOpenDeleteModal={handleOpenDeleteModal}
          />
        </CardContent>
      </Card>
    </>
  );
}
