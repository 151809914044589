import { Alert } from '@mui/material';
import { formatUTCDate } from 'src/lib/utils/formatUTCDate';
import { fetchLastVerificationDate } from '../../services/fetchLastVerificationDate';

export default function WalletVerificationAlertView(): JSX.Element {
  const { data } = fetchLastVerificationDate();
  return (
    <Alert severity="info">
      {`There might be small inconsistencies between this table and the real-time checks in the
          user's "wallet" tab, as the verifier script runs hourly. 
          \n
          Last date verified: ${
            data ? formatUTCDate(data.lastExecutionDate) : '-'
          }.`}
    </Alert>
  );
}
