import {
  CircularProgress,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
} from '@mui/material';
import Center from 'src/components/shared/Center';
import { TableHeadCustom } from 'src/template/components/table';
import { IHeadLabel } from 'src/template/components/table/TableHeadCustom';

import { UserPurge } from 'src/components/modules/Profile/types/UserPurge';
import UserPurgeTableRow from './Row';
import useUserPurgesTable from './useUserPurgesTable';

const TABLE_HEAD: IHeadLabel[] = [
  { id: '', label: '' },
  { id: 'status', label: 'Status' },
  { id: 'user', label: 'User' },
  { id: 'createdByUser', label: 'Created By' },
  { id: 'triggeredByUser', label: 'Triggered By' },
  { id: 'startedAt', label: 'Started At', align: 'right' },
  { id: 'completedAt', label: 'completed At', align: 'right' },
  { id: 'updatedAt', label: 'Updated', align: 'right' },
  { id: 'createdAt', label: 'Created', align: 'right' },
  { id: 'actions', label: 'Actions', align: 'right' },
];

export default function UserPurgesTable({
  data,
  loading,
  searchHighlight,
}: {
  data: UserPurge[] | undefined;
  loading: boolean;
  searchHighlight: { searchColumn: string; searchKeyword: string };
}) {
  const [order, orderBy, selected, toggleSelected] = useUserPurgesTable(
    (state) => [
      state.filter.order,
      state.filter.orderBy,
      state.selected,
      state.toggleSelected,
    ]
  );

  const onSort = useUserPurgesTable((state) => state.onSort);

  const dense = useUserPurgesTable((state) => state.dense);

  return (
    <TableContainer sx={{ minWidth: '100%', position: 'relative' }}>
      <Table size={dense ? 'small' : 'medium'}>
        <TableHeadCustom
          order={order}
          orderBy={orderBy}
          headLabel={TABLE_HEAD}
          rowCount={loading ? 0 : data && data.length}
          onSort={onSort}
        />
        <TableBody>
          {loading || !data ? (
            <TableRow>
              <TableCell colSpan={TABLE_HEAD.length + 1}>
                <Center>
                  <CircularProgress />
                </Center>
              </TableCell>
            </TableRow>
          ) : (
            data &&
            data.map((userPurge: UserPurge) => (
              <UserPurgeTableRow
                key={userPurge.uuid}
                userPurge={userPurge}
                searchHighlight={searchHighlight}
                isSelected={selected.includes(userPurge.uuid)}
                handleSelect={toggleSelected}
              />
            ))
          )}
        </TableBody>
      </Table>
    </TableContainer>
  );
}
