import { EntityNoteTypes } from 'src/lib/types/entityNote';
import EntityNoteForm from '../../components/Form';
import useCreateEntityNote from './useView';

type Props = {
  entityType: EntityNoteTypes;
  entityUuid: string;
};
export default function CreateEntityNote({
  entityUuid,
  entityType,
}: Props): JSX.Element {
  const { handleCreateEntityNote } = useCreateEntityNote();
  return (
    <EntityNoteForm
      defaultValues={{
        note: '',
        tagName: '',
        entityUuid,
        entityType,
      }}
      title={`Create a note`}
      onSubmit={async (values) => {
        await handleCreateEntityNote({ ...values });
      }}
    />
  );
}
