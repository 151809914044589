import {
  Box,
  Card,
  CardProps,
  Stack,
  Typography,
  useTheme,
} from '@mui/material';
import { ReactNode } from 'react';

export type NumberInfoProps = {
  title: string;
  text: string;
  info?: ReactNode;
  componentProps?: CardProps;
};

export default function CardInfo({
  title,
  text,
  info,
  componentProps,
}: NumberInfoProps) {
  const theme = useTheme();

  return (
    <Card
      {...componentProps}
      sx={{
        display: 'flex',
        alignItems: 'center',
        p: 3,
        transitionDuration: '0.2s',
        transitionProperty: 'background-color',
        ...componentProps?.sx,
        ...(componentProps?.onClick
          ? {
              ':hover': {
                cursor: 'pointer',
                backgroundColor: `${theme.palette.primary.main}1A`,
              },
            }
          : {}),
      }}
    >
      <Box sx={{ flexGrow: 1 }}>
        <Typography variant="subtitle2">{title}</Typography>

        {info ? (
          <Stack
            direction="row"
            alignItems="center"
            spacing={1}
            sx={{ mt: 2, mb: 1 }}
          >
            {info}
          </Stack>
        ) : null}

        <Typography variant="h3">{text}</Typography>
      </Box>
    </Card>
  );
}
