import getAPIClient from 'src/lib/services/api/axios.config';
import { ServiceResponse } from 'src/lib/types/ServiceResponse';
import {
  Contest,
  ContestAwardDistributionModes,
  ContestFrequency,
} from 'src/lib/types/contest';
import { getMessageFromError } from 'src/lib/utils/error';

export type CreateContestData = {
  name: string;
  gameUuid: string;
  details: string;
  entryPrice: number;
  playerEntryLimit: number | null;
  awardValue: number | null;
  awardDistributionMode: ContestAwardDistributionModes;
  frequency: ContestFrequency;
  startDate: string | Date;
  endDate: string | Date | null;
  primaryColor: string;
  secondaryColor: string;
  awardConfiguration: number[];
  awardWinningStatisticsGroupUuid: string;
};

export async function CreateContest(
  data: CreateContestData
): Promise<ServiceResponse<Contest>> {
  try {
    const api = await getAPIClient();
    const res = await api.post(`admin/game-contest/`, { ...data, assets: [] });
    return {
      data: res.data,
      error: null,
    };
  } catch (e) {
    return {
      data: null,
      error: getMessageFromError(e),
    };
  }
}
