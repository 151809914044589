import {
  Autocomplete,
  Card,
  CardContent,
  InputAdornment,
  TextField,
  debounce,
} from '@mui/material';
import { GridSortDirection } from '@mui/x-data-grid';
import { CollectionToAdd } from 'src/components/modules/Clubs/components/Forms/Club/types';
import Flex from 'src/components/shared/Flex';
import { Collection } from 'src/lib/types/collection';
import Iconify from 'src/template/components/Iconify';
import AddCollectionsTable from '../../components/addCollectionsTable';
import useSelectCollections from './useView';

const searchOptions = [
  {
    label: 'Name',
    value: 'name',
  },
  {
    label: 'UUID',
    value: 'uuid',
  },
  {
    label: 'Description',
    value: 'description',
  },
  {
    label: 'Creator Name',
    value: 'user.name',
  },
  {
    label: 'Slug',
    value: 'slug',
  },
  {
    label: 'User Email',
    value: 'user.email',
  },
  {
    label: 'Blockchain',
    value: 'blockchain',
  },
];

interface SelectCollectionProps {
  handleAddCollection: (collection: Collection) => void;
  handleRemoveCollection: (uuid: string) => void;
  selectedCollections: CollectionToAdd[];
}

export default function SelectCollections({
  handleAddCollection,
  handleRemoveCollection,
  selectedCollections,
}: SelectCollectionProps): JSX.Element {
  const {
    data,
    isLoading,
    order,
    page,
    query,
    searchBy,
    size,
    orderBy,
    setOrder,
    setPage,
    setRowsPerPage,
    setQuery,
    setSearchBy,
    setOrderBy,
  } = useSelectCollections();

  function handleChangeSearch(value: string) {
    setQuery(value);
  }

  const debouncedSearch = debounce(handleChangeSearch, 300);

  return (
    <Card>
      <Flex gap={2} p={2}>
        <Autocomplete
          disableClearable
          options={searchOptions}
          fullWidth
          renderInput={(params) => <TextField label="Search By" {...params} />}
          getOptionLabel={(p) => p.label}
          sx={{ maxWidth: 250 }}
          defaultValue={searchOptions.find((o) => o.value === searchBy)}
          key={searchBy}
          onChange={(_, value) => {
            setSearchBy(value?.value ?? '');
          }}
        />

        <TextField
          fullWidth
          label="Search"
          defaultValue={query}
          type="search"
          onChange={(e) => {
            const { value } = e.target;
            debouncedSearch(value);
          }}
          key={query}
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <Iconify
                  icon="eva:search-fill"
                  sx={{ color: 'text.disabled', width: 20, height: 20 }}
                />
              </InputAdornment>
            ),
          }}
        />
      </Flex>

      <CardContent>
        <AddCollectionsTable
          data={data ?? []}
          isLoading={isLoading}
          page={page}
          size={size}
          sortBy={orderBy}
          order={order as GridSortDirection}
          setOrder={setOrder as (order: GridSortDirection) => void}
          setSortBy={setOrderBy}
          setPage={setPage}
          setRowsPerPage={setRowsPerPage}
          handleAddCollection={handleAddCollection}
          handleRemoveCollection={handleRemoveCollection}
          selectedCollections={selectedCollections}
        />
      </CardContent>
    </Card>
  );
}
