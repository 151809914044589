import { Autocomplete, InputAdornment, TextField } from '@mui/material';
import Flex from 'src/components/shared/Flex';
import Iconify from 'src/template/components/Iconify';
import { FilterProps } from './types';

export function FeedItemTableSearchFilters({
  addParam,
  removeParam,
  debouncedChangeField,
  propParams,
}: FilterProps) {
  const feedItemSearchOptions = [
    {
      label: 'Title',
      query: 'title',
    },
    {
      label: 'Related Item name',
      query: 'itemName',
    },
    {
      label: 'Related Item UUID',
      query: 'itemUuid',
    },
  ];
  return (
    <>
      <Autocomplete
        options={feedItemSearchOptions}
        fullWidth
        renderInput={(params) => (
          <TextField label="Search Feed by..." {...params} fullWidth />
        )}
        getOptionLabel={(p) => p.label}
        sx={{ maxWidth: 250 }}
        defaultValue={
          feedItemSearchOptions.find(
            (o) => o.query === propParams.filterSearchBy
          ) || null
        }
        key={'filterSearchBy'}
        onChange={(_, value) => {
          if (!value) removeParam('filterSearchBy');
          else addParam('filterSearchBy', value.query);
        }}
      />
      <TextField
        fullWidth
        label="Search"
        defaultValue={propParams.filterQuery}
        type="search"
        onChange={(e) => {
          const { value } = e.target;
          debouncedChangeField(value, 'filterQuery');
        }}
        key={'filterQuery'}
        InputProps={{
          startAdornment: (
            <InputAdornment position="start">
              <Iconify
                icon="eva:search-fill"
                sx={{ color: 'text.disabled', width: 20, height: 20 }}
              />
            </InputAdornment>
          ),
        }}
      />

      <Flex gap={1} justifyContent={'flex-end'} p={2}>
        <TextField
          type="date"
          label="Start Date"
          InputLabelProps={{ shrink: true }}
          defaultValue={propParams.startDate}
          onChange={(e) => {
            const { value } = e.target;
            if (value) {
              debouncedChangeField(value, 'startDate');
            } else removeParam('startDate');
          }}
        />
        <TextField
          type="date"
          label="End Date"
          InputLabelProps={{ shrink: true }}
          defaultValue={propParams.endDate}
          onChange={(e) => {
            const { value } = e.target;
            if (value) {
              debouncedChangeField(value, 'endDate');
            } else removeParam('endDate');
          }}
        />
      </Flex>
    </>
  );
}
