import { DeepPartial } from 'react-hook-form';
import { GamePublisher } from 'src/lib/types/game';
import { parseSearchParams } from 'src/lib/utils/parseSearchParams';
import useSWR, { SWRResponse } from 'swr';

export type PossibleParams = {
  page: number | string;
  size: number | string;
  order: Record<string, string>;
  filter: {
    [key: string]: {
      like: string;
      eq: string;
    };
  };
};

export const fetchGamePublishers = (
  filter?: DeepPartial<PossibleParams>,
  shouldFetch = true
): SWRResponse<GamePublisher[]> => {
  const parsedParams = parseSearchParams(filter ?? {});
  const response = useSWR<GamePublisher[], Error>(
    shouldFetch ? `admin/game/publishers/?${parsedParams}` : null
  );

  return response;
};
