export function addHoursToDate(hours: number, date = new Date()): Date {
  const resultDate = new Date(date);
  resultDate.setHours(resultDate.getHours() + hours);
  return resultDate;
}

export function add3HoursToDate(date = new Date()): Date {
  return addHoursToDate(3, date);
}

export function newDateWith3Hours(): Date {
  return add3HoursToDate();
}
