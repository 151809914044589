import useNotify from 'src/lib/hooks/useNotify';
import useModalStore from 'src/lib/stores/useModalStore';
import { ClubEmitter } from '../../events/ClubEmitter';
import { unbanUserFromClub } from '../../services/unbanUserFromClub';

export default function useUnbanUserFromClub() {
  const closeModal = useModalStore((state) => state.closeModal);
  const notify = useNotify();

  const handleUnbanUserFromClub = async (
    userUuid: string,
    clubUuid: string
  ) => {
    const { data, error } = await unbanUserFromClub(userUuid, clubUuid);
    if (error) {
      notify(error, { variant: 'error' });
      closeModal();
    } else {
      notify('User unbanned successfuly!', { variant: 'success' });
      closeModal();
      ClubEmitter.emit('UserUnbanned', data);
    }
  };

  return {
    handleUnbanUserFromClub,
  };
}
