import { Stack } from '@mui/material';
import SectionDivider from 'src/components/shared/SectionDivider';
import TransactionHistoryTable from './TransactionHistoryTable';

export default function TransactionHistory({
  userUuid,
}: {
  userUuid?: string;
}) {
  const dividerString = userUuid
    ? 'Transaction related to this User'
    : 'System Transaction History';

  return (
    <Stack gap={2}>
      <SectionDivider section={dividerString} />
      <TransactionHistoryTable userUuid={userUuid} />
    </Stack>
  );
}
