import { debounce } from 'lodash';
import { DEFAULT_INITIAL_PAGE_SIZE } from 'src/lib/constants/pagination';
import useURLSearchParams from 'src/lib/hooks/useURLSearchParams';
import useModalStore from 'src/lib/stores/useModalStore';
import { fetchMostActiveUsers } from '../../services/fetchMostActiveUsers';
import SGMMostActiveUsersFilter from './Filters';

export default function useMakerDialogs() {
  const { addParam, removeParam, allSearchParams } =
    useURLSearchParams(initialParams);
  const openModal = useModalStore((state) => state.openModal);

  const params = {
    page: +(allSearchParams.page ?? initialParams.page),
    size: +(allSearchParams.size ?? initialParams.size),
    orderBy: allSearchParams.orderBy ?? initialParams.orderBy,
    sortOrder: allSearchParams.order ?? initialParams.order,

    searchBy: allSearchParams.searchBy ?? initialParams.searchBy,
    query: allSearchParams.query ?? initialParams.query,

    startMessagesCreatedBetween:
      allSearchParams.startMessagesCreatedBetween ?? '',
    endMessagesCreatedBetween: allSearchParams.endMessagesCreatedBetween ?? '',
    minTotalChats: parseInt(
      allSearchParams.minTotalChats ?? initialParams.minTotalChats,
      10
    ),
    maxTotalChats: parseInt(
      allSearchParams.maxTotalChats ?? initialParams.maxTotalChats,
      10
    ),
    minTotalMessages: parseInt(
      allSearchParams.minTotalMessages ?? initialParams.minTotalMessages,
      10
    ),
    maxTotalMessages: parseInt(
      allSearchParams.maxTotalMessages ?? initialParams.maxTotalMessages,
      10
    ),
  };

  const { data, isLoading } = fetchMostActiveUsers({
    limit: params.size,
    offset: params.page * params.size,

    ...(params.orderBy &&
      params.sortOrder && {
        order: {
          [params.orderBy]: params.sortOrder,
        },
      }),

    filter: {
      messagesCreatedBetween: {
        ...(params.startMessagesCreatedBetween && {
          gte: `${params.startMessagesCreatedBetween}T00:00:00.000Z`,
        }),
        ...(params.endMessagesCreatedBetween && {
          lte: `${params.endMessagesCreatedBetween}T23:59:59.999Z`,
        }),
      },
      totalChats: {
        ...(params.minTotalChats && { gte: params.minTotalChats }),
        ...(params.maxTotalChats && { lte: params.maxTotalChats }),
      },
      totalMessages: {
        ...(params.minTotalMessages && { gte: params.minTotalMessages }),
        ...(params.maxTotalMessages && { lte: params.maxTotalMessages }),
      },
      ...(params.query &&
        params.searchBy == 'userUuid' && {
          userUuid: params.query,
        }),
    },
  });

  async function handleOpenFilters() {
    openModal(<SGMMostActiveUsersFilter />);
  }

  function handleChangeSearch(value: string, param: string = 'query') {
    if (!value) removeParam(param);
    else addParam(param, value);
  }

  const debouncedSearch = debounce(handleChangeSearch, 1000);

  return {
    data,
    isLoading,
    params,
    handleOpenFilters,
    addParam,
    removeParam,
    debouncedSearch,
  };
}

const initialParams = {
  page: '0',
  size: DEFAULT_INITIAL_PAGE_SIZE.toString(),
  order: 'desc',
  orderBy: 'totalMessages',
  searchBy: 'userUuid',
  query: '',

  staertMessagesCreatedBetween: '',
  endMessagesCreatedBetween: '',
  minTotalChats: '',
  maxTotalChats: '',
  minTotalMessages: '',
  maxTotalMessages: '',
};

export type FetchMakerMostActiveUsersParams = {
  page: number;
  size: number;
  orderBy: string;
  sortOrder: string;
  searchBy: string;
  query: string;

  startMessagesCreatedBetween?: string;
  endMessagesCreatedBetween?: string;
  minTotalChats?: number;
  maxTotalChats?: number;
  minTotalMessages?: number;
  maxTotalMessages?: number;
};
