import { DeepPartial } from 'react-hook-form';
import { PlayerSessionStatsListItem } from 'src/lib/types/gameSession';
import { WithCount } from 'src/lib/types/withCount';
import { parseSearchParams } from 'src/lib/utils/parseSearchParams';
import useSWR, { SWRResponse } from 'swr';

export type PossibleParams = {
  limit: number | string;
  offset: number | string;
  sortOrder: string;
  orderBy: string;
  startDate: string;
  endDate: string;
};

export const fetchPlayerStats = (
  filter?: DeepPartial<PossibleParams>
): SWRResponse<WithCount<PlayerSessionStatsListItem>> => {
  const parsedParams = parseSearchParams(filter ?? {});
  const response = useSWR<WithCount<PlayerSessionStatsListItem>, Error>(
    `admin/game-session/player/stats/?${parsedParams}`
  );

  return response;
};
