import { DeepPartial } from 'react-hook-form';
import { PaginatedResponse } from 'src/lib/types/PaginatedResponse';
import { Product } from 'src/lib/types/product';
import { getMessageFromError } from 'src/lib/utils/error';
import { parseSearchParams } from 'src/lib/utils/parseSearchParams';
import useSWR, { SWRResponse } from 'swr';

type PossibleParams = {
  size: number | string;
  page: number | string;
  order: Record<string, string>;
  filter: Record<string, string>;
};

export function fetchConsumables(
  filters?: DeepPartial<PossibleParams>
): SWRResponse<PaginatedResponse<Product[]>> {
  const parsedParams = parseSearchParams(filters ?? {});

  const { error, ...response } = useSWR(
    `admin/products/consumables/?${parsedParams}`
  );
  return {
    ...response,
    error: error ? getMessageFromError(error) : undefined,
  };
}
