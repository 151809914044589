import { InputAdornment, TextField } from '@mui/material';
import { useEffect, useState } from 'react';
import useDebounce from 'src/lib/hooks/useDebounce';
import Iconify from 'src/template/components/Iconify';

interface SmartTableTextInputProps {
  setValue: (value: string) => void;
  defaultValue?: string;
}
function SmartTableTextInput({
  setValue,
  defaultValue,
}: SmartTableTextInputProps) {
  const [inputValue, setInputValue] = useState(defaultValue ?? '');
  const debouncedValue = useDebounce(inputValue, 500);

  useEffect(() => {
    setValue(debouncedValue);
  }, [debouncedValue]);
  return (
    <TextField
      fullWidth
      value={inputValue}
      onChange={(e) => setInputValue(e.target.value)}
      placeholder="Search"
      InputProps={{
        startAdornment: (
          <InputAdornment position="start">
            <Iconify
              icon="eva:search-fill"
              sx={{ color: 'text.disabled', width: 20, height: 20 }}
            />
          </InputAdornment>
        ),
      }}
    />
  );
}
export default SmartTableTextInput;
