import { useCallback, useEffect } from 'react';
import { UserClubRelations } from 'src/components/modules/Profile/sections/Clubs/types';
import { DEFAULT_INITIAL_PAGE_SIZE } from 'src/lib/constants/pagination';
import useURLSearchParams from 'src/lib/hooks/useURLSearchParams';
import useModalStore from 'src/lib/stores/useModalStore';
import { ExternalClubAsset } from 'src/lib/types/club';
import { ClubWithObjectLinks } from 'src/lib/types/clubWithAdminAnalytics';
import { OBJECT_MODERATION_REVIEW_STATUS } from 'src/lib/types/objectModeration';
import useTabs from 'src/template/hooks/useTabs';
import { ClubEmitter } from '../../events/ClubEmitter';
import { fetchClubsWithCount } from '../../services/fetchClubsWithCount';
import AddExternalAssetView from '../AddExternalAsset';
import CreateClubView from '../Create';
import DeleteClubView from '../Delete';
import ListExternalAssets from '../ListExternalAssets';
import UpdateClubView from '../Update';

const getRelationFilter = (userUuid: string, relation: UserClubRelations) => {
  switch (relation) {
    case UserClubRelations.ADMIN:
      return { adminUuid: userUuid };
    case UserClubRelations.MEMBER:
      return { memberUuid: userUuid };
    case UserClubRelations.CREATOR:
      return { createdByUserUuid: userUuid };
    default:
      return {};
  }
};

interface UseClubsViewProps {
  userUuid?: string;
  relation?: UserClubRelations;
}

export default function useClubsView({
  userUuid,
  relation,
}: UseClubsViewProps) {
  const { currentTab, onChangeTab } = useTabs('all', {
    queryName: 'tab',
  });

  const openModal = useModalStore((state) => state.openModal);

  const handleOpenCreateModal = async () => {
    openModal(<CreateClubView />);
  };
  const handleOpenUpdateModal = async (club: ClubWithObjectLinks) => {
    openModal(<UpdateClubView club={club} />);
  };
  const handleOpenDeleteModal = async (uuid: string) => {
    openModal(<DeleteClubView uuid={uuid} />);
  };
  const handleOpenAddAssetModal = async (uuid: string) => {
    openModal(<AddExternalAssetView uuid={uuid} />);
  };
  const handleOpenAssetsListModal = async (
    chats: ExternalClubAsset[],
    topics: ExternalClubAsset[]
  ) => {
    openModal(<ListExternalAssets chats={chats} topics={topics} />);
  };

  const { allSearchParams } = useURLSearchParams(initialParams);
  const page = Number(allSearchParams.page);
  const size = Number(allSearchParams.size);

  /* 
  const { currentTab: status, onChangeTab: onChangeStatus } = useTabs('all', {
    queryName: 'status',
  });

  const filters = {
    status: status === 'all' ? undefined : status,
  };
  */

  let relationFilter = {};
  if (userUuid) {
    relationFilter = getRelationFilter(
      userUuid,
      relation ?? UserClubRelations.MEMBER
    );
  }

  const params = {
    page: +(allSearchParams.page ?? initialParams.page),
    size: +(allSearchParams.size ?? initialParams.size),
    orderBy: allSearchParams.orderBy ?? initialParams.orderBy,
    sortOrder: allSearchParams.order ?? initialParams.order,
    searchBy: allSearchParams.searchBy ?? initialParams.searchBy,
    query: allSearchParams.query ?? initialParams.query,
  };

  const query: any = {};
  if (params.searchBy && params.query) {
    query[params.searchBy] = {
      like: `${params.query}%`,
    };
  }

  let reviewStatusFilter: any = {};
  if (!['all', 'humanAnalysisNeeded'].includes(currentTab ?? '')) {
    reviewStatusFilter = {
      reviewStatus: currentTab as OBJECT_MODERATION_REVIEW_STATUS,
    };
  }

  let humanAnalysisNeededFilter: any = {};
  if (currentTab == 'humanAnalysisNeeded') {
    humanAnalysisNeededFilter = {
      humanAnalysisNeeded: true,
    };
  }

  const { data, isLoading, mutate } = fetchClubsWithCount({
    page,
    size,

    ...(params.orderBy &&
      params.sortOrder && {
        order: {
          [params.orderBy]: params.sortOrder,
        },
      }),

    filter: {
      ...query,
      ...relationFilter,
      ...reviewStatusFilter,
      ...humanAnalysisNeededFilter,
    },
  });

  const refetchData = useCallback(() => {
    mutate?.();
  }, [mutate]);

  useEffect(() => {
    ClubEmitter.on('ClubCreated', refetchData);
    ClubEmitter.on('ClubUpdated', refetchData);
    ClubEmitter.on('ClubDeleted', refetchData);

    return () => {
      ClubEmitter.off('ClubCreated', refetchData);
      ClubEmitter.off('ClubUpdated', refetchData);
      ClubEmitter.off('ClubDeleted', refetchData);
    };
  }, [refetchData]);

  return {
    data,
    isLoading,
    params,
    handleOpenCreateModal,
    handleOpenUpdateModal,
    handleOpenDeleteModal,
    handleOpenAddAssetModal,
    handleOpenAssetsListModal,
    currentTab,
    onChangeTab,
  };
}

const initialParams = {
  page: '0',
  size: DEFAULT_INITIAL_PAGE_SIZE.toString(),
  order: 'desc',
  orderBy: 'createdAt',
  searchBy: 'name',
  query: '',
};

export type FetchClubsQueryParams = {
  page: number;
  size: number;
  orderBy: string;
  searchBy: string;
  sortOrder: string;
};
