import { Add as AddIcon, Remove as RemoveIcon } from '@mui/icons-material';
import { Button, IconButton, Stack, TextField } from '@mui/material';
import { useMemo, useState } from 'react';

interface ItemDataInputProps {
  itemDataValue: Record<string, any> | null;
  onChange: (value: Record<string, any>) => void;
}

function ItemDataInput({ itemDataValue, onChange }: ItemDataInputProps) {
  const [keyValuePairs, setKeyValuePairs] = useState(
    itemDataValue ? Object.entries(itemDataValue) : []
  );

  const handleAddPair = () => {
    setKeyValuePairs([...keyValuePairs, ['', '']]);
  };

  const handleRemovePair = (index: number) => {
    const newPairs = keyValuePairs.filter((_, i) => i !== index);
    setKeyValuePairs(newPairs);
    onChange(Object.fromEntries(newPairs));
  };

  const handleKeyChange = useMemo(
    () => (index: number, newKey: string) => {
      const newPairs: [string, any][] = keyValuePairs.map((pair, i) =>
        i === index ? [newKey, pair[1]] : pair
      );
      setKeyValuePairs(newPairs);
      onChange(Object.fromEntries(newPairs));
    },
    [keyValuePairs, onChange]
  );

  const handleValueChange = useMemo(
    () => (index: number, newValue: string) => {
      const newPairs: [string, any][] = keyValuePairs.map((pair, i) =>
        i === index ? [pair[0], newValue] : pair
      );
      setKeyValuePairs(newPairs);
      onChange(Object.fromEntries(newPairs));
    },
    [keyValuePairs, onChange]
  );
  return (
    <Stack spacing={2}>
      {keyValuePairs.map(([key, value], index) => (
        // eslint-disable-next-line react/no-array-index-key
        <Stack direction="row" spacing={1} key={index}>
          <TextField
            label="Key"
            value={key}
            onChange={(e) => handleKeyChange(index, e.target.value)}
            fullWidth
          />
          <TextField
            label="Value"
            value={value}
            onChange={(e) => handleValueChange(index, e.target.value)}
            fullWidth
          />
          <IconButton onClick={() => handleRemovePair(index)}>
            <RemoveIcon />
          </IconButton>
        </Stack>
      ))}
      <Button onClick={handleAddPair} variant="contained">
        <AddIcon /> Add Item Data
      </Button>
    </Stack>
  );
}

export default ItemDataInput;
