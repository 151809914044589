import create from 'zustand';

interface IModalStore {
  previousModal: JSX.Element | null;
  modal: JSX.Element | null;
  isOpen: boolean;
  openModal: (modal: JSX.Element) => void;
  openPreviousModal: () => void;
  closeModal: () => void;
}

const useModalStore = create<IModalStore>((set, get) => ({
  previousModal: null,
  modal: null,
  isOpen: false,
  openModal: (modal) =>
    set((state) => ({ isOpen: true, previousModal: state.modal, modal })),
  openPreviousModal: () => {
    const { previousModal } = get();
    if (previousModal)
      set((state) => ({ isOpen: true, modal: state.previousModal }));
  },
  closeModal: () => {
    set(() => ({ isOpen: false }));
    set({ modal: null });
  },
}));

export default useModalStore;
