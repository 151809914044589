import getAPIClient from 'src/lib/services/api/axios.config';
import { ServiceResponse } from 'src/lib/types/ServiceResponse';
import { Game } from 'src/lib/types/game';
import { getMessageFromError } from 'src/lib/utils/error';

export type UpdateGameStatusData = {
  status: 'active' | 'inactive';
};

export async function UpdateGameStatus(
  uuid: string,
  data: UpdateGameStatusData
): Promise<ServiceResponse<Game>> {
  try {
    const api = await getAPIClient();
    const res = await api.patch(`admin/game/${uuid}/status`, data);
    return {
      data: res.data,
      error: null,
    };
  } catch (e) {
    return {
      data: null,
      error: getMessageFromError(e),
    };
  }
}
