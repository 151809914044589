import { Card, CardContent, Container } from '@mui/material';
import Routes from 'src/routes/Routes';
import HeaderBreadcrumbs from 'src/template/components/HeaderBreadcrumbs';
import ContestEventRankingsTable from '../../components/ContestEventRankingsTable';
import useContestEventRankings from './useView';

export default function ContestEventRankings() {
  const { data, isLoading, params, eventUuid, handleOpenListAssetsUsed } =
    useContestEventRankings();

  return (
    <Container maxWidth="xl">
      <HeaderBreadcrumbs
        heading="Rankings"
        links={[
          { name: 'Contests', href: Routes.configurations.contests },
          { name: 'Rankings' },
        ]}
      />

      <Card>
        <CardContent>
          <ContestEventRankingsTable
            data={data}
            isLoading={isLoading}
            params={params}
            handleOpenListAssetsUsed={handleOpenListAssetsUsed}
          />
        </CardContent>
      </Card>
    </Container>
  );
}
