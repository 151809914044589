import {
  Edit,
  Facebook,
  Instagram,
  Language,
  Telegram,
  Twitter,
  YouTube,
} from '@mui/icons-material';
import {
  Card,
  CardContent,
  CardHeader,
  Grid,
  IconButton,
  Tooltip,
  Typography,
} from '@mui/material';
import { useState } from 'react';
import { Collection } from 'src/lib/types/collection';
import { SocialMedia } from 'src/lib/types/socialMedia';
import Iconify from 'src/template/components/Iconify';
import UpdateSocialMediaModal from './UpdateSocialMediaModal';

export default function CollectionSocialMedia({
  collection,
}: {
  collection: Collection;
}) {
  const [showEditSocialMediaModal, setShowEditSocialMediaModal] =
    useState(false);

  const isSocialMediaEmpty = Object.entries(collection.socialMedia).every(
    ([key, value]) =>
      !socialMediaIcons.find((i) => i.value === key) ||
      value === null ||
      value === ''
  );

  const getLinkFromSocialMediaTextValue = (value: string): string => {
    const formattedValue = value.trim();
    if (!formattedValue?.length) return '';
    return formattedValue.startsWith('http://') ||
      formattedValue.startsWith('https://')
      ? formattedValue
      : `http://${formattedValue}`;
  };

  const handleGoToSocialMedia = (value: string) => {
    const link = getLinkFromSocialMediaTextValue(value);
    if (link) {
      window.open(link, '_blank', 'noopener,noreferrer')?.focus();
    }
  };

  return (
    <>
      <Card>
        <Tooltip title="Edit Social Media" placement="top">
          <IconButton
            className="edit-social-media-button"
            sx={{
              position: 'absolute',
              top: 20,
              right: 20,
            }}
            onClick={() => setShowEditSocialMediaModal(true)}
          >
            <Edit />
          </IconButton>
        </Tooltip>
        <CardHeader title="Social Media" />
        <CardContent>
          <Grid container>
            {socialMediaIcons.map(
              ({ value, icon }) =>
                collection?.socialMedia[String(value) as keyof SocialMedia] && (
                  <Grid item>
                    <IconButton
                      key={value}
                      onClick={() =>
                        handleGoToSocialMedia(
                          collection?.socialMedia[
                            value as keyof SocialMedia
                          ] as string
                        )
                      }
                      aria-label={`Go to ${value} page`}
                    >
                      {icon}
                    </IconButton>
                  </Grid>
                )
            )}
            {isSocialMediaEmpty && (
              <Typography color="text.secondary">Empty</Typography>
            )}
          </Grid>
        </CardContent>
      </Card>
      <UpdateSocialMediaModal
        collection={collection}
        modalIsOpen={showEditSocialMediaModal}
        closeModal={() => setShowEditSocialMediaModal(false)}
        socialMediaIcons={socialMediaIcons}
      />
    </>
  );
}

const socialMediaIcons: { value: keyof SocialMedia; icon: JSX.Element }[] = [
  { value: 'instagram', icon: <Instagram /> },
  { value: 'twitter', icon: <Twitter /> },
  { value: 'youtube', icon: <YouTube /> },
  { value: 'website', icon: <Language /> },
  { value: 'facebook', icon: <Facebook /> },
  { value: 'telegram', icon: <Telegram /> },
  {
    value: 'tiktok',
    icon: <Iconify icon="ic:round-tiktok" sx={{ fontSize: '1.5rem' }} />,
  },
  {
    value: 'medium',
    icon: <Iconify icon="mingcute:medium-fill" sx={{ fontSize: '1.5rem' }} />,
  },
];
