import { Box } from '@mui/material';
import { capitalCase } from 'change-case';
import MagicTable from 'src/components/shared/MagicTable/MagicTable';
import MagicTableUserCell from 'src/components/shared/MagicTable/UserCell';
import { FormReviewStatus } from 'src/lib/types/formReviewStatus';
import { formatUTCDate } from 'src/lib/utils/formatUTCDate';
import Label from 'src/template/components/Label';
import { getReviewLabelColor } from '../../utils/utils';

type ReviewHistoryTableProps = {
  data: FormReviewStatus[];
  isLoading: boolean;
};

export default function ReviewHistoryTable({
  data,
  isLoading,
}: ReviewHistoryTableProps) {
  return (
    <MagicTable.Container>
      <Box p={2}>
        <MagicTable
          loading={isLoading}
          rows={data ?? []}
          sortingMode="client"
          hideNumberOfRows
          hideFooter
          initialState={{
            sorting: {
              sortModel: [
                {
                  field: 'createdAt',
                  sort: 'desc',
                },
              ],
            },
          }}
          columns={[
            {
              field: 'reviewer',
              headerName: 'Reviewer',
              sortable: false,
              renderCell({ row }) {
                return <MagicTableUserCell user={row.reviewer} />;
              },
            },
            {
              field: 'status',
              headerName: 'Status',
              sortable: false,
              renderCell({ row }) {
                return (
                  <Label
                    ml={4}
                    color={getReviewLabelColor(row.status)}
                    fontWeight="bold"
                  >
                    {capitalCase(row.status)}
                  </Label>
                );
              },
            },
            {
              field: 'reviewerComment',
              headerName: 'Reviewer Comment',
              sortable: false,
            },
            {
              field: 'createdAt',
              headerName: 'Created At',
              sortable: true,
              sortingOrder: ['desc', 'asc'],
              sortComparator: (a, b) => a - b,
              valueFormatter({ value }) {
                return value ? formatUTCDate(value) : '-';
              },
            },
          ]}
        />
      </Box>
    </MagicTable.Container>
  );
}
