import { TabContext, TabPanel } from '@mui/lab';
import { Card, Tab, Tabs } from '@mui/material';
import useTabs from 'src/template/hooks/useTabs';
import TopBalancesView from './TopBalances';
import UserRankTopBuyersView from './TopBuyers';
import TopDevicesView from './TopDevices';
import TopFlaggedTransactionsView from './TopFlaggedTransactions';
import TopImporterAndCreator from './TopImporterAndCreator';
import UserRankTopSellersView from './TopSellers';
import TopTransfers from './TopTransfers';

type TabId =
  | 'sellers'
  | 'buyers'
  | 'transfers'
  | 'importer_and_creator'
  | 'devices'
  | 'balances'
  | 'flagged_transactions';

const rankTabs: Record<TabId, { label: string; value: TabId }> = {
  balances: {
    label: 'Top Balances',
    value: 'balances',
  },
  sellers: {
    label: 'Top Sellers',
    value: 'sellers',
  },
  buyers: {
    label: 'Top Buyers',
    value: 'buyers',
  },
  transfers: {
    label: 'Top Transfers',
    value: 'transfers',
  },
  importer_and_creator: {
    label: 'Top Importer / Creator',
    value: 'importer_and_creator',
  },
  devices: {
    label: 'Top Devices',
    value: 'devices',
  },

  flagged_transactions: {
    label: 'Flagged Transactions',
    value: 'flagged_transactions',
  },
} as const;

const initialTab: TabId = 'balances';
export default function UserRanksView(): JSX.Element {
  const { currentTab: rankType, onChangeTab: onChangeRankType } = useTabs(
    initialTab,
    { resetOnChange: true }
  );

  return (
    <Card>
      <TabContext value={rankType ?? initialTab}>
        <Tabs
          allowScrollButtonsMobile
          variant="scrollable"
          scrollButtons="auto"
          TabIndicatorProps={{
            sx: {
              height: '3px',
              borderRadius: '20px',
            },
          }}
          sx={{
            mb: '1rem',
            px: 2,
            bgcolor: 'background.neutral',
          }}
          value={rankType}
          onChange={onChangeRankType}
        >
          {Object.values(rankTabs).map((tab) => (
            <Tab
              key={tab.value}
              disableRipple
              label={tab.label}
              value={tab.value}
              sx={{
                p: '0.5rem',
                borderRadius: '13px',
                fontWeight: 'bold',
              }}
            />
          ))}
        </Tabs>
        <TabPanel value={rankTabs.sellers.value}>
          <UserRankTopSellersView />
        </TabPanel>
        <TabPanel value={rankTabs.buyers.value}>
          <UserRankTopBuyersView />
        </TabPanel>
        <TabPanel value={rankTabs.transfers.value}>
          <TopTransfers />
        </TabPanel>
        <TabPanel value={rankTabs.importer_and_creator.value}>
          <TopImporterAndCreator />
        </TabPanel>
        <TabPanel value={rankTabs.devices.value}>
          <TopDevicesView />
        </TabPanel>
        <TabPanel value={rankTabs.balances.value}>
          <TopBalancesView />
        </TabPanel>
        <TabPanel value={rankTabs.flagged_transactions.value}>
          <TopFlaggedTransactionsView />
        </TabPanel>
      </TabContext>
    </Card>
  );
}
