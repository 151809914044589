import React, { ReactElement } from 'react';
import { GameMakerAssetCollection } from 'src/lib/types/gameMakerAsset';
import Iconify, { IconifyProps } from 'src/template/components/Iconify';

const MainInfo = React.lazy(
  () => import('src/components/modules/GameMakerAssetCollections/view/MainInfo')
);

export enum AssetCollectionTabs {
  MAIN = 'main_info',
}

interface IAssetCollectionTabs {
  value: AssetCollectionTabs;
  icon: ReactElement<IconifyProps>;
  component: (assetCollection: GameMakerAssetCollection) => JSX.Element;
}

const ASSET_COLLECTION_TABS: IAssetCollectionTabs[] = [
  {
    value: AssetCollectionTabs.MAIN,
    icon: (
      <Iconify icon="material-symbols:info-outline" width={20} height={20} />
    ),
    component: (assetCollection) => (
      <MainInfo assetCollectionDetails={assetCollection} />
    ),
  },
];

export default ASSET_COLLECTION_TABS;
