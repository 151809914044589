import { Add, Check, Edit } from '@mui/icons-material';
import {
  Autocomplete,
  Button,
  Card,
  CardContent,
  CardHeader,
  InputAdornment,
  Stack,
  TextField,
  debounce,
} from '@mui/material';
import { GridSortDirection } from '@mui/x-data-grid';
import { capitalCase } from 'change-case';
import { Navigate, useParams } from 'react-router';
import Flex from 'src/components/shared/Flex';
import NumericTypography from 'src/components/shared/NumericTypography';
import SectionDivider from 'src/components/shared/SectionDivider';
import SimpleTable from 'src/components/shared/SimpleTable/SimpleTable';
import useURLSearchParams from 'src/lib/hooks/useURLSearchParams';
import { NftPackStatus } from 'src/lib/types/nftPack';
import Routes from 'src/routes/Routes';
import HeaderBreadcrumbs from 'src/template/components/HeaderBreadcrumbs';
import Iconify from 'src/template/components/Iconify';
import Label from 'src/template/components/Label';
import LoadingScreen from 'src/template/components/LoadingScreen';
import NFTSInPackTable from '../../../components/NFTSInPackTable';
import useNftPackDetailsView from './useView';

const searchOptions = [
  {
    label: 'Name',
    query: 'name',
  },
  {
    label: 'Rarity',
    query: 'rarity',
  },
];

export default function NftPackDetails() {
  const { uuid } = useParams<'uuid'>();
  if (!uuid) return <Navigate to={Routes.products.nft_packs} replace />;

  const {
    nftPackDetails: details,
    handleOpenUpdateModal,
    handleOpenEditRaritiesModal,
    handleOpenAddNftModal,
    handleOpenDeleteNftFromPackModal,
    handleOpenUpdateNftModal,
    handleOpenChangeStatusModal,
    nftsInPack,
    nftsInPackIsLoading,
    params: tableParams,
  } = useNftPackDetailsView();

  const filteredNftsInPack =
    tableParams.searchBy && tableParams.query
      ? nftsInPack?.filter((nftIntoPack) => {
          if (tableParams.searchBy === 'name') {
            return nftIntoPack.nft.name
              .toLowerCase()
              .includes(tableParams.query!.toLowerCase());
          }
          if (tableParams.searchBy === 'rarity') {
            return (
              nftIntoPack.rarity.name.toLowerCase() ===
              tableParams.query!.toLowerCase()
            );
          }
          return true;
        })
      : nftsInPack;

  const pack = details
    ? {
        uuid: details.uuid,
        name: details.name,
        amountOfNftsToRecharge: details.amountOfNftsToRecharge,
        type: details.type,
        status: details.status,
        productUuid: details.product.uuid,
        createdAt: details.createdAt,
        updatedAt: details.updatedAt,
        deletedAt: details.deletedAt,
      }
    : null;

  const { addParam, removeParam } = useURLSearchParams();

  function handleChangeSearch(value: string, param: string = 'query') {
    if (!value) removeParam(param);
    else addParam(param, value);
  }

  const debouncedSearch = debounce(handleChangeSearch, 500);

  return details ? (
    <>
      <HeaderBreadcrumbs
        heading={details.name || 'Empty Name'}
        links={[
          { name: 'NFT Packs', href: Routes.products.nft_packs },
          { name: details.name || 'Empty Name' },
        ]}
        action={
          <Button
            variant="contained"
            color={pack!.status === NftPackStatus.DRAFT ? 'success' : 'warning'}
            onClick={() => {
              handleOpenChangeStatusModal(
                pack!.uuid,
                pack!.status === NftPackStatus.DRAFT
                  ? NftPackStatus.READY
                  : NftPackStatus.DRAFT
              );
            }}
            startIcon={
              pack!.status === NftPackStatus.DRAFT ? <Check /> : <Edit />
            }
          >
            Mark as {pack!.status === NftPackStatus.DRAFT ? 'Ready' : 'Draft'}
          </Button>
        }
      />
      <Stack direction="row" width="100%" gap={2}>
        <Card sx={{ flex: 1 }}>
          <Flex justifyContent="space-between" alignItems="center" pr={2}>
            <CardHeader title="Details" />
            <CardHeader
              action={
                <Button
                  variant="contained"
                  color="info"
                  size="medium"
                  onClick={() => {
                    handleOpenUpdateModal(pack!);
                  }}
                  startIcon={<Edit />}
                >
                  Edit Details
                </Button>
              }
            />
          </Flex>
          <CardContent>
            <SimpleTable
              border
              rounded
              data={[
                {
                  id: 'name',
                  label: 'Name',
                  value: details.name,
                },
                {
                  id: 'type',
                  label: 'Type',
                  value: details.type,
                },
                {
                  id: 'amountOfNftsToRecharge',
                  label: 'Amount of NFTs to Recharge',
                  value: details.amountOfNftsToRecharge,
                },
                {
                  id: 'status',
                  label: 'Status',
                  value: (
                    <Label
                      color={
                        details.status == NftPackStatus.DRAFT
                          ? 'warning'
                          : 'success'
                      }
                    >
                      {capitalCase(details.status)}
                    </Label>
                  ),
                },
                {
                  id: 'product',
                  label: 'Product',
                  value: details.product.name,
                },
                {
                  id: 'nftQuantity',
                  label: 'NFT Quantity',
                  value: details.nftQuantity,
                },
                {
                  id: 'pricePerNFT',
                  label: 'Price per NFT ($)',
                  value: (
                    <NumericTypography
                      formatDecimal
                      formatDigits
                      currency="USD"
                    >
                      {-details.pricePerNFT}
                    </NumericTypography>
                  ),
                },
              ]}
            />
          </CardContent>
        </Card>
        <Card sx={{ flex: 1 }}>
          <Flex justifyContent="space-between" alignItems="center" pr={2}>
            <CardHeader title="Rarity Odds" />
            <CardHeader
              action={
                <Button
                  variant="contained"
                  color="info"
                  size="medium"
                  onClick={() => {
                    handleOpenEditRaritiesModal(pack!.uuid, details.rarities);
                  }}
                  startIcon={<Edit />}
                >
                  Edit Rarities
                </Button>
              }
            />
          </Flex>
          <CardContent>
            <SimpleTable
              border
              rounded
              data={details.rarities.map((rarity) => ({
                id: rarity.uuid,
                label: rarity.name,
                value: `${rarity.nftPackRarity.proportion}%`,
              }))}
            />
          </CardContent>
        </Card>

        <Card sx={{ flex: 0.9 }}>
          <Flex justifyContent="space-between" alignItems="center" pr={2}>
            <CardHeader title="Items By Rarity" />
          </Flex>
          <CardContent>
            <SimpleTable
              border
              rounded
              data={details.rarities.map((rarity) => ({
                id: rarity.uuid,
                label: rarity.name,
                value: `${
                  nftsInPack?.filter(
                    (nftInPack) => nftInPack.rarity.uuid === rarity.uuid
                  ).length ?? 0
                }`,
              }))}
            />
          </CardContent>
        </Card>
      </Stack>
      <SectionDivider section="Nfts on Pack" />

      <Card>
        <Flex gap={2} p={2}>
          <Autocomplete
            disableClearable
            options={searchOptions}
            fullWidth
            renderInput={(params) => (
              <TextField label="Search By" {...params} />
            )}
            getOptionLabel={(p) => p.label}
            sx={{ maxWidth: 250 }}
            defaultValue={searchOptions.find(
              (o) => o.query === tableParams.searchBy
            )}
            key={tableParams.searchBy}
            onChange={(_, value) => {
              removeParam('query');
              if (!value) removeParam('searchBy');
              else addParam('searchBy', value.query);
            }}
          />

          {tableParams.searchBy == 'rarity' ? (
            <Autocomplete
              options={Object.values(details.rarities.map((r) => r.name))}
              fullWidth
              onChange={(_, value) => {
                handleChangeSearch(value ?? '');
              }}
              renderInput={(params) => <TextField label="Rarity" {...params} />}
            />
          ) : (
            <TextField
              fullWidth
              label="Search"
              defaultValue={tableParams.query}
              type="search"
              onChange={(e) => {
                const { value } = e.target;
                debouncedSearch(value);
              }}
              key={tableParams.query}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <Iconify
                      icon="eva:search-fill"
                      sx={{ color: 'text.disabled', width: 20, height: 20 }}
                    />
                  </InputAdornment>
                ),
              }}
            />
          )}

          <Button
            variant="contained"
            color="info"
            size="large"
            onClick={() => {
              handleOpenAddNftModal({
                uuid: pack!.uuid,
                rarities: details.rarities,
              });
            }}
            startIcon={<Add />}
            sx={{
              minWidth: 150,
            }}
          >
            Add NFT
          </Button>
        </Flex>

        <CardContent>
          <NFTSInPackTable
            data={filteredNftsInPack ?? []}
            isLoading={nftsInPackIsLoading}
            page={tableParams.page}
            size={tableParams.size}
            sortBy={tableParams.sortBy!}
            order={tableParams.order as GridSortDirection}
            handleDeleteNftFromPack={handleOpenDeleteNftFromPackModal}
            handleUpdateNft={handleOpenUpdateNftModal(details.rarities)}
          />
        </CardContent>
      </Card>
    </>
  ) : (
    <LoadingScreen />
  );
}
