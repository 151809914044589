import { DEFAULT_INITIAL_PAGE_SIZE } from 'src/lib/constants/pagination';
import useTable from 'src/template/hooks/useTable';
import { fetchStatisticByGame } from '../../services/fetchStatisticByGame';

export default function useSelectAssetsForContest(gameUuid: string) {
  const {
    page,
    rowsPerPage: size,
    order,
    orderBy,
    query,
    searchBy,
    setRowsPerPage,
    setOrder,
    setPage,
    setQuery,
    setSearchBy,
    setOrderBy,
  } = useTable({
    defaultOrderBy: 'name',
    defaultOrder: 'asc',
    defaultCurrentPage: 0,
    defaultRowsPerPage: DEFAULT_INITIAL_PAGE_SIZE,
    defaultSearchBy: 'name',
  });

  const { data, isLoading } = fetchStatisticByGame(gameUuid);

  return {
    data,
    isLoading,
    page,
    size,
    order,
    orderBy,
    searchBy,
    query,
    setPage,
    setRowsPerPage,
    setOrder,
    setQuery,
    setSearchBy,
    setOrderBy,
  };
}
