import useNotify from 'src/lib/hooks/useNotify';
import useModalStore from 'src/lib/stores/useModalStore';
import { ContestFormValues } from '../../components/Forms/Contest/types';
import { ContestEmitter } from '../../events/ContestEmitter';
import { UpdateContest } from '../../services/updateContest';

export default function useUpdateContestView() {
  const closeModal = useModalStore((state) => state.closeModal);
  const notify = useNotify();

  const handleUpdateContest = async (
    uuid: string,
    values: ContestFormValues
  ) => {
    const { error, data } = await UpdateContest(uuid, {
      ...values,
      awardConfiguration: values.positionAwards.map((award) => award.value),
    });

    if (error) {
      notify(error, { variant: 'error' });
      closeModal();
    } else if (data) {
      notify('Contest Updated!', { variant: 'success' });
      closeModal();

      ContestEmitter.emit('ContestUpdated', data);
    }
  };

  return {
    handleUpdateContest,
  };
}
