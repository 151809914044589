import { useCallback, useEffect } from 'react';
import { useNavigate, useParams } from 'react-router';
import useModalStore from 'src/lib/stores/useModalStore';
import Routes from 'src/routes/Routes';
import useTabs from 'src/template/hooks/useTabs';
import { AssetCollectionEmitter } from '../../events/AssetCollectionEmitter';
import { fetchAssetCollection } from '../../services/fetchAssetCollection';
import UpdateAssetCollectionView from '../Update';
import { AssetCollectionTabs } from './channelTabs';

const DEFAULT_TAB = AssetCollectionTabs.MAIN;

export default function useAssetCollectionDetailsView() {
  const { assetCollectionUuid } = useParams<'assetCollectionUuid'>();
  const navigate = useNavigate();
  if (!assetCollectionUuid) navigate(Routes.channel.channels);

  const openModal = useModalStore((state) => state.openModal);

  const handleOpenUpdateModal = () => {
    openModal(
      <UpdateAssetCollectionView assetCollection={assetCollectionDetails!} />
    );
  };

  const { currentTab, onChangeTab } = useTabs(DEFAULT_TAB, {
    resetOnChange: true,
  });

  const { data: assetCollectionDetails, mutate } = fetchAssetCollection(
    assetCollectionUuid!
  );
  const refetchData = useCallback(() => {
    mutate?.();
  }, []);

  useEffect(() => {
    AssetCollectionEmitter.on('AssetCollectionUpdated', refetchData);
    return () => {
      AssetCollectionEmitter.off('AssetCollectionUpdated', refetchData);
    };
  }, [refetchData]);

  return {
    assetCollectionDetails,
    currentTab,
    onChangeTab,
    handleOpenUpdateModal,
  };
}
