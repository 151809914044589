import { IncludeExcludeAll } from 'src/lib/utils/includeExcludeFilterUtils';
import {
  DateRangeQuery,
  OrderQuery,
  PaginationQuery,
  SearchQuery,
} from 'src/lib/utils/query/types';

export type RefundReason = 'null' | 'CUSTOMER_ERROR' | 'Upgraded' | 'APP_ERROR';

export type RefundsList = {
  uuid: string;
  poolUuid: string | null;
  userUuid: string;
  userName: string;
  userSlug: string;
  userEmail: string;
  blocked: boolean;
  flagged: boolean;
  productType: string;
  originalTransactionId: string;
  eventType: RefundTypes;
  notificationType: string;
  reason: RefundReason | null;
  provider: string;
  eventDate: number;
  currency: string | null;
  totalUSD: string;
  purchaseDate: number;
  originalPurchaseDate: number;
  createdAt: number;
  product: string;
  totalNotifications: number;
  events: string;
  userHasFeedback: boolean;
  refundHasNote: boolean;
  causedByUpgrade: boolean;
};

export type FetchRefundsQueryParams = PaginationQuery &
  Partial<OrderQuery> &
  Partial<SearchQuery> &
  DateRangeQuery &
  Partial<{
    reasons: RefundReason[];
    upgradeFilter: IncludeExcludeAll;
    types: RefundTypes[];
  }>;

export enum RefundTypes {
  refundRequest = 'REQUEST_CONSUMPTION_INFO',
  refund = 'Refund',
  refundDenied = 'RefundDenied',
  null = 'null',
}
