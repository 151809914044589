import {
  Card,
  CardActions,
  CardContent,
  CardHeader,
  InputAdornment,
  Stack,
  TextField,
} from '@mui/material';
import { useSnackbar } from 'notistack';
import { useForm } from 'react-hook-form';
import Button from 'src/components/shared/Buttons/CustomButton';
import Modal from 'src/components/shared/Modal';
import { createOrUpdateCollection } from 'src/lib/services/collections';
import { Collection } from 'src/lib/types/collection';
import { SocialMedia } from 'src/lib/types/socialMedia';
import { getMessageFromError } from 'src/lib/utils/error';

type UpdateSocialMediaFormValues = {
  [key in keyof SocialMedia]: string;
};
type UpdateSocialMediaModalProps = {
  collection: Collection;
  modalIsOpen: boolean;
  closeModal: () => void;
  socialMediaIcons: {
    value: keyof SocialMedia;
    icon: JSX.Element;
  }[];
};
export default function UpdateSocialMediaModal({
  modalIsOpen,
  closeModal,
  collection,
  socialMediaIcons,
}: UpdateSocialMediaModalProps): JSX.Element {
  const { enqueueSnackbar: notify } = useSnackbar();
  const {
    register,
    handleSubmit,
    formState: { isSubmitting },
  } = useForm<UpdateSocialMediaFormValues>();
  const onSave = async (values: UpdateSocialMediaFormValues) => {
    try {
      await createOrUpdateCollection({
        ...collection,
        socialMedia: values,
      });

      notify('Social media updated successfully', { variant: 'success' });
      closeModal();
    } catch (e) {
      notify(getMessageFromError(e), { variant: 'error' });
    }
  };
  return (
    <Modal onClose={closeModal} open={modalIsOpen}>
      <Card
        sx={{
          width: '500px',
        }}
      >
        <CardHeader title="Edit Social Media" />
        <form onSubmit={handleSubmit(onSave)}>
          <CardContent>
            <Stack direction="column" spacing={1}>
              {socialMediaIcons.map(({ value, icon }) => (
                <TextField
                  key={value}
                  label={value}
                  placeholder="-"
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">{icon}</InputAdornment>
                    ),
                  }}
                  {...register(value)}
                />
              ))}
            </Stack>
          </CardContent>
          <CardActions
            sx={{
              padding: 2,
            }}
          >
            <Stack
              direction="row"
              spacing={2}
              justifyContent="space-between"
              width="100%"
            >
              <Button color="error" onClick={closeModal}>
                Cancel
              </Button>
              <Button
                loading={isSubmitting}
                type="submit"
                variant="contained"
                color="secondary"
              >
                Save
              </Button>
            </Stack>
          </CardActions>
        </form>
      </Card>
    </Modal>
  );
}
