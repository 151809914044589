import { useCallback, useEffect } from 'react';
import { DEFAULT_INITIAL_PAGE_SIZE } from 'src/lib/constants/pagination';
import useURLSearchParams from 'src/lib/hooks/useURLSearchParams';
import { ConfigurationEmitter } from '../../events/ConfigurationEmitter';
import { fetchConfigurations } from '../../services/fetchConfigurations';

export default function useConfigurationsView() {
  const { allSearchParams } = useURLSearchParams({
    page: '0',
    size: DEFAULT_INITIAL_PAGE_SIZE.toString(),
    sortBy: 'createdAt',
    sortOrder: 'desc',
    searchFor: 'name',
  });
  const { data, error, loading, mutate } = fetchConfigurations({
    page: allSearchParams.page,
    size: allSearchParams.size,
    order: {
      [allSearchParams.sortBy!]: allSearchParams.sortOrder,
    },
    filter: {
      name: {
        like: allSearchParams.name,
      },
      platform: {
        like: allSearchParams.platform,
      },
      description: {
        like: allSearchParams.description,
      },
      active: allSearchParams.active,
    },
  });

  const refetchData = useCallback(() => {
    mutate?.();
  }, []);

  useEffect(() => {
    ConfigurationEmitter.on('configurationCreated', refetchData);
    ConfigurationEmitter.on('configurationUpdated', refetchData);
    ConfigurationEmitter.on('configurationDeleted', refetchData);

    return () => {
      ConfigurationEmitter.off('configurationCreated', refetchData);
      ConfigurationEmitter.off('configurationUpdated', refetchData);
      ConfigurationEmitter.off('configurationDeleted', refetchData);
    };
  }, [refetchData]);

  return {
    data,
    error,
    loading,
    sortBy: allSearchParams.sortBy!,
    sortOrder: allSearchParams.sortOrder!,
    page: +allSearchParams.page!,
    size: +allSearchParams.size!,
    searchFor: allSearchParams.searchFor!,
  };
}
