import getAPIClient from 'src/lib/services/api/axios.config';
import { ServiceResponse } from 'src/lib/types/ServiceResponse';
import { EntityNote, EntityNoteTypes } from 'src/lib/types/entityNote';
import { getMessageFromError } from 'src/lib/utils/error';

export type CreateEntityNoteData = Partial<{
  entityType: EntityNoteTypes;
  entityUuid: string;
  note: string;
  tagName: string;
  uuid: string;
}>;

export async function CreateEntityNote(
  data: CreateEntityNoteData
): Promise<ServiceResponse<EntityNote>> {
  try {
    const api = await getAPIClient();
    const res = await api.post(`admin/entity-notes/`, data);
    return {
      data: res.data,
      error: null,
    };
  } catch (e) {
    return {
      data: null,
      error: getMessageFromError(e),
    };
  }
}
