export enum NotificationType {
  RENEW = 'DID_RENEW',
  SUBSCRIPTION = 'SUBSCRIBED',
  DID_CHANGE_RENEWAL_STATUS = 'DID_CHANGE_RENEWAL_STATUS',
  EXPIRED = 'EXPIRED',
  DID_FAIL_TO_RENEW = 'DID_FAIL_TO_RENEW',
  DID_CHANGE_RENEWAL_PREF = 'DID_CHANGE_RENEWAL_PREF',
  CONSUMPTION_REQUEST = 'CONSUMPTION_REQUEST',
  REFUND = 'REFUND',
  GRACE_PERIOD_EXPIRED = 'GRACE_PERIOD_EXPIRED',
  OFFER_REDEEMED = 'OFFER_REDEEMED',
  PRICE_INCREASE = 'PRICE_INCREASE',
  REFUND_DECLINED = 'REFUND_DECLINED',
  REFUND_REVERSED = 'REFUND_REVERSED',
  RENEWAL_EXTENDED = 'RENEWAL_EXTENDED',
  RENEWAL_EXTENSION = 'RENEWAL_EXTENSION',
  REVOKE = 'REVOKE',
  TEST = 'TEST',
}

export enum NotificationSubType {
  BILLING_RECOVERY = 'BILLING_RECOVERY',
  INITIAL_BUY = 'INITIAL_BUY',
  RESUBSCRIBE = 'RESUBSCRIBE',
  AUTO_RENEW_ENABLED = 'AUTO_RENEW_ENABLED',
  AUTO_RENEW_DISABLED = 'AUTO_RENEW_DISABLED',
  VOLUNTARY = 'VOLUNTARY',
  BILLING_RETRY = 'BILLING_RETRY',
  PRICE_INCREASE = 'PRICE_INCREASE',
  PRODUCT_NOT_FOR_SALE = 'PRODUCT_NOT_FOR_SALE',
  GRACE_PERIOD = 'GRACE_PERIOD',
  UPGRADE = 'UPGRADE',
  DOWNGRADE = 'DOWNGRADE',
  PENDING = 'PENDING',
  ACCEPTED = 'ACCEPTED',
  SUMMARY = 'SUMMARY',
  FAILURE = 'FAILURE',
}
