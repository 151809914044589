import { DeepPartial } from 'react-hook-form';
import { PaginatedResponse } from 'src/lib/types/PaginatedResponse';
import { SGMChatTopUsersStatistics } from 'src/lib/types/sgmChatMessage';
import { parseSearchParams } from 'src/lib/utils/parseSearchParams';
import useSWR, { SWRResponse } from 'swr';

export type PossibleParams = {
  limit: number | string;
  offset: number | string;
  order: Record<string, string>;
  filter: {
    userUuid: string;
    totalChats: {
      gte: number;
      lte: number;
    };
    totalMessages: {
      gte: number;
      lte: number;
    };
    messagesCreatedBetween: {
      gte: string;
      lte: string;
    };
  };
};

export const fetchMostActiveUsers = (
  filter?: DeepPartial<PossibleParams>
): SWRResponse<PaginatedResponse<SGMChatTopUsersStatistics[]>> => {
  const parsedParams = parseSearchParams(filter ?? {});
  const response = useSWR<
    PaginatedResponse<SGMChatTopUsersStatistics[]>,
    Error
  >(`admin/game-maker-chats/top-users/?${parsedParams}`);

  return response;
};
