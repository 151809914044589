import {
  Avatar,
  Card,
  CardActionArea,
  Link,
  Stack,
  Typography,
} from '@mui/material';
import { Link as RouterLink } from 'react-router-dom';
import { User } from 'src/lib/types/users';
import Iconify from 'src/template/components/Iconify';

export default function CollectionCreatorCard({ ...user }: User) {
  return (
    <Card>
      <Link component={RouterLink} to={`/users/${user.uuid}`} underline="none">
        <CardActionArea
          sx={{
            padding: 2,
            alignItems: 'center',
            justifyContent: 'center',
          }}
        >
          <Stack
            direction="row"
            alignItems="center"
            spacing={3}
            justifyContent="space-between"
            pr={1}
          >
            <Stack direction="row" alignItems="center" spacing={1}>
              <Avatar src={user?.profileImage ?? undefined} />
              <Stack>
                <Typography variant="body1" fontWeight="bold">
                  {user.name}
                </Typography>
                <Typography
                  variant="body1"
                  fontWeight="bold"
                  fontSize={12}
                  color="text.secondary"
                >
                  @{user.slug}
                </Typography>
              </Stack>
            </Stack>
            <Iconify
              width={18}
              height={18}
              icon="akar-icons:link-out"
              lineHeight={0}
            />
          </Stack>
        </CardActionArea>
      </Link>
    </Card>
  );
}
