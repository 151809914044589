import {
  DeepPartial,
  DefaultValues,
  FieldValues,
  useForm,
  UseFormReturn,
} from 'react-hook-form';
import useURLSearchParams from 'src/lib/hooks/useURLSearchParams';
import objectEntries from 'src/lib/utils/objectEntries';

type Config = {
  overrideSubmit: boolean;
};
const initialConfig: Config = {
  overrideSubmit: false,
};

type Props<T extends FieldValues> = {
  children: (methods: UseFormReturn<T, any>) => JSX.Element;
  onSubmit?: (values: T, formMethods: UseFormReturn<T, any>) => void;
  defaultValues?: DefaultValues<T>;
  config?: DeepPartial<Config>;
};
export default function FilterForm<T extends FieldValues>({
  children,
  onSubmit: submitHandler,
  defaultValues,
  config = initialConfig,
}: Props<T>): JSX.Element {
  const { addParam, removeParam } = useURLSearchParams();
  const methods = useForm<T>({ defaultValues });
  const { handleSubmit } = methods;

  function onSubmit(values: T) {
    if (config.overrideSubmit && submitHandler) {
      submitHandler(values, methods);
      return;
    }
    objectEntries(values).forEach(([key, value]) => {
      if (value === null || value === undefined || value === '') {
        removeParam(key);
      } else {
        addParam(key, String(value));
      }
    });
    submitHandler?.(values, methods);
  }

  return <form onSubmit={handleSubmit(onSubmit)}>{children(methods)}</form>;
}
