/* eslint-disable no-promise-executor-return */
import { ContentCopy, Delete, Edit, PlayCircle } from '@mui/icons-material';
import {
  Autocomplete,
  Box,
  InputAdornment,
  MenuItem,
  Stack,
  Switch,
  TextField,
  Typography,
} from '@mui/material';
import { GridRowModel, GridSortDirection } from '@mui/x-data-grid';
import { debounce } from 'lodash';

import { useCallback, useState } from 'react';
import Flex from 'src/components/shared/Flex';
import Link from 'src/components/shared/Link';
import Loader from 'src/components/shared/Loader';
import MagicTable from 'src/components/shared/MagicTable/MagicTable';
import useNotify from 'src/lib/hooks/useNotify';
import useURLSearchParams from 'src/lib/hooks/useURLSearchParams';

import { LabelValue } from 'src/lib/types/labelValue';
import Routes from 'src/routes/Routes';
import Iconify from 'src/template/components/Iconify';
import Image from 'src/template/components/Image';

import LinkIcon from '@mui/icons-material/Link';
import CopyToClipboard from 'react-copy-to-clipboard';
import { UpdateFeedItem } from 'src/components/modules/ChannelContent/services/updateFeedItem';
import MagicTableChannelCell from 'src/components/shared/MagicTable/ChannelCell';
import MoreMenu from 'src/components/shared/MoreMenu';
import {
  FeedItem,
  FeedItemContentTypes,
  FeedItemTypes,
  FeedItemWithAnalyticsFeed,
} from 'src/lib/types/FeedItem';
import { formatUTCDate } from 'src/lib/utils/formatUTCDate';
import { AppVideoFeedEmitter } from '../../events/AppVideoFeedEmitter';
import { UpdateFeedOrder } from '../../services/UpdateFeedOrder';
import { FetchGamesQueryParams } from '../../view/List/useView';

type FeedTableProps = {
  data?: FeedItem[];
  isLoading: boolean;
  params: FetchGamesQueryParams;
  handleOpenDeleteModal?: (uuid: string) => void;
  onEditClick?: (uuid: string) => void;
  onChangeFeedStatus?: (
    uuid: string,
    currentStatus: 'active' | 'inactive'
  ) => Promise<void>;
};

export default function FeedTable({
  data,
  isLoading,
  params: { page, size, searchBy, orderBy, sortOrder },
  handleOpenDeleteModal,
  onEditClick,
  onChangeFeedStatus,
}: FeedTableProps) {
  const notify = useNotify();

  const { addParam, removeParam, allSearchParams } = useURLSearchParams();

  function handleChangeSearch(value: string, param: string = 'query') {
    if (!value) removeParam(param);
    else addParam(param, value);
  }

  const debouncedSearch = debounce(handleChangeSearch, 1000);
  const [updatingRowId, setUpdatingRowId] = useState<string | null>(null);

  const processRowUpdate = useCallback(
    async (newRow: GridRowModel) => {
      setUpdatingRowId(newRow.uuid); // Set the updating row ID

      const { data: responseData, error } = await UpdateFeedOrder(
        newRow.appVideoFeed.uuid,
        newRow.viewOrder as number
      );

      if (error) {
        setUpdatingRowId(null); // Reset the updating row ID on error
        throw new Error(error);
      }

      if (!responseData) {
        setUpdatingRowId(null); // Reset the updating row ID if update fails
        throw new Error('Error, Game Feed Item could not be updated!');
      }

      AppVideoFeedEmitter.emit('AppVideoFeedUpdated', newRow.uuid);
      notify('Order Updated', { variant: 'success' });

      setUpdatingRowId(null); // Reset the updating row ID on success

      return { ...newRow, ...responseData };
    },
    [UpdateFeedItem, notify, AppVideoFeedEmitter]
  );

  const handleProcessRowUpdateError = useCallback((error: Error) => {
    setUpdatingRowId(null); // Reset the updating row ID on error
    notify('Error to change order', { variant: 'error' });
  }, []);

  const [rowUuidChangingStatus, setRowUuidChangingStatus] = useState<string>();

  function handleGameStatusChange(
    uuid: string,
    currentStatus: 'active' | 'inactive'
  ) {
    setRowUuidChangingStatus(uuid);
    onChangeFeedStatus?.(uuid, currentStatus).finally(() => {
      setRowUuidChangingStatus(undefined);
    });
  }

  const isChangingStatus = !!rowUuidChangingStatus;

  return (
    <MagicTable.Container>
      <Flex gap={2} p={2} justifyContent={'flex-end'}>
        <Autocomplete
          options={SEARCH_OPTIONS}
          fullWidth
          renderInput={(params) => <TextField label="Search By" {...params} />}
          getOptionLabel={(p) => p.label}
          sx={{ maxWidth: 250 }}
          defaultValue={SEARCH_OPTIONS.find((o) => o.value === searchBy)}
          key={searchBy}
          onChange={(_, value) => {
            if (!value) removeParam('searchBy');
            else addParam('searchBy', value.value);
          }}
        />
        <TextField
          fullWidth
          label="Search"
          defaultValue={allSearchParams.query}
          type="search"
          onChange={(e) => {
            const { value } = e.target;
            debouncedSearch(value);
          }}
          key={allSearchParams.query}
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <Iconify
                  icon="eva:search-fill"
                  sx={{ color: 'text.disabled', width: 20, height: 20 }}
                />
              </InputAdornment>
            ),
          }}
        />
      </Flex>
      <Box px={2}>
        <MagicTable
          loading={isLoading}
          pageSize={size}
          page={page}
          paginationMode="server"
          onPageChange={(newPage) => {
            addParam('page', newPage);
          }}
          onPageSizeChange={(newSize) => {
            addParam('size', newSize);
          }}
          onSortModelChange={(model) => {
            const currentModel = model[0];
            if (currentModel) {
              addParam('orderBy', currentModel.field);
              if (currentModel.sort) addParam('order', currentModel.sort);
            } else {
              removeParam('order');
              removeParam('orderBy');
            }
          }}
          sortModel={[
            {
              field: orderBy,
              sort: sortOrder as GridSortDirection,
            },
          ]}
          rows={data ?? []}
          hideNumberOfRows
          processRowUpdate={processRowUpdate}
          onProcessRowUpdateError={handleProcessRowUpdateError}
          experimentalFeatures={{ newEditingApi: true }}
          columns={[
            {
              field: 'channel',
              headerName: 'Channel',
              sortable: false,
              renderCell({ row }) {
                return row.channel ? (
                  <MagicTableChannelCell channel={row.channel} />
                ) : (
                  '--'
                );
              },
            },
            {
              field: 'status',
              headerName: 'Status',
              align: 'center',
              flex: 0,
              renderCell({ row }) {
                const rowUuid = row.appVideoFeed?.uuid;
                const status = row.appVideoFeed?.status;
                const active = status === 'active';
                return (
                  <Stack
                    alignItems="center"
                    justifyContent="center"
                    spacing={0}
                  >
                    <Switch
                      checked={active}
                      color="success"
                      onChange={() =>
                        handleGameStatusChange(
                          rowUuid,
                          status as 'active' | 'inactive'
                        )
                      }
                      size="small"
                      disabled={
                        (isChangingStatus &&
                          rowUuidChangingStatus === rowUuid) ||
                        isLoading
                      }
                    />
                    <Box textAlign="center" fontSize={12}>
                      (
                      {rowUuidChangingStatus === rowUuid
                        ? 'Changing...'
                        : status}
                      )
                    </Box>
                  </Stack>
                );
              },
            },
            {
              field: 'viewOrder',
              headerName: 'Order',
              editable: true,
              flex: 0,
              type: 'number',
              disableReorder: true,
              valueGetter: (params) => params.row.appVideoFeed?.viewOrder,
              renderCell({ row }: { row: FeedItemWithAnalyticsFeed }) {
                const isUpdating = updatingRowId === row.uuid;
                return (
                  <Flex alignItems="center" justifyContent="center" gap={2}>
                    <Typography variant="body2" color="text.secondary">
                      {row.appVideoFeed?.viewOrder}
                    </Typography>
                    {isUpdating && <Loader size={10} />}
                  </Flex>
                );
              },
            },
            {
              field: 'item',
              headerName: 'Item',
              flex: 1 / 2,
              sortable: false,
              renderCell({ row }: { row: FeedItemWithAnalyticsFeed }) {
                if (row.itemType === FeedItemTypes.game && row.game)
                  return (
                    <Link to={Routes.game_center.game.view(row.game.uuid)}>
                      <Flex gap={2} alignItems="center">
                        {row.game.versions?.length && (
                          <Image
                            src={row.game.versions[0].imageUrl}
                            alt={row.game.name}
                            sx={{ width: 30, height: 30, borderRadius: 1 }}
                          />
                        )}
                        {row.game.name}
                      </Flex>
                    </Link>
                  );

                if (row.itemType === FeedItemTypes.collection && row.collection)
                  return (
                    <Link to={Routes.collection(row.collection.uuid)}>
                      <Flex gap={2} alignItems="center">
                        {row.collection.coverFileUrl && (
                          <Image
                            src={row.collection.coverFileUrl}
                            alt={row.collection.name}
                            sx={{ width: 30, height: 30, borderRadius: 1 }}
                          />
                        )}
                        {row.collection.name}
                      </Flex>
                    </Link>
                  );

                if (row.itemType === FeedItemTypes.nft && row.nft)
                  return (
                    <Link to={Routes.nft(row.nft.uuid)}>
                      <Flex gap={2} alignItems="center">
                        {row.nft.fileUrl && (
                          <Image
                            src={row.nft.fileUrl}
                            alt={row.nft.name}
                            sx={{ width: 30, height: 30, borderRadius: 1 }}
                          />
                        )}
                        {row.nft.name}
                      </Flex>
                    </Link>
                  );

                if (row.itemType === FeedItemTypes.club && row.club)
                  return (
                    <Link
                      to={Routes.comunity.club(row.club.uuid)}
                      target="_blank"
                    >
                      <Flex gap={2} alignItems="center">
                        <LinkIcon />
                        {row.club.name}
                      </Flex>
                    </Link>
                  );

                return (
                  <Typography variant="body2" color="text.secondary">
                    No item found
                  </Typography>
                );
              },
            },
            {
              field: 'content',
              headerName: 'Content',
              sortable: false,
              flex: 1 / 2,
              renderCell({ row }: { row: FeedItemWithAnalyticsFeed }) {
                if (
                  row.contentType === FeedItemContentTypes.video &&
                  row.contentUrl
                )
                  return (
                    <Link to={row.contentUrl} target="_blank">
                      <Flex gap={1} alignItems="center">
                        <PlayCircle />
                        Play Video
                      </Flex>
                    </Link>
                  );

                if (
                  row.contentType === FeedItemContentTypes.image &&
                  row.contentUrl
                )
                  return (
                    <Link to={row.contentUrl} target="_blank">
                      <Flex gap={1} alignItems="center">
                        <LinkIcon />
                        Show Image
                      </Flex>
                    </Link>
                  );

                return (
                  <Typography variant="body2" color="text.secondary">
                    No content found
                  </Typography>
                );
              },
            },
            /* Internal name */
            {
              field: 'itemFeed.title',
              headerName: 'Title',
              sortable: false,
              flex: 1 / 2,
              renderCell({ row }: { row: FeedItemWithAnalyticsFeed }) {
                return (
                  <Typography variant="body2" color="text.secondary">
                    {row.internalName || '--'}
                  </Typography>
                );
              },
            },
            {
              field: 'views',
              headerName: 'Views',
              sortable: true,
              flex: 0,
            },
            {
              headerName: 'Clicks',
              field: 'clicks',
              sortable: true,
              flex: 0,
            },
            {
              field: 'uniqueUsers',
              headerName: 'Unique Users',
              sortable: true,
              flex: 0,
            },
            {
              field: 'clicksPerUser',
              headerName: 'Clicks / Users',
              sortable: true,
              renderCell({ row }) {
                const value = row.clicks / row.uniqueUsers;
                return value ? `${(value * 100).toFixed(2)}%` : '--';
              },
              flex: 1 / 2,
            },
            {
              field: 'updatedAt',
              headerName: 'Updated At',
              sortable: true,
              flex: 1 / 2,
              renderCell({ row }: { row: FeedItemWithAnalyticsFeed }) {
                return (
                  <Typography variant="body2" color="text.secondary">
                    {row.updatedAt
                      ? formatUTCDate(
                          row.appVideoFeed?.updatedAt ?? row.updatedAt
                        )
                      : '--'}
                  </Typography>
                );
              },
            },
            {
              field: 'actions',
              headerName: 'Actions',
              align: 'center',
              sortable: false,
              flex: 0,
              renderCell({ row }) {
                return (
                  <MoreMenu>
                    <CopyToClipboard text={row.appVideoFeed?.uuid}>
                      <MenuItem aria-label="copy app video feed uuid">
                        <ContentCopy />
                        Copy Feed Uuid
                      </MenuItem>
                    </CopyToClipboard>
                    <CopyToClipboard text={row.uuid}>
                      <MenuItem aria-label="copy item feed uuid">
                        <ContentCopy />
                        Copy Content UUID
                      </MenuItem>
                    </CopyToClipboard>
                    {onEditClick && (
                      <MenuItem onClick={() => onEditClick(row.uuid)}>
                        <Edit color="primary" sx={{ cursor: 'pointer' }} />
                        Update
                      </MenuItem>
                    )}
                    {handleOpenDeleteModal && (
                      <MenuItem
                        onClick={() =>
                          handleOpenDeleteModal(row.appVideoFeed?.uuid)
                        }
                      >
                        <Delete color="error" sx={{ cursor: 'pointer' }} />
                        Delete
                      </MenuItem>
                    )}
                  </MoreMenu>
                );
              },
            },
          ]}
        />
      </Box>
    </MagicTable.Container>
  );
}

const SEARCH_OPTIONS: LabelValue[] = [
  {
    label: 'Item Name',
    value: 'itemName',
  },
];
