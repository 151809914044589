import { create } from 'zustand';

import { BracketFilterParams } from '../types/bracketFilterParams';

interface IFilterStore {
  filter: BracketFilterParams;
  setFilter: (filter: Partial<BracketFilterParams>) => void;
}

const createFilterStore = (initialFilterState: BracketFilterParams) =>
  create<IFilterStore>((set) => ({
    filter: initialFilterState,
    setFilter: (filter) =>
      set((state) => ({
        filter: {
          ...state.filter,
          ...filter,
        },
      })),
  }));

export default createFilterStore;
