import { Autocomplete, InputAdornment, TextField } from '@mui/material';
import Iconify from 'src/template/components/Iconify';
import { FilterProps } from './types';

export function GameRatingsTableGameFilters({
  addParam,
  removeParam,
  debouncedChangeField,
  propParams,
}: FilterProps) {
  const gameSearchOptions = [
    {
      label: 'Game Name',
      query: 'game.name',
    },
    {
      label: 'Game UUID',
      query: 'game.uuid',
    },
  ];
  return (
    <>
      <Autocomplete
        options={gameSearchOptions}
        fullWidth
        renderInput={(params) => (
          <TextField label="Search Game By" {...params} fullWidth />
        )}
        getOptionLabel={(p) => p.label}
        sx={{ maxWidth: 250 }}
        defaultValue={gameSearchOptions.find(
          (o) => o.query === propParams.gameSearchBy
        )}
        key={'gameSearchBy'}
        onChange={(_, value) => {
          if (!value) removeParam('gameSearchBy');
          else addParam('gameSearchBy', value.query);
        }}
      />
      <TextField
        fullWidth
        label="Search"
        defaultValue={propParams.gameQuery}
        type="search"
        onChange={(e) => {
          const { value } = e.target;
          debouncedChangeField(value, 'gameQuery');
        }}
        key={propParams.gameQuery}
        InputProps={{
          startAdornment: (
            <InputAdornment position="start">
              <Iconify
                icon="eva:search-fill"
                sx={{ color: 'text.disabled', width: 20, height: 20 }}
              />
            </InputAdornment>
          ),
        }}
      />
    </>
  );
}
