import { ResponseWithStatus } from 'src/lib/types/ResponseWithStatus';
import { ChatMessage } from 'src/lib/types/chat';
import useSWR, { SWRResponse } from 'swr';

export const fetchChatMessage = (
  roomId: string,
  messageId: string
): SWRResponse<ResponseWithStatus<ChatMessage>> => {
  const response = useSWR<ResponseWithStatus<ChatMessage>, Error>(
    `admin/chats/${roomId}/messages/${messageId}`
  );

  return response;
};
