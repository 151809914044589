import HeaderBreadcrumbs from 'src/template/components/HeaderBreadcrumbs';
import SearchesAnalyticsTable from '../../components/SearchesAnalytics/SearchesAnalyticsTable';
import { useSearchesAnalyticsTable } from '../../components/SearchesAnalytics/useSearchesAnalyticsTable';

type UserSearchAnalyticsProps = {
  searchTerm?: string;
};

export default function UserSearchAnalytics({
  searchTerm,
}: UserSearchAnalyticsProps) {
  const { data, isLoading, params } = useSearchesAnalyticsTable();

  return (
    <>
      <HeaderBreadcrumbs
        heading="Searches Analytics"
        links={[{ name: 'Searches Analytics' }]}
      />

      <SearchesAnalyticsTable
        data={data?.body || []}
        isLoading={isLoading}
        params={params}
        totalCount={data?.meta.totalCount || 0}
        searchTerm={searchTerm}
      />
    </>
  );
}
