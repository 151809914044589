import { NftPack } from 'src/lib/types/nftPack';
import { createEmitter } from 'src/lib/utils/createEmitter';

export const NftPackEmitter = createEmitter<{
  NftPackCreated: NftPack;
  NftPackUpdated: NftPack;
  NftPackDeleted: NftPack;
  NftPackRaritiesUpdated: null;
  NftPackNftsUpdated: null;
  NftPackNftsRemoved: null;
}>();
