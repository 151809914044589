import getAPIClient from 'src/lib/services/api/axios.config';
import { ServiceResponse } from 'src/lib/types/ServiceResponse';
import { Club } from 'src/lib/types/club';
import { SubmissionFormStatuses } from 'src/lib/types/formReviewStatus';
import { getMessageFromError } from 'src/lib/utils/error';

export type ReviewSubmissionData = {
  status: SubmissionFormStatuses;
  reviewerComment: string;
};

export async function reviewSubmission(
  uuid: string,
  data: ReviewSubmissionData
): Promise<ServiceResponse<Club>> {
  try {
    const api = await getAPIClient();
    const res = await api.post(`admin/submission-form/${uuid}/review`, data);
    return {
      data: res.data,
      error: null,
    };
  } catch (e) {
    return {
      data: null,
      error: getMessageFromError(e),
    };
  }
}
