import { Container } from '@mui/material';
import UserPurgesList from 'src/components/modules/UserPurges/components/UserPurgesList';
import Page from 'src/template/components/Page';

export default function UserPurgesPage() {
  return (
    <Page title="User Purges">
      <Container maxWidth="xl">
        <UserPurgesList />
      </Container>
    </Page>
  );
}
