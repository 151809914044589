import {
  Card,
  CardContent,
  CardHeader,
  CircularProgress,
  Stack,
} from '@mui/material';
import CloseableAlert from 'src/components/shared/CloseableAlert';
import SimpleTable from 'src/components/shared/SimpleTable/SimpleTable';
import {
  RefundStatsResponse,
  RefundStatsTotal,
} from 'src/lib/types/revenueEvent';

type RefundsStatsProps = { data?: RefundStatsResponse; loading: boolean };

export default function RefundsStats({ data, loading }: RefundsStatsProps) {
  if (loading)
    return (
      <Stack width="100%" py={5} alignItems="center">
        <CircularProgress />
      </Stack>
    );

  if (!data && !loading)
    return (
      <CloseableAlert severity="error">Something Went Wrong.</CloseableAlert>
    );

  return (
    <Stack direction="row" width="100%" gap={3}>
      <Stack width="100%" gap={3}>
        <Card>
          <CardHeader title="Total Events" />
          <CardContent>
            {data && (
              <SimpleTable
                border
                rounded
                data={Object.keys(data.total).map((totalItem) => ({
                  id: totalItem,
                  label: totalItem,
                  value: data.total[totalItem as keyof RefundStatsTotal],
                }))}
              />
            )}
          </CardContent>
        </Card>
        <Card>
          <CardHeader title="Total Users" />
          <CardContent>
            <SimpleTable
              border
              rounded
              data={[
                {
                  id: 'refundedUsers',
                  label: 'with at least one refund',
                  value: data?.refundedUsers,
                },
                {
                  id: 'usersWithMoreThanOneRefund',
                  label: 'with more than one refund',
                  value: data?.usersWithMoreThanOneRefund,
                },
              ]}
            />
          </CardContent>
        </Card>
      </Stack>
      <Stack width="100%" gap={3}>
        <Card>
          <CardHeader title="Top Refund Reasons" />
          <CardContent>
            {data && (
              <SimpleTable
                border
                rounded
                data={data.topReasons.map((item) => ({
                  id: item.reason,
                  label: item.reason ?? 'Empty Reason',
                  value: item.events,
                }))}
              />
            )}
          </CardContent>
        </Card>
        <Card>
          <CardHeader title="Top Refund Products" />
          <CardContent>
            {data && (
              <SimpleTable
                border
                rounded
                data={data.topProducts.map((item) => ({
                  id: item.productId,
                  label: item.productId,
                  value: item.events,
                }))}
              />
            )}
          </CardContent>
        </Card>
      </Stack>
    </Stack>
  );
}
