import { Card, CardContent, CardHeader, Stack, TextField } from '@mui/material';
import { Controller } from 'react-hook-form';
import Button from 'src/components/shared/Buttons/CustomButton';
import Modal from 'src/components/shared/Modal';
import { SGMPrompt } from 'src/lib/types/sgmPrompt';
import { useCreateSGMPrompt } from './useView';

export default function CreateSGMPromptForm({
  sgmPrompt,
}: {
  sgmPrompt?: SGMPrompt;
}): JSX.Element {
  const {
    handleCloseModal,
    isSubmitting,
    onSubmitForm,
    form: { control, handleSubmit, setValue },
  } = useCreateSGMPrompt(sgmPrompt);

  return (
    <Modal open onClose={handleCloseModal}>
      <Card
        sx={{
          minWidth: '600px',
        }}
      >
        <CardHeader title="Create a Prompt Type" />
        <CardContent>
          <form onSubmit={handleSubmit(onSubmitForm)}>
            <Stack spacing={2}>
              <Controller
                name="type"
                control={control}
                render={({ field }) => (
                  <TextField
                    label="Type"
                    fullWidth
                    {...field}
                    variant="outlined"
                  />
                )}
              />

              <Controller
                name="title"
                control={control}
                render={({ field }) => (
                  <TextField
                    label="Title"
                    fullWidth
                    {...field}
                    variant="outlined"
                  />
                )}
              />

              <Controller
                name="description"
                control={control}
                render={({ field }) => (
                  <TextField
                    label="Description"
                    fullWidth
                    {...field}
                    variant="outlined"
                  />
                )}
              />

              <Controller
                name="prompt"
                control={control}
                render={({ field }) => (
                  <TextField
                    label="Prompt"
                    fullWidth
                    {...field}
                    variant="outlined"
                    multiline
                    minRows={4}
                  />
                )}
              />

              <Button
                variant="contained"
                color="secondary"
                type="submit"
                loading={isSubmitting}
                size="large"
              >
                Save
              </Button>
            </Stack>
          </form>
        </CardContent>
      </Card>
    </Modal>
  );
}
