import { DeepPartial } from 'react-hook-form';
import { PaginatedResponse } from 'src/lib/types/PaginatedResponse';
import { parseSearchParams } from 'src/lib/utils/parseSearchParams';
import useSWR, { SWRConfiguration, SWRResponse } from 'swr';
import { SystemUptime } from '../types/SystemUptime';

type PossibleParams = {
  page: number | string;
  size: number | string;
  order: Record<string, string>;
  filter: {
    systemName: string;
    status: string;
  };
};
export function fetchSystemUptime(
  params?: DeepPartial<PossibleParams>,
  config?: SWRConfiguration
): SWRResponse<PaginatedResponse<SystemUptime[]>> {
  const parsedParams = parseSearchParams(params ?? {});
  const response = useSWR<PaginatedResponse<SystemUptime[]>>(
    `admin/health/system-uptime?${parsedParams}`,
    null,
    config
  );

  return response;
}
