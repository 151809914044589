import { debounce } from 'lodash';
import { DEFAULT_INITIAL_PAGE_SIZE } from 'src/lib/constants/pagination';
import useURLSearchParams from 'src/lib/hooks/useURLSearchParams';
import { Operators } from 'src/lib/types/Operators';
import { fetchConsumableList } from '../../../services/fetchConsumableList';

const initialParams = {
  orderBy: 'eventDate',
  order: 'desc',
  page: '0',
  size: DEFAULT_INITIAL_PAGE_SIZE.toString(),
  searchBy: 'user.slug',
};

export default function useConsumablesListView() {
  const { addParam, removeParam, allSearchParams } =
    useURLSearchParams(initialParams);
  const { data, error, isLoading } = fetchConsumableList({
    order: {
      [allSearchParams.orderBy ?? initialParams.orderBy]:
        allSearchParams.order ?? initialParams.order,
    },
    page: allSearchParams.page ?? initialParams.page,
    size: allSearchParams.size ?? initialParams.size,
    ...(allSearchParams.searchBy &&
      allSearchParams.query && {
        filter: {
          [allSearchParams.searchBy]: {
            like: allSearchParams.query,
          } as Operators,
        },
      }),
  });

  function onChangeFilter(field: string, value: string | undefined) {
    if (!value) removeParam(field);
    else addParam(field, value);
  }

  const debouncedChangeFilter = debounce(onChangeFilter, 400);

  return {
    error,
    isLoading,
    data: data?.body ?? [],
    totalCount: data?.meta?.totalCount ?? 0,
    onChangeFilter: debouncedChangeFilter,
    initialParams: allSearchParams,
  };
}
