import { Box } from '@mui/material';
import UserRankTable from '../../../components/Rankings/BuyerAndSellerRankTable';
import useUserRankTopSellersView from './useView';

export default function UserRankTopSellersView(): JSX.Element {
  const {
    data,
    isLoading,
    handleOpenFilter,
    currentPage,
    currentRankBy,
    currentSize,
  } = useUserRankTopSellersView();
  return (
    <Box mx={2}>
      <UserRankTable
        rankType="seller"
        data={data?.body}
        isLoading={isLoading}
        stats={data?.meta}
        handleOpenFilter={handleOpenFilter}
        page={currentPage}
        rankBy={currentRankBy}
        size={currentSize}
      />
    </Box>
  );
}
