import { DEFAULT_INITIAL_PAGE_SIZE } from 'src/lib/constants/pagination';
import useURLSearchParams from 'src/lib/hooks/useURLSearchParams';
import { fetchSystemUptime } from '../../services/fetchSystemUptime';

export default function useSystemUptimeView() {
  const { allSearchParams } = useURLSearchParams({
    page: '0',
    size: DEFAULT_INITIAL_PAGE_SIZE.toString(),
    sortBy: 'lastCheck',
    sortOrder: 'desc',
  });
  const { data, isLoading } = fetchSystemUptime(
    {
      page: allSearchParams.page,
      size: allSearchParams.size,
      order: {
        [allSearchParams.sortBy!]: allSearchParams.sortOrder,
      },
      filter: {
        systemName: allSearchParams.systemName,
        ...(allSearchParams.status &&
          allSearchParams.status !== 'all' && {
            status: allSearchParams.status,
          }),
      },
    },
    { refreshInterval: 60000 }
  );

  return {
    data,
    isLoading,
    page: +allSearchParams.page!,
    size: +allSearchParams.size!,
    sortBy: allSearchParams.sortBy!,
    sortOrder: allSearchParams.sortOrder!,
  };
}
