import { ElementType, lazy, Suspense } from 'react';
import { Navigate, useLocation, useRoutes } from 'react-router-dom';

import { BlockchainHealthCheckView } from 'src/components/modules/BlockchainHealthChecks';
import FeedAnalytics from 'src/components/modules/FeedAnalytics/view/List';
import GameRatings from 'src/components/modules/GameRatings/views/List';
import PoolsStatus from 'src/components/modules/Home/components/PoolsStatus';
import TransactionHistory from 'src/components/modules/Profile/sections/TransactionHistory/TransactionHistory';
import UserSearchAnalytics from 'src/components/modules/Searches/view/Analytics';
import UserSearchList from 'src/components/modules/Searches/view/List';
import AdminNftTransferPage from 'src/pages/AdminNftTransferPage';
import Login from 'src/pages/auth/Login';
import Register from 'src/pages/auth/Register';
import ChannelContentPage from 'src/pages/ChannelContentPage';
import ChannelPage from 'src/pages/ChannelPage';
import ChannelsPage from 'src/pages/ChannelsPage';
import ChannelVideosPage from 'src/pages/ChannelVideosPage';
import ClubPage from 'src/pages/ClubPage';
import ClubsPage from 'src/pages/ClubsPage';
import CoinsDeliveryPage from 'src/pages/CoinsDeliveryPage';
import CoinsExpiringPage from 'src/pages/CoinsExpiryPage';
import CollectionPage from 'src/pages/CollectionPage';
import Collections from 'src/pages/CollectionsPage';
import CommunicationsPage from 'src/pages/CommunicationsPage';
import ConfigurationsPage from 'src/pages/ConfigurationsPage';
import ConsumablePage from 'src/pages/ConsumablePage';
import ConsumablesPage from 'src/pages/ConsumablesPage';
import ConsumablesSalesPage from 'src/pages/ConsumablesSalesPage';
import ContestAssetsPage from 'src/pages/ContestAssetsPage';
import ContestEventRankingsPage from 'src/pages/ContestEventRankingsPage';
import ConstestEventsPage from 'src/pages/ContestEventsPage';
import ConstestsPage from 'src/pages/ContestsPage';
import DevicePage from 'src/pages/DevicePage';
import DevicesPage from 'src/pages/DevicesPage';
import FeedbacksPage from 'src/pages/FeedbacksPage';
import GameCategoriesPage from 'src/pages/GameCategoriesPage';
import GamePage from 'src/pages/GamePage ';
import GameRankingsPage from 'src/pages/GameRankingsPage';
import GamesAnalyticsPage from 'src/pages/GamesAnalyticsPage';
import GamesPage from 'src/pages/GamesPage';
import GamesSessionsPage from 'src/pages/GamesSessionsPage';
import GameStatisticGroupsPage from 'src/pages/GameStatisticGroupsPage';
import GameTagsPage from 'src/pages/GameTagsPage';
import HomePage from 'src/pages/Home';
import StickyWebPage from 'src/pages/HomeFeedsPage';
import IapAppleReceiptPage from 'src/pages/IapAppleReceiptPage';
import IapTransactionsPage from 'src/pages/IapTransactionsPage';
import NftOwnershipPage from 'src/pages/NftOwnershipPage';
import NftPacksDeliveryPage from 'src/pages/NftPackDeliveryPage';
import NFTPackPage from 'src/pages/NFTPackPage';
import NFTPacksPage from 'src/pages/NFTPacksPage';
import NFTPage from 'src/pages/NFTPage';
import NFTSForPackPage from 'src/pages/NFTSForPackPage';
import NftsPage from 'src/pages/NftsPage';
import NotProcessedConsumablesPage from 'src/pages/NotProcessedConsumablesPage';

import ChatMessagePage from 'src/pages/ChatMessagePage';
import ChatPage from 'src/pages/ChatPage';
import GameMakerAssetCollectionPage from 'src/pages/GameMakerAssetCollectionPage';
import GameMakerAssetCollectionsPage from 'src/pages/GameMakerAssetCollectionsPage';
import MakerDialogPage from 'src/pages/MakerDialogPage';
import MakerDialogsPage from 'src/pages/MakerDialogsPage';
import MakerMostActiveUsersPage from 'src/pages/MakerMostActiveUsers';
import MakerPromptsPage from 'src/pages/MakerPrompts';
import MakerStatisticsPage from 'src/pages/MakerStatisticsPage';
import ObjectsToModeratePage from 'src/pages/ObjectsToModeratePage';
import OldTransactionsPage from 'src/pages/OldTransactionsPage';
import PaymentPage from 'src/pages/PaymentPage';
import PaymentsPage from 'src/pages/PaymentsPage';
import PlayerAnalyticsPage from 'src/pages/PlayerAnalyticsPage';
import PoolPage from 'src/pages/PoolPage';
import PoolsPage from 'src/pages/PoolsPage';
import ProfilePage from 'src/pages/ProfilePage';
import RefundPage from 'src/pages/RefundPage';
import RefundsPage from 'src/pages/RefundsPage';
import RegionsPage from 'src/pages/RegionsPage';
import ReportsPage from 'src/pages/ReportsPage';
import RevenueEventsConsistencyPage from 'src/pages/RevenueEventsConsistencyPage';
import SanctionedCountriesPage from 'src/pages/SanctionedCountriesPage';
import StickyChannelsPage from 'src/pages/StickyChannelsPage';
import SubmissionPage from 'src/pages/SubmissionlPage';
import SubmissionsPage from 'src/pages/SubmissionsPage';
import SubscriptionPage from 'src/pages/SubscriptionPage';
import SubscriptionSalesPage from 'src/pages/SubscriptionSalesPage';
import SubscriptionsPage from 'src/pages/SubscriptionsPage';
import SystemUptimePage from 'src/pages/SystemUptimePage';
import ToReviewPage from 'src/pages/ToReviewPage';
import UserManagementEventsPage from 'src/pages/UserManagementEvents';
import UserPurgesPage from 'src/pages/UserPurgesPage';
import UserRanksPage from 'src/pages/UserRanksPage';
import UsersConsumablesPage from 'src/pages/UsersConsumablesPage';
import UsersLinksPage from 'src/pages/UsersLinksPage';
import UsersNotesPage from 'src/pages/UsersNotesPage';
import UsersPage from 'src/pages/UsersPage';
import UsersSubscriptionsPage from 'src/pages/UsersSubscriptionsPage';
import UsersWithoutWalletPage from 'src/pages/UsersWithoutWalletPage';
import GamesFeedPage from 'src/pages/VideosFeedPage';
import WalletConsistencyPage from 'src/pages/WalletConsistencyPage';
import LoadingScreen from 'src/template/components/LoadingScreen';
import AuthGuard from 'src/template/guards/AuthGuard';
import GuestGuard from 'src/template/guards/GuestGuard';
import DashboardLayout from '../template/layouts/dashboard';
import LogoOnlyLayout from '../template/layouts/LogoOnlyLayout';
import Routes from './Routes';

const Loadable = (Component: ElementType) => (props: any) => {
  const { pathname } = useLocation();

  return (
    <Suspense
      fallback={<LoadingScreen isDashboard={pathname.includes('/dashboard')} />}
    >
      <Component {...props} />
    </Suspense>
  );
};

const NotFound = Loadable(lazy(() => import('../pages/Page404')));
const NotAuthorized = Loadable(lazy(() => import('../pages/Page401')));

export default function Router() {
  return useRoutes([
    {
      path: 'auth',
      children: [
        {
          path: 'login',
          element: (
            <GuestGuard>
              <Login />
            </GuestGuard>
          ),
        },
        {
          path: 'register',
          element: (
            <GuestGuard>
              <Register />
            </GuestGuard>
          ),
        },
        { path: 'login-unprotected', element: <Login /> },
        { path: 'register-unprotected', element: <Register /> },
      ],
    },
    {
      path: '/',
      element: (
        <AuthGuard>
          <DashboardLayout />
        </AuthGuard>
      ),
      children: [
        { element: <Navigate to={Routes.checks} replace />, index: true },
        { path: Routes.checks, element: <HomePage /> },

        // Users
        { path: Routes.users.list, element: <UsersPage /> },
        { path: Routes.user(':uuid'), element: <ProfilePage /> },
        { path: Routes.user_rankings, element: <UserRanksPage /> },
        {
          path: Routes.users.management_events,
          element: <UserManagementEventsPage />,
        },
        {
          path: Routes.users.notes,
          element: <UsersNotesPage />,
        },
        { path: Routes.users.userLinks, element: <UsersLinksPage /> },

        // transactions

        // products
        {
          path: Routes.products.subscriptions,
          element: <SubscriptionsPage />,
        },
        {
          path: Routes.products.consumables,
          element: <ConsumablesPage />,
        },
        { path: Routes.products.nft_packs, element: <NFTPacksPage /> },
        { path: Routes.products.nft_pack(':uuid'), element: <NFTPackPage /> },

        // Objects
        { path: Routes.objects.collections, element: <Collections /> },
        { path: Routes.objects.nfts, element: <NftsPage /> },
        {
          path: Routes.db_consistency.admin_nfts_transfer,
          element: <AdminNftTransferPage />,
        },
        { path: Routes.objects.nfts_for_packs, element: <NFTSForPackPage /> },

        // db_consistency
        {
          path: Routes.db_consistency.user_purges,
          element: <UserPurgesPage />,
        },
        {
          path: Routes.db_consistency.nft_ownership,
          element: <NftOwnershipPage />,
        },
        {
          path: Routes.db_consistency.wallet_consistency,
          element: <WalletConsistencyPage />,
        },
        {
          path: Routes.db_consistency.users_without_wallet,
          element: <UsersWithoutWalletPage />,
        },
        {
          path: Routes.db_consistency.blockchain_healthchecks,
          element: <BlockchainHealthCheckView />,
        },
        {
          path: Routes.db_consistency.revenue_events_consistency,
          element: <RevenueEventsConsistencyPage />,
        },
        {
          path: Routes.db_consistency.not_processed_consumables,
          element: <NotProcessedConsumablesPage />,
        },

        // game_center
        {
          path: Routes.game_center.games,
          element: <GamesPage />,
        },
        {
          path: Routes.game_center.categories,
          element: <GameCategoriesPage />,
        },
        {
          path: Routes.game_center.tags,
          element: <GameTagsPage />,
        },

        {
          path: Routes.game_center.analytics,
          element: <GamesAnalyticsPage />,
        },
        {
          path: Routes.game_center.player_analytics,
          element: <PlayerAnalyticsPage />,
        },
        {
          path: Routes.game_center.sessions,
          element: <GamesSessionsPage />,
        },
        {
          path: Routes.game_center.game.view(':gameUuid'),
          element: <GamePage />,
        },
        {
          path: Routes.configurations.contests,
          element: <ConstestsPage />,
        },
        {
          path: Routes.game_center.game.contests(':uuid'),
          element: <ConstestsPage />,
        },
        {
          path: Routes.game_center.ratings,
          element: <GameRatings />,
        },
        {
          path: Routes.game_center.contest.events(':contestUuid'),
          element: <ConstestEventsPage />,
        },
        {
          path: Routes.game_center.contest.assets(':contestUuid'),
          element: <ContestAssetsPage />,
        },
        {
          path: Routes.game_center.game.rankings(':gameUuid'),
          element: <GameRankingsPage />,
        },
        {
          path: Routes.game_center.contestEvent.rankings(':eventUuid'),
          element: <ContestEventRankingsPage />,
        },
        {
          path: Routes.game_center.game.statistic_groups(':gameUuid'),
          element: <GameStatisticGroupsPage />,
        },
        // SGM
        {
          path: Routes.sgm.statistics,
          element: <MakerStatisticsPage />,
        },
        {
          path: Routes.sgm.dialogs,
          element: <MakerDialogsPage />,
        },
        {
          path: Routes.sgm.dialog(':promptDialogUuid'),
          element: <MakerDialogPage />,
        },
        {
          path: Routes.sgm.most_active_users,
          element: <MakerMostActiveUsersPage />,
        },
        {
          path: Routes.sgm.prompts,
          element: <MakerPromptsPage />,
        },

        {
          path: Routes.sgm.assetCollections,
          element: <GameMakerAssetCollectionsPage />,
        },
        {
          path: Routes.sgm.assetCollection(':assetCollectionUuid'),
          element: <GameMakerAssetCollectionPage />,
        },

        // Channels
        {
          path: Routes.channel.channels,
          element: <ChannelsPage />,
        },
        {
          path: Routes.channel.stickyChannels,
          element: <StickyChannelsPage />,
        },
        {
          path: Routes.channel.channel(':channelUuid'),
          element: <ChannelPage />,
        },
        {
          path: Routes.channel.contents,
          element: <ChannelVideosPage />,
        },
        {
          path: Routes.channel.content(':contentUuid'),
          element: <ChannelContentPage />,
        },

        // Submissions
        {
          path: Routes.submission.submissions,
          element: <SubmissionsPage />,
        },
        {
          path: Routes.submission.submission(':submissionUuid'),
          element: <SubmissionPage />,
        },

        // Review
        {
          path: Routes.review.toReview,
          element: <ToReviewPage />,
        },
        {
          path: Routes.review.object(':objectType'),
          element: <ObjectsToModeratePage />,
        },

        // Search
        {
          path: Routes.search.searches,
          element: <UserSearchList />,
        },
        {
          path: Routes.search.analytics,
          element: <UserSearchAnalytics />,
        },

        {
          path: Routes.game_center.feed_analytics,
          element: <FeedAnalytics />,
        },

        // Comunity
        {
          path: Routes.comunity.clubs,
          element: <ClubsPage />,
        },
        {
          path: Routes.comunity.club(':clubUuid'),
          element: <ClubPage />,
        },
        {
          path: Routes.comunity.chat.chat(':roomId'),
          element: <ChatPage />,
        },
        {
          path: Routes.comunity.chat.message(':roomId', ':messageId'),
          element: <ChatMessagePage />,
        },

        // Configurations
        { path: Routes.configurations.home_feeds, element: <StickyWebPage /> },
        {
          path: Routes.configurations.app_configurations,
          element: <ConfigurationsPage />,
        },
        {
          path: Routes.configurations.sanctioned_countries,
          element: <SanctionedCountriesPage />,
        },
        { path: Routes.configurations.regions, element: <RegionsPage /> },
        {
          path: Routes.configurations.game_feed,
          element: <GamesFeedPage />,
        },

        { path: Routes.collection(':uuid'), element: <CollectionPage /> },
        { path: Routes.nft(':uuid'), element: <NFTPage /> },
        {
          path: Routes.transactions.payment_requests,
          element: <PaymentsPage />,
        },
        {
          path: Routes.transactions.payment_request(':uuid'),
          element: <PaymentPage />,
        },
        {
          path: Routes.transactions.nft_pack_delivery,
          element: <NftPacksDeliveryPage />,
        },
        {
          path: Routes.transactions.coins_delivery,
          element: <CoinsDeliveryPage />,
        },
        {
          path: Routes.transactions.coins_expiring,
          element: <CoinsExpiringPage />,
        },
        {
          path: Routes.transactions.nft_pack(':uuid'),
          element: <NFTPackPage />,
        },
        { path: Routes.old_transactions, element: <OldTransactionsPage /> },
        { path: Routes.refunds, element: <RefundsPage /> },
        {
          path: Routes.refund(':originalTransactionId'),
          element: <RefundPage />,
        },
        {
          path: Routes.user_refund(':originalTransactionId', ':userId'),
          element: <RefundPage />,
        },
        { path: Routes.transactions.pools, element: <PoolsPage /> },
        { path: Routes.transactions.list, element: <TransactionHistory /> },
        { path: Routes.transactions.poolsStatus, element: <PoolsStatus /> },
        { path: Routes.transactions.pool(':id'), element: <PoolPage /> },
        { path: Routes.iap, element: <IapTransactionsPage /> },
        {
          path: Routes.iap_apple_receipt(':uuid'),
          element: <IapAppleReceiptPage />,
        },
        { path: Routes.reports, element: <ReportsPage /> },
        { path: Routes.feedbacks, element: <FeedbacksPage /> },

        { path: Routes.device(':uuid'), element: <DevicePage /> },
        {
          path: Routes.devices,
          element: <DevicesPage />,
        },
        {
          path: Routes.communications,
          element: <CommunicationsPage />,
        },
        {
          path: Routes.subscription(':purchaseId', ':userUuid?'),
          element: <SubscriptionPage />,
        },
        {
          path: Routes.system_uptime,
          element: <SystemUptimePage />,
        },
        {
          path: Routes.subscriptions_sales_report,
          element: <SubscriptionSalesPage />,
        },
        {
          path: Routes.consumables_sales_report,
          element: <ConsumablesSalesPage />,
        },
        {
          path: Routes.subscriptions_sales_v2(),
          element: <UsersSubscriptionsPage />,
        },
        {
          path: Routes.consumables_sales_list(),
          element: <UsersConsumablesPage />,
        },
        {
          path: Routes.consumable(':id', ':userUuid?'),
          element: <ConsumablePage />,
        },
      ],
    },
    {
      path: '*',
      element: <LogoOnlyLayout />,
      children: [
        { path: '401', element: <NotAuthorized /> },
        { path: '404', element: <NotFound /> },
        { path: '*', element: <Navigate to="/404" replace /> },
      ],
    },
    { path: '*', element: <Navigate to="/404" replace /> },
  ]);
}
