import { ThumbDown, ThumbUp } from '@mui/icons-material';
import { GridColumns } from '@mui/x-data-grid';
import Link from 'src/components/shared/Link';
import MagicTableUserCell from 'src/components/shared/MagicTable/UserCell';
import { GameCenterReviewListRow } from 'src/lib/types/gameReview';
import { formatMilliseconds } from 'src/lib/utils/formatMilliseconds';
import { formatUTCDate } from 'src/lib/utils/formatUTCDate';
import Routes from 'src/routes/Routes';

export const tableColumns: GridColumns<GameCenterReviewListRow> = [
  {
    field: 'user.name',
    headerName: 'User',
    sortable: true,
    renderCell: ({ row }) => (
      <MagicTableUserCell
        user={row.user}
        linkUrl={`${Routes.user(row.user?.uuid)}?tab=Game%20Ratings`}
      />
    ),
  },
  {
    field: 'like',
    headerName: 'Rating',
    headerAlign: 'center',
    align: 'center',
    sortable: false,
    renderCell({ row }) {
      return row.like ? (
        <ThumbUp color="success" />
      ) : (
        <ThumbDown color="error" />
      );
    },
    flex: 0,
  },
  {
    field: 'comment',
    headerName: 'Comment',
    sortable: false,
    valueFormatter({ value }) {
      return value ?? '--';
    },
  },
  {
    field: 'gameVersion.game.name',
    headerName: 'Game',
    sortable: true,
    renderCell({ row }) {
      return (
        <Link
          to={`${Routes.game_center.game.view(
            row.gameVersion?.game?.uuid ?? ''
          )}?tab=sessions`}
          color={'GrayText'}
          underline="hover"
        >
          {row.gameVersion?.game?.name ?? row.gameVersion?.uuid.substring(0, 5)}
        </Link>
      );
    },
  },
  {
    field: 'gameVersion.version',
    headerName: 'Version',
    sortable: false,
    renderCell({ row }) {
      return (
        <p>
          {row.gameVersion.version ??
            row.gameVersion?.game?.uuid.substring(0, 5)}
        </p>
      );
    },
  },
  {
    field: 'totalSessions',
    headerName: 'Total Sessions',
    sortable: true,
  },
  {
    field: 'totalTime',
    headerName: 'Total Time',
    sortable: true,
    valueFormatter({ value }) {
      return formatMilliseconds(value);
    },
  },
  {
    field: 'firstPlayed',
    headerName: 'First Played',
    sortable: false,
    valueFormatter({ value }) {
      return value ? formatUTCDate(value) : '-';
    },
  },
  {
    field: 'lastPlayed',
    headerName: 'Last Played',
    sortable: false,
    valueFormatter({ value }) {
      return value ? formatUTCDate(value) : '-';
    },
  },
  {
    field: 'createdAt',
    headerName: 'Review Date',
    sortable: true,
    valueFormatter({ value }) {
      return value ? formatUTCDate(value) : '-';
    },
  },
];
