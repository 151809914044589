import { TextField } from '@mui/material';
import { CommonDateStrings } from 'src/lib/constants/CommonDates';
import { FilterProps } from './types';

export function UserSearchesTableDateFilters({
  // addParam,
  removeParam,
  debouncedChangeField,
  propParams,
}: FilterProps) {
  return (
    <>
      <TextField
        type="date"
        label="Start Date"
        InputLabelProps={{ shrink: true }}
        defaultValue={propParams.startDate}
        inputProps={{
          min: CommonDateStrings.userSearchRegistrationFeatureLaunchDate
            .dateTime,
          max: CommonDateStrings.today.dateTime,
        }}
        onChange={(e) => {
          const { value } = e.target;
          if (value) {
            debouncedChangeField(value, 'startDate');
          } else removeParam('startDate');
        }}
      />
      <TextField
        type="date"
        label="End Date"
        InputLabelProps={{ shrink: true }}
        defaultValue={propParams.endDate}
        inputProps={{
          min: CommonDateStrings.userSearchRegistrationFeatureLaunchDate
            .dateTime,
          max: CommonDateStrings.today.dateTime,
        }}
        onChange={(e) => {
          const { value } = e.target;
          if (value) {
            debouncedChangeField(value, 'endDate');
          } else removeParam('endDate');
        }}
      />
    </>
  );
}
