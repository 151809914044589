import { Container } from '@mui/material';
import Reports from 'src/components/modules/Reports/Reports';
import Page from 'src/template/components/Page';

export default function ReportsPage() {
  return (
    <Page title="Reports">
      <Container maxWidth="xl">
        <Reports />
      </Container>
    </Page>
  );
}
