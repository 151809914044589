import { yupResolver } from '@hookform/resolvers/yup';
import { Add, Close } from '@mui/icons-material';
import {
  Alert,
  Autocomplete,
  Card,
  CardContent,
  CardHeader,
  Stack,
  TextField,
} from '@mui/material';
import { Controller, useFieldArray, useForm } from 'react-hook-form';
import Button from 'src/components/shared/Buttons/CustomButton';
import Flex from 'src/components/shared/Flex';
import Modal from 'src/components/shared/Modal';
import useModalStore from 'src/lib/stores/useModalStore';
import * as yup from 'yup';
import { fetchRarities } from '../../../services/fetchRarities';
import { NFTPackRarityFormValues } from './types';

const validationSchema = yup.object().shape({
  rarities: yup
    .array()
    .of(
      yup.object().shape({
        uuid: yup.string().required('Rarity is required'),
        proportion: yup
          .number()
          .min(1, 'Proportion must be greater than or equal to 1')
          .required(),
      })
    )
    .test({
      name: 'sumOfProportions',
      exclusive: true,
      message: 'The sum of all proportions must be 100.',
      test: (value) => {
        const sum = value!.reduce(
          (accumulator, rarity) => accumulator + rarity.proportion!,
          0
        );
        return sum === 100;
      },
    })
    .required(),
});

type Props = {
  defaultValues: NFTPackRarityFormValues;
  onSubmit: (values: NFTPackRarityFormValues) => Promise<void>;
  title: string;
};

export default function NFTPackRarityForm({
  defaultValues,
  title,
  onSubmit,
}: Props): JSX.Element {
  const onClose = useModalStore((state) => state.closeModal);
  const {
    control,
    handleSubmit,
    formState: { isSubmitting },
    getValues,
    getFieldState,
  } = useForm<NFTPackRarityFormValues>({
    defaultValues,
    resolver: yupResolver(validationSchema),
  });

  const handleCloseModal = () => {
    onClose();
  };

  const { data: raritiesData } = fetchRarities();

  const {
    fields: selectedRarities,
    remove: removeRarity,
    append: appendRarity,
  } = useFieldArray({
    control,
    name: 'rarities',
  });

  const getRaritiesOptionsByIndex = (index: number) =>
    raritiesData?.filter(
      (rarityOption) =>
        selectedRarities[index].uuid === rarityOption.uuid ||
        !selectedRarities.some((r) => r.uuid == rarityOption.uuid)
    ) ?? [];

  const handleAddRarity = () => {
    const values = getValues('rarities');
    const emptyRarity = values.find((rarity) => rarity.uuid === '');

    if (emptyRarity) return;

    appendRarity({
      uuid: '',
      name: '',
      proportion: 0,
    });
  };

  const handleRemoveRarity = (index: number) => {
    const values = getValues('rarities');
    if (values.length === 1) return;
    removeRarity(index);
  };

  return (
    <Modal open onClose={handleCloseModal}>
      <Card
        sx={{
          width: '600px',
        }}
      >
        <CardHeader title={title} />
        <CardContent>
          <form onSubmit={handleSubmit(onSubmit)}>
            <Stack mb={3} spacing={1.5}>
              {selectedRarities.map((rarity, rarityIndex) => (
                <Flex key={rarity.id} gap={1} alignItems={'flex-start'}>
                  <Controller
                    control={control}
                    name={`rarities.${rarityIndex}.uuid`}
                    render={({ field, fieldState }) => (
                      <Autocomplete
                        fullWidth
                        options={
                          getRaritiesOptionsByIndex(rarityIndex)?.map(
                            (r) => r.uuid
                          ) ?? []
                        }
                        getOptionLabel={(option) =>
                          getRaritiesOptionsByIndex(rarityIndex)?.find(
                            (r) => r.uuid === option
                          )?.name ?? ''
                        }
                        defaultValue={field.value}
                        onChange={(e, value) => field.onChange(value)}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            {...field}
                            label="Rarity"
                            fullWidth
                            required
                          />
                        )}
                      />
                    )}
                  />
                  <Controller
                    control={control}
                    name={`rarities.${rarityIndex}.proportion`}
                    render={({ field, fieldState }) => (
                      <TextField
                        {...field}
                        label="Proportion"
                        required
                        type="number"
                        error={fieldState.invalid}
                        helperText={fieldState.error?.message}
                      />
                    )}
                  />
                  <Button
                    onClick={() => handleRemoveRarity(rarityIndex)}
                    size="large"
                  >
                    <Close />
                  </Button>
                </Flex>
              ))}

              {getFieldState(`rarities`).error?.message && (
                <Alert severity="error">
                  {getFieldState(`rarities`).error?.message}
                </Alert>
              )}

              <Button color="success" onClick={handleAddRarity}>
                Add Rarity
                <Add />
              </Button>
            </Stack>

            <Stack
              direction="row"
              justifyContent="space-between"
              spacing={1}
              mt={2}
            >
              <Button color="error" onClick={handleCloseModal}>
                Cancel
              </Button>
              <Button
                variant="contained"
                color="secondary"
                type="submit"
                loading={isSubmitting}
              >
                Save
              </Button>
            </Stack>
          </form>
        </CardContent>
      </Card>
    </Modal>
  );
}
