import { fetchPaymentRequests } from 'src/lib/services/paymentRequests';
import { PaymentRequestStatus } from 'src/lib/types/paymentRequest';
import useTabs from 'src/template/hooks/useTabs';
import usePaymentsTable from '../../usePaymentsTable';

export default function usePayments() {
  const { currentTab: status, onChangeTab: onChangeReviewStatus } =
    useTabs('all');
  const { filter } = usePaymentsTable((state) => state);

  const [setQuery, setSearchFor] = usePaymentsTable((state) => [
    state.setQuery,
    state.setSearchFor,
  ]);

  const { data: paymentRequests, total } = fetchPaymentRequests(
    {
      ...filter,
    },
    status as PaymentRequestStatus | 'all'
  );

  return {
    paymentRequests,
    total,
    status,
    filter,
    onChangeReviewStatus,
    setQuery,
    setSearchFor,
  };
}
