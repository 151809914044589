import { DeepPartial } from 'react-hook-form';
import { ArrayOfObjectsToModerate } from 'src/lib/types/arrayOfObjectsToModerate';
import { OBJECT_MODERATION_OBJECT_TYPE } from 'src/lib/types/objectModeration';
import { parseSearchParams } from 'src/lib/utils/parseSearchParams';
import useSWR, { SWRResponse } from 'swr';

export const fetchObjectsToReview = (
  filters?: DeepPartial<PossibleParams>,
  shouldFetch = true
): SWRResponse<ArrayOfObjectsToModerate> => {
  const response = useSWR(
    shouldFetch
      ? `admin/object-moderation?${parseSearchParams(filters ?? {})}`
      : null
  );

  return response;
};

export type ObjectsToModerateResponse = {
  meta: { totalCount: number };
  body: ArrayOfObjectsToModerate[];
};

type PossibleParams = {
  page: number | string;
  size: number | string;
  order: Record<string, string>;
  objectType: OBJECT_MODERATION_OBJECT_TYPE;
};
