import useNotify from 'src/lib/hooks/useNotify';
import { Collection } from 'src/lib/types/collection';
import { makeCollectionService } from '../../services/CollectionService';
import CollectionForm from './Form';
import { CollectionFormValues } from './Form/types';

export type UpdateCollectionProps = {
  collection: Collection;
  open: boolean;
  onClose: () => void;
};
export default function UpdateCollection({
  collection,
  open,
  onClose,
}: UpdateCollectionProps): JSX.Element {
  const notify = useNotify();
  const handleSubmit = async (values: CollectionFormValues) => {
    const collectionService = await makeCollectionService();

    const { error } = await collectionService.update(collection.uuid, {
      ...values,
      tags: values.tags.split(','),
    });

    if (error) {
      notify(error, { variant: 'error' });
      return;
    }
    onClose();
    notify('Collection updated successfully', { variant: 'success' });
  };
  return (
    <CollectionForm
      initialValues={{
        name: collection.name,
        description: collection.description,
        priceType: collection.priceType,
        hasCertifiedRights: collection.hasCertifiedRights,
        tags: collection.tags.join(','),
        coverFileUrl: collection.coverFileUrl,
        userUuid: collection.userUuid,
        uuid: collection.uuid,
        certifiedRights: collection.certifiedRights ?? false,
      }}
      open={open}
      onClose={onClose}
      onSubmit={handleSubmit}
      collection={collection}
    />
  );
}
