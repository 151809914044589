import { Divider, Stack, Typography, useTheme } from '@mui/material';
import { ReactNode, useCallback, useState } from 'react';
import NumericTypography from '../NumericTypography';
import Tooltip from '../Tooltip';

export type SimpleTableData = {
  id: string;
  label: ReactNode | string;
  value: ReactNode | string | number;
  onClick?: () => void;
  children?: ReactNode;
  tooltip?: string[];
  capitalize?: boolean;
};

type SimpleTableProps = {
  data: SimpleTableData[];
  rounded?: boolean;
  border?: boolean;
  invertColors?: boolean;
  capitalize?: boolean;
};

export default function SimpleTable({
  data,
  rounded = false,
  border = false,
  invertColors = false,
  capitalize,
}: SimpleTableProps) {
  const theme = useTheme();

  return (
    <Stack
      width="100%"
      border={border ? `1px solid ${theme.palette.primary.main}1A` : ''}
      borderRadius={rounded ? '7px' : '0'}
    >
      {data &&
        data.map((item) => (
          <SimpleTableRow
            item={item}
            key={item.id}
            rounded={rounded}
            invertColors={invertColors}
            tooltip={item.tooltip}
            capitalize={item.capitalize ?? capitalize}
          />
        ))}
    </Stack>
  );
}

type SimpleTableRowProps = {
  item: SimpleTableData;
  rounded?: boolean;
  invertColors?: boolean;
  tooltip?: string[];
  capitalize?: boolean;
};

function SimpleTableRow({
  item,
  rounded,
  invertColors,
  capitalize = true,
}: SimpleTableRowProps) {
  const theme = useTheme();

  const [expandedRowKey, setExpandedRowKey] = useState<string | null>(null);

  const toggleRowExpansion = useCallback(
    (newKey: string) => {
      setExpandedRowKey(newKey === expandedRowKey ? null : newKey);
    },
    [expandedRowKey]
  );

  return (
    <Stack
      key={item.id}
      sx={{
        [`:nth-child(${invertColors ? 'odd' : 'even'})`]: {
          backgroundColor: `${theme.palette.primary.main}13`,
        },
        ...(rounded
          ? {
              ':first-child': {
                borderTopLeftRadius: '7px',
                borderTopRightRadius: '7px',
              },
              ':last-child': {
                borderBottomLeftRadius: '7px',
                borderBottomRightRadius: '7px',
              },
            }
          : {}),
      }}
    >
      <Stack
        alignItems="center"
        justifyContent="space-between"
        direction="row"
        py={1}
        px={2}
        onClick={() => {
          if (item.children) toggleRowExpansion(item.id);
          if (item.onClick) item.onClick();
        }}
        sx={{
          ...(item.onClick || item.children
            ? {
                ':hover': {
                  backgroundColor: `${theme.palette.primary.main}10`,
                  cursor: 'pointer',
                },
              }
            : {}),
        }}
      >
        <Stack direction="row">
          {typeof item.label === 'string' ? (
            <Typography
              fontSize="14px"
              sx={{
                textTransform: capitalize ? 'capitalize' : 'none',
              }}
            >
              {item.label}
            </Typography>
          ) : (
            item.label
          )}
          {item.tooltip?.length && <Tooltip title={item.tooltip} />}
        </Stack>
        {typeof item.value === 'string' || typeof item.value === 'number' ? (
          <NumericTypography fontSize="14px" fontWeight="bold">
            {item.value}
          </NumericTypography>
        ) : (
          item.value
        )}
      </Stack>
      {item.children && expandedRowKey === item.id ? (
        <Stack
          bgcolor={theme.palette.background.default}
          direction="row"
          pl={1}
          justifyContent="space-between"
        >
          <Divider orientation="vertical" flexItem />
          {item.children}
        </Stack>
      ) : null}
    </Stack>
  );
}
