import { Container, Stack } from '@mui/material';
import HeaderBreadcrumbs from 'src/template/components/HeaderBreadcrumbs';
import WalletsConsistencyTableView from '../../components/table';
import TriggerWalletVerificationButtonView from '../../components/triggerWalletVerificationButton';
import WalletVerificationAlertView from '../../components/walletVerificationAlert';

export default function WalletsConsistencyView(): JSX.Element {
  return (
    <Container maxWidth="xl">
      <HeaderBreadcrumbs
        heading="Wallets Inconsistency"
        links={[{ name: 'Wallets Inconsistency' }]}
        action={
          <Stack direction="row" spacing={1}>
            <TriggerWalletVerificationButtonView />
          </Stack>
        }
      />
      <WalletVerificationAlertView />
      <WalletsConsistencyTableView />
    </Container>
  );
}
