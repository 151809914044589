import { debounce } from 'lodash';
import { CommonDateStrings } from 'src/lib/constants/CommonDates';
import useURLSearchParams from 'src/lib/hooks/useURLSearchParams';
import {
  fetchSubscriptionsReport,
  fetchSubscriptionsTotalizers,
} from '../../../services/fetchSubscriptionsReport';

export default function useSubscriptionSalesView() {
  const { allSearchParams, removeParam, addParam } = useURLSearchParams({
    startDate: CommonDateStrings.sevenDaysAgo.date,
    endDate: CommonDateStrings.today.date,
  });

  const { data, error, isLoading } = fetchSubscriptionsReport({
    endDate: allSearchParams.endDate || CommonDateStrings.today.date,
    startDate: allSearchParams.startDate || CommonDateStrings.sevenDaysAgo.date,
    country: allSearchParams.country,
  });

  const {
    data: totalizers,
    error: errorTotalizers,
    isLoading: isLoadingTotalizers,
  } = fetchSubscriptionsTotalizers();

  function onChangeFilter(field: string, value: string | null | undefined) {
    if (!value) removeParam(field);
    else addParam(field, value);
  }

  const debouncedOnChangeFilter = debounce(onChangeFilter, 600);

  return {
    data,
    error,
    isLoading,
    onChangeFilter: debouncedOnChangeFilter,
    totalizers: {
      data: totalizers,
      error: errorTotalizers,
      isLoading: isLoadingTotalizers,
    },
    filter: {
      startDate: allSearchParams.startDate,
      endDate: allSearchParams.endDate,
      country: allSearchParams.country,
    },
  };
}
