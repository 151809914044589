import { Box, BoxProps } from '@mui/material';

export default function MagicTableWrapper({
  children,
  ...props
}: BoxProps): JSX.Element {
  return (
    <Box px={2} {...props}>
      {children}
    </Box>
  );
}
