/* eslint-disable react/destructuring-assignment */
import { Reviews } from '@mui/icons-material';
import {
  Button,
  Card,
  CardContent,
  CardHeader,
  Container,
  Divider,
} from '@mui/material';
import { capitalCase } from 'change-case';
import Flex from 'src/components/shared/Flex';
import SimpleTable from 'src/components/shared/SimpleTable/SimpleTable';
import {
  OBJECT_MODERATION_HUMAN_ANALYSIS_NEEDED,
  OBJECT_MODERATION_OBJECT_TYPE,
  ObjectActionables,
  ObjectModeration,
  ObjectStatuses,
} from 'src/lib/types/objectModeration';
import Routes from 'src/routes/Routes';
import HeaderBreadcrumbs from 'src/template/components/HeaderBreadcrumbs';
import Label from 'src/template/components/Label';
import { toolTipByStatusAndActionables } from '../../utils/statusAndActionablesTooltipsText';
import {
  colorByAIReview,
  colorByHumanAnalysisNeeded,
  colorByObjectVisibility,
  colorByReviewStatus,
  colorByUserStatus,
  colortByActionablesStatus,
  labelByObjectType,
} from '../../utils/utils';
import ObjectModerationTable from '../Table';
import useObjectModerationTab from './useView';

export const linkByObjectType: Record<OBJECT_MODERATION_OBJECT_TYPE, string> = {
  [OBJECT_MODERATION_OBJECT_TYPE.CHANNEL]: Routes.channel.channels,
  [OBJECT_MODERATION_OBJECT_TYPE.CLUB]: Routes.comunity.clubs,
  [OBJECT_MODERATION_OBJECT_TYPE.COLLECTION]: Routes.objects.collections,
  [OBJECT_MODERATION_OBJECT_TYPE.GAME_VERSION]: Routes.game_center.games,
  [OBJECT_MODERATION_OBJECT_TYPE.NFT]: Routes.objects.nfts,
  [OBJECT_MODERATION_OBJECT_TYPE.USER]: Routes.users.list,
  [OBJECT_MODERATION_OBJECT_TYPE.VIDEO]: Routes.channel.contents,
};
interface IObjectModerationTabProps {
  showHeader?: boolean;
  objectUuid: string;
  objectType: OBJECT_MODERATION_OBJECT_TYPE;
}

type MainStatusProps = Partial<
  Pick<ObjectStatuses, 'reviewStatus' | 'userStatus' | 'visibility'>
>;
function MainStatus(props: MainStatusProps) {
  return (
    <Card sx={{ flex: 1, width: '50%' }}>
      <Flex justifyContent="space-between" alignItems="center" pr={2}>
        <CardHeader title="Main Status" />
      </Flex>
      <CardContent>
        <SimpleTable
          border
          rounded
          data={[
            {
              id: 'reviewStatus',
              label: 'Review Status',
              value: (
                <Label
                  variant="ghost"
                  color={colorByReviewStatus[props.reviewStatus!]}
                  sx={{ textTransform: 'capitalize' }}
                >
                  {capitalCase(props.reviewStatus ?? 'null')}
                </Label>
              ),
            },
            {
              id: 'userStatus',
              label: 'User Status',
              value: (
                <Label
                  variant="ghost"
                  color={colorByUserStatus[props.userStatus!]}
                >
                  {capitalCase(props.userStatus ?? 'null')}
                </Label>
              ),
            },
          ]}
        />

        <Divider
          sx={{
            my: 2,
          }}
        />

        <SimpleTable
          border
          rounded
          data={[
            {
              id: 'visibility',
              label: 'Visibility',
              value: (
                <Label
                  variant="ghost"
                  color={colorByObjectVisibility[props.visibility!]}
                >
                  {capitalCase(props.visibility ?? 'null')}
                </Label>
              ),
              tooltip: toolTipByStatusAndActionables.visibility,
            },
          ]}
        />
      </CardContent>
    </Card>
  );
}

type ReviewStatusProps = Partial<
  Pick<ObjectStatuses, 'humanAnalysisNeeded'> &
    Pick<ObjectModeration, 'aiReview' | 'reviewStatus'>
>;
function ReviewStatus(props: ReviewStatusProps) {
  return (
    <Card sx={{ flex: 1, width: '50%' }}>
      <Flex justifyContent="space-between" alignItems="center" pr={2}>
        <CardHeader title="AI/HA Status" />
      </Flex>
      <CardContent>
        <SimpleTable
          border
          rounded
          data={[
            {
              id: 'aiReview',
              label: 'AI Review',
              value: (
                <Label variant="ghost" color={colorByAIReview[props.aiReview!]}>
                  {capitalCase(props.aiReview ?? 'NONE')}
                </Label>
              ),
            },
          ]}
        />
        <Divider
          sx={{
            my: 2,
          }}
        />
        <SimpleTable
          border
          rounded
          invertColors
          data={[
            {
              id: 'humanAnalysisNeeded',
              label: 'HA Needed?',
              value: (
                <Label
                  variant="ghost"
                  color={
                    props.humanAnalysisNeeded !==
                    OBJECT_MODERATION_HUMAN_ANALYSIS_NEEDED.FALSE
                      ? 'error'
                      : 'success'
                  }
                >
                  {props.humanAnalysisNeeded !==
                  OBJECT_MODERATION_HUMAN_ANALYSIS_NEEDED.FALSE
                    ? 'Yes'
                    : 'No'}
                </Label>
              ),
            },
            ...(props.humanAnalysisNeeded !==
            OBJECT_MODERATION_HUMAN_ANALYSIS_NEEDED.FALSE
              ? [
                  {
                    id: 'whyHumanAnalysisNeeded',
                    label: 'Why Needed?',
                    value: (
                      <Label
                        variant="ghost"
                        color={
                          colorByHumanAnalysisNeeded[props.humanAnalysisNeeded!]
                        }
                      >
                        {capitalCase(props.humanAnalysisNeeded ?? 'null')}
                      </Label>
                    ),
                  },
                ]
              : []),
            {
              id: 'haReview',
              label: 'HA Review',
              value: (
                <Label
                  variant="ghost"
                  color={colorByReviewStatus[props.reviewStatus!]}
                >
                  {capitalCase(props.reviewStatus ?? 'NONE')}
                </Label>
              ),
              tooltip: toolTipByStatusAndActionables.humanReview,
            },
          ]}
        />
      </CardContent>
    </Card>
  );
}

type ActionablesCardProps = Partial<ObjectActionables>;
function ActionablesCard(props: ActionablesCardProps) {
  return (
    <Card sx={{ flex: 1, width: '50%' }}>
      <Flex justifyContent="space-between" alignItems="center" pr={2}>
        <CardHeader title="Actionables" />
      </Flex>
      <CardContent>
        <SimpleTable
          border
          rounded
          data={[
            {
              id: 'editability',
              label: 'Editability',
              value: (
                <Label
                  variant="ghost"
                  color={colortByActionablesStatus[props.editability!]}
                >
                  {capitalCase(props.editability ?? 'null')}
                </Label>
              ),
              tooltip: toolTipByStatusAndActionables.editability,
            },
            {
              id: 'social',
              label: 'Social',
              value: (
                <Label
                  variant="ghost"
                  color={colortByActionablesStatus[props.social!]}
                >
                  {capitalCase(props.social ?? 'null')}
                </Label>
              ),
              tooltip: toolTipByStatusAndActionables.social,
            },
            {
              id: 'transfer',
              label: 'Transfer',
              value: (
                <Label
                  variant="ghost"
                  // color={colortByActionablesStatus[props.transfer!]}
                  color="default"
                >
                  {/* capitalCase(props.transfer ?? 'null') */}
                  None
                </Label>
              ),
              tooltip: toolTipByStatusAndActionables.transfer,
            },
            {
              id: 'monetization',
              label: 'Monetization',
              value: (
                <Label
                  variant="ghost"
                  // color={colortByActionablesStatus[props.monetization!]}
                  color="default"
                >
                  {/* capitalCase(props.monetization ?? 'null') */}
                  None
                </Label>
              ),
              tooltip: toolTipByStatusAndActionables.monetization,
            },
          ]}
        />
      </CardContent>
    </Card>
  );
}

export default function ObjectModerationTab({
  showHeader = false,
  objectType,
  objectUuid,
}: IObjectModerationTabProps) {
  const { data, isLoading, params, openReviewModal } = useObjectModerationTab(
    objectType,
    objectUuid
  );

  return (
    // add a action to change the object moderation status
    <Container maxWidth="xl">
      {showHeader ? (
        <HeaderBreadcrumbs
          heading={`Moderation`}
          links={[
            {
              name: `${labelByObjectType[objectType]}s`,
              href: linkByObjectType[objectType],
            },
            { name: `Moderation` },
          ]}
          action={
            <Button
              variant="contained"
              color="secondary"
              onClick={() => {
                openReviewModal(objectUuid, objectType);
              }}
              startIcon={<Reviews />}
            >
              Review
            </Button>
          }
        />
      ) : (
        <Flex justifyContent="flex-end">
          <Button
            variant="contained"
            color="secondary"
            onClick={() => {
              openReviewModal(objectUuid, objectType);
            }}
            startIcon={<Reviews />}
          >
            Review
          </Button>
        </Flex>
      )}
      {/*
        Update the ObjectModerationTable component to accept a new prop
      */}

      <Flex alignItems={'start'} my={2} gap={2}>
        <MainStatus
          reviewStatus={data?.object?.reviewStatus}
          userStatus={data?.object?.userStatus}
          visibility={data?.object?.visibility}
        />
        <ReviewStatus
          humanAnalysisNeeded={data?.object?.humanAnalysisNeeded}
          aiReview={
            data?.moderations.rows.find((moderation) => !!moderation.aiReview)
              ?.aiReview ?? undefined
          }
          reviewStatus={
            data?.moderations.rows?.[0]?.adminReviewerUuid
              ? data?.moderations.rows?.[0]?.reviewStatus
              : undefined
          }
        />
        <ActionablesCard
          editability={data?.object?.editability}
          monetization={data?.object?.monetization}
          social={data?.object?.social}
          transfer={data?.object?.transfer}
        />
      </Flex>

      <Card>
        <Flex justifyContent="space-between" alignItems="center" pr={2}>
          <CardHeader title="All Moderations" />
        </Flex>
        <CardContent>
          <ObjectModerationTable
            data={data?.moderations?.rows}
            totalCount={data?.moderations?.count}
            isLoading={isLoading}
            params={params}
          />
        </CardContent>
      </Card>
    </Container>
  );
}
