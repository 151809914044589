import getAPIClient from 'src/lib/services/api/axios.config';
import { ServiceResponse } from 'src/lib/types/ServiceResponse';
import { getMessageFromError } from 'src/lib/utils/error';

export type SetNftPackRaritiesData = {
  uuid: string;
  proportions: {
    rarityUuid: string;
    proportion: number;
  }[];
};

export async function SetNftPackRarities(
  data: SetNftPackRaritiesData
): Promise<ServiceResponse<string>> {
  try {
    const api = await getAPIClient();
    const res = await api.post(
      `admin/nft-pack/${data.uuid}/rarity-proportions`,
      { proportions: data.proportions }
    );
    return {
      data: res.data,
      error: null,
    };
  } catch (e) {
    return {
      data: null,
      error: getMessageFromError(e),
    };
  }
}
