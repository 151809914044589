import AssetCollectionDetails from 'src/components/modules/GameMakerAssetCollections/view/Details';
import Page from 'src/template/components/Page';

export default function GameMakerAssetCollectionPage() {
  return (
    <Page title="Asset Collection">
      <AssetCollectionDetails />
    </Page>
  );
}
