import { Submission } from 'src/lib/types/submission';
import useSWR, { SWRResponse } from 'swr';

export const fetchSubmissionDetails = (
  uuid: string
): SWRResponse<Submission> => {
  const response = useSWR(`admin/submission-form/${uuid}`);

  return response;
};
