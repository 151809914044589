import { DeepPartial } from 'react-hook-form';
import { GameMakerAssetCollection } from 'src/lib/types/gameMakerAsset';
import { parseSearchParams } from 'src/lib/utils/parseSearchParams';
import useSWR, { SWRResponse } from 'swr';

export type PossibleParams = {
  page: number | string;
  size: number | string;
  order: Record<string, string>;
  filter: any;
};

type FetchAssetCollectionsResponse = {
  meta: {
    totalCount: number;
  };
  body: GameMakerAssetCollection[];
};

export const fetchAssetCollections = (
  filter?: DeepPartial<PossibleParams>,
  shouldFetch = true
): SWRResponse<FetchAssetCollectionsResponse> => {
  const parsedParams = parseSearchParams(filter ?? {});
  const response = useSWR<FetchAssetCollectionsResponse, Error>(
    shouldFetch ? `admin/game-maker-asset-collection?${parsedParams}` : null
  );

  return response;
};
