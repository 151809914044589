import useNotify from 'src/lib/hooks/useNotify';
import useModalStore from 'src/lib/stores/useModalStore';
import { ReviewSubmisisonFormValues } from '../../components/Forms/ReviewSubmisison/types';
import { SubmissionEmitter } from '../../events/SubmissionEmitter';
import { reviewSubmission } from '../../services/reviewSubmission';

export default function useReviewSubmission(submissionUuid: string) {
  const closeModal = useModalStore((state) => state.closeModal);
  const notify = useNotify();

  const handleReviewSubmission = async (values: ReviewSubmisisonFormValues) => {
    const { error, data } = await reviewSubmission(submissionUuid, {
      reviewerComment: values.reviewerComment,
      status: values.status,
    });
    if (error) {
      notify(error, { variant: 'error' });
      closeModal();
    } else if (data) {
      notify('Review Created!', { variant: 'success' });
      closeModal();

      SubmissionEmitter.emit('SubmissionUpdated', null);
    }
  };

  return {
    handleReviewSubmission,
  };
}
