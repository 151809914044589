import { ContentCopy } from '@mui/icons-material';
import { Checkbox, MenuItem } from '@mui/material';
import { GridSortDirection } from '@mui/x-data-grid';
import CopyToClipboard from 'react-copy-to-clipboard';
import MagicTable from 'src/components/shared/MagicTable/MagicTable';
import MoreMenu from 'src/components/shared/MoreMenu';
import {
  GameStatistic,
  ParsedGameStatistic,
} from 'src/lib/types/gameStatistic';
import { StatisticToAdd } from '../Forms/StatisticGroup/types';

interface AddStatisticsTableProps {
  data: GameStatistic[];
  isLoading: boolean;
  page: number;
  size: number;
  sortBy: string;
  order: GridSortDirection;
  setPage: (page: number) => void;
  setRowsPerPage: (size: number) => void;
  setOrder: (order: GridSortDirection) => void;
  setSortBy: (sortBy: string) => void;

  selectedStatistics: StatisticToAdd[];
  handleAddStatistic: (statistic: ParsedGameStatistic) => void;
  handleRemoveStatistic: (uuid: string) => void;
}

export default function AddStatisticsTable({
  data,
  isLoading,
  page,
  size,
  sortBy,
  order,
  setPage,
  setRowsPerPage,
  setOrder,
  setSortBy,

  selectedStatistics,
  handleAddStatistic,
  handleRemoveStatistic,
}: AddStatisticsTableProps): JSX.Element {
  return (
    <MagicTable
      rows={data.map((nft) => nft)}
      hideNumberOfRows
      getRowId={(r) => r.uuid}
      loading={isLoading}
      paginationMode="client"
      pageSize={size}
      page={page}
      onPageChange={(newPage) => {
        setPage(newPage);
      }}
      onPageSizeChange={(newSize) => {
        setRowsPerPage(newSize);
      }}
      sortModel={[{ field: sortBy, sort: order }]}
      onSortModelChange={(model) => {
        const currentModel = model[0];
        if (currentModel) {
          setSortBy(currentModel.field);
          if (currentModel.sort) setOrder(currentModel.sort);
        } else {
          setOrder('asc');
          setSortBy('');
        }
      }}
      columns={[
        {
          field: 'checkbox',
          headerName: '',
          sortable: false,
          flex: 0,
          renderCell: ({ row }) => (
            <Checkbox
              checked={selectedStatistics.some(
                (statistic) => statistic.uuid === row.uuid
              )}
              onChange={() => {
                if (
                  selectedStatistics.some(
                    (statistic) => statistic.uuid === row.uuid
                  )
                ) {
                  handleRemoveStatistic(row.uuid);
                } else {
                  handleAddStatistic(row);
                }
              }}
            />
          ),
        },
        {
          field: 'assetName',
          headerName: 'Asset',
          sortable: true,
          valueFormatter({ value }) {
            return value.name;
          },
        },
        {
          field: 'assetType',
          headerName: 'Asset Type',
          sortable: true,
          valueFormatter({ value }) {
            return value.name;
          },
        },
        {
          field: 'assetAction',
          headerName: 'Action',
          sortable: true,
          valueFormatter({ value }) {
            return value.name;
          },
        },
        {
          field: 'actions',
          headerName: 'Actions',
          align: 'center',
          sortable: false,
          flex: 0,
          renderCell({ row }) {
            return (
              <MoreMenu>
                <CopyToClipboard text={row.uuid}>
                  <MenuItem aria-label="copy feed uuid">
                    <ContentCopy />
                    Copy UUID
                  </MenuItem>
                </CopyToClipboard>
              </MoreMenu>
            );
          },
        },
      ]}
    />
  );
}
