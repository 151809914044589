import { debounce } from 'lodash';
import { CommonDateStrings } from 'src/lib/constants/CommonDates';
import useURLSearchParams from 'src/lib/hooks/useURLSearchParams';
import { fetchConsumablesReport } from '../../../services/fetchConsumablesSalesReport';

type ConsumablesSalesViewProps = {
  startDate: string;
  endDate: string;
  country: string | null;
};

export function useConsumablesSalesView(props: ConsumablesSalesViewProps) {
  let searchParams;
  if (props.country) {
    searchParams = {
      startDate: props.startDate,
      endDate: props.endDate,
      country: props.country,
    };
  } else {
    searchParams = {
      startDate: props.startDate,
      endDate: props.endDate,
    };
  }
  const { allSearchParams, removeParam, addParam } =
    useURLSearchParams(searchParams);

  const { status, startDate, endDate, country } = allSearchParams;

  const { data, error, isLoading } = fetchConsumablesReport(
    getPossibleParams(allSearchParams)
  );

  function onChangeFilter(field: string, value: string | null | undefined) {
    if (!value) removeParam(field);
    else addParam(field, value);
  }

  const debouncedOnChangeFilter = debounce(onChangeFilter, 600);

  return {
    data,
    error,
    isLoading,
    onChangeFilter: debouncedOnChangeFilter,
    filter: {
      startDate,
      endDate,
      status,
      country,
    },
  };
}

function getPossibleParams(
  allSearchParams: Record<string, string | undefined>
): Parameters<typeof fetchConsumablesReport>[0] {
  const { startDate, endDate, country } = allSearchParams;
  return {
    startDate: startDate ?? CommonDateStrings.sevenDaysAgo.dateTime,
    endDate: endDate ?? CommonDateStrings.today.dateTime,
    country,
  };
}
