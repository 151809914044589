import {
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  Stack,
} from '@mui/material';
import { useState } from 'react';
import useNftsGridFilter from 'src/lib/hooks/useNftsGridFilter';
import { BracketFilterParams } from 'src/lib/types/bracketFilterParams';

const SORT_BY_OPTIONS = {
  newest: 'Newest',
  oldest: 'Oldest',
  priceDesc: 'Price: High-Low',
  priceAsc: 'Price: Low-High',
  editionsAsc: 'Editions Asc',
  editionsDesc: 'Editions Desc',
};

const getSortByFilter = (value: string): Partial<BracketFilterParams> => {
  switch (value) {
    case 'newest':
      return { orderBy: 'createdAt', order: 'desc' };
    case 'oldest':
      return { orderBy: 'createdAt', order: 'asc' };
    case 'priceDesc':
      return { orderBy: 'salesPrice', order: 'desc' };
    case 'priceAsc':
      return { orderBy: 'salesPrice', order: 'asc' };
    case 'editionsAsc':
      return { orderBy: 'salesLimit', order: 'asc' };
    case 'editionsDesc':
      return { orderBy: 'salesLimit', order: 'desc' };
    default:
      return { orderBy: 'createdAt', order: 'desc' };
  }
};

const getFilterFromSortBy = (filter: any) => {
  if (filter.orderBy === 'createdAt' && filter.order === 'desc') {
    return 'newest';
  }

  if (filter.orderBy === 'createdAt' && filter.order === 'asc') {
    return 'oldest';
  }

  if (filter.orderBy === 'salesPrice' && filter.order === 'desc') {
    return 'priceDesc';
  }

  return 'priceAsc';
};

export default function NFTFilter() {
  const filter = useNftsGridFilter((state) => state.filter);
  const setFilter = useNftsGridFilter((state) => state.setFilter);

  const [sortBy, setSortBy] = useState(getFilterFromSortBy(filter));

  const onSortBy = (e: any) => {
    setSortBy(e.target.value);

    setFilter({ ...getSortByFilter(e.target.value) });
  };

  return (
    <Stack direction="row" alignItems="right" spacing={1}>
      <FormControl fullWidth>
        <InputLabel id="sort-by-label">Sort by</InputLabel>
        <Select
          labelId="sort-by-label"
          id="sort-by"
          value={sortBy}
          label="Sort by"
          onChange={onSortBy}
        >
          {Object.entries(SORT_BY_OPTIONS).map(([value, label]) => (
            <MenuItem key={value} value={value}>
              {label}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    </Stack>
  );
}
