import ConfirmationDialogRaw from 'src/components/shared/Dialog/ConfirmationDialog';
import useModalStore from 'src/lib/stores/useModalStore';
import { NftPackStatus } from 'src/lib/types/nftPack';
import useChangeNftPackStatus from './useView';

type Props = {
  uuid: string;
  status: NftPackStatus;
};
export default function ChangeNftPackStatusView({
  uuid,
  status,
}: Props): JSX.Element {
  const { handleChangeNftPackStatus } = useChangeNftPackStatus();
  const closeModal = useModalStore((state) => state.closeModal);

  return (
    <ConfirmationDialogRaw
      onClose={closeModal}
      open
      title={`Change this pack status to ${status}`}
      onConfirm={async () => {
        await handleChangeNftPackStatus(uuid, status);
      }}
    >
      Are you sure you want change Nft Pack status?
    </ConfirmationDialogRaw>
  );
}
