import { Card, CardContent, CardHeader, Stack } from '@mui/material';
import { Controller } from 'react-hook-form';
import Button from 'src/components/shared/Buttons/CustomButton';
import { ObjectLinkComponent } from 'src/components/shared/Form/useObjectLinkSearch';
import ControllerField from 'src/components/shared/Inputs/ControllerField';
import Modal from 'src/components/shared/Modal';
import UploadContent, {
  UploadTypes,
} from 'src/components/shared/UploadContent';
import * as yup from 'yup';
import { UpdateChannelFormValues } from './types';
import useUpdateChannelFormView from './useView';

const validationSchema = yup.object().shape({
  name: yup.string().required('Name is required'),
  description: yup.string().required('Description is required'),
});

type Props = {
  defaultValues: UpdateChannelFormValues;
  onSubmit: (values: UpdateChannelFormValues) => Promise<void>;
  title: string;
};

export default function UpdateChannelForm({
  defaultValues,
  title,
  onSubmit,
}: Props): JSX.Element {
  const {
    form: {
      control,
      handleSubmit,
      formState: { isSubmitting },
      watch,
      setValue,
    },
    handleCloseModal,
    objectLinkProps,
    onUpdateImage,
    isUpdatingBackgroundImage,
  } = useUpdateChannelFormView({ defaultValues });

  return (
    <Modal open onClose={handleCloseModal}>
      <Card
        sx={{
          width: '700px',
          display: 'flex',
          flexDirection: 'column',
        }}
      >
        <CardHeader title={title} />
        <CardContent>
          <form onSubmit={handleSubmit(onSubmit)}>
            <Stack>
              <Stack mb={2} spacing={1.5}>
                <ControllerField
                  control={control}
                  name="name"
                  label="Name"
                  fullWidth
                />
                <ControllerField
                  control={control}
                  name="description"
                  label="Description"
                  multiline
                  minRows={3}
                  fullWidth
                />
                <Controller
                  name="backgroundImageUrl"
                  control={control}
                  render={({ field }) => (
                    <UploadContent
                      {...field}
                      type={UploadTypes.IMAGE}
                      content={field.value ?? ''}
                      onEdit={(file) => {
                        onUpdateImage(file);
                      }}
                      onRemove={() => {
                        setValue('backgroundImageUrl', '');
                      }}
                      showEditButton
                      sx={{
                        maxWidth: '300px',
                        maxHeight: '300px',
                        minHeight: '30px',
                        minWidth: '100px',
                      }}
                      isLoading={isUpdatingBackgroundImage}
                    />
                  )}
                />
                <ObjectLinkComponent {...objectLinkProps} />
              </Stack>
              <Stack
                direction="row"
                justifyContent="space-between"
                spacing={1}
                mt={2}
              >
                <Button color="error" onClick={handleCloseModal} size="large">
                  Cancel
                </Button>
                <Button
                  variant="contained"
                  color="secondary"
                  type="submit"
                  loading={isSubmitting}
                  size="large"
                >
                  Save
                </Button>
              </Stack>
            </Stack>
          </form>
        </CardContent>
      </Card>
    </Modal>
  );
}
