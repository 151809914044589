import { Fetch } from 'src/lib/types/fetch';
import useSWR from 'swr';
import { RankingFilterOptions } from '../components/Rankings/BuyerAndSellerRankTable/types';

function parseItem(item: string[] | undefined) {
  if (!item) {
    return [];
  }

  return item
    .filter((option) => option && Number.isNaN(Number(option)))
    .sort((a, b) => a.localeCompare(b));
}

function parseData(
  data: RankingFilterOptions | undefined
): RankingFilterOptions {
  if (!data) {
    return {
      countries: [],
      langs: [],
    };
  }

  return {
    countries: parseItem(data.countries),
    langs: parseItem(data.langs),
  };
}

export const fetchRankingFilterOptions = (): Fetch<RankingFilterOptions> => {
  const { data, error, mutate } = useSWR<RankingFilterOptions, Error>(
    `admin/users/ranking/filter-options`
  );

  return {
    data: parseData(data),
    loading: !error && !data,
    error: error?.message,
    mutate,
  };
};
