export function setStartDateHours(date = new Date()): string {
  const resultDate = new Date(date);
  resultDate.setHours(0);
  resultDate.setMinutes(0);
  resultDate.setSeconds(0);

  return resultDate.toISOString();
}

export function setEndDateHours(date = new Date()): string {
  const resultDate = new Date(date);
  resultDate.setHours(23);
  resultDate.setMinutes(59);
  resultDate.setSeconds(59);

  return resultDate.toISOString();
}
