import { Box } from '@mui/material';
import { ReactNode, useState } from 'react';
import { TableMoreMenu } from 'src/template/components/table';

interface MoreMenuProps {
  children: ReactNode;
  renderOpenButton?: ReactNode;
}

export default function MoreMenu({
  children,
  renderOpenButton,
}: MoreMenuProps) {
  const [openMenu, setOpenMenuActions] = useState<HTMLElement | null>(null);

  const handleOpenMenu = (event: React.MouseEvent<HTMLElement>) => {
    setOpenMenuActions(event.currentTarget);
    event.stopPropagation();
  };

  const handleCloseMenu = (event: React.MouseEvent<HTMLElement>) => {
    setOpenMenuActions(null);
    event.stopPropagation();
  };

  return (
    <TableMoreMenu
      open={openMenu}
      onOpen={handleOpenMenu}
      onClose={handleCloseMenu}
      actions={<Box onClick={handleCloseMenu}>{children}</Box>}
      renderOpenButton={renderOpenButton}
    />
  );
}
