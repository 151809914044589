import GameMakerAssetCollections from 'src/components/modules/GameMakerAssetCollections/view/List';
import Page from 'src/template/components/Page';

export default function GameMakerAssetCollectionsPage() {
  return (
    <Page title="Asset Collections">
      <GameMakerAssetCollections />
    </Page>
  );
}
