import { getDefaultObjectLinksOptions } from 'src/components/shared/Form/useObjectLinkSearch';
import { ClubWithObjectLinks } from 'src/lib/types/clubWithAdminAnalytics';
import getUserName from 'src/lib/utils/getUserName';
import ClubForm from '../../components/Forms/Club';
import useUpdateClubView from './useView';

type Props = {
  club: ClubWithObjectLinks;
};

export default function UpdateClubView({ club }: Props): JSX.Element {
  const { handleUpdateClub } = useUpdateClubView();
  return (
    <ClubForm
      defaultValues={{
        clubUuid: club.uuid,
        name: club.name,
        description: club.description,
        joinMethod: club.joinMethod,
        coverFileUrl: club.coverFileUrl,
        landingPageUrl: club.landingPageUrl,
        photoFileUrl: club.photoFileUrl,
        games:
          club?.games?.map((game) => ({
            uuid: game.uuid,
            name: game.name,
          })) ?? [],
        collections:
          club.collections?.map((collection) => ({
            uuid: collection.uuid,
            name: collection.name,
            coverFileUrl: collection.coverFileUrl,
            creatorName: getUserName(collection.user),
          })) ?? [],
        hasGame: club.games?.length > 0,
        createdByUserUuid: club.createdByUserUuid,
        objectLinks: getDefaultObjectLinksOptions(club.objectLinks),
        objectLinksTempSearch: '',
      }}
      defaultUser={club.createdByUser}
      onSubmit={async (values) => {
        await handleUpdateClub(club.uuid, values);
      }}
      title="Update Club"
    />
  );
}
