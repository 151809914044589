import { format } from 'date-fns';
import { getMessageFromError } from 'src/lib/utils/error';
import objectEntries from 'src/lib/utils/objectEntries';
import useSWR, { SWRConfiguration, SWRResponse } from 'swr';
import {
  BlockchainHealth,
  BlockchainHealthWorker,
  ParsedBlockchainHealth,
} from '../types/BlockchainHealth';

const blockchainWorkerLabel: Record<BlockchainHealthWorker, string> = {
  deploy: 'Deploy',
  marketplaceBuy: 'Buy on Marketplace',
  makertplaceBuy: 'Buy on Marketplace',
  mintNft: 'Mint NFT',
  transferNft: 'Transfer NFT',
  verifyDeploy: 'Deploy Verifier',
  verifyMarketplaceBuy: 'Marketplace Buy Verifier',
  verifyMint: 'Mint Verifier',
  verifyTransfer: 'Transfer Verifier',
};

function parseData(
  data: BlockchainHealth | undefined
): ParsedBlockchainHealth | undefined {
  if (!data) return undefined;

  return objectEntries(data).reduce<ParsedBlockchainHealth>(
    (acc, [blockchain, item]) => {
      acc[blockchain] = objectEntries(item).map(([key, value]) => ({
        isWorking: value?.work ?? false,
        lastCheck: value?.lastCheck
          ? format(new Date(value.lastCheck), 'dd/MM/yyyy hh:MM')
          : '--',
        error: value?.error,
        name: key,
        parsedName: blockchainWorkerLabel[key],
      }));
      return acc;
    },
    {} as ParsedBlockchainHealth
  );
}

export function fetchBlockchainServiceHealth(
  config?: SWRConfiguration
): SWRResponse<ParsedBlockchainHealth> {
  const { data, error, ...response } = useSWR(
    `admin/health/blockchain-service`,
    null,
    config
  );
  return {
    ...response,
    data: parseData(data),
    error: error ? getMessageFromError(error) : undefined,
  };
}
