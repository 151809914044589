import useRegionsTable from 'src/components/modules/Regions/RegionsTable/useRegionsTable';
import useSWR, { mutate } from 'swr';
import { PaginatedResponse } from '../types/PaginatedResponse';
import { BracketFilterParams } from '../types/bracketFilterParams';
import { Fetch } from '../types/fetch';
import { LtvRegion } from '../types/ltvRegion';
import { getMessageFromError } from '../utils/error';
import generateSearchRequest from '../utils/generateSearchRequest';
import getAPIClient from './api/axios.config';

const mutateRegionList = () => {
  const { filter } = useRegionsTable.getState();

  mutate(`admin/regions/${generateSearchRequest(filter)}`);
};

/**
 * Fethes a list of LTV regions
 * @returns {Fetch<LtvRegion>}
 */
export const fetchLtvRegions = (
  filter: BracketFilterParams
  // type: 'all' | 'ios' | 'android' | 'web' = 'all'
): Fetch<LtvRegion[]> => {
  const { data, error } = useSWR<PaginatedResponse<LtvRegion[]>, Error>(
    `admin/regions/${generateSearchRequest(filter)}`
  );

  return {
    data: data?.body,
    loading: !error && !data,
    total: data?.meta.totalCount,
    error: error?.message,
  };
};

export const createRegion = async (region: Partial<LtvRegion>) => {
  try {
    const api = await getAPIClient();
    const res = await api.post('admin/regions', {
      ...region,
      iosStickyValue: Number(region.iosStickyValue) || null,
      webStickyValue: Number(region.webStickyValue) || null,
      androidStickyValue: Number(region.androidStickyValue) || null,
    });

    mutateRegionList();
    return {
      data: res.data,
      error: null,
    };
  } catch (e) {
    return {
      data: null,
      error: getMessageFromError(e),
    };
  }
};

export const updateRegion = async (region: Partial<LtvRegion>) => {
  try {
    const api = await getAPIClient();

    const res = await api.put(`admin/regions/${region.uuid}`, {
      name: region.name,
      code: region.code,
      iosProductFullId: region.iosProductFullId,
      webProductFullId: region.webProductFullId,
      androidProductFullId: region.androidProductFullId,
      iosStickyValue: Number(region.iosStickyValue) || null,
      webStickyValue: Number(region.webStickyValue) || null,
      androidStickyValue: Number(region.androidStickyValue) || null,
    });

    mutateRegionList();
    return {
      data: res.data,
      error: null,
    };
  } catch (e) {
    return {
      data: null,
      error: getMessageFromError(e),
    };
  }
};

export const deleteRegion = async (uuid: string) => {
  const api = await getAPIClient();
  const res = await api.delete(`admin/regions/${uuid}`);

  mutateRegionList();
  return res;
};

export const uploadRegionsCSV = async (regions: Partial<LtvRegion>[]) => {
  const api = await getAPIClient();
  const res = await api
    .post('admin/regions/csv', { regions })
    .catch((e) => e.response);

  mutateRegionList();
  return {
    error: res.data.error,
    created: res.data.created,
    updated: res.data.updated,
  };
};
