import { Card, CardContent, CardHeader, Stack, TextField } from '@mui/material';
import { capitalCase } from 'change-case';
import { Controller } from 'react-hook-form';
import Button from 'src/components/shared/Buttons/CustomButton';
import FilterForm from 'src/components/shared/FilterForm';
import Flex from 'src/components/shared/Flex';
import ControllerField from 'src/components/shared/Inputs/ControllerField';
import Modal from 'src/components/shared/Modal';
import SelectOption from 'src/components/shared/SelectOption';
import { CommonDateStrings } from 'src/lib/constants/CommonDates';
import useModalStore from 'src/lib/stores/useModalStore';
import { IncludeExcludeOptions } from 'src/lib/utils/includeExcludeFilterUtils';
import objectEntries from 'src/lib/utils/objectEntries';
import { fetchRankingFilterOptions } from '../../../services/fetchRankingFilterOptions';
import { UserTypeLabel } from '../../../types/UserType';
import ResetFiltersButton from '../ResetFiltersButton';
import {
  NFTOriginFilterValue,
  RankByFilterValue,
  RankFilterFormValues,
} from './types';

type Props = {
  rankFields: RankByFilterValue[];
  defaultFilterValues: RankFilterFormValues;
  initialValues?: Partial<RankFilterFormValues>;
};
export default function UserRankFilter({
  rankFields,
  initialValues,
  defaultFilterValues,
}: Props): JSX.Element {
  const closeModal = useModalStore((state) => state.closeModal);
  const { data: filterOptions } = fetchRankingFilterOptions();

  function handleCancel(): void {
    closeModal();
  }

  return (
    <Modal open onClose={handleCancel}>
      <FilterForm<RankFilterFormValues>
        defaultValues={initialValues}
        onSubmit={handleCancel}
      >
        {({ control, reset }) => (
          <Card sx={{ minWidth: 500 }}>
            <CardHeader
              title="Filter"
              action={
                <ResetFiltersButton
                  onClick={() => {
                    reset(defaultFilterValues);
                  }}
                />
              }
            />
            <CardContent>
              <Stack spacing={2} direction="column">
                <Flex gap={1}>
                  <ControllerField
                    control={control}
                    name="FreePurchases"
                    label="Free Purchases"
                    fullWidth
                    select
                  >
                    {IncludeExcludeOptions.map(({ label, value }) => (
                      <SelectOption key={value} value={value}>
                        {label}
                      </SelectOption>
                    ))}
                  </ControllerField>

                  <ControllerField
                    control={control}
                    name="NftPack"
                    label="NFT Pack"
                    fullWidth
                    select
                  >
                    {IncludeExcludeOptions.map(({ label, value }) => (
                      <SelectOption key={value} value={value}>
                        {label}
                      </SelectOption>
                    ))}
                  </ControllerField>

                  <ControllerField
                    control={control}
                    name="InactiveUsers"
                    label="Inactive Users"
                    fullWidth
                    select
                  >
                    {IncludeExcludeOptions.map(({ label, value }) => (
                      <SelectOption key={value} value={value}>
                        {label}
                      </SelectOption>
                    ))}
                  </ControllerField>
                </Flex>
                <ControllerField
                  control={control}
                  name="rankBy"
                  select
                  label="Rank By"
                >
                  {rankFields.map((option) => (
                    <SelectOption key={option} value={option}>
                      {capitalCase(option)}
                    </SelectOption>
                  ))}
                </ControllerField>
                <Flex gap={2}>
                  <Controller
                    name="country"
                    control={control}
                    render={({ field: { value, ...field } }) => (
                      <TextField
                        select
                        label="Country"
                        fullWidth
                        value={value || ''}
                        {...field}
                      >
                        {filterOptions?.countries.map((option) => (
                          <SelectOption key={option} value={option}>
                            {option}
                          </SelectOption>
                        ))}
                      </TextField>
                    )}
                  />
                  <Controller
                    name="lang"
                    control={control}
                    render={({ field: { value, ...field } }) => (
                      <TextField
                        select
                        label="Language"
                        fullWidth
                        value={value || ''}
                        {...field}
                      >
                        {filterOptions?.langs.map((option) => (
                          <SelectOption key={option} value={option}>
                            {option}
                          </SelectOption>
                        ))}
                      </TextField>
                    )}
                  />
                </Flex>
                <Flex gap={2}>
                  <ControllerField
                    control={control}
                    name="startDate"
                    label="Start Date"
                    type="date"
                    InputLabelProps={{ shrink: true }}
                    inputProps={{
                      min: CommonDateStrings.firstStickyOrderBookMatch.date,
                      max: CommonDateStrings.today.date,
                    }}
                    fullWidth
                  />
                  <ControllerField
                    control={control}
                    name="endDate"
                    label="End Date"
                    type="date"
                    InputLabelProps={{ shrink: true }}
                    inputProps={{
                      min: CommonDateStrings.firstStickyOrderBookMatch.date,
                      max: CommonDateStrings.today.date,
                    }}
                    fullWidth
                  />
                </Flex>
                <Flex gap={2}>
                  <ControllerField
                    control={control}
                    name="userType"
                    label="User Type"
                    fullWidth
                    select
                  >
                    <SelectOption value="all">All</SelectOption>
                    {objectEntries(UserTypeLabel).map(([option, label]) => (
                      <SelectOption key={option} value={option}>
                        {label}
                      </SelectOption>
                    ))}
                  </ControllerField>
                  <ControllerField
                    control={control}
                    name="nftOrigin"
                    label="NFT Origin"
                    fullWidth
                    select
                  >
                    {objectEntries(nftOriginOptions).map(([option, label]) => (
                      <SelectOption key={option} value={option}>
                        {label}
                      </SelectOption>
                    ))}
                  </ControllerField>
                </Flex>
                <Flex justifyContent="space-between">
                  <Button onClick={handleCancel} variant="text" color="error">
                    Cancel
                  </Button>
                  <Button type="submit" variant="contained" color="info">
                    Apply
                  </Button>
                </Flex>
              </Stack>
            </CardContent>
          </Card>
        )}
      </FilterForm>
    </Modal>
  );
}

const nftOriginOptions: Record<NFTOriginFilterValue, string> = {
  all: 'All',
  created: 'Created',
  imported: 'Imported',
  purchased: 'Purchased',
  transferred: 'Transferred',
};
