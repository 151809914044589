import { Card, CardContent, Container, Tab, Tabs } from '@mui/material';
import { MODERATED_OBJECT_STATUS_TABS } from 'src/components/modules/Review/utils/utils';
import Flex from 'src/components/shared/Flex';
import HeaderBreadcrumbs from 'src/template/components/HeaderBreadcrumbs';
import ChannelContentTable from '../../components/ChannelContentTable';
import ChannelContentTableHeader from '../../components/ChannelContentTable/header';
import useChannelVideos from './useView';

const STATUS_TABS = MODERATED_OBJECT_STATUS_TABS;

export default function ChannelVideos() {
  const {
    data,
    isLoading,
    params,
    onChangeChannelContentStatus,
    currentTab,
    onChangeTab,
  } = useChannelVideos();

  return (
    <Container maxWidth="xl">
      <HeaderBreadcrumbs
        heading="Channel Videos"
        links={[{ name: 'Channel Videos' }]}
      />

      <Card>
        <Flex sx={{ px: 2, bgcolor: 'background.neutral' }}>
          <Tabs
            allowScrollButtonsMobile
            variant="scrollable"
            scrollButtons="auto"
            value={currentTab}
            onChange={onChangeTab}
          >
            {STATUS_TABS.map((tab) => (
              <Tab
                disableRipple
                key={tab.value}
                label={tab.label}
                value={tab.value}
              />
            ))}
          </Tabs>
        </Flex>

        <CardContent>
          <ChannelContentTableHeader params={params} />
          <ChannelContentTable
            data={data}
            isLoading={isLoading}
            params={params}
            onChangeChannelContentStatus={onChangeChannelContentStatus}
          />
        </CardContent>
      </Card>
    </Container>
  );
}
