import { Container } from '@mui/material';
import IapTransactions from 'src/components/modules/IapTransactions/IapTransactions';
import Page from 'src/template/components/Page';

export default function IapTransactionsPage() {
  return (
    <Page title="Iap Transactions">
      <Container maxWidth="xl">
        <IapTransactions />
      </Container>
    </Page>
  );
}
