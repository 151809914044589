import { Fade, Modal as MuiModal, Stack } from '@mui/material';

export interface ModalProps {
  open: boolean;
  onClose: () => void;
  children?: React.ReactNode;
}

export default function Modal({ open, onClose, children }: ModalProps) {
  return (
    <MuiModal
      open={open}
      onClose={onClose}
      closeAfterTransition
      BackdropProps={{
        timeout: 500,
      }}
      sx={{
        position: 'fixed',
        height: '100%',
        width: '100%',
        display: 'grid',
        overflow: 'scroll',
      }}
    >
      <Fade in={open}>
        <Stack
          alignItems="center"
          justifyContent="center"
          width="fit-content"
          m="auto"
        >
          {children}
        </Stack>
      </Fade>
    </MuiModal>
  );
}
