import { Container } from '@mui/material';
import NFTSForPacks from 'src/components/modules/NFT/view/List';
import Page from 'src/template/components/Page';

export default function NFTSForPackPage() {
  return (
    <Page title="Collections">
      <Container maxWidth="xl">
        <NFTSForPacks />
      </Container>
    </Page>
  );
}
