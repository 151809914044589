import { IncludeExcludeAll } from 'src/lib/utils/includeExcludeFilterUtils';
import { RankBy } from '../../../services/fetchBalanceRanking';
import { UserType } from '../../../types/UserType';

export const RankByLabel: Record<RankBy, string> = {
  availableBonus: 'Bonus Coins Available',
  availableBoughtCoins: 'Bought Coins Available',
  availableEarnings: 'Earned Coins Available',
  balance: 'Balance',
};
export type RankFilterFormValues = {
  rankBy: RankBy;
  lang: string;
  country: string;
  InactiveUsers: IncludeExcludeAll;
  userType: UserType | 'all';
};

export type Params = 'order' | 'page' | 'size' | keyof RankFilterFormValues;
