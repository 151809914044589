import useSWR, { mutate as SwrMutate } from 'swr';
import { PaginatedResponse } from '../types/PaginatedResponse';
import { PaymentInfo } from '../types/PaymentInfo';
import { BracketFilterParams } from '../types/bracketFilterParams';
import { Fetch } from '../types/fetch';
import {
  PaymentRequest,
  PaymentRequestStatus,
  PaymentRequestValues,
  WithPaymentRequestHistory,
} from '../types/paymentRequest';
import { FetchPayoutEligibilityResponse } from '../types/payoutEligibilityRules';
import { User } from '../types/users';
import { WithCryptoWallets } from '../types/wallet/cryptoWallet';
import { PaymentDetailsWallet } from '../types/wallet/wallets';
import { getMessageFromError } from '../utils/error';
import formatStickyCoinValue from '../utils/formatStickyCoinValue';
import generateSearchRequest from '../utils/generateSearchRequest';
import getAPIClient from './api/axios.config';

export const fetchPaymentRequests = (
  filter: BracketFilterParams,
  status: PaymentRequestStatus | 'all'
): Fetch<PaymentRequest[]> => {
  const statusFilter = status === 'all' ? '' : `&filter[status][eq]=${status}`;

  const { data, error } = useSWR<PaginatedResponse<PaymentRequest[]>, Error>(
    `admin/payment-requests/${generateSearchRequest(filter)}${statusFilter}`
  );

  return {
    data: data?.body,
    total: data?.meta.totalCount,
    loading: !error && !data,
    error: error?.message,
  };
};

export const fetchPaymentRequest = (
  uuid: string
): Fetch<WithPaymentRequestHistory<PaymentRequest>> => {
  const { data, error, mutate } = useSWR<
    PaginatedResponse<WithPaymentRequestHistory<PaymentRequest>>,
    Error
  >(`admin/payment-requests/${uuid}`);

  const parseData = (
    paymentRequest: WithPaymentRequestHistory<PaymentRequest>
  ): WithPaymentRequestHistory<PaymentRequest> => ({
    ...paymentRequest,
    amount: formatStickyCoinValue(paymentRequest.amount),
  });

  return {
    data: data?.body ? parseData(data.body) : undefined,
    loading: !error && !data,
    error: getMessageFromError(error),
    mutate,
  };
};

type PaymentRequestDetailsResponse = {
  paymentInfo: PaymentInfo;
  user: WithCryptoWallets<User>;
  wallet: PaymentDetailsWallet;
};

export const fetchPaymentRequestDetails = (
  uuid: string
): Fetch<PaymentRequestDetailsResponse> => {
  const { data, error } = useSWR(`admin/payment-requests/${uuid}/details`);

  return {
    data: data?.body,
    loading: !error && !data,
    error: error?.message,
  };
};

export const fetchPayoutEligibility = (
  userId: string
): Fetch<FetchPayoutEligibilityResponse> => {
  const { data, error } = useSWR<FetchPayoutEligibilityResponse, Error>(
    `admin/users/${userId}/payout-eligibility`
  );

  return {
    data,
    loading: !error && !data,
    error: error?.message,
  };
};

type Data = {
  status: PaymentRequestStatus;
  rejectedReason?: string | null;
  updateNote?: string | null;
};
export const updatePaymentRequestStatus = async (uuid: string, data: Data) => {
  try {
    const api = await getAPIClient();

    const res = await api.put(`admin/payment-requests/${uuid}`, data);

    SwrMutate(`admin/payment-requests/${uuid}`);
    return {
      data: res.data,
      error: null,
    };
  } catch (error) {
    return {
      data: null,
      error: getMessageFromError(error),
    };
  }
};

export const updatePaymentRequestValues = async (
  uuid: string,
  data: PaymentRequestValues
) => {
  const api = await getAPIClient();

  const res = await api.put(`admin/payment-requests/${uuid}/values`, data);

  SwrMutate(`admin/payment-requests/${uuid}`, res.data);
  return res;
};
