import { Card, CardContent, Stack, Typography } from '@mui/material';
import { grey } from '@mui/material/colors';
import { useEffect, useState } from 'react';
import Button from 'src/components/shared/Buttons/CustomButton';
import Modal from 'src/components/shared/Modal';
import { HomeFeed } from 'src/lib/types/homeFeed';
import { fetchTypeOptions } from '../../../../../lib/services/homeFeeds';
import { formatDate } from '../../../../../lib/utils/formatters';
import FeedDetailsBox from './FeedDetailsBox';
import FiltersCardSelect from './FiltersCardSelect';
import { FeedDetails, KeyValueFeedFilter } from './types';
import {
  getFeedDetailsFromFeed,
  getFiltersObjFromKeyValueFiltersList,
  getKeyValueFiltersListFromFiltersObj,
} from './utils';

interface EditFeedModalProps {
  open: boolean;
  feedToEdit: HomeFeed | null;
  onSave: (feed: HomeFeed | null) => Promise<boolean>;
  onCancel: () => void;
}

function EditFeedModal({
  open,
  feedToEdit,
  onSave,
  onCancel,
}: EditFeedModalProps) {
  const [feedDetails, setFeedDetails] = useState<FeedDetails | null>(null);
  const [keyValueFilters, setKeyValueFilters] = useState<KeyValueFeedFilter[]>([
    {},
  ]);

  const { data: options } = fetchTypeOptions();
  const typeOptions = options?.map((opt) => opt.type) || [];
  const attributeOptions =
    options?.find((opt) => feedDetails?.type && opt.type === feedDetails.type)
      ?.attributes || [];

  const isFilterType = !!attributeOptions?.length;
  const isCreation = !feedToEdit?.uuid;

  const changeFeedDetails = (newDetails: Partial<FeedDetails>) => {
    setFeedDetails(
      (d) => ({ ...(d ?? {}), ...(newDetails ?? {}) } as FeedDetails)
    );
  };

  const handleSave = () => {
    const { limit, offset, ...details } = feedDetails || {};
    const obj = getFiltersObjFromKeyValueFiltersList(keyValueFilters);

    const filters = isFilterType ? obj : null;
    const content = isFilterType ? null : obj;

    if (filters) {
      if (limit !== null && limit !== undefined) filters.limit = limit;
      if (offset !== null && offset !== undefined) filters.offset = offset;
    }

    onSave({
      ...(feedToEdit?.uuid ? { uuid: feedToEdit.uuid } : {}),
      ...details,
      filters,
      content,
    } as HomeFeed).then((success) => {
      if (success) {
        setFeedDetails(null);
        setKeyValueFilters([{}]);
      }
    });
  };

  useEffect(() => {
    if (!feedToEdit) {
      setFeedDetails(null);
      setKeyValueFilters([{}]);
      return;
    }
    setFeedDetails(getFeedDetailsFromFeed(feedToEdit));
    if (Object.keys(feedToEdit?.filters || {}).length) {
      const { limit, offset, ...filters } = feedToEdit.filters;
      setKeyValueFilters(getKeyValueFiltersListFromFiltersObj(filters));
    } else if (Object.keys(feedToEdit?.content || {}).length) {
      setKeyValueFilters(
        getKeyValueFiltersListFromFiltersObj(feedToEdit.content)
      );
    }
  }, [feedToEdit?.uuid]);

  useEffect(() => {
    if (feedToEdit?.type && feedDetails?.type === feedToEdit?.type) return;
    setKeyValueFilters([{}]);
  }, [isFilterType]);

  return (
    <Modal open={open} onClose={onCancel}>
      <Card
        sx={{
          bgcolor: '#fafafafa',
          width: '95vw',
          height: '95vh',
        }}
      >
        <Stack gap={3} component={CardContent}>
          <Stack
            direction="row"
            justifyContent="space-between"
            alignItems="center"
            spacing={2}
          >
            <Stack>
              <Typography variant="h4" fontWeight="bold">
                {isCreation ? 'New Home Feed' : 'Home Feed'}
              </Typography>
              {!isCreation && (
                <Typography
                  variant="body2"
                  sx={{
                    whiteSpace: 'nowrap',
                    color: 'text.disabled',
                  }}
                >
                  Created at: {formatDate(feedToEdit?.createdAt)} . Updated at:{' '}
                  {formatDate(feedToEdit?.updatedAt)} . UUID: {feedToEdit?.uuid}
                </Typography>
              )}
            </Stack>
            <Stack direction="row" gap={2}>
              <Button onClick={onCancel} sx={{ gap: 1, color: grey[500] }}>
                cancel
              </Button>
              <Button
                onClick={handleSave}
                type="submit"
                variant="contained"
                color="primary"
                sx={{ gap: 1 }}
              >
                {isCreation ? 'Create' : 'Save'}
              </Button>
            </Stack>
          </Stack>
          <FeedDetailsBox
            isFilterType={isFilterType}
            typeOptions={typeOptions}
            feedDetails={feedDetails}
            onChange={changeFeedDetails}
          />
          <FiltersCardSelect
            isFilterType={isFilterType}
            attributeOptions={attributeOptions}
            keyValueFilters={keyValueFilters}
            setKeyValueFilters={setKeyValueFilters}
          />
        </Stack>
      </Card>
    </Modal>
  );
}
export default EditFeedModal;
