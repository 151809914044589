import { Avatar, Stack } from '@mui/material';
import { Game } from 'src/lib/types/game';
import Routes from 'src/routes/Routes';
import Flex from '../Flex';
import Link from '../Link';

type Props = {
  game?: Pick<Game, 'name' | 'uuid' | 'versions'>;
  linkUrl?: string;
};
export default function MagicTableGameCell({
  game,
  linkUrl,
}: Props): JSX.Element {
  if (!game) {
    return <Flex component="span">empty</Flex>;
  }
  const { name, uuid, versions } = game;
  return (
    <Flex component="span" gap={2}>
      <Avatar
        alt={name ?? 'user profile picture'}
        src={versions?.[0]?.imageUrl ?? ''}
      />
      <Stack>
        <Link
          to={linkUrl ?? Routes.game_center.game.view(uuid)}
          color={name ? 'inherit' : 'GrayText'}
          underline="hover"
        >
          {name}
        </Link>
      </Stack>
    </Flex>
  );
}
