import WalletsConsistencyView from 'src/components/modules/WalletConsistency/view/Wallets';
import Page from 'src/template/components/Page';

export default function WalletConsistencyPage(): JSX.Element {
  return (
    <Page title="Wallet Consistency">
      <WalletsConsistencyView />
    </Page>
  );
}
