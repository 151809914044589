import { Box, Stack, TextField, Typography } from '@mui/material';
import { useEffect, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import Button from 'src/components/shared/Buttons/CustomButton';
import useNotify from 'src/lib/hooks/useNotify';
import { GameEmitter } from '../../events/GameEmitter';
import { patchGameAiDescription } from '../../services/patchGameAiDescription';

interface GameDescriptionPromptProps {
  gameUuid: string;
  gameDescriptionPrompt: string;
}

export function GameDescriptionPrompt({
  gameUuid,
  gameDescriptionPrompt,
}: GameDescriptionPromptProps) {
  const notify = useNotify();
  useEffect(() => {
    reset({
      prompt: gameDescriptionPrompt,
    });
  }, [gameDescriptionPrompt]);

  const { control, handleSubmit, reset } = useForm({
    defaultValues: {
      prompt: gameDescriptionPrompt,
    },
  });

  const [isEditing, setIsEditing] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);

  const handleEditClick = () => {
    setIsEditing(true);
  };

  const handleUpdateDescriptionPrompt = async (values: { prompt: string }) => {
    setIsSubmitting(true);
    try {
      const { data: promptResponse, error } = await patchGameAiDescription(
        gameUuid,
        values.prompt
      );
      if (error) {
        notify(error, { variant: 'error' });
      } else {
        notify('Prompt Updated!', { variant: 'success' });
        GameEmitter.emit('GameDescriptionPromptUpdated', values.prompt);
      }
    } catch (error: any) {
      notify(error?.message || error?.description, { variant: 'error' });
    } finally {
      setIsEditing(false);
      setIsSubmitting(false);
    }
  };

  const handleCancelClick = () => {
    reset();
    setIsEditing(false);
  };

  return (
    <Box p={4}>
      {isEditing ? (
        <form onSubmit={handleSubmit(handleUpdateDescriptionPrompt)}>
          <Controller
            name="prompt"
            control={control}
            render={({ field }) => (
              <TextField
                {...field}
                label="Prompt"
                fullWidth
                multiline
                minRows={4}
                sx={{ marginBottom: 2 }}
              />
            )}
          />

          <Stack direction="row" spacing={2} marginTop={2}>
            <Button
              variant="outlined"
              color="error"
              onClick={handleCancelClick}
            >
              Cancel
            </Button>
            <Button
              type="submit"
              variant="contained"
              color="success"
              loading={isSubmitting}
            >
              Save
            </Button>
          </Stack>
        </form>
      ) : (
        <Typography
          variant="body1"
          sx={{
            whiteSpace: 'pre-line',
            padding: '10px',
            borderRadius: '10px',
            border: '1px solid #ddd',
            minHeight: 'calc(5 * 1.5em)',
          }}
        >
          {gameDescriptionPrompt ?? `{empty description prompt}`}
        </Typography>
      )}

      {!isEditing && (
        <Stack direction="row" spacing={2} marginTop={2}>
          <Button variant="contained" color="info" onClick={handleEditClick}>
            Update Description Prompt
          </Button>
        </Stack>
      )}
    </Box>
  );
}
