import MakerMostActiveUsers from 'src/components/modules/MakerDialog/view/MostActiveUsers';
import Page from 'src/template/components/Page';

export default function MakerMostActiveUsersPage() {
  return (
    <Page title="SGM Chat">
      <MakerMostActiveUsers />
    </Page>
  );
}
