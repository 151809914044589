import { Avatar, Link, TableCell, TableRow } from '@mui/material';
import { alpha } from '@mui/material/styles';
import { capitalCase } from 'change-case';
import Highlighter from 'react-highlight-words';
import { Link as RouterLink } from 'react-router-dom';
import Routes from 'src/routes/Routes';
import useSettings from 'src/template/hooks/useSettings';
import { matchingColors, rowColors } from '../utils';

function formatContractAddress(mintToken: string | undefined) {
  if (!mintToken) return '';

  if (mintToken.length <= 10) return mintToken;

  return `${mintToken.substring(0, 6)}...${mintToken.slice(-4)}`;
}

const blockchains = {
  POLYGON: 'polygon',
  ETHEREUM: 'ethereum',
  STICKY: 'sticky',
};

function getOpenSea(nft: any) {
  if (!nft) return undefined;

  let openSeaBlockchain;
  if (nft?.blockchain === blockchains.POLYGON) openSeaBlockchain = 'matic';
  if (nft?.blockchain === blockchains.ETHEREUM) openSeaBlockchain = 'ethereum';
  return {
    link: `https://opensea.io/assets/${openSeaBlockchain}/${nft?.mintToken}/${nft?.mintTokenId}`,
    icon: 'simple-icons:opensea',
  };
}

const highlightStyle = {
  backgroundColor: '#FC6600', // Dark Orange
  // fontWeight: 'bold',
  color: 'white',
};

function searchFormatter(
  text: string,
  colName: string,
  searchHighlight: {
    searchColumn: string;
    searchKeyword: string;
  }
) {
  return searchHighlight.searchColumn === colName ? (
    <Highlighter
      searchWords={[searchHighlight.searchKeyword]}
      textToHighlight={text}
      highlightStyle={highlightStyle}
    />
  ) : (
    text
  );
}

export default function StickyNftsTableRow({
  nft,
  searchHighlight,
}: {
  nft: any; // NFT,
  searchHighlight: {
    searchColumn: string;
    searchKeyword: string;
  };
}) {
  // eslint-disable-next-line no-param-reassign
  nft.blockchainOwnedEditions =
    nft.blockchainOwnedEditions ?? nft.ownedEditionsBlockchain;
  // eslint-disable-next-line no-param-reassign
  nft.stickyOwnedEditions = nft.stickyOwnedEditions ?? nft.ownedEditionsSticky;
  // eslint-disable-next-line no-param-reassign
  // nft.uuid = nft.nftUuid ?? nft.uuid;

  const theme = useSettings().themeMode;
  const getBalanceColor = () => {
    // const theme = useSettings().themeMode;
    if (
      nft?.blockchainOwnedEditions === '' ||
      nft?.blockchainOwnedEditions === `couldn't fetch` ||
      nft?.blockchainOwnedEditions === undefined ||
      nft?.stickyOwnedEditions === '' ||
      nft?.stickyOwnedEditions === undefined
    )
      return matchingColors.missingInfoGold;
    if (nft?.stickyOwnedEditions == nft?.blockchainOwnedEditions)
      return matchingColors.matchGreen[theme];
    if (nft?.stickyOwnedEditions != nft?.blockchainOwnedEditions)
      return matchingColors.mismatchRed;
    return matchingColors.neutral[theme];
  };

  const cellWidth = 100 / 9;
  return (
    <TableRow
      key={nft.uuid}
      hover
      sx={{
        backgroundColor: nft?.deletedAt
          ? alpha(rowColors.deleted[theme], rowColors.alpha[theme])
          : rowColors.neutral[theme],
      }}
    >
      <TableCell sx={{ display: 'flex', alignItems: 'center' }}>
        <Avatar
          alt={nft.name}
          src={nft.fileUrl}
          variant="rounded"
          sx={{ mr: 2 }}
        />
        <Link
          component={RouterLink}
          to={Routes.nft(nft.nftUuid ?? nft.uuid)}
          color={nft.name ? 'inherit' : 'text.secondary'}
          underline="hover"
          display="inline-block"
        >
          {nft.name
            ? searchFormatter(nft.name, 'name', searchHighlight)
            : 'empty'}{' '}
        </Link>
      </TableCell>
      <TableCell align="center">
        {searchFormatter(
          capitalCase(nft.blockchain),
          'blockchain',
          searchHighlight
        )}
      </TableCell>
      <TableCell align="center">
        {searchFormatter(
          nft.mintContract ?? '',
          'mintContract',
          searchHighlight
        )}
      </TableCell>
      <TableCell align="center">
        <Link
          textTransform={'none'}
          color={nft.name ? 'inherit' : 'text.secondary'}
          underline="hover"
          display="inline-block"
          href={getOpenSea(nft)?.link ?? '.'}
        >
          {searchFormatter(
            formatContractAddress(nft?.mintToken),
            'mintToken',
            searchHighlight
          )}
        </Link>
      </TableCell>
      <TableCell align="center">{nft.nftUuid ?? nft.uuid}</TableCell>
      <TableCell
        align="center"
        sx={{ color: getBalanceColor() }}
        style={{ width: `${cellWidth}%` }}
      >
        {nft?.stickyOwnedEditions}
      </TableCell>
      <TableCell
        align="center"
        sx={{ color: getBalanceColor() }}
        style={{ width: `${cellWidth}%` }}
      >
        {nft?.blockchainOwnedEditions === undefined ||
        nft?.blockchainOwnedEditions === ''
          ? `couldn't fetch`
          : nft?.blockchainOwnedEditions}
      </TableCell>
      {/* <TableCell align="right">{formatDate(nft.updatedAt)}</TableCell>
      <TableCell align="right">{formatDate(nft.createdAt)}</TableCell> */}
    </TableRow>
  );
}
