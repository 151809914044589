import { BoxProps } from '@mui/material';
import Flex from 'src/components/shared/Flex';
import { Chat, ChatMessage } from 'src/lib/types/chat';
import LoadingScreen from 'src/template/components/LoadingScreen';
import ChatHeader from './ChatHeader';
import ChatMessages from './ChatMessages';

interface ChatScreenProps {
  chat: Chat;
  messages: ChatMessage[];
  isLoading: boolean;
  flexProps?: BoxProps;
  loadMoreMessages?: () => void;
}

export default function ChatScreen({
  chat,
  messages,
  isLoading,
  flexProps,
  loadMoreMessages,
}: ChatScreenProps) {
  if (isLoading) {
    return (
      <Flex
        width="100%"
        justifyContent="center"
        alignItems={'center'}
        bgcolor={'#000000'}
        {...flexProps}
      >
        <LoadingScreen />
      </Flex>
    );
  }

  return (
    <Flex
      flex="1"
      width="100%"
      height={'100%'}
      overflow="auto"
      bgcolor={'#000000'}
      flexDirection={'column'}
      alignItems={'center'}
      {...flexProps}
    >
      <ChatHeader chat={chat} />
      <ChatMessages messages={messages} loadMoreMessages={loadMoreMessages} />
    </Flex>
  );
}
