import { DEFAULT_INITIAL_PAGE_SIZE } from 'src/lib/constants/pagination';
import useDebounce from 'src/lib/hooks/useDebounce';
import { fetchCollections } from 'src/lib/services/collections';
import useTable from 'src/template/hooks/useTable';

export default function useSelectColletions() {
  const {
    page,
    rowsPerPage: size,
    order,
    orderBy,
    query,
    searchBy,
    setRowsPerPage,
    setOrder,
    setPage,
    setQuery,
    setSearchBy,
    setOrderBy,
  } = useTable({
    defaultOrderBy: 'createdAt',
    defaultOrder: 'desc',
    defaultCurrentPage: 0,
    defaultRowsPerPage: DEFAULT_INITIAL_PAGE_SIZE,
    defaultSearchBy: 'name',
  });

  const {
    data,
    total: totalCount,
    loading: isLoading,
  } = fetchCollections(
    {
      query: useDebounce(query, 500),
      page,
      order,
      orderBy,
      searchFor: searchBy,
      size,
    },
    'approved'
  );

  return {
    data,
    isLoading,
    totalCount,
    page,
    size,
    order,
    orderBy,
    searchBy,
    query,
    setPage,
    setRowsPerPage,
    setOrder,
    setQuery,
    setSearchBy,
    setOrderBy,
  };
}
