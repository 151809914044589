import {
  Card,
  CardContent,
  CardHeader,
  InputAdornment,
  MenuItem,
  Stack,
  TextField,
} from '@mui/material';
import { Controller, useForm } from 'react-hook-form';
import Button from 'src/components/shared/Buttons/CustomButton';
import Flex from 'src/components/shared/Flex';
import ControllerField from 'src/components/shared/Inputs/ControllerField';
import Modal from 'src/components/shared/Modal';
import useModalStore from 'src/lib/stores/useModalStore';
import Iconify from 'src/template/components/Iconify';
import { PayoutCountryFormValues } from './types';

type Props = {
  defaultValues: PayoutCountryFormValues;
  onSubmit(values: PayoutCountryFormValues): Promise<void>;
  title: string;
};
export default function PayoutCountryForm({
  defaultValues,
  onSubmit,
  title,
}: Props): JSX.Element {
  const closeModal = useModalStore((state) => state.closeModal);
  const {
    handleSubmit,
    formState: { isSubmitting },
    control,
  } = useForm<PayoutCountryFormValues>({
    defaultValues,
  });

  const handleCancel = () => {
    closeModal();
  };

  return (
    <Modal
      open
      onClose={handleCancel}
      aria-labelledby="create a Payout Country"
    >
      <Card
        sx={{
          width: 400,
        }}
      >
        <form onSubmit={handleSubmit(onSubmit)}>
          <CardHeader title={title} />
          <CardContent>
            <Stack spacing={1.5}>
              <ControllerField control={control} name="name" label="Name" />
              <ControllerField control={control} name="code" label="Code" />

              <Controller
                name="active"
                control={control}
                render={({ field }) => (
                  <TextField
                    select
                    label="Active"
                    {...field}
                    onSelect={(e) => console.log(e)}
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">
                          <Flex alignItems="center" justifyContent="center">
                            <Iconify
                              icon={
                                field.value ? 'eva:checkmark-circle-2-fill' : ''
                              }
                              fontSize="small"
                            />
                          </Flex>
                        </InputAdornment>
                      ),
                    }}
                  >
                    <MenuItem value={'true'}>Yes</MenuItem>
                    <MenuItem value={'false'}>No</MenuItem>
                  </TextField>
                )}
              />
            </Stack>
            <Flex width="100%" justifyContent="space-between" mt={4}>
              <Button variant="text" color="error" onClick={handleCancel}>
                Cancel
              </Button>
              <Button
                type="submit"
                loading={isSubmitting}
                variant="contained"
                color="secondary"
              >
                Save
              </Button>
            </Flex>
          </CardContent>
        </form>
      </Card>
    </Modal>
  );
}
