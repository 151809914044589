import { Add } from '@mui/icons-material';
import { Container } from '@mui/material';
import CreateConfiguration from 'src/components/modules/Configurations/views/Create';
import ListConfigurations from 'src/components/modules/Configurations/views/List';
import Button from 'src/components/shared/Buttons/CustomButton';
import useModalStore from 'src/lib/stores/useModalStore';
import HeaderBreadcrumbs from 'src/template/components/HeaderBreadcrumbs';
import Page from 'src/template/components/Page';

export default function ConfigurationsPage(): JSX.Element {
  const openModal = useModalStore((state) => state.openModal);

  const handleCreateConfiguration = () => {
    openModal(<CreateConfiguration />);
  };

  return (
    <Page title="Configurations">
      <Container maxWidth="xl">
        <HeaderBreadcrumbs
          heading="Configurations"
          links={[{ name: 'List' }]}
          action={
            <Button
              variant="contained"
              color="primary"
              onClick={handleCreateConfiguration}
              sx={{ gap: 1 }}
            >
              <Add />
              New
            </Button>
          }
        />
        <ListConfigurations />
      </Container>
    </Page>
  );
}
