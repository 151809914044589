import getAPIClient from 'src/lib/services/api/axios.config';
import { ServiceResponse } from 'src/lib/types/ServiceResponse';
import { getMessageFromError } from 'src/lib/utils/error';

export type AddNftsOnPackData = {
  uuid: string;
  nfts: {
    uuid: string;
    rarityUuid: string;
    faceValue: number;
  }[];
};

export async function AddNftsOnPack(
  data: AddNftsOnPackData
): Promise<ServiceResponse<string>> {
  try {
    const api = await getAPIClient();
    const res = await api.post(`admin/nft-pack/${data.uuid}/nfts`, {
      nfts: data.nfts,
    });
    return {
      data: res.data,
      error: null,
    };
  } catch (e) {
    return {
      data: null,
      error: getMessageFromError(e),
    };
  }
}
