import PayoutCountryForm from '../../components/Form';
import useCreatePayoutCountry from './useView';

export default function CreatePayoutCountry(): JSX.Element {
  const { handleCreatePayoutCountry } = useCreatePayoutCountry();
  return (
    <PayoutCountryForm
      defaultValues={{
        name: '',
        code: '',
        active: 'true',
      }}
      onSubmit={handleCreatePayoutCountry}
      title="Add a Payout Country"
    />
  );
}
