import useNotify from 'src/lib/hooks/useNotify';
import useModalStore from 'src/lib/stores/useModalStore';
import { UpdateChannelFormValues } from '../../components/Forms/Update/types';
import { ChannelEmitter } from '../../events/ChannelEmitter';
import { UpdateChannel } from '../../services/updateChannel';

export default function useUpdateChannelView() {
  const closeModal = useModalStore((state) => state.closeModal);
  const notify = useNotify();

  const handleUpdateChannel = async (
    uuid: string,
    values: UpdateChannelFormValues
  ) => {
    const { error, data } = await UpdateChannel(uuid, {
      name: values.name,
      description: values.description,
      backgroundImageUrl: values.backgroundImageUrl,
      ...(values.objectLinks && { objectLinks: values.objectLinks }),
    });

    if (error) {
      notify(error, { variant: 'error' });
      closeModal();
    } else if (data) {
      notify('Channel Updated!', { variant: 'success' });
      closeModal();

      ChannelEmitter.emit('ChannelUpdated', data);
    }
  };

  return {
    handleUpdateChannel,
  };
}
