import { DeepPartial } from 'react-hook-form';
import { ChannelWithAdminAnalytics } from 'src/lib/types/channelWithAdminAnalytics';
import { parseSearchParams } from 'src/lib/utils/parseSearchParams';
import useSWR, { SWRResponse } from 'swr';

export const fetchChannels = (
  filters?: DeepPartial<PossibleParams>,
  shouldFetch = true
): SWRResponse<ChannelWithAdminAnalytics[]> => {
  const response = useSWR(
    shouldFetch ? `admin/channels?${parseSearchParams(filters ?? {})}` : null
  );

  return response;
};

type PossibleParams = {
  page: number | string;
  size: number | string;
  filter: {
    status: string;
    name: {
      like: string;
    };
    isSticky: boolean | number;

    ownerUuid: string;
    followerUuid: string;
    adminUuid: string;
  };
  order: string;
  sortBy: string;
};
