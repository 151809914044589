import { Container } from '@mui/material';
import EntityNotes from 'src/components/modules/EntityNotes/views/List';
import Page from 'src/template/components/Page';

export default function UsersNotesPage() {
  return (
    <Page title="Users Notes">
      <Container maxWidth="xl">
        <EntityNotes />
      </Container>
    </Page>
  );
}
