import { format } from 'date-fns';
import { DeepPartial } from 'react-hook-form';
import { type Fetch } from 'src/lib/types/fetch';
import { type PaginatedResponse } from 'src/lib/types/PaginatedResponse';
import { parseSearchParams } from 'src/lib/utils/parseSearchParams';
import useSWR from 'swr';
import {
  ParsedConfiguration,
  type Configuration,
} from '../types/Configuration';

const parsedData = (
  response: PaginatedResponse<Configuration[]> | undefined
): PaginatedResponse<ParsedConfiguration[]> | undefined => {
  if (!response) return undefined;
  return {
    body: response.body.map(
      ({ createdAt, deletedAt, updatedAt, ...config }) => ({
        ...config,
        createdAt: format(createdAt, 'dd/MM/yyyy'),
        deletedAt: deletedAt ? format(createdAt, 'dd/MM/yyyy') : null,
        updatedAt: format(createdAt, 'dd/MM/yyyy'),
      })
    ),
    meta: response.meta,
  };
};

export const fetchConfigurations = (
  params?: DeepPartial<PossibleParams>
): Fetch<PaginatedResponse<ParsedConfiguration[]>> => {
  const parsedParams = parseSearchParams(params ?? {});
  const { data, error, isLoading, mutate } = useSWR<
    PaginatedResponse<Configuration[]>
  >(`admin/configurations?${parsedParams}`);

  return {
    data: parsedData(data),
    error,
    loading: isLoading,
    mutate,
  };
};

type PossibleParams = {
  page: string | number;
  size: string | number;
  order: Record<string, string>;
  filter: {
    name: {
      like: string;
    };
    platform: {
      like: string;
    };
    description: {
      like: string;
    };
    active: boolean | string;
  };
};
