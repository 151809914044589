import ConfirmationDialogRaw from 'src/components/shared/Dialog/ConfirmationDialog';
import useModalStore from 'src/lib/stores/useModalStore';
import useBanUserFromClub from './useView';

type Props = {
  userUuid: string;
  clubUuid: string;
};
export default function ChangeGameStatusView({
  userUuid,
  clubUuid,
}: Props): JSX.Element {
  const { handleBanUserFromClub } = useBanUserFromClub();
  const closeModal = useModalStore((state) => state.closeModal);

  return (
    <ConfirmationDialogRaw
      onClose={closeModal}
      open
      title={`Are you sure you want to ban this user?`}
      onConfirm={async () => {
        await handleBanUserFromClub(userUuid, clubUuid);
      }}
    >
      Are you sure you want to ban this user?
    </ConfirmationDialogRaw>
  );
}
