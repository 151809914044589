import { Edit } from '@mui/icons-material';
import { Button, Card, SxProps } from '@mui/material';
import Image, { ImageProps } from 'src/template/components/Image';

export default function EditableImage({
  image,
  onEdit,
  sx,
  imageProps,
  showEditButton = false,
  ...otherInputProps
}: {
  image: string;
  onEdit: (file: any) => void;
  sx?: SxProps;
  imageProps?: ImageProps;
  showEditButton?: boolean;
}) {
  return (
    <Card
      sx={{
        ...sx,
        position: 'relative',
        backgroundColor: 'black',
        '&:hover': {
          '& .edit-cover-button': {
            opacity: 1,
          },
        },
      }}
    >
      <Image
        src={image}
        {...imageProps}
        sx={{
          '&:hover': {
            opacity: 0.7,
          },
        }}
      />
      <Button
        fullWidth
        size="large"
        startIcon={<Edit />}
        className="edit-cover-button"
        component="label"
        sx={{
          position: 'absolute',
          opacity: showEditButton ? (image ? 0 : 1) : 0,
          height: '100%',
          width: '100%',
          color: 'white !important',
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, -50%)',
          '&:hover': {
            backgroundColor: '#00000080',
          },
        }}
      >
        Edit
        <input
          type="file"
          hidden
          accept="image/*"
          multiple={false}
          onChange={(e) => {
            onEdit(e.target?.files?.[0]);
          }}
          {...otherInputProps}
        />
      </Button>
    </Card>
  );
}
