export const matchingColors = {
  matchGreen: {
    light: `#58c759`,
    // light: `#47b648`,
    dark: `#7ae97b`,
  },
  mismatchRed: `#d97273`,
  // match: `#229722`,
  // mismatch: `#972222`,
  // missingInfo: `#a67c00`,
  missingInfoGold: `#b49847`,
  neutral: {
    light: `#000000`,
    dark: `#ffffff`,
  },
};

export const matchingColorsThemed = {
  matchGreen: {
    light: `#58c759`,
    dark: `#7ae97b`,
  },
  mismatchRed: {
    light: `#d97273`,
    dark: `#d97273`,
  },
  missingInfoGold: {
    light: `#b49847`,
    dark: `#b49847`,
  },
  neutral: {
    light: `#000000`,
    dark: `#ffffff`,
  },
};

export const rowColors = {
  neutral: {
    light: undefined,
    dark: undefined,
  },
  deleted: {
    light: '#FCaa44',
    dark: '#fC6600',
  },
  alpha: {
    light: 0.2,
    dark: 0.1,
  },
};
