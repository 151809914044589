import { ContentCopy, Delete, Edit } from '@mui/icons-material';
import { Button, MenuItem, TableCell, TableRow } from '@mui/material';
import { useEffect } from 'react';
import CopyToClipboard from 'react-copy-to-clipboard';
import MoreMenu from 'src/components/shared/MoreMenu';
import SmartTable, {
  SmartColumn,
} from 'src/components/shared/SmartTable/SmartTable';
import createFilterStore from 'src/lib/stores/createFilterStore';
import { HomeFeed } from 'src/lib/types/homeFeed';
import { LabelValue } from 'src/lib/types/labelValue';
import { formatDate } from 'src/lib/utils/formatters';
import useTabs from 'src/template/hooks/useTabs';
import { fetchHomeFeeds } from '../../../../lib/services/homeFeeds';
import HomeFeedsFilterModal from './HomeFeedsFilterModal';

type HomeFeedsTableProps = {
  onChangeQueryParams: (queryParams: string) => void;
  onDelete: (feed: HomeFeed) => void;
  onEdit: (feed: HomeFeed) => void;
  toggleFeedStatus: (feed: HomeFeed) => void;
};

export default function HomeFeedsTable({
  onChangeQueryParams,
  onDelete,
  onEdit,
  toggleFeedStatus,
}: HomeFeedsTableProps) {
  const { filter, setFilter } = useFilter();
  const { currentTab: status, onChangeTab: onChangeStatus } = useTabs('all');

  const {
    data: homeFeeds,
    total,
    loading,
    queryParams,
  } = fetchHomeFeeds(filter, status);

  useEffect(() => {
    onChangeQueryParams(queryParams);
  }, [queryParams]);

  return (
    <SmartTable
      data={homeFeeds}
      loading={loading}
      total={total || 0}
      tabs={STATUS_TABS}
      activeTab={status}
      onChangeTab={onChangeStatus}
      columns={TABLE_COLUMNS}
      filter={filter}
      setFilter={setFilter}
      filterModal={
        <HomeFeedsFilterModal filter={filter} setFilter={setFilter} />
      }
      searchForOptions={SEARCH_FOR_OPTIONS}
    >
      {homeFeeds?.map((feed) => (
        <TableRow key={feed.uuid}>
          <TableCell>{feed.feedOrder ?? '-'}</TableCell>
          <TableCell>{feed.type ?? '-'}</TableCell>
          <TableCell>{feed.cardType ?? '-'}</TableCell>
          <TableCell>{feed.name ?? '-'}</TableCell>
          <TableCell>{feed.description ?? '-'}</TableCell>
          <TableCell>{`${feed.hasSeeAll}` ?? '-'}</TableCell>
          <TableCell align="center">
            <Button
              color={feed.status === 'active' ? 'success' : 'error'}
              variant="outlined"
              onClick={() => {
                toggleFeedStatus(feed);
              }}
              size="small"
              sx={{ textTransform: 'capitalize' }}
            >
              {feed.status}
            </Button>
          </TableCell>
          <TableCell>{formatDate(feed.createdAt) ?? '-'}</TableCell>
          <TableCell>{formatDate(feed.updatedAt) ?? '-'}</TableCell>

          <TableCell align="right">
            <MoreMenu>
              <CopyToClipboard text={feed.uuid}>
                <MenuItem aria-label="copy feed uuid">
                  <ContentCopy />
                  Copy UUID
                </MenuItem>
              </CopyToClipboard>
              <MenuItem aria-label="edit feed" onClick={() => onEdit(feed)}>
                <Edit />
                Edit
              </MenuItem>

              <MenuItem aria-label="delete feed" onClick={() => onDelete(feed)}>
                <Delete />
                Delete
              </MenuItem>
            </MoreMenu>
          </TableCell>
        </TableRow>
      ))}
    </SmartTable>
  );
}

const useFilter = createFilterStore({
  orderBy: 'feedOrder',
  order: 'desc',
  searchFor: 'name',
  query: '',
  page: 0,
  size: 30,
  custom: {},
});

const STATUS_TABS = [
  { value: 'all', label: 'All' },
  { value: 'active', label: 'Active' },
  { value: 'inactive', label: 'Inactive' },
];

const TABLE_COLUMNS: SmartColumn[] = [
  {
    id: 'feedOrder',
    label: 'Order',
    isSortable: true,
  },
  {
    id: 'type',
    label: 'Type',
    isSortable: true,
  },
  {
    id: 'cardType',
    label: 'Card Type',
    isSortable: true,
  },
  {
    id: 'name',
    label: 'Name',
    isSortable: true,
  },
  {
    id: 'description',
    label: 'Description',
    isSortable: true,
  },
  {
    id: 'hasSeeAll',
    label: 'See All',
    isSortable: true,
  },
  {
    id: 'status',
    label: 'Status',
    isSortable: true,
    align: 'center',
  },
  {
    id: 'createdAt',
    label: 'Created',
    isSortable: true,
  },
  {
    id: 'updatedAt',
    label: 'Updated',
    isSortable: true,
  },
  {
    id: 'actions',
    label: '',
    isSortable: false,
  },
];

const SEARCH_FOR_OPTIONS: LabelValue[] = TABLE_COLUMNS.map(({ id, label }) => ({
  label,
  value: id,
}));
