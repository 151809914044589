import { ContentCopy, OpenInNew } from '@mui/icons-material';
import {
  Checkbox,
  Link,
  MenuItem,
  Stack,
  TableCell,
  TableRow,
} from '@mui/material';
import { format } from 'date-fns';
import CopyToClipboard from 'react-copy-to-clipboard';
import { useNavigate } from 'react-router';
import { Link as RouterLink } from 'react-router-dom';
import MoreMenu from 'src/components/shared/MoreMenu';
import SmartTable from 'src/components/shared/SmartTable/SmartTable';
import { fetchIapTransactions } from 'src/lib/services/iapTransactions';
import createFilterStore from 'src/lib/stores/createFilterStore';
import createSelectStore from 'src/lib/stores/createSelectStore';
import { formatDate } from 'src/lib/utils/formatters';
import Routes from 'src/routes/Routes';
import HeaderBreadcrumbs from 'src/template/components/HeaderBreadcrumbs';
import Iconify from 'src/template/components/Iconify';
import Label, { LabelColor } from 'src/template/components/Label';
import useTabs from 'src/template/hooks/useTabs';
import IapTransactionsFilter from './IapTransactionsFilter';

const useFilter = createFilterStore({
  orderBy: 'createdAt',
  order: 'desc',
  searchFor: 'startUser.name',
  query: '',
  page: 0,
  size: 30,
  custom: {},
});

const useSelected = createSelectStore();

const searchForOptions = [
  { label: 'UUID', value: 'uuid' },
  {
    label: 'Product',
    value: 'productFullId',
  },
  {
    label: 'Status',
    value: 'status',
  },
  {
    label: 'Error Description',
    value: 'errorDescription',
  },
  {
    label: 'Username',
    value: 'startUser.name',
  },
  {
    label: 'Email',
    value: 'startUser.email',
  },
  {
    label: 'Slug',
    value: 'startUser.slug',
  },
];

const IAP_TRANSACTION_STATUS_TABS = [
  { value: 'all', label: 'All', color: 'default' },
  { value: 'api_error', label: 'Api Error', color: 'error' },
  { value: 'started', label: 'Started', color: 'warning' },
  { value: 'consolidated', label: 'Consolidated', color: 'success' },
  { value: 'canceled', label: 'Canceled', color: 'secondary' },
];

export default function IapTransactions() {
  const navigate = useNavigate();
  const { filter, setFilter } = useFilter();
  const { selected, toggleSelected, toggleSelectAll } = useSelected();

  const { currentTab: status, onChangeTab: onChangeStatus } = useTabs('all');

  const {
    data: iapTransactions,
    total,
    loading,
  } = fetchIapTransactions(filter, status);

  return (
    <>
      <HeaderBreadcrumbs
        heading="Iap Transactions"
        links={[{ name: 'Iap Transactions' }]}
      />
      <SmartTable
        data={iapTransactions}
        loading={loading}
        total={total || 0}
        tabs={IAP_TRANSACTION_STATUS_TABS}
        activeTab={status}
        onChangeTab={onChangeStatus}
        columns={[
          {
            id: 'createdAt',
            label: 'Date',
            isSortable: true,
          },
          {
            id: 'productFullId',
            label: 'Product',
            isSortable: true,
          },
          {
            id: 'startUser.name',
            label: 'Username',
            isSortable: true,
          },
          {
            id: 'startUser.email',
            label: 'Email',
            isSortable: true,
          },
          {
            id: 'startUser.slug',
            label: 'Slug',
            isSortable: true,
          },
          {
            id: 'status',
            label: 'Status',
            isSortable: true,
          },
          {
            id: 'errorDescription',
            label: 'Error Description',
            isSortable: true,
          },
          {
            id: 'updatedAt',
            label: 'Updated At',
            isSortable: true,
          },
        ]}
        filter={filter}
        setFilter={setFilter}
        filterModal={
          <IapTransactionsFilter filter={filter} setFilter={setFilter} />
        }
        searchForOptions={searchForOptions}
        selected={selected}
        onSelectAll={() => {
          toggleSelectAll(iapTransactions?.map((t) => t.uuid) || []);
        }}
      >
        {iapTransactions &&
          iapTransactions.map((t) => (
            <TableRow key={t.uuid} hover>
              <TableCell padding="checkbox">
                <Checkbox
                  checked={selected.includes(t.uuid)}
                  onClick={() => toggleSelected(t.uuid)}
                />
              </TableCell>
              <TableCell>{format(t.createdAt, 'dd/MM/yyyy · HH:mm')}</TableCell>
              <TableCell>{t.productFullId || '-'}</TableCell>
              <TableCell>{t.startUser?.name || '-'}</TableCell>
              <TableCell>{t.startUser?.email || '-'}</TableCell>
              <TableCell>{t.startUser?.slug || '-'}</TableCell>
              <TableCell>
                <Stack spacing={2}>
                  <Label
                    variant="ghost"
                    color={
                      IAP_TRANSACTION_STATUS_TABS?.find(
                        (tab) => tab.value === t.status
                      )?.color as LabelColor | undefined
                    }
                    sx={{ textTransform: 'capitalize' }}
                  >
                    {t.status || '-'}
                  </Label>
                  {t.fixed && (
                    <Label
                      variant="ghost"
                      color="info"
                      sx={{ textTransform: 'capitalize' }}
                    >
                      fixed
                    </Label>
                  )}
                </Stack>
              </TableCell>
              <TableCell>{t.errorDescription || '-'}</TableCell>
              <TableCell>{formatDate(t.updatedAt)}</TableCell>
              <TableCell align="right">
                <MoreMenu>
                  <CopyToClipboard text={t.uuid}>
                    <MenuItem>
                      <ContentCopy />
                      Copy UUID
                    </MenuItem>
                  </CopyToClipboard>
                  {t.startUser?.uuid && (
                    <MenuItem>
                      <Link
                        component={RouterLink}
                        to={Routes.user(t.startUser.uuid)}
                        underline="none"
                        display="flex"
                        align="center"
                      >
                        <Iconify
                          width={16}
                          height={16}
                          icon="akar-icons:link-out"
                        />
                        See User
                      </Link>
                    </MenuItem>
                  )}
                  {t.transactionId &&
                    ['api_error', 'consolidated'].includes(t.status) && (
                      <MenuItem
                        onClick={() => {
                          navigate(`/iap/${t.uuid}/apple-receipt`);
                        }}
                      >
                        <OpenInNew />
                        Show Apple Receipt
                      </MenuItem>
                    )}
                </MoreMenu>
              </TableCell>
            </TableRow>
          ))}
      </SmartTable>
    </>
  );
}
