import AddAssetInPackForm from '../../components/Forms/AddAsset';
import useAddAssetInContestView from './useView';

interface AddAssetInContestViewProps {
  contestUuid: string;
}

export default function AddAssetInContestView({
  contestUuid,
}: AddAssetInContestViewProps): JSX.Element {
  const { handleAddAssetInContest } = useAddAssetInContestView();
  return (
    <AddAssetInPackForm
      onSubmit={async (values) => {
        await handleAddAssetInContest(contestUuid, values);
      }}
      title="Add Assets In Contest"
      contestUuid={contestUuid}
    />
  );
}
