import { WithProduct } from 'src/lib/types/product';
import { User, WithOptionalUser } from 'src/lib/types/users';
import { deepClone } from 'src/lib/utils/deepClone';
import { SubscriptionDetailsResponse } from '../services/fetchSubscription';
import { RevenueEvent, RevenueEventType } from '../types/RevenueEvent';

export type SubscriptionDetails = {
  isActive: boolean;
  isFreeTrial: boolean;
  isAutoRenewalEnabled: boolean;
  renovationCount: number;
  paymentCount: number;
  user?: Pick<User, 'uuid' | 'name' | 'slug' | 'active' | 'email'>;
  renewals: Array<WithProduct<WithOptionalUser<RevenueEvent>>>;
};
export function parseDataToSubscriptionDetails(
  response: SubscriptionDetailsResponse
): SubscriptionDetails {
  const revenueEvents = response.body;
  const sortedEvents = deepClone(revenueEvents).sort(
    (a, b) => b.eventDate - a.eventDate
  );
  const latestEvent = sortedEvents[0];
  const { isFreeTrial } = latestEvent;

  const subscriptionIsActive = revenueEvents.some((renew) => {
    if (
      (renew.eventType === RevenueEventType.RENEW ||
        renew.eventType === RevenueEventType.CHANGE_STATUS_DISABLED ||
        renew.eventType === RevenueEventType.BUY) &&
      renew.subscriptionExpiresDate
    ) {
      const expiresDateAtMidnight = new Date(
        renew.subscriptionExpiresDate
      ).setUTCHours(0, 0, 0);
      const currentDateAtMidnight = new Date().setUTCHours(0, 0, 0);
      return expiresDateAtMidnight > currentDateAtMidnight;
    }
    return false;
  });

  const { paymentCount } = response.meta;

  const renovationCount = revenueEvents.reduce((acc, curr) => {
    if (!curr.isFreeTrial && curr.eventType === RevenueEventType.RENEW) {
      return acc + 1;
    }
    return acc;
  }, 0);

  const isAutoRenewalEnabled: boolean =
    latestEvent.eventType !== RevenueEventType.CHANGE_STATUS_DISABLED &&
    subscriptionIsActive;

  return {
    renewals: sortedEvents,
    isActive: subscriptionIsActive,
    isFreeTrial,
    paymentCount,
    renovationCount,
    user: revenueEvents[0].user,
    isAutoRenewalEnabled,
  };
}
