import { ContentCopy } from '@mui/icons-material';
import { Box, Button, MenuItem } from '@mui/material';
import { GridSortDirection } from '@mui/x-data-grid';
import { capitalCase } from 'change-case';
import CopyToClipboard from 'react-copy-to-clipboard';
import Link from 'src/components/shared/Link';
import MagicTable from 'src/components/shared/MagicTable/MagicTable';
import MoreMenu from 'src/components/shared/MoreMenu';
import useURLSearchParams from 'src/lib/hooks/useURLSearchParams';
import { ContestEvent, ContestEventStatus } from 'src/lib/types/contestEvent';
import { formatUTCDate } from 'src/lib/utils/formatUTCDate';
import Routes from 'src/routes/Routes';
import Label from 'src/template/components/Label';
import { FetchContestEventsQueryParams } from '../../view/List/useView';

type ContestsTableProps = {
  data?: ContestEvent[];
  isLoading: boolean;
  params: FetchContestEventsQueryParams;
};

export default function ContestEventsTable({
  data,
  isLoading,
  params: { page, size, orderBy, sortOrder, status },
}: ContestsTableProps) {
  const { addParam } = useURLSearchParams();

  return (
    <MagicTable.Container>
      <MagicTable.Tabs
        defaultTab="all"
        tabs={[
          {
            label: 'All',
            value: 'all',
          },
          ...Object.values(ContestEventStatus).map((eventStatus) => ({
            label: eventStatus,
            value: eventStatus,
          })),
        ]}
        tabsConfig={{ queryName: 'status' }}
      />
      <Box p={2}>
        <MagicTable
          loading={isLoading}
          pageSize={size}
          page={page}
          paginationMode="server"
          onPageChange={(newPage) => {
            addParam('page', newPage);
          }}
          onPageSizeChange={(newSize) => {
            addParam('size', newSize);
          }}
          onSortModelChange={(model) => {
            const sortModel = model[0];
            addParam('orderBy', sortModel.field);
            addParam('order', sortModel.sort!);
          }}
          sortModel={[
            {
              field: orderBy,
              sort: sortOrder as GridSortDirection,
            },
          ]}
          rows={data ?? []}
          hideNumberOfRows
          columns={[
            {
              field: 'status',
              headerName: 'Status',
              flex: 1,
              renderCell: ({ row }) => (
                <Label
                  color={
                    row.status == ContestEventStatus.ACTIVE ||
                    row.status == ContestEventStatus.COMPLETED
                      ? 'success'
                      : 'error'
                  }
                >
                  {capitalCase(row.status)}
                </Label>
              ),
            },
            {
              field: 'entryPrice',
              headerName: 'Entry Price',
              valueFormatter({ value }) {
                return value ? value / 1000 : 'Free';
              },
            },
            {
              field: 'playerEntryLimit',
              headerName: 'Entry Limit',
              valueFormatter({ value }) {
                return value ?? 'Unlimited';
              },
            },

            {
              field: 'totalEntries',
              headerName: 'Players Limit',
              valueFormatter({ value }) {
                return value ?? 'Unlimited';
              },
            },
            {
              field: 'startDate',
              headerName: 'Start Date',
              sortingOrder: ['desc', 'asc'],
              flex: 1,
              valueFormatter({ value }) {
                return value ? formatUTCDate(value) : '-';
              },
            },
            {
              field: 'endDate',
              headerName: 'End Date',
              sortingOrder: ['desc', 'asc'],
              flex: 1,
              valueFormatter({ value }) {
                return value ? formatUTCDate(value) : '-';
              },
            },
            {
              field: 'createdAt',
              headerName: 'Created At',
              sortingOrder: ['desc', 'asc'],
              flex: 1,
              valueFormatter({ value }) {
                return value ? formatUTCDate(value) : '-';
              },
            },
            {
              field: 'Rankings',
              headerName: '',
              align: 'center',
              sortable: false,
              flex: 0,
              renderCell({ row }) {
                return (
                  <Link to={Routes.game_center.contestEvent.rankings(row.uuid)}>
                    <Button color="warning" variant="contained">
                      Rankings
                    </Button>
                  </Link>
                );
              },
            },
            {
              field: 'actions',
              headerName: 'Actions',
              align: 'center',
              sortable: false,
              flex: 0,
              renderCell({ row }) {
                return (
                  <MoreMenu>
                    <CopyToClipboard text={row.uuid}>
                      <MenuItem aria-label="copy feed uuid">
                        <ContentCopy />
                        Copy UUID
                      </MenuItem>
                    </CopyToClipboard>
                  </MoreMenu>
                );
              },
            },
          ]}
        />
      </Box>
    </MagicTable.Container>
  );
}
