import useNotify from 'src/lib/hooks/useNotify';
import useModalStore from 'src/lib/stores/useModalStore';
import { ConfigurationEmitter } from '../../events/ConfigurationEmitter';
import { updateConfiguration } from '../../services/updateConfiguration';
import { ParsedConfiguration } from '../../types/Configuration';
import UpdateConfiguration from '../../views/Update';

export default function useConfigurationsTableView() {
  const [openModal, closeModal] = useModalStore((state) => [
    state.openModal,
    state.closeModal,
  ]);
  const notify = useNotify();

  const handleUpdateConfig = (configuration: ParsedConfiguration) => {
    openModal(<UpdateConfiguration configuration={configuration} />);
  };

  const handleToggleConfigStatus = async (
    configId: string,
    currentStatus: ParsedConfiguration['active']
  ) => {
    const { data, error } = await updateConfiguration(configId, {
      active: !currentStatus,
    });
    if (error) {
      notify(error, { variant: 'error' });
      closeModal();
    } else if (data) {
      notify('Configuration updated!', { variant: 'success' });
      closeModal();
      ConfigurationEmitter.emit('configurationUpdated', data);
    }
  };

  return {
    handleUpdateConfig,
    handleToggleConfigStatus,
  };
}
