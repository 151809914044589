import { Box, TablePagination } from '@mui/material';
import { PaginationQuery } from 'src/lib/utils/query/types';

type Props = {
  onChangeRowsPerPage: (params: Partial<PaginationQuery>) => void;
  currentPage: number;
  rowsPerPage: number;
  rowsRendered: number;
  rowsPerPageOptions?: number[];
};

export default function EldenTablePagination({
  onChangeRowsPerPage,
  currentPage,
  rowsPerPage,
  rowsRendered,
  rowsPerPageOptions = [10, 50, 100],
}: Props) {
  return (
    <Box sx={{ position: 'relative' }}>
      <TablePagination
        rowsPerPageOptions={rowsPerPageOptions}
        component="div"
        count={rowsRendered < rowsPerPage ? 0 : Number.MAX_SAFE_INTEGER}
        rowsPerPage={rowsPerPage}
        page={currentPage}
        labelDisplayedRows={({ page }) => `Page: ${page + 1}`}
        onPageChange={(e, page) => onChangeRowsPerPage({ page })}
        onRowsPerPageChange={(e) =>
          onChangeRowsPerPage({ size: parseInt(e.target.value, 10), page: 0 })
        }
      />
    </Box>
  );
}
