import { Add } from '@mui/icons-material';
import {
  Button,
  Card,
  CardActions,
  CardContent,
  CardHeader,
  Chip,
  Grid,
  IconButton,
  Stack,
  TextField,
  Tooltip,
  Typography,
} from '@mui/material';
import { useSnackbar } from 'notistack';
import { useState } from 'react';
import Modal from 'src/components/shared/Modal';

export default function CollectionChipsCard({
  chips,
  title,
  modalTitle,
  onDelete,
  onAdd,
}: {
  chips: string[];
  title: string;
  modalTitle?: string;
  onDelete?: (chip: string) => Promise<any>;
  onAdd?: (chip: string) => Promise<any>;
}) {
  const [showAddChipModal, setShowAddChipModal] = useState(false);
  const [newChip, setNewChip] = useState<string>('');

  const { enqueueSnackbar: notify } = useSnackbar();

  const canAdd = typeof onAdd === 'function';
  const canDelete = typeof onDelete === 'function';

  const closeModal = () => {
    setShowAddChipModal(false);
    setNewChip('');
  };

  const handleChange = async (
    chip: string,
    callback?: (chip: string) => Promise<any>
  ) => {
    if (chip && typeof callback === 'function') {
      const res = await callback(chip);
      if (res) {
        if (res.data?.error) {
          notify(res.data.error.message, { variant: 'error' });
        } else {
          notify(`${title} updated successfully`, { variant: 'success' });
        }
      }
    }
    closeModal();
  };

  const deleteChip = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    event.preventDefault();
    const text = event.target?.parentElement?.parentElement?.textContent;
    if (!text) return;
    handleChange(text, onDelete);
  };

  return (
    <>
      <Card
        sx={{
          position: 'relative',
        }}
      >
        {canAdd && (
          <Tooltip title={modalTitle || 'Add'} placement="top">
            <IconButton
              sx={{
                position: 'absolute',
                top: 20,
                right: 20,
              }}
              onClick={() => setShowAddChipModal(true)}
            >
              <Add />
            </IconButton>
          </Tooltip>
        )}
        <CardHeader title={title} />
        <CardContent>
          <Grid container spacing={1}>
            {chips.length > 0 ? (
              chips.map((chip) => (
                <Grid item key={chip}>
                  <Chip
                    label={chip}
                    onDelete={canDelete ? deleteChip : undefined}
                  />
                </Grid>
              ))
            ) : (
              <Typography color="text.secondary">Empty</Typography>
            )}
          </Grid>
        </CardContent>
      </Card>
      <Modal onClose={closeModal} open={showAddChipModal}>
        <Card
          sx={{
            width: '500px',
          }}
        >
          <CardHeader title={modalTitle || 'Add'} />
          <CardContent>
            <TextField
              multiline
              fullWidth
              value={newChip}
              onChange={(e) => setNewChip(e.target.value)}
            />
          </CardContent>
          <CardActions
            sx={{
              padding: 2,
            }}
          >
            <Stack
              direction="row"
              spacing={2}
              justifyContent="space-between"
              width="100%"
            >
              <Button color="error" onClick={closeModal}>
                Cancel
              </Button>
              <Button
                variant="contained"
                color="secondary"
                disabled={!newChip}
                onClick={() => handleChange(newChip?.trim(), onAdd)}
              >
                Add
              </Button>
            </Stack>
          </CardActions>
        </Card>
      </Modal>
    </>
  );
}
