import { useState } from 'react';
import { SubmitHandler, useForm } from 'react-hook-form';
import useNotify from 'src/lib/hooks/useNotify';
import useModalStore from 'src/lib/stores/useModalStore';

import { SGMPromptEmitter } from '../../../events/SGMPromptEmitter';
import { createMakerPrompt } from '../../../services/createMakerPrompt';
import { updateMakerPrompt } from '../../../services/updateMakerPrompt';

type SGMPromptForm = {
  type: string;
  title: string;
  description: string;
  prompt: string;
};

export function useCreateSGMPrompt(sgmPrompt?: SGMPromptForm) {
  const notify = useNotify();
  const form = useForm<SGMPromptForm>({
    defaultValues: sgmPrompt ?? {
      title: '',
      description: '',
      prompt: '',
      type: '',
    },
  });

  const [isSubmitting, setIsSubmitting] = useState<boolean>(false);

  const onSubmitForm: SubmitHandler<SGMPromptForm> = async (data) => {
    setIsSubmitting(true);

    try {
      if (sgmPrompt) {
        const { data: gameCategory, error } = await updateMakerPrompt(
          data.type,
          data
        );
        if (error) {
          notify(error, { variant: 'error' });
        } else {
          notify('SGM Prompt Updated!', { variant: 'success' });
          SGMPromptEmitter.emit('SGMPromptUpdated', gameCategory?.uuid);
        }
      } else {
        const { data: gameCategory, error } = await createMakerPrompt(
          data.type,
          data
        );
        if (error) {
          notify(error, { variant: 'error' });
        } else {
          notify('SGM Prompt created!', { variant: 'success' });
          SGMPromptEmitter.emit('SGMPromptCreated', gameCategory?.uuid);
        }
      }
    } catch (error: any) {
      notify(error?.message || error?.description, { variant: 'error' });
    } finally {
      setIsSubmitting(false);
      handleCloseModal();
    }
  };

  const onClose = useModalStore((state) => state.closeModal);

  const handleCloseModal = () => {
    onClose();
  };

  return {
    onSubmitForm,
    form,
    handleCloseModal,
    isSubmitting,
  };
}
