import { useNavigate } from 'react-router';
import Routes from 'src/routes/Routes';

export default function useMakerDialogsTable() {
  const navigate = useNavigate();

  const handleOpenMakerDialog = (promptDialogUuid: string) => {
    navigate(Routes.sgm.dialog(promptDialogUuid));
  };

  return {
    handleOpenMakerDialog,
  };
}
