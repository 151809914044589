import {
  Autocomplete,
  Card,
  CardContent,
  InputAdornment,
  TextField,
  debounce,
} from '@mui/material';
import { GridSortDirection } from '@mui/x-data-grid';
import Flex from 'src/components/shared/Flex';
import useURLSearchParams from 'src/lib/hooks/useURLSearchParams';
import HeaderBreadcrumbs from 'src/template/components/HeaderBreadcrumbs';
import Iconify from 'src/template/components/Iconify';
import NFTSForPacksTable from '../../components/NFTSForPackTable';
import useNFTSForPacksView from './useView';

const searchOptions = [
  {
    label: 'Name',
    query: 'name',
  },
  {
    label: 'Collection Name',
    query: 'collectionName',
  },
];

export default function NFTSForPacks(): JSX.Element {
  const { data, isLoading, order, page, query, searchBy, size, sortBy } =
    useNFTSForPacksView();

  const { addParam, removeParam } = useURLSearchParams();

  function handleChangeSearch(value: string, param: string = 'query') {
    if (!value) removeParam(param);
    else addParam(param, value);
  }

  const debouncedSearch = debounce(handleChangeSearch, 300);

  return (
    <>
      <HeaderBreadcrumbs
        heading="Nfts Available For Nft Packs"
        links={[{ name: 'Nfts Available For Nft Packs' }]}
      />

      <Card>
        <Flex gap={2} p={2}>
          <Autocomplete
            options={searchOptions}
            fullWidth
            renderInput={(params) => (
              <TextField label="Search By" {...params} />
            )}
            getOptionLabel={(p) => p.label}
            sx={{ maxWidth: 250 }}
            defaultValue={searchOptions.find((o) => o.query === searchBy)}
            key={searchBy}
            onChange={(_, value) => {
              if (!value) removeParam('searchBy');
              else addParam('searchBy', value.query);
            }}
          />

          <TextField
            fullWidth
            label="Search"
            defaultValue={query}
            type="search"
            onChange={(e) => {
              const { value } = e.target;
              debouncedSearch(value);
            }}
            key={query}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <Iconify
                    icon="eva:search-fill"
                    sx={{ color: 'text.disabled', width: 20, height: 20 }}
                  />
                </InputAdornment>
              ),
            }}
          />
        </Flex>

        <CardContent>
          <NFTSForPacksTable
            data={data ?? []}
            isLoading={isLoading}
            page={page}
            size={size}
            sortBy={sortBy!}
            order={order as GridSortDirection}
          />
        </CardContent>
      </Card>
    </>
  );
}
