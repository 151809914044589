import { RateReviewRounded } from '@mui/icons-material';
import { Button, Stack } from '@mui/material';
import { useState } from 'react';
import { Navigate, useParams } from 'react-router';
import SectionDivider from 'src/components/shared/SectionDivider';
import useNotify from 'src/lib/hooks/useNotify';
import { fetchPaymentRequest } from 'src/lib/services/paymentRequests';
import { executePaymentRequest } from 'src/lib/services/stripe';
import { PaymentRequestStatus } from 'src/lib/types/paymentRequest';
import { getTruncatedText } from 'src/lib/utils/formatters';
import HeaderBreadcrumbs from 'src/template/components/HeaderBreadcrumbs';
import LoadingScreen from 'src/template/components/LoadingScreen';
import PaymentAmount from './PaymentAmount';
import PaymentRequestDetails from './PaymentRequestDetails';
import PaymentRequestInfo from './PaymentRequestInfo';
import UpdatePaymentRequestStatusModal from './UpdateStatus';

export default function Payment() {
  const notify = useNotify();
  const { uuid } = useParams<'uuid'>();
  if (!uuid) return <Navigate to="/payment-requests" replace />;

  const [showStatusModal, setShowStatusModal] = useState(false);

  const { data: paymentRequest, loading } = fetchPaymentRequest(uuid);

  if (loading && !paymentRequest) return <LoadingScreen />;

  const executeRequestWithStripe = async () => {
    if (!uuid) return;
    const res = await executePaymentRequest(uuid);
    notify(res.message, { variant: res.success ? 'success' : 'error' });
  };

  return paymentRequest ? (
    <>
      <HeaderBreadcrumbs
        heading="Payment Request"
        links={[
          { name: 'Payment Requests', href: '/payment-requests' },
          { name: getTruncatedText(paymentRequest.uuid, 6, 4) || 'Empty Name' },
        ]}
        action={
          <Stack direction="row" spacing={2}>
            {paymentRequest.status !== 'executed' && (
              <>
                {/*
                <Button
                  variant="contained"
                  size="small"
                  onClick={() => executeRequestWithStripe()}
                  startIcon={<RateReviewRounded />}
                >
                  Execute With Stripe
                </Button>
            */}
                <Button
                  variant="contained"
                  size="small"
                  color="secondary"
                  onClick={() => {
                    setShowStatusModal(true);
                  }}
                  startIcon={<RateReviewRounded />}
                  disabled={[
                    PaymentRequestStatus.CANCELED,
                    PaymentRequestStatus.EXECUTED,
                    PaymentRequestStatus.REJECTED,
                  ].includes(paymentRequest.status)}
                >
                  Update Status
                </Button>
              </>
            )}
          </Stack>
        }
      />
      <SectionDivider section="Payment Request" />
      <Stack direction="row" spacing={2}>
        <PaymentRequestInfo paymentRequest={paymentRequest} />
      </Stack>

      <SectionDivider section="From" />
      <PaymentRequestDetails
        userUuid={paymentRequest.userUuid}
        paymentRequest={paymentRequest}
      />

      <SectionDivider section="Custom Payment" />
      <PaymentAmount paymentRequest={paymentRequest} />
      <UpdatePaymentRequestStatusModal
        onClose={() => {
          setShowStatusModal(false);
        }}
        open={showStatusModal}
        paymentRequest={paymentRequest}
      />
    </>
  ) : null;
}
