import { Container } from '@mui/material';
import Pool from 'src/components/modules/Pools/view/Pool/Pool';
import Page from 'src/template/components/Page';

export default function PoolPage() {
  return (
    <Page title="Pool">
      <Container maxWidth="xl">
        <Pool />
      </Container>
    </Page>
  );
}
