import { AxiosInstance } from 'axios';
import getAPIClient from 'src/lib/services/api/axios.config';
import { ServiceResponse } from 'src/lib/types/ServiceResponse';
import { Collection } from 'src/lib/types/collection';
import { getMessageFromError } from 'src/lib/utils/error';
import { CollectionEmitter } from '../../events/CollectionEmitter';
import { ICollectionService } from './interfaces';

export class CollectionService implements ICollectionService {
  constructor(private readonly api: AxiosInstance) {}

  async update(
    id: string,
    values: Partial<Collection>
  ): Promise<ServiceResponse<Collection>> {
    try {
      const { data } = await this.api.post(`admin/collections`, {
        ...values,
        uuid: id,
      });
      CollectionEmitter.emit('collectionUpdated', data);
      return {
        data,
        error: null,
      };
    } catch (e) {
      return {
        data: null,
        error: getMessageFromError(e),
      };
    }
  }
}

export async function makeCollectionService(): Promise<ICollectionService> {
  return new CollectionService(await getAPIClient());
}
