import React, { ReactElement } from 'react';
import { ChannelWithAdminAnalytics } from 'src/lib/types/channelWithAdminAnalytics';
import { OBJECT_MODERATION_OBJECT_TYPE } from 'src/lib/types/objectModeration';
import Iconify, { IconifyProps } from 'src/template/components/Iconify';

const MainInfo = React.lazy(
  () => import('src/components/modules/Channels/view/MainInfo')
);
const ObjectModerationTab = React.lazy(
  () => import('src/components/modules/Review/components/ModerationObjectsTab')
);

export enum ChannelTabs {
  MAIN = 'main_info',
  MODERATION = 'moderation',
}

interface IChannelTabs {
  value: ChannelTabs;
  icon: ReactElement<IconifyProps>;
  component: (channel: ChannelWithAdminAnalytics) => JSX.Element;
}

const CHANNEL_TABS: IChannelTabs[] = [
  {
    value: ChannelTabs.MAIN,
    icon: (
      <Iconify icon="material-symbols:info-outline" width={20} height={20} />
    ),
    component: (channel) => <MainInfo channelDetails={channel} />,
  },
  {
    value: ChannelTabs.MODERATION,
    icon: <Iconify icon="mdi:shield-account" width={20} height={20} />,
    component: (channel) => (
      <ObjectModerationTab
        objectType={OBJECT_MODERATION_OBJECT_TYPE.CHANNEL}
        objectUuid={channel.uuid}
        showHeader
      />
    ),
  },
];

export default CHANNEL_TABS;
