import { UserRank } from 'src/components/modules/Users/types/Ranking';

export enum AvailableSellerStatisticsToRank {
  volume = 'volume',
  averageSalesPrice = 'averageSalesPrice',
  sales = 'sales',
}

export enum AvailableBuyerStatisticsToRank {
  volume = 'volume',
  averagePurchasePrice = 'averagePurchasePrice',
  purchases = 'purchases',
}

export type SellerRankingParams = {
  rankBy: AvailableSellerStatisticsToRank;
  country?: string;
  lang?: string;
  page?: number;
  size?: number;
};

export type BuyerRankingParams = {
  rankBy: AvailableBuyerStatisticsToRank;
  country?: string;
  lang?: string;
  page?: number;
  size?: number;
};

export type BuyerStatistics = {
  volume: number;
  averagePurchasePrice: number;
  purchases: number;
};
export type ParsedBuyerStatistics = {
  volume: string;
  averagePurchasePrice: string;
  purchases: string;
};

export type BuyerWithRank = UserRank & BuyerStatistics & { pos: number };
export type ParsedBuyerWithRank = UserRank &
  ParsedBuyerStatistics & { pos: number };
export type SellerStatistics = {
  volume: number;
  averageSalesPrice: number;
  sales: number;
};
export type ParsedSellerStatistics = {
  volume: string;
  averageSalesPrice: string;
  sales: string;
};

export type SellerWithRank = UserRank & SellerStatistics & { pos: number };
export type ParsedSellerWithRank = UserRank &
  ParsedSellerStatistics & { pos: number };

export type SellerBuyerTotalStatistics = {
  totalCount: number;
  totalVolume: number;
  totalTransactions: number;
  totalAveragePrice: number;
};
