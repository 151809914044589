import { CloseOutlined } from '@mui/icons-material';
import {
  Card,
  CardContent,
  CardHeader,
  IconButton,
  MenuItem,
  Stack,
  TextField,
} from '@mui/material';
import { useState } from 'react';
import Button from 'src/components/shared/Buttons/CustomButton';
import Modal from 'src/components/shared/Modal';
import useNotify from 'src/lib/hooks/useNotify';
import { updateReport } from 'src/lib/services/reports';
import { Report, ReportStatus } from 'src/lib/types/report';
import { REPORT_STATUS_OPTIONS } from './utils';

type UpdateReportModalProps = {
  report: Report;
  showModal: boolean;
  setShowModal: (show: boolean) => void;
  queryParams: string;
};

export default function UpdateReportModal({
  report,
  showModal,
  setShowModal,
  queryParams,
}: UpdateReportModalProps) {
  const [isLoading, setIsLoading] = useState(false);
  const [editedReport, setEditedReport] = useState<Partial<Report>>(report);

  const notify = useNotify();

  const onSave = async () => {
    if (!editedReport) return;
    setIsLoading(true);

    const res = await updateReport(
      report.uuid,
      { ...editedReport },
      queryParams
    );
    notify(res.message, { variant: res.success ? 'success' : 'error' });

    setShowModal(!res.success);
    setIsLoading(false);
  };

  return (
    <Modal open={showModal} onClose={() => setShowModal(false)}>
      <Card sx={{ width: '300px' }}>
        <IconButton
          sx={{
            position: 'absolute',
            top: 20,
            right: 20,
          }}
          onClick={() => setShowModal(false)}
        >
          <CloseOutlined />
        </IconButton>
        <CardHeader title="Update Report" />
        <CardContent>
          <Stack spacing={2} justifyContent="center" mb={2}>
            <TextField
              id="outlined-select-currency"
              select
              label="Status"
              value={editedReport?.status || ''}
              onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
                setEditedReport((prev) => ({
                  ...prev,
                  status: REPORT_STATUS_OPTIONS.find(
                    (opt) => opt.value === event?.target?.value
                  )?.value as ReportStatus,
                }))
              }
            >
              {REPORT_STATUS_OPTIONS.filter((opt) => opt.value !== 'all').map(
                (opt) => (
                  <MenuItem key={opt.value} value={opt.value}>
                    {opt.label}
                  </MenuItem>
                )
              )}
            </TextField>
          </Stack>

          <Stack direction="row" spacing={2} justifyContent="space-between">
            <Button
              color="error"
              onClick={() => {
                setEditedReport({});
              }}
            >
              Clear
            </Button>
            <Button
              loading={isLoading}
              color="secondary"
              variant="contained"
              disabled={!editedReport}
              onClick={onSave}
            >
              Save
            </Button>
          </Stack>
        </CardContent>
      </Card>
    </Modal>
  );
}
