import { yupResolver } from '@hookform/resolvers/yup';
import { useState } from 'react';
import { useForm } from 'react-hook-form';
import useModalStore from 'src/lib/stores/useModalStore';
import { ObjectLinkTypes } from 'src/lib/types/objectLink';
import { uploadToStorage } from 'src/lib/utils/upload';
import * as yup from 'yup';
import { UpdateChannelFormValues } from './types';

const validationSchema = yup.object().shape({
  name: yup.string().required('Name is required'),
  description: yup.string().required('Description is required'),
});

function useUpdateChannelFormView({
  defaultValues,
}: {
  defaultValues: UpdateChannelFormValues;
}) {
  const onClose = useModalStore((state) => state.closeModal);
  const form = useForm<UpdateChannelFormValues>({
    defaultValues,
    resolver: yupResolver(validationSchema),
  });

  const handleCloseModal = () => {
    onClose();
  };

  // image
  const [isUpdatingBackgroundImage, setIsUpdatingBackgroundImage] =
    useState<boolean>(false);

  const onUpdateImage = async (file: any) => {
    try {
      const url = await uploadToStorage('channel/content', file);
      form.setValue('backgroundImageUrl', url);
    } finally {
      setIsUpdatingBackgroundImage(false);
    }
  };

  /* Object Links */
  const objectLinkProps = {
    form,
    objectLinksToCreateField: 'objectLinks',
    objectLinksTempField: 'objectLinksTempSearch',
    modelsToSearch: [
      ObjectLinkTypes.COLLECTION,
      ObjectLinkTypes.CLUB,
      ObjectLinkTypes.GAME,
    ],
  };

  return {
    form,
    handleCloseModal,
    objectLinkProps,
    isUpdatingBackgroundImage,
    onUpdateImage,
  };
}

export default useUpdateChannelFormView;
