import Flex from 'src/components/shared/Flex';
import NumberInfo from 'src/components/shared/NumberInfo/NumberInfo';

interface MakerDialogStatsCardInfosProps {
  uniqueMessages: number;
  messagesByModel: {
    [key: string]: number;
  };
}

export default function MakerDialogStatsCardInfos({
  uniqueMessages,
  messagesByModel,
}: MakerDialogStatsCardInfosProps) {
  return (
    <Flex
      gap={3}
      alignItems="center"
      justifyContent="center"
      my={2}
      flexWrap={'wrap'}
    >
      <NumberInfo title="# Messages" total={uniqueMessages} />
      {Object.keys(messagesByModel).map((key) => (
        <NumberInfo
          key={key}
          title={`Messages by ${key}`}
          total={messagesByModel[key]}
        />
      ))}
    </Flex>
  );
}
