import { Card, CardContent } from '@mui/material';
import Modal from 'src/components/shared/Modal';
import { ExternalClubAsset } from 'src/lib/types/club';
import ExternalAssetsTable from '../../components/ExternalAssetsTable';
import useAddExternalAssetView from './useView';

type Props = {
  chats: ExternalClubAsset[];
  topics: ExternalClubAsset[];
};

export default function ListExternalAssetsView({
  chats,
  topics,
}: Props): JSX.Element {
  const { handleCloseModal, params, handleRemoveAsset } =
    useAddExternalAssetView();

  return (
    <Modal open onClose={handleCloseModal}>
      <Card>
        <CardContent>
          <ExternalAssetsTable
            data={[...chats, ...topics]}
            isLoading={false}
            params={params}
            handleRemoveAsset={handleRemoveAsset}
          />
        </CardContent>
      </Card>
    </Modal>
  );
}
