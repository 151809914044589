import { Container } from '@mui/material';
import NotProcessedConsumables from 'src/components/modules/RevenueEvents/view/NotProcessedConsumables';
import Page from 'src/template/components/Page';

export default function NotProcessedConsumablesPage(): JSX.Element {
  return (
    <Page title="Not Processed Consumables">
      <Container maxWidth="xl">
        <NotProcessedConsumables />
      </Container>
    </Page>
  );
}
