import { format } from 'date-fns';
import { getMessageFromError } from 'src/lib/utils/error';
import useSWR, { SWRConfiguration, SWRResponse } from 'swr';
import { NotificationHealth } from '../types/NotificationHealth';

export function fetchNotificationsHealth(
  config?: SWRConfiguration
): SWRResponse<NotificationHealth[]> {
  const { data, error, ...response } = useSWR(
    `admin/health/notifications`,
    null,
    config
  );

  return {
    ...response,
    data: data?.map((item: NotificationHealth) => ({
      ...item,
      lastCheck: item?.lastCheck
        ? format(new Date(item.lastCheck), 'dd/MM/yyyy hh:MM')
        : '--',
    })),
    error: error ? getMessageFromError(error) : undefined,
  };
}
