import Flex, { Props } from '../Flex';

export default function MagicTableFilterContainer({
  children,
  ...props
}: Props): JSX.Element {
  return (
    <Flex gap={2} p={2} {...props}>
      {children}
    </Flex>
  );
}
