import { NftPackDetails } from 'src/lib/types/nftPack';
import useSWR, { SWRResponse } from 'swr';

export const fetchNftPackDetails = (
  uuid: string
): SWRResponse<NftPackDetails> => {
  const response = useSWR(`admin/nft-pack/${uuid}/details`);

  return response;
};
