import { sgmFetcher } from 'src/lib/services/sgm/fetcher';
import { SGMPrompt, SGMPromptType } from 'src/lib/types/sgmPrompt';
import useSWR, { SWRResponse } from 'swr';

export const fetchMakerPrompt = (
  type: SGMPromptType
): SWRResponse<SGMPrompt> => {
  const response = useSWR<SGMPrompt, Error>(
    `/api/admin/prompt/${type}`,
    sgmFetcher
  );

  return response;
};
