import { useEffect, useState } from 'react';
import Button from 'src/components/shared/Buttons/CustomButton';
import { fetchWalletVerificationStatus } from '../../services/fetchWalletVerificationStatus';
import { triggerWalletVerification } from '../../services/triggerWalletVerification';

export default function TriggerWalletVerificationButtonView(): JSX.Element {
  const [isJobRunning, setIsJobRunning] = useState(false);

  const handleTriggerWalletVerification = async () => {
    const { data, error } = await triggerWalletVerification();
    if (data?.data?.success) {
      setIsJobRunning(true);
    }
  };

  const { data: statusData, mutate } = fetchWalletVerificationStatus({
    ...(isJobRunning && { refreshInterval: 10000 }),
  });

  useEffect(() => {
    setIsJobRunning(statusData?.status === 'running');
    mutate();
  }, [statusData, mutate, isJobRunning]);

  return (
    <Button
      variant="outlined"
      size="large"
      color={'success'}
      aria-label="verify wallets inconsistency"
      onClick={handleTriggerWalletVerification}
      loading={false}
      disabled={isJobRunning}
    >
      {isJobRunning
        ? 'Verifying inconsistencies...'
        : 'Check for new inconsistencies'}
    </Button>
  );
}
