import { FeedItemAnalyticsTableRow } from 'src/lib/types/FeedItem';
import useSWR, { SWRResponse } from 'swr';

export const fetchChannelContentDetail = (
  uuid: string
): SWRResponse<FeedItemAnalyticsTableRow> => {
  const response = useSWR(`admin/feed-item/${uuid}`);

  return response;
};
