import { Game } from 'src/lib/types/game';
import { GameVersion } from 'src/lib/types/gameVersion';
import { createEmitter } from 'src/lib/utils/createEmitter';

export const GameEmitter = createEmitter<{
  GameCreated: Game;
  GameUpdated: Game;
  GameDeleted: Game;
  GameVersionCreated: GameVersion;
  GameVersionDeleted: GameVersion;
  GameVersionUpdated: GameVersion;
  GameStatusChanged: string;
  GameDescriptionPromptUpdated: string;
}>();
