import { Edit } from '@mui/icons-material';
import { TabContext, TabList, TabPanel } from '@mui/lab';
import { Box, Card, Divider, Grid, Stack, Tab } from '@mui/material';
import { useCallback, useEffect, useState } from 'react';
import { Navigate, useParams } from 'react-router';
import ObjectModerationTab from 'src/components/modules/Review/components/ModerationObjectsTab';
import Button from 'src/components/shared/Buttons/CustomButton';
import { BlockchainNetwork } from 'src/lib/constants/blockchain';
import {
  createOrUpdateCollection,
  fetchCollection,
} from 'src/lib/services/collections';
import { OBJECT_MODERATION_OBJECT_TYPE } from 'src/lib/types/objectModeration';
import { uploadToStorage } from 'src/lib/utils/upload';
import Routes from 'src/routes/Routes';
import HeaderBreadcrumbs from 'src/template/components/HeaderBreadcrumbs';
import Iconify from 'src/template/components/Iconify';
import Image from 'src/template/components/Image';
import LoadingScreen from 'src/template/components/LoadingScreen';
import useTabs from 'src/template/hooks/useTabs';
import NftsGrid from '../../../NFT/NFTsGrid';
import CollectionAddressCard from '../../CollectionAddressCard';
import CollectionCreatorCard from '../../CollectionCreatorCard';
import CollectionAbout from '../components/Collection/CollectionAbout';
import CollectionActions from '../components/Collection/CollectionActions';
import CollectionChipsCard from '../components/Collection/CollectionChipsCard';
import CollectionCover from '../components/Collection/CollectionCover';
import CollectionDescription from '../components/Collection/CollectionDescription';
import CollectionSales from '../components/Collection/CollectionSales';
import CollectionSocialMedia from '../components/SocialMedia/CollectionSocialMedia';
import { CollectionEmitter } from '../events/CollectionEmitter';

export default function Collection() {
  const { uuid } = useParams<'uuid'>();
  if (!uuid) return <Navigate to={Routes.objects.collections} replace />;
  const [isLoading, setIsLoading] = useState(false);
  const { currentTab: selectedTab, onChangeTab } = useTabs('nfts');
  const {
    data: collection,
    loading: collectionLoading,
    mutate,
  } = fetchCollection(uuid);

  const onEditBanner = async (files: any) => {
    setIsLoading(true);

    if (collection) {
      const originalsFileUrl = await uploadToStorage(collection.uuid, files[0]);

      await createOrUpdateCollection({
        ...collection,
        originalsFileUrl: originalsFileUrl as string,
      });
    }

    setIsLoading(false);
  };

  const refetchCollection = useCallback(() => {
    mutate?.();
  }, [mutate]);

  useEffect(() => {
    CollectionEmitter.on('collectionUpdated', refetchCollection);
    return () => {
      CollectionEmitter.off('collectionUpdated', refetchCollection);
    };
  }, [refetchCollection]);

  if (collectionLoading) return <LoadingScreen />;

  return !collection ? (
    <Navigate to={Routes.objects.collections} />
  ) : (
    <>
      <HeaderBreadcrumbs
        heading={collection?.name ?? 'Empty Name'}
        links={[
          { name: 'Collections', href: Routes.objects.collections },
          { name: collection?.name ?? 'Empty Name' },
        ]}
        action={<CollectionActions collection={collection} />}
      />

      <Stack spacing={3}>
        <Grid padding={0} container spacing={3}>
          <Grid item xs={12} md={3}>
            <Stack spacing={2}>
              <CollectionCover collection={collection} />
              {collection?.user && (
                <CollectionCreatorCard {...collection.user} />
              )}
              {collection.blockchain !== BlockchainNetwork.STICKY &&
                (collection?.externalWallet || collection?.mintToken) && (
                  <CollectionAddressCard collection={collection} />
                )}
              <CollectionAbout collection={collection} />
              <CollectionDescription collection={collection} />
              <CollectionSocialMedia collection={collection} />
              <CollectionChipsCard
                chips={collection?.categories?.map((c) => c.name) ?? []}
                title="Categories"
              />
              <CollectionChipsCard
                chips={collection?.tags ?? []}
                title="Tags"
                modalTitle="Add Tag"
                onDelete={(tag) => {
                  if (!tag) return Promise.resolve(null);
                  const tags = collection?.tags?.filter((t) => t !== tag);
                  return createOrUpdateCollection({ ...collection, tags });
                }}
                onAdd={(tag) => {
                  if (!tag) return Promise.resolve(null);
                  const tags = [
                    ...(collection?.tags?.filter((t) => t !== tag) ?? []),
                    tag,
                  ];
                  return createOrUpdateCollection({ ...collection, tags });
                }}
              />
            </Stack>
          </Grid>
          <Grid item xs={12} md={9}>
            {collection?.originalsFileUrl && (
              <Card
                sx={{
                  position: 'relative',
                  '&:hover': {
                    '& .edit-banner-button': {
                      opacity: 1,
                    },
                  },
                }}
              >
                <Image
                  src={collection?.originalsFileUrl}
                  alt="Collection Cover Image"
                  sx={{
                    aspectRatio: '37 / 9',
                    '&:hover': {
                      opacity: 0.7,
                    },
                  }}
                />
                <Button
                  startIcon={<Edit />}
                  className="edit-banner-button"
                  loading={isLoading}
                  sx={{
                    position: 'absolute',
                    opacity: 0,
                    height: '100%',
                    width: '100%',
                    color: 'white !important',
                    top: '50%',
                    left: '50%',
                    transform: 'translate(-50%, -50%)',
                    '&:hover': {
                      backgroundColor: '#00000080',
                    },
                  }}
                >
                  Edit Banner
                  <input
                    type="file"
                    hidden
                    accept="image/*"
                    multiple={false}
                    onChange={(e) => onEditBanner(e.target.files)}
                  />
                </Button>
              </Card>
            )}
            <TabContext value={selectedTab ?? 'nfts'}>
              <Stack spacing={3}>
                <Box>
                  <TabList onChange={onChangeTab}>
                    <Tab value="nfts" label="NFTs" />
                    <Tab value="sales" label="Sales" />
                    <Tab
                      value="Moderatoin"
                      label="Moderation"
                      icon={
                        <Iconify
                          icon="mdi:shield-account"
                          width={20}
                          height={20}
                        />
                      }
                    />
                  </TabList>
                  <Divider />
                </Box>
                <TabPanel value="nfts">
                  <NftsGrid collectionUuid={collection.uuid} />
                </TabPanel>
                <TabPanel value="sales">
                  <CollectionSales collectionId={uuid} />
                </TabPanel>
                <TabPanel value="Moderatoin">
                  <ObjectModerationTab
                    objectType={OBJECT_MODERATION_OBJECT_TYPE.COLLECTION}
                    objectUuid={uuid}
                  />
                </TabPanel>
              </Stack>
            </TabContext>
          </Grid>
        </Grid>
      </Stack>
    </>
  );
}
