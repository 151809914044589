import { Container } from '@mui/material';
import Refunds from 'src/components/modules/Refunds/Refunds';
import Page from 'src/template/components/Page';

export default function RefundsPage() {
  return (
    <Page title="Refunds">
      <Container maxWidth="xl">
        <Refunds />
      </Container>
    </Page>
  );
}
