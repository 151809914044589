import { ContentCopy } from '@mui/icons-material';
import {
  Autocomplete,
  MenuItem,
  TableCell,
  TableRow,
  TextField,
  Tooltip,
} from '@mui/material';
import CopyToClipboard from 'react-copy-to-clipboard';
import Link from 'src/components/shared/Link';
import MoreMenu from 'src/components/shared/MoreMenu';
import SmartTable, {
  SmartColumn,
} from 'src/components/shared/SmartTable/SmartTable';
import { fetchFeedbacks } from 'src/lib/services/feedback';
import createFilterStore from 'src/lib/stores/createFilterStore';
import { AppPlatforms } from 'src/lib/types/collection';
import { LabelValue } from 'src/lib/types/labelValue';
import { formatDate, getTruncatedText } from 'src/lib/utils/formatters';
import HeaderBreadcrumbs from 'src/template/components/HeaderBreadcrumbs';
import Label from 'src/template/components/Label';

export default function Feedbacks() {
  const { filter, setFilter } = useFilter();

  const { data: feedbacks, total, loading } = fetchFeedbacks(filter);

  return (
    <>
      <HeaderBreadcrumbs heading="Feedbacks" links={[{ name: 'Feedbacks' }]} />
      <SmartTable
        columns={feedbackColumns}
        data={feedbacks}
        filter={filter}
        searchForOptions={searchForOptions}
        total={total}
        setFilter={setFilter}
        loading={loading}
        cardProps={{
          sx: {
            mt: '1rem',
          },
        }}
        menu={
          <Autocomplete
            fullWidth
            sx={{
              width: '500px',
            }}
            renderInput={(params) => <TextField {...params} label="Platform" />}
            value={null}
            onChange={(e, newValue) =>
              setFilter({
                custom: {
                  platform: {
                    like:
                      newValue?.id === 'all'
                        ? undefined
                        : newValue?.id ?? undefined,
                  },
                },
              })
            }
            options={[
              { label: 'All', id: 'all' },
              ...Object.values(AppPlatforms)
                .map((value) => ({
                  label: value,
                  id: value,
                }))
                .filter((p) => p.id !== AppPlatforms.imported),
            ]}
          />
        }
      >
        {feedbacks &&
          feedbacks.map((feedback) => (
            <Tooltip
              title={feedback.message}
              placement="left"
              arrow
              key={feedback.uuid}
            >
              <TableRow key={feedback.uuid}>
                <TableCell
                  sx={{
                    maxWidth: '500px',
                    textOverflow: 'ellipsis',
                    overflow: 'hidden',
                    whiteSpace: 'nowrap',
                  }}
                >
                  {feedback.message}
                </TableCell>
                <TableCell>
                  {feedback.userUuid ? (
                    <Link to={`/users/${feedback.userUuid}`}>
                      {feedback.user?.name ||
                        feedback.user?.slug ||
                        feedback.user?.email ||
                        getTruncatedText(feedback.userUuid, 4, 3)}
                    </Link>
                  ) : (
                    '-'
                  )}
                </TableCell>
                <TableCell>
                  {feedback.user && feedback.user.active ? (
                    <Label color="success">Active</Label>
                  ) : (
                    <Label color="error">Inactive</Label>
                  )}
                </TableCell>
                <TableCell>
                  {feedback.userHasProSubscription ? (
                    <Label color="success">Yes</Label>
                  ) : (
                    <Label color="error">No</Label>
                  )}
                </TableCell>
                <TableCell>{feedback.platform}</TableCell>
                <TableCell>{feedback.type}</TableCell>
                <TableCell align="right">
                  {formatDate(feedback.createdAt)}
                </TableCell>
                <TableCell align="right">
                  <MoreMenu>
                    <CopyToClipboard text={feedback.uuid}>
                      <MenuItem aria-label="copy feed uuid">
                        <ContentCopy />
                        Copy UUID
                      </MenuItem>
                    </CopyToClipboard>
                  </MoreMenu>
                </TableCell>
              </TableRow>
            </Tooltip>
          ))}
      </SmartTable>
    </>
  );
}

const feedbackColumns: SmartColumn[] = [
  {
    id: 'message',
    isSortable: false,
    label: 'Message',
  },
  {
    id: 'user',
    isSortable: false,
    label: 'User',
  },
  {
    id: 'user.active',
    isSortable: false,
    label: 'User Status',
  },
  {
    id: 'userHasProSubscription',
    isSortable: false,
    label: 'User Has Pro ',
  },
  {
    id: 'platform',
    isSortable: false,
    label: 'Platform',
  },
  {
    id: 'type',
    isSortable: false,
    label: 'Type',
  },
  {
    id: 'createdAt',
    align: 'right',
    isSortable: true,
    label: 'Created At',
  },
  {
    id: 'actions',
    label: 'Actions',
    isSortable: false,
  },
];

const searchForOptions: LabelValue[] = [
  { label: 'UUID', value: 'uuid' },
  { label: 'User UUID', value: 'user.uuid' },
  { label: 'User Email', value: 'user.email' },
  { label: 'User Name', value: 'user.name' },
];

const getSearchParams = () => {
  const searchParams = new URLSearchParams(window.location.search);
  return {
    searchFor: searchParams.get('searchFor') || 'uuid',
    query: searchParams.get('query') || '',
  };
};

const useFilter = createFilterStore({
  orderBy: 'createdAt',
  order: 'desc',
  searchFor: getSearchParams().searchFor ?? 'uuid',
  query: getSearchParams().query ?? '',
  page: 0,
  size: 30,
  custom: {},
});
