import { User } from 'src/lib/types/users';

export enum UserPurgeStatus {
  pending = 'pending',
  running = 'running',
  completed = 'completed',
  failed = 'failed',
}

export enum UserPurgeStepStatus {
  pending = 'pending',
  running = 'running',
  completed = 'completed',
  failed = 'failed',
}

export type UserPurgeStep = {
  uuid: string;
  userPurgeUuid: string;
  order: number;
  name: string;
  status: UserPurgeStepStatus;
  executed: number;
  total: number;
  startedAt: Date | null;
  completedAt?: Date | null;
  createdAt: Date;
  updatedAt: Date;
};

export type UserPurge = {
  uuid: string;
  userUuid: string;
  user?: User;
  createdByUserUuid: string;
  createdByUser?: User;
  triggeredByUserUuid: string;
  triggeredByUser?: User;
  status: UserPurgeStatus;
  steps?: UserPurgeStep[];
  startedAt: Date;
  completedAt: Date;
  createdAt: Date;
  updatedAt: Date;
};

export enum PurgeUseCaseStatuses {
  stop_requested = 'stop_requested',
  stopped = 'stopped',
  running = 'running',
  completed = 'completed',
}

export type StatusOfPurgeUseCase = {
  status: PurgeUseCaseStatuses;
  totalPurgesLeft: number;
  remainingTime: number;
  currentPurge?: UserPurge;
};
