import ConfirmationDialogRaw from 'src/components/shared/Dialog/ConfirmationDialog';
import useModalStore from 'src/lib/stores/useModalStore';
import { GameStatus } from 'src/lib/types/game';
import useChangeGameStatus from './useView';

type Props = {
  uuid: string;
  status: GameStatus;
};
export default function ChangeGameStatusView({
  uuid,
  status,
}: Props): JSX.Element {
  const { handleChangeGameStatus } = useChangeGameStatus();
  const closeModal = useModalStore((state) => state.closeModal);

  return (
    <ConfirmationDialogRaw
      onClose={closeModal}
      open
      title={`Change this Game status to ${status}`}
      onConfirm={async () => {
        await handleChangeGameStatus(uuid, status);
      }}
    >
      Are you sure you want change Game status?
    </ConfirmationDialogRaw>
  );
}
