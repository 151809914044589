import { Link as MuiLink, Typography } from '@mui/material';
import { BlockchainNetwork } from 'src/lib/constants/blockchain';
import { User } from 'src/lib/types/users';
import { getTruncatedText } from 'src/lib/utils/formatters';
import getBlockExplorerUrl from 'src/lib/utils/getBlockExplorerUrl';
import getUserName from 'src/lib/utils/getUserName';
import Routes from 'src/routes/Routes';
import Link from './Link';

type Props = {
  user?: User | null;
  externalWallet?: string | null;
  blockchain?: BlockchainNetwork;
};
export default function LinkToUser({
  externalWallet,
  user,
  blockchain,
}: Props): JSX.Element | null {
  if (user) {
    return (
      <Link to={Routes.user(user.uuid)} color="inherit" underline="hover">
        {getUserName(user)}
      </Link>
    );
  }
  if (externalWallet && blockchain) {
    return (
      <MuiLink
        href={getBlockExplorerUrl(externalWallet, blockchain)}
        color="inherit"
        underline="hover"
        target="_blank"
      >
        {getTruncatedText(externalWallet, 4, 3)}
      </MuiLink>
    );
  }
  return <Typography color="GrayText">No user found</Typography>;
}
