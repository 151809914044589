import getAPIClient from 'src/lib/services/api/axios.config';
import { getFormattedError } from 'src/lib/utils/error';

export const deleteEntityNote = async (uuid: string) => {
  try {
    const api = await getAPIClient();
    const res = await api.delete(`admin/entity-notes/${uuid}`);
    return {
      data: res.data,
      error: false,
      message: 'The entity note has been deleted successfully',
    };
  } catch (err) {
    return {
      error: true,
      ...getFormattedError(err),
    };
  }
};
