import { DeepPartial } from 'react-hook-form';
import { User } from 'src/lib/types/users';
import { parseSearchParams } from 'src/lib/utils/parseSearchParams';
import useSWR, { SWRResponse } from 'swr';

type PossibleParams = {
  page: number | string;
  size: number | string;
  order: Record<string, string>;
  filter: {
    active: string | boolean;
    name: string;
    slug: string;
    email: string;
    updatedAt: {
      gte: string;
      lte: string;
    };
    lastSession: {
      gte: string;
      lte: string;
    };
  };
};
export const fetchUsersWithoutWallet = (
  filter?: DeepPartial<PossibleParams>
): SWRResponse<User[]> => {
  const parsedParams = parseSearchParams(filter ?? {});
  // TODO: change this to the correct endpoint
  const response = useSWR<User[], Error>(
    `admin/users/without-wallet?${parsedParams}`
  );

  return response;
};
