import { getDownloadURL, getStorage, ref, uploadBytes } from 'firebase/storage';

/**
 * Uploads HTML content to Firebase Storage and returns the download URL.
 *
 * @param htmlContent - The HTML content to upload.
 * @param filename - The desired filename for the uploaded HTML file.
 * @returns The download URL of the uploaded HTML file.
 */
export const uploadHTMLToFirebase = async (
  htmlContent: string,
  filename: string
): Promise<string> => {
  try {
    const blob = new Blob([htmlContent], { type: 'text/html' });
    const storageRef = ref(getStorage(), `games/${filename}.html`);
    const snapshot = await uploadBytes(storageRef, blob);
    const downloadURL = await getDownloadURL(snapshot.ref);
    return downloadURL;
  } catch (error) {
    console.error('Error uploading HTML to Firebase:', error);
    throw error;
  }
};
