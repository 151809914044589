import MakerStatistics from 'src/components/modules/MakerDialog/view/Statistics';
import Page from 'src/template/components/Page';

export default function MakerStatisticsPage() {
  return (
    <Page title="SGM Statistics">
      <MakerStatistics />
    </Page>
  );
}
