import getAPIClient from 'src/lib/services/api/axios.config';
import { ServiceResponse } from 'src/lib/types/ServiceResponse';
import { Club } from 'src/lib/types/club';
import { linksToCreate } from 'src/lib/types/objectLink';
import { getMessageFromError } from 'src/lib/utils/error';

export type ClubCreationData = Pick<
  Club,
  | 'name'
  | 'description'
  | 'joinMethod'
  | 'photoFileUrl'
  | 'landingPageUrl'
  | 'coverFileUrl'
  | 'createdByUserUuid'
> & {
  objectLinks?: linksToCreate[];
};

export type ExternalAssetsCreationData = {
  assetId: string;
  assetType: string;
};

export type CreateClubData = {
  clubData: ClubCreationData;
  collectionUuids: string[];
  gameUuids: string[];
  externalAssets: ExternalAssetsCreationData[];
};

export async function CreateClub(
  data: CreateClubData
): Promise<ServiceResponse<Club>> {
  try {
    const api = await getAPIClient();
    const res = await api.post(`admin/clubs/`, data);
    return {
      data: res.data,
      error: null,
    };
  } catch (e) {
    return {
      data: null,
      error: getMessageFromError(e),
    };
  }
}
