import MakerPrompts from 'src/components/modules/MakerDialog/view/Prompts';
import Page from 'src/template/components/Page';

export default function MakerPromptsPage() {
  return (
    <Page title="SGM Chat">
      <MakerPrompts />
    </Page>
  );
}
