import Label from 'src/template/components/Label';

type MaliciousUserProps = {
  isFlagged: boolean;
  isBlocked: boolean;
};

export function MaliciousUserLabel({
  isFlagged,
  isBlocked,
}: MaliciousUserProps) {
  if (isFlagged && isBlocked) {
    return <Label color="error">flagged and blocked</Label>;
  }

  if (isBlocked) {
    return <Label color="error">blocked</Label>;
  }

  if (isFlagged) {
    return <Label color="warning">flagged</Label>;
  }

  return <Label color="success">active</Label>;
}
