import { OpenInNew } from '@mui/icons-material';
import { Avatar, IconButton, Link, TableCell, TableRow } from '@mui/material';
import Highlighter from 'react-highlight-words';
import { Link as RouterLink } from 'react-router-dom';
import LinkToUser from 'src/components/shared/LinkToUser';
import { AdminNftTransfer } from 'src/lib/types/adminNftTransfer';
import { formatDate } from 'src/lib/utils/formatters';
import Routes from 'src/routes/Routes';

const highlightStyle = {
  backgroundColor: '#FC6600',
  color: 'white',
};

function searchFormatter(
  text: string,
  colName: string,
  searchHighlight: {
    searchColumn: string;
    searchKeyword: string;
  }
) {
  return searchHighlight.searchColumn === colName ? (
    <Highlighter
      searchWords={[searchHighlight.searchKeyword]}
      textToHighlight={text}
      highlightStyle={highlightStyle}
    />
  ) : (
    text
  );
}

export default function AdminNftTransfersTableRow({
  transfer,
  searchHighlight,
}: {
  transfer: AdminNftTransfer;
  searchHighlight: { searchColumn: string; searchKeyword: string };
}) {
  return (
    <TableRow key={transfer.uuid} hover>
      <TableCell sx={{ display: 'flex', alignItems: 'center' }}>
        <Avatar
          alt={transfer.nft?.name}
          src={transfer.nft?.fileUrl}
          variant="rounded"
          sx={{ mr: 2 }}
        />
        <Link
          component={RouterLink}
          to={Routes.nft(transfer.nftUuid)}
          color={transfer.nft?.name ? 'inherit' : 'text.secondary'}
          underline="hover"
          display="inline-block"
        >
          {searchFormatter(
            transfer.nft?.name ?? 'empty',
            'name',
            searchHighlight
          )}
        </Link>
      </TableCell>
      <TableCell align="center">
        {searchFormatter(
          transfer.editions.toString(),
          'editions',
          searchHighlight
        )}
      </TableCell>
      <TableCell>
        <LinkToUser user={transfer.ownerGlobalWallet?.user} />
      </TableCell>
      <TableCell>
        <LinkToUser user={transfer.receiverGlobalWallet?.user} />
      </TableCell>
      <TableCell>
        <LinkToUser user={transfer.triggeredByUser} />
      </TableCell>
      <TableCell align="right">{formatDate(transfer.updatedAt)}</TableCell>
      <TableCell align="right">{formatDate(transfer.createdAt)}</TableCell>
      <TableCell align="right">
        <IconButton
          component={RouterLink}
          to={Routes.transactions.pool(transfer.poolUuid)}
        >
          <OpenInNew />
        </IconButton>
      </TableCell>
    </TableRow>
  );
}
