import { Container } from '@mui/material';
import HeaderBreadcrumbs from 'src/template/components/HeaderBreadcrumbs';
import ConsumablesTableView from '../../components/ConsumablesTable';

export default function ConsumablesView(): JSX.Element {
  return (
    <Container maxWidth="xl">
      <HeaderBreadcrumbs
        heading="Consumables"
        links={[{ name: 'Consumables List' }]}
      />
      <ConsumablesTableView />
    </Container>
  );
}
