import {
  AttachMoneyRounded,
  BurstModeRounded,
  ImageRounded,
  SupervisedUserCircleRounded,
} from '@mui/icons-material';
import {
  Card,
  CardContent,
  CardHeader,
  CircularProgress,
  Stack,
  SxProps,
  Theme,
  Typography,
} from '@mui/material';
import { fetchCollectionDetails } from 'src/lib/services/collections';
import { SalesDetails } from 'src/lib/types/collection';
import formatStickyCoinValue from 'src/lib/utils/formatStickyCoinValue';

type SalesData = {
  id: string;
  label: string;
  value: string | number;
  icon?: React.ReactNode;
  sx?: SxProps<Theme>;
};

type Props = {
  collectionId: string;
};
export default function CollectionSales({ collectionId }: Props) {
  const { data: details, isLoading } = fetchCollectionDetails(collectionId);

  const getSalesData = (salesDetails: SalesDetails): SalesData[] => [
    // {
    //   id: 'totalNftCopies',
    //   label: 'Total NFT Copies',
    //   value: salesDetails?.totalNftCopies ?? 0,
    //   icon: <GridViewOutlined />,
    // },
    {
      id: 'totalUniqueNftsSold',
      label: 'Total Unique NFTs Sold',
      value: salesDetails?.totalUniqueNftsSold ?? 0,
      icon: <ImageRounded />,
    },
    {
      id: 'totalNftCopiesSold',
      label: 'Total NFT Copies Sold',
      value: salesDetails?.totalNftEditionsSold ?? 0,
      icon: <BurstModeRounded />,
    },
    {
      id: 'uniqueBuyers',
      label: 'Unique Buyers',
      value: salesDetails?.uniqueBuyers ?? 0,
      icon: <SupervisedUserCircleRounded />,
    },
    {
      id: 'totalSalesAmount',
      label: 'Total Sales Amount',
      value: formatStickyCoinValue(salesDetails?.totalSalesAmount ?? 0),
      icon: <AttachMoneyRounded />,
    },
  ];

  if (isLoading) return <CircularProgress />;
  if (!details) return null;

  const fromCreatorData = getSalesData(details);
  return (
    <Stack spacing={2}>
      <Stack direction="row" spacing={2}>
        <Card sx={{ width: '100%', maxWidth: 600 }}>
          <CardHeader title="From Creator" />
          <CardContent>
            <Stack spacing={1}>
              {fromCreatorData.map((data) => (
                <Stack
                  direction="row"
                  spacing={2}
                  justifyContent="space-between"
                  alignItems="center"
                  key={data.label}
                >
                  <Stack direction="row" spacing={1} alignItems="center">
                    {data.icon}
                    <Typography fontSize="14px">{data.label}:</Typography>
                  </Stack>
                  <Typography
                    fontSize="14px"
                    fontWeight="bold"
                    textAlign="right"
                    sx={{ ...data.sx }}
                  >
                    {data.value}
                  </Typography>
                </Stack>
              ))}
            </Stack>
          </CardContent>
        </Card>
      </Stack>
    </Stack>
  );
}
