import { DEFAULT_INITIAL_PAGE_SIZE } from 'src/lib/constants/pagination';
import useModalStore from 'src/lib/stores/useModalStore';
import useTable from 'src/template/hooks/useTable';
import { ExternalAssetsCreationData } from '../../services/createClub';
import DeleteExternalAssetView from '../DeleteExternalAsset';

export default function useListExternalAssetsView() {
  const {
    page,
    rowsPerPage: size,
    order,
    orderBy,
    setRowsPerPage,
    setOrder,
    setPage,
    setOrderBy,
  } = useTable({
    defaultOrderBy: 'uniqueUsersAffecteds',
    defaultOrder: 'desc',
    defaultCurrentPage: 0,
    defaultRowsPerPage: DEFAULT_INITIAL_PAGE_SIZE,
    defaultSearchBy: 'assetType',
  });

  const onClose = useModalStore((state) => state.closeModal);

  const handleCloseModal = () => {
    onClose();
  };

  const openModal = useModalStore((state) => state.openModal);

  const handleRemoveAsset = (
    clubUuid: string,
    assetToRemove: ExternalAssetsCreationData
  ) => {
    openModal(
      <DeleteExternalAssetView
        assetToRemove={assetToRemove}
        clubUuid={clubUuid}
      />
    );
  };

  return {
    params: {
      page,
      size,
      sortOrder: order,
      orderBy,
    },
    setPage,
    setRowsPerPage,
    setOrder,
    setOrderBy,
    handleCloseModal,
    handleRemoveAsset,
  };
}
