import { Container } from '@mui/material';
import Feedbacks from 'src/components/modules/Feedbacks/Feedbacks';

export default function FeedbacksPage() {
  return (
    <Container maxWidth="xl">
      <Feedbacks />
    </Container>
  );
}
