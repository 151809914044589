import getSGMClient from 'src/lib/services/sgm/axios.config';
import { getMessageFromError } from 'src/lib/utils/error';

export async function restoreMakerPrompt(type: string, id: string) {
  try {
    const api = await getSGMClient();
    const res = await api.post(`/api/admin/prompt/${type}/${id}/restore`);
    return {
      data: res.data,
      error: null,
    };
  } catch (e) {
    return {
      data: null,
      error: getMessageFromError(e),
    };
  }
}
