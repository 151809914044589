import {
  Avatar,
  Badge,
  Button,
  IconButton,
  Stack,
  Tooltip,
} from '@mui/material';
import {
  GridSelectionModel,
  GridSortDirection,
  GridSortModel,
} from '@mui/x-data-grid';
import { useCallback, useState } from 'react';
import { CreateMultiplePurges } from 'src/components/modules/UserPurges/services/CreateMultiplePurges';
import Flex from 'src/components/shared/Flex';
import Link from 'src/components/shared/Link';
import MagicTable from 'src/components/shared/MagicTable/MagicTable';
import useNotify from 'src/lib/hooks/useNotify';
import useURLSearchParams from 'src/lib/hooks/useURLSearchParams';
import Routes from 'src/routes/Routes';
import Iconify from 'src/template/components/Iconify';
import Label from 'src/template/components/Label';

import { Send } from '@mui/icons-material';
import Loader from 'src/components/shared/Loader';
import { ParsedBalanceRank } from '../../../types/Ranking';
import { UserType, UserTypeLabel } from '../../../types/UserType';
import { Params } from './types';

type Props = {
  totalCount: number;
  data: ParsedBalanceRank[];
  isLoading: boolean;
  handleOpenFilter: () => void;
  page: number;
  size: number;
  rankBy: string;
  order: string;
};
export default function BalanceRankTableView({
  data,
  totalCount,
  isLoading,
  handleOpenFilter,
  order,
  page,
  rankBy,
  size,
}: Props): JSX.Element {
  const { addParam } = useURLSearchParams<Params>();

  const handleChangeSort = useCallback(
    (model: GridSortModel) => {
      const currentModel = model[0];
      if (currentModel) {
        addParam('rankBy', currentModel.field);
        if (currentModel.sort) {
          addParam('order', currentModel.sort.toUpperCase());
        }
      }
    },
    [addParam]
  );

  const notify = useNotify();
  const [selected, setSelected] = useState<string[]>([]);
  const handleSelect = (newSelection: GridSelectionModel) => {
    const selectedUsers =
      newSelection?.map((uuid) => uuid.toString() ?? '') || [];
    setSelected(selectedUsers);
  };

  const [isSendingToPurgeList, setIsSendingToPurgeList] = useState(false);

  const handleSendToPurgeList = async () => {
    setIsSendingToPurgeList(true);

    const { data: purges, error } = await CreateMultiplePurges(selected);
    if (error) {
      notify(error, { variant: 'error' });
      setIsSendingToPurgeList(false);
      return;
    }

    notify(`${purges?.length} users have been sent to the purge list.`, {
      variant: 'success',
    });

    setSelected([]);
    setIsSendingToPurgeList(false);
  };

  return (
    <>
      {selected.length > 0 && (
        <Flex
          justifyContent="flex-end"
          alignItems="center"
          p={2}
          position="fixed"
          bottom={0}
          left={0}
          zIndex={200}
          sx={{
            width: '100%',
            backgroundColor: 'rgba(255, 255, 255, 0.5)',
            height: 70,
            px: 2,
            backdropFilter: 'blur(10px)',
          }}
        >
          <Stack direction="row" spacing={2}>
            <Button
              variant="contained"
              disabled={!selected.length || isSendingToPurgeList}
              startIcon={isSendingToPurgeList ? <Loader size={15} /> : <Send />}
              sx={{ alignSelf: 'flex-end' }}
              color="info"
              onClick={handleSendToPurgeList}
            >
              {isSendingToPurgeList && 'Sending...'}
              {!isSendingToPurgeList && (
                <>
                  Send {selected.length}{' '}
                  {selected.length > 1 ? 'Users' : 'User'} to Purge List
                </>
              )}
            </Button>
            <Button
              variant="outlined"
              onClick={() => setSelected([])}
              color="info"
              disabled={isSendingToPurgeList}
            >
              Clear Selection
            </Button>
          </Stack>
        </Flex>
      )}
      <Flex justifyContent="flex-end">
        <Tooltip title="Filters" placement="left" arrow>
          <Badge color="info" overlap="circular" variant="dot">
            <IconButton onClick={handleOpenFilter}>
              <Iconify icon="lucide:filter" />
            </IconButton>
          </Badge>
        </Tooltip>
      </Flex>
      <MagicTable
        loading={isLoading}
        pageSize={size}
        page={page}
        rowCount={totalCount}
        sortModel={[
          { field: rankBy, sort: order.toLowerCase() as GridSortDirection },
        ]}
        autoHeight
        paginationMode="server"
        onPageChange={(newPage) => {
          addParam('page', newPage);
        }}
        onPageSizeChange={(newSize) => {
          addParam('size', newSize);
        }}
        onSortModelChange={handleChangeSort}
        checkboxSelection
        onSelectionModelChange={handleSelect}
        selectionModel={selected}
        columns={[
          { field: 'pos', headerName: 'Pos', flex: 1 / 2, sortable: false },
          {
            field: 'active',
            headerName: 'Active',
            align: 'left',
            sortable: false,
            flex: 1 / 2,
            renderCell: ({ row }) => {
              if (row.active) {
                return <Label color="success">Active</Label>;
              }
              return <Label color="error">Inactive</Label>;
            },
          },
          {
            field: 'userType',
            headerName: 'Type',
            align: 'left',
            sortable: false,
            valueFormatter: ({ value }) => UserTypeLabel[value as UserType],
          },
          {
            field: 'name',
            headerName: 'User',
            flex: 2,
            sortable: false,
            renderCell: ({ row }) => (
              <>
                <Avatar alt={row.name} src={row.profileImage} sx={{ mr: 2 }} />
                <Link
                  to={Routes.user(row.uuid)}
                  color={row.name ? 'inherit' : 'text.secondary'}
                  underline="hover"
                >
                  {row.name ?? 'empty'}
                </Link>
              </>
            ),
          },
          {
            field: 'balance',
            headerName: 'Balance',
            align: 'right',
            sortingOrder: ['desc', 'asc'],
            sortComparator: () => 0,
          },
          {
            field: 'availableEarnings',
            headerName: 'Earned Available',
            align: 'right',
            sortingOrder: ['desc', 'asc'],
            sortComparator: () => 0,
          },
          {
            field: 'availableBoughtCoins',
            headerName: 'Bought Available',
            align: 'right',
            sortingOrder: ['desc', 'asc'],
            sortComparator: () => 0,
          },
          {
            field: 'availableBonus',
            headerName: 'Bonus Available',
            align: 'right',
            sortingOrder: ['desc', 'asc'],
            sortComparator: () => 0,
          },
          {
            field: 'lockedBonus',
            headerName: 'Locked Bonus',
            align: 'right',
            sortingOrder: ['desc', 'asc'],
            sortComparator: () => 0,
          },
          {
            field: 'lockedBoughtCoins',
            headerName: 'Bought Locked',
            align: 'right',
            sortingOrder: ['desc', 'asc'],
            sortComparator: () => 0,
          },
          {
            field: 'lockedEarnings',
            headerName: 'Earned Locked',
            align: 'right',
            sortingOrder: ['desc', 'asc'],
            sortComparator: () => 0,
          },
        ]}
        rows={data.map((r) => ({
          ...r,
          id: r.uuid,
        }))}
      />
    </>
  );
}
