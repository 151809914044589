import { Container } from '@mui/material';
import SystemUptimeView from 'src/components/modules/Home/view/SystemUptime';
import Routes from 'src/routes/Routes';
import HeaderBreadcrumbs from 'src/template/components/HeaderBreadcrumbs';
import Page from 'src/template/components/Page';

export default function SystemUptimePage(): JSX.Element {
  return (
    <Page title="System Uptime">
      <Container maxWidth="xl">
        <HeaderBreadcrumbs
          heading="System Uptime"
          links={[
            { name: 'Home', href: Routes.home },
            { name: 'List of Systems' },
          ]}
        />
        <SystemUptimeView />
      </Container>
    </Page>
  );
}
