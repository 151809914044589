import getAPIClient from 'src/lib/services/api/axios.config';
import { ServiceResponse } from 'src/lib/types/ServiceResponse';
import { NFT } from 'src/lib/types/nft';
import { getMessageFromError } from 'src/lib/utils/error';

export type UpdateNftRarityData = {
  packUuid: string;
  nftUuid: string;
  rarityUuid: string;
  faceValue: number;
};
export async function UpdateNftOnPack(
  data: UpdateNftRarityData
): Promise<ServiceResponse<NFT>> {
  try {
    const api = await getAPIClient();
    const res = await api.patch(
      `admin/nft-pack/${data.packUuid}/nfts/${data.nftUuid}`,
      {
        rarityUuid: data.rarityUuid,
        faceValue: data.faceValue,
      }
    );
    return {
      data: res.data,
      error: null,
    };
  } catch (e) {
    return {
      data: null,
      error: getMessageFromError(e),
    };
  }
}
