import { useState } from 'react';
import { useNavigate } from 'react-router';
import useURLSearchParams from 'src/lib/hooks/useURLSearchParams';

// ----------------------------------------------------------------------

export type Config = {
  /**
   * Remove all query params from URL on change tab
   * @example
   * If True:
   * // /users?name=Jose&tab=1 => /users?tab=2
   * If False:
   * // /users?name=Jose&tab=1 => /users?name=Jose&tab=2
   */
  resetOnChange: boolean;
  queryName: string;
};
export const initialConfig: Config = {
  resetOnChange: false,
  queryName: 'tab',
};
export default function useTabs(
  defaultValue?: string,
  config?: Partial<Config>
) {
  const hookConfig: Config = {
    ...initialConfig,
    ...config,
  };
  const { queryName, resetOnChange } = hookConfig;
  const navigate = useNavigate();
  const { addParam: handleAddParam, getParam } = useURLSearchParams();
  const [currentTab, setCurrentTab] = useState(defaultValue || '');

  function handleChangeTab(
    event: React.SyntheticEvent<Element, Event> | null,
    newValue: string
  ) {
    if (resetOnChange) {
      navigate(`?${queryName}=${newValue}`);
    } else {
      handleAddParam(queryName, newValue);
    }
  }
  return {
    currentTab: getParam(queryName) ?? defaultValue,
    onChangeTab: handleChangeTab,
    setCurrentTab,
  };
}
