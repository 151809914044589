import PoolsStatusView from './View';
import usePoolsStatusView from './useView';

export default function PoolsStatus(): JSX.Element {
  const { pools, isLoadingPools, handleFilter, defaultValues } =
    usePoolsStatusView();
  return (
    <PoolsStatusView
      pools={pools}
      isLoading={isLoadingPools}
      onFilter={handleFilter}
      defaultValues={defaultValues}
    />
  );
}
