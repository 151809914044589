import {
  Autocomplete,
  Card,
  CardContent,
  IconButton,
  InputAdornment,
  Tab,
  Tabs,
  TextField,
} from '@mui/material';
import { GridSortDirection } from '@mui/x-data-grid';
import { capitalCase } from 'change-case';
import { debounce } from 'lodash';
import { ReactNode } from 'react';
import { UserType } from 'src/components/modules/Users/types/UserType';
import Flex from 'src/components/shared/Flex';
import SelectOption from 'src/components/shared/SelectOption';
import useURLSearchParams from 'src/lib/hooks/useURLSearchParams';
import HeaderBreadcrumbs from 'src/template/components/HeaderBreadcrumbs';
import Iconify from 'src/template/components/Iconify';
import CoinsDeliveriesTable from '../../components/Deliveries/Table';
import useDeliveriesView from './useView';

const STATUS_TABS = [
  { value: 'all', label: 'All' },
  { value: 'pending', label: 'Waiting' },
  { value: 'complete', label: 'Delivered' },
  { value: 'failed', label: 'Failed' },
];

const searchOptions = [
  {
    label: 'User Name',
    query: 'user.name',
  },
  {
    label: 'User Slug',
    query: 'user.slug',
  },
  {
    label: 'User Email',
    query: 'user.email',
  },
  {
    label: 'User UUID',
    query: 'user.uuid',
  },
];

export default function CoinDeliveries() {
  const {
    data,
    isLoading,
    onChangeStatus,
    status,
    page,
    size,
    order,
    sortBy,
    searchBy,
    query,
    handleOpenFilters,
  } = useDeliveriesView();

  const { addParam, removeParam } = useURLSearchParams();

  function handleChangeSearch(value: string, param: string = 'query') {
    if (!value) removeParam(param);
    else addParam(param, value);
  }

  const debouncedSearch = debounce(handleChangeSearch, 1000);

  const InputBySearchBy: Record<string, ReactNode> = {
    lastSession: (
      <Flex gap={2} width="100%">
        <TextField
          fullWidth
          type="date"
          label="Start Date"
          InputLabelProps={{ shrink: true }}
          onChange={(e) => {
            const { value } = e.target;
            debouncedSearch(value ? `${value}T00:00:00` : '', 'startDate');
          }}
        />
        <TextField
          fullWidth
          type="date"
          label="End Date"
          InputLabelProps={{ shrink: true }}
          onChange={(e) => {
            const { value } = e.target;
            debouncedSearch(value ? `${value}T23:59:59` : '', 'endDate');
          }}
        />
      </Flex>
    ),
    userType: (
      <TextField
        fullWidth
        select
        onChange={(e) => {
          const { value } = e.target;
          handleChangeSearch(value);
        }}
      >
        {Object.values(UserType).map((type) => (
          <SelectOption value={type} key={type}>
            {capitalCase(type)}
          </SelectOption>
        ))}
      </TextField>
    ),
  };

  return (
    <>
      <HeaderBreadcrumbs
        heading="Coin Deliveries"
        links={[{ name: 'Coin Deliveries' }]}
      />
      <Card>
        <Flex sx={{ px: 2, bgcolor: 'background.neutral' }}>
          <Tabs
            allowScrollButtonsMobile
            variant="scrollable"
            scrollButtons="auto"
            value={status}
            onChange={onChangeStatus}
          >
            {STATUS_TABS.map((tab) => (
              <Tab
                disableRipple
                key={tab.value}
                label={tab.label}
                value={tab.value}
              />
            ))}
          </Tabs>
          <IconButton sx={{ ml: 'auto' }} onClick={handleOpenFilters}>
            <Iconify icon="lucide:filter" />
          </IconButton>
        </Flex>
        <Flex gap={2} p={2}>
          <Autocomplete
            options={searchOptions}
            fullWidth
            renderInput={(params) => (
              <TextField label="Search By" {...params} />
            )}
            getOptionLabel={(p) => p.label}
            sx={{ maxWidth: 250 }}
            defaultValue={searchOptions.find((o) => o.query === searchBy)}
            key={searchBy}
            onChange={(_, value) => {
              if (!value) removeParam('searchBy');
              else addParam('searchBy', value.query);
            }}
          />
          {searchBy && InputBySearchBy?.[searchBy] ? (
            InputBySearchBy[searchBy]
          ) : (
            <TextField
              fullWidth
              label="Search"
              defaultValue={query}
              type="search"
              onChange={(e) => {
                const { value } = e.target;
                debouncedSearch(value);
              }}
              key={query}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <Iconify
                      icon="eva:search-fill"
                      sx={{ color: 'text.disabled', width: 20, height: 20 }}
                    />
                  </InputAdornment>
                ),
              }}
            />
          )}
        </Flex>
        <CardContent>
          <CoinsDeliveriesTable
            isLoading={isLoading}
            data={data?.body ?? []}
            page={page}
            size={size}
            totalCount={data?.meta.totalCount ?? 0}
            order={order as GridSortDirection}
            sortBy={sortBy!}
          />
        </CardContent>
      </Card>
    </>
  );
}
