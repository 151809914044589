export const transactionHistoryTabs = [
  {
    label: 'All',
    value: 'all',
  },
  {
    label: 'Success',
    value: 'success',
  },
  {
    label: 'Failed',
    value: 'failed',
  },
  {
    label: 'Pending',
    value: 'pending',
  },
  {
    label: 'Rollback',
    value: 'rollback',
  },
];
