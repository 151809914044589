import { RevenueEventType } from 'src/components/modules/RevenueEvents/types/RevenueEvent';
import { RefundReason } from '../services/refunds/types';
import { WithTimestamp } from './WithTimestamp';

/**
 * @deprecated
 */
export type RevenueEvent = WithTimestamp<{
  uuid: string;
  appleNotificationUuid: string;
  purchaseEventUuid: string;
  userUuid: string;
  webOrderLineItemId: string;
  originalTransactionId: string;
  originalPurchaseDate: number;
  identifier: string;
  productUuid: string;
  eventType: RevenueEventType;
  eventDate: number;
  transactionPoolUuid: string;
  subscriptionStartDate: number;
  subscriptionExpiresDate: number;
  isFreeTrial: boolean;
  environment: string;
}>;

export type ProductType =
  | 'Consumable'
  | 'Non-Consumable'
  | 'Auto-Renewable Subscription'
  | 'Non-Renewing';

export const productTypes = [
  'Auto-Renewable Subscription',
  'Consumable',
  'Non-Consumable',
  'Non-Renewing',
];

export type EventType =
  | 'RefundRequest'
  | 'Refund'
  | 'Upgrade'
  | 'Downgrade'
  | 'BillingIssueGP'
  | 'BillingIssueNoGP'
  | 'BillingRecovery'
  | 'Renew'
  | 'Expire'
  | 'RefundDenied'
  | 'Buy'
  | 'convertedFT'
  | 'StartFT'
  | 'Paused'
  | 'LockEvents';

export type OfferType =
  | 'INTRODUTORY'
  | 'PROMOTIONAL'
  | 'SUBSCRIPTION_OFFER_CODE';

export type RefundStatsResponse = {
  total: RefundStatsTotal;
  usersWithMoreThanOneRefund: number;
  refundedUsers: number;
  topReasons: TopReason;
  topProducts: TopProducts;
};

export type MostRefundedUsersByQuantityResponse = {
  count: number;
  rows: Array<{
    name?: string;
    slug?: string;
    active?: boolean;
    email?: string;
    userUuid?: string;
    refunds: number;
    refund_denied: number;
    total_refunded: string;
  }>;
};

export type RefundStatsTotal = {
  denied: number;
  refunded: number;
  requested: number;
};

export type TopReason = Array<{
  reason: string;
  events: number;
}>;

export type TopProducts = Array<{
  productId: string;
  events: number;
}>;

export const refundReasons: RefundReason[] = [
  'CUSTOMER_ERROR',
  // 'Upgraded',
  'APP_ERROR',
  'null',
];
