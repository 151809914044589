import { ChannelWithAdminAnalytics } from 'src/lib/types/channelWithAdminAnalytics';
import useSWR, { SWRResponse } from 'swr';

export const fetchChannelDetails = (
  uuid: string
): SWRResponse<ChannelWithAdminAnalytics> => {
  const response = useSWR(`admin/channels/${uuid}`);

  return response;
};
