import useNotify from 'src/lib/hooks/useNotify';
import useModalStore from 'src/lib/stores/useModalStore';
import { ConfigurationFormValues } from '../../components/Form/types';
import { ConfigurationEmitter } from '../../events/ConfigurationEmitter';
import { updateConfiguration } from '../../services/updateConfiguration';

export default function useUpdateConfiguration() {
  const closeModal = useModalStore((state) => state.closeModal);
  const notify = useNotify();

  const handleUpdateConfiguration = async (
    configId: string,
    values: ConfigurationFormValues
  ) => {
    const { error, data } = await updateConfiguration(configId, {
      ...values,
      active: true,
    });
    if (error) {
      notify(error, { variant: 'error' });
      closeModal();
    } else if (data) {
      notify('Configuration updated!', { variant: 'success' });
      closeModal();
      ConfigurationEmitter.emit('configurationUpdated', data);
    }
  };

  return {
    handleUpdateConfiguration,
  };
}
