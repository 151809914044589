import getAPIClient from 'src/lib/services/api/axios.config';
import { ServiceResponse } from 'src/lib/types/ServiceResponse';
import { Club } from 'src/lib/types/club';
import { getMessageFromError } from 'src/lib/utils/error';
import { CreateClubData } from './createClub';

export async function UpdateClub(
  clubUuid: string,
  data: Partial<CreateClubData>
): Promise<ServiceResponse<Club>> {
  try {
    const api = await getAPIClient();
    const res = await api.put(`admin/clubs/${clubUuid}`, data);
    return {
      data: res.data,
      error: null,
    };
  } catch (e) {
    return {
      data: null,
      error: getMessageFromError(e),
    };
  }
}
