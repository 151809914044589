import {
  Alert,
  Box,
  Card,
  CardContent,
  CardHeader,
  Stack,
} from '@mui/material';
import Button from 'src/components/shared/Buttons/CustomButton';
import FilterForm from 'src/components/shared/FilterForm';
import Flex from 'src/components/shared/Flex';
import ControllerField from 'src/components/shared/Inputs/ControllerField';
import Modal from 'src/components/shared/Modal';
import { CommonDateStrings } from 'src/lib/constants/CommonDates';
import useModalStore from 'src/lib/stores/useModalStore';
import { SGMMostActiveUsersFilterFormValues } from './types';

type Props = {
  defaultValues: SGMMostActiveUsersFilterFormValues;
};
export default function SGMDialogLisFiltersView({
  defaultValues,
}: Props): JSX.Element {
  const closeModal = useModalStore((state) => state.closeModal);
  return (
    <Modal open onClose={closeModal}>
      <FilterForm<SGMMostActiveUsersFilterFormValues>
        defaultValues={defaultValues}
        onSubmit={closeModal}
      >
        {({ control }) => (
          <Card sx={{ minWidth: 500 }}>
            <CardHeader title="Filters" />
            <CardContent>
              <Box mb={2}>
                <Alert severity="info">
                  Data is reliably available from{' '}
                  {new Date(
                    CommonDateStrings.sgmInfosAdded.dateTime
                  ).toLocaleDateString('en-US', {
                    year: 'numeric',
                    month: 'long',
                    day: 'numeric',
                  })}{' '}
                  onward.
                </Alert>
              </Box>
              <Stack spacing={2}>
                <Box display="grid" gridTemplateColumns="1fr 1fr" gap={2}>
                  <ControllerField
                    control={control}
                    name="startMessagesCreatedBetween"
                    fullWidth
                    label="Start Messages Created Date"
                    type="date"
                    inputProps={{
                      max: CommonDateStrings.sevenDaysFromNow.date,
                    }}
                  />
                  <ControllerField
                    control={control}
                    name="endMessagesCreatedBetween"
                    fullWidth
                    label="End Messages Created Date"
                    type="date"
                    inputProps={{
                      max: CommonDateStrings.sevenDaysFromNow.date,
                    }}
                  />
                  <ControllerField
                    control={control}
                    name="minTotalChats"
                    fullWidth
                    label="Min Total Chats"
                    type="number"
                    inputProps={{
                      min: 0,
                    }}
                  />
                  <ControllerField
                    control={control}
                    name="maxTotalChats"
                    fullWidth
                    label="Max Total Chats"
                    type="number"
                    inputProps={{
                      min: 0,
                    }}
                  />
                  <ControllerField
                    control={control}
                    name="minTotalMessages"
                    fullWidth
                    label="Min Total Messages"
                    type="number"
                    inputProps={{
                      min: 0,
                    }}
                  />
                  <ControllerField
                    control={control}
                    name="maxTotalMessages"
                    fullWidth
                    label="Max Total Messages"
                    type="number"
                    inputProps={{
                      min: 0,
                    }}
                  />
                </Box>
                <Flex justifyContent="space-between">
                  <Button color="error" variant="text" onClick={closeModal}>
                    Cancel
                  </Button>
                  <Button color="secondary" type="submit" variant="contained">
                    Filter
                  </Button>
                </Flex>
              </Stack>
            </CardContent>
          </Card>
        )}
      </FilterForm>
    </Modal>
  );
}
