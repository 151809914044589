import { fetchNotificationsHealth } from '../../../services/fetchNotificationsHealth';
import { Status } from '../types';

export function useHomeHealthCheckView(): UseHealthCheckView {
  const {
    isLoading: notificationIsLoading,
    data: notificationData,
    error: notificationError,
  } = fetchNotificationsHealth();

  return {
    statuses: [
      {
        isLoading: notificationIsLoading,
        name: 'Notification Service',
        error: notificationError,
        workers: notificationData
          ? notificationData.map((data) => ({
              isWorking: data.work,
              lastChecked: data.lastCheck,
              name: data.label,
            }))
          : [],
      },
    ],
  };
}

type UseHealthCheckView = {
  statuses: Status[];
};
