import useNotify from 'src/lib/hooks/useNotify';
import useModalStore from 'src/lib/stores/useModalStore';
import { StatisticGroupFormValues } from '../../components/Forms/StatisticGroup/types';
import { GameStatisticGroupEmitter } from '../../events/GameStatisticGroupEmitter';
import { UpdateStatisticGroup } from '../../services/updateStatisticGroup';

export default function useUpdateStatisticGroupView() {
  const closeModal = useModalStore((state) => state.closeModal);
  const notify = useNotify();

  const handleUpdateStatisticGroup = async (
    groupUuid: string,
    values: StatisticGroupFormValues
  ) => {
    const updateData =
      values.type === 'STATISTIC_LIST'
        ? {
            name: values.name,
            type: values.type,
            statisticsList: values.statisticsList.map(
              (statistic) => statistic.uuid
            ),
          }
        : {
            name: values.name,
            actionUuid: values.actionUuid,
            typeUuid: values.typeUuid,
            type: values.type,
          };
    const { error, data } = await UpdateStatisticGroup(groupUuid, {
      ...updateData,
    });

    if (error) {
      notify(error, { variant: 'error' });
      closeModal();
    } else if (data) {
      notify('Contest Updated!', { variant: 'success' });
      closeModal();

      GameStatisticGroupEmitter.emit('GameStatisticGroupUpdated', data);
    }
  };

  return {
    handleUpdateStatisticGroup,
  };
}
