import { SGMChatWithAssets } from 'src/lib/types/sgmChatMessage';
import useSWR, { SWRResponse } from 'swr';

export const fetchMakerDialog = (
  promptDialogUuid: string
): SWRResponse<SGMChatWithAssets> => {
  const response = useSWR<SGMChatWithAssets, Error>(
    `admin/game-maker-chats/${promptDialogUuid}`
  );

  return response;
};
