import useSWR, { mutate } from 'swr';
import { PaginatedResponse } from '../types/PaginatedResponse';
import { BracketFilterParams } from '../types/bracketFilterParams';
import { AppleReceipt, IAP, IapTransaction } from '../types/iapTransaction';
import { getFormattedError } from '../utils/error';
import generateSearchRequest from '../utils/generateSearchRequest';
import getAPIClient from './api/axios.config';

export const fetchIapTransactions = (
  filter: BracketFilterParams,
  status = 'all'
) => {
  const statusFilter = status === 'all' ? '' : `&filter[status]=${status}`;

  const { data, error } = useSWR<PaginatedResponse<IapTransaction[]>, Error>(
    `admin/iap/${generateSearchRequest(filter)}${statusFilter}`
  );

  return {
    data: data?.body,
    total: data?.meta.totalCount,
    loading: !error && !data,
    error: error?.message,
  };
};

export const fetchIapTransactionWithSWR = (uuid: string) => {
  const { data, error } = useSWR<PaginatedResponse<IapTransaction>, Error>(
    `admin/iap/${uuid}`
  );
  return {
    data: data?.body,
    loading: !error && !data,
    error: error?.message,
  };
};

export const fetchIapTransaction = async (uuid: string) => {
  try {
    const api = await getAPIClient();
    const { data } = await api.get<IapTransaction>(`admin/iap/${uuid}`);

    return { data };
  } catch (err) {
    return { error: getFormattedError(err) };
  }
};

export const fetchAppleReceipt = (uuid: string) => {
  const { data, error } = useSWR<PaginatedResponse<AppleReceipt>, Error>(
    `admin/iap/${uuid}/apple-receipt`
  );

  return {
    data: data?.body,
    loading: !error && !data,
    error: error?.message,
  };
};

export const createIapTransaction = async (
  iapTransaction: Partial<IapTransaction>
) => {
  try {
    const api = await getAPIClient();
    const res = await api.post('admin/iap', iapTransaction);

    mutate(`admin/iap/${iapTransaction.uuid}`);
    return { data: res.data?.body };
  } catch (err) {
    return { error: getFormattedError(err) };
  }
};

export const updateIapTransaction = async (
  iapTransaction: Partial<IapTransaction>
) => {
  try {
    const api = await getAPIClient();
    const res = await api.post(
      `admin/iap/${iapTransaction.uuid}`,
      iapTransaction
    );

    mutate(`admin/iap/${iapTransaction.uuid}`);
    return { data: res.data?.body };
  } catch (err) {
    return { error: getFormattedError(err) };
  }
};

export const fixAllIaps = async (uuid: string) => {
  try {
    const api = await getAPIClient();
    const res = await api.post(`admin/iap/${uuid}/fix-all-iaps`);

    mutate(`admin/iap/${uuid}/apple-receipt`);
    return { data: res.data?.body, total: res.data?.meta?.totalCount };
  } catch (err) {
    return { error: getFormattedError(err) };
  }
};

export const fixIap = async (uuid: string, iap: Partial<IAP>) => {
  try {
    const api = await getAPIClient();
    const res = await api.post(`admin/iap/${uuid}/fix-iap`, iap);

    mutate(`admin/iap/${uuid}/apple-receipt`);
    return { data: res.data?.body };
  } catch (err) {
    return { error: getFormattedError(err) };
  }
};
