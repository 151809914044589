import useNotify from 'src/lib/hooks/useNotify';
import useModalStore from 'src/lib/stores/useModalStore';
import { updateDevice } from '../../../services/updateDevice';
import { UpdateDeviceFormValues } from './types';

export default function useUpdateDeviceFormView() {
  const notify = useNotify();
  const closeModal = useModalStore((state) => state.closeModal);

  async function handleSubmit(
    id: string,
    values: UpdateDeviceFormValues
  ): Promise<void> {
    const { error } = await updateDevice(id, {
      description: values.description,
    });
    if (error) {
      notify(error, { variant: 'error' });
      return;
    }
    notify('Device updated successfully', { variant: 'success' });
    closeModal();
  }

  return {
    handleSubmit,
  };
}
