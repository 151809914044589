type FormatMillisecondsUnits = 'hours' | 'minutes' | 'seconds';

export function formatMilliseconds(
  milliseconds: number,
  precision: FormatMillisecondsUnits = 'seconds'
): string {
  const duration = milliseconds / 1000;
  const hours = Math.floor(duration / 3600);
  const minutes = Math.floor((duration % 3600) / 60);
  const seconds = Math.floor(duration % 60);

  if (precision === 'hours') {
    return `${hours}h`;
  }
  if (precision === 'minutes') {
    return `${hours ? `${hours}h ` : ''}${minutes}m`;
  }

  return `${hours ? `${hours}h ` : ''}${
    minutes ? `${minutes}m ` : ''
  }${seconds}s`;
}
