import { ClubBan } from 'src/lib/types/ClubBan';
import { Club } from 'src/lib/types/club';
import { createEmitter } from 'src/lib/utils/createEmitter';

export const ClubEmitter = createEmitter<{
  ClubCreated: Club;
  ClubUpdated: Club | null;
  ClubDeleted: Club;
  UserBanned: ClubBan;
  UserUnbanned: Club;
}>();
