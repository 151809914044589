import StatisticGroups from 'src/components/modules/GameStatisticGroups/view/List';
import Page from 'src/template/components/Page';

export default function GameStatisticGroupsPage() {
  return (
    <Page title="Game Statistic Groups">
      <StatisticGroups />
    </Page>
  );
}
