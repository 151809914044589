/* eslint-disable no-promise-executor-return */
import { Box, MenuItem, Typography } from '@mui/material';

import MagicTable from 'src/components/shared/MagicTable/MagicTable';
import useURLSearchParams from 'src/lib/hooks/useURLSearchParams';

import { ContentCopy, OpenInNew } from '@mui/icons-material';
import { capitalCase } from 'change-case';
import CopyToClipboard from 'react-copy-to-clipboard';
import MagicTableUserCell from 'src/components/shared/MagicTable/UserCell';
import MoreMenu from 'src/components/shared/MoreMenu';
import { FeedItem } from 'src/lib/types/FeedItem';
import {
  OBJECT_MODERATION_AI_REVIEW,
  OBJECT_MODERATION_HUMAN_REVIEW,
  ObjectModeration,
} from 'src/lib/types/objectModeration';
import { formatUTCDate } from 'src/lib/utils/formatUTCDate';
import Label from 'src/template/components/Label';
import { ObjectModerationInfoReturn } from '../../services/fetchObjectModerations';
import {
  colorByAIReview,
  colorByHumanReview,
  colorByReviewStatus,
} from '../../utils/utils';
import useVideosToReviewTableView from './useView';

type ObjectModerationTableProps = {
  data?: ObjectModerationInfoReturn['moderations']['rows'];
  totalCount?: number;
  isLoading: boolean;
  params: { page: number; size: number }; // 'orderBy' | 'sortOrder'
};

export default function ObjectModerationTable(
  props: ObjectModerationTableProps
) {
  const {
    data,
    totalCount,
    isLoading,
    params: { page, size },
  } = props;
  const { handleOpenModerationDetails } = useVideosToReviewTableView();
  const { addParam, removeParam } = useURLSearchParams();

  return (
    <MagicTable.Container>
      <Box>
        <MagicTable
          loading={isLoading}
          pageSize={size}
          page={page}
          paginationMode="server"
          onPageChange={(newPage) => {
            addParam('page', newPage);
          }}
          onPageSizeChange={(newSize) => {
            addParam('size', newSize);
          }}
          onSortModelChange={(model) => {
            const currentModel = model[0];
            if (currentModel) {
              addParam('orderBy', currentModel.field);
              if (currentModel.sort) addParam('order', currentModel.sort);
            } else {
              removeParam('order');
              removeParam('orderBy');
            }
          }}
          rows={data ?? []}
          rowCount={totalCount ?? 0}
          onCellClick={({ row }) => {
            handleOpenModerationDetails(row);
          }}
          columns={[
            {
              field: 'reviewStatus',
              headerName: 'Review Status',
              sortable: false,
              renderCell({ row }: { row: ObjectModeration }) {
                return (
                  <Label
                    variant="ghost"
                    color={colorByReviewStatus[row.reviewStatus]}
                    sx={{ textTransform: 'capitalize' }}
                  >
                    {capitalCase(row.reviewStatus ?? 'none')}
                  </Label>
                );
              },
            },
            {
              field: 'aiModel',
              headerName: 'AI Model',
              sortable: false,
            },
            {
              field: 'aiReview',
              headerName: 'AI Review',
              sortable: false,
              renderCell({ row }: { row: ObjectModeration }) {
                return (
                  <Label
                    variant="ghost"
                    color={
                      colorByAIReview[
                        row.aiReview as OBJECT_MODERATION_AI_REVIEW
                      ]
                    }
                    sx={{ textTransform: 'capitalize' }}
                  >
                    {capitalCase(row.aiReview ?? 'none')}
                  </Label>
                );
              },
            },
            {
              field: 'aiRejectionReason',
              headerName: 'AI Reason',
              sortable: false,
            },
            {
              field: 'adminReviewer',
              headerName: 'Admin Reviewer',
              sortable: false,
              renderCell({ row }) {
                return <MagicTableUserCell user={row.adminReviewer} />;
              },
            },
            {
              field: 'humanReview',
              headerName: 'Human Review',
              sortable: false,
              renderCell({ row }) {
                return (
                  <Label
                    variant="ghost"
                    color={
                      colorByHumanReview[
                        row.humanReview as OBJECT_MODERATION_HUMAN_REVIEW
                      ]
                    }
                    sx={{ textTransform: 'capitalize' }}
                  >
                    {capitalCase(row.humanReview ?? 'none')}
                  </Label>
                );
              },
            },
            {
              field: 'humanReviewRejectionReason',
              headerName: 'Human Reason',
              sortable: false,
            },
            {
              field: 'reviewNote',
              headerName: 'Review Note',
              sortable: false,
            },
            {
              field: 'internalJustification',
              headerName: 'Internal Justification',
              sortable: false,
            },
            {
              field: 'createdAt',
              headerName: 'Created At',
              sortable: true,
              sortingOrder: ['asc', 'desc'],
              renderCell({ row }: { row: FeedItem }) {
                return (
                  <Typography variant="body2" color="text.secondary">
                    {formatUTCDate(row.updatedAt)}
                  </Typography>
                );
              },
            },
            {
              field: 'actions',
              headerName: 'Actions',
              align: 'center',
              sortable: false,
              flex: 0,
              renderCell({ row }) {
                return (
                  <MoreMenu>
                    <CopyToClipboard text={row.uuid}>
                      <MenuItem aria-label="copy feed uuid">
                        <ContentCopy />
                        Copy UUID
                      </MenuItem>
                    </CopyToClipboard>
                    <MenuItem
                      aria-label="view moderation"
                      onClick={() => handleOpenModerationDetails(row)}
                    >
                      <OpenInNew />
                      View Moderation
                    </MenuItem>
                  </MoreMenu>
                );
              },
            },
          ]}
        />
      </Box>
    </MagicTable.Container>
  );
}
