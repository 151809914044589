import { useCallback, useEffect } from 'react';
import { DEFAULT_INITIAL_PAGE_SIZE } from 'src/lib/constants/pagination';
import useURLSearchParams from 'src/lib/hooks/useURLSearchParams';
import useModalStore from 'src/lib/stores/useModalStore';

import { GameCategoryEmitter } from '../../events/Emitter';
import { fetchGameCategory } from '../../services/fetch';
import CreateGameView from '../Create';
import DeleteGameView from '../Delete';

export default function useGameCategory() {
  const openModal = useModalStore((state) => state.openModal);
  const handleOpenEditModal = (uuid: string) => {
    const gameCategory = data?.find((item) => item.uuid === uuid);
    openModal(<CreateGameView gameCategory={gameCategory} />);
  };

  const handleOpenCreateModal = () => {
    openModal(<CreateGameView />);
  };
  const handleOpenDeleteModal = (uuid: string) => {
    openModal(<DeleteGameView uuid={uuid} />);
  };

  const { allSearchParams } = useURLSearchParams(initialParams);

  const params = {
    page: +(allSearchParams.page ?? initialParams.page),
    size: +(allSearchParams.size ?? initialParams.size),
    orderBy: allSearchParams.orderBy ?? initialParams.orderBy,
    sortOrder: allSearchParams.sortOrder ?? initialParams.order,
    searchBy: allSearchParams.searchBy ?? initialParams.searchBy,
    query: allSearchParams.query ?? initialParams.query,
  };

  const { data, isLoading, isValidating, mutate } = fetchGameCategory({
    page: params.page,
    size: params.size,

    ...(params.orderBy &&
      params.sortOrder && {
        order: {
          [params.orderBy]: params.sortOrder,
        },
      }),

    filter: {
      ...(params.searchBy &&
        params.query && {
          [params.searchBy]: {
            like: `%${params.query}%`,
          },
        }),
    },
  });

  const refetchData = useCallback(() => {
    mutate?.();
  }, []);

  useEffect(() => {
    GameCategoryEmitter.on('GameCategoryCreated', refetchData);
    GameCategoryEmitter.on('GameCategoryUpdated', refetchData);
    GameCategoryEmitter.on('GameCategoryDeleted', refetchData);
    return () => {
      GameCategoryEmitter.off('GameCategoryCreated', refetchData);
      GameCategoryEmitter.off('GameCategoryUpdated', refetchData);
      GameCategoryEmitter.off('GameCategoryDeleted', refetchData);
    };
  }, [refetchData]);

  return {
    data,
    isLoading: isLoading || isValidating,
    params,
    handleOpenCreateModal,
    handleOpenDeleteModal,
    handleOpenEditModal,
  };
}

const initialParams = {
  page: '0',
  size: DEFAULT_INITIAL_PAGE_SIZE.toString(),
  order: 'asc',
  orderBy: 'createdAt',
  searchBy: 'name',
  query: '',
};

export type FetchGamesQueryParams = {
  page: number;
  size: number;
  orderBy: string;
  searchBy: string;
  sortOrder: string;
};
