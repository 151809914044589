type Options = Intl.DateTimeFormatOptions;

const defaultOptions: Options = {
  timeZone: 'UTC',
  hour: '2-digit',
  minute: '2-digit',
  year: 'numeric',
  month: 'numeric',
  day: 'numeric',
};
export function formatUTCDate(
  date: Date | number = new Date(),
  options?: Options
): string {
  const dateString = new Date(date).toLocaleString('pt-BR', {
    ...defaultOptions,
    ...options,
  });

  return dateString;
}
