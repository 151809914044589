import getAPIClient from 'src/lib/services/api/axios.config';
import { ServiceResponse } from 'src/lib/types/ServiceResponse';
import { getMessageFromError } from 'src/lib/utils/error';

export async function triggerWalletVerification(): Promise<
  ServiceResponse<any>
> {
  try {
    const api = await getAPIClient();
    const data = await api.post(
      `admin/wallet-inconsistency/verify-wallet-inconsistency`
    );

    return {
      data,
      error: null,
    };
  } catch (e) {
    return {
      data: null,
      error: getMessageFromError(e),
    };
  }
}
