import getSGMClient from 'src/lib/services/sgm/axios.config';
import { getMessageFromError } from 'src/lib/utils/error';

export type UpdateMakerPromptForm = {
  title: string;
  description: string;
  prompt: string;
};

export async function updateMakerPrompt(
  type: string,
  data: UpdateMakerPromptForm
) {
  try {
    const api = await getSGMClient();
    const res = await api.put(`/api/admin/prompt/${type}`, data);
    return {
      data: res.data,
      error: null,
    };
  } catch (e) {
    return {
      data: null,
      error: getMessageFromError(e),
    };
  }
}
