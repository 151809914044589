import { DeepPartial } from 'react-hook-form';
import { GameTag } from 'src/lib/types/gameTag';
import { parseSearchParams } from 'src/lib/utils/parseSearchParams';
import useSWR, { SWRResponse } from 'swr';

export type PossibleParams = {
  page: number | string;
  size: number | string;
  order: Record<string, string>;
  filter: {
    [key: string]: {
      like: string;
      eq: string;
    };
  };
};

export const fetchGameMode = (
  filter?: DeepPartial<PossibleParams>
): SWRResponse<GameTag[]> => {
  const parsedParams = parseSearchParams(filter ?? {});
  const response = useSWR<GameTag[], Error>(`/game-mode?${parsedParams}`, {
    revalidateOnFocus: false,
  });

  return response;
};
