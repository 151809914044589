import { yupResolver } from '@hookform/resolvers/yup';
import {
  Autocomplete,
  Card,
  CardContent,
  CardHeader,
  Stack,
  TextField,
} from '@mui/material';
import { Controller, useForm } from 'react-hook-form';
import Button from 'src/components/shared/Buttons/CustomButton';
import Flex from 'src/components/shared/Flex';
import ControllerField from 'src/components/shared/Inputs/ControllerField';
import Modal from 'src/components/shared/Modal';
import useModalStore from 'src/lib/stores/useModalStore';
import * as yup from 'yup';
import { fetchEntityNoteTags } from '../../services/fetchEntityNoteTags';
import { EntityNoteFormValues } from './types';

const validationSchema = yup.object().shape({
  note: yup.string().required('Note is required'),
  tagName: yup.string().required('Tag is required'),
});

type Props = {
  defaultValues: EntityNoteFormValues;
  onSubmit(values: EntityNoteFormValues): Promise<void>;
  title: string;
};
export default function EntityNoteForm({
  defaultValues,
  onSubmit,
  title,
}: Props): JSX.Element {
  const closeModal = useModalStore((state) => state.closeModal);
  const {
    handleSubmit,
    formState: { isSubmitting },
    control,
    getFieldState,
  } = useForm<EntityNoteFormValues>({
    defaultValues,
    resolver: yupResolver(validationSchema),
  });

  const handleCancel = () => {
    closeModal();
  };

  const { data } = fetchEntityNoteTags();

  return (
    <Modal open onClose={handleCancel} aria-labelledby="create a configuration">
      <Card
        sx={{
          width: 400,
        }}
      >
        <form onSubmit={handleSubmit(onSubmit)}>
          <CardHeader title={title} />
          <CardContent>
            <Stack spacing={1.5}>
              <Controller
                control={control}
                name="tagName"
                render={({ field }) => (
                  <Autocomplete
                    freeSolo
                    handleHomeEndKeys
                    options={data ?? []}
                    value={field.value}
                    fullWidth
                    renderInput={(params) => (
                      <TextField
                        label="Tag"
                        {...params}
                        {...field}
                        error={Boolean(getFieldState('tagName')?.error)}
                        helperText={getFieldState('tagName')?.error?.message}
                      />
                    )}
                    onSelect={(e) => {
                      field.onChange(e);
                    }}
                  />
                )}
                // i want to show a error message here
              />
              <ControllerField
                control={control}
                name="note"
                label="Note"
                multiline
                minRows={3}
              />
            </Stack>
            <Flex width="100%" justifyContent="space-between" mt={4}>
              <Button variant="text" color="error" onClick={handleCancel}>
                Cancel
              </Button>
              <Button
                type="submit"
                loading={isSubmitting}
                variant="contained"
                color="secondary"
              >
                Save
              </Button>
            </Flex>
          </CardContent>
        </form>
      </Card>
    </Modal>
  );
}
