import { CloseOutlined } from '@mui/icons-material';
import {
  Button,
  Card,
  CardContent,
  CardHeader,
  Divider,
  FormControl,
  FormLabel,
  IconButton,
  MenuItem,
  Stack,
  TextField,
} from '@mui/material';
import { capitalCase } from 'change-case';
import { useState } from 'react';
import Modal from 'src/components/shared/Modal';
import { CommonDateStrings } from 'src/lib/constants/CommonDates';
import { BlockchainNetwork } from 'src/lib/constants/blockchain';
import { CustomFilters } from 'src/lib/types/bracketFilterParams';
import { AppPlatforms } from 'src/lib/types/collection';

type CustomFiltersCollectionModalProps = {
  customFilters: CustomFilters;
  setCustomFilters: React.Dispatch<React.SetStateAction<CustomFilters>>;
  open: boolean;
  onClose: () => void;
};

type InputProps = {
  minField: {
    defaultValue: string;
    maxValue: string;
    minValue: string;
  };
  maxField: {
    defaultValue: string;
    maxValue: string;
    minValue: string;
  };
};

const createdAtInputProps: InputProps = {
  minField: {
    minValue: CommonDateStrings.firstCollection.date,
    maxValue: CommonDateStrings.today.date,
    defaultValue: CommonDateStrings.firstCollection.date,
  },
  maxField: {
    minValue: CommonDateStrings.firstCollection.date,
    maxValue: CommonDateStrings.today.date,
    defaultValue: CommonDateStrings.today.date,
  },
};

const rangeFilterFields: {
  id: string;
  label: string;
  type: string;
  inputProps?: InputProps;
}[] = [
  { id: 'aiMaxScore', label: 'A.I. Max Score', type: 'number' },
  { id: 'nftsCount', label: 'NFTs Count', type: 'number' },
  { id: 'reportsCount', label: 'Reports', type: 'number' },
  { id: 'salesCount', label: 'NFTs Sold', type: 'number' },
  {
    id: 'createdAt',
    label: 'Created At',
    type: 'date',
    inputProps: createdAtInputProps,
  },
];

export default function CustomFiltersCollectionModal({
  customFilters,
  setCustomFilters,
  open,
  onClose,
}: CustomFiltersCollectionModalProps) {
  const [newCustomFilters, setNewCustomFilters] = useState(customFilters);

  const onApply = () => {
    setCustomFilters(newCustomFilters);
    onClose();
  };

  const onRemoveFilters = () => {
    setCustomFilters({});
    setNewCustomFilters({});
    onClose();
  };

  return (
    <Modal open={open} onClose={onClose}>
      <Card sx={{ width: 'fit-content' }}>
        <IconButton
          sx={{
            position: 'absolute',
            top: 20,
            right: 20,
          }}
          onClick={onClose}
        >
          <CloseOutlined />
        </IconButton>
        <CardHeader title="Custom Filters" />
        <CardContent>
          <Stack
            divider={<Divider orientation="vertical" flexItem />}
            direction="row"
            spacing={2}
            justifyContent="center"
            mb={2}
          >
            <Stack spacing={2} flex={1}>
              {rangeFilterFields.map(({ id, label, type, inputProps }) => (
                <FormControl key={id}>
                  <FormLabel sx={{ mb: 1 }}>{label}</FormLabel>
                  <Stack spacing={2} direction="row">
                    <TextField
                      size="small"
                      id="outlined-number"
                      label="Min."
                      type={type}
                      InputLabelProps={{ shrink: true }}
                      inputProps={{
                        min: inputProps?.minField.minValue,
                        max: inputProps?.minField.maxValue,
                      }}
                      defaultValue={inputProps?.minField.defaultValue}
                      value={newCustomFilters?.[id]?.gte}
                      onChange={(event) =>
                        setNewCustomFilters((custom) => ({
                          ...(custom ?? {}),
                          [id]: {
                            ...(custom?.[id] ?? {}),
                            gte: event?.target?.value || '',
                          },
                        }))
                      }
                    />
                    <TextField
                      size="small"
                      id="outlined-number"
                      label="Max."
                      type={type}
                      InputLabelProps={{ shrink: true }}
                      value={newCustomFilters?.[id]?.lte}
                      inputProps={{
                        min: inputProps?.maxField.minValue,
                        max: inputProps?.maxField.maxValue,
                      }}
                      defaultValue={inputProps?.maxField.defaultValue}
                      onChange={(event) =>
                        setNewCustomFilters((custom) => ({
                          ...(custom ?? {}),
                          [id]: {
                            ...(custom?.[id] ?? {}),
                            lte: event?.target?.value || '',
                          },
                        }))
                      }
                    />
                  </Stack>
                </FormControl>
              ))}
            </Stack>
            <Stack spacing={2} flex={1}>
              <FormControl>
                <FormLabel sx={{ mb: 1 }}>Platform</FormLabel>
                <TextField
                  select
                  size="small"
                  id="outlined-number"
                  value={newCustomFilters?.platform?.eq || ''}
                  onChange={(event) =>
                    setNewCustomFilters((custom) => ({
                      ...(custom ?? {}),
                      platform: {
                        eq: event?.target?.value || '',
                      },
                    }))
                  }
                >
                  {Object.values(AppPlatforms).map((platform) => (
                    <MenuItem value={platform} key={platform}>
                      {capitalCase(platform)}
                    </MenuItem>
                  ))}
                </TextField>
              </FormControl>
              <FormControl>
                <FormLabel sx={{ mb: 1 }}>Blockchain</FormLabel>
                <TextField
                  select
                  size="small"
                  id="outlined-number"
                  value={newCustomFilters?.blockchain?.eq || ''}
                  onChange={(event) =>
                    setNewCustomFilters((custom) => ({
                      ...(custom ?? {}),
                      blockchain: {
                        eq: event?.target?.value || '',
                      },
                    }))
                  }
                >
                  {Object.values(BlockchainNetwork).map((platform) => (
                    <MenuItem value={platform} key={platform}>
                      {capitalCase(platform)}
                    </MenuItem>
                  ))}
                </TextField>
              </FormControl>
            </Stack>
          </Stack>

          <Stack direction="row" spacing={2} justifyContent="space-between">
            <Button color="error" onClick={onRemoveFilters}>
              Remove Filters
            </Button>
            <Button
              color="secondary"
              variant="contained"
              onClick={onApply}
              disabled={false}
            >
              Apply
            </Button>
          </Stack>
        </CardContent>
      </Card>
    </Modal>
  );
}
