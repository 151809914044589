import ConfirmationDialogRaw from 'src/components/shared/Dialog/ConfirmationDialog';
import useModalStore from 'src/lib/stores/useModalStore';
import useSendChannelContentToFeedView from './useView';

type Props = {
  uuid: string;
};
export default function SendChannelContentToFeedView({
  uuid,
}: Props): JSX.Element {
  const { handleSendToFeed } = useSendChannelContentToFeedView();
  const closeModal = useModalStore((state) => state.closeModal);

  return (
    <ConfirmationDialogRaw
      onClose={closeModal}
      open
      title={`Send To Feed`}
      invertColors={false}
      onConfirm={async () => {
        await handleSendToFeed(uuid);
      }}
    >
      Are you sure you want to post this Channel Content on the feed?
    </ConfirmationDialogRaw>
  );
}
