import {
  Box,
  Autocomplete as MaterialAutoComplete,
  TextField,
} from '@mui/material';

export type AutoCompleteOption = {
  label: string;
  img?: string;
  uuid: string;
};

type Props = {
  label: string;
  options: AutoCompleteOption[];
  isLoading: boolean;
  disabled?: boolean;
  handleInputChange: (event: React.ChangeEvent<{}>, newValue: any) => void;
  handleChange: (
    event: React.ChangeEvent<{}>,
    newValue: AutoCompleteOption | null
  ) => void;

  id: string;
  defaultValue?: AutoCompleteOption | null;
};
export default function AsyncAutoComplete({
  label,
  options: autoCompleteOptions,
  isLoading,
  handleInputChange,
  handleChange,
  disabled,
  defaultValue,
  id,
  ...otherFieldProps
}: Props) {
  return (
    <MaterialAutoComplete
      id={id}
      sx={{ width: 300 }}
      options={autoCompleteOptions}
      autoHighlight
      onInputChange={handleInputChange}
      onChange={handleChange}
      disabled={disabled}
      loading={isLoading}
      getOptionLabel={(option) => option.label}
      renderOption={(props, option) => (
        <Box
          component="li"
          sx={{ '& > img': { mr: 2, flexShrink: 0 } }}
          {...props}
        >
          {option.img && (
            <img
              loading="lazy"
              width="20"
              srcSet={`${option.img} 2x`}
              src={option.img}
              alt=""
            />
          )}
          {option.label}
        </Box>
      )}
      renderInput={(params) => (
        <TextField
          {...params}
          label={label}
          inputProps={{
            ...params.inputProps,
            autoComplete: 'new-password', // disable autocomplete and autofill
          }}
          {...otherFieldProps}
        />
      )}
      defaultValue={defaultValue}
    />
  );
}
