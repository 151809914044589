import { Device } from '../../../types/Device';
import BlockDeviceFormView from './View';
import useBlockDeviceFormView from './useView';

type Props = {
  device: Device;
};
export default function BlockDeviceForm({ device }: Props): JSX.Element {
  const { handleSubmit } = useBlockDeviceFormView();
  return (
    <BlockDeviceFormView
      initialValues={{
        reason: device.blockReason ?? '',
      }}
      onSubmit={async (values) => {
        await handleSubmit(device.uuid, values);
      }}
    />
  );
}
