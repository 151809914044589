import { Container } from '@mui/material';
import UserLinks from 'src/components/modules/UserLinks/views/List';
import Page from 'src/template/components/Page';

export default function UsersLinksPage() {
  return (
    <Page title="Users Links">
      <Container maxWidth="xl">
        <UserLinks />
      </Container>
    </Page>
  );
}
