import getAPIClient from 'src/lib/services/api/axios.config';
import { ServiceResponse } from 'src/lib/types/ServiceResponse';
import { Contest, ContestStatus } from 'src/lib/types/contest';
import { getMessageFromError } from 'src/lib/utils/error';

export async function ChangeContestStatus(
  uuid: string,
  status: ContestStatus
): Promise<ServiceResponse<Contest>> {
  try {
    const api = await getAPIClient();
    const res = await api.put(`admin/game-contest/${uuid}/status`, {
      status,
    });
    return {
      data: res.data,
      error: null,
    };
  } catch (e) {
    return {
      data: null,
      error: getMessageFromError(e),
    };
  }
}
