import { DEFAULT_ROWS_OPTIONS } from 'src/lib/constants/pagination';
import useURLSearchParams from 'src/lib/hooks/useURLSearchParams';
import { fetchNFTSForPackWithTransfersCount } from '../../services/fetchNFTSForPackWithTransfersCount';

export default function useNFTSForPacksView() {
  const { allSearchParams } = useURLSearchParams({
    sortBy: 'ownedAmount',
    order: 'desc',
    page: '0',
    size: Math.max(...DEFAULT_ROWS_OPTIONS).toString(),
    searchBy: 'name',
  });
  const page = Number(allSearchParams.page);
  const size = Number(allSearchParams.size);

  const { data, error, isLoading } = fetchNFTSForPackWithTransfersCount({
    page,
    size,
    order: allSearchParams.order,
    orderBy: allSearchParams.sortBy,
    query: allSearchParams.query,
    searchBy: allSearchParams.searchBy,
  });

  return {
    data,
    isLoading,
    page,
    size,
    order: allSearchParams.order,
    sortBy: allSearchParams.sortBy,
    searchBy: allSearchParams.searchBy,
    query: allSearchParams.query,
  };
}
