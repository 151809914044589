import { ResponseWithStatus } from 'src/lib/types/ResponseWithStatus';
import { Chat } from 'src/lib/types/chat';
import useSWR, { SWRResponse } from 'swr';

export const fetchChat = (
  roomId: string
): SWRResponse<ResponseWithStatus<Chat>> => {
  const response = useSWR<ResponseWithStatus<Chat>, Error>(
    `admin/chats/${roomId}`
  );

  return response;
};
