import { Container } from '@mui/material';
import Submissions from 'src/components/modules/Submissions/view/List';
import Page from 'src/template/components/Page';

export default function SubmissionsPage() {
  return (
    <Page title="Submissions">
      <Container maxWidth="xl">
        <Submissions />
      </Container>
    </Page>
  );
}
