import useNotify from 'src/lib/hooks/useNotify';
import useModalStore from 'src/lib/stores/useModalStore';
import { updateDevice } from '../../../services/updateDevice';
import { BlockDeviceFormValues } from './types';

export default function useBlockDeviceFormView() {
  const notify = useNotify();
  const closeModal = useModalStore((state) => state.closeModal);

  async function handleSubmit(
    id: string,
    values: BlockDeviceFormValues
  ): Promise<void> {
    const { error } = await updateDevice(id, {
      active: false,
      blockReason: values.reason,
    });
    if (error) {
      notify(error, { variant: 'error' });
      return;
    }
    notify('Device blocked successfully', { variant: 'success' });
    closeModal();
  }

  return {
    handleSubmit,
  };
}
