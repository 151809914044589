export function formatPercentage(
  value: number | undefined,
  decimalPlaces: number = 2
): string {
  if (!value) {
    return '-'; // Retorna um traço ou algum outro valor padrão se o valor for indefinido
  }

  const percentage = (value * 100).toFixed(decimalPlaces);
  return `${percentage}%`;
}
