import { Collection } from 'src/lib/types/collection';
import AddNftsModalView from './View';
import { addNftsEvent } from './events';
import useAddNftsModal from './useView';

type Props = {
  collection: Collection;
};

export default function AddNftsModal({ collection }: Props): JSX.Element {
  const { submitHandler, closeModal } = useAddNftsModal();
  return (
    <AddNftsModalView
      onClose={closeModal}
      onSubmit={async (values) => {
        const { error, data } = await submitHandler(values, collection.uuid);
        if (data && !error) {
          addNftsEvent.emit('nftAdded', data);
          closeModal();
        }
      }}
      open
      defaultValues={{
        nftData: {
          name: collection.name,
          editions: 1,
          price: 0,
          tags: '',
        },
        files: [],
      }}
    />
  );
}
