import { debounce } from 'lodash';
import { DEFAULT_INITIAL_PAGE_SIZE } from 'src/lib/constants/pagination';
import useURLSearchParams from 'src/lib/hooks/useURLSearchParams';
import { SubmissionFormStatuses } from 'src/lib/types/formReviewStatus';
import useTabs from 'src/template/hooks/useTabs';
import { fetchSubmissions } from '../../services/fetchSubmissions';

export default function useChannelsView() {
  const { currentTab: reviewStatus, onChangeTab: onChangeReviewStatus } =
    useTabs('all', {
      queryName: 'reviewStatus',
    });
  const { allSearchParams, addParam, removeParam } =
    useURLSearchParams(initialParams);

  const params: ListSubmissionsParams = {
    page: Number(allSearchParams.page) ?? initialParams.page,
    size: Number(allSearchParams.size) ?? initialParams.size,
    sortBy: allSearchParams.sortBy ?? initialParams.sortBy,
    order: allSearchParams.order ?? initialParams.order,
    searchBy: allSearchParams.searchBy ?? initialParams.searchBy,
    query: allSearchParams.query ?? initialParams.query,
  };

  const query: any = {};
  if (params.searchBy && params.query) {
    query[params.searchBy] = {
      like: `%${params.query}%`,
    };
  }

  const { data, isLoading, mutate } = fetchSubmissions({
    filter: {
      ...(params.searchBy &&
        params.query && {
          [params.searchBy]: {
            like: `%${params.query}%`,
          },
        }),
      ...(reviewStatus === 'all'
        ? {}
        : { latestStatus: reviewStatus as SubmissionFormStatuses }),
    },
    page: params.page,
    size: params.size,

    order: {
      [params.sortBy]: params.order,
    },
  });

  function handleChangeSearch(value: string, param: string = 'query') {
    if (!value) removeParam(param);
    else addParam(param, value);
  }

  const debouncedSearch = debounce(handleChangeSearch, 300);

  return {
    data,
    isLoading,
    onChangeReviewStatus,
    reviewStatus,
    params,
    debouncedSearch,
    addParam,
    removeParam,
  };
}

export type ListSubmissionsParams = {
  page: number;
  size: number;
  sortBy: string;
  order: string;
  searchBy?: string;
  query?: string;
};

const initialParams = {
  sortBy: 'updatedAt',
  order: 'desc',
  page: '0',
  size: DEFAULT_INITIAL_PAGE_SIZE.toString(),
  searchBy: 'itemType',
  query: '',
};
