import { Box, Container } from '@mui/material';
import Flex from 'src/components/shared/Flex';
import { Chat } from 'src/lib/types/chat';
import HeaderBreadcrumbs from 'src/template/components/HeaderBreadcrumbs';
import ChatScreen from '../ChatScreen';
import useMessageDetails from './useView';

export default function MessageDetails() {
  const { data, chatData, isLoading, params } = useMessageDetails();

  return (
    <Container maxWidth="xl">
      <HeaderBreadcrumbs
        heading="Chat Message"
        links={[{ name: 'Chat Message' }]}
      />
      <Flex justifyContent={'center'} alignItems={'none'}>
        <Box width={'600px'} maxHeight={'800px'}>
          <ChatScreen
            chat={chatData?.response ?? ({} as Chat)}
            messages={[data?.response!]}
            isLoading={!data?.response}
            flexProps={{
              borderRadius: '16px',
            }}
          />
        </Box>
      </Flex>
    </Container>
  );
}
