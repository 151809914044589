import useNotify from 'src/lib/hooks/useNotify';
import useModalStore from 'src/lib/stores/useModalStore';
import { NFTPackRarityFormValues } from '../../../components/Forms/Rarity/types';
import { NftPackEmitter } from '../../../events/NftPackEmitter';
import { SetNftPackRarities } from '../../../services/setNftPackRarities';

export default function useEditNftPackRaritiesView() {
  const closeModal = useModalStore((state) => state.closeModal);
  const notify = useNotify();

  const handleEditRaritesFromNftPack = async (
    uuid: string,
    values: NFTPackRarityFormValues
  ) => {
    const { error } = await SetNftPackRarities({
      proportions: values.rarities.map((rarity) => ({
        rarityUuid: rarity.uuid,
        proportion: rarity.proportion,
      })),
      uuid,
    });

    if (error) {
      notify(error, { variant: 'error' });
      closeModal();
    } else {
      notify('Nft Pack Rarities Set!', { variant: 'success' });
      closeModal();

      NftPackEmitter.emit('NftPackRaritiesUpdated', null);
    }
  };

  return {
    handleEditRaritesFromNftPack,
  };
}
