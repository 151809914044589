import { Box } from '@mui/material';
import UserRankTable from '../../../components/Rankings/BuyerAndSellerRankTable';
import useUserRankTopBuyersView from './useView';

export default function UserRankTopBuyersView(): JSX.Element {
  const {
    data,
    isLoading,
    handleOpenFilter,
    currentPage,
    currentRankBy,
    currentSize,
  } = useUserRankTopBuyersView();
  return (
    <Box mx={2}>
      <UserRankTable
        rankType="buyer"
        data={data?.body}
        isLoading={isLoading}
        stats={data?.meta}
        handleOpenFilter={handleOpenFilter}
        page={currentPage}
        rankBy={currentRankBy}
        size={currentSize}
      />
    </Box>
  );
}
