import { Container } from '@mui/material';
import UsersWithoutWallet from 'src/components/modules/Users/view/UsersWithoutWalletList';
import Page from 'src/template/components/Page';

export default function UsersWithoutWalletPage(): JSX.Element {
  return (
    <Page title="Users Without Wallet">
      <Container maxWidth="xl">
        <UsersWithoutWallet />
      </Container>
    </Page>
  );
}
