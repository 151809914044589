import { Card, CardContent } from '@mui/material';
import { GameToAdd } from 'src/components/modules/Clubs/components/Forms/Club/types';
import { Game } from 'src/lib/types/game';
import AddGamesTable from '../../components/addGamesTable';
import useSelectGames from './useView';

interface SelectGameProps {
  handleAddGame: (game: Game) => void;
  handleRemoveGame: (uuid: string) => void;
  selectedGames: GameToAdd[];
}

export default function SelectGames({
  handleAddGame,
  handleRemoveGame,
  selectedGames,
}: SelectGameProps): JSX.Element {
  const { data, isLoading, params } = useSelectGames();

  return (
    <Card>
      <CardContent>
        <AddGamesTable
          data={data?.rows ?? []}
          totalCount={data?.count ?? 0}
          isLoading={isLoading}
          params={params}
          handleAddGame={handleAddGame}
          handleRemoveGame={handleRemoveGame}
          selectedGames={selectedGames}
        />
      </CardContent>
    </Card>
  );
}
