import { Send } from '@mui/icons-material';
import {
  Autocomplete,
  Avatar,
  Box,
  Button,
  Checkbox,
  InputAdornment,
  Stack,
  TextField,
} from '@mui/material';
import { GridSortDirection } from '@mui/x-data-grid';
import { capitalCase } from 'change-case';
import { debounce } from 'lodash';
import { ReactNode, useState } from 'react';
import { reviewStatusColumns } from 'src/components/modules/Review/reviewStatusColumn';
import { MODERATED_OBJECT_STATUS_TABS } from 'src/components/modules/Review/utils/utils';
import { CreateMultiplePurges } from 'src/components/modules/UserPurges/services/CreateMultiplePurges';
import Flex from 'src/components/shared/Flex';
import Link from 'src/components/shared/Link';
import Loader from 'src/components/shared/Loader';
import MagicTable from 'src/components/shared/MagicTable/MagicTable';
import SectionDivider from 'src/components/shared/SectionDivider';
import SelectOption from 'src/components/shared/SelectOption';
import { DEFAULT_INITIAL_PAGE_SIZE } from 'src/lib/constants/pagination';
import useNotify from 'src/lib/hooks/useNotify';
import useURLSearchParams from 'src/lib/hooks/useURLSearchParams';
import { LabelValue } from 'src/lib/types/labelValue';
import { OBJECT_MODERATION_REVIEW_STATUS } from 'src/lib/types/objectModeration';
import { formatUTCDate } from 'src/lib/utils/formatUTCDate';
import Routes from 'src/routes/Routes';
import Iconify from 'src/template/components/Iconify';
import Label from 'src/template/components/Label';
import { fetchUsers } from '../../services/fetchUsers';
import { UserType } from '../../types/UserType';

export default function UsersTable() {
  const { addParam, removeParam, allSearchParams } =
    useURLSearchParams(initialParams);
  const size = +(allSearchParams?.size ?? initialParams.size);
  const page = +(allSearchParams?.page ?? initialParams.page);
  const orderBy = allSearchParams?.orderBy ?? initialParams.orderBy;
  const sortOrder = allSearchParams?.order ?? initialParams.order;
  const searchBy = allSearchParams?.searchBy ?? initialParams.searchBy;
  const currentTab = allSearchParams?.status ?? 'all';

  const { data: users, isLoading } = fetchUsers({
    page,
    size,
    order: {
      [orderBy]: sortOrder,
    },
    filter: {
      lastSession: {
        gte: allSearchParams.startDate,
        lte: allSearchParams.endDate,
      },
      ...(searchBy &&
        allSearchParams?.query && {
          [searchBy]: {
            like: `%${allSearchParams.query}%`,
          },
        }),

      ...(!['all', 'humanAnalysisNeeded'].includes(currentTab ?? '') && {
        reviewStatus: currentTab as OBJECT_MODERATION_REVIEW_STATUS,
      }),
      ...(currentTab == 'humanAnalysisNeeded' && {
        humanAnalysisNeeded: true,
      }),
    },
  });

  function handleChangeSearch(value: string, param: string = 'query') {
    if (!value) removeParam(param);
    else addParam(param, value);
  }

  const debouncedSearch = debounce(handleChangeSearch, 1000);

  const InputBySearchBy: Record<string, ReactNode> = {
    lastSession: (
      <Flex gap={2} width="100%">
        <TextField
          fullWidth
          type="date"
          label="Start Date"
          InputLabelProps={{ shrink: true }}
          onChange={(e) => {
            const { value } = e.target;
            debouncedSearch(value ? `${value}T00:00:00` : '', 'startDate');
          }}
        />
        <TextField
          fullWidth
          type="date"
          label="End Date"
          InputLabelProps={{ shrink: true }}
          onChange={(e) => {
            const { value } = e.target;
            debouncedSearch(value ? `${value}T23:59:59` : '', 'endDate');
          }}
        />
      </Flex>
    ),
    userType: (
      <TextField
        fullWidth
        select
        onChange={(e) => {
          const { value } = e.target;
          handleChangeSearch(value);
        }}
      >
        {Object.values(UserType).map((type) => (
          <SelectOption value={type} key={type}>
            {capitalCase(type)}
          </SelectOption>
        ))}
      </TextField>
    ),
  };

  const [selectedUsers, setSelectedUsers] = useState<string[]>([]);
  const isUserSelected = (uuid: string) => selectedUsers.includes(uuid);

  const toggleSelect = (uuid: string) => {
    setSelectedUsers((prev) => {
      if (prev.includes(uuid)) {
        return prev.filter((id) => id !== uuid);
      }
      return [...prev, uuid];
    });
  };

  const handleSelect = (uuid: string) => {
    toggleSelect(uuid);
  };

  const notify = useNotify();
  const [isSendingToPurgeList, setIsSendingToPurgeList] = useState(false);

  const handleSendToPurgeList = async () => {
    setIsSendingToPurgeList(true);

    const { data, error } = await CreateMultiplePurges(selectedUsers);
    if (error) {
      notify(error, { variant: 'error' });
      setIsSendingToPurgeList(false);
      return;
    }

    notify(`${data?.length} users have been sent to the purge list.`, {
      variant: 'success',
    });

    setSelectedUsers([]);
    setIsSendingToPurgeList(false);
  };

  return (
    <>
      {selectedUsers.length > 0 && (
        <Flex
          justifyContent="flex-end"
          alignItems="center"
          p={2}
          position="fixed"
          bottom={0}
          left={0}
          zIndex={200}
          sx={{
            width: '100%',
            backgroundColor: 'rgba(255, 255, 255, 0.5)',
            height: 70,
            px: 2,
            backdropFilter: 'blur(10px)',
          }}
        >
          <Stack direction="row" spacing={2}>
            <Button
              variant="contained"
              disabled={!selectedUsers.length || isSendingToPurgeList}
              startIcon={isSendingToPurgeList ? <Loader size={15} /> : <Send />}
              sx={{ alignSelf: 'flex-end' }}
              color="info"
              onClick={handleSendToPurgeList}
            >
              {isSendingToPurgeList && 'Sending...'}
              {!isSendingToPurgeList && (
                <>
                  Send {selectedUsers.length}{' '}
                  {selectedUsers.length > 1 ? 'Users' : 'User'} to Purge List
                </>
              )}
            </Button>
            <Button
              variant="outlined"
              onClick={() => setSelectedUsers([])}
              color="info"
              disabled={isSendingToPurgeList}
            >
              Clear Selection
            </Button>
          </Stack>
        </Flex>
      )}
      <SectionDivider section="Dates are in UTC timezone" />
      <MagicTable.Container>
        <MagicTable.Tabs
          defaultTab="all"
          tabs={STATUS_TABS}
          tabsConfig={{ queryName: 'status' }}
        />
        <Flex gap={2} p={2}>
          <Autocomplete
            options={SEARCH_OPTIONS}
            fullWidth
            renderInput={(params) => (
              <TextField label="Search By" {...params} />
            )}
            getOptionLabel={(p) => p.label}
            sx={{ maxWidth: 250 }}
            defaultValue={SEARCH_OPTIONS.find((o) => o.value === searchBy)}
            key={searchBy}
            onChange={(_, value) => {
              if (!value) removeParam('searchBy');
              else addParam('searchBy', value.value);
            }}
          />
          {allSearchParams?.searchBy &&
          InputBySearchBy?.[allSearchParams.searchBy] ? (
            InputBySearchBy[allSearchParams.searchBy]
          ) : (
            <TextField
              fullWidth
              label="Search"
              defaultValue={allSearchParams.query}
              type="search"
              onChange={(e) => {
                const { value } = e.target;
                debouncedSearch(value);
              }}
              key={allSearchParams.query}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <Iconify
                      icon="eva:search-fill"
                      sx={{ color: 'text.disabled', width: 20, height: 20 }}
                    />
                  </InputAdornment>
                ),
              }}
            />
          )}
        </Flex>
        <Box px={2}>
          <MagicTable
            rowHeight={64}
            loading={isLoading}
            pageSize={size}
            page={page}
            paginationMode="server"
            onPageChange={(newPage) => {
              addParam('page', newPage);
            }}
            onPageSizeChange={(newSize) => {
              addParam('size', newSize);
            }}
            onSortModelChange={(model) => {
              const sortModel = model[0];
              addParam('orderBy', sortModel.field);
              addParam('order', sortModel.sort!);
            }}
            sortModel={[
              {
                field: orderBy,
                sort: sortOrder as GridSortDirection,
              },
            ]}
            rows={users ?? []}
            hideNumberOfRows
            columns={[
              {
                field: '',
                headerName: '',
                sortable: false,
                flex: 0,
                renderCell({ row }) {
                  return (
                    <Checkbox
                      checked={isUserSelected(row.uuid)}
                      onChange={(e) => {
                        e.stopPropagation();
                        e.preventDefault();
                        handleSelect(row.uuid);
                      }}
                    />
                  );
                },
              },
              {
                field: 'name',
                headerName: 'Name',
                sortable: false,
                renderCell({ row }) {
                  return (
                    <>
                      <Avatar
                        alt={row?.name ?? 'user profile picture'}
                        src={row?.profileImage ?? ''}
                        sx={{ mr: 2 }}
                      />

                      <Link
                        to={Routes.user(row.uuid)}
                        color={row?.name ? 'inherit' : 'GrayText'}
                        underline="hover"
                      >
                        {row?.name || 'empty'}
                      </Link>
                    </>
                  );
                },
              },
              {
                field: 'slug',
                headerName: 'Slug',
                sortable: false,
              },
              {
                field: 'email',
                headerName: 'Email',
                sortable: false,
              },
              ...reviewStatusColumns,
              {
                field: 'active',
                headerName: 'Status',
                flex: 0,
                sortable: false,
                renderCell({ value }) {
                  if (value) {
                    return <Label color="success">Active</Label>;
                  }
                  return <Label color="error">Inactive</Label>;
                },
              },
              {
                field: 'lastSession',
                headerName: 'Last Session',
                sortingOrder: ['desc', 'asc'],
                flex: 1 / 2,
                sortComparator: () => 0,
                valueFormatter({ value }) {
                  return value ? formatUTCDate(value) : '-';
                },
              },
              {
                field: 'createdAt',
                headerName: 'Created At',
                sortingOrder: ['desc', 'asc'],
                flex: 1 / 2,
                sortComparator: () => 0,
                valueFormatter({ value }) {
                  return value ? formatUTCDate(value) : '-';
                },
              },
            ]}
          />
        </Box>
      </MagicTable.Container>
    </>
  );
}

const initialParams = {
  page: '0',
  size: DEFAULT_INITIAL_PAGE_SIZE.toString(),
  order: 'desc',
  orderBy: 'lastSession',
  searchBy: 'slug',
  status: 'all',
};

const SEARCH_OPTIONS: LabelValue[] = [
  {
    label: 'Slug',
    value: 'slug',
  },
  {
    label: 'Name',
    value: 'name',
  },
  {
    label: 'User Type',
    value: 'userType',
  },
  {
    label: 'Email',
    value: 'email',
  },
  {
    label: 'UUID',
    value: 'uuid',
  },
  {
    label: 'Auth ID',
    value: 'authId',
  },
  {
    label: 'Last Session',
    value: 'lastSession',
  },
  {
    label: 'Payout E-Mail',
    value: 'paymentInfo.paypalEmail',
  },
  {
    label: 'Global Wallet ID',
    value: 'globalWalletUuid',
  },
  {
    label: 'Hash',
    value: 'hash',
  },
  {
    label: 'IDFV',
    value: 'appleVendorId',
  },
  {
    label: 'IDFA',
    value: 'advertisingId',
  },
  {
    label: 'Language',
    value: 'lang',
  },
  {
    label: 'Region',
    value: 'region',
  },
  {
    label: 'Platform',
    value: 'platform',
  },
  {
    label: 'Sticky Wallet ID',
    value: 'walletUuid',
  },
  {
    label: 'Crypto Wallet Address',
    value: 'cryptoWalletAddress',
  },
  {
    label: 'Crypto Wallet UUID',
    value: 'cryptoWalletUuid',
  },
];

const STATUS_TABS = MODERATED_OBJECT_STATUS_TABS;
