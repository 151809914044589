import { Container } from '@mui/material';
import RevenueEventsConsistencyView from 'src/components/modules/RevenueEvents/view/Consistency';
import HeaderBreadcrumbs from 'src/template/components/HeaderBreadcrumbs';
import Page from 'src/template/components/Page';

export default function RevenueEventsConsistencyPage(): JSX.Element {
  return (
    <Page title="Revenue Events with Failed Transactions">
      <Container maxWidth="xl">
        <HeaderBreadcrumbs
          heading="Revenue Events with Failed Transactions"
          links={[{ name: 'Rev. Events with Failed Transactions' }]}
        />
        <RevenueEventsConsistencyView />
      </Container>
    </Page>
  );
}
