import { NotificationsActive } from '@mui/icons-material';
import Button from 'src/components/shared/Buttons/CustomButton';
import HeaderBreadcrumbs from 'src/template/components/HeaderBreadcrumbs';
import CommunicationsTable from '../../components/CommunicationsTable';
import useCommunicationsListView from './useView';

export default function CommunicationsList(): JSX.Element {
  const { handleSendPushNotification, data, isLoading } =
    useCommunicationsListView();
  return (
    <>
      <HeaderBreadcrumbs
        heading="Communications"
        links={[{ name: 'Communications List' }]}
        action={
          <Button
            color="secondary"
            variant="contained"
            startIcon={<NotificationsActive />}
            onClick={handleSendPushNotification}
          >
            Send Notification
          </Button>
        }
      />
      <CommunicationsTable data={data ?? []} isLoading={isLoading} />
    </>
  );
}
