import { ContentCopy } from '@mui/icons-material';
import { Link, MenuItem, Stack, TableCell, TableRow } from '@mui/material';
import CopyToClipboard from 'react-copy-to-clipboard';
import { Link as RouterLink } from 'react-router-dom';
import MoreMenu from 'src/components/shared/MoreMenu';
import NumericTypography from 'src/components/shared/NumericTypography';
import SectionDivider from 'src/components/shared/SectionDivider';
import SmartTable from 'src/components/shared/SmartTable/SmartTable';
import { fetchOldTransactions } from 'src/lib/services/transactions';
import createFilterStore from 'src/lib/stores/createFilterStore';
import { OldTransaction } from 'src/lib/types/OldTransaction';
import formatStickyCoinValue from 'src/lib/utils/formatStickyCoinValue';
import { formatUTCDate } from 'src/lib/utils/formatUTCDate';
import getUserName from 'src/lib/utils/getUserName';
import Routes from 'src/routes/Routes';
import HeaderBreadcrumbs from 'src/template/components/HeaderBreadcrumbs';
import Label from 'src/template/components/Label';
import TransactionsFilter from './TransactionsFilter';

const useFilter = createFilterStore({
  orderBy: 'createdAt',
  order: 'desc',
  searchFor: 'walletFrom.user.slug',
  query: '',
  page: 0,
  size: 30,
  custom: {},
});

const searchForOptions = [
  {
    label: 'From: Slug',
    value: 'walletFrom.user.slug',
  },
  {
    label: 'From: Name',
    value: 'walletFrom.user.name',
  },
  {
    label: 'To: Slug',
    value: 'walletTo.user.slug',
  },
  {
    label: 'To: Name',
    value: 'walletTo.user.name',
  },
  { label: 'UUID', value: 'uuid' },
];

export default function OldTransactions() {
  const { filter, setFilter } = useFilter();

  const { data: transactions, total, loading } = fetchOldTransactions(filter);

  return (
    <>
      <HeaderBreadcrumbs
        heading="Old Transactions"
        links={[{ name: 'Old Transactions' }]}
      />
      <SectionDivider section="Dates are in UTC timezone" />
      <SmartTable
        data={transactions}
        loading={loading}
        total={total || 0}
        columns={[
          {
            id: 'product',
            label: 'Product',
            isSortable: false,
          },
          {
            id: 'type',
            label: 'Type',
            isSortable: false,
          },
          {
            id: 'value',
            label: 'Amount',
            isSortable: false,
          },
          {
            id: 'from',
            label: 'From',
            isSortable: false,
          },
          {
            id: 'to',
            label: 'To',
            isSortable: false,
          },
          {
            id: 'region',
            label: 'Region',
            isSortable: false,
          },
          {
            id: 'platform',
            label: 'Platform',
            isSortable: false,
          },
          {
            id: 'createdAt',
            label: 'Date',
            align: 'right',
            isSortable: true,
          },
          {
            id: 'actions',
            label: '',
            isSortable: false,
          },
        ]}
        filter={filter}
        setFilter={setFilter}
        filterModal={
          <TransactionsFilter filter={filter} setFilter={setFilter} />
        }
        searchForOptions={searchForOptions}
      >
        {transactions &&
          transactions.map((t) => (
            <TableRow key={t.uuid} hover>
              <TableCell>{getRechargeType(t)}</TableCell>
              <TableCell>{getType(t)}</TableCell>
              <TableCell>
                <Stack spacing={1}>
                  <NumericTypography>{getValue(t)}</NumericTypography>
                </Stack>
              </TableCell>
              <TableCell>
                {t.walletFrom?.userUuid ? (
                  <Link
                    component={RouterLink}
                    to={Routes.user(t.walletFrom?.userUuid)}
                  >
                    {getUserName(t.walletFrom?.user)}
                  </Link>
                ) : (
                  <Label>empty</Label>
                )}
              </TableCell>
              <TableCell>
                {t.walletTo?.userUuid ? (
                  <Link
                    component={RouterLink}
                    to={Routes.user(t.walletTo?.userUuid)}
                  >
                    {getUserName(t.walletTo?.user)}
                  </Link>
                ) : (
                  <Label>empty</Label>
                )}
              </TableCell>
              <TableCell>{t.walletFrom?.user?.region || '-'}</TableCell>
              <TableCell>{getPlatform(t)}</TableCell>
              <TableCell align="right">{formatUTCDate(t.createdAt)}</TableCell>
              <TableCell align="right">
                <MoreMenu>
                  <CopyToClipboard text={t.uuid}>
                    <MenuItem>
                      <ContentCopy />
                      Copy UUID
                    </MenuItem>
                  </CopyToClipboard>
                </MoreMenu>
              </TableCell>
            </TableRow>
          ))}
      </SmartTable>
    </>
  );
}

function getRechargeType(transaction: OldTransaction) {
  const { mint, nft, nfts, amount, objectProvider } = transaction;
  if (mint > 0) return `Mint Passes Purchase`;
  if (nfts > 0) return `${nfts} NFT Pack Purchase`;
  if (amount > 0 && objectProvider === 'apple') return `Coins Purchase (Apple)`;
  if (!!nft && objectProvider === 'stickers') return `NFT Purchase`;
  if (objectProvider === 'stickers') return `Daily Bonus`;
  if (objectProvider === 'gamification') return `Free Coins`;
  return `Coins Purchase`;
}

function getPlatform(transaction: OldTransaction) {
  const { objectProvider } = transaction;
  if (objectProvider === 'apple') return 'Apple';
  if (objectProvider === 'eth') return 'Web';
  if (objectProvider === 'google') return 'Android';
  return 'Web';
}

function getValue(transaction: OldTransaction) {
  const { amount, nfts } = transaction;
  if (amount) return formatStickyCoinValue(amount);
  if (nfts) return nfts;
  return '-';
}

function getType(transaction: OldTransaction) {
  if (transaction.objectProductFullId === null) return '-';

  const subscription = [
    'com.sticky.coins',
    'com.sticky.mintpass',
    'com.sticky.nftpack',
  ];

  const plan = [
    'sticky-coins-weekly-g1',
    'mintpass-weekly',
    'sticky-nftpack-weekly1',
  ];

  const trial = ['mintpass-weekly-3dft', 'sticky-nftpack-weekly1-3dft'];

  if (subscription.includes(transaction.objectProductFullId))
    return 'Subscription';
  if (plan.includes(transaction.objectProductFullId)) return 'Plan';
  if (trial.includes(transaction.objectProductFullId)) return 'Free Trial';
  return 'Consumable';
}
