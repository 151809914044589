import { Avatar, Box } from '@mui/material';
import { GridSortDirection } from '@mui/x-data-grid';
import Flex from 'src/components/shared/Flex';
import Link from 'src/components/shared/Link';
import MagicTable from 'src/components/shared/MagicTable/MagicTable';
import SectionDivider from 'src/components/shared/SectionDivider';
import { DEFAULT_INITIAL_PAGE_SIZE } from 'src/lib/constants/pagination';
import useURLSearchParams from 'src/lib/hooks/useURLSearchParams';
import { formatUTCDate } from 'src/lib/utils/formatUTCDate';
import Routes from 'src/routes/Routes';
import Label from 'src/template/components/Label';
import { fetchUsersWithoutWallet } from '../../services/fetchUsersWithoutWallet';

export default function UsersWithoutWalletTable() {
  const { addParam, allSearchParams } = useURLSearchParams(initialParams);
  const size = +(allSearchParams?.size ?? initialParams.size);
  const page = +(allSearchParams?.page ?? initialParams.page);
  const orderBy = allSearchParams?.orderBy ?? initialParams.orderBy;
  const sortOrder = allSearchParams?.order ?? initialParams.order;

  const { data: users, isLoading } = fetchUsersWithoutWallet({
    page,
    size,
    order: {
      [orderBy]: sortOrder,
    },
    filter: {
      lastSession: {
        gte: allSearchParams.startDate,
        lte: allSearchParams.endDate,
      },
      ...(allSearchParams.status && {
        active: allSearchParams.status === 'active',
      }),
    },
  });

  return (
    <>
      <SectionDivider section="Dates are in UTC timezone" />
      <MagicTable.Container>
        <Flex gap={2} p={2} />
        <Box px={2}>
          <MagicTable
            loading={isLoading}
            pageSize={size}
            page={page}
            paginationMode="server"
            onPageChange={(newPage) => {
              addParam('page', newPage);
            }}
            onPageSizeChange={(newSize) => {
              addParam('size', newSize);
            }}
            onSortModelChange={(model) => {
              const sortModel = model[0];
              addParam('orderBy', sortModel.field);
              addParam('order', sortModel.sort!);
            }}
            sortModel={[
              {
                field: orderBy,
                sort: sortOrder as GridSortDirection,
              },
            ]}
            rows={users ?? []}
            hideNumberOfRows
            columns={[
              {
                field: 'name',
                headerName: 'Name',
                sortable: false,
                renderCell({ row }) {
                  return (
                    <>
                      <Avatar
                        alt={row?.name ?? 'user profile picture'}
                        src={row?.profileImage ?? ''}
                        sx={{ mr: 2 }}
                      />

                      <Link
                        to={Routes.user(row.uuid)}
                        color={row?.name ? 'inherit' : 'GrayText'}
                        underline="hover"
                      >
                        {row?.name || 'empty'}
                      </Link>
                    </>
                  );
                },
              },
              {
                field: 'slug',
                headerName: 'Slug',
                sortable: false,
              },
              {
                field: 'email',
                headerName: 'Email',
                sortable: false,
              },
              {
                field: 'active',
                headerName: 'Status',
                flex: 0,
                sortable: false,
                renderCell({ value }) {
                  if (value) {
                    return <Label color="success">Active</Label>;
                  }
                  return <Label color="error">Inactive</Label>;
                },
              },
              {
                field: 'lastSession',
                headerName: 'Last Session',
                sortingOrder: ['desc', 'asc'],
                flex: 1 / 2,
                sortComparator: () => 0,
                valueFormatter({ value }) {
                  return value ? formatUTCDate(value) : '-';
                },
              },
              {
                field: 'createdAt',
                headerName: 'Created At',
                sortingOrder: ['desc', 'asc'],
                flex: 1 / 2,
                sortComparator: () => 0,
                valueFormatter({ value }) {
                  return value ? formatUTCDate(value) : '-';
                },
              },
            ]}
          />
        </Box>
      </MagicTable.Container>
    </>
  );
}

const initialParams = {
  page: '0',
  size: DEFAULT_INITIAL_PAGE_SIZE.toString(),
  order: 'desc',
  orderBy: 'lastSession',
};
