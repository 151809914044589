import { DeepPartial } from 'react-hook-form';
import { SGMChatStatistics } from 'src/lib/types/sgmChatMessage';
import { parseSearchParams } from 'src/lib/utils/parseSearchParams';
import useSWR, { SWRResponse } from 'swr';

export type PossibleParams = {
  limit: number | string;
  offset: number | string;
  order: Record<string, string>;
  filter: {
    userUuid: string;
    startDate: string;
    endDate: string;
  };
};

export const fetchMakerStats = (
  filter?: DeepPartial<PossibleParams>
): SWRResponse<SGMChatStatistics> => {
  const parsedParams = parseSearchParams(filter ?? {});
  const response = useSWR<SGMChatStatistics, Error>(
    `admin/game-maker-chats/statistics/?${parsedParams}`
  );

  return response;
};
