import { InfoOutlined } from '@mui/icons-material';
import { Tooltip as MTooltip, Typography } from '@mui/material';

export type Props = {
  title: string[]; // Atualizado para ser um array de strings
};

export default function Tooltip({ title }: Props): JSX.Element {
  return (
    <MTooltip
      title={
        <div style={{ padding: '8px' }}>
          <Typography variant="body2" component="div">
            <ul style={{ margin: 0, paddingLeft: '20px' }}>
              {' '}
              {/* Ajusta o padding do ul */}
              {title.map((item, index) => (
                // eslint-disable-next-line react/no-array-index-key
                <li key={index} style={{ listStyleType: 'disc' }}>
                  {item}
                </li>
              ))}
            </ul>
          </Typography>
        </div>
      }
      sx={{ maxHeight: '20px' }}
    >
      <InfoOutlined />
    </MTooltip>
  );
}
