import getAPIClient from 'src/lib/services/api/axios.config';
import { GameCategory } from 'src/lib/types/gameCategory';
import { ServiceResponse } from 'src/lib/types/ServiceResponse';
import { getMessageFromError } from 'src/lib/utils/error';

export type CreateGameWithVersionData = {
  uuid: string;
};

export async function CreateGameCategory(
  data: Partial<GameCategory>
): Promise<ServiceResponse<GameCategory>> {
  try {
    const api = await getAPIClient();
    const res = await api.post(`admin/game-category/`, data);
    return {
      data: res.data,
      error: null,
    };
  } catch (e) {
    return {
      data: null,
      error: getMessageFromError(e),
    };
  }
}
