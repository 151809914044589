import { Container } from '@mui/material';
import CoinsDeliveries from 'src/components/modules/Coins/view/Deliveries';
import Page from 'src/template/components/Page';

export default function CoinsDeliveryPage() {
  return (
    <Page title="Coins Delivery">
      <Container maxWidth="xl">
        <CoinsDeliveries />
      </Container>
    </Page>
  );
}
