import { ContentCopy, NotInterested, OpenInNew } from '@mui/icons-material';
import { IconButton, MenuItem } from '@mui/material';
import { GridSortDirection } from '@mui/x-data-grid';
import CopyToClipboard from 'react-copy-to-clipboard';
import Link from 'src/components/shared/Link';
import MagicTable from 'src/components/shared/MagicTable/MagicTable';
import MagicTableUserCell from 'src/components/shared/MagicTable/UserCell';
import MoreMenu from 'src/components/shared/MoreMenu';
import useURLSearchParams from 'src/lib/hooks/useURLSearchParams';
import { WithUser } from 'src/lib/types/users';
import Routes from 'src/routes/Routes';
import Label from 'src/template/components/Label';
import { CoinExpiration } from '../../../types/CoinExpiration';

type Props = {
  data: WithUser<CoinExpiration>[];
  isLoading: boolean;
  page: number;
  size: number;
  sortBy: string;
  order: GridSortDirection;
  handleOpenExpireModal?: (coinUuid: string) => void;
};
export default function CoinsExpiringTable({
  data,
  isLoading,
  page,
  size,
  handleOpenExpireModal,
}: Props): JSX.Element {
  const { addParam, removeParam } = useURLSearchParams();

  return (
    <MagicTable
      rows={data}
      getRowId={(r) => r.uuid + r.expiredByPoolUuid}
      loading={isLoading}
      paginationMode="server"
      hideNumberOfRows
      pageSize={size}
      page={page}
      onPageChange={(newPage) => {
        addParam('page', newPage);
      }}
      onPageSizeChange={(newSize) => {
        addParam('size', newSize);
      }}
      onSortModelChange={(model) => {
        const currentModel = model[0];
        if (currentModel) {
          addParam('sortBy', currentModel.field);
          if (currentModel.sort) addParam('order', currentModel.sort);
        } else {
          removeParam('order');
          removeParam('sortBy');
        }
      }}
      columns={[
        {
          field: 'user',
          headerName: 'User',
          renderCell: ({ row }) => <MagicTableUserCell user={row.user} />,
          sortable: false,
        },
        {
          field: 'user.active',
          headerName: 'User Status',
          align: 'center',
          sortable: false,
          flex: 1 / 2,
          renderCell: ({ row }) => {
            if (row.user.active) {
              return <Label color="success">Active</Label>;
            }
            return <Label color="error">Inactive</Label>;
          },
        },
        {
          field: 'available',
          headerName: 'Amount',
          sortable: false,
          flex: 1,
          renderCell: ({ row }) => {
            if (row.locked > 0)
              return `${row.locked / 1000} coin${
                row.locked > 1000 ? 's' : ''
              } locked`;

            const expired = !!row.expiredByPoolUuid;
            const value = expired ? row.expired : row.available;

            return `${value / 1000} coin${value > 1000 ? 's' : ''}`;
          },
        },
        {
          field: 'expiresIn',
          headerName: 'Expires In',
          sortable: false,
          flex: 1,
          renderCell: ({ row }) => {
            const expired = !!row.expiredByPoolUuid;
            const value = row.expiresIn ?? row.expiredAt;

            if (value === 0) return 'Today';

            if (expired) {
              return `Expired ${-row.expiredAt} day${
                value < -1 ? 's' : ''
              } ago`;
            }
            return `${row.expiresIn} day${value > 1 || value < -1 ? 's' : ''}`;
          },
        },
        {
          field: 'type',
          headerName: 'Type',
          sortable: false,
          flex: 1,
        },
        {
          field: 'origin',
          headerName: 'Origin',
          sortable: false,
          flex: 1,
        },
        {
          field: 'actions',
          headerName: '',
          sortable: false,
          flex: 0,
          align: 'center',
          renderCell: ({ row }) => (
            <>
              {row.expiredByPoolUuid && (
                <IconButton
                  component={Link}
                  to={Routes.transactions.pool(row.expiredByPoolUuid)}
                >
                  <OpenInNew />
                </IconButton>
              )}
              <MoreMenu>
                <CopyToClipboard text={row.uuid}>
                  <MenuItem aria-label="copy uuid">
                    <ContentCopy />
                    Copy UUID
                  </MenuItem>
                </CopyToClipboard>
                {handleOpenExpireModal && (
                  <MenuItem
                    aria-label="expire now"
                    onClick={() => {
                      handleOpenExpireModal(row.uuid);
                    }}
                  >
                    <NotInterested />
                    Expire Now
                  </MenuItem>
                )}
              </MoreMenu>
            </>
          ),
        },
      ]}
    />
  );
}
