import { Avatar, Stack } from '@mui/material';
import { User } from 'src/lib/types/users';
import getUserName from 'src/lib/utils/getUserName';
import Routes from 'src/routes/Routes';
import Label from 'src/template/components/Label';
import Flex from '../Flex';
import Link from '../Link';

type Props = {
  user?: Pick<
    User,
    'name' | 'slug' | 'email' | 'profileImage' | 'uuid' | 'active'
  >;
  linkUrl?: string;
  displayUserStatus?: boolean;
};
export default function MagicTableUserCell({
  user,
  linkUrl,
  displayUserStatus = false,
}: Props): JSX.Element {
  if (!user) {
    return <Flex component="span">empty</Flex>;
  }
  const { name, profileImage, uuid, active } = user;
  const userName = user ? getUserName(user) : 'empty';
  return (
    <Flex component="span" gap={2}>
      <Avatar alt={name ?? 'user profile picture'} src={profileImage ?? ''} />
      <Stack>
        <Link
          to={linkUrl ?? Routes.user(uuid)}
          color={userName !== 'empty' ? 'inherit' : 'GrayText'}
          underline="hover"
        >
          {userName}
        </Link>
        {!active && displayUserStatus && (
          <Label
            color="error"
            sx={{
              minWidth: 'unset',
              width: 'fit-content',
              fontSize: '10px',
              height: '17px',
              px: 1.5,
            }}
          >
            Inactive
          </Label>
        )}
      </Stack>
    </Flex>
  );
}
