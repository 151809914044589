import { CommonDateStrings } from 'src/lib/constants/CommonDates';
import { DEFAULT_INITIAL_PAGE_SIZE } from 'src/lib/constants/pagination';
import useURLSearchParams from 'src/lib/hooks/useURLSearchParams';
import { fetchFeedItemAnalytics } from '../../services/fetchFeedItemAnalytics';

export type FetchFeedItemAnalyticsQueryParams = {
  page: number;
  size: number;
  filterQuery: string;
  filterSearchBy: string;
  startDate?: string;
  endDate?: string;
  orderBy: string;
  sortOrder: string;
};

const initialParams = {
  page: '0',
  size: DEFAULT_INITIAL_PAGE_SIZE.toString(),
  filterQuery: '',
  filterSearchBy: '',
  startDate: CommonDateStrings.sevenDaysAgo.dateTime.split('T')[0],
  endDate: CommonDateStrings.today.endOfDay.split('T')[0],

  orderBy: '',
  order: 'desc',
};

export function useFeedItemAnalyticsTable() {
  const { allSearchParams } = useURLSearchParams(initialParams);

  const params: FetchFeedItemAnalyticsQueryParams = {
    size: +(allSearchParams?.size ?? initialParams.size),
    page: +(allSearchParams?.page ?? initialParams.page),
    filterQuery: allSearchParams.filterQuery ?? initialParams.filterQuery,
    filterSearchBy:
      allSearchParams.filterSearchBy ?? initialParams.filterSearchBy,

    startDate: allSearchParams.startDate ?? initialParams.startDate,
    endDate: allSearchParams.endDate ?? initialParams.endDate,

    orderBy: allSearchParams.orderBy ?? initialParams.orderBy,
    sortOrder: allSearchParams.order ?? initialParams.order,
  };

  const fetchData = {
    page: params.page,
    size: params.size,

    order: params.sortOrder,
    orderBy: params.orderBy,

    filter: {
      ...(params.filterSearchBy &&
        params.filterQuery && {
          [params.filterSearchBy]: {
            like: `${params.filterQuery}`,
          },
        }),
      createdAt: {
        ...(params.startDate && {
          gte: `${params.startDate}T00:00:00`,
        }),
        ...(params.endDate && {
          lte: `${params.endDate}T23:59:59`,
        }),
      },
    },
  };

  const { data, isLoading } = fetchFeedItemAnalytics(fetchData);

  return {
    data,
    isLoading,
    params,
  };
}
