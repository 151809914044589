import { Card, CardContent, Tab, Tabs } from '@mui/material';
import { Suspense } from 'react';
import Flex from 'src/components/shared/Flex';
import Loader from 'src/components/shared/Loader';
import { OBJECT_MODERATION_OBJECT_TYPE } from 'src/lib/types/objectModeration';
import HeaderBreadcrumbs from 'src/template/components/HeaderBreadcrumbs';
import { TO_REVIEW_TABS } from './toReviewTabs';
import useToReviewView from './useView';

const STATUS_TABS = [
  /*
  ...Object.values(OBJECT_MODERATION_OBJECT_TYPE).map((value) => ({
    value,
    label: value,
  })),
  */
  {
    value: OBJECT_MODERATION_OBJECT_TYPE.CHANNEL,
    label: OBJECT_MODERATION_OBJECT_TYPE.CHANNEL,
  },
  {
    value: OBJECT_MODERATION_OBJECT_TYPE.VIDEO,
    label: OBJECT_MODERATION_OBJECT_TYPE.VIDEO,
  },
  {
    value: OBJECT_MODERATION_OBJECT_TYPE.GAME_VERSION,
    label: OBJECT_MODERATION_OBJECT_TYPE.GAME_VERSION,
  },
];

export default function ToReview() {
  const { currentTab, onChangeTab, data, isLoading, params } =
    useToReviewView();

  return (
    <>
      <HeaderBreadcrumbs heading="To Review" links={[{ name: 'To Review' }]} />
      <Card>
        <Flex sx={{ px: 2, bgcolor: 'background.neutral' }}>
          <Tabs
            allowScrollButtonsMobile
            variant="scrollable"
            scrollButtons="auto"
            value={currentTab}
            onChange={onChangeTab}
          >
            {STATUS_TABS.map((tab) => (
              <Tab
                disableRipple
                key={tab.value}
                label={tab.label}
                value={tab.value}
              />
            ))}
          </Tabs>
        </Flex>

        <CardContent>
          <Suspense fallback={<Loader />}>
            {TO_REVIEW_TABS.map(
              (tab, index) =>
                tab.value === currentTab &&
                tab.component(data ?? [], isLoading, params, index)
            )}
          </Suspense>
        </CardContent>
      </Card>
    </>
  );
}
