import { DeepPartial } from 'react-hook-form';
import { GameSessionStats } from 'src/lib/types/gameSession';
import { parseSearchParams } from 'src/lib/utils/parseSearchParams';
import useSWR, { SWRResponse } from 'swr';

export type PossibleParams = {
  startDate: string;
  endDate: string;
};

export const fetchGameGeneralStats = (
  filter?: DeepPartial<PossibleParams>
): SWRResponse<GameSessionStats> => {
  const parsedParams = parseSearchParams(filter ?? {});
  const response = useSWR<GameSessionStats, Error>(
    `admin/game-session/game/general-stats/?${parsedParams}`
  );

  return response;
};
