export type Product = {
  uuid: string;
  providerProductId: string;
  provider: ProductProvider;
  productId: string;
  type: ProductType;
  name: string;
  displayName?: string | null;
  description?: string | null;
  subscriptionDuration?: number | null;
  freeTrialDuration?: number | null;
  freeTrialRechargeValue?: number | null;
  approvalStatus?: string | null;
  clearedForSale?: boolean | null;
  isDaily: boolean;
  familySharing?: boolean | null;
  hasGracePeriod?: boolean | null;
  subscriptionGroupIdentifier?: number | null;
  subscriptionGroupName?: string | null;
  levelOfService?: number | null;
  priceUSD: string;
  hasFreeTrial?: boolean | null;
  visible?: boolean | null;
  startDate?: Date | null;
  endDate?: Date | null;
  rechargeType?: RechargeType | null;
  rechargeValue?: number | null;
  createdAt: Date;
  updatedAt: Date;
  deletedAt?: Date | null;
};

export enum ProductProvider {
  APPLE = 'apple',
  GOOGLE = 'google',
  WEB = 'web',
}

export enum ProductType {
  CONSUMABLE = 'Consumable',
  AUTO_RENEWABLE_SUBSCRIPTION = 'Auto-Renewable Subscription',
}

export enum RechargeType {
  STICKY_COINS = 'StickyCoin',
  NFT = 'NFT',
  NFT_EDITION = 'NFT Edition',
  STICKY_CUP_NFT = 'StickyCupNFT',
  PRO_FEATURES = 'ProFeatures',
}

export type WithProduct<T> = T & {
  product: Product;
};
