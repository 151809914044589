import { Add } from '@mui/icons-material';
import { Button, Card, CardContent, Container } from '@mui/material';
import Flex from 'src/components/shared/Flex';
import Routes from 'src/routes/Routes';
import HeaderBreadcrumbs from 'src/template/components/HeaderBreadcrumbs';
import AssetsTable from '../../components/assetsTable';
import useGameAssets from './useView';

export default function GameAssets() {
  const {
    data,
    isLoading,
    params,
    contestUuid,
    handleOpenAddAssetModal,
    handleRemoveAsset,
  } = useGameAssets();
  return (
    <Container maxWidth="xl">
      <HeaderBreadcrumbs
        heading="Assets"
        links={[
          ...(contestUuid
            ? [{ name: 'Contests', href: Routes.configurations.contests }]
            : []),
          { name: 'Assets' },
        ]}
        action={
          <Flex pt={2} px={2} justifyContent="flex-end">
            <Button
              variant="contained"
              color="info"
              size="large"
              onClick={() => {
                handleOpenAddAssetModal();
              }}
              startIcon={<Add />}
              sx={{
                minWidth: 150,
              }}
            >
              Add Asset
            </Button>
          </Flex>
        }
      />
      <Card>
        <CardContent>
          <AssetsTable
            data={data ?? []}
            isLoading={isLoading}
            params={params}
            handleRemoveAsset={handleRemoveAsset}
          />
        </CardContent>
      </Card>
    </Container>
  );
}
