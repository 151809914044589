import { IncludeExcludeAll } from 'src/lib/utils/includeExcludeFilterUtils';
import { RankBy } from '../../../services/fetchImporterAndCreatorRanking';
import { UserType } from '../../../types/UserType';

export const RankByLabel: Record<RankBy, string> = {
  total: 'Total',
  imported: 'Imported',
  created: 'Created',
};
export type RankFilterFormValues = {
  rankBy: RankBy;
  lang: string;
  country: string;
  startDate: string;
  endDate: string;
  InactiveUsers: IncludeExcludeAll;
  userType: UserType | 'all';
};
