import MessageDetails from 'src/components/modules/Chat/view/MessageDetails';
import Page from 'src/template/components/Page';

export default function ChatMessagePage() {
  return (
    <Page title="Chat Message">
      <MessageDetails />
    </Page>
  );
}
