import { Box, Container } from '@mui/material';
import Flex from 'src/components/shared/Flex';
import { Chat } from 'src/lib/types/chat';
import HeaderBreadcrumbs from 'src/template/components/HeaderBreadcrumbs';
import ChatHeader from '../ChatScreen/ChatHeader';
import ChatMessages from '../ChatScreen/ChatMessages';
import useChatDetails from './useView';

export default function ChatDetails() {
  const { data, messages, handleFetchMore } = useChatDetails();

  return (
    <Container maxWidth="xl">
      <HeaderBreadcrumbs heading="Chat" links={[{ name: 'Chat' }]} />
      <Flex justifyContent={'center'} alignItems={'none'}>
        <Box width={'600px'} maxHeight={'800px'}>
          <Flex
            flex="1"
            width="100%"
            height={'100%'}
            overflow="auto"
            bgcolor={'#000000'}
            flexDirection={'column'}
            alignItems={'center'}
          >
            <ChatHeader chat={data?.response ?? ({} as Chat)} />
            <ChatMessages
              key={'chat-messages'}
              messages={messages}
              loadMoreMessages={handleFetchMore}
            />
          </Flex>
        </Box>
      </Flex>
    </Container>
  );
}
