import { WithUsers } from 'src/lib/types/users';
import useSWR, { SWRResponse } from 'swr';
import { Device, DeviceModel } from '../types/Device';

function parseData(
  data: WithUsers<DeviceModel> | undefined
): WithUsers<Device> | undefined {
  if (!data) return undefined;

  const { appleVendorId, androidId, appleAdvertisingId, webId } = data;
  let platformId: string;
  if (appleAdvertisingId) platformId = appleAdvertisingId;
  else if (appleVendorId) platformId = appleVendorId;
  else if (webId) platformId = webId;
  else if (androidId) platformId = androidId;

  return {
    ...data,
    platformId: platformId!,
  };
}

export function fetchDevice(id: string): SWRResponse<WithUsers<Device>> {
  const { data, ...response } = useSWR(`devices/${id}`);

  return {
    ...response,
    data: parseData(data),
  };
}
