import { Card, CardContent, Tab, Tabs } from '@mui/material';
import { capitalCase } from 'change-case';
import Flex from 'src/components/shared/Flex';
import { OBJECT_MODERATION_REVIEW_STATUS } from 'src/lib/types/objectModeration';
import HeaderBreadcrumbs from 'src/template/components/HeaderBreadcrumbs';
import { MODERATED_OBJECTS_TABLES } from './toReviewTabs';
import useToReviewView from './useView';

const STATUS_TABS = [
  ...Object.values(OBJECT_MODERATION_REVIEW_STATUS).map((value) => ({
    value,
    label: capitalCase(value),
  })),
];

export default function ModeratedObjects() {
  const {
    currentTab,
    onChangeTab,
    data,
    isLoading,
    params,
    ObjectModerationObjectType,
  } = useToReviewView();

  return (
    <>
      <HeaderBreadcrumbs
        heading={capitalCase(ObjectModerationObjectType)}
        links={[{ name: capitalCase(ObjectModerationObjectType) }]}
      />
      <Card>
        <Flex sx={{ px: 2, bgcolor: 'background.neutral' }}>
          <Tabs
            allowScrollButtonsMobile
            variant="scrollable"
            scrollButtons="auto"
            value={currentTab}
            onChange={onChangeTab}
          >
            {STATUS_TABS.map((tab) => (
              <Tab
                disableRipple
                key={tab.value}
                label={tab.label}
                value={tab.value}
              />
            ))}
          </Tabs>
        </Flex>

        <CardContent>
          {MODERATED_OBJECTS_TABLES[ObjectModerationObjectType].component(
            data ?? [],
            isLoading,
            params,
            ObjectModerationObjectType
          )}
        </CardContent>
      </Card>
    </>
  );
}
