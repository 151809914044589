import { Alert, AlertProps } from '@mui/material';

export default function ErrorAlert({
  children,
  ...rest
}: AlertProps): JSX.Element {
  return (
    <Alert severity="error" variant="outlined" {...rest}>
      {children}
    </Alert>
  );
}
