import { GameMakerAssetCollection } from 'src/lib/types/gameMakerAsset';
import AssetCollectionForm from '../../components/Forms/AssetCollectionForm';
import useUpdateAssetCollectionView from './useView';

interface UpdateGameViewProps {
  assetCollection: GameMakerAssetCollection;
}

export default function UpdateAssetCollectionView({
  assetCollection,
}: UpdateGameViewProps): JSX.Element {
  const { handleUpdateAssetCollection } = useUpdateAssetCollectionView(
    assetCollection.uuid
  );

  return (
    <AssetCollectionForm
      defaultValues={{
        uuid: assetCollection.uuid,
        name: assetCollection.name,
        description: assetCollection.description,
      }}
      onSubmit={handleUpdateAssetCollection}
      title="Update Asset Collection"
    />
  );
}
