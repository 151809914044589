import { AppPlatforms } from 'src/lib/types/collection';
import ConfigurationForm from '../../components/Form';
import useCreateConfiguration from './useView';

export default function CreateConfiguration(): JSX.Element {
  const { handleCreateConfiguration } = useCreateConfiguration();
  return (
    <ConfigurationForm
      defaultValues={{
        name: '',
        platform: AppPlatforms.ios,
        value: '',
        description: '',
      }}
      onSubmit={handleCreateConfiguration}
      title="Create a Configuration"
    />
  );
}
