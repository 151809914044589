import useNotify from 'src/lib/hooks/useNotify';
import useModalStore from 'src/lib/stores/useModalStore';
import { CoinEmitter } from '../../events/CoinEmmiter';
import { ExpireCoins } from '../../services/expireCoins';

export default function useExpireCoins() {
  const closeModal = useModalStore((state) => state.closeModal);
  const notify = useNotify();

  const handleExpireCoins = async (uuid: string) => {
    const { data, error } = await ExpireCoins(uuid);
    if (error) {
      notify(error, { variant: 'error' });
      closeModal();
    } else {
      notify('Coins Expired!', { variant: 'success' });
      closeModal();
      CoinEmitter.emit('CoinExpired', data);
    }
  };

  return {
    handleExpireCoins,
  };
}
