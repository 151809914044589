import PlayerStats from 'src/components/modules/Games/view/ListPlayerStats';
import Page from 'src/template/components/Page';

export default function PlayerAnalyticsPage() {
  return (
    <Page title="Player Analytics">
      <PlayerStats />
    </Page>
  );
}
