import getAPIClient from 'src/lib/services/api/axios.config';
import { GameTag } from 'src/lib/types/gameTag';
import { ServiceResponse } from 'src/lib/types/ServiceResponse';
import { getMessageFromError } from 'src/lib/utils/error';

export type CreateGameWithVersionData = {
  uuid: string;
};

export async function CreateGameTag(
  data: Partial<GameTag>
): Promise<ServiceResponse<GameTag>> {
  try {
    const api = await getAPIClient();
    const res = await api.post(`admin/game-tag/`, data);
    return {
      data: res.data,
      error: null,
    };
  } catch (e) {
    return {
      data: null,
      error: getMessageFromError(e),
    };
  }
}
