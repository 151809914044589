import { yupResolver } from '@hookform/resolvers/yup';
import { Close } from '@mui/icons-material';
import {
  Alert,
  Autocomplete,
  Avatar,
  Card,
  CardContent,
  CardHeader,
  Stack,
  TextField,
  Typography,
} from '@mui/material';
import { useState } from 'react';
import { Controller, useFieldArray, useForm } from 'react-hook-form';
import SelectNFTSForPacks from 'src/components/modules/NFT/view/SelectList';
import Button from 'src/components/shared/Buttons/CustomButton';
import Flex from 'src/components/shared/Flex';
import Modal from 'src/components/shared/Modal';
import useModalStore from 'src/lib/stores/useModalStore';
import { NFTForPack } from 'src/lib/types/nft';
import { Rarity } from 'src/lib/types/nftPack';
import { getImageURL } from 'src/lib/utils/nft';
import * as yup from 'yup';
import { AddNftOnPackFormValues } from './types';

const validationSchema = yup.object().shape({
  nfts: yup
    .array()
    .of(
      yup.object().shape({
        uuid: yup.string().required('Nft is required'),
        rarityUuid: yup.string().required('Rarity is required'),
        faceValue: yup
          .number()
          .min(1)
          .integer('Face value should be a integer')
          .required('Face value is required'),
      })
    )
    .required(),
});

type Props = {
  onSubmit: (values: AddNftOnPackFormValues) => Promise<void>;
  raritiesOptions: Rarity[];
  title: string;
};

export default function AddNftOnPackForm({
  onSubmit,
  raritiesOptions,
  title,
}: Props): JSX.Element {
  const onClose = useModalStore((state) => state.closeModal);
  const {
    control,
    handleSubmit,
    formState: { isSubmitting },
    getFieldState,
  } = useForm<AddNftOnPackFormValues>({
    resolver: yupResolver(validationSchema),
  });

  const handleCloseModal = () => {
    onClose();
  };

  const {
    fields: selectedNfts,
    remove: removeNft,
    append: appendNft,
  } = useFieldArray({
    control,
    name: 'nfts',
  });

  const handleAddNft = (nft: NFTForPack) => {
    appendNft({
      uuid: nft.uuid!,

      name: nft.name!,
      fileUrl: nft.fileUrl!,
      collectionName: nft.collectionName,

      rarityUuid: '',
      faceValue: 0,
    });
  };

  const handleRemoveNft = (uuid: string) => {
    const index = selectedNfts.findIndex((nft) => nft.uuid === uuid);
    if (index > -1) removeNft(index);
  };

  const handleRemoveNftByIndex = (index: number) => {
    if (selectedNfts.length > 1) removeNft(index);
  };

  const [currentStep, setCurrentStep] = useState(1); // 1: table, 2: select rarities

  const handleNextStep = () => {
    setCurrentStep(currentStep + 1);
  };

  const handlePreviousStep = () => {
    setCurrentStep(currentStep - 1);
  };

  return (
    <Modal open onClose={handleCloseModal}>
      <Card
        sx={{
          ...(currentStep === 1
            ? {
                width: '80vw',
                minHeight: '50vh',
              }
            : {}),
          display: 'flex',
          flexDirection: 'column',
        }}
      >
        <CardHeader title={title} />
        <CardContent
          sx={{
            justifySelf: 'flex-end',
          }}
        >
          <form onSubmit={handleSubmit(onSubmit)}>
            {currentStep === 1 ? (
              <SelectNFTSForPacks
                handleAddNft={handleAddNft}
                handleRemoveNft={handleRemoveNft}
                selectedNfts={selectedNfts}
              />
            ) : (
              <Stack mb={3} spacing={1.5}>
                {selectedNfts.map((nft, nftIndex) => (
                  <Flex
                    key={nft.id}
                    gap={6}
                    alignItems={'flex-start'}
                    justifyContent={'space-between'}
                  >
                    <Stack direction={'row'}>
                      <Avatar
                        alt={nft.name}
                        src={getImageURL(nft.fileUrl)}
                        variant="rounded"
                        sx={{ mr: 2 }}
                      />

                      <Stack>
                        <Typography variant="body1" noWrap>
                          {nft.name}
                        </Typography>
                        <Typography variant="subtitle2" color="grey" noWrap>
                          ({nft.collectionName})
                        </Typography>
                      </Stack>
                    </Stack>

                    <Stack direction={'row'} gap={2}>
                      <Controller
                        control={control}
                        name={`nfts.${nftIndex}.rarityUuid`}
                        render={({ field }) => (
                          <Autocomplete
                            sx={{
                              width: '200px',
                            }}
                            options={raritiesOptions?.map((r) => r.uuid) ?? []}
                            getOptionLabel={(option) =>
                              raritiesOptions?.find((r) => r.uuid === option)
                                ?.name ?? ''
                            }
                            defaultValue={field.value}
                            onChange={(e, value) => field.onChange(value)}
                            renderInput={(params) => (
                              <TextField
                                {...params}
                                {...field}
                                label="Rarity"
                                required
                                sx={{
                                  width: '200px',
                                }}
                              />
                            )}
                          />
                        )}
                      />

                      <Controller
                        control={control}
                        name={`nfts.${nftIndex}.faceValue`}
                        render={({ field }) => (
                          <TextField
                            {...field}
                            label="Face Value"
                            required
                            type="number"
                            sx={{
                              width: '200px',
                            }}
                          />
                        )}
                      />

                      <Button
                        onClick={() => handleRemoveNftByIndex(nftIndex)}
                        size="large"
                      >
                        <Close />
                      </Button>
                    </Stack>
                  </Flex>
                ))}

                {getFieldState(`nfts`).error?.message && (
                  <Alert severity="error">
                    {getFieldState(`nfts`).error?.message}
                  </Alert>
                )}
              </Stack>
            )}
            <Stack
              direction="row"
              justifyContent="space-between"
              spacing={1}
              mt={2}
            >
              {currentStep === 2 ? (
                <Button
                  variant="contained"
                  color="error"
                  type="button"
                  onClick={handlePreviousStep}
                  size="large"
                >
                  Previous
                </Button>
              ) : (
                <Button color="error" onClick={handleCloseModal} size="large">
                  Cancel
                </Button>
              )}
              {currentStep === 1 ? (
                <Button
                  variant="contained"
                  color="secondary"
                  type="button"
                  disabled={selectedNfts.length === 0}
                  onClick={handleNextStep}
                  size="large"
                >
                  Next
                </Button>
              ) : (
                <Button
                  variant="contained"
                  color="secondary"
                  type="submit"
                  loading={isSubmitting}
                  size="large"
                >
                  Save
                </Button>
              )}
            </Stack>
          </form>
        </CardContent>
      </Card>
    </Modal>
  );
}
