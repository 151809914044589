import { Card } from '@mui/material';
import { debounce } from 'lodash';
import Flex from 'src/components/shared/Flex';
import MagicTable from 'src/components/shared/MagicTable/MagicTable';
import useURLSearchParams from 'src/lib/hooks/useURLSearchParams';
import { FeedItemAnalyticsTableRow } from 'src/lib/types/FeedItem';
import { tableColumns } from './columns';
import { FeedItemTableSearchFilters } from './filters/SearchFilter';
import { FetchFeedItemAnalyticsQueryParams } from './useFeedItemAnalyticsTable';

export default function FeedItemAnalyticsTable({
  data,
  isLoading,
  totalCount,
  params,
}: FeedItemAnalyticsTableProps) {
  const { addParam, removeParam } = useURLSearchParams();
  function handleChangeField(value: string, field: string) {
    addParam(field, value);
  }
  const debouncedChangeField = debounce(handleChangeField, 400);
  return (
    <Card>
      <Flex gap={2} p={2} justifyContent={'space-between'}>
        <FeedItemTableSearchFilters
          removeParam={removeParam}
          propParams={params}
          addParam={addParam}
          debouncedChangeField={debouncedChangeField}
        />
      </Flex>
      <MagicTable.Container>
        <MagicTable
          loading={isLoading}
          pageSize={params.size}
          page={params.page}
          rowCount={totalCount}
          paginationMode="server"
          onPageChange={(newPage) => {
            addParam('page', newPage);
          }}
          onPageSizeChange={(newSize) => {
            addParam('size', newSize);
          }}
          onSortModelChange={(model) => {
            const currentModel = model[0];
            if (currentModel) {
              addParam('orderBy', currentModel.field);
              if (currentModel.sort) addParam('order', currentModel.sort);
            } else {
              removeParam('order');
              removeParam('orderBy');
            }
          }}
          rows={data}
          columns={tableColumns}
        />
      </MagicTable.Container>
    </Card>
  );
}

type FeedItemAnalyticsTableProps = {
  data: FeedItemAnalyticsTableRow[];
  isLoading: boolean;
  totalCount: number;
  params: FetchFeedItemAnalyticsQueryParams;
};
