import useURLSearchParams from 'src/lib/hooks/useURLSearchParams';
import useModalStore from 'src/lib/stores/useModalStore';
import { IncludeExcludeAll } from 'src/lib/utils/includeExcludeFilterUtils';
import BalanceRankFilter from '../../../components/Rankings/BalanceRankTable/Filter';
import { Params } from '../../../components/Rankings/BalanceRankTable/types';
import {
  INITIAL_FILTERS,
  INITIAL_SEARCH_PARAMS_STATE,
} from '../../../constants/BalanceRanking';
import {
  RankBy,
  fetchBalanceRank,
} from '../../../services/fetchBalanceRanking';
import { UserType } from '../../../types/UserType';
import { valueIfDifferentOf } from '../../../utils/valueIfDifferentOf';

export default function useTopBalancesView() {
  const openModal = useModalStore((state) => state.openModal);
  const { allSearchParams } = useURLSearchParams<Params>(
    INITIAL_SEARCH_PARAMS_STATE
  );
  const { data, isLoading } = fetchBalanceRank({
    page: allSearchParams.page,
    size: allSearchParams.size,
    rankBy: allSearchParams.rankBy as RankBy,
    order: allSearchParams.order,
    userType: valueIfDifferentOf(allSearchParams.userType as UserType, 'all'),
    filter: {
      country: allSearchParams.country,
      lang: allSearchParams.lang,
      InactiveUsers: allSearchParams.InactiveUsers as IncludeExcludeAll,
    },
  });

  function handleOpenFilter() {
    openModal(
      <BalanceRankFilter
        initialValues={{
          country: allSearchParams.country ?? INITIAL_FILTERS.country,
          lang: allSearchParams.lang ?? INITIAL_FILTERS.lang,
          rankBy: (allSearchParams.rankBy as RankBy) ?? INITIAL_FILTERS.rankBy,
          InactiveUsers: allSearchParams.InactiveUsers as IncludeExcludeAll,
          userType:
            (allSearchParams.userType as UserType) ?? INITIAL_FILTERS.userType,
        }}
      />
    );
  }
  return {
    data,
    isLoading,
    handleOpenFilter,
    currentPage: Number(
      allSearchParams.page ?? INITIAL_SEARCH_PARAMS_STATE.page
    ),
    currentSize: Number(
      allSearchParams.size ?? INITIAL_SEARCH_PARAMS_STATE.size
    ),
    currentRankBy: allSearchParams.rankBy ?? INITIAL_FILTERS.rankBy,
    currentOrder: allSearchParams.order ?? INITIAL_SEARCH_PARAMS_STATE.order,
  };
}
