import { DeepPartial } from 'react-hook-form';
import { parseSearchParams } from 'src/lib/utils/parseSearchParams';
import { parseTimestamp } from 'src/lib/utils/parseTimestamp';
import useSWR, { SWRResponse } from 'swr';
import { Communication, CommunicationModel } from '../types/Communication';

type PossibleParams = {
  page: number | string;
  size: number | string;
};

function parseData(data: CommunicationModel[] | undefined): Communication[] {
  if (!data) return [];
  return data.map((d) => parseTimestamp(d));
}
export function fetchCommunications(
  params?: DeepPartial<PossibleParams>
): SWRResponse<Communication[]> {
  const parsedParams = parseSearchParams(params ?? {});
  const { data, ...response } = useSWR(
    `admin/communications/list?${parsedParams}`
  );
  return {
    ...response,
    data: parseData(data?.data),
  };
}
