import { Box, Card, Typography, styled } from '@mui/material';
import { useSnackbar } from 'notistack';
import EditableImage from 'src/components/shared/EditableImage';
import { updateUser } from 'src/lib/services/users';

import { User } from 'src/lib/types/users';
import { uploadToStorage } from 'src/lib/utils/upload';
import ProfileDropdownNavigation from './ProfileDropdownNavigation';

interface UserDetailsHeaderProps {
  user: User;
  onChangeTab: (
    event: React.SyntheticEvent<Element, Event> | null,
    newValue: string
  ) => void;
  currentTab: string | undefined;
}

const InfoStyle = styled('div')(({ theme }) => ({
  left: 0,
  right: 0,
  zIndex: 99,
  position: 'absolute',
  marginTop: theme.spacing(5),
  [theme.breakpoints.down('md')]: {
    right: 'auto',
    display: 'flex',
    alignItems: 'center',
    left: theme.spacing(3),
    bottom: theme.spacing(3),
  },
  [theme.breakpoints.up('md')]: {
    right: 'auto',
    display: 'flex',
    alignItems: 'center',
    left: theme.spacing(3),
    bottom: '50%',
    transform: 'translateY(50%)',
  },
}));

export default function ProfileHeader({
  user,
  onChangeTab,
  currentTab,
}: UserDetailsHeaderProps): JSX.Element {
  const { enqueueSnackbar } = useSnackbar();

  const onUpdateAvatar = async (file: any) => {
    const url = await uploadToStorage(user.uuid, file);

    const res = await updateUser(user.uuid, {
      profileImage: url as string,
    });

    if (res.data) {
      enqueueSnackbar('Profile image updated', { variant: 'success' });
    } else if (res.error) {
      enqueueSnackbar(
        `Error updating profile image: ${res.error} ${res.error}`,
        { variant: 'error' }
      );
    }
  };

  const onUpdateCover = async (file: any) => {
    const url = await uploadToStorage(user.uuid, file);

    const res = await updateUser(user.uuid, {
      coverImage: url as string,
    });

    if (res.data) {
      enqueueSnackbar('Profile image updated', { variant: 'success' });
    } else if (res.error) {
      enqueueSnackbar(
        `Error updating profile image: ${res.error} ${res.error}`,
        { variant: 'error' }
      );
    }
  };

  return (
    <Card
      sx={{
        mb: 3,
        height: 280,
        position: 'relative',
      }}
    >
      <EditableImage
        image={user?.coverImage ?? ''}
        onEdit={onUpdateCover}
        imageProps={{
          alt: `${user.name} profile cover image`,
          placeholderSrc: '/static/images/avatars/avatar_11.png',
        }}
        sx={{
          mx: 'auto',
          height: '100%',
          backgroundColor: 'primary.main',
        }}
      />
      <InfoStyle>
        <EditableImage
          image={user?.profileImage ?? ''}
          onEdit={onUpdateAvatar}
          imageProps={{
            alt: `${user.name} profile image`,
            ratio: `1/1`,
          }}
          sx={{
            mx: 'auto',
            borderWidth: 2,
            borderStyle: 'solid',
            borderColor: 'common.white',
            width: { xs: 80, md: 128 },
            height: { xs: 80, md: 128 },
          }}
        />
        <Box
          sx={{
            ml: { xs: 1, md: 3 },
            mt: { xs: 0, md: 0 },
            color: 'common.white',
            textAlign: { md: 'left' },
          }}
        >
          <Typography
            variant="h4"
            sx={{
              textShadow: '0 2px 5px #000000AA',
            }}
          >
            {user?.name}
          </Typography>
          <Typography sx={{ opacity: 0.72, textShadow: '0 2px 5px #000000AA' }}>
            {user?.slug}
          </Typography>
        </Box>
      </InfoStyle>
      <ProfileDropdownNavigation
        currentTab={currentTab}
        onChangeTab={onChangeTab}
      />
    </Card>
  );
}
