import { WithTimestamp } from 'src/lib/types/WithTimestamp';
import { TransactionsPoolType } from 'src/lib/types/transaction';

export type RevenueEvent = {
  uuid: string;
  appleNotificationUuid?: string | null;
  purchaseEventUuid?: string | null;
  userUuid?: string;
  webOrderLineItemId: string;
  identifier?: string | null;
  productUuid: string;
  eventType: RevenueEventType;
  eventDate: number;
  transactionPoolUuid?: string | null;
  environment: string;
  subscriptionStartDate?: number;
  subscriptionExpiresDate?: number;
  originalPurchaseDate: number;
  isFreeTrial: boolean;
  createdAt: number;
  updatedAt: number;
  originalTransactionId: string;
};

export type RevenueEventPool = WithTimestamp<{
  uuid: string;
  revenueEventUuid: string;
  transactionPoolUuid: string;
}>;

export type DeliveryPool = WithTimestamp<{
  uuid: string;
  status: string;
  transactionPoolType: Pick<TransactionsPoolType, 'name'>;
}>;

export type WithDelivered<T> = T & {
  delivered: boolean;
};

export type WithRevenueEventPools<T> = T & {
  revenueEventPools: RevenueEventPool[];
};

export type WithDeliveryPools<T> = T & {
  deliveryPools: DeliveryPool[];
};

export enum RevenueEventType {
  BUY = 'Buy',
  RENEW = 'Renew',
  CANCEL = 'Cancel',
  REFUND = 'Refund',
  REFUND_REQUEST = 'RefundRequest',
  REFUND_DENIED = 'RefundDenied',
  UPGRADE = 'Upgrade',
  DOWNGRADE = 'Downgrade',
  DOWNGRADERETURN = 'DowngradeReturn',
  BILLING_ISSUE = 'BillingIssue',
  CHANGE_STATUS_ENABLED = 'ChangeStatusEnabled',
  CHANGE_STATUS_DISABLED = 'ChangeStatusDisabled',
}
