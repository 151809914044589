import { Card, CardContent, CardHeader, Stack } from '@mui/material';
import { Formik } from 'formik';
import { useSnackbar } from 'notistack';
import Button from 'src/components/shared/Buttons/CustomButton';
import FormikField from 'src/components/shared/Inputs/FormikField';
import Modal from 'src/components/shared/Modal';
import { createRegion, updateRegion } from 'src/lib/services/regions';
import { LtvRegion } from 'src/lib/types/ltvRegion';
import removeEmptyFieldsFromObject from 'src/lib/utils/removeEmptyFieldsFromObject';
import * as yup from 'yup';

export type RegionFormValues = {
  code: string;
  name: string;
  iosProductFullId: string;
  webProductFullId: string;
  androidProductFullId: string;
  iosStickyValue: number;
  webStickyValue: number;
  androidStickyValue: number;
};

const validationSchema = yup.object().shape({
  code: yup.string().required('Code is required'),
  name: yup.string().required('Name is required'),
  iosProductFullId: yup.string().required('iOS Product Full ID is required'),
  webProductFullId: yup.string().required('Web Product Full ID is required'),
  androidProductFullId: yup
    .string()
    .required('Android Product Full ID is required'),
  iosStickyValue: yup.number().required('iOS Sticky Value is required'),
  webStickyValue: yup.number().required('Web Sticky Value is required'),
  androidStickyValue: yup.number().required('Android Sticky Value is required'),
});

type EditOrCreateRegionModalProps = {
  open: boolean;
  onClose: () => void;
  region?: LtvRegion;
};

export default function EditOrCreateRegionModal({
  open,
  onClose,
  region,
}: EditOrCreateRegionModalProps) {
  const { enqueueSnackbar: notify } = useSnackbar();

  const onSave = async (values: Partial<LtvRegion>) => {
    if (region) {
      const res = await updateRegion({
        ...region,
        ...values,
      });

      if (res.data) {
        notify('Region updated successfully', { variant: 'success' });
        onClose();
      } else if (res.error) {
        notify(res.error, { variant: 'error' });
      }
    } else {
      const res = await createRegion(removeEmptyFieldsFromObject(values));

      if (res.data) {
        notify('Region created successfully', { variant: 'success' });
        onClose();
      } else if (res.error) {
        notify(res.error, { variant: 'error' });
      }
    }
  };

  return (
    <Modal open={open} onClose={onClose}>
      <Card sx={{ width: '400px' }}>
        <CardHeader title={region ? 'Edit Region' : 'Create New Region'} />
        <CardContent>
          <Stack spacing={2} justifyContent="center">
            <Formik
              initialValues={{
                code: region?.code || '',
                name: region?.name || '',
                iosProductFullId: region?.iosProductFullId || '',
                webProductFullId: region?.webProductFullId || '',
                androidProductFullId: region?.androidProductFullId || '',
                iosStickyValue: region?.iosStickyValue || 0,
                webStickyValue: region?.webStickyValue || 0,
                androidStickyValue: region?.androidStickyValue || 0,
              }}
              validationSchema={validationSchema}
              onSubmit={onSave}
              validateOnChange={false}
            >
              {({ handleSubmit, isSubmitting }) => (
                <>
                  <FormikField
                    size="small"
                    label="Code"
                    variant="outlined"
                    name="code"
                  />
                  <FormikField
                    size="small"
                    label="Name"
                    variant="outlined"
                    name="name"
                  />
                  <FormikField
                    size="small"
                    label="iOS Product"
                    variant="outlined"
                    name="iosProductFullId"
                  />
                  <FormikField
                    size="small"
                    label="Web Product"
                    variant="outlined"
                    name="webProductFullId"
                  />
                  <FormikField
                    size="small"
                    label="Android Product"
                    variant="outlined"
                    name="androidProductFullId"
                  />
                  <Stack direction="row" spacing={2}>
                    <FormikField
                      size="small"
                      label="iOS Value"
                      variant="outlined"
                      name="iosStickyValue"
                      type="number"
                    />
                    <FormikField
                      size="small"
                      label="Web Value"
                      variant="outlined"
                      name="webStickyValue"
                      type="number"
                    />
                    <FormikField
                      size="small"
                      label="Android Value"
                      variant="outlined"
                      name="androidStickyValue"
                      type="number"
                    />
                  </Stack>

                  <Stack
                    direction="row"
                    spacing={2}
                    justifyContent="space-between"
                  >
                    <Button color="error" onClick={onClose}>
                      Cancel
                    </Button>
                    <Button
                      loading={isSubmitting}
                      color="secondary"
                      variant="contained"
                      onClick={() => handleSubmit()}
                    >
                      Save
                    </Button>
                  </Stack>
                </>
              )}
            </Formik>
          </Stack>
        </CardContent>
      </Card>
    </Modal>
  );
}
