import { Close } from '@mui/icons-material';
import {
  Alert,
  Avatar,
  Box,
  Card,
  CardContent,
  CardHeader,
  FormControlLabel,
  FormLabel,
  Radio,
  RadioGroup,
  Stack,
  Typography,
} from '@mui/material';
import { capitalCase } from 'change-case';
import SelectCollections from 'src/components/modules/Collections/Collection/view/SelectList';
import SelectGames from 'src/components/modules/Games/view/SelectList';
import Button from 'src/components/shared/Buttons/CustomButton';
import EditableImage from 'src/components/shared/EditableImage';
import Flex from 'src/components/shared/Flex';
import { ObjectLinkComponent } from 'src/components/shared/Form/useObjectLinkSearch';
import ControllerField from 'src/components/shared/Inputs/ControllerField';
import Modal from 'src/components/shared/Modal';
import SelectOption from 'src/components/shared/SelectOption';
import { ClubJoinMethods } from 'src/lib/types/club';
import { User } from 'src/lib/types/users';
import objectEntries from 'src/lib/utils/objectEntries';
import { ClubFormValues } from './types';
import useClubFormView from './useView';

type Props = {
  defaultValues: ClubFormValues;
  defaultUser?: User;
  onSubmit: (values: ClubFormValues) => Promise<void>;
  title: string;
};

export default function ClubForm({
  defaultValues,
  defaultUser,
  title,
  onSubmit,
}: Props): JSX.Element {
  const {
    form: {
      control,
      handleSubmit,
      formState: { isSubmitting },
      setValue,
      getFieldState,
    },
    handleCloseModal,
    lastStep,
    currentStep,
    handleNextStep,
    handlePreviousStep,
    isCollectionSelectionStep,
    isCollectionVerificationStep,
    isGameSelectionStep,
    isGameVerificationStep,
    selectedCollections,
    handleAddCollection,
    handleRemoveCollection,
    handleRemoveCollectionByIndex,
    selectedGames,
    handleAddGame,
    handleRemoveGame,
    handleRemoveGameByIndex,
    onUpdateImage,
    coverFileUrl,
    photoFileUrl,
    UserSearchComponent,
    userSearchProps,
    hasGame,
    objectLinkProps,
  } = useClubFormView({ defaultValues, defaultUser });

  return (
    <Modal open onClose={handleCloseModal}>
      <Card
        sx={{
          ...(currentStep % 2 === 0
            ? {
                width: '80vw',
                minHeight: '50vh',
              }
            : {
                width: '700px',
              }),
          display: 'flex',
          flexDirection: 'column',
        }}
      >
        <CardHeader title={title} />
        <CardContent>
          <form onSubmit={handleSubmit(onSubmit)}>
            <Stack>
              {currentStep === 1 && (
                <Stack mb={2} spacing={1.5}>
                  <ControllerField
                    control={control}
                    name="name"
                    label="Name"
                    fullWidth
                  />
                  <ControllerField
                    control={control}
                    name="description"
                    label="Description"
                    multiline
                    minRows={3}
                    fullWidth
                  />
                  <ControllerField
                    control={control}
                    name="landingPageUrl"
                    label="Landing Page URL"
                    fullWidth
                  />

                  <UserSearchComponent {...userSearchProps} />
                  <Stack px={1} gap={2}>
                    {/* <Flex justifyContent={'space-between'}>
                      <Typography variant="subtitle1">
                        {`Upload a Photo (optional):`}
                      </Typography>
                      <EditableImage
                        image={photoFileUrl ?? ''}
                        onEdit={(file) => {
                          onUpdateImage(file, 'photoFileUrl');
                        }}
                        imageProps={{
                          alt: `${defaultValues.clubUuid} photo image`,
                        }}
                        showEditButton
                        sx={{
                          maxWidth: '300px',
                          maxHeight: '300px',
                        }}
                      />
                    </Flex> */}
                    <Flex justifyContent={'space-between'}>
                      <Typography variant="subtitle1">
                        {`Upload a Cover: (optional):`}
                      </Typography>
                      <EditableImage
                        image={coverFileUrl ?? ''}
                        onEdit={(file) => {
                          onUpdateImage(file, 'coverFileUrl');
                        }}
                        imageProps={{
                          alt: `${defaultValues.clubUuid} cover image`,
                        }}
                        showEditButton
                        sx={{
                          maxWidth: '300px',
                          maxHeight: '300px',
                        }}
                      />
                    </Flex>
                  </Stack>

                  <ControllerField
                    control={control}
                    name="joinMethod"
                    fullWidth
                    label="Join Method"
                    select
                    wrapperProps={{
                      sx: {
                        gridColumn: '1 / span 2',
                      },
                    }}
                  >
                    {objectEntries(ClubJoinMethods).map(([label, value]) => (
                      <SelectOption value={value} key={value}>
                        {capitalCase(label)}
                      </SelectOption>
                    ))}
                  </ControllerField>
                  <Box p={1}>
                    <FormLabel id="demo-row-radio-buttons-group-label">
                      Related to Game
                    </FormLabel>
                    <RadioGroup
                      row
                      aria-labelledby="row-radio-buttons-group-label"
                      name="row-radio-buttons-group"
                      value={hasGame ? 'true' : 'false'}
                      onChange={(e) => {
                        if (e.target.value === 'true') {
                          setValue('hasGame', true);
                        } else {
                          setValue('hasGame', false);
                        }
                      }}
                    >
                      <FormControlLabel
                        value="true"
                        control={<Radio />}
                        label="Yes"
                      />
                      <FormControlLabel
                        value="false"
                        control={<Radio />}
                        label="No"
                      />
                    </RadioGroup>
                  </Box>
                  <ObjectLinkComponent {...objectLinkProps} />
                </Stack>
              )}
              {isCollectionSelectionStep && (
                <>
                  <Typography variant="subtitle1">
                    Select collections that are related to this club
                  </Typography>
                  <SelectCollections
                    handleAddCollection={handleAddCollection}
                    handleRemoveCollection={handleRemoveCollection}
                    selectedCollections={selectedCollections}
                  />
                </>
              )}
              {isCollectionVerificationStep && (
                <Stack mb={3} spacing={1.5}>
                  {selectedCollections.map((collection, collectionIndex) => (
                    <Flex
                      key={collection.id}
                      gap={6}
                      alignItems={'flex-start'}
                      justifyContent={'space-between'}
                    >
                      <Stack direction={'row'}>
                        <Avatar
                          alt={collection.name}
                          src={collection.coverFileUrl}
                          variant="rounded"
                          sx={{ mr: 2 }}
                        />
                        <Stack>
                          <Typography variant="body1" noWrap>
                            {collection.name}
                          </Typography>
                          <Typography variant="subtitle2" color="grey" noWrap>
                            ({collection.creatorName})
                          </Typography>
                        </Stack>
                      </Stack>

                      <Stack direction={'row'}>
                        <Button
                          onClick={() =>
                            handleRemoveCollectionByIndex(collectionIndex)
                          }
                          size="large"
                        >
                          <Close />
                        </Button>
                      </Stack>
                    </Flex>
                  ))}

                  {getFieldState(`collections`).error?.message && (
                    <Alert severity="error">
                      {getFieldState(`collections`).error?.message}
                    </Alert>
                  )}
                </Stack>
              )}
              {isGameSelectionStep && (
                <>
                  <Typography variant="subtitle1">
                    Select games that are related to this club
                  </Typography>
                  <SelectGames
                    handleAddGame={handleAddGame}
                    handleRemoveGame={handleRemoveGame}
                    selectedGames={selectedGames}
                  />
                </>
              )}
              {isGameVerificationStep && (
                <Stack mb={3} spacing={1.5}>
                  {selectedGames.map((game, gameIndex) => (
                    <Flex
                      key={game.id}
                      gap={6}
                      alignItems={'flex-start'}
                      justifyContent={'space-between'}
                    >
                      <Stack direction={'row'}>
                        <Stack>
                          <Typography variant="body1" noWrap>
                            {gameIndex + 1} - {game.name}
                          </Typography>
                          <Typography variant="subtitle2" color="grey" noWrap>
                            ({game.name})
                          </Typography>
                        </Stack>
                      </Stack>

                      <Stack direction={'row'}>
                        <Button
                          onClick={() => handleRemoveGameByIndex(gameIndex)}
                          size="large"
                        >
                          <Close />
                        </Button>
                      </Stack>
                    </Flex>
                  ))}

                  {getFieldState(`games`).error?.message && (
                    <Alert severity="error">
                      {getFieldState(`games`).error?.message}
                    </Alert>
                  )}
                </Stack>
              )}
              <Stack
                direction="row"
                justifyContent="space-between"
                spacing={1}
                mt={2}
              >
                {currentStep === 1 ? (
                  <Button color="error" onClick={handleCloseModal} size="large">
                    Cancel
                  </Button>
                ) : (
                  <Button
                    variant="contained"
                    color="error"
                    type="button"
                    onClick={handlePreviousStep}
                    size="large"
                  >
                    Previous
                  </Button>
                )}
                {currentStep === lastStep ? (
                  <Button
                    variant="contained"
                    color="secondary"
                    type="submit"
                    loading={isSubmitting}
                    size="large"
                  >
                    Save
                  </Button>
                ) : (
                  <Button
                    variant="contained"
                    color="secondary"
                    type="button"
                    onClick={(e) => {
                      e.preventDefault();

                      handleNextStep();
                    }}
                    size="large"
                  >
                    Next
                  </Button>
                )}
              </Stack>
            </Stack>
          </form>
        </CardContent>
      </Card>
    </Modal>
  );
}
