import { Container } from '@mui/material';
import NftsSearch from 'src/components/modules/Nfts/NftsSearch';
import Page from 'src/template/components/Page';

export default function NftsPage() {
  return (
    <Page title="Nfts">
      <Container maxWidth="xl">
        <NftsSearch />
      </Container>
    </Page>
  );
}
