import { RemoveRedEye } from '@mui/icons-material';
import {
  Alert,
  Badge,
  Card,
  CardContent,
  CardHeader,
  Divider,
  IconButton,
  Stack,
  Typography,
} from '@mui/material';
import { GridColumns } from '@mui/x-data-grid';
import { ReactNode, useMemo } from 'react';
import Flex from 'src/components/shared/Flex';
import Link from 'src/components/shared/Link';
import MagicTable from 'src/components/shared/MagicTable/MagicTable';
import useMagicTable from 'src/components/shared/MagicTable/useMagicTable';
import { formatUTCDate } from 'src/lib/utils/formatUTCDate';
import getUserName from 'src/lib/utils/getUserName';
import Routes from 'src/routes/Routes';
import Label from 'src/template/components/Label';
import LoadingScreen from 'src/template/components/LoadingScreen';
import { SubscriptionDetailsResponse } from '../../../services/fetchConsumable';
import useConsumableDetailsView from './useView';

export default function ConsumableDetailsView(): JSX.Element {
  const magicTableProps = useMagicTable();
  const { data, showRelatedPools, isLoading, error } =
    useConsumableDetailsView();

  const columns: GridColumns<SubscriptionDetailsResponse['body'][number]> =
    useMemo(
      () => [
        {
          field: 'productId',
          headerName: 'Product',
          sortable: false,
          flex: 2,
          renderCell({ row }) {
            if (row.product.name)
              return (
                <Stack>
                  <Typography>{row.product.name}</Typography>
                  <Typography fontSize="12px" color="GrayText">
                    {row.product.productId}
                  </Typography>
                </Stack>
              );
            return row.product.productId;
          },
        },
        {
          field: 'eventType',
          headerName: 'Event Type',
        },
        {
          field: 'tags',
          headerName: 'Tags',
          sortable: false,
          renderCell({ row }) {
            const tags: ReactNode[] = [];
            if (row.environment === 'Sandbox') {
              tags.push(
                <Label color={'warning'} key="warning">
                  Sandbox
                </Label>
              );
            }
            if (row.isFreeTrial) {
              tags.push(
                <Label color="info" key="info">
                  Free Trial
                </Label>
              );
            }

            return <Flex gap={1}>{tags.map((t) => t)}</Flex>;
          },
        },
        {
          field: 'eventDate',
          headerName: 'Event Date',
          renderCell({ row }) {
            return formatUTCDate(row.eventDate, { second: '2-digit' });
          },
        },
        {
          field: 'relatedPools',
          headerName: 'Related Pools',
          sortable: false,
          flex: 1 / 1.7,
          align: 'center',
          renderCell({ row }) {
            const pools: string[] = [];
            if (row.transactionPoolUuid) pools.push(row.transactionPoolUuid);
            row.revenueEventPools.forEach((pool) => {
              pools.push(pool.transactionPoolUuid);
            });

            return (
              <Badge
                badgeContent={pools.length}
                overlap="circular"
                color="info"
              >
                <IconButton
                  onClick={() => {
                    showRelatedPools(row.uuid);
                  }}
                >
                  <RemoveRedEye />
                </IconButton>
              </Badge>
            );
          },
        },
      ],
      []
    );

  if (isLoading || !data) {
    return <LoadingScreen />;
  }
  if (error) {
    return <Alert severity="error">{error}</Alert>;
  }

  const product = data.body.find((item) => item.product)?.product;
  const user = data.body.find((item) => item.user)?.user;
  const purchaseDate = data.body[0].originalPurchaseDate;

  return (
    <Stack spacing={3}>
      <Card sx={{ width: '50%' }}>
        <CardHeader title="Purchase Details" />
        <CardContent>
          <Stack divider={<Divider />} spacing={1}>
            {user && (
              <LabelValue
                label="User"
                value={
                  <Flex gap={1}>
                    <Link to={Routes.user(user.uuid)}>{getUserName(user)}</Link>
                    <Label color={user.active ? 'success' : 'error'}>
                      {user.active ? 'Active' : 'Inactive'}
                    </Label>
                  </Flex>
                }
              />
            )}
            <LabelValue label="Product" value={product?.name} />
            <LabelValue
              label="Purchase Date"
              value={formatUTCDate(purchaseDate)}
            />
          </Stack>
        </CardContent>
      </Card>

      <MagicTable.Container>
        <MagicTable
          {...magicTableProps}
          columns={columns}
          rows={data?.body ?? []}
          initialState={{
            sorting: {
              sortModel: [{ sort: 'desc', field: 'eventDate' }],
            },
          }}
        />
      </MagicTable.Container>
    </Stack>
  );
}

type LabelValueProps = {
  label: ReactNode;
  value: ReactNode;
};
function LabelValue({ label, value }: LabelValueProps): JSX.Element {
  return (
    <Flex justifyContent="space-between">
      <Typography>{label}</Typography>
      <Typography>{value}</Typography>
    </Flex>
  );
}
