import { Add } from '@mui/icons-material';
import { useState } from 'react';
import Button from 'src/components/shared/Buttons/CustomButton';
import useNotify from 'src/lib/hooks/useNotify';
import {
  createHomeFeed,
  deleteHomeFeed,
  updateHomeFeed,
} from 'src/lib/services/homeFeeds';
import { HomeFeed } from 'src/lib/types/homeFeed';
import HeaderBreadcrumbs from 'src/template/components/HeaderBreadcrumbs';
import { DeleteFeedModal, EditFeedModal, HomeFeedsTable } from './components';

export default function HomeFeeds(): JSX.Element {
  const [queryParams, setQueryParams] = useState<string | null>(null);

  const [action, setAction] = useState<string | null>(null);
  const [selectedFeed, setSelectedFeed] = useState<HomeFeed | null>(null);

  const notify = useNotify();

  const startAction = (
    newAction: 'delete' | 'edit' | null,
    newFeed: HomeFeed | null
  ) => {
    setSelectedFeed(newFeed);
    setAction(newAction);
  };

  const finishAction = () => {
    setSelectedFeed(null);
    setAction(null);
  };

  const handleDelete = async () => {
    if (!selectedFeed) return;
    const res = await deleteHomeFeed(selectedFeed.uuid, queryParams || '');
    notify(res.message, { variant: res.success ? 'success' : 'error' });
    finishAction();
  };

  const handleSave = async (feed: HomeFeed | null) => {
    if (!feed) return false;
    let res;
    if (feed.uuid) res = await updateHomeFeed(feed, queryParams || '');
    else res = await createHomeFeed(feed, queryParams || '');

    notify(res?.message, { variant: res?.success ? 'success' : 'error' });
    if (res?.success) finishAction();
    return res?.success;
  };

  const toggleStatus = async (feed: HomeFeed) => {
    const newStatus = feed.status === 'active' ? 'inactive' : 'active';
    return handleSave({ ...feed, status: newStatus });
  };

  return (
    <>
      <HeaderBreadcrumbs
        heading="Home Feeds"
        links={[{ name: 'iOS/Web' }]}
        action={
          <Button
            onClick={() => startAction('edit', null)}
            variant="contained"
            color="primary"
            sx={{ gap: 1 }}
          >
            <Add />
            New
          </Button>
        }
      />
      <HomeFeedsTable
        onChangeQueryParams={(newQueryParams) => setQueryParams(newQueryParams)}
        onDelete={(feed) => startAction('delete', feed)}
        onEdit={(feed) => startAction('edit', feed)}
        toggleFeedStatus={(feed) => toggleStatus(feed)}
      />
      <DeleteFeedModal
        open={action === 'delete'}
        feedToDelete={selectedFeed}
        onDelete={handleDelete}
        onCancel={finishAction}
      />
      <EditFeedModal
        open={action === 'edit'}
        feedToEdit={selectedFeed}
        onSave={handleSave}
        onCancel={finishAction}
      />
    </>
  );
}
