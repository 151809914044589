import { Box } from '@mui/material';
import FlaggedTransactionsRankingTableView from '../../../components/Rankings/FlaggedTransactionsRankingTable';
import useFlaggedTransactionsView from './useView';

export default function TopFlaggedTransactionsView(): JSX.Element {
  const { data, isLoading, handleOpenFilter, currentPage, currentSize } =
    useFlaggedTransactionsView();
  return (
    <Box mx={2}>
      <FlaggedTransactionsRankingTableView
        data={data?.body ?? []}
        isLoading={isLoading}
        stats={data?.meta}
        handleOpenFilter={handleOpenFilter}
        page={currentPage}
        size={currentSize}
      />
    </Box>
  );
}
