import ConsumablesView from 'src/components/modules/Products/view/Consumables';
import Page from 'src/template/components/Page';

export default function ConsumablesPage(): JSX.Element {
  return (
    <Page title="Consumables">
      <ConsumablesView />
    </Page>
  );
}
