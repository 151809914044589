import { Device } from '../../../types/Device';
import UpdateDeviceFormView from './View';
import useUpdateDeviceFormView from './useView';

type Props = {
  device: Device;
};
export default function UpdateDeviceForm({ device }: Props): JSX.Element {
  const { handleSubmit } = useUpdateDeviceFormView();
  return (
    <UpdateDeviceFormView
      initialValues={{
        description: device.description ?? '',
      }}
      onSubmit={async (values) => {
        await handleSubmit(device.uuid, values);
      }}
    />
  );
}
