import { Avatar, Box, Button, Stack, Typography } from '@mui/material';
import Flex from 'src/components/shared/Flex';
import Link from 'src/components/shared/Link';
import {
  ChatMessage,
  ChatMessageData,
  ChatMessageDataObject,
} from 'src/lib/types/chat';
import getUserName from 'src/lib/utils/getUserName';
import Routes from 'src/routes/Routes';
import Image from 'src/template/components/Image';

interface MessageBubbleProps {
  message: ChatMessage;
}

export default function MessageBubble({ message }: MessageBubbleProps) {
  const { fromUser, timestamp } = message;
  const user = fromUser?.sticky_user;
  const userName = user ? getUserName(user) : 'empty';
  return (
    <Flex
      alignSelf={'flex-start'}
      px={4}
      bgcolor={'rgba(255, 255, 255, 0.08)'}
      color="white"
      fontSize="14px"
      lineHeight={1.6}
      fontWeight={500}
      borderRadius="12px"
      py={2.5}
      maxWidth={'80%'}
      flexDirection={'column'}
      position="relative"
      border="1px solid transparent"
    >
      <Flex alignItems={'center'} mb={1}>
        {user && (
          <Flex flexDirection={'column'} alignItems={'center'} mr={2}>
            <Avatar
              alt={user.name ?? 'user profile picture'}
              src={user.profileImage ?? ''}
            />
            <Link
              to={Routes.user(user.uuid)}
              color={userName !== 'empty' ? 'inherit' : 'GrayText'}
              underline="hover"
            >
              <Box
                maxWidth="80px"
                overflow="hidden"
                whiteSpace="nowrap"
                textOverflow="ellipsis"
              >
                {userName}
              </Box>
            </Link>
          </Flex>
        )}
        {!user && (
          <Flex flexDirection={'column'} alignItems={'center'} mr={2}>
            <Avatar
              alt={fromUser.displayname ?? 'user profile picture'}
              src={''}
            />
            <Box
              maxWidth="80px"
              overflow="hidden"
              whiteSpace="nowrap"
              textOverflow="ellipsis"
            >
              {fromUser.displayname}
            </Box>
          </Flex>
        )}

        {getComponentByMessage(message)}
      </Flex>

      <Typography
        fontSize="10px"
        fontWeight={500}
        color="rgba(255, 255, 255, 0.3)"
        position="absolute"
        bottom="10px"
        right="10px"
      >
        {new Date(timestamp).toLocaleTimeString([], {
          day: '2-digit',
          month: '2-digit',
          year: '2-digit',
          hour: '2-digit',
          minute: '2-digit',
        })}
      </Typography>
    </Flex>
  );
}

// Type

const getObjectLink = (uuid: string, objectType: string) => {
  switch (objectType) {
    case 'channel':
      return Routes.channel.channel(uuid);
    case 'user':
      return Routes.user(uuid);
    case 'game':
      return Routes.game_center.game.view(uuid);
    case 'video':
      return Routes.channel.content(uuid);
    default:
      return '';
  }
};
interface SharedObjectProps {
  content: string;
  data: ChatMessageDataObject;
}
function SharedObject({ data, content }: SharedObjectProps) {
  return (
    <Stack>
      <Image
        src={data.imageUrl}
        alt="chat object"
        width={100}
        borderRadius={4}
      />

      {data.title}
      <Link
        to={getObjectLink(data.objectUuid, data.objectType)}
        color="inherit"
        underline="hover"
      >
        <Button variant="outlined" color="inherit" fullWidth>
          View {data.objectType}
        </Button>
      </Link>

      <Typography whiteSpace="pre-wrap" mr={3}>
        {formatContent(content)}
      </Typography>
    </Stack>
  );
}

interface ImageObjectProps {
  src: string;
  content: string;
}

function ImageObject({ src, content }: ImageObjectProps) {
  return (
    <Stack>
      <Image src={src} alt="chat image" width={100} />

      <Typography whiteSpace="pre-wrap" mr={3}>
        {formatContent(content)}
      </Typography>
    </Stack>
  );
}

function getComponentByMessage(message: ChatMessage) {
  const data: ChatMessageData = JSON.parse(message.data || '{}');
  if (data) {
    const { type } = data;

    switch (type) {
      case 'image':
        return <ImageObject src={data?.url} content={message.content} />;
      case 'object':
        return <SharedObject content={message.content} data={data} />;
      default:
        break;
    }
  }

  return (
    <Typography whiteSpace="pre-wrap" mr={3}>
      {formatContent(message.content)}
    </Typography>
  );
}

// Content
function defaultFormat(content: string): string {
  const withoutHtml = content.replace(/<[^>]*>/g, '');

  const textArea = document.createElement('textarea');
  textArea.innerHTML = withoutHtml;
  return textArea.value.trim();
}

function replaceDeletedMessage(content: string): string {
  return content.replace(
    /<p>\[\[modules:chat\.message-deleted\]\]<\/p>/g,
    '<THIS MESSAGE HAS BEEN DELETED>'
  );
}

const replacementMap: { [key: string]: (content: string) => string } = {
  '<p>[[modules:chat.message-deleted]]</p>': replaceDeletedMessage,
};

function formatContent(content: string): string {
  if (Object.keys(replacementMap).some((key) => content.includes(key))) {
    return Object.entries(replacementMap).reduce(
      (acc, [key, formatter]) => acc.replace(key, formatter),
      content
    );
  }

  return defaultFormat(content);
}
