import { Box } from '@mui/material';
import ImporterAndCreatorRankTableView from '../../../components/Rankings/ImporterAndCreatorTable';
import useTopTransfersView from './useView';

export default function TopImporterAndCreator(): JSX.Element {
  const {
    data,
    isLoading,
    handleOpenFilter,
    currentPage,
    currentSize,
    currentRankBy,
  } = useTopTransfersView();
  return (
    <Box mx={2}>
      <ImporterAndCreatorRankTableView
        data={data?.body ?? []}
        isLoading={isLoading}
        handleOpenFilter={handleOpenFilter}
        stats={data?.meta}
        page={currentPage}
        rankBy={currentRankBy}
        size={currentSize}
      />
    </Box>
  );
}
