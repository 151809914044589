import { yupResolver } from '@hookform/resolvers/yup';
import { Card, CardContent, CardHeader, Stack } from '@mui/material';
import { capitalCase } from 'change-case';
import { useForm } from 'react-hook-form';
import Button from 'src/components/shared/Buttons/CustomButton';
import Flex from 'src/components/shared/Flex';
import ControllerField from 'src/components/shared/Inputs/ControllerField';
import Modal from 'src/components/shared/Modal';
import SelectOption from 'src/components/shared/SelectOption';
import useModalStore from 'src/lib/stores/useModalStore';
import { SubmissionFormStatuses } from 'src/lib/types/formReviewStatus';
import objectEntries from 'src/lib/utils/objectEntries';
import * as yup from 'yup';
import { ReviewSubmisisonFormValues } from './types';

const validationSchema = yup.object().shape({
  // status should be a enum item from SubmissionFormStatuses
  status: yup
    .string()
    .required('Status is required')
    .oneOf(objectEntries(SubmissionFormStatuses).map(([, value]) => value)),
  reviewerComment: yup.string(),
});

type Props = {
  defaultValues: ReviewSubmisisonFormValues;
  onSubmit(values: ReviewSubmisisonFormValues): Promise<void>;
  title: string;
};
export default function ReviewSubmisisonForm({
  defaultValues,
  onSubmit,
  title,
}: Props): JSX.Element {
  const closeModal = useModalStore((state) => state.closeModal);
  const {
    handleSubmit,
    formState: { isSubmitting },
    control,
    getFieldState,
  } = useForm<ReviewSubmisisonFormValues>({
    defaultValues,
    resolver: yupResolver(validationSchema),
  });

  const handleCancel = () => {
    closeModal();
  };

  return (
    <Modal open onClose={handleCancel} aria-labelledby="create a configuration">
      <Card
        sx={{
          width: 400,
        }}
      >
        <form onSubmit={handleSubmit(onSubmit)}>
          <CardHeader title={title} />
          <CardContent>
            <Stack spacing={1.5}>
              <ControllerField
                control={control}
                name="status"
                fullWidth
                label="Status"
                select
                wrapperProps={{
                  sx: {
                    gridColumn: '1 / span 2',
                  },
                }}
              >
                {objectEntries(SubmissionFormStatuses).map(([label, value]) => (
                  <SelectOption value={value} key={value}>
                    {capitalCase(label)}
                  </SelectOption>
                ))}
              </ControllerField>
              <ControllerField
                control={control}
                name="reviewerComment"
                label="Message"
                multiline
                minRows={3}
              />
            </Stack>
            <Flex width="100%" justifyContent="space-between" mt={4}>
              <Button variant="text" color="error" onClick={handleCancel}>
                Cancel
              </Button>
              <Button
                type="submit"
                loading={isSubmitting}
                variant="contained"
                color="secondary"
              >
                Save
              </Button>
            </Flex>
          </CardContent>
        </form>
      </Card>
    </Modal>
  );
}
