import { DeepPartial } from 'react-hook-form';
import { FeedItemAnalyticsTableRow } from 'src/lib/types/FeedItem';
import { parseSearchParams } from 'src/lib/utils/parseSearchParams';
import useSWR, { SWRResponse } from 'swr';

export type PossibleParams = {
  page: number | string;
  size: number | string;
  filter: {
    createdAt: {
      gte: string;
      lte: string;
    };
  };
};

export type FeedItemAnalyticsResponse = {
  meta: { totalCount: number };
  body: FeedItemAnalyticsTableRow[];
};

export const fetchFeedItemAnalytics = (
  params: DeepPartial<PossibleParams>
): SWRResponse<FeedItemAnalyticsResponse, Error> => {
  const queryString = parseSearchParams(params ?? {});

  const response = useSWR<FeedItemAnalyticsResponse, Error>(
    `admin/app-video-feeds/analytics?${queryString}`
  );

  return response;
};
