import useURLSearchParams from 'src/lib/hooks/useURLSearchParams';
import useModalStore from 'src/lib/stores/useModalStore';
import { IncludeExcludeAll } from 'src/lib/utils/includeExcludeFilterUtils';
import UserTransferRankFilter from '../../../components/Rankings/TransfersRankTable/Filter';
import {
  RankBy,
  WalletType,
} from '../../../components/Rankings/TransfersRankTable/types';
import {
  INITIAL_FILTERS,
  INITIAL_SEARCH_PARAMS_STATE,
} from '../../../constants/TransfersRanking';
import { fetchTransfersRank } from '../../../services/fetchTransfersRanking';
import { UserType } from '../../../types/UserType';
import { valueIfDifferentOf } from '../../../utils/valueIfDifferentOf';

export default function useTopTransfersView() {
  const openModal = useModalStore((state) => state.openModal);
  const { allSearchParams } = useURLSearchParams(INITIAL_SEARCH_PARAMS_STATE);

  const { data, isLoading } = fetchTransfersRank({
    page: allSearchParams.page,
    size: allSearchParams.size,
    rankBy: allSearchParams.rankBy as RankBy,
    userType: valueIfDifferentOf(allSearchParams.userType as UserType, 'all'),
    filter: {
      country: allSearchParams.country,
      SelfTransfers: allSearchParams.SelfTransfers as IncludeExcludeAll,
      InactiveUsers: allSearchParams.InactiveUsers as IncludeExcludeAll,
      lang: allSearchParams.lang,
      receiverWalletType: allSearchParams.receiverWalletType as WalletType,
      senderWalletType: allSearchParams.senderWalletType as WalletType,

      endDate: allSearchParams.endDate
        ? `${allSearchParams.endDate}T23:59:59.000Z`
        : undefined,
      startDate: allSearchParams.startDate
        ? `${allSearchParams.startDate}T00:00:00.000Z`
        : undefined,
    },
  });

  function handleOpenFilter() {
    openModal(
      <UserTransferRankFilter
        initialValues={{
          startDate: allSearchParams.startDate,
          endDate: allSearchParams.endDate,
          country: allSearchParams.country ?? INITIAL_FILTERS.country,
          SelfTransfers: allSearchParams.SelfTransfers as IncludeExcludeAll,
          lang: allSearchParams.lang ?? INITIAL_FILTERS.lang,
          rankBy: (allSearchParams.rankBy as RankBy) ?? INITIAL_FILTERS.rankBy,
          receiverWalletType:
            (allSearchParams.receiverWalletType as WalletType) ??
            INITIAL_FILTERS.receiverWalletType,
          senderWalletType:
            (allSearchParams.senderWalletType as WalletType) ??
            INITIAL_FILTERS.senderWalletType,
          InactiveUsers: allSearchParams.InactiveUsers as IncludeExcludeAll,
          userType:
            (allSearchParams.userType as UserType) ?? INITIAL_FILTERS.userType,
        }}
      />
    );
  }

  return {
    data,
    isLoading,
    handleOpenFilter,
    currentPage: Number(
      allSearchParams.page ?? INITIAL_SEARCH_PARAMS_STATE.page
    ),
    currentSize: Number(
      allSearchParams.size ?? INITIAL_SEARCH_PARAMS_STATE.size
    ),
    currentRankBy: (allSearchParams.rankBy as RankBy) ?? INITIAL_FILTERS.rankBy,
  };
}
