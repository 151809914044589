import { EventsTabs } from './Types/EventsTabs';
import UserBlocksTable from './components/UserBlocksTable/UserBlocksTable';
import UserFlagsTable from './components/UserFlagsTable/UserFlagsTable';
import UserUnblocksTable from './components/UserUnblocksTable/UserUnblocksTable';
import UserUnflagsTable from './components/UserUnflagsTable/UserUnflagsTable';

interface IProfileTabs {
  value: EventsTabs;
  component: () => JSX.Element;
}

const EVENTS_TABS: IProfileTabs[] = [
  {
    value: EventsTabs.BLOCKS,
    component: () => <UserBlocksTable key={'blocks'} />,
  },
  {
    value: EventsTabs.UNBLOCKS,
    component: () => <UserUnblocksTable key={'unblocks'} />,
  },
  {
    value: EventsTabs.FLAGS,
    component: () => <UserFlagsTable key={'flags'} />,
  },
  {
    value: EventsTabs.UNFLAGS,
    component: () => <UserUnflagsTable key={'unflags'} />,
  },
];

export default EVENTS_TABS;
