import { SmartColumn } from 'src/components/shared/SmartTable/SmartTable';

export const refundedUsersTableColumns: SmartColumn[] = [
  { id: 'user', label: 'User', align: 'left', isSortable: false },
  {
    id: 'user_status',
    label: 'User Status',
    align: 'center',
    isSortable: false,
  },
  { id: 'refunds', label: 'Total Refunds', align: 'center', isSortable: false },
  {
    id: 'deniedRefunds',
    label: 'Denied Refunds',
    align: 'center',
    isSortable: false,
  },
  {
    id: 'amountRefunded',
    label: 'Total Amount Refunded',
    align: 'right',
    isSortable: false,
  },
];
