import SystemUptimeTable from '../../components/SystemUptime/Table';
import useSystemUptimeView from './useView';

export default function SystemUptimeView(): JSX.Element {
  const { data, isLoading, page, size, sortBy, sortOrder } =
    useSystemUptimeView();
  return (
    <SystemUptimeTable
      page={page}
      size={size}
      data={data?.body ?? []}
      isLoading={isLoading}
      totalCount={data?.meta.totalCount ?? 0}
      sortBy={sortBy}
      sortOrder={sortOrder}
    />
  );
}
