import { useCallback, useEffect } from 'react';
import { useParams } from 'react-router';
import AddAssetInContestView from 'src/components/modules/Contests/view/AddAsset';
import { DEFAULT_INITIAL_PAGE_SIZE } from 'src/lib/constants/pagination';
import useURLSearchParams from 'src/lib/hooks/useURLSearchParams';
import useModalStore from 'src/lib/stores/useModalStore';
import { GameAssetEmitter } from '../../events/GameAssetEmitter';
import { fetchGameAssetsByContest } from '../../services/fetchGameAssetsByContest';
import DeleteAssetView from '../DeleteGameAsset';

export default function useGameAssets() {
  const { allSearchParams } = useURLSearchParams(initialParams);
  const { contestUuid } = useParams<{
    contestUuid: string;
  }>();

  const [openModal] = useModalStore((state) => [
    state.openModal,
    state.closeModal,
  ]);

  const handleOpenAddAssetModal = async () => {
    openModal(<AddAssetInContestView contestUuid={contestUuid!} />);
  };
  const handleRemoveAsset = async (assetUuid: string) => {
    openModal(
      <DeleteAssetView contestUuid={contestUuid!} assetUuid={assetUuid} />
    );
  };

  const params = {
    page: +(allSearchParams.page ?? initialParams.page),
    size: +(allSearchParams.size ?? initialParams.size),
    orderBy: allSearchParams.orderBy ?? initialParams.orderBy,
    sortOrder: allSearchParams.sortOrder ?? initialParams.order,
  };

  const { data, isLoading, mutate } = fetchGameAssetsByContest(contestUuid!, {
    page: params.page,
    size: params.size,

    ...(params.orderBy &&
      params.sortOrder && {
        order: {
          [params.orderBy]: params.sortOrder,
        },
      }),
  });

  const refetchData = useCallback(() => {
    mutate?.();
  }, []);

  useEffect(() => {
    GameAssetEmitter.on('GameAssetAdded', refetchData);
    GameAssetEmitter.on('GameAssetRemoved', refetchData);

    return () => {
      GameAssetEmitter.off('GameAssetAdded', refetchData);
      GameAssetEmitter.off('GameAssetRemoved', refetchData);
    };
  }, [refetchData]);

  return {
    data,
    isLoading,
    params,
    contestUuid,
    handleOpenAddAssetModal,
    handleRemoveAsset,
  };
}

const initialParams = {
  page: '0',
  size: DEFAULT_INITIAL_PAGE_SIZE.toString(),
  order: 'desc',
  orderBy: 'createdAt',
};

export type FetchGameAssetsQueryParams = {
  page: number;
  size: number;
  orderBy: string;
  sortOrder: string;
};
