import HomeView from 'src/components/modules/Home/view/Home';
import Page from 'src/template/components/Page';

export default function HomePage() {
  return (
    <Page title="Sticky Admin">
      <HomeView />
    </Page>
  );
}
