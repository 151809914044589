import { format } from 'date-fns';
import { useCallback, useEffect } from 'react';
import { useParams } from 'react-router';
import useNotify from 'src/lib/hooks/useNotify';
import useModalStore from 'src/lib/stores/useModalStore';
import BlockDeviceForm from '../../components/Device/BlockForm';
import UpdateDeviceForm from '../../components/Device/UpdateForm';
import { DeviceEmitter } from '../../events/DeviceEmitter';
import { fetchDevice } from '../../services/fetchDevice';
import { updateDevice } from '../../services/updateDevice';
import { Device } from '../../types/Device';

export default function useDeviceView() {
  const { uuid } = useParams<{ uuid: string }>();
  const { isLoading, data, mutate } = fetchDevice(uuid!);
  const notify = useNotify();
  const openModal = useModalStore((state) => state.openModal);

  async function handleBlockDevice(device: Device) {
    openModal(<BlockDeviceForm device={device} />);
  }

  async function handleUnblockDevice(device: Device) {
    const { error } = await updateDevice(device.uuid, { active: true });
    if (error) {
      notify(error, { variant: 'error' });
      return;
    }
    notify('Device unblocked successfully', { variant: 'success' });
  }

  async function handleToggleDeviceActivation(): Promise<void> {
    if (!uuid || !data) return;
    if (data.active) {
      await handleBlockDevice(data);
    } else {
      await handleUnblockDevice(data);
    }
  }

  async function handleUpdateDevice(): Promise<void> {
    if (!data) return;
    openModal(<UpdateDeviceForm device={data} />);
  }

  const refetchData = useCallback(() => {
    mutate();
  }, []);

  useEffect(() => {
    DeviceEmitter.on('deviceUpdated', refetchData);
    return () => {
      DeviceEmitter.off('deviceUpdated', refetchData);
    };
  }, []);

  const usersLength = data?.users?.length ?? 0;

  const allLastLogins =
    data?.users.filter((u) => u.lastLogin).map((u) => u.lastLogin!) ?? [];

  const lastLogin: number | undefined =
    allLastLogins.length > 0 ? Math.max(...allLastLogins) : undefined;

  const allCreatedAt = data?.users.map((u) => u.createdAt) ?? [];

  const firstCreated: number | undefined =
    allCreatedAt.length > 0 ? Math.min(...allCreatedAt) : undefined;
  const nonAnonymousUsers = data?.users.filter((u) => u.email).length ?? 0;
  const anonymousUsers = usersLength - nonAnonymousUsers;
  const activeUsers = data?.users.filter((u) => u.active).length ?? 0;
  const inactiveUsers = usersLength - activeUsers;

  return {
    data,
    isLoading,
    usersLength,
    handleToggleDeviceActivation,
    lastLogin: lastLogin ? format(lastLogin, 'dd/MM/yyyy · HH:mm') : '--',
    firstCreated: firstCreated
      ? format(firstCreated, 'dd/MM/yyyy · HH:mm')
      : '--',
    nonAnonymousUsers,
    anonymousUsers,
    activeUsers,
    inactiveUsers,
    handleUpdateDevice,
  };
}
