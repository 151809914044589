import { Box, Button, Typography } from '@mui/material';

type ErrorFallbackProps = {
  error: Error;
  resetErrorBoundary: () => void;
};

export default function ErrorFallback({
  error,
  resetErrorBoundary,
}: ErrorFallbackProps) {
  return (
    <Box sx={{ p: 3, textAlign: 'center' }}>
      <Typography variant="h6" gutterBottom>
        Something went wrong:
      </Typography>
      <Typography sx={{ color: 'text.secondary' }}>{error.message}</Typography>
      <Button onClick={resetErrorBoundary} sx={{ mt: 3 }}>
        Try again
      </Button>
    </Box>
  );
}
