import { WithTimestamp } from './WithTimestamp';

export type Contest = WithTimestamp<{
  uuid: string;
  name: string;
  description: string;
  details: string;
  entryPrice: number;
  totalEntries: number;
  playerEntryLimit: number;
  awardValue: number;
  awardDistributionMode: ContestAwardDistributionModes;
  frequency: ContestFrequency;
  startDate: Date;
  endDate: Date | null;
  gameLevelUuid: string;
  gameUuid: string;
  primaryColor: string;
  secondaryColor: string;
  status: ContestStatus;
  awardConfigurations: AwardConfiguration[];
  awardWinningStatisticsGroupUuid: string;
}>;

export enum ContestAwardDistributionModes {
  PERCENTUAL = 'Percentual',
  FIXED = 'Fixed',
}

export enum ContestFrequency {
  DAILY = 'Daily',
  WEEKLY = 'Weekly',
  BIWEEKLY = 'Biweekly',
  MONTHLY = 'Monthly',
}

export type AwardConfiguration = WithTimestamp<{
  uuid: string;
  contestUuid: string;
  position: number;
  awardValue: number;
}>;

export enum ContestStatus {
  ACTIVE = 'active',
  CANCELED = 'canceled',
  DRAFT = 'draft',
}
