import { Container } from '@mui/material';
import NftPackDetails from 'src/components/modules/NFTPacks/view/NFTPack/Details';
import Page from 'src/template/components/Page';

export default function NFTPackPage() {
  return (
    <Page title="NFT Pack">
      <Container maxWidth="xl">
        <NftPackDetails />
      </Container>
    </Page>
  );
}
