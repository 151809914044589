/* eslint-disable no-restricted-syntax */
import { OpenInNew } from '@mui/icons-material';
import {
  Alert,
  Autocomplete,
  Box,
  IconButton,
  Stack,
  TextField,
  Typography,
} from '@mui/material';
import { GridColumns } from '@mui/x-data-grid';
import { capitalCase } from 'change-case';
import { ProfileTabs } from 'src/components/modules/Profile/types/ProfileTabs';
import Center from 'src/components/shared/Center';
import CloseableAlert from 'src/components/shared/CloseableAlert';
import Flex from 'src/components/shared/Flex';
import Link from 'src/components/shared/Link';
import MagicTable from 'src/components/shared/MagicTable/MagicTable';
import MagicTableUserCell from 'src/components/shared/MagicTable/UserCell';
import useMagicTable from 'src/components/shared/MagicTable/useMagicTable';
import SectionDivider from 'src/components/shared/SectionDivider';
import { RevenueEvent } from 'src/lib/types/revenueEvent';
import { formatUTCDate } from 'src/lib/utils/formatUTCDate';
import Routes from 'src/routes/Routes';
import Iconify from 'src/template/components/Iconify';
import Label from 'src/template/components/Label';
import { ColorByRevenueEventType } from '../../constants/ColorByRevenueEventType';
import { RevenueEventType } from '../../types/RevenueEvent';
import useRevenueEventsConsistencyView from './useView';

const missingInfoColor = '#a00000';

export default function RevenueEventsConsistencyView(): JSX.Element {
  const magicTableProps = useMagicTable();
  const { data, error, isLoading, onChangeFilter, filter } =
    useRevenueEventsConsistencyView();

  const tableData = data ?? [];

  if (error) {
    return (
      <Center>
        <Alert severity="error">
          Could not load Revenue Events, please try again later.
          <br /> <b>Error:</b> {error}
        </Alert>
      </Center>
    );
  }
  return (
    <Stack spacing={3}>
      <CloseableAlert severity="info">
        This page shows all revenue events without associated pools or those
        that have failed.
      </CloseableAlert>
      <SectionDivider section="Dates are in UTC timezone" />
      <MagicTable.Container>
        <MagicTable.FilterContainer>
          <Autocomplete
            // RevenueEventType
            options={[
              RevenueEventType.RENEW,
              RevenueEventType.UPGRADE,
              RevenueEventType.REFUND,
              RevenueEventType.BUY,
            ].map((eventType) => eventType)}
            getOptionLabel={(opt) => capitalCase(opt)}
            fullWidth
            renderInput={(params) => (
              <TextField label="Event Type" {...params} />
            )}
            loading={isLoading}
            defaultValue={filter.eventType}
            onChange={(_, value) => {
              onChangeFilter('eventType', value);
            }}
          />

          <TextField
            label="User UUID"
            fullWidth
            type="text"
            InputLabelProps={{ shrink: true }}
            defaultValue={filter.userUuid}
            onChange={({ target: { value } }) => {
              onChangeFilter('userUuid', value);
            }}
          />
        </MagicTable.FilterContainer>
        <MagicTable.Wrapper>
          <MagicTable
            {...magicTableProps}
            loading={isLoading}
            columns={columns}
            rows={tableData}
            initialState={{
              sorting: {
                sortModel: [{ field: 'expiresDate', sort: 'desc' }],
              },
            }}
          />
        </MagicTable.Wrapper>
      </MagicTable.Container>
    </Stack>
  );
}
const columns: GridColumns<
  RevenueEvent & {
    userName: string;
    userSlug: string;
    userEmail: string;
    userActive: boolean;
    userProfileImage: string;
    productDisplayName: string;
    inconsistencyType: 'notExists' | 'failed';
    productName: string;
    productId: string;
  }
> = [
  {
    field: 'productName',
    headerName: 'Product',
    sortable: true,
    flex: 2.5,
    renderCell({ row, value }) {
      if (row.productName)
        return row.productName ? (
          <Stack>
            <Typography>{row.productName}</Typography>
            <Flex gap={1}>
              <Typography fontSize="12px" color="GrayText">
                {row.productId}
              </Typography>
            </Flex>
          </Stack>
        ) : (
          <Label variant="ghost" color="default" flex={1}>
            null
          </Label>
        );
      return value;
    },
  },
  {
    field: 'user',
    sortable: false,
    headerName: 'User',
    flex: 1.5,
    renderCell({ row }) {
      if (!row.userUuid) {
        return '--';
      }
      return (
        <MagicTableUserCell
          displayUserStatus
          linkUrl={`${Routes.user(row.userUuid)}?tab=${
            ProfileTabs.SUBSCRIPTIONS
          }`}
          user={{
            name: row.userName,
            slug: row.userSlug,
            email: row.userEmail,
            profileImage: row.userProfileImage,
            uuid: row.userUuid,
            active: row.userActive,
          }}
        />
      );
    },
  },
  {
    field: 'eventType',
    headerName: 'Event Type',
    sortable: true,
    renderCell({ row }) {
      const { eventType } = row;
      return (
        <Box>
          <Label color={ColorByRevenueEventType[eventType]}>
            {capitalCase(row.eventType)}
          </Label>
        </Box>
      );
    },
  },
  {
    field: 'eventDate',
    headerName: 'Event Date',
    flex: 3 / 2,
    sortable: true,
    valueFormatter({ value }) {
      return formatUTCDate(value, { second: '2-digit' });
    },
  },
  {
    field: 'originalPurchaseDate',
    headerName: 'Purchase Date',
    flex: 3 / 2,
    sortable: true,
    valueFormatter({ value }) {
      return value ? formatUTCDate(value, { second: '2-digit' }) : '--';
    },
  },
  {
    field: 'action',
    headerName: 'Transaction link',
    align: 'center',
    flex: 1 / 2,
    sortable: false,
    renderCell({ row }) {
      return row.transactionPoolUuid ? (
        <IconButton
          component={Link}
          to={Routes.transactions.pool(row.transactionPoolUuid)}
        >
          <OpenInNew />
        </IconButton>
      ) : (
        <Iconify
          icon="mdi:null-off"
          sx={{ fontSize: '1.0rem', color: missingInfoColor }}
        />
      );
    },
  },
];
