import { DeepPartial } from 'react-hook-form';
import { PaginatedResponse } from 'src/lib/types/PaginatedResponse';
import { IncludeExcludeAll } from 'src/lib/utils/includeExcludeFilterUtils';
import { parseSearchParams } from 'src/lib/utils/parseSearchParams';
import useSWR, { SWRResponse } from 'swr';
import {
  ImporterAndCreatorTotalStatistics,
  ParsedUserImporterAndCreatorRank,
  UserImporterAndCreatorRank,
} from '../types/Ranking';
import { UserType } from '../types/UserType';

export type RankBy = 'total' | 'imported' | 'created';

type PossibleFilters = {
  rankBy: RankBy;
  size: number | string;
  page: number | string;
  userType: UserType;
  filter: {
    startDate: string;
    endDate: string;
    lang: string;
    country: string;
    InactiveUsers: IncludeExcludeAll;
  };
};

function parseData(
  data:
    | PaginatedResponse<
        UserImporterAndCreatorRank[],
        ImporterAndCreatorTotalStatistics
      >
    | undefined
): PaginatedResponse<
  ParsedUserImporterAndCreatorRank[],
  ImporterAndCreatorTotalStatistics
> {
  if (!data) {
    return {
      body: [],
      meta: {
        totalCount: 0,
        totalImported: 0,
        totalCreated: 0,
        total: 0,
      },
    };
  }
  return {
    meta: data.meta,
    body: data.body.map((item) => ({
      ...item,
      created: Number(item.created),
      imported: Number(item.imported),
    })),
  };
}

export function fetchImporterAndCreatorRank(
  params?: DeepPartial<PossibleFilters>
): SWRResponse<
  PaginatedResponse<
    ParsedUserImporterAndCreatorRank[],
    ImporterAndCreatorTotalStatistics
  >
> {
  const parsedParams = parseSearchParams(params ?? {});
  const { data, ...response } = useSWR(
    `admin/users/ranking/import-and-created?${parsedParams}`
  );

  return {
    ...response,
    data: parseData(data),
  };
}
