import useSWR, { mutate } from 'swr';
import { PaginatedResponse } from '../types/PaginatedResponse';
import { BracketFilterParams } from '../types/bracketFilterParams';
import { FilterOperator, HomeFeed, TypeOption } from '../types/homeFeed';
import { getFormattedError } from '../utils/error';
import generateSearchRequest from '../utils/generateSearchRequest';
import getAPIClient from './api/axios.config';

export const fetchHomeFeeds = (filter: BracketFilterParams, status = 'all') => {
  const statusFilter = status === 'all' ? '' : `&filter[status]=${status}`;

  const { data, error } = useSWR<PaginatedResponse<HomeFeed[]>, Error>(
    `admin/feeds/${generateSearchRequest(filter)}${statusFilter}`
  );

  return {
    data: data?.body,
    total: data?.meta.totalCount,
    loading: !error && !data,
    error: error?.message,
    queryParams: `${generateSearchRequest(filter)}${statusFilter}`,
  };
};

export const fetchHomeFeedWithSWR = (uuid: string) => {
  const { data, error } = useSWR<HomeFeed, Error>(`admin/feeds/${uuid}`);
  return {
    data,
    loading: !error && !data,
    error: error?.message,
  };
};

export const fetchTypeOptions = () => {
  const { data, error } = useSWR<TypeOption[], Error>(`admin/feeds/types`);
  return {
    data,
    loading: !error && !data,
    error: error?.message,
  };
};

export const fetchFilterOperators = () => {
  const { data, error } = useSWR<FilterOperator[], Error>(
    `admin/feeds/filter-operators`
  );
  return {
    data,
    loading: !error && !data,
    error: error?.message,
  };
};

export const fetchHomeFeed = async (uuid: string) => {
  try {
    const api = await getAPIClient();
    const { data } = await api.get<HomeFeed>(`admin/feeds/${uuid}`);

    return { data };
  } catch (err) {
    return { error: getFormattedError(err) };
  }
};

export const createHomeFeed = async (
  homeFeed: Partial<HomeFeed>,
  currentQueryParams: string
) => {
  try {
    const api = await getAPIClient();
    const res = await api.post('admin/feeds', homeFeed);

    mutate(`admin/feeds/${currentQueryParams}`);

    return {
      data: res.data,
      success: true,
      message: 'The home page feed has been created successfully',
    };
  } catch (err) {
    return {
      success: false,
      ...getFormattedError(err),
    };
  }
};

export const updateHomeFeed = async (
  homeFeed: Partial<HomeFeed>,
  currentQueryParams: string
) => {
  try {
    const api = await getAPIClient();
    const res = await api.put(`admin/feeds/${homeFeed.uuid}`, homeFeed);

    mutate(`admin/feeds/${homeFeed.uuid}`);
    mutate(`admin/feeds/${currentQueryParams}`);

    return {
      data: res.data,
      success: true,
      message: 'The home page feed has been updated successfully',
    };
  } catch (err) {
    return {
      success: false,
      ...getFormattedError(err),
    };
  }
};

export const deleteHomeFeed = async (
  uuid: string,
  currentQueryParams: string
) => {
  try {
    const api = await getAPIClient();
    const res = await api.delete(`admin/feeds/${uuid}`);

    mutate(`admin/feeds/${uuid}`);
    mutate(`admin/feeds/${currentQueryParams}`);

    return {
      data: res.data,
      success: true,
      message: 'The home page feed has been deleted successfully',
    };
  } catch (err) {
    return {
      success: false,
      ...getFormattedError(err),
    };
  }
};
