import { Add, Clear } from '@mui/icons-material';
import {
  Autocomplete,
  Card,
  CardContent,
  IconButton,
  MenuItem,
  Stack,
  TextField,
} from '@mui/material';
import { capitalCase } from 'change-case';
import Button from 'src/components/shared/Buttons/CustomButton';
import { FilterOperator } from 'src/lib/types/homeFeed';
import CustomSelect from '../CustomSelect';
import { FilterTypes, UiFeedFilter } from './types';

interface UiFiltersCardCardProps {
  filterList: UiFeedFilter[];
  changeFilterList: (index: number, newFilter: UiFeedFilter | null) => void;
  attributes: string[];
  operators: FilterOperator[];
}
export default function UiFiltersCard({
  filterList,
  changeFilterList,
  attributes,
  operators,
}: UiFiltersCardCardProps): JSX.Element {
  return (
    <Card
      sx={{
        bgcolor: '#fafafafa',
        overflowY: 'auto',
        height: 'calc(95vh - 320px);',
      }}
    >
      <Card>
        <CardContent>
          <Stack direction="column" spacing={3}>
            {filterList.map((filter, index) => (
              <Stack direction="row" spacing={3}>
                <CustomSelect
                  label="Filter Type"
                  value={filter.filterType || ''}
                  onChange={(event) =>
                    changeFilterList(index, {
                      filterType:
                        (event.target.value as FilterTypes) || undefined,
                    })
                  }
                >
                  {Object.values(FilterTypes).map((filterType) => (
                    <MenuItem key={filterType} value={filterType}>
                      {capitalCase(filterType)}
                    </MenuItem>
                  ))}
                </CustomSelect>
                <Autocomplete
                  freeSolo
                  options={[
                    ...(attributes ?? []),
                    'random',
                    'creator',
                    'price',
                  ]}
                  inputValue={filter.attribute || ''}
                  onInputChange={(event, newInputValue) => {
                    changeFilterList(index, {
                      filterType: filter.filterType,
                      attribute: newInputValue,
                    });
                  }}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label="Attribute"
                      placeholder="Enter a attribute"
                    />
                  )}
                  sx={{ bgcolor: '#fff', width: '100%' }}
                />
                {filter.filterType === FilterTypes.FILTER && (
                  <>
                    <CustomSelect
                      label="Operator"
                      value={filter.operator || ''}
                      disabled={!filter.attribute}
                      onChange={(event) =>
                        changeFilterList(index, {
                          ...filter,
                          operator: event.target.value || undefined,
                        })
                      }
                    >
                      {operators.map((operator) => (
                        <MenuItem key={operator.value} value={operator.value}>
                          {operator.label}
                        </MenuItem>
                      ))}
                    </CustomSelect>
                    <TextField
                      label="Value"
                      placeholder="Filter value"
                      value={filter.value || ''}
                      disabled={!filter.attribute}
                      onChange={(event) =>
                        changeFilterList(index, {
                          ...filter,
                          value: event.target.value || undefined,
                        })
                      }
                      sx={{ width: '100%' }}
                    />
                  </>
                )}
                {filter.filterType === FilterTypes.ORDER &&
                  (filter.attribute === 'random' ? (
                    <TextField
                      label="Value"
                      placeholder="Filter value"
                      value={filter.value || ''}
                      onChange={(event) =>
                        changeFilterList(index, {
                          ...filter,
                          value: event.target.value || undefined,
                        })
                      }
                      sx={{ width: '100%' }}
                    />
                  ) : (
                    <CustomSelect
                      label="Value"
                      value={filter.value || ''}
                      onChange={(event) =>
                        changeFilterList(index, {
                          ...filter,
                          value: event.target.value || undefined,
                        })
                      }
                    >
                      <MenuItem key="asc" value="asc">
                        Ascendent
                      </MenuItem>
                      <MenuItem key="desc" value="desc">
                        Descendent
                      </MenuItem>
                    </CustomSelect>
                  ))}
                <IconButton
                  onClick={() => changeFilterList(index, null)}
                  aria-label="delete filter"
                  size="small"
                  disabled={index === 0}
                  sx={{
                    opacity: index === 0 ? 0.5 : 1,
                  }}
                >
                  <Clear color="error" />
                </IconButton>
              </Stack>
            ))}
            <Button
              startIcon={<Add />}
              variant="outlined"
              onClick={() => changeFilterList(filterList.length, {})}
              color="secondary"
            >
              Add
            </Button>
          </Stack>
        </CardContent>
      </Card>
    </Card>
  );
}
