import getAPIClient from 'src/lib/services/api/axios.config';
import { getMessageFromError } from 'src/lib/utils/error';

export async function removeAssetFromContest(
  contestUuid: string,
  assetUuid: string
) {
  try {
    const api = await getAPIClient();
    const res = await api.delete(
      `/admin/game-contest/${contestUuid}/assets/${assetUuid}`
    );
    return {
      data: res.data,
      error: null,
    };
  } catch (e) {
    return {
      data: null,
      error: getMessageFromError(e),
    };
  }
}
