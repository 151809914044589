import { DeepPartial } from 'react-hook-form';
import { NftPack } from 'src/lib/types/nftPack';
import { parseSearchParams } from 'src/lib/utils/parseSearchParams';
import useSWR, { SWRResponse } from 'swr';

export const fetchNftPacks = (
  filters?: DeepPartial<PossibleParams>
): SWRResponse<NftPack[]> => {
  const response = useSWR(`admin/nft-pack?${parseSearchParams(filters ?? {})}`);

  return response;
};

type PossibleParams = {
  page: number | string;
  size: number | string;
  filter: {
    status: string;
    name: {
      like: string;
    };
  };
  order: Record<string, string>;
};
