import { LabelColor } from 'src/template/components/Label';
import { RevenueEventType } from '../types/RevenueEvent';

export const ColorByRevenueEventType: Record<RevenueEventType, LabelColor> = {
  Buy: 'success',
  ChangeStatusDisabled: 'error',
  ChangeStatusEnabled: 'success',
  RefundRequest: 'error',
  Cancel: 'error',
  BillingIssue: 'error',
  Downgrade: 'error',
  DowngradeReturn: 'success',
  Refund: 'error',
  RefundDenied: 'error',
  Renew: 'info',
  Upgrade: 'success',
};
