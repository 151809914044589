import { GameCategory } from 'src/lib/types/gameCategory';
import CreateGameCategoryForm from '../../components/Forms/Create';

export default function CreateVideoGameCategory({
  gameCategory,
}: {
  gameCategory?: GameCategory;
}): JSX.Element {
  return <CreateGameCategoryForm gameCategory={gameCategory} />;
}
