import { Typography } from '@mui/material';
import Flex from 'src/components/shared/Flex';
import { Chat } from 'src/lib/types/chat';
import decodeHtmlEntities from 'src/lib/utils/decodeHtmlEntities';

interface ChatHeaderProps {
  chat: Chat;
}

export default function ChatHeader({ chat }: ChatHeaderProps) {
  return (
    <Flex
      width="100%"
      alignItems="center"
      justifyContent="flex-start"
      borderBottom="1px solid rgba(255, 255, 255, 0.1)"
      py={3}
      bgcolor="rgba(0, 0, 0, 0.8)"
      position="relative"
      top="0"
      zIndex="2"
    >
      <Flex
        justifyContent="space-between"
        alignItems="center"
        width="full"
        maxWidth={['full', '500px']}
        px={4}
      >
        <Typography color="white" fontWeight={700} fontSize="14px">
          {chat?.roomName ? decodeHtmlEntities(chat.roomName) : 'Chat'}
        </Typography>
      </Flex>
    </Flex>
  );
}
