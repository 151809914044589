import useModalStore from 'src/lib/stores/useModalStore';
import { GameMakerAssetCollection } from 'src/lib/types/gameMakerAsset';
import DeleteAssetCollectionView from '../../view/Delete';
import UpdateAssetCollectionView from '../../view/Update';

export default function useAssetCollectionTable() {
  const openModal = useModalStore((state) => state.openModal);

  const handleOpenUpdateModal = (assetCollection: GameMakerAssetCollection) => {
    openModal(<UpdateAssetCollectionView assetCollection={assetCollection} />);
  };
  const handleOpenDeleteModal = (uuid: string) => {
    openModal(<DeleteAssetCollectionView uuid={uuid} />);
  };

  return {
    handleOpenUpdateModal,
    handleOpenDeleteModal,
  };
}
