import Contests from 'src/components/modules/Contests/view/List';
import Page from 'src/template/components/Page';

export default function ConstestsPage() {
  return (
    <Page title="Game Constests">
      <Contests />
    </Page>
  );
}
