import {
  Autocomplete,
  Avatar,
  Stack,
  TableCell,
  TableRow,
  TextField,
} from '@mui/material';
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { useNavigate } from 'react-router';
import Link from 'src/components/shared/Link';
import SmartTable from 'src/components/shared/SmartTable/SmartTable';
import { CommonDateStrings } from 'src/lib/constants/CommonDates';
import { DEFAULT_INITIAL_PAGE_SIZE } from 'src/lib/constants/pagination';
import { fetchUserLiteralTransactions } from 'src/lib/services/transactions';
import createFilterStore from 'src/lib/stores/createFilterStore';
import { AvailableAssets } from 'src/lib/types/availableAssets';
import { TransactionType } from 'src/lib/types/transaction';
import { newDateWith3Hours } from 'src/lib/utils/addHoursToDate';
import convertUnderscoredText from 'src/lib/utils/convertUnderscoredText';
import formatStickyCoinValue from 'src/lib/utils/formatStickyCoinValue';
import { formatUTCDate } from 'src/lib/utils/formatUTCDate';
import { getImageURL } from 'src/lib/utils/nft';
import {
  setEndDateHours,
  setStartDateHours,
} from 'src/lib/utils/setFiltersDateHour';
import Routes from 'src/routes/Routes';
import Label from 'src/template/components/Label';
import { getLabelColor } from '../../../../../lib/utils/transactions/getTransactionStatusLabelColor';
import { transactionHistoryTableColumns } from './columns';

const useFilter = createFilterStore({
  orderBy: 'createdAt',
  order: 'desc',
  searchFor: 'userFromSlug',
  query: '',
  page: 0,
  size: DEFAULT_INITIAL_PAGE_SIZE,
  custom: {
    transactionType: {
      like: undefined,
    },
    dateRange: {
      startDate: CommonDateStrings.firstTransactionAndPool.date,
      endDate: new Date().toISOString(),
    },
  },
});

const searchForOptions = [
  {
    label: 'User From (slug)',
    value: 'userFromSlug',
  },
  {
    label: 'User To (slug)',
    value: 'userToSlug',
  },
  {
    label: 'User From or To (slug)',
    value: 'userFromOrToSlug',
  },
  {
    label: 'User From (Name)',
    value: 'userFromName',
  },
  {
    label: 'User To (Name)',
    value: 'userToName',
  },
  {
    label: 'User From or To (Name)',
    value: 'userFromOrToName',
  },
  {
    label: 'User From (email)',
    value: 'userFromEmail',
  },
  {
    label: 'User To (email)',
    value: 'userToEmail',
  },
  {
    label: 'User From or To (email)',
    value: 'userFromOrToEmail',
  },
];

export default function TransactionHistoryTable({
  userUuid,
}: {
  userUuid?: string;
}) {
  const navigate = useNavigate();
  const { filter, setFilter } = useFilter();

  const { data, total, loading } = fetchUserLiteralTransactions({
    page: filter.page,
    size: filter.size,
    transactionType: filter.custom?.transactionType?.like as TransactionType,
    filter: {
      [filter.searchFor]: {
        like: filter.query,
      },
    },
    dateRange: {
      startDate: filter.custom?.dateRange.startDate,
      endDate: filter.custom?.dateRange.endDate,
    },
    ...(userUuid ? { userUuidFromScreen: userUuid } : {}),
  });

  return (
    <SmartTable
      data={data}
      total={total}
      loading={loading}
      columns={transactionHistoryTableColumns}
      searchForOptions={searchForOptions}
      filter={filter}
      setFilter={setFilter}
      menu={
        <Stack
          direction="row"
          gap={2}
          justifyContent={'flex-end'}
          sx={{ minWidth: '100%' }}
        >
          <Autocomplete
            fullWidth
            renderInput={(params) => (
              <TextField {...params} label="Transaction Type" />
            )}
            defaultValue={null}
            onChange={(e, newValue) =>
              setFilter({
                custom: {
                  ...filter.custom,
                  transactionType: {
                    like:
                      newValue?.id === 'all'
                        ? undefined
                        : newValue?.id ?? undefined,
                  },
                },
              })
            }
            options={[
              { label: 'All', id: 'all' },
              ...Object.values(TransactionType).map((value) => ({
                label: value,
                id: value,
              })),
            ]}
          />
          <LocalizationProvider dateAdapter={AdapterDateFns}>
            <DatePicker
              label="Start Date"
              value={
                new Date(
                  filter.custom?.dateRange?.startDate ??
                    CommonDateStrings.firstTransactionAndPool.date
                )
              }
              minDate={new Date(CommonDateStrings.firstTransactionAndPool.date)}
              maxDate={newDateWith3Hours()}
              onChange={(e) => {
                if (!e) return;
                setFilter({
                  custom: {
                    ...filter.custom,
                    dateRange: {
                      ...filter.custom?.dateRange,
                      startDate: setStartDateHours(e),
                    },
                  },
                });
              }}
              format="dd/MM/yyyy"
            />
            <DatePicker
              label="End Date"
              value={new Date(filter.custom?.dateRange?.endDate ?? new Date())}
              minDate={
                new Date(CommonDateStrings.firstRevenueEventV2CreatedAt.date)
              }
              maxDate={newDateWith3Hours()}
              onChange={(e) => {
                if (!e) return;
                setFilter({
                  custom: {
                    ...filter.custom,
                    dateRange: {
                      ...filter.custom?.dateRange,
                      endDate: setEndDateHours(e),
                    },
                  },
                });
              }}
              format="dd/MM/yyyy"
            />
          </LocalizationProvider>
        </Stack>
      }
    >
      {data &&
        data.map((t) => (
          <TableRow
            key={t.uuid}
            hover
            style={{
              cursor: 'pointer',
            }}
            onClick={(e) => {
              e.stopPropagation();
              const { target } = e;
              // @ts-ignore href might exist in the target
              if ('href' in target && target.href) return;
              navigate(Routes.transactions.pool(t.poolId));
            }}
            onAuxClick={(e) => {
              e.stopPropagation();
              window.open(
                `/pools/${t.poolId}`,
                '_blank',
                'rel=noopener noreferrer'
              );
            }}
          >
            <TableCell>
              {t.type ? convertUnderscoredText(t.type) : 'empty'}
            </TableCell>
            <TableCell align="center">
              {t.amount
                ? [
                    AvailableAssets.boughtCoin,
                    AvailableAssets.earnedCoin,
                    AvailableAssets.bonusCoin,
                  ].includes(t.assetType)
                  ? formatStickyCoinValue(t.amount)
                  : t.amount
                : '-'}
            </TableCell>
            <TableCell align="center">{t.assetType}</TableCell>
            <TableCell>
              {t.nft ? (
                <Stack direction={'row'}>
                  <Avatar
                    alt={t.nft?.name}
                    src={getImageURL(t.nft?.fileUrl ?? '')}
                    variant="rounded"
                    sx={{ mr: 2 }}
                  />
                  <Link
                    to={Routes.nft(t.nft?.uuid ?? '')}
                    color={t.nft?.name ? 'inherit' : 'text.secondary'}
                    underline="hover"
                    display="inline-block"
                  >
                    {t.nft?.name ?? 'empty'}{' '}
                  </Link>
                </Stack>
              ) : t.collection ? (
                <Stack direction={'row'}>
                  <Avatar
                    alt={t.collection?.name}
                    src={getImageURL(t.collection?.coverFileUrl ?? '')}
                    variant="rounded"
                    sx={{ mr: 2 }}
                  />
                  <Link
                    to={Routes.collection(t.collection?.uuid ?? '')}
                    color={t.collection?.name ? 'inherit' : 'text.secondary'}
                    underline="hover"
                    display="inline-block"
                  >
                    {t.collection?.name ?? 'empty'}{' '}
                  </Link>
                </Stack>
              ) : (
                '-'
              )}
            </TableCell>
            <TableCell>
              <Link to={Routes.user(t.userFrom?.uuid!)}>
                {t.userFrom?.slug ?? '-'}
              </Link>
            </TableCell>
            <TableCell>
              <Link to={Routes.user(t.userTo?.uuid!)}>
                {t.userTo?.slug ?? '-'}
              </Link>
            </TableCell>
            <TableCell align="center">
              <Label color={getLabelColor(t.status)}>{t.status ?? '-'}</Label>
            </TableCell>
            <TableCell>
              <Link to={Routes.transactions.pool(t.poolId)}>
                {t.poolId.substring(0, 5) ?? '-'}
              </Link>
            </TableCell>
            <TableCell align="right">{formatUTCDate(t.createdAt)}</TableCell>
          </TableRow>
        ))}
    </SmartTable>
  );
}
