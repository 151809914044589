import { KeyboardArrowDown, OpenInNew } from '@mui/icons-material';
import { ListItemIcon, Menu, MenuItem } from '@mui/material';
import { useState } from 'react';
import Button from 'src/components/shared/Buttons/CustomButton';
import { NFT } from 'src/lib/types/nft';
import { getWebAppUrl } from 'src/lib/utils/utils';
import DeleteNFT from './DeleteNFT';
import EditNFT from './EditNFT';

interface NFTActionsProps {
  nft: NFT;
}

export default function NFTActions({ nft }: NFTActionsProps): JSX.Element {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [isLoading, setIsLoading] = useState(false);

  const menuIsOpen = Boolean(anchorEl);

  const handleClickMenu = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleCloseMenu = () => {
    setAnchorEl(null);
  };

  const handleOpenOnStickyWebsite = () => {
    const url = getWebAppUrl();
    window.open(`${url}/nft/${nft.uuid}`, '_blank');
  };

  return (
    <>
      <Button
        id="actions-button"
        aria-controls={menuIsOpen ? 'actions-menu' : undefined}
        aria-haspopup="true"
        aria-expanded={menuIsOpen ? 'true' : undefined}
        onClick={handleClickMenu}
        endIcon={<KeyboardArrowDown />}
        loading={isLoading}
      >
        Actions
      </Button>
      <Menu
        id="actions-menu"
        aria-labelledby="actions-button"
        anchorEl={anchorEl}
        open={menuIsOpen}
        onClose={handleCloseMenu}
      >
        <MenuItem onClick={handleOpenOnStickyWebsite}>
          <ListItemIcon>
            <OpenInNew />
          </ListItemIcon>
          Open on Sticky Website
        </MenuItem>
        <EditNFT nft={nft} handleCloseMenu={handleCloseMenu} />
        <DeleteNFT
          nft={nft}
          handleCloseMenu={handleCloseMenu}
          setLoading={setIsLoading}
        />
      </Menu>
    </>
  );
}
