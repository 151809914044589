import { Box, BoxProps } from '@mui/material';

export type Props = BoxProps;
export default function Flex({ children, ...rest }: Props): JSX.Element {
  return (
    <Box display="flex" alignItems="center" {...rest}>
      {children}
    </Box>
  );
}
