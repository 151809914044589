export function dateToDatetimeString(date: Date): string {
  // Ex: 2023-07-24 18:55:46
  const string = date.toISOString().split('.')[0];
  return string;
}

export function dateToDateString(date: Date): string {
  // Ex: 2023-07-24
  const string = date.toISOString().split('T')[0];
  return string;
}
