import { Card, CardContent, CardHeader } from '@mui/material';
import { useForm } from 'react-hook-form';
import ErrorAlert from 'src/components/shared/Alerts/ErrorAlert';
import Button from 'src/components/shared/Buttons/CustomButton';
import Flex from 'src/components/shared/Flex';
import ControllerField from 'src/components/shared/Inputs/ControllerField';
import Modal from 'src/components/shared/Modal';
import useModalStore from 'src/lib/stores/useModalStore';
import { BlockDeviceFormValues } from './types';

type Props = {
  onSubmit(values: BlockDeviceFormValues): void;
  initialValues: BlockDeviceFormValues;
};
export default function BlockDeviceFormView({
  onSubmit,
  initialValues,
}: Props): JSX.Element {
  const closeModal = useModalStore((state) => state.closeModal);
  const {
    handleSubmit,
    control,
    formState: { isSubmitting },
  } = useForm<BlockDeviceFormValues>({
    defaultValues: initialValues,
  });

  return (
    <Modal onClose={closeModal} open>
      <Card sx={{ width: 450 }}>
        <CardHeader title="Block Device" />
        <CardContent>
          <form onSubmit={handleSubmit(onSubmit)}>
            <ControllerField
              control={control}
              name="reason"
              label="Reason"
              placeholder="Type a reason"
              fullWidth
            />
            {isSubmitting && (
              <ErrorAlert
                severity="info"
                sx={{
                  mt: 2,
                }}
              >
                This operation might take a while because firebase does not
                allow concurrent requests.
              </ErrorAlert>
            )}
            <Flex justifyContent="space-between" mt={1}>
              <Button
                variant="contained"
                onClick={closeModal}
                color="error"
                sx={{
                  width: 80,
                }}
              >
                Cancel
              </Button>
              <Button
                variant="contained"
                type="submit"
                loading={isSubmitting}
                color="secondary"
                sx={{
                  width: 80,
                }}
              >
                Save
              </Button>
            </Flex>
          </form>
        </CardContent>
      </Card>
    </Modal>
  );
}
