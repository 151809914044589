import {
  CalendarMonth,
  Circle,
  ContentCopy,
  ExpandMore,
  TypeSpecimen,
} from '@mui/icons-material';
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Card,
  CardContent,
  IconButton,
  MenuItem,
  Stack,
  Tooltip,
  TooltipProps,
  Typography,
  styled,
  tooltipClasses,
} from '@mui/material';
import { useMemo } from 'react';
import { useParams } from 'react-router';
import Editions from 'src/assets/Editions';
import EntityNotesTable from 'src/components/modules/EntityNotes/components/EntityNotesTable/EntityNotesTable';
import { useEntityNotesTable } from 'src/components/modules/EntityNotes/components/EntityNotesTable/useEntityNotesTable';
import Flex from 'src/components/shared/Flex';
import Link from 'src/components/shared/Link';
import MagicTable from 'src/components/shared/MagicTable/MagicTable';
import SectionDivider from 'src/components/shared/SectionDivider';
import {
  FetchPoolDataResponse,
  fetchPoolData,
} from 'src/lib/services/transactions';
import { AvailableAssets } from 'src/lib/types/availableAssets';
import { EntityNoteTypes } from 'src/lib/types/entityNote';
import formatStickyCoinValue from 'src/lib/utils/formatStickyCoinValue';
import { formatUTCDate } from 'src/lib/utils/formatUTCDate';
import { getTruncatedText } from 'src/lib/utils/formatters';
import { getLabelColor } from 'src/lib/utils/transactions/getTransactionStatusLabelColor';
import Routes from 'src/routes/Routes';
import HeaderBreadcrumbs from 'src/template/components/HeaderBreadcrumbs';
import Label from 'src/template/components/Label';
import LoadingScreen from 'src/template/components/LoadingScreen';
import Scrollbar from 'src/template/components/Scrollbar';

import CopyToClipboard from 'react-copy-to-clipboard';
import { InfoIcon } from 'src/template/theme/overrides/CustomIcons';
import { fetchTransactions } from '../../services/fetchTransactions';

const HtmlTooltip = styled(({ className, ...props }: TooltipProps) => (
  <Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: '#f5f5f9',
    color: 'rgba(0, 0, 0, 0.87)',
    maxWidth: 350,
    fontSize: theme.typography.pxToRem(12),
    border: '1px solid #dadde9',
  },
}));

function dateFormatter(date: number) {
  const milliseconds = new Date(date).toISOString().match(/\.(\d{3})Z?/)?.[1];
  return `${formatUTCDate(date, { second: '2-digit' })}:${milliseconds}`;
}
export default function Pool() {
  const { id } = useParams<'id'>();

  const { data: pool } = fetchPoolData(id);
  const { data } = fetchTransactions({
    filter: {
      uuid: {
        eq: id!,
      },
    },
  });
  const poolWithAsset = data?.[0];

  const formattedInput = useMemo(
    () => JSON.stringify(pool?.input, null, 3),
    [pool]
  );

  const transactions = pool?.transactions ?? [];

  const {
    data: entityNotesData,
    isLoading: entityNotesIsLoading,
    params: entityNotesParams,
    handleUpdateEntityNote,
    handleDeleteEntityNote,
    handleCreateEntityNote,
  } = useEntityNotesTable({ entityUuid: id });

  return id && pool ? (
    <>
      <HeaderBreadcrumbs
        heading="Pool Details"
        links={[
          { name: 'Transactions', href: '/pools' },
          { name: 'Pool Details' },
        ]}
      />
      <SectionDivider section="Dates are in UTC timezone" />
      <Stack spacing={3}>
        <Card>
          <CardContent>
            <Flex flexWrap="wrap" gap={3}>
              <Stack direction="row" spacing={1}>
                <TypeSpecimen />
                <Typography>Type: {pool.type}</Typography>
              </Stack>
              <Stack direction="row" spacing={1} alignItems="center">
                <Circle fontSize="small" color={getLabelColor(pool.status)} />
                <Typography>Status: {pool.status}</Typography>
              </Stack>
              <Stack direction="row" spacing={1}>
                <CalendarMonth />
                <Typography>
                  Created Date: {formatUTCDate(pool.createdAt)}
                </Typography>
              </Stack>
              {poolWithAsset &&
                poolWithAsset?.assetType &&
                poolWithAsset?.asset && (
                  <Stack direction="row" spacing={1}>
                    <Editions fontSize="medium" />
                    <Typography>
                      {poolWithAsset.assetType.toUpperCase()}:{' '}
                      <Link
                        to={Routes[poolWithAsset.assetType](
                          poolWithAsset.asset.id
                        )}
                      >
                        {poolWithAsset.asset.name}
                      </Link>
                    </Typography>
                  </Stack>
                )}
            </Flex>
          </CardContent>
        </Card>
        {pool.input && (
          <Card>
            <Accordion>
              <AccordionSummary expandIcon={<ExpandMore />}>
                Transaction Pool Input
              </AccordionSummary>
              <Scrollbar>
                <AccordionDetails>
                  <pre>
                    <code>{formattedInput}</code>
                  </pre>
                </AccordionDetails>
              </Scrollbar>
            </Accordion>
          </Card>
        )}

        <MagicTable.Container>
          <MagicTable
            hideFooter
            rows={transactions}
            initialState={{
              sorting: {
                sortModel: [{ field: 'createdAt', sort: 'asc' }],
              },
            }}
            columns={[
              {
                field: 'type',
                headerName: 'Type',
              },
              {
                field: 'asset',
                headerName: 'Asset',
                flex: 0,
                renderCell({ row }) {
                  return <AssetCell transaction={row} />;
                },
              },
              {
                field: 'network',
                headerName: 'Network',
                flex: 0,
              },
              {
                field: 'globalWalletFrom',
                headerName: 'From',
                renderCell({ value }) {
                  return (
                    <Stack
                      direction="row"
                      alignItems="center"
                      justifyContent="flex-start"
                      spacing={1}
                    >
                      <Link to={Routes.user(value.user.uuid)}>
                        {value?.user?.name ||
                          value?.user?.slug ||
                          value?.user?.slug ||
                          getTruncatedText(value.user.uuid, 4, 3)}
                      </Link>

                      <HtmlTooltip
                        title={
                          <>
                            <Stack
                              direction="row"
                              alignItems="center"
                              justifyContent="space-between"
                            >
                              <Typography color="inherit">
                                Global Wallet info:
                              </Typography>
                              <CopyToClipboard text={JSON.stringify(value)}>
                                <MenuItem aria-label="copy feed uuid">
                                  <ContentCopy color="info" fontSize="small" />
                                </MenuItem>
                              </CopyToClipboard>
                            </Stack>
                            <br />
                            <Stack spacing={1}>
                              <Typography fontSize={12}>
                                <strong>UUID:</strong> {value.uuid}
                              </Typography>
                              <Typography fontSize={12}>
                                <strong>Created At:</strong>{' '}
                                {dateFormatter(value.createdAt)}
                              </Typography>
                              <Typography fontSize={12}>
                                <strong>Type:</strong>{' '}
                                {value.cryptoWalletUuid
                                  ? 'Crypto Wallet'
                                  : 'Sticky Wallet'}
                              </Typography>
                              {value.cryptoWalletUuid && (
                                <Typography fontSize={12}>
                                  <strong>Crypto Wallet UUID:</strong>{' '}
                                  {value.cryptoWalletUuid}
                                </Typography>
                              )}
                              {value.cryptoWallet && (
                                <>
                                  <Typography fontSize={12}>
                                    <strong>Address:</strong>{' '}
                                    {value.cryptoWallet.walletAddress}
                                  </Typography>
                                  <Typography fontSize={12}>
                                    <strong>Network:</strong>{' '}
                                    {value.cryptoWallet.network}
                                  </Typography>
                                  <Typography fontSize={12}>
                                    <strong>Is Synced?:</strong>{' '}
                                    {value.cryptoWallet.synced ? 'Yes' : 'No'}
                                  </Typography>
                                </>
                              )}
                              {value.walletUuid && (
                                <Typography fontSize={12}>
                                  <strong>Sticky Wallet UUID:</strong>{' '}
                                  {value.walletUuid}
                                </Typography>
                              )}
                            </Stack>
                          </>
                        }
                      >
                        <IconButton>
                          <InfoIcon color="info" />
                        </IconButton>
                      </HtmlTooltip>
                    </Stack>
                  );
                },
              },
              {
                field: 'globalWalletTo',
                headerName: 'To',
                renderCell({ value }) {
                  return (
                    <Stack
                      direction="row"
                      alignItems="center"
                      justifyContent="flex-start"
                      spacing={1}
                    >
                      <Link to={Routes.user(value.user.uuid)}>
                        {value?.user?.name ||
                          value?.user?.slug ||
                          value?.user?.slug ||
                          getTruncatedText(value.user.uuid, 4, 3)}
                      </Link>

                      <HtmlTooltip
                        title={
                          <>
                            <Stack
                              direction="row"
                              alignItems="center"
                              justifyContent="space-between"
                            >
                              <Typography color="inherit">
                                Global Wallet info:
                              </Typography>
                              <CopyToClipboard text={JSON.stringify(value)}>
                                <MenuItem aria-label="copy feed uuid">
                                  <ContentCopy color="info" fontSize="small" />
                                </MenuItem>
                              </CopyToClipboard>
                            </Stack>
                            <br />
                            <Stack spacing={1}>
                              <Typography fontSize={12}>
                                <strong>UUID:</strong> {value.uuid}
                              </Typography>
                              <Typography fontSize={12}>
                                <strong>Created At:</strong>{' '}
                                {dateFormatter(value.createdAt)}
                              </Typography>
                              <Typography fontSize={12}>
                                <strong>Type:</strong>{' '}
                                {value.cryptoWalletUuid
                                  ? 'Crypto Wallet'
                                  : 'Sticky Wallet'}
                              </Typography>
                              {value.cryptoWalletUuid && (
                                <Typography fontSize={12}>
                                  <strong>Crypto Wallet UUID:</strong>{' '}
                                  {value.cryptoWalletUuid}
                                </Typography>
                              )}
                              {value.cryptoWallet && (
                                <>
                                  <Typography fontSize={12}>
                                    <strong>Address:</strong>{' '}
                                    {value.cryptoWallet.walletAddress}
                                  </Typography>
                                  <Typography fontSize={12}>
                                    <strong>Network:</strong>{' '}
                                    {value.cryptoWallet.network}
                                  </Typography>
                                  <Typography fontSize={12}>
                                    <strong>Is Synced?:</strong>{' '}
                                    {value.cryptoWallet.synced ? 'Yes' : 'No'}
                                  </Typography>
                                </>
                              )}
                              {value.walletUuid && (
                                <Typography fontSize={12}>
                                  <strong>Sticky Wallet UUID:</strong>{' '}
                                  {value.walletUuid}
                                </Typography>
                              )}
                            </Stack>
                          </>
                        }
                      >
                        <IconButton>
                          <InfoIcon color="info" />
                        </IconButton>
                      </HtmlTooltip>
                    </Stack>
                  );
                },
              },
              {
                field: 'status',
                headerName: 'Status',
                flex: 0,
                renderCell({ value }) {
                  return <Label color={getLabelColor(value)}>{value}</Label>;
                },
              },
              {
                field: 'updatedAt',
                headerName: 'Updated At',
                valueFormatter({ value }) {
                  return dateFormatter(value);
                },
              },
              {
                field: 'createdAt',
                headerName: 'Created At',
                valueFormatter({ value }) {
                  return dateFormatter(value);
                },
              },
            ]}
          />
        </MagicTable.Container>
        <>
          <SectionDivider section="Entity Notes" />
          <EntityNotesTable
            showSearch={false}
            data={entityNotesData}
            isLoading={entityNotesIsLoading}
            paras={entityNotesParams}
            handleUpdate={handleUpdateEntityNote}
            handleDelete={handleDeleteEntityNote}
            handleCreate={handleCreateEntityNote(
              EntityNoteTypes.TRANSACTION_POOL,
              id
            )}
          />
        </>
      </Stack>
    </>
  ) : (
    <LoadingScreen />
  );
}

type AssetCellProps = {
  transaction: FetchPoolDataResponse['transactions'][number];
};
function AssetCell({ transaction }: AssetCellProps): JSX.Element {
  const { amount, assetType, assetUuid } = transaction;
  if (assetType === AvailableAssets.nft && assetUuid) {
    return (
      <Link to={Routes.nft(assetUuid)}>
        NFT: {getTruncatedText(assetUuid, 4, 3)}
      </Link>
    );
  }
  if (assetUuid) {
    if (assetType === AvailableAssets.nft) {
      return (
        <Link to={Routes.nft(assetUuid)}>
          NFT: {getTruncatedText(assetUuid, 4, 3)}
        </Link>
      );
    }
    if (assetType === AvailableAssets.collection) {
      return (
        <Link to={Routes.collection(assetUuid)}>
          Collection: {getTruncatedText(assetUuid, 4, 3)}
        </Link>
      );
    }
  }
  if (
    assetType === AvailableAssets.nft ||
    assetType === AvailableAssets.collection
  ) {
    return (
      <>
        {amount} {assetType}s
      </>
    );
  }

  if (assetType === AvailableAssets.nft_shard) {
    return (
      <Link to={Routes.nft(transaction.cardShard.nftUuid)}>
        {amount} Shard(s) of nft
      </Link>
    );
  }

  return <>{formatStickyCoinValue(amount)}</>;
}
