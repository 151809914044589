import { Card, CardContent, Container } from '@mui/material';
import GamesTable from 'src/components/modules/Games/components/GamesTable/GamesTable';
import { User } from 'src/lib/types/users';
import useUserGames from './useView';

interface UserGamesProps {
  user: User;
}

export default function UserGames({ user }: UserGamesProps): JSX.Element {
  const { data, isLoading, params, userUuid } = useUserGames();

  const dataWithOwner = data?.body.map((game) => ({
    ...game,
    owner: user,
  }));

  return (
    <Container maxWidth="xl">
      <Card>
        <CardContent>
          <GamesTable
            data={dataWithOwner || []}
            isLoading={isLoading}
            params={params}
            totalCount={data?.meta.totalCount || 0}
          />
        </CardContent>
      </Card>
    </Container>
  );
}
