import { useParams } from 'react-router';
import { CommonDateStrings } from 'src/lib/constants/CommonDates';
import { DEFAULT_INITIAL_PAGE_SIZE } from 'src/lib/constants/pagination';
import useURLSearchParams from 'src/lib/hooks/useURLSearchParams';
import { fetchGameRatings } from '../../services/fetchGameRatings';

export type FetchGameRatingsQueryParams = {
  page: number;
  size: number;
  startDate: string;
  endDate: string;
  userQuery: string;
  gameQuery: string;
  userSearchBy: string;
  gameSearchBy: string;
  orderBy: string;
  sortOrder: string;
};

const initialParams = {
  page: '0',
  size: DEFAULT_INITIAL_PAGE_SIZE.toString(),

  startDate: CommonDateStrings.sevenDaysAgo.dateTime.split('T')[0],
  endDate: CommonDateStrings.today.dateTime.split('T')[0],

  userQuery: '',
  userSearchBy: '',

  gameQuery: '',
  gameSearchBy: '',

  orderBy: 'createdAt',
  order: 'desc',
};

export function useGameRatings() {
  const { gameUuid, uuid: userUuid } = useParams<{
    gameUuid?: string;
    uuid?: string;
  }>();

  const { allSearchParams } = useURLSearchParams(initialParams);

  const params: FetchGameRatingsQueryParams = {
    size: +(allSearchParams?.size ?? initialParams.size),
    page: +(allSearchParams?.page ?? initialParams.page),

    startDate: allSearchParams.startDate ?? initialParams.startDate,
    endDate: allSearchParams.endDate ?? initialParams.endDate,

    userSearchBy: allSearchParams.userSearchBy ?? initialParams.userSearchBy,
    userQuery: allSearchParams.userQuery ?? initialParams.userQuery,

    gameSearchBy: allSearchParams.gameSearchBy ?? initialParams.gameSearchBy,
    gameQuery: allSearchParams.gameQuery ?? initialParams.gameQuery,

    orderBy: allSearchParams.orderBy ?? initialParams.orderBy,
    sortOrder: allSearchParams.order ?? initialParams.order,
  };

  const fetchData = {
    page: params.page,
    size: params.size,

    order: {
      [params.orderBy]: params.sortOrder,
    },

    filter: {
      createdAt: {
        ...(params.startDate && {
          gte: `${params.startDate}T00:00:00.000Z`,
        }),
        ...(params.endDate && {
          lte: `${params.endDate}T23:59:59.999Z`,
        }),
      },

      ...(gameUuid && {
        'gameVersion.gameUuid': gameUuid,
      }),

      ...(userUuid && {
        'user.uuid': {
          like: `${userUuid}`,
        },
      }),

      ...(params.userSearchBy &&
        params.userQuery && {
          [params.userSearchBy]: {
            like: `%${params.userQuery}%`,
          },
        }),

      ...(params.gameSearchBy &&
        params.gameQuery && {
          [params.gameSearchBy]: {
            like: `${params.gameQuery}`,
          },
        }),
    },
  };

  const { data, isLoading } = fetchGameRatings(fetchData);

  return {
    data,
    isLoading,
    params,
    gameUuid,
    userUuid,
  };
}
