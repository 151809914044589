import getAPIClient from 'src/lib/services/api/axios.config';
import { ServiceResponse } from 'src/lib/types/ServiceResponse';
import { AppVideoFeed } from 'src/lib/types/appVideoFeed';
import { getMessageFromError } from 'src/lib/utils/error';

export async function createAppVideoFeed(
  feedItemUuid: string
): Promise<ServiceResponse<AppVideoFeed>> {
  try {
    const api = await getAPIClient();
    const res = await api.post(`admin/app-video-feeds/`, { feedItemUuid });
    return {
      data: res.data,
      error: null,
    };
  } catch (e) {
    return {
      data: null,
      error: getMessageFromError(e),
    };
  }
}
