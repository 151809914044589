import { DeepPartial } from 'react-hook-form';
import { GameCenterReviewListRow } from 'src/lib/types/gameReview';
import { parseSearchParams } from 'src/lib/utils/parseSearchParams';
import useSWR, { SWRResponse } from 'swr';

export type PossibleParams = {
  page: number | string;
  size: number | string;
  order: Record<string, string>;
  filter: {
    createdAt: {
      gte: string;
      lte: string;
    };
  };
};

// ?filter[user.name][like]=osdfjisdfsdf

export type GameRatingsFetchResponse = {
  meta: { totalCount: number };
  body: GameCenterReviewListRow[];
};
export const fetchGameRatings = (
  params?: DeepPartial<PossibleParams>
): SWRResponse<GameRatingsFetchResponse, Error> => {
  const queryString = parseSearchParams(params ?? {});

  const response: SWRResponse<GameRatingsFetchResponse, Error> = useSWR<
    GameRatingsFetchResponse,
    Error
  >(`admin/review/game?${queryString}`);

  return response;
};
