import { SmartColumn } from 'src/components/shared/SmartTable/SmartTable';

export const transactionHistoryTableColumns: SmartColumn[] = [
  {
    id: 'type',
    label: 'Type',
    isSortable: false,
  },
  {
    id: 'amount',
    label: 'amount',
    isSortable: false,
  },
  {
    id: 'assetType',
    label: 'assetType',
    isSortable: false,
  },
  {
    id: 'see',
    label: 'View Asset',
    isSortable: false,
  },
  {
    id: 'from',
    label: 'From',
    isSortable: false,
  },
  {
    id: 'to',
    label: 'To',
    isSortable: false,
  },
  {
    id: 'status',
    label: 'Status',
    isSortable: false,
  },
  {
    id: 'pool',
    label: 'View Pool',
    isSortable: false,
  },
  {
    id: 'createdAt',
    label: 'Created At',
    isSortable: false,
    align: 'right',
  },
];
