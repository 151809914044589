import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from '@mui/material';
import Button from '../Buttons/CustomButton';

export interface ConfirmationDialogRawProps {
  keepMounted?: boolean;
  open: boolean;
  onClose: () => void;
  onConfirm: () => void;
  children: React.ReactNode;
  title: React.ReactNode;
  disableButtons?: boolean;
  invertColors?: boolean;
}

export default function ConfirmationDialogRaw(
  props: ConfirmationDialogRawProps
) {
  const {
    onClose,
    open,
    onConfirm,
    title,
    children,
    disableButtons,
    invertColors = true,
    ...rest
  } = props;

  const handleCancel = () => {
    onClose();
  };

  const handleOk = () => {
    onConfirm();
    onClose();
  };

  return (
    <Dialog
      sx={{ '& .MuiDialog-paper': { width: '80%', maxHeight: 435 } }}
      maxWidth="xs"
      open={open}
      {...rest}
    >
      <DialogTitle>{title}</DialogTitle>
      <DialogContent dividers>{children}</DialogContent>
      <DialogActions>
        <Button
          color={!invertColors ? 'error' : 'secondary'}
          autoFocus
          onClick={handleCancel}
          variant="contained"
          disabled={disableButtons}
        >
          Cancel
        </Button>
        <Button
          color={!invertColors ? 'secondary' : 'error'}
          variant="contained"
          onClick={handleOk}
          disabled={disableButtons}
        >
          Confirm
        </Button>
      </DialogActions>
    </Dialog>
  );
}
