import { DeepPartial } from 'react-hook-form';
import { PaginatedResponse } from 'src/lib/types/PaginatedResponse';
import { formatFractionNumber } from 'src/lib/utils/formatFractionNumber';
import formatStickyCoinValue from 'src/lib/utils/formatStickyCoinValue';
import { IncludeExcludeAll } from 'src/lib/utils/includeExcludeFilterUtils';
import { parseSearchParams } from 'src/lib/utils/parseSearchParams';
import useSWR, { SWRResponse } from 'swr';
import {
  BalanceRank,
  BalanceRankMeta,
  ParsedBalanceRank,
} from '../types/Ranking';
import { UserType } from '../types/UserType';

export type RankBy =
  | 'balance'
  | 'availableBonus'
  | 'availableBoughtCoins'
  | 'availableEarnings';
type PossibleParams = {
  page: number | string;
  size: number | string;
  rankBy: RankBy;
  order: string;
  userType: UserType;
  filter: {
    lang: string;
    country: string;
    InactiveUsers: IncludeExcludeAll;
  };
};

function parseData(
  data: PaginatedResponse<BalanceRank[], BalanceRankMeta> | undefined
): PaginatedResponse<ParsedBalanceRank[], BalanceRankMeta> {
  if (!data) {
    return {
      meta: {
        totalCount: 0,

        balanceTotalCounts: {
          totalBalance: 0,
          totalAvailableBonus: 0,
          totalAvailableBoughtCoins: 0,
          totalAvailableEarnings: 0,
          totalLockedBonus: 0,
          totalLockedBoughtCoins: 0,
          totalLockedEarnings: 0,
        },
      },
      body: [],
    };
  }

  return {
    meta: data.meta,
    body: data.body.map(
      ({
        availableBonus,
        availableBoughtCoins,
        availableEarnings,
        balance,
        lockedBonus,
        lockedBoughtCoins,
        lockedEarnings,
        ...rest
      }) => ({
        ...rest,
        availableBonus: formatFractionNumber(
          formatStickyCoinValue(availableBonus)
        ),
        availableBoughtCoins: formatFractionNumber(
          formatStickyCoinValue(availableBoughtCoins)
        ),
        availableEarnings: formatFractionNumber(
          formatStickyCoinValue(availableEarnings)
        ),
        lockedBonus: formatFractionNumber(formatStickyCoinValue(lockedBonus)),
        lockedBoughtCoins: formatFractionNumber(
          formatStickyCoinValue(lockedBoughtCoins)
        ),
        lockedEarnings: formatFractionNumber(
          formatStickyCoinValue(lockedEarnings)
        ),
        balance: formatFractionNumber(formatStickyCoinValue(balance)),
      })
    ),
  };
}

export function fetchBalanceRank(
  params?: DeepPartial<PossibleParams>
): SWRResponse<PaginatedResponse<ParsedBalanceRank[], BalanceRankMeta>> {
  const parsedParams = parseSearchParams(params ?? {});

  const { data, ...response } = useSWR(
    `admin/users/ranking/wallet?${parsedParams}`
  );

  return {
    ...response,
    data: parseData(data),
  };
}
