import React, { ReactElement } from 'react';
import { NFT } from 'src/lib/types/nft';
import { OBJECT_MODERATION_OBJECT_TYPE } from 'src/lib/types/objectModeration';
import Iconify, { IconifyProps } from 'src/template/components/Iconify';

const MainInfo = React.lazy(
  () => import('src/components/modules/NFT/view/MainInfo/index')
);
const ObjectModerationTab = React.lazy(
  () => import('src/components/modules/Review/components/ModerationObjectsTab')
);

export enum NFTTab {
  MAIN = 'main_info',
  MODERATION = 'moderation',
}

interface INFTTabs {
  value: NFTTab;
  icon: ReactElement<IconifyProps>;
  component: (Nft: NFT) => JSX.Element;
}

const NFT_TABS: INFTTabs[] = [
  {
    value: NFTTab.MAIN,
    icon: (
      <Iconify icon="material-symbols:info-outline" width={20} height={20} />
    ),
    component: (Nft) => <MainInfo collection={Nft.collection} nft={Nft} />,
  },
  {
    value: NFTTab.MODERATION,
    icon: <Iconify icon="mdi:shield-account" width={20} height={20} />,
    component: (Nft) => (
      <ObjectModerationTab
        objectType={OBJECT_MODERATION_OBJECT_TYPE.NFT}
        objectUuid={Nft?.uuid!}
        showHeader
      />
    ),
  },
];

export default NFT_TABS;
