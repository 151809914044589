import { ContentCopy, OpenInNew } from '@mui/icons-material';
import { Box, IconButton, MenuItem } from '@mui/material';
import { GridSortDirection } from '@mui/x-data-grid';
import CopyToClipboard from 'react-copy-to-clipboard';
import MagicTable from 'src/components/shared/MagicTable/MagicTable';
import MagicTableUserCell from 'src/components/shared/MagicTable/UserCell';
import MoreMenu from 'src/components/shared/MoreMenu';
import useURLSearchParams from 'src/lib/hooks/useURLSearchParams';
import { GameAsset } from 'src/lib/types/asset';
import { ContestEventRankingPositionResult } from 'src/lib/types/gameRanking';
import { formatMilliseconds } from 'src/lib/utils/formatMilliseconds';
import { formatUTCDate } from 'src/lib/utils/formatUTCDate';
import Label from 'src/template/components/Label';
import { FetchContestEventRankingsQueryParams } from '../../view/ContestEventRankings/useView';

type ContestEventRankingsTableProps = {
  data?: ContestEventRankingPositionResult[];
  isLoading: boolean;
  params: FetchContestEventRankingsQueryParams;
  handleOpenListAssetsUsed: (gameAssetsUsed: GameAsset[]) => void;
};

export default function ContestEventRankingsTable({
  data,
  isLoading,
  params: { page, size, orderBy, sortOrder },
  handleOpenListAssetsUsed,
}: ContestEventRankingsTableProps) {
  const { addParam } = useURLSearchParams();

  return (
    <MagicTable.Container>
      <Box p={2}>
        <MagicTable
          getRowId={(row) => row.gameMatchUuid}
          loading={isLoading}
          pageSize={size}
          page={page}
          paginationMode="server"
          onPageChange={(newPage) => {
            addParam('page', newPage);
          }}
          onPageSizeChange={(newSize) => {
            addParam('size', newSize);
          }}
          onSortModelChange={(model) => {
            const sortModel = model[0];
            addParam('orderBy', sortModel.field);
            addParam('order', sortModel.sort!);
          }}
          sortModel={[
            {
              field: orderBy,
              sort: sortOrder as GridSortDirection,
            },
          ]}
          rows={data ?? []}
          hideNumberOfRows
          columns={[
            {
              field: 'position',
              headerName: 'Position',
              flex: 0,
              sortable: false,
            },
            {
              field: 'user',
              headerName: 'User',
              renderCell: ({ row }) => <MagicTableUserCell user={row.user} />,
              sortable: false,
            },
            {
              field: 'isUserPro',
              headerName: 'Has Pro',
              sortable: false,
              flex: 0,
              renderCell: ({ row }) => (
                <Label
                  color={row.isUserPro ? 'success' : 'error'}
                  sx={{ flex: 1 }}
                >
                  {row.isUserPro ? 'Yes' : 'No'}
                </Label>
              ),
            },
            {
              field: 'total_value',
              headerName: 'Total Value',
              sortable: false,
            },
            {
              field: 'totalDuration',
              headerName: 'Total Duration',
              sortable: false,
              valueFormatter: ({ value }) =>
                formatMilliseconds(value as number),
            },
            {
              field: 'gameMatchDate',
              headerName: 'Match Date',
              sortable: false,
              valueFormatter: ({ value }) => formatUTCDate(value),
            },
            {
              field: 'gameAssetsUsed',
              headerName: 'Assets Used',
              sortable: false,
              flex: 0,
              renderCell({ row }) {
                return (
                  <IconButton
                    onClick={() => {
                      handleOpenListAssetsUsed(
                        row.gameAssetsUsed.map(
                          (
                            asset: { name: string; type: string },
                            index: number
                          ) => ({
                            uuid: index,
                            gameUuid: '',
                            name: asset.name,
                            description: '',
                            skillTypeUuid: '',
                            assetType: {
                              uuid: '',
                              name: asset.type,
                              description: '',
                            },
                          })
                        )
                      );
                    }}
                  >
                    <OpenInNew />
                  </IconButton>
                );
              },
            },
            {
              field: 'actions',
              headerName: 'Actions',
              align: 'center',
              sortable: false,
              flex: 0,
              renderCell({ row }) {
                return (
                  <MoreMenu>
                    <CopyToClipboard text={row.gameMatchUuid}>
                      <MenuItem aria-label="copy feed uuid">
                        <ContentCopy />
                        Copy Match Uuid
                      </MenuItem>
                    </CopyToClipboard>
                  </MoreMenu>
                );
              },
            },
          ]}
        />
      </Box>
    </MagicTable.Container>
  );
}
