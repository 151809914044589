import { ContentCopy, Delete, FlashOn, PlayCircle } from '@mui/icons-material';
import {
  Avatar,
  Checkbox,
  Link,
  MenuItem,
  Stack,
  TableCell,
  TableRow,
  Tooltip,
} from '@mui/material';
import { useState } from 'react';
import CopyToClipboard from 'react-copy-to-clipboard';
import { Link as RouterLink } from 'react-router-dom';
import {
  PurgeUseCaseStatuses,
  UserPurge,
  UserPurgeStatus,
} from 'src/components/modules/Profile/types/UserPurge';
import MoreMenu from 'src/components/shared/MoreMenu';
import useNotify from 'src/lib/hooks/useNotify';
import { User } from 'src/lib/types/users';
import { formatUTCDate } from 'src/lib/utils/formatUTCDate';
import Routes from 'src/routes/Routes';
import Label from 'src/template/components/Label';
import { DeletePendingPurge } from '../../services/DelelePendingPurge';
import { PurgeUser } from '../../services/PurgeUser';
import { useFetchUserPurgeStatus } from '../../services/useFetchUserPurgeStatus';

// const highlightStyle = {
//   backgroundColor: '#FC6600',
//   color: 'white',
// };

// function searchFormatter(
//   text: string,
//   colName: string,
//   searchHighlight: {
//     searchColumn: string;
//     searchKeyword: string;
//   }
// ) {
//   return searchHighlight.searchColumn === colName ? (
//     <Highlighter
//       searchWords={[searchHighlight.searchKeyword]}
//       textToHighlight={text}
//       highlightStyle={highlightStyle}
//     />
//   ) : (
//     text
//   );
// }

const getColorByPurgeStatus = (status: UserPurgeStatus) => {
  switch (status) {
    case UserPurgeStatus.completed:
      return 'success';
    case UserPurgeStatus.running:
      return 'info';
    case UserPurgeStatus.pending:
      return 'warning';
    case UserPurgeStatus.failed:
      return 'error';
    default:
      return 'primary';
  }
};

const getUserIdentifier = (user: User | undefined) => {
  if (!user) return 'Unknown';
  if (user.slug) return `@${user.slug}`;
  if (user.name) return user.name;
  if (user.email) return user.email;
  return `with UUID: ${user.uuid}`;
};

type UserPurgeTableRowProps = {
  userPurge: UserPurge;
  searchHighlight: { searchColumn: string; searchKeyword: string };
  handleSelect: (uuid: string) => void;
  isSelected: boolean;
};

export default function UserPurgeTableRow({
  userPurge,
  handleSelect,
  isSelected,
}: UserPurgeTableRowProps) {
  const notify = useNotify();

  const [isBeingRemoved, setIsBeingRemoved] = useState(false);

  const { data: purgeStatus } = useFetchUserPurgeStatus();

  const allowActionsWithPurges =
    purgeStatus?.status === PurgeUseCaseStatuses.stopped;

  const canBeUserRemoved =
    userPurge.status === UserPurgeStatus.pending &&
    allowActionsWithPurges &&
    !isBeingRemoved;
  const canBePurged =
    userPurge.status === UserPurgeStatus.pending &&
    allowActionsWithPurges &&
    !isBeingRemoved;

  const canBeSelected =
    userPurge.status === UserPurgeStatus.pending &&
    allowActionsWithPurges &&
    !isBeingRemoved;

  const handleRemoveFromList = async () => {
    setIsBeingRemoved(true);
    if (isSelected) handleSelect(userPurge.uuid);
    const { error } = await DeletePendingPurge(userPurge.uuid);
    if (error) {
      notify(error, { variant: 'error' });
    } else {
      notify('User removed from purge list successfully', {
        variant: 'success',
      });
    }
    setIsBeingRemoved(false);
  };

  const handlePurgeUser = async () => {
    const { error } = await PurgeUser(userPurge.uuid);
    if (error) {
      notify(error, { variant: 'error' });
    } else {
      notify('User purged successfully', { variant: 'success' });
    }
  };

  const getSelectTooltipMessage = () => {
    if (userPurge.status !== UserPurgeStatus.pending) {
      return 'Cannot select user with status other than pending';
    }

    if (purgeStatus?.status !== PurgeUseCaseStatuses.stopped) {
      return 'Cannot select user while purge is running';
    }

    if (isPurging) {
      return 'User is being purged...';
    }

    return '';
  };

  const isPurging =
    userPurge.status === UserPurgeStatus.running &&
    purgeStatus?.currentPurge?.uuid === userPurge.uuid;

  return (
    <TableRow
      key={userPurge.uuid}
      hover
      sx={{ backgroundColor: isPurging ? '#dbedff' : 'inherit' }}
    >
      <TableCell align="center">
        {isPurging && (
          <Tooltip title={getSelectTooltipMessage()} arrow>
            <span>
              <PlayCircle color="info" />
            </span>
          </Tooltip>
        )}
        {!isPurging && (
          <Tooltip title={getSelectTooltipMessage()} arrow>
            <span>
              <Checkbox
                disabled={!canBeSelected}
                checked={isSelected}
                onChange={(e) => {
                  e.stopPropagation();
                  e.preventDefault();
                  handleSelect(userPurge.uuid);
                }}
              />
            </span>
          </Tooltip>
        )}
      </TableCell>
      <TableCell>
        <Label
          color={getColorByPurgeStatus(userPurge.status)}
          sx={{ textTransform: 'capitalize' }}
        >
          {userPurge.status}
        </Label>
      </TableCell>
      <TableCell>
        <Stack direction="row" spacing={1} alignItems="center">
          {userPurge.user && (
            <Avatar
              alt={getUserIdentifier(userPurge.user)}
              src={userPurge.user?.profileImage || ''}
              sx={{ mr: 2 }}
            />
          )}
          <Link
            component={RouterLink}
            to={Routes.user(userPurge.userUuid)}
            color={userPurge.user?.name ? 'inherit' : 'text.secondary'}
            underline="hover"
            display="inline-block"
          >
            {getUserIdentifier(userPurge.user)}
          </Link>
        </Stack>
      </TableCell>
      <TableCell>
        <Stack direction="row" spacing={1} alignItems="center">
          <Link
            component={RouterLink}
            to={Routes.user(userPurge.createdByUserUuid)}
            color={userPurge.createdByUser?.name ? 'inherit' : 'text.secondary'}
            underline="hover"
            display="inline-block"
          >
            {getUserIdentifier(userPurge.createdByUser)}
          </Link>
        </Stack>
      </TableCell>

      <TableCell>
        <Stack direction="row" spacing={1} alignItems="center">
          {userPurge.triggeredByUser ? (
            <Link
              component={RouterLink}
              to={Routes.user(userPurge.triggeredByUserUuid)}
              color={
                userPurge.triggeredByUser?.name ? 'inherit' : 'text.secondary'
              }
              underline="hover"
              display="inline-block"
            >
              {getUserIdentifier(userPurge.triggeredByUser)}
            </Link>
          ) : (
            '--'
          )}
        </Stack>
      </TableCell>
      <TableCell align="right">
        {userPurge.startedAt ? formatUTCDate(+userPurge.startedAt) : '--'}
      </TableCell>
      <TableCell align="right">
        {userPurge.completedAt ? formatUTCDate(+userPurge.completedAt) : '--'}
      </TableCell>
      <TableCell align="right">{formatUTCDate(+userPurge.updatedAt)}</TableCell>
      <TableCell align="right">{formatUTCDate(+userPurge.createdAt)}</TableCell>
      <TableCell align="center">
        <MoreMenu>
          <CopyToClipboard text={userPurge.uuid}>
            <MenuItem>
              <ContentCopy />
              Copy UUID
            </MenuItem>
          </CopyToClipboard>

          {canBePurged && (
            <MenuItem onClick={handlePurgeUser}>
              <FlashOn />
              Purge User
            </MenuItem>
          )}

          {canBeUserRemoved && (
            <MenuItem onClick={handleRemoveFromList}>
              <Delete />
              Remove From List
            </MenuItem>
          )}
        </MoreMenu>
      </TableCell>
    </TableRow>
  );
}
