import useSWR from 'swr';
import { PaginatedResponse } from '../types/PaginatedResponse';
import { BracketFilterParams } from '../types/bracketFilterParams';
import { Feedback } from '../types/feedback';
import { WithUser } from '../types/users';
import generateSearchRequest from '../utils/generateSearchRequest';

export const fetchFeedbacks = (filter: BracketFilterParams) => {
  const { data, error } = useSWR<
    PaginatedResponse<WithUser<Feedback>[]>,
    Error
  >(`admin/feedbacks/list${generateSearchRequest(filter)}`);

  return {
    data: data?.body,
    total: data?.meta.totalCount ?? 0,
    loading: !error && !data,
    error: error?.message,
  };
};
