import { Card, CardContent, Tab, Tabs } from '@mui/material';
import { UserChannelRelations } from 'src/components/modules/Profile/sections/Channels/types';
import { MODERATED_OBJECT_STATUS_TABS } from 'src/components/modules/Review/utils/utils';
import Flex from 'src/components/shared/Flex';
import HeaderBreadcrumbs from 'src/template/components/HeaderBreadcrumbs';
import ChannelsTable from '../../components/Table';
import useChannelsView from './useView';

const STATUS_TABS = MODERATED_OBJECT_STATUS_TABS;

interface ChannelsProps {
  userUuid?: string;
  relation?: UserChannelRelations;
  showHeader?: boolean;
}

export default function Channels({
  showHeader = true,
  userUuid,
  relation,
}: ChannelsProps) {
  const { data, isLoading, params, currentTab, onChangeTab } = useChannelsView({
    userUuid,
    relation,
  });

  return (
    <>
      {showHeader && (
        <HeaderBreadcrumbs heading="Channels" links={[{ name: 'Channels' }]} />
      )}
      <Card>
        <Flex sx={{ px: 2, bgcolor: 'background.neutral' }}>
          <Tabs
            allowScrollButtonsMobile
            variant="scrollable"
            scrollButtons="auto"
            value={currentTab}
            onChange={onChangeTab}
          >
            {STATUS_TABS.map((tab) => (
              <Tab
                disableRipple
                key={tab.value}
                label={tab.label}
                value={tab.value}
              />
            ))}
          </Tabs>
        </Flex>

        <CardContent>
          <ChannelsTable
            data={data ?? []}
            isLoading={isLoading}
            params={params}
          />
        </CardContent>
      </Card>
    </>
  );
}
