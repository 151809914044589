import { useCallback, useEffect } from 'react';
import { DEFAULT_INITIAL_PAGE_SIZE } from 'src/lib/constants/pagination';
import useURLSearchParams from 'src/lib/hooks/useURLSearchParams';
import useModalStore from 'src/lib/stores/useModalStore';
import useTabs from 'src/template/hooks/useTabs';
import { CoinEmitter } from '../../events/CoinEmmiter';
import { fetchCoinBlockedExpirations } from '../../services/fetchCoinBlockedExpirations';
import { fetchCoinBreakdown } from '../../services/fetchCoinBreakdown';
import { fetchCoinExpired } from '../../services/fetchCoinExpired';
import { fetchCoinExpiring } from '../../services/fetchCoinExpiring';
import { fetchCoinFailedExpirations } from '../../services/fetchCoinFailedExpirations';
import {
  CoinTypes,
  ExpiringCoinsDateFilterRanges,
  ExpiringCoinsTabs,
} from '../../types/CoinExpiration';
import ExpireCoinsView from '../Expire';

export default function useExpiringView() {
  const openModal = useModalStore((state) => state.openModal);
  const { currentTab: expirationType, onChangeTab: onChangeExpirationType } =
    useTabs('next-expiring', {
      queryName: 'expirationType',
    });

  const { allSearchParams } = useURLSearchParams({
    sortBy: 'expiresAt',
    order: 'desc',
    page: '0',
    size: DEFAULT_INITIAL_PAGE_SIZE.toString(),
    searchBy: 'user.slug',

    dateFilter: ExpiringCoinsDateFilterRanges.OneYear,
    coinTypeFilter: 'all',
  });
  const page = Number(allSearchParams.page);
  const size = Number(allSearchParams.size);

  const query: any = {};
  if (allSearchParams.searchBy && allSearchParams.query) {
    query[allSearchParams.searchBy] = {
      like: `%${allSearchParams.query}%`,
    };
  }

  const fetchParams = {
    dateFilter: allSearchParams.dateFilter as ExpiringCoinsDateFilterRanges,
    coinTypeFilter: allSearchParams.coinTypeFilter as CoinTypes,
    page,
    size,
    expirationTypeFilter: expirationType as ExpiringCoinsTabs,
    filter: {
      ...(allSearchParams.coinTypeFilter !== 'all' && {
        type: allSearchParams.coinTypeFilter as CoinTypes,
      }),
    },
    ...(allSearchParams.sortBy && {
      order: {
        [allSearchParams.sortBy]: allSearchParams.order,
      },
    }),
  };

  const fetchFunctions = {
    [ExpiringCoinsTabs.NextExpiring]: fetchCoinExpiring,
    [ExpiringCoinsTabs.LatestExpired]: fetchCoinExpired,
    [ExpiringCoinsTabs.Failed]: fetchCoinFailedExpirations,
    [ExpiringCoinsTabs.Blocked]: fetchCoinBlockedExpirations,
  };

  const { data, isLoading, mutate } =
    fetchFunctions[expirationType! as ExpiringCoinsTabs](fetchParams);

  const refetchData = useCallback(() => {
    mutate?.();
  }, []);

  useEffect(() => {
    CoinEmitter.on('CoinExpired', refetchData);

    return () => {
      CoinEmitter.off('CoinExpired', refetchData);
    };
  }, [refetchData]);

  const { data: breakdownData } = fetchCoinBreakdown();

  breakdownData?.push(
    breakdownData?.reduce(
      (acc, bd) => ({
        type: 'all',
        total_expired: String(
          Number(acc.total_expired) + Number(bd.total_expired)
        ),
        expiring_one_day: String(
          Number(acc.expiring_one_day) + Number(bd.expiring_one_day)
        ),
        expiring_one_week: String(
          Number(acc.expiring_one_week) + Number(bd.expiring_one_week)
        ),
        expiring_one_month: String(
          Number(acc.expiring_one_month) + Number(bd.expiring_one_month)
        ),
        expiring_one_year: String(
          Number(acc.expiring_one_year) + Number(bd.expiring_one_year)
        ),
        expiring_more_than_one_year: String(
          Number(acc.expiring_more_than_one_year) +
            Number(bd.expiring_more_than_one_year)
        ),
      }),
      {
        type: 'all',
        total_expired: '0',
        expiring_one_day: '0',
        expiring_one_week: '0',
        expiring_one_month: '0',
        expiring_one_year: '0',
        expiring_more_than_one_year: '0',
      }
    )
  );

  async function handleOpenFilters() {
    const ExpiringFilters = (await import('../../components/Expiring/Filters'))
      .default;
    openModal(<ExpiringFilters />);
  }

  const handleOpenExpireModal = async (coinUuid: string) => {
    openModal(<ExpireCoinsView coinUuid={coinUuid} />);
  };

  return {
    data,
    breakdownData,
    isLoading,
    page,
    size,
    order: allSearchParams.order,
    sortBy: allSearchParams.sortBy,
    searchBy: allSearchParams.searchBy,
    query: allSearchParams.query,
    expirationType,
    coinType: allSearchParams.coinTypeFilter,
    onChangeExpirationType,
    handleOpenFilters,
    handleOpenExpireModal,
  };
}
