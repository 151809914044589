import { ContentCopy, Delete, Edit, OpenInNew } from '@mui/icons-material';
import { IconButton, MenuItem } from '@mui/material';
import { capitalCase } from 'change-case';
import CopyToClipboard from 'react-copy-to-clipboard';
import MagicTable from 'src/components/shared/MagicTable/MagicTable';
import MoreMenu from 'src/components/shared/MoreMenu';
import useURLSearchParams from 'src/lib/hooks/useURLSearchParams';
import { GameStatistic } from 'src/lib/types/gameStatistic';
import {
  GameStatisticGroup,
  StatisticGroupTypes,
} from 'src/lib/types/statisticGroup';
import { formatUTCDate } from 'src/lib/utils/formatUTCDate';
import Label from 'src/template/components/Label';
import { FetchGameStatisticGroupsQueryParams } from '../../view/List/useView';

interface StatisticGroupsTableProps {
  data: GameStatisticGroup[];
  isLoading: boolean;

  params: FetchGameStatisticGroupsQueryParams;

  handleOpenUpdateStatisticGroupModal: (
    statisticGroup: GameStatisticGroup
  ) => void;
  handleDeleteStatisticGroup: (statisticGroupUuid: string) => void;
  handleOpenListGroupStatistics: (statistics: GameStatistic[]) => void;
}

export default function StatisticGroupsTable({
  data,
  isLoading,
  params,
  handleOpenUpdateStatisticGroupModal,
  handleDeleteStatisticGroup,
  handleOpenListGroupStatistics,
}: StatisticGroupsTableProps): JSX.Element {
  const { addParam, removeParam } = useURLSearchParams();

  return (
    <MagicTable
      rows={data}
      getRowId={(r) => r.uuid}
      loading={isLoading}
      paginationMode="client"
      pageSize={params.size}
      page={params.page}
      onPageChange={(newPage) => {
        addParam('page', newPage);
      }}
      onPageSizeChange={(newSize) => {
        addParam('size', newSize);
      }}
      onSortModelChange={(model) => {
        const currentModel = model[0];
        if (currentModel) {
          addParam('sortBy', currentModel.field);
          if (currentModel.sort) addParam('order', currentModel.sort);
        } else {
          removeParam('order');
          removeParam('sortBy');
        }
      }}
      columns={[
        {
          field: 'name',
          headerName: 'Name',
          sortable: true,
        },
        {
          field: 'type',
          headerName: 'Type',
          sortable: true,
          valueFormatter({ value }) {
            return capitalCase(value);
          },
        },
        {
          field: 'Type Info',
          headerName: 'Type Info',
          sortable: false,
          renderCell({ row }) {
            return row.type == StatisticGroupTypes.ASSET_GROUP ? (
              <Label flex={1}>
                {`${row.assetGroup?.assetType?.name} (${row.assetGroup?.assetAction?.name})`}
              </Label>
            ) : (
              <IconButton
                onClick={() => {
                  handleOpenListGroupStatistics(row.statisticsList.statistics);
                }}
              >
                <OpenInNew />
              </IconButton>
            );
          },
        },
        {
          field: 'createdAt',
          headerName: 'Created At',
          sortable: true,
          valueFormatter({ value }) {
            return formatUTCDate(value);
          },
        },
        {
          field: 'updatedAt',
          headerName: 'Updated At',
          sortable: true,
          valueFormatter({ value }) {
            return formatUTCDate(value);
          },
        },
        {
          field: 'actions',
          headerName: 'Actions',
          align: 'center',
          sortable: false,
          flex: 0,
          renderCell({ row }) {
            return (
              <MoreMenu>
                <CopyToClipboard text={row.uuid}>
                  <MenuItem aria-label="copy feed uuid">
                    <ContentCopy />
                    Copy UUID
                  </MenuItem>
                </CopyToClipboard>
                <MenuItem
                  onClick={() => {
                    handleOpenUpdateStatisticGroupModal(row);
                  }}
                >
                  <Edit />
                  Update
                </MenuItem>
                <MenuItem
                  onClick={() => {
                    handleDeleteStatisticGroup(row.uuid);
                  }}
                >
                  <Delete />
                  Delete
                </MenuItem>
              </MoreMenu>
            );
          },
        },
      ]}
    />
  );
}
