import {
  Autocomplete,
  Avatar,
  Box,
  InputAdornment,
  TextField,
} from '@mui/material';
import { capitalCase } from 'change-case';
import { debounce } from 'lodash';
import { ReactNode } from 'react';
import { UserType } from 'src/components/modules/Users/types/UserType';
import Flex from 'src/components/shared/Flex';
import Link from 'src/components/shared/Link';
import MagicTable from 'src/components/shared/MagicTable/MagicTable';
import useMagicTable from 'src/components/shared/MagicTable/useMagicTable';
import SectionDivider from 'src/components/shared/SectionDivider';
import SelectOption from 'src/components/shared/SelectOption';
import { DEFAULT_INITIAL_PAGE_SIZE } from 'src/lib/constants/pagination';
import useURLSearchParams from 'src/lib/hooks/useURLSearchParams';
import { LabelValue } from 'src/lib/types/labelValue';
import { formatUTCDate } from 'src/lib/utils/formatUTCDate';
import Routes from 'src/routes/Routes';
import Iconify from 'src/template/components/Iconify';
import { fetchUsersWithWalletInconsistencies } from '../../services/fetchUsersWithWalletInconsistencies';

export default function WalletsConsistencyTableView(): JSX.Element {
  const magicTableProps = useMagicTable();

  const { addParam, removeParam, allSearchParams } =
    useURLSearchParams(initialParams);
  const size = +(allSearchParams?.size ?? initialParams.size);
  const page = +(allSearchParams?.page ?? initialParams.page);
  const orderBy = allSearchParams?.orderBy ?? initialParams.orderBy;
  const sortOrder = allSearchParams?.order ?? initialParams.order;
  const searchBy = allSearchParams?.searchBy ?? initialParams.searchBy;

  const {
    data: users,
    error,
    isLoading,
  } = fetchUsersWithWalletInconsistencies({
    page,
    size,
    order: {
      [orderBy]: sortOrder,
    },
    filter: {
      ...(searchBy &&
        allSearchParams?.query && {
          [searchBy]: {
            like: `%${allSearchParams.query}%`,
          },
        }),
    },
  });

  function handleChangeSearch(value: string, param: string = 'query') {
    if (!value) removeParam(param);
    else addParam(param, value);
  }

  const debouncedSearch = debounce(handleChangeSearch, 1000);

  const InputBySearchBy = getInputBySearchBy(
    debouncedSearch,
    handleChangeSearch
  );

  return (
    <>
      <SectionDivider section="Dates are in UTC timezone" />
      <MagicTable.Container>
        <Flex gap={2} p={2}>
          <Autocomplete
            options={SEARCH_OPTIONS}
            fullWidth
            renderInput={(renderParams) => (
              <TextField label="Search By" {...renderParams} />
            )}
            getOptionLabel={(p) => p.label}
            sx={{ maxWidth: 250 }}
            defaultValue={SEARCH_OPTIONS.find(
              (o) => o.value === allSearchParams.searchBy
            )}
            key={allSearchParams.searchBy}
            onChange={(_, value) => {
              if (!value) removeParam('searchBy');
              else addParam('searchBy', value.value);
            }}
          />
          {allSearchParams?.searchBy &&
          InputBySearchBy?.[allSearchParams.searchBy] ? (
            InputBySearchBy[allSearchParams.searchBy]
          ) : (
            <TextField
              fullWidth
              label="Search"
              defaultValue={allSearchParams.query}
              type="search"
              onChange={(e) => {
                const { value } = e.target;
                debouncedSearch(value);
              }}
              key={allSearchParams.query}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <Iconify
                      icon="eva:search-fill"
                      sx={{ color: 'text.disabled', width: 20, height: 20 }}
                    />
                  </InputAdornment>
                ),
              }}
            />
          )}
        </Flex>

        <Box px={2}>
          <MagicTable
            loading={isLoading}
            pageSize={size}
            page={page}
            paginationMode="server"
            onPageChange={(newPage) => {
              addParam('page', newPage);
            }}
            onPageSizeChange={(newPageSize) => {
              addParam('size', newPageSize);
            }}
            onSortModelChange={(model) => {
              const sortModel = model[0];
              if (sortModel && sortModel.field && sortModel.sort) {
                addParam('orderBy', sortModel.field);
                addParam('order', sortModel.sort!);
              }
            }}
            rows={users ?? []}
            hideNumberOfRows
            columns={[
              {
                field: 'name',
                headerName: 'Name',
                sortable: true,
                renderCell({ row }) {
                  return (
                    <>
                      <Avatar
                        alt={row?.name ?? 'user profile picture'}
                        src={row?.profileImage ?? ''}
                        sx={{ mr: 2 }}
                      />

                      <Link
                        to={Routes.user(row.uuid)}
                        color={row?.name ? 'inherit' : 'GrayText'}
                        underline="hover"
                      >
                        {row?.name || 'empty'}
                      </Link>
                    </>
                  );
                },
              },
              {
                field: 'slug',
                headerName: 'Slug',
                sortable: true,
              },
              {
                field: 'email',
                headerName: 'Email',
                sortable: true,
              },
              {
                field: 'wallet.updatedAt',
                headerName: 'Wallet Updated At',
                sortable: true,
                valueFormatter({ value }) {
                  return value ? formatUTCDate(value) : '-';
                },
              },
              {
                field: 'wallet.walletInconsistency.incosistentValues',
                headerName: 'Inconsistent Values',
                sortable: false,
                valueFormatter({ value }) {
                  return value ? value.join(', ') : '-';
                },
              },
            ]}
          />
        </Box>
      </MagicTable.Container>
    </>
  );
}

const SEARCH_OPTIONS: LabelValue[] = [
  {
    label: 'Slug',
    value: 'slug',
  },
  {
    label: 'Name',
    value: 'name',
  },
  {
    label: 'User Type',
    value: 'userType',
  },
  {
    label: 'Email',
    value: 'email',
  },
  {
    label: 'UUID',
    value: 'uuid',
  },
  {
    label: 'Auth ID',
    value: 'authId',
  },
];

const getInputBySearchBy = (
  debouncedFilter: (value: string, param: string) => void,
  handleChangeSearch: (value: string, param?: string) => void
): Record<string, ReactNode> => ({
  lastSession: (
    <Flex gap={2} width="100%">
      <TextField
        fullWidth
        type="date"
        label="Start Date"
        InputLabelProps={{ shrink: true }}
        onChange={(e) => {
          const { value } = e.target;
          debouncedFilter(value ? `${value}T00:00:00` : '', 'startDate');
        }}
      />
      <TextField
        fullWidth
        type="date"
        label="End Date"
        InputLabelProps={{ shrink: true }}
        onChange={(e) => {
          const { value } = e.target;
          debouncedFilter(value ? `${value}T23:59:59` : '', 'endDate');
        }}
      />
    </Flex>
  ),
  userType: (
    <TextField
      fullWidth
      select
      onChange={(e) => {
        const { value } = e.target;
        handleChangeSearch(value);
      }}
    >
      {Object.values(UserType).map((type) => (
        <SelectOption value={type} key={type}>
          {capitalCase(type)}
        </SelectOption>
      ))}
    </TextField>
  ),
});

const initialParams = {
  page: '0',
  size: DEFAULT_INITIAL_PAGE_SIZE.toString(),
  order: 'desc',
  orderBy: 'lastSession',
  searchBy: 'slug',
};
