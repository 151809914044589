import { GridSortDirection, GridSortModel } from '@mui/x-data-grid';
import { DEFAULT_INITIAL_PAGE_SIZE } from 'src/lib/constants/pagination';
import useURLSearchParams from 'src/lib/hooks/useURLSearchParams';

export default function useMagicTable() {
  const { addParam, allSearchParams, removeParam } = useURLSearchParams();
  const { orderBy, order } = allSearchParams;
  const page = Number(allSearchParams.page ?? 0);
  const size = Number(allSearchParams.size ?? DEFAULT_INITIAL_PAGE_SIZE);
  const sortModel: GridSortModel | undefined =
    orderBy && order
      ? [{ field: orderBy, sort: order as GridSortDirection }]
      : undefined;

  const onPageChange = (newPage: number) => {
    addParam('page', newPage);
  };
  const onPageSizeChange = (newSize: number) => {
    addParam('size', newSize);
  };

  const onSortModelChange = (model: GridSortModel) => {
    const currentModel = model[0];
    if (currentModel && currentModel.sort) {
      addParam('orderBy', currentModel.field);
      addParam('order', currentModel.sort);
    } else {
      removeParam('orderBy');
      removeParam('order');
    }
  };

  return {
    onPageChange,
    onSortModelChange,
    onPageSizeChange,
    page,
    pageSize: size,
    sortModel,
  };
}
