export enum UserType {
  STICKY_MANAGED = 'sticky_managed',
  TEST_USER = 'test_user',
  STICKY_TEAM_MEMBER = 'sticky_team_member',
  FORMER_STICKY_MEMBER = 'former_sticky_member',
  RELATED_TO_STICKY_MEMBER = 'related_to_sticky_member',
  REGULAR_USER = 'regular_user',
}

export const UserTypeLabel: Record<UserType, string> = {
  [UserType.FORMER_STICKY_MEMBER]: 'Former Sticky Member',
  [UserType.REGULAR_USER]: 'Regular User',
  [UserType.RELATED_TO_STICKY_MEMBER]: 'Related To Sticky Member',
  [UserType.STICKY_MANAGED]: 'Sticky Managed User',
  [UserType.STICKY_TEAM_MEMBER]: 'Sticky Team Member',
  [UserType.TEST_USER]: 'Test User',
};
