import { DeepPartial } from 'react-hook-form';
import { ContestEvent } from 'src/lib/types/contestEvent';
import { parseSearchParams } from 'src/lib/utils/parseSearchParams';
import useSWR, { SWRResponse } from 'swr';

export type PossibleParams = {
  page: number | string;
  size: number | string;
  order: Record<string, string>;
  filter: {
    [key: string]: {
      like: string;
      eq: string;
    };
  };
};

export const fetchContestEventsByContest = (
  contestUuid: string,
  filter?: DeepPartial<PossibleParams>
): SWRResponse<ContestEvent[]> => {
  const parsedParams = parseSearchParams(filter ?? {});
  const response = useSWR<ContestEvent[], Error>(
    `admin/game-contest/${contestUuid}/events/?${parsedParams}`
  );

  return response;
};
