import GamesSessions from 'src/components/modules/Games/view/ListSessions';
import Page from 'src/template/components/Page';

export default function GamesSessionsPage() {
  return (
    <Page title="Games Sessions">
      <GamesSessions />
    </Page>
  );
}
