import getSGMClient from './axios.config';

export const sgmFetcher = async (url: string) => {
  const api = await getSGMClient();

  return api.get(url).then((res) => res.data);
};

export const infiniteListSGMFetcher = async (url: string) => {
  const api = await getSGMClient();

  return api.get(url).then((res) => res.data.body);
};
