import { QueryParams, SearchParams } from './types';

const getQueryFromSearchParams = <T>(
  keys: string[],
  urlSearchParams: URLSearchParams
): T => {
  const queryObject = keys.reduce((obj, key) => {
    const values = urlSearchParams
      .getAll(key)
      .map((value) => JSON.parse(value));

    return {
      ...obj,
      [key]: values.length > 1 ? values : values[0],
    };
  }, {} as T);

  return queryObject;
};

const getSearchParamsFromQuery = (query: QueryParams): SearchParams => {
  const keys = Object.keys(query) as string[];

  return keys.reduce(
    (searchParams, key) => ({
      ...searchParams,
      [key]: JSON.stringify(query[key]),
    }),
    {}
  );
};

export const PersistentQueryParser = {
  fromQuery: getSearchParamsFromQuery,
  toQuery: getQueryFromSearchParams,
};
