import { CommonDateStrings } from 'src/lib/constants/CommonDates';
import { DEFAULT_INITIAL_PAGE_SIZE } from 'src/lib/constants/pagination';
import { IncludeExcludeAll } from 'src/lib/utils/includeExcludeFilterUtils';
import { objectValuesToString } from 'src/lib/utils/objectValuesToString';
import removeEmptyFieldsFromObject from 'src/lib/utils/removeEmptyFieldsFromObject';
import { RankFilterFormValues } from '../components/Rankings/FlaggedTransactionsRankingTable/types';

export const INITIAL_FILTERS: RankFilterFormValues = {
  country: '',
  lang: '',
  endDate: CommonDateStrings.today.date,
  startDate: CommonDateStrings.firstTransactionAndPool.date,
  userBehavior: 'blocked',
  InactiveUsers: IncludeExcludeAll.Exclude,
  userType: 'all',
};

export const INITIAL_SEARCH_PARAMS_STATE = {
  page: '0',
  size: String(DEFAULT_INITIAL_PAGE_SIZE),
  ...removeEmptyFieldsFromObject(objectValuesToString(INITIAL_FILTERS)),
};
