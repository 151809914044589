import {
  Autocomplete,
  Box,
  Checkbox,
  InputAdornment,
  TextField,
} from '@mui/material';
import { GridSortDirection } from '@mui/x-data-grid';
import { debounce } from 'lodash';
import { GameToAdd } from 'src/components/modules/Clubs/components/Forms/Club/types';
import Flex from 'src/components/shared/Flex';
import MagicTable from 'src/components/shared/MagicTable/MagicTable';
import useURLSearchParams from 'src/lib/hooks/useURLSearchParams';
import { Game } from 'src/lib/types/game';
import { LabelValue } from 'src/lib/types/labelValue';
import { formatUTCDate } from 'src/lib/utils/formatUTCDate';
import Iconify from 'src/template/components/Iconify';
import { FetchGamesQueryParams } from '../../view/List/useView';

type GamesTableProps = {
  data?: Game[];
  totalCount: number;
  isLoading: boolean;
  params: FetchGamesQueryParams;

  selectedGames: GameToAdd[];
  handleAddGame: (collection: Game) => void;
  handleRemoveGame: (uuid: string) => void;
};

export default function AddGamesTable({
  data,
  totalCount,
  isLoading,
  params: { page, size, searchBy, orderBy, sortOrder },

  selectedGames,
  handleAddGame,
  handleRemoveGame,
}: GamesTableProps) {
  const { addParam, removeParam, allSearchParams } = useURLSearchParams();

  function handleChangeSearch(value: string, param: string = 'query') {
    if (!value) removeParam(param);
    else addParam(param, value);
  }

  const debouncedSearch = debounce(handleChangeSearch, 1000);

  return (
    <MagicTable.Container>
      <Flex gap={2} p={2} justifyContent={'flex-end'}>
        <Autocomplete
          options={SEARCH_OPTIONS}
          fullWidth
          renderInput={(params) => <TextField label="Search By" {...params} />}
          getOptionLabel={(p) => p.label}
          sx={{ maxWidth: 250 }}
          defaultValue={SEARCH_OPTIONS.find((o) => o.value === searchBy)}
          key={searchBy}
          onChange={(_, value) => {
            if (!value) removeParam('searchBy');
            else addParam('searchBy', value.value);
          }}
        />
        <TextField
          fullWidth
          label="Search"
          defaultValue={allSearchParams.query}
          type="search"
          onChange={(e) => {
            const { value } = e.target;
            debouncedSearch(value);
          }}
          key={allSearchParams.query}
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <Iconify
                  icon="eva:search-fill"
                  sx={{ color: 'text.disabled', width: 20, height: 20 }}
                />
              </InputAdornment>
            ),
          }}
        />
      </Flex>
      <Box px={2}>
        <MagicTable
          loading={isLoading}
          pageSize={size}
          page={page}
          paginationMode="server"
          onPageChange={(newPage) => {
            addParam('page', newPage);
          }}
          onPageSizeChange={(newSize) => {
            addParam('size', newSize);
          }}
          onSortModelChange={(model) => {
            const sortModel = model[0];
            addParam('orderBy', sortModel.field);
            addParam('order', sortModel.sort!);
          }}
          sortModel={[
            {
              field: orderBy,
              sort: sortOrder as GridSortDirection,
            },
          ]}
          rows={data ?? []}
          rowCount={totalCount}
          columns={[
            {
              field: 'checkbox',
              headerName: '',
              sortable: false,
              flex: 0,
              renderCell: ({ row }) => (
                <Checkbox
                  checked={selectedGames.some((game) => game.uuid === row.uuid)}
                  onChange={() => {
                    if (selectedGames.some((game) => game.uuid === row.uuid)) {
                      handleRemoveGame(row.uuid);
                    } else {
                      handleAddGame(row);
                    }
                  }}
                />
              ),
            },
            {
              field: 'name',
              headerName: 'Name',
              flex: 1 / 2,
            },
            {
              field: 'description',
              headerName: 'Description',
              flex: 1,
            },
            {
              field: 'createdAt',
              headerName: 'Created At',
              sortingOrder: ['desc', 'asc'],
              flex: 1 / 2,
              valueFormatter({ value }) {
                return value ? formatUTCDate(value) : '-';
              },
            },
          ]}
        />
      </Box>
    </MagicTable.Container>
  );
}

const SEARCH_OPTIONS: LabelValue[] = [
  {
    label: 'Name',
    value: 'name',
  },
];
