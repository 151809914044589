import {
  Autocomplete,
  Card,
  CardContent,
  InputAdornment,
  TextField,
  debounce,
} from '@mui/material';
import { GridSortDirection } from '@mui/x-data-grid';
import { NftToAdd } from 'src/components/modules/NFTPacks/components/Forms/AddNft/types';
import Flex from 'src/components/shared/Flex';
import { NFTForPack } from 'src/lib/types/nft';
import Iconify from 'src/template/components/Iconify';
import AddNftsTable from '../../components/AddNftsTable';
import useSelectNFTSForPacksView from './useView';

const searchOptions = [
  {
    label: 'Name',
    query: 'name',
  },
  {
    label: 'Collection Name',
    query: 'collectionName',
  },
];

interface SelectNFTSForPacksProps {
  handleAddNft: (nft: NFTForPack) => void;
  handleRemoveNft: (uuid: string) => void;
  selectedNfts: NftToAdd[];
}

export default function SelectNFTSForPacks({
  handleAddNft,
  handleRemoveNft,
  selectedNfts,
}: SelectNFTSForPacksProps): JSX.Element {
  const {
    data,
    isLoading,
    order,
    page,
    query,
    searchBy,
    size,
    orderBy,
    setOrder,
    setPage,
    setRowsPerPage,
    setQuery,
    setSearchBy,
    setOrderBy,
  } = useSelectNFTSForPacksView();

  function handleChangeSearch(value: string) {
    setQuery(value);
  }

  const debouncedSearch = debounce(handleChangeSearch, 300);

  return (
    <Card>
      <Flex gap={2} p={2}>
        <Autocomplete
          disableClearable
          options={searchOptions}
          fullWidth
          renderInput={(params) => <TextField label="Search By" {...params} />}
          getOptionLabel={(p) => p.label}
          sx={{ maxWidth: 250 }}
          defaultValue={searchOptions.find((o) => o.query === searchBy)}
          key={searchBy}
          onChange={(_, value) => {
            setSearchBy(value?.query ?? '');
          }}
        />

        <TextField
          fullWidth
          label="Search"
          defaultValue={query}
          type="search"
          onChange={(e) => {
            const { value } = e.target;
            debouncedSearch(value);
          }}
          key={query}
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <Iconify
                  icon="eva:search-fill"
                  sx={{ color: 'text.disabled', width: 20, height: 20 }}
                />
              </InputAdornment>
            ),
          }}
        />
      </Flex>

      <CardContent>
        <AddNftsTable
          data={data ?? []}
          isLoading={isLoading}
          page={page}
          size={size}
          sortBy={orderBy}
          order={order as GridSortDirection}
          setOrder={setOrder as (order: GridSortDirection) => void}
          setSortBy={setOrderBy}
          setPage={setPage}
          setRowsPerPage={setRowsPerPage}
          handleAddNft={handleAddNft}
          handleRemoveNft={handleRemoveNft}
          selectedNfts={selectedNfts}
        />
      </CardContent>
    </Card>
  );
}
