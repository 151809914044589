import { DeepPartial } from 'react-hook-form';
import { GameAssetAction, GameAssetType } from 'src/lib/types/asset';
import { parseSearchParams } from 'src/lib/utils/parseSearchParams';
import useSWR, { SWRResponse } from 'swr';

export type PossibleParams = {
  page: number;
  size: number;
  order: Record<string, string>;
  filter: {
    [key: string]: {
      like: string;
      eq: string;
    };
  };
};

export const fetchActionAndAssetTypesByGameUuid = (
  gameUuid: string,
  filter?: DeepPartial<PossibleParams>
): SWRResponse<FetchActionAndAssetTypesByGameUuidResponse> => {
  const parsedParams = parseSearchParams(filter ?? {});
  const response = useSWR<FetchActionAndAssetTypesByGameUuidResponse, Error>(
    `/admin/game/${gameUuid}/statistic/actions-and-types?${parsedParams}`
  );

  return response;
};

type FetchActionAndAssetTypesByGameUuidResponse = {
  actions: GameAssetAction[];
  assetTypes: GameAssetType[];
};
