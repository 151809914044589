import { Container } from '@mui/material';
import AdminNftTransfersList from 'src/components/modules/AdminNftTransfers/components/AdminNftTransfersList';
import Page from 'src/template/components/Page';

export default function AdminNftTransferPage() {
  return (
    <Page title="Admin NFT Transfers">
      <Container maxWidth="xl">
        <AdminNftTransfersList />
      </Container>
    </Page>
  );
}
