import getAPIClient from 'src/lib/services/api/axios.config';
import { NftPackStatus } from 'src/lib/types/nftPack';
import { getMessageFromError } from 'src/lib/utils/error';

export async function ChangeNftPackStatus(uuid: string, status: NftPackStatus) {
  try {
    const api = await getAPIClient();
    const res = await api.patch(`admin/nft-pack/${uuid}/status`, {
      status,
    });
    return {
      data: res.data,
      error: null,
    };
  } catch (e) {
    return {
      data: null,
      error: getMessageFromError(e),
    };
  }
}
