import { UserChannelRelations } from 'src/components/modules/Profile/sections/Channels/types';
import { DEFAULT_INITIAL_PAGE_SIZE } from 'src/lib/constants/pagination';
import useURLSearchParams from 'src/lib/hooks/useURLSearchParams';
import { OBJECT_MODERATION_REVIEW_STATUS } from 'src/lib/types/objectModeration';
import useTabs from 'src/template/hooks/useTabs';
import { fetchChannels } from '../../services/fetchChannel';

const getRelationFilter = (
  userUuid: string,
  relation: UserChannelRelations
) => {
  switch (relation) {
    case UserChannelRelations.ADMIN:
      return { adminUuid: userUuid };
    case UserChannelRelations.FOLLOWER:
      return { followerUuid: userUuid };
    case UserChannelRelations.OWNER:
      return { ownerUuid: userUuid };
    default:
      return {};
  }
};

type UseChannelsViewProps = {
  userUuid?: string;
  relation?: UserChannelRelations;
};

export default function useChannelsView({
  userUuid,
  relation,
}: UseChannelsViewProps) {
  const { allSearchParams } = useURLSearchParams(initialParams);

  const { currentTab, onChangeTab } = useTabs('all', {
    queryName: 'tab',
  });

  const params: ListChannelsParams = {
    page: Number(allSearchParams.page) ?? initialParams.page,
    size: Number(allSearchParams.size) ?? initialParams.size,
    sortBy: allSearchParams.sortBy ?? initialParams.sortBy,
    order: allSearchParams.order ?? initialParams.order,
    searchBy: allSearchParams.searchBy ?? initialParams.searchBy,
    query: allSearchParams.query ?? initialParams.query,
  };

  let relationFilter = {};
  if (userUuid) {
    relationFilter = getRelationFilter(
      userUuid,
      relation ?? UserChannelRelations.FOLLOWER
    );
  }

  const query: any = {};
  if (params.searchBy && params.query) {
    query[params.searchBy] = {
      like: `%${params.query}%`,
    };
  }

  let reviewStatusFilter: any = {};
  if (!['all', 'humanAnalysisNeeded'].includes(currentTab ?? '')) {
    reviewStatusFilter = {
      reviewStatus: currentTab as OBJECT_MODERATION_REVIEW_STATUS,
    };
  }

  let humanAnalysisNeededFilter: any = {};
  if (currentTab == 'humanAnalysisNeeded') {
    humanAnalysisNeededFilter = {
      humanAnalysisNeeded: true,
    };
  }

  const { data, isLoading, mutate } = fetchChannels({
    filter: {
      ...query,
      ...relationFilter,
      ...reviewStatusFilter,
      ...humanAnalysisNeededFilter,
    },
    page: params.page,
    size: params.size,
    order: params.order,
    sortBy: params.sortBy,
  });

  return {
    data,
    isLoading,
    params,
    currentTab,
    onChangeTab,
  };
}

export type ListChannelsParams = {
  page: number;
  size: number;
  sortBy: string;
  order: string;
  searchBy?: string;
  query?: string;
};

const initialParams = {
  sortBy: 'updatedAt',
  order: 'desc',
  page: '0',
  size: DEFAULT_INITIAL_PAGE_SIZE.toString(),
  searchBy: 'name',
  query: '',
};
