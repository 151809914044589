import { BlockchainNetwork } from '../constants/blockchain';
// eslint-disable-next-line import/no-cycle
import { WithObjectModerationProps } from './objectModeration';
import { SocialMedia } from './socialMedia';

export type Collection<User = any, NFT = any> = WithObjectModerationProps<{
  active: boolean;
  aiAnalysis: CollectionReview['aiAnalysis'];
  aiMaxScore: string;
  bannerImage: any;
  blockchain: BlockchainNetwork;
  categories: { name: string }[];
  certifiedRights?: boolean;
  coverAiScore: number;
  coverFileUrl: string;
  coverNftUuid: string;
  createdAt: string;
  dailyCollection: boolean;
  deletedAt: string;
  description: string;
  displayTheme: string;
  externalWallet?: string;
  featuredImage: string;
  floorPrice: number;
  hasCertifiedRights: boolean;
  humanAnalysis: CollectionReview['humanAnalysis'];
  isSharedCollection: boolean;
  lang: string;
  links: string;
  metadata: string;
  migrated: boolean;
  mintToken?: string;
  name: string;
  nfts?: NFT[];
  nftsCount: number;
  originalsFileUrl: string;
  platform: AppPlatforms;
  pqFamousCartoon: string;
  pqIncludeWritting: string;
  pqStickersType: string;
  pqTransparentBackground: string;
  priceType: string;
  priority: string;
  productId: string;
  region: string;
  releasedAt: string;
  reportsCount: number;
  revalidate: boolean;
  revenueShare: boolean;
  reviewMessage: string;
  salesAmount: number;
  salesCount: number;
  slug: string;
  socialMedia: SocialMedia;
  tags: string[];
  trashed: boolean;
  updatedAt: string;
  user?: User;
  userStatus: string;
  userUuid: string;
  uuid: string;
  verified: boolean;
  walletUuid: string;
}>;

export type WithCollections<T> = T & {
  collections: Collection[];
};
export enum AppPlatforms {
  android = 'android-sticky',
  imported = 'imported',
  ios = 'ios-sticky-free',
  web = 'web-sticky',
}

export type CollectionReview = {
  reviewStatus: 'rejected' | 'approved' | 'reviewing' | 'awaiting_review';
  humanAnalysis:
    | 'everywhere'
    | 'internal'
    | 'public_profile'
    | 'private_profile'
    | 'not_set';
  aiAnalysis: 'approved' | 'review' | 'denied' | 'not_set';
  reviewMessage: string;
  revalidate: boolean;
  verified: boolean;
};

export type SalesDetails = {
  totalNftEditionsSold: number;
  totalSalesAmount: number;
  totalUniqueNftsSold: number;
  uniqueBuyers: number;
};

export type CollectionDetails = SalesDetails;
