import { getDefaultObjectLinksOptions } from 'src/components/shared/Form/useObjectLinkSearch';
import { ChannelWithAdminAnalytics } from 'src/lib/types/channelWithAdminAnalytics';
import UpdateChannelForm from '../../components/Forms/Update';
import { UpdateChannelFormValues } from '../../components/Forms/Update/types';
import useCreateChannelView from './useView';

interface UpdatChannelViewProps {
  channel: ChannelWithAdminAnalytics;
}

export default function UpdateChannelView({
  channel,
}: UpdatChannelViewProps): JSX.Element {
  const { handleUpdateChannel } = useCreateChannelView();
  return (
    <UpdateChannelForm
      defaultValues={{
        name: channel.name,
        description: channel.description,
        backgroundImageUrl: channel.backgroundImageUrl,

        objectLinks: getDefaultObjectLinksOptions(channel.objectLinks),
        objectLinksTempSearch: '',
      }}
      onSubmit={async (values: UpdateChannelFormValues) => {
        await handleUpdateChannel(channel.uuid, values);
      }}
      title="Update Channel"
    />
  );
}
