import { Navigate, useParams } from 'react-router';

import { Card, CardContent, Stack, Tab, Tabs } from '@mui/material';
import { capitalCase } from 'change-case';
import { Suspense } from 'react';
import Flex from 'src/components/shared/Flex';
import Loader from 'src/components/shared/Loader';
import { fetchCollection } from 'src/lib/services/collections';
import { fetchNFT } from 'src/lib/services/nft';
import { Collection } from 'src/lib/types/collection';
import HeaderBreadcrumbs from 'src/template/components/HeaderBreadcrumbs';
import LoadingScreen from 'src/template/components/LoadingScreen';
import useTabs from 'src/template/hooks/useTabs';
import NFTActions from './NFTActions';
import NFT_TABS, { NFTTab } from './view/NFTTabs';

const DEFAULT_TAB = NFTTab.MAIN;

export default function NFT() {
  const { uuid } = useParams<'uuid'>();
  if (!uuid) return <Navigate to="/collections" replace />;

  const { currentTab, onChangeTab } = useTabs(DEFAULT_TAB, {
    resetOnChange: true,
  });

  const { data: nft } = fetchNFT(uuid);
  const { data: collection } = fetchCollection(nft?.collectionUuid ?? null);

  return !nft ? (
    <LoadingScreen />
  ) : (
    <>
      <HeaderBreadcrumbs
        heading={nft?.name || 'Empty Name'}
        links={[
          { name: 'Collections', href: '/collections' },
          {
            name: collection?.name ?? 'Unnamed Collection',
            href: `/collections/${nft?.collectionUuid ?? ''}`,
          },
          { name: nft?.name ?? 'Unnamed NFT' },
        ]}
        action={<NFTActions nft={nft} />}
      />

      <Card>
        <Stack pb={2}>
          <Flex sx={{ px: 2, bgcolor: 'background.neutral' }} mb={2}>
            <Tabs
              allowScrollButtonsMobile
              variant="scrollable"
              scrollButtons="auto"
              value={currentTab}
              onChange={onChangeTab}
            >
              {NFT_TABS.map((tab) => (
                <Tab
                  disableRipple
                  key={tab.value}
                  value={tab.value}
                  icon={tab.icon}
                  label={capitalCase(tab.value)}
                />
              ))}
            </Tabs>
          </Flex>
          <CardContent>
            <Suspense fallback={<Loader />}>
              {NFT_TABS.map(
                (tab) =>
                  tab.value === currentTab &&
                  tab.component({
                    ...nft,
                    collection: collection as Collection,
                  })
              )}
            </Suspense>
          </CardContent>
        </Stack>
      </Card>
    </>
  );
}
