import { deepClone } from './deepClone';
import objectEntries from './objectEntries';

export default function removeEmptyFieldsFromObject<
  T extends Record<string, unknown>
>(data: T): Partial<T> {
  const newData = deepClone(data);
  objectEntries(newData).forEach(([key, value]) => {
    if (value === null || value === undefined || value === '') {
      delete newData[key];
    }
  });

  return newData;
}
