import { DeepPartial } from 'react-hook-form';
import { PaginatedResponse } from 'src/lib/types/PaginatedResponse';
import { AdminListGamesRow } from 'src/lib/types/gameRow';
import { parseSearchParams } from 'src/lib/utils/parseSearchParams';
import useSWR, { SWRResponse } from 'swr';

export type PossibleParams = {
  page: number | string;
  size: number | string;
  order: Record<string, string>;
  filter: any;
};

export const fetchGamesByAdmin = (
  userUuid: string,
  filter?: DeepPartial<PossibleParams>,
  shouldFetch = true
): SWRResponse<PaginatedResponse<AdminListGamesRow[]>> => {
  const parsedParams = parseSearchParams(filter ?? {});
  const response = useSWR<PaginatedResponse<AdminListGamesRow[]>, Error>(
    shouldFetch ? `admin/users/${userUuid}/managed-games?${parsedParams}` : null
  );

  return response;
};
