import {
  AttachMoneyRounded,
  DriveFileRenameOutlineOutlined,
  LocalFireDepartmentRounded,
  LocalOfferRounded,
  ShoppingBagRounded,
} from '@mui/icons-material';
import {
  Card,
  CardActions,
  CardContent,
  CardHeader,
  InputAdornment,
  Stack,
  Switch,
  TextField,
} from '@mui/material';
import { useForm } from 'react-hook-form';
import Button from 'src/components/shared/Buttons/CustomButton';
import Modal, { ModalProps } from 'src/components/shared/Modal';
import useNotify from 'src/lib/hooks/useNotify';
import { fetchCollection } from 'src/lib/services/collections';
import { updateNft } from 'src/lib/services/nft';
import { NFT } from 'src/lib/types/nft';
import { getMessageFromError } from 'src/lib/utils/error';
import removeEmptyFieldsFromObject from 'src/lib/utils/removeEmptyFieldsFromObject';

interface NFTEditModalProps extends ModalProps {
  nft: NFT;
  closeActionsMenu: () => void;
}

export default function NFTEditModal({
  nft,
  open,
  onClose,
  closeActionsMenu,
}: NFTEditModalProps) {
  const notify = useNotify();
  const {
    register,
    handleSubmit,
    reset,
    formState: { errors, isValid, isSubmitting },
  } = useForm({
    defaultValues: {
      name: nft.name,
      tags: (nft.tags ?? []).join(','),
      salesPrice: nft.salesPrice,
      salesLimit: nft.salesLimit,
      mintBurned: nft.mintBurned,
      forSale: nft.buyNow,
    },
  });

  const { data: collection } = fetchCollection(nft.collectionUuid);

  const handleCloseModal = () => {
    onClose();
    closeActionsMenu();
  };

  const onSave = handleSubmit(async (data) => {
    try {
      const nftData = removeEmptyFieldsFromObject({
        uuid: nft.uuid,
        userUuid: collection?.userUuid || undefined,
        packUid: nft.collectionUuid,
        fileUrl: nft.fileUrl,
        name: data.name,
        tags: data.tags ? data.tags.split(',') : undefined,
        salesPrice: data.salesPrice,
        salesLimit: data.salesLimit,
        mintBurned: data.mintBurned,
        forSale: data.forSale,
      });

      await updateNft(nftData);

      handleCloseModal();
      notify('NFT updated successfully', { variant: 'success' });
      reset(() => ({
        name: data.name,
        tags: data.tags,
        salesPrice: data.salesPrice,
        salesLimit: data.salesLimit,
        mintBurned: data.mintBurned,
        forSale: data.forSale,
      }));
    } catch (e) {
      notify(getMessageFromError(e), { variant: 'error' });
    }
  });

  return (
    <Modal open={open} onClose={handleCloseModal}>
      <form
        onSubmit={onSave}
        onKeyDown={(e) => {
          e.stopPropagation();
        }}
      >
        <Card
          sx={{
            width: ['100%', '400px'],
          }}
        >
          <CardHeader title="Edit NFT Details" />
          <CardContent>
            <Stack spacing={2}>
              <TextField
                label="Name"
                error={!!errors.name}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <DriveFileRenameOutlineOutlined />
                    </InputAdornment>
                  ),
                }}
                {...register('name')}
              />
              <TextField
                label="Sales Price"
                type="number"
                {...register('salesPrice', {
                  valueAsNumber: true,
                })}
                error={!!errors.salesPrice}
                helperText={errors.salesPrice && 'Sales Price is required'}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <AttachMoneyRounded />
                    </InputAdornment>
                  ),
                }}
              />
              <TextField
                label="Sales Limit"
                {...register('salesLimit', {
                  valueAsNumber: true,
                })}
                type="number"
                error={!!errors.salesLimit}
                helperText={errors.salesLimit && 'Sales Limit is required'}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <ShoppingBagRounded />
                    </InputAdornment>
                  ),
                }}
              />
              <TextField
                label="Mint Burned"
                {...register('mintBurned', {
                  valueAsNumber: true,
                  min: 1,
                })}
                error={!!errors.mintBurned}
                helperText={errors.mintBurned && 'Mint Burned is required'}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <LocalFireDepartmentRounded />
                    </InputAdornment>
                  ),
                }}
              />
              <TextField
                label="Tags"
                {...register('tags')}
                error={!!errors.tags}
                helperText="Comma separated tags for these NFTs"
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <LocalOfferRounded />
                    </InputAdornment>
                  ),
                }}
              />
              <Stack direction="row" justifyContent="space-between">
                For Sale:
                <Switch
                  {...register('forSale')}
                  color="primary"
                  defaultChecked={nft.buyNow}
                />
              </Stack>
            </Stack>
          </CardContent>
          <CardActions
            sx={{
              justifyContent: 'space-between',
            }}
          >
            <Button color="error" onClick={handleCloseModal}>
              Cancel
            </Button>
            <Button
              variant="contained"
              color="secondary"
              loading={isSubmitting}
              type="submit"
              disabled={!isValid}
            >
              Save
            </Button>
          </CardActions>
        </Card>
      </form>
    </Modal>
  );
}
