import { WithTimestamp } from './WithTimestamp';
import { Collection } from './collection';
import { Game } from './game';
import { User } from './users';

export type Club = WithTimestamp<{
  uuid: string;
  name: string;
  joinMethod: ClubJoinMethods;
  membersCount: number;

  photoFileUrl: string | null;
  landingPageUrl: string | null;
  coverFileUrl: string | null;

  description: string | null;
  createdByUserUuid: string | null;

  createdAt: Date;
  updatedAt: Date;
  deletedAt: Date | null;

  externalClubAssets?: ExternalClubAsset[];
  internalClubAssets?: InternalClubAsset[];

  games: Game[];
  collections: Collection[];
  chats: ExternalClubAsset[];
  topics: ExternalClubAsset[];

  status: ClubStatus;
  createdByUser?: User;
}>;

export enum ClubJoinMethods {
  PUBLIC = 'public',
  PUBLIC_OPT_IN = 'public-opt-in',
  COLLECTION_OWNERSHIP = 'collection-ownership',
  PRO_SUBSCRIPTION = 'pro-subscription',
  BOUGHT_ENTRANCE = 'bought-entrance',
}

export enum ClubStatus {
  DRAFT = 'draft',
  READY = 'ready',
}

export type ExternalClubAsset = {
  uuid: string;
  clubUuid: string;
  assetId: string;
  assetType: PossibleExternalClubAssets;
  createdAt: Date;
  updatedAt: Date;
  deletedAt: Date | null;
};
export enum PossibleExternalClubAssets {
  CHAT = 'chat',
  TOPIC = 'topic',
}

export type InternalClubAsset = {
  uuid: string;
  clubUuid: string;
  collectionUuid: string | null;
  gameUuid: string | null;
  createdAt: Date;
  updatedAt: Date;
  deletedAt: Date | null;

  collection?: Collection;
  game?: Game;
};
