import { Card, CardContent, Stack, Tab, Tabs } from '@mui/material';
import { capitalCase } from 'change-case';
import { Suspense } from 'react';
import CHANNEL_TABS from 'src/components/modules/Channels/view/Details/channelTabs';
import Flex from 'src/components/shared/Flex';
import Loader from 'src/components/shared/Loader';
import Routes from 'src/routes/Routes';
import HeaderBreadcrumbs from 'src/template/components/HeaderBreadcrumbs';
import LoadingScreen from 'src/template/components/LoadingScreen';
import CHANNEL_CONTENT_TABS from './channelContentTabs';
import useChannelContentDetailsView from './useView';

export default function ChannelContentDetails() {
  const { channelContentDetails, currentTab, onChangeTab } =
    useChannelContentDetailsView();

  return channelContentDetails ? (
    <>
      <HeaderBreadcrumbs
        heading={channelContentDetails.title || 'Empty Title'}
        links={[
          { name: 'Contents', href: Routes.channel.contents },
          { name: channelContentDetails.title || 'Empty Title' },
        ]}
      />

      <Card>
        <Stack pb={2}>
          <Flex sx={{ px: 2, bgcolor: 'background.neutral' }} mb={2}>
            <Tabs
              allowScrollButtonsMobile
              variant="scrollable"
              scrollButtons="auto"
              value={currentTab}
              onChange={onChangeTab}
            >
              {CHANNEL_TABS.map((tab) => (
                <Tab
                  disableRipple
                  key={tab.value}
                  value={tab.value}
                  icon={tab.icon}
                  label={capitalCase(tab.value)}
                />
              ))}
            </Tabs>
          </Flex>
          <CardContent>
            <Suspense fallback={<Loader />}>
              {CHANNEL_CONTENT_TABS.map(
                (tab) =>
                  tab.value === currentTab &&
                  tab.component(channelContentDetails)
              )}
            </Suspense>
          </CardContent>
        </Stack>
      </Card>
    </>
  ) : (
    <LoadingScreen />
  );
}
