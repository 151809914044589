import { getMessageFromError } from 'src/lib/utils/error';
import useSWR, { SWRConfiguration, SWRResponse } from 'swr';

export function fetchWalletVerificationStatus(
  config?: SWRConfiguration
): SWRResponse {
  const { error, ...response } = useSWR(
    `admin/wallet-inconsistency/verificationStatus`,
    config
  );

  return {
    ...response,
    error: error ? getMessageFromError(error) : undefined,
  };
}
