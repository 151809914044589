import useModalStore from 'src/lib/stores/useModalStore';
import { ObjectModeration } from 'src/lib/types/objectModeration';
import ModerationDetailsModalView from '../ModerationDetails';

export default function useVideosToReviewTableView() {
  const openModal = useModalStore((state) => state.openModal);

  const handleOpenModerationDetails = (moderation: ObjectModeration) => {
    openModal(<ModerationDetailsModalView moderation={moderation} />);
  };

  return {
    handleOpenModerationDetails,
  };
}
