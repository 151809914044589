import { Stack, TextField } from '@mui/material';
import { CommonDateStrings } from 'src/lib/constants/CommonDates';

import { BracketFilterParams } from 'src/lib/types/bracketFilterParams';

interface HomeFeedsFilterModalProps {
  filter: BracketFilterParams;
  setFilter: (filter: Partial<BracketFilterParams>) => void;
}
function HomeFeedsFilterModal({
  filter,
  setFilter,
}: HomeFeedsFilterModalProps) {
  return (
    <Stack
      sx={{
        m: 4,
      }}
      spacing={2}
    >
      <TextField
        type="date"
        name="startDate"
        label="Start Date"
        fullWidth
        InputLabelProps={{ shrink: true }}
        value={filter?.custom?.createdAt?.gte || undefined}
        defaultValue={CommonDateStrings.firstFeed.date}
        onChange={(e) =>
          setFilter({
            ...filter,
            custom: {
              ...filter.custom,
              createdAt: {
                gte: e.target.value,
              },
            },
          })
        }
      />
      <TextField
        type="date"
        name="endDate"
        label="End Date"
        InputLabelProps={{ shrink: true }}
        fullWidth
        value={filter?.custom?.createdAt?.lte || undefined}
        defaultValue={CommonDateStrings.today.date}
        onChange={(e) =>
          setFilter({
            ...filter,
            custom: {
              ...filter.custom,
              createdAt: {
                lte: e.target.value,
              },
            },
          })
        }
      />
    </Stack>
  );
}
export default HomeFeedsFilterModal;
