import VideoFeed from 'src/components/modules/VideoFeed/view/List';
import Page from 'src/template/components/Page';

export default function GamesFeedPage() {
  return (
    <Page title="Video Feed">
      <VideoFeed />
    </Page>
  );
}
