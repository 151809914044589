import { Container } from '@mui/material';
import Routes from 'src/routes/Routes';
import HeaderBreadcrumbs from 'src/template/components/HeaderBreadcrumbs';
import ContestEventsTable from '../../components/ContestsTable/ContestsTable';
import useContestEvents from './useView';

export default function ContestEvents() {
  const { data, isLoading, params, contestUuid } = useContestEvents();
  return (
    <Container maxWidth="xl">
      <HeaderBreadcrumbs
        heading="Contest Events"
        links={[
          ...(contestUuid
            ? [{ name: 'Contests', href: Routes.configurations.contests }]
            : []),
          { name: 'Contest Events' },
        ]}
      />

      <ContestEventsTable data={data} isLoading={isLoading} params={params} />
    </Container>
  );
}
