import {
  Card,
  CardContent,
  CardHeader,
  Checkbox,
  Divider,
  FormControlLabel,
  InputLabel,
  MenuItem,
  Stack,
  TextField,
} from '@mui/material';
import { Controller } from 'react-hook-form';
import Button from 'src/components/shared/Buttons/CustomButton';
import Modal from 'src/components/shared/Modal';
import UploadContent, {
  UploadTypes,
} from 'src/components/shared/UploadContent';
import {
  FeedItem,
  FeedItemActions,
  FeedItemContentTypes,
  FeedItemPageTypes,
  FeedItemTypes,
} from 'src/lib/types/FeedItem';
import Autocomplete from './components/Autocomplete';
import ItemDataInput from './components/ItemDataInput';
import { FeedItemForm, useCreateFeedItemView } from './useView';

interface ChannelContentFormProps {
  feedItem?: FeedItem;
  onSubmit: (values: FeedItemForm) => Promise<void>;
  isSubmitting?: boolean;
}

export default function ChannelContentForm({
  feedItem,
  onSubmit,
  isSubmitting,
}: ChannelContentFormProps): JSX.Element {
  const {
    autoCompleteOptions,
    autoCompleteLabel,
    autoCompleteDefaultValue,
    showItemUuidField,
    disableAutoComplete,
    isLoading,
    handleInputChange,
    handleCloseModal,
    onUpdateContent,
    isUpdatingContent,
    currentContentType,
    form: { control, handleSubmit, setValue },
  } = useCreateFeedItemView(feedItem);

  return (
    <Modal open onClose={handleCloseModal}>
      <Card
        sx={{
          width: '600px',
        }}
      >
        <CardHeader title="Create Channel Content" />
        <CardContent>
          <form onSubmit={handleSubmit(onSubmit)}>
            <Stack spacing={2}>
              <Divider textAlign="left">Details</Divider>
              {/* TextField for title */}
              <Controller
                name="title"
                control={control}
                render={({ field }) => (
                  <TextField
                    label="Title"
                    fullWidth
                    {...field}
                    variant="outlined"
                  />
                )}
              />
              {/* TextField for titleUrl */}
              <Controller
                name="titleUrl"
                control={control}
                render={({ field }) => (
                  <Stack spacing={2}>
                    <InputLabel htmlFor="my-input">
                      Image title (Optional)
                    </InputLabel>
                    <UploadContent
                      {...field}
                      type={UploadTypes.IMAGE}
                      content={field.value ?? ''}
                      onEdit={(file) => {
                        onUpdateContent(file, 'titleUrl');
                      }}
                      onRemove={() => {
                        setValue('titleUrl', '');
                      }}
                      showEditButton
                      sx={{
                        maxWidth: '300px',
                        maxHeight: '300px',
                        minHeight: '30px',
                        minWidth: '100px',
                      }}
                      isLoading={isUpdatingContent}
                    />
                  </Stack>
                )}
              />

              {/* TextField for description */}
              <Controller
                name="description"
                control={control}
                render={({ field }) => (
                  <TextField
                    label="Description"
                    fullWidth
                    {...field}
                    variant="outlined"
                  />
                )}
              />

              {/* TextField for iternal name */}
              <Controller
                name="internalName"
                control={control}
                render={({ field }) => (
                  <TextField
                    label="Internal Name"
                    fullWidth
                    {...field}
                    variant="outlined"
                  />
                )}
              />

              <Divider textAlign="left">Content</Divider>

              {/* Select for contentType */}

              <Controller
                name="contentType"
                control={control}
                render={({ field }) => (
                  <TextField
                    select
                    label="Content Type"
                    fullWidth
                    {...field}
                    variant="outlined"
                  >
                    {Object.values(FeedItemContentTypes).map((type) => (
                      <MenuItem key={type} value={type}>
                        {type}
                      </MenuItem>
                    ))}
                  </TextField>
                )}
              />

              {/* TextField for contentUrl */}
              {currentContentType && (
                <Controller
                  name="contentUrl"
                  control={control}
                  render={({ field }) => (
                    <UploadContent
                      {...field}
                      type={currentContentType as unknown as UploadTypes}
                      content={field.value ?? ''}
                      onEdit={(file) => {
                        onUpdateContent(file, 'contentUrl');
                      }}
                      onRemove={() => {
                        setValue('contentUrl', '');
                      }}
                      showEditButton
                      sx={{
                        maxWidth: '300px',
                        maxHeight: '300px',
                        minHeight: '30px',
                        minWidth: '100px',
                      }}
                      isLoading={isUpdatingContent}
                    />
                  )}
                />
              )}

              {/* Select for contentOrientation */}
              {currentContentType && (
                <Controller
                  name="contentOrientation"
                  control={control}
                  render={({ field }) => (
                    <TextField
                      select
                      label="Content Orientation"
                      fullWidth
                      {...field}
                      variant="outlined"
                    >
                      <MenuItem value="horizontal">Horizontal</MenuItem>
                      <MenuItem value="vertical">Vertical</MenuItem>
                    </TextField>
                  )}
                />
              )}

              {/* Select for contentObjectFit */}
              {currentContentType && (
                <Controller
                  name="contentObjectFit"
                  control={control}
                  render={({ field }) => (
                    <TextField
                      select
                      label="Content Object Fit"
                      fullWidth
                      {...field}
                      variant="outlined"
                      disabled={!currentContentType}
                    >
                      <MenuItem value="cover">Cover</MenuItem>
                      <MenuItem value="contain">Contain</MenuItem>
                      <MenuItem value="fill">Fill</MenuItem>
                    </TextField>
                  )}
                />
              )}

              <Divider textAlign="left">Actions</Divider>

              {/* Select for primaryAction */}
              <Controller
                name="primaryAction"
                control={control}
                render={({ field }) => (
                  <TextField
                    select
                    label="Primary Action"
                    fullWidth
                    {...field}
                    variant="outlined"
                  >
                    {Object.values(FeedItemActions).map((action) => (
                      <MenuItem key={action} value={action}>
                        {action}
                      </MenuItem>
                    ))}
                  </TextField>
                )}
              />

              {/* Select for secondaryAction */}
              <Controller
                name="secondaryAction"
                control={control}
                render={({ field }) => (
                  <TextField
                    select
                    label="Secondary Action"
                    fullWidth
                    {...field}
                    variant="outlined"
                  >
                    {Object.values(FeedItemActions).map((action) => (
                      <MenuItem key={action} value={action}>
                        {action}
                      </MenuItem>
                    ))}
                  </TextField>
                )}
              />

              <Divider textAlign="left">Item</Divider>

              {/* Select for itemType */}
              <Controller
                name="itemType"
                control={control}
                render={({ field }) => (
                  <TextField
                    select
                    label="Item Type"
                    fullWidth
                    {...field}
                    variant="outlined"
                  >
                    {Object.values(FeedItemTypes).map((type) => (
                      <MenuItem key={type} value={type}>
                        {type}
                      </MenuItem>
                    ))}
                  </TextField>
                )}
              />

              {/* AutoComplete for itemUuid */}
              {showItemUuidField && (
                <Controller
                  name="itemUuid"
                  control={control}
                  render={({ field }) => (
                    <Autocomplete
                      label={autoCompleteLabel}
                      options={autoCompleteOptions}
                      isLoading={isLoading}
                      disabled={disableAutoComplete}
                      handleInputChange={handleInputChange}
                      handleChange={(event, value) =>
                        setValue('itemUuid', value?.uuid || '')
                      }
                      defaultValue={autoCompleteDefaultValue}
                      {...field}
                    />
                  )}
                />
              )}

              <Controller
                name="itemData"
                control={control}
                render={({ field }) => (
                  <ItemDataInput
                    itemDataValue={field.value}
                    onChange={(newData) => setValue('itemData', newData)}
                  />
                )}
              />

              <Divider textAlign="left">Visibility</Divider>

              {/* Select for pageType */}
              <Controller
                name="pageType"
                control={control}
                render={({ field }) => (
                  <TextField
                    select
                    label="Page Type"
                    fullWidth
                    {...field}
                    variant="outlined"
                  >
                    {Object.values(FeedItemPageTypes).map((type) => (
                      <MenuItem key={type} value={type}>
                        {type}
                      </MenuItem>
                    ))}
                  </TextField>
                )}
              />

              {/* Checkbox for requiresStickyPro */}
              <Controller
                name="requiresStickyPro"
                control={control}
                render={({ field }) => (
                  <FormControlLabel
                    control={<Checkbox {...field} />}
                    label="Requires Sticky Pro"
                  />
                )}
                defaultValue={false}
              />

              {!feedItem && (
                <Controller
                  name="sendToAppFeed"
                  control={control}
                  render={({ field }) => (
                    <FormControlLabel
                      control={<Checkbox {...field} />}
                      label="Send To App Feed"
                    />
                  )}
                  defaultValue={false}
                />
              )}

              <Button
                variant="contained"
                color="secondary"
                type="submit"
                loading={isSubmitting}
                size="large"
              >
                Save
              </Button>
            </Stack>
          </form>
        </CardContent>
      </Card>
    </Modal>
  );
}
