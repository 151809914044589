import AddAssetsForm from '../../components/Forms/AddExternalAssets';
import useAddExternalAssetView from './useView';

type Props = {
  uuid: string;
};

export default function AddExternalAssetView({ uuid }: Props): JSX.Element {
  const { handleAddExternalAsset } = useAddExternalAssetView();
  return (
    <AddAssetsForm
      defaultValues={{
        externalAssets: [
          {
            assetId: '',
            assetType: '',
          },
        ],
      }}
      onSubmit={async (values) => {
        await handleAddExternalAsset(uuid, values);
      }}
      title="Update Club"
    />
  );
}
