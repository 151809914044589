import useNotify from 'src/lib/hooks/useNotify';
import useModalStore from 'src/lib/stores/useModalStore';
import { EntityNoteEmitter } from '../../events/EntityNoteEmitter';
import { deleteEntityNote } from '../../services/deleteEntityNote';

export default function useDeleteEntityNote() {
  const closeModal = useModalStore((state) => state.closeModal);
  const notify = useNotify();

  const handleDeleteEntityNote = async (uuid: string) => {
    const { data, error } = await deleteEntityNote(uuid);
    if (error) {
      notify(error, { variant: 'error' });
      closeModal();
    } else if (data) {
      notify('Entity note deleted!', { variant: 'success' });
      closeModal();
      EntityNoteEmitter.emit('entityNoteDeleted', data);
    }
  };

  return {
    handleDeleteEntityNote,
  };
}
