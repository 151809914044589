import { BlockchainNetwork } from '../constants/blockchain';

const urls: Record<BlockchainNetwork, (address: string) => string> = {
  [BlockchainNetwork.ETHEREUM]: (address: string) =>
    `https://etherscan.io/address/${address}`,
  [BlockchainNetwork.POLYGON]: (address: string) =>
    `https://polygonscan.com/address/${address}`,
  [BlockchainNetwork.STICKY]: () => '',
};

export default function getBlockExplorerUrl(
  address: string,
  blockchain: BlockchainNetwork
): string {
  return urls[blockchain](address);
}
