import getAPIClient from 'src/lib/services/api/axios.config';
import { ServiceResponse } from 'src/lib/types/ServiceResponse';
import { getMessageFromError } from 'src/lib/utils/error';
import { DeviceEmitter } from '../events/DeviceEmitter';
import { DeviceModel } from '../types/Device';

type UpdateDeviceData = Partial<
  Pick<DeviceModel, 'active' | 'description' | 'blockReason'>
>;

export async function updateDevice(
  id: string,
  data: UpdateDeviceData
): Promise<ServiceResponse<DeviceModel>> {
  try {
    const api = await getAPIClient();
    const res = await api.put(`devices/${id}`, data);
    DeviceEmitter.emit('deviceUpdated', id);
    return {
      data: res.data,
      error: null,
    };
  } catch (e) {
    return {
      data: null,
      error: getMessageFromError(e),
    };
  }
}
