import { Add, Collections, Delete, LocalOffer } from '@mui/icons-material';
import {
  Card,
  CardContent,
  CardHeader,
  InputAdornment,
  Stack,
  Tooltip,
} from '@mui/material';
import { capitalCase } from 'change-case';
import { useState } from 'react';
import { useForm } from 'react-hook-form';
import { useNavigate } from 'react-router';
import Button from 'src/components/shared/Buttons/CustomButton';
import Flex from 'src/components/shared/Flex';
import ControllerField from 'src/components/shared/Inputs/ControllerField';
import Modal from 'src/components/shared/Modal';
import SelectOption from 'src/components/shared/SelectOption';
import { BlockchainNetwork } from 'src/lib/constants/blockchain';
import useNotify from 'src/lib/hooks/useNotify';
import { deleteCollection } from 'src/lib/services/collections';
import useModalStore from 'src/lib/stores/useModalStore';
import { Collection } from 'src/lib/types/collection';
import { getMessageFromError } from 'src/lib/utils/error';
import Routes from 'src/routes/Routes';
import AddNftsModal from '../../AddNftsModal';
import { CollectionFormValues } from './types';

type CollectionFormProps = {
  initialValues?: CollectionFormValues;
  onSubmit: (values: CollectionFormValues) => Promise<void>;
  open: boolean;
  onClose: () => void;
  collection: Collection;
};
export default function CollectionForm({
  onClose,
  open,
  initialValues,
  onSubmit,
  collection,
}: CollectionFormProps): JSX.Element {
  const openModal = useModalStore((state) => state.openModal);
  const notify = useNotify();
  const navigate = useNavigate();
  const [isDeleting, setIsDeleting] = useState(false);
  const {
    formState: { isSubmitting },
    handleSubmit,
    reset,
    control,
  } = useForm<CollectionFormValues>({
    defaultValues: initialValues,
  });
  const isBlockchain = collection.blockchain !== BlockchainNetwork.STICKY;

  const handleAddNfts = () => {
    openModal(<AddNftsModal collection={collection} />);
  };

  const handleRemoveCollection = async () => {
    try {
      setIsDeleting(true);
      await deleteCollection(collection.uuid);
      notify('Collection deleted successfully', { variant: 'success' });
      navigate(Routes.objects.collections, { replace: true });
    } catch (e) {
      notify(getMessageFromError(e), { variant: 'error' });
    }
    setIsDeleting(false);
  };

  return (
    <Modal open={open} onClose={onClose}>
      <Card sx={{ minWidth: 500 }}>
        <CardHeader
          title={initialValues ? 'Edit Collection' : 'Create Collection'}
          action={
            <Flex gap={1}>
              <Tooltip title="Delete Collection" arrow placement="top">
                <Button
                  loading={isDeleting}
                  color="error"
                  onClick={handleRemoveCollection}
                  sx={{ minWidth: 'unset' }}
                >
                  <Delete />
                </Button>
              </Tooltip>
              {!isBlockchain && (
                <Tooltip title="Add NFTs" arrow placement="top">
                  <Button
                    color="info"
                    sx={{ minWidth: 'unset' }}
                    onClick={handleAddNfts}
                  >
                    <Add />
                  </Button>
                </Tooltip>
              )}
            </Flex>
          }
        />
        <form onSubmit={handleSubmit(onSubmit)}>
          <CardContent>
            <Stack spacing={2}>
              <ControllerField
                control={control}
                name="name"
                label="Collection Name"
                required
                fullWidth
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <Collections />
                    </InputAdornment>
                  ),
                }}
              />
              <Flex gap={2}>
                <ControllerField
                  control={control}
                  name="tags"
                  label="Tags"
                  placeholder="Ex.: Tag1,Tag2"
                  fullWidth
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <LocalOffer />
                      </InputAdornment>
                    ),
                  }}
                />
                <ControllerField
                  control={control}
                  name="priceType"
                  select
                  label="Price Type"
                  required
                  fullWidth
                >
                  {PRICE_TYPE_OPTIONS.map((option) => (
                    <SelectOption key={option} value={option}>
                      {capitalCase(option)}
                    </SelectOption>
                  ))}
                </ControllerField>
              </Flex>

              <ControllerField
                control={control}
                name="description"
                label="Description"
                multiline
                minRows={2}
              />
            </Stack>
            <Flex justifyContent="space-between" mt={2}>
              <Button
                onClick={() => {
                  reset(initialValues);
                  onClose();
                }}
                color="error"
              >
                Cancel
              </Button>
              <Button
                color="secondary"
                variant="contained"
                type="submit"
                loading={isSubmitting}
              >
                Save
              </Button>
            </Flex>
          </CardContent>
        </form>
      </Card>
    </Modal>
  );
}

const PRICE_TYPE_OPTIONS = ['free', 'paid'];
