import { OpenInNew } from '@mui/icons-material';
import { IconButton } from '@mui/material';
import { GridSortDirection } from '@mui/x-data-grid';
import Link from 'src/components/shared/Link';
import MagicTable from 'src/components/shared/MagicTable/MagicTable';
import MagicTableUserCell from 'src/components/shared/MagicTable/UserCell';
import useURLSearchParams from 'src/lib/hooks/useURLSearchParams';
import { WithUser } from 'src/lib/types/users';
import Routes from 'src/routes/Routes';
import Label from 'src/template/components/Label';
import { NftPackDelivery } from '../../../types/NftPackDelivery';

type Props = {
  data: WithUser<NftPackDelivery>[];
  isLoading: boolean;
  page: number;
  size: number;
  totalCount: number;
  sortBy: string;
  order: GridSortDirection;
  sortable?: boolean;
};
export default function NFTPackDeliveriesTable({
  data,
  isLoading,
  page,
  size,
  totalCount,
  sortBy,
  order,
  sortable = true,
}: Props): JSX.Element {
  const { addParam, removeParam } = useURLSearchParams();

  return (
    <MagicTable
      rows={data}
      getRowId={(r) => r.uuid}
      loading={isLoading}
      paginationMode="server"
      pageSize={size}
      page={page}
      rowCount={totalCount}
      onPageChange={(newPage) => {
        addParam('page', newPage);
      }}
      onPageSizeChange={(newSize) => {
        addParam('size', newSize);
      }}
      sortModel={[{ field: sortBy, sort: order }]}
      onSortModelChange={
        sortable
          ? (model) => {
              const currentModel = model[0];
              if (currentModel) {
                addParam('sortBy', currentModel.field);
                if (currentModel.sort) addParam('order', currentModel.sort);
              } else {
                removeParam('order');
                removeParam('sortBy');
              }
            }
          : undefined
      }
      columns={[
        {
          field: 'user',
          headerName: 'User',
          renderCell: ({ row }) => <MagicTableUserCell user={row.user} />,
          sortable: false,
        },
        {
          field: 'user.active',
          headerName: 'User Status',
          align: 'center',
          sortable: false,
          flex: 1 / 2,
          renderCell: ({ row }) => {
            if (row.user.active) {
              return <Label color="success">Active</Label>;
            }
            return <Label color="error">Inactive</Label>;
          },
        },
        {
          field: 'nftsAmount',
          headerName: '# Nfts',
          align: 'right',
          sortable: false,
          flex: 0,
        },
        { field: 'status', headerName: 'Status', sortable: false, flex: 0 },
        {
          field: 'product',
          headerName: 'Product',
          renderCell: ({ row }) => row.product.name,
          sortable: false,
        },
        {
          field: 'objectId',
          headerName: 'Receipt',
          sortable: false,
        },
        {
          field: 'activeAt',
          headerName: 'To Deliver At',
          sortComparator: () => 0,
          sortingOrder: ['desc', 'asc'],
        },
        {
          field: 'updatedAt',
          headerName: 'Delivered At',
          sortComparator: () => 0,
          sortingOrder: ['desc', 'asc'],
          renderCell({ row, value }) {
            if (row.status === 'complete') {
              return value;
            }
            return '--';
          },
        },
        {
          field: 'actions',
          headerName: '',
          sortable: false,
          flex: 0,
          align: 'center',
          renderCell: ({ row }) =>
            row.giveNftPoolUuid ? (
              <IconButton
                component={Link}
                disabled={!row.giveNftPoolUuid}
                to={Routes.transactions.pool(row.giveNftPoolUuid)}
              >
                <OpenInNew />
              </IconButton>
            ) : null,
        },
      ]}
    />
  );
}
