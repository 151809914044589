import { Alert, Box } from '@mui/material';
import { Link } from 'react-router-dom';
import { User } from 'src/lib/types/users';
import { formatTimeToHuman } from 'src/lib/utils/formatTimeToHuman';
import { PurgeUseCaseStatuses } from '../../Profile/types/UserPurge';
import { useFetchUserPurgeStatus } from '../services/useFetchUserPurgeStatus';

const getAlertColorByPurgeStatus = (status: PurgeUseCaseStatuses) => {
  switch (status) {
    case PurgeUseCaseStatuses.stop_requested:
      return 'warning';
    case PurgeUseCaseStatuses.stopped:
      return 'info';
    case PurgeUseCaseStatuses.running:
      return 'info';
    case PurgeUseCaseStatuses.completed:
      return 'success';
    default:
      return 'error';
  }
};

const getUserIdentifier = (user: User | undefined) => {
  if (!user) return 'Unknown';
  if (user.slug) return `@${user.slug}`;
  if (user.name) return user.name;
  if (user.email) return user.email;
  return `with UUID: ${user.uuid}`;
};

export const PURGE_ALERT_BAR_HEIGHT = 48;

export function PurgeAlertBar() {
  const { data } = useFetchUserPurgeStatus();

  if (!data) return null;

  const { currentPurge, totalPurgesLeft, remainingTime, status } = data;

  switch (data.status) {
    case PurgeUseCaseStatuses.stop_requested:
      return (
        <Box width={'100%'} top={0} left={0} height={PURGE_ALERT_BAR_HEIGHT}>
          <Alert
            severity={getAlertColorByPurgeStatus(status)}
            variant="outlined"
            sx={{ width: '100%', borderRadius: 0 }}
          >
            Purge stop requested. Waiting for the current purge to finish.{' '}
            <Link to={`/user-purges/`}>See full Purge List.</Link>
          </Alert>
        </Box>
      );
    case PurgeUseCaseStatuses.stopped:
      if (totalPurgesLeft === 0) return null;
      return (
        <Box width={'100%'} top={0} left={0} height={PURGE_ALERT_BAR_HEIGHT}>
          <Alert
            severity={getAlertColorByPurgeStatus(data.status)}
            variant="outlined"
            sx={{ width: '100%', borderRadius: 0 }}
          >
            {totalPurgesLeft} users waiting for purge.{' '}
            <Link to={`/user-purges/`}>See full Purge List.</Link>
          </Alert>
        </Box>
      );
    case PurgeUseCaseStatuses.running:
      return (
        <Box width={'100%'} top={0} left={0} height={PURGE_ALERT_BAR_HEIGHT}>
          <Alert
            severity={getAlertColorByPurgeStatus(data.status)}
            variant="filled"
            sx={{ width: '100%', borderRadius: 0 }}
          >
            Purge running for User {getUserIdentifier(currentPurge?.user)}.
            Estimated remaining time: {formatTimeToHuman(remainingTime)}.{' '}
            <Link to={`/user-purges/`}>See full Purge List.</Link>
          </Alert>
        </Box>
      );
    default:
      return null;
  }
}
