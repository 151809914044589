import { Card, Tab, Tabs } from '@mui/material';
import { Suspense } from 'react';
import Flex from 'src/components/shared/Flex';
import Loader from 'src/components/shared/Loader';
import HeaderBreadcrumbs from 'src/template/components/HeaderBreadcrumbs';
import useTabs from 'src/template/hooks/useTabs';
import EVENTS_TABS from '../../EventsTabs';

export default function UserManagementEvents() {
  const { currentTab, onChangeTab } = useTabs(defaultTab, {
    resetOnChange: true,
  });
  return (
    <>
      <HeaderBreadcrumbs
        heading="User Management Events"
        links={[{ name: 'User Management Events' }]}
      />
      <Card>
        <Flex sx={{ px: 2, bgcolor: 'background.neutral' }}>
          <Tabs value={currentTab} onChange={onChangeTab} centered>
            {EVENTS_TABS.map((activity) => (
              <Tab
                disableRipple
                key={activity.value}
                label={activity.value}
                value={activity.value}
              />
            ))}
          </Tabs>
        </Flex>

        <Suspense fallback={<Loader />}>
          {EVENTS_TABS.map(
            (tab) => tab.value === currentTab && tab.component()
          )}
        </Suspense>
      </Card>
    </>
  );
}

const defaultTab = EVENTS_TABS[0].value;
