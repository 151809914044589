import { sgmFetcher } from 'src/lib/services/sgm/fetcher';
import { SGMChatMessageError } from 'src/lib/types/sgmChatMessage';
import { parseSearchParams } from 'src/lib/utils/parseSearchParams';
import useSWR, { SWRResponse } from 'swr';

export const fetchMessageErrors = (
  messageId: string
): SWRResponse<SGMChatMessageError[]> => {
  const parsedParams = parseSearchParams({
    filter: {
      messageId,
    },
  });
  const response = useSWR<SGMChatMessageError[], Error>(
    `/api/admin/chat-errors?${parsedParams}`,
    sgmFetcher
  );

  return response;
};
