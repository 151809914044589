import {
  Card,
  InputAdornment,
  MenuItem,
  Stack,
  TextField,
} from '@mui/material';
import DebouncedField from 'src/components/shared/Inputs/DebouncedField';
import useDebounce from 'src/lib/hooks/useDebounce';
import { fetchAdminNftTransfers } from 'src/lib/services/adminNftTransfers';
import HeaderBreadcrumbs from 'src/template/components/HeaderBreadcrumbs';
import Iconify from 'src/template/components/Iconify';
import Scrollbar from 'src/template/components/Scrollbar';
import NftsTableFooter from './AdminNftTransfersTable/Footer';
import AdminNftTransfersTable from './AdminNftTransfersTable/Table';
import useNftsTable, {
  SearchForOptions,
} from './AdminNftTransfersTable/useAdminNftTransferTable';

const SEARCH_OPTIONS = [
  {
    label: 'NFT name',
    value: 'nft.name',
  },
  {
    label: 'Nft uuid',
    value: 'nftUuid',
  },
  {
    label: `User From (uuid)`,
    value: 'ownerGlobalWallet.userUuid',
  },
  {
    label: `User From (slug)`,
    value: 'ownerGlobalWallet.user.slug',
  },
  {
    label: `User From (Name)`,
    value: 'ownerGlobalWallet.user.name',
  },
  {
    label: `User From (email)`,
    value: 'ownerGlobalWallet.user.email',
  },
  {
    label: `User To (uuid)`,
    value: 'receiverGlobalWallet.userUuid',
  },
  {
    label: `User To (slug)`,
    value: 'receiverGlobalWallet.user.slug',
  },
  {
    label: `User To (Name)`,
    value: 'receiverGlobalWallet.user.name',
  },
  {
    label: `User To (email)`,
    value: 'receiverGlobalWallet.user.email',
  },
  {
    label: `Triggered By (uuid)`,
    value: 'triggeredByUserUuid',
  },
  {
    label: `Triggered By (slug)`,
    value: 'triggeredByUser.slug',
  },
  {
    label: `Triggered By (Name)`,
    value: 'triggeredByUser.name',
  },
  {
    label: `Triggered By (email)`,
    value: 'triggeredByUser.email',
  },
  {
    label: 'Tx. Pool (uuid)',
    value: 'poolUuid',
  },
];

export default function AdminNftTransfersList() {
  const { page, query, searchFor, size } = useNftsTable(
    (state) => state.filter
  );

  const [setQuery, setSearchFor] = useNftsTable((state) => [
    state.setQuery,
    state.setSearchFor,
  ]);

  const filter: any = {};
  const filterQuery = useDebounce(query, 500);
  if (searchFor && filterQuery !== '') {
    filter[searchFor] = {
      like: `%${filterQuery}%`,
    };
  }
  const {
    data: adminNftTransfers,
    meta,
    loading,
  } = fetchAdminNftTransfers({
    filter,
    page,
    size,
  });

  const total = meta?.totalCount || 0;

  const searchHighlight = {
    searchColumn: searchFor,
    searchKeyword: filterQuery,
  };

  return (
    <>
      <HeaderBreadcrumbs
        heading="Admin NFT Transfers"
        links={[{ name: 'Admin NFT Transfers' }]}
      />
      <Card>
        <Stack
          spacing={2}
          direction={{ xs: 'column', sm: 'row' }}
          sx={{ py: 2.5, px: 3 }}
        >
          <TextField
            fullWidth
            select
            label="Search for"
            value={searchFor}
            onChange={(e) => setSearchFor(e.target.value as SearchForOptions)}
            SelectProps={{
              MenuProps: {
                sx: { '& .MuiPaper-root': { maxHeight: 260 } },
              },
            }}
            sx={{
              maxWidth: { sm: 240 },
              textTransform: 'capitalize',
            }}
          >
            {SEARCH_OPTIONS.map((option) => (
              <MenuItem
                key={option.value}
                value={option.value}
                sx={{
                  mx: 1,
                  my: 0.5,
                  borderRadius: 0.75,
                  typography: 'body2',
                  textTransform: 'capitalize',
                }}
              >
                {option.label}
              </MenuItem>
            ))}
          </TextField>

          <DebouncedField
            fullWidth
            onDebounceChange={(e) => setQuery(e)}
            placeholder="Type here your search text..."
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <Iconify
                    icon="eva:search-fill"
                    sx={{ color: 'text.disabled', width: 20, height: 20 }}
                  />
                </InputAdornment>
              ),
            }}
          />
        </Stack>

        <Scrollbar>
          <AdminNftTransfersTable
            data={adminNftTransfers}
            loading={loading}
            searchHighlight={searchHighlight}
          />
        </Scrollbar>

        <NftsTableFooter total={total || 0} page={page} rowsPerPage={size} />
      </Card>
    </>
  );
}
