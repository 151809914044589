import { getMessageFromError } from 'src/lib/utils/error';
import { parseSearchParams } from 'src/lib/utils/parseSearchParams';
import useSWR, { SWRResponse } from 'swr';
import {
  SubscriptionReportType,
  SubscriptionReportsTotalizers,
} from '../types/Subscription';

type PossibleParams = {
  startDate: string;
  endDate: string;
  country?: string;
};

export function fetchSubscriptionsReport(
  params: PossibleParams
): SWRResponse<SubscriptionReportType> {
  const parsedParams = parseSearchParams(params ?? {});
  const { error, ...response } = useSWR(
    `admin/subscription/notifications-report-filtered?${parsedParams}`
  );

  return {
    ...response,
    error: error ? getMessageFromError(error) : undefined,
  };
}

export function fetchSubscriptionsTotalizers(): SWRResponse<SubscriptionReportsTotalizers> {
  const { error, ...response } = useSWR(
    `admin/subscription/notifications-report-all-time`
  );

  return {
    ...response,
    error: error ? getMessageFromError(error) : undefined,
  };
}
