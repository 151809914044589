import useNotify from 'src/lib/hooks/useNotify';
import useModalStore from 'src/lib/stores/useModalStore';
import { NftPackStatus } from 'src/lib/types/nftPack';
import { NftPackEmitter } from '../../../events/NftPackEmitter';
import { ChangeNftPackStatus } from '../../../services/changeNftPackStatus';

export default function useChangeNftPackStatus() {
  const closeModal = useModalStore((state) => state.closeModal);
  const notify = useNotify();

  const handleChangeNftPackStatus = async (
    uuid: string,
    status: NftPackStatus
  ) => {
    const { data, error } = await ChangeNftPackStatus(uuid, status);
    if (error) {
      notify(error, { variant: 'error' });
      closeModal();
    } else {
      notify('Nft Pack status Updated!', { variant: 'success' });
      closeModal();
      NftPackEmitter.emit('NftPackUpdated', data);
    }
  };

  return {
    handleChangeNftPackStatus,
  };
}
