import getAPIClient from 'src/lib/services/api/axios.config';
import { ServiceResponse } from 'src/lib/types/ServiceResponse';
import { NftPack } from 'src/lib/types/nftPack';
import { getMessageFromError } from 'src/lib/utils/error';

export type UpdateNftpackData = {
  uuid: string;
  name: string;
  productUuid: string;
  type: string;
  amountOfNftsToRecharge: number;
};

export async function UpdateNftpack(
  data: UpdateNftpackData
): Promise<ServiceResponse<NftPack>> {
  try {
    const api = await getAPIClient();
    const res = await api.post(`admin/nft-pack/`, data);
    return {
      data: res.data,
      error: null,
    };
  } catch (e) {
    return {
      data: null,
      error: getMessageFromError(e),
    };
  }
}
