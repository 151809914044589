import { CommonDateStrings } from 'src/lib/constants/CommonDates';
import { DEFAULT_INITIAL_PAGE_SIZE } from 'src/lib/constants/pagination';
import { IncludeExcludeAll } from 'src/lib/utils/includeExcludeFilterUtils';
import { objectValuesToString } from 'src/lib/utils/objectValuesToString';
import removeEmptyFieldsFromObject from 'src/lib/utils/removeEmptyFieldsFromObject';
import { RankFilterFormValues } from '../components/Rankings/BuyerAndSellerRankTable/types';

export const INITIAL_FILTERS: RankFilterFormValues = {
  rankBy: 'purchases',
  FreePurchases: IncludeExcludeAll.Exclude,
  InactiveUsers: IncludeExcludeAll.Exclude,
  NftPack: IncludeExcludeAll.All,
  country: '',
  lang: '',
  nftOrigin: 'all',
  userType: 'all',
  endDate: CommonDateStrings.today.date,
  startDate: CommonDateStrings.firstStickyOrderBookMatch.date,
};

export const INITIAL_SEARCH_PARAMS_STATE = {
  page: '0',
  size: String(DEFAULT_INITIAL_PAGE_SIZE),
  ...removeEmptyFieldsFromObject(objectValuesToString(INITIAL_FILTERS)),
};
