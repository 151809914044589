import ConfirmationDialogRaw from 'src/components/shared/Dialog/ConfirmationDialog';
import useModalStore from 'src/lib/stores/useModalStore';
import useRestorePromptVersion from './useView';

type Props = {
  type: string;
  uuid: string;
};
export default function RestorePromptVersionView({
  type,
  uuid,
}: Props): JSX.Element {
  const { handleRestorePromptVersion } = useRestorePromptVersion();
  const closeModal = useModalStore((state) => state.closeModal);

  return (
    <ConfirmationDialogRaw
      onClose={closeModal}
      open
      title={`Restore Prompt Version`}
      onConfirm={async () => {
        await handleRestorePromptVersion(type, uuid);
      }}
    >
      Are you sure you want to restore this prompt version?
    </ConfirmationDialogRaw>
  );
}
