import {
  CircularProgress,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
} from '@mui/material';
import Center from 'src/components/shared/Center';
import { TableHeadCustom } from 'src/template/components/table';
import { IHeadLabel } from 'src/template/components/table/TableHeadCustom';

import { AdminNftTransfer } from 'src/lib/types/adminNftTransfer';
import AdminNftTransfersTableRow from './Row';
import useAdminNftTransferTable from './useAdminNftTransferTable';

const TABLE_HEAD: IHeadLabel[] = [
  { id: 'nftUuid', label: 'NFT' },
  { id: 'editions', label: 'Editions' },
  { id: 'ownerGlobalWalletUuid', label: 'From' },
  { id: 'receiverGlobalWalletUuid', label: 'To' },
  { id: 'triggeredByUserUuid', label: 'Triggered By' },
  { id: 'updatedAt', label: 'Updated', align: 'right' },
  { id: 'createdAt', label: 'Created', align: 'right' },
  { id: 'poolUuid', label: 'Tx. Pool', align: 'right' },
];

export default function AdminNftTransfersTable({
  data,
  loading,
  searchHighlight,
}: {
  data: AdminNftTransfer[] | undefined;
  loading: boolean;
  searchHighlight: { searchColumn: string; searchKeyword: string };
}) {
  const [order, orderBy] = useAdminNftTransferTable((state) => [
    state.filter.order,
    state.filter.orderBy,
  ]);

  const onSort = useAdminNftTransferTable((state) => state.onSort);

  const dense = useAdminNftTransferTable((state) => state.dense);

  return (
    <TableContainer sx={{ minWidth: '100%', position: 'relative' }}>
      <Table size={dense ? 'small' : 'medium'}>
        <TableHeadCustom
          order={order}
          orderBy={orderBy}
          headLabel={TABLE_HEAD}
          rowCount={loading ? 0 : data && data.length}
          onSort={onSort}
        />
        <TableBody>
          {loading || !data ? (
            <TableRow>
              <TableCell colSpan={TABLE_HEAD.length + 1}>
                <Center>
                  <CircularProgress />
                </Center>
              </TableCell>
            </TableRow>
          ) : (
            data &&
            data.map((transfer: AdminNftTransfer) => (
              <AdminNftTransfersTableRow
                key={transfer.uuid}
                transfer={transfer}
                searchHighlight={searchHighlight}
              />
            ))
          )}
        </TableBody>
      </Table>
    </TableContainer>
  );
}
