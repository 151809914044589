import useURLSearchParams from 'src/lib/hooks/useURLSearchParams';
import useModalStore from 'src/lib/stores/useModalStore';
import { IncludeExcludeAll } from 'src/lib/utils/includeExcludeFilterUtils';
import BalanceRankFilter from '../../../components/Rankings/FlaggedTransactionsRankingTable/Filter';
import { Params } from '../../../components/Rankings/FlaggedTransactionsRankingTable/types';
import {
  INITIAL_FILTERS,
  INITIAL_SEARCH_PARAMS_STATE,
} from '../../../constants/FlaggedTransactionsRanking';

import {
  fetchFlaggedTransactionsRanking,
  UserBehavior,
} from '../../../services/fetchFlaggedTransactionsRanking';
import { UserType } from '../../../types/UserType';
import { valueIfDifferentOf } from '../../../utils/valueIfDifferentOf';

export default function useFlaggedTransactionsView() {
  const openModal = useModalStore((state) => state.openModal);
  const { allSearchParams } = useURLSearchParams<Params>(
    INITIAL_SEARCH_PARAMS_STATE
  );

  const { data, isLoading } = fetchFlaggedTransactionsRanking({
    page: allSearchParams.page,
    size: allSearchParams.size,
    userType: valueIfDifferentOf(allSearchParams.userType as UserType, 'all'),
    userBehavior: allSearchParams.userBehavior as UserBehavior,
    filter: {
      country: allSearchParams.country,
      lang: allSearchParams.lang,
      InactiveUsers: allSearchParams.InactiveUsers as IncludeExcludeAll,

      endDate: allSearchParams.endDate
        ? `${allSearchParams.endDate}T23:59:59.000Z`
        : undefined,
      startDate: allSearchParams.startDate
        ? `${allSearchParams.startDate}T00:00:00.000Z`
        : undefined,
    },
  });

  function handleOpenFilter() {
    openModal(
      <BalanceRankFilter
        initialValues={{
          country: allSearchParams.country ?? INITIAL_FILTERS.country,
          lang: allSearchParams.lang ?? INITIAL_FILTERS.lang,
          userBehavior:
            (allSearchParams.userBehavior as UserBehavior) ??
            INITIAL_FILTERS.userBehavior,
          startDate: allSearchParams.startDate ?? INITIAL_FILTERS.startDate,
          endDate: allSearchParams.endDate ?? INITIAL_FILTERS.endDate,
          InactiveUsers:
            (allSearchParams.InactiveUsers as IncludeExcludeAll) ??
            INITIAL_FILTERS.InactiveUsers,
          userType:
            (allSearchParams.userType as UserType) ?? INITIAL_FILTERS.userType,
        }}
      />
    );
  }
  return {
    data,
    isLoading,
    handleOpenFilter,
    currentPage: Number(
      allSearchParams.page ?? INITIAL_SEARCH_PARAMS_STATE.page
    ),
    currentSize: Number(
      allSearchParams.size ?? INITIAL_SEARCH_PARAMS_STATE.size
    ),
  };
}
