import { Container } from '@mui/material';
import Routes from 'src/routes/Routes';
import HeaderBreadcrumbs from 'src/template/components/HeaderBreadcrumbs';
import GameRankingsTable from '../../components/GameRankingsTable';
import useGameRankings from './useView';

export default function GameRankings() {
  const { data, isLoading, params, handleOpenListAssetsUsed } =
    useGameRankings();

  return (
    <Container maxWidth="xl">
      <HeaderBreadcrumbs
        heading="Rankings"
        links={[
          { name: 'Games', href: Routes.game_center.games },
          { name: 'Rankings' },
        ]}
      />
      <GameRankingsTable
        data={data}
        isLoading={isLoading}
        params={params}
        handleOpenListAssetsUsed={handleOpenListAssetsUsed}
      />
    </Container>
  );
}
