import { SubmissionFormStatuses } from 'src/lib/types/formReviewStatus';
import ReviewSubmisisonForm from '../../components/Forms/ReviewSubmisison';
import useReviewSubmission from './useView';

type Props = {
  submissionUuid: string;
};
export default function ReviewSubmission({
  submissionUuid,
}: Props): JSX.Element {
  const { handleReviewSubmission } = useReviewSubmission(submissionUuid);
  return (
    <ReviewSubmisisonForm
      defaultValues={{
        reviewerComment: '',
        status: SubmissionFormStatuses.AWAITING_REVIEW,
      }}
      title={`Review Submission`}
      onSubmit={async (values) => {
        await handleReviewSubmission({ ...values });
      }}
    />
  );
}
