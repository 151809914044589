import { Add } from '@mui/icons-material';
import { Button, Container } from '@mui/material';
import Flex from 'src/components/shared/Flex';
import Routes from 'src/routes/Routes';
import HeaderBreadcrumbs from 'src/template/components/HeaderBreadcrumbs';
import ContestsTable from '../../components/ContestsTable/ContestsTable';
import useContests from './useView';

interface Props {
  hideBreadcrumb?: boolean;
}

export default function Contests({ hideBreadcrumb = false }: Props) {
  const {
    data,
    isLoading,
    params,
    gameUuid,
    handleOpenCreateModal,
    handleOpenUpdateModal,
    handleOpenDeleteModal,
    handleOpenChangeStatusModal,
  } = useContests();

  return (
    <Container maxWidth="xl">
      {!hideBreadcrumb ? (
        <HeaderBreadcrumbs
          heading="Game Contests"
          links={[
            ...(gameUuid
              ? [{ name: 'Games', href: Routes.game_center.games }]
              : []),
            { name: 'Contests' },
          ]}
          action={
            gameUuid ? (
              <AddContestButton handleOpenCreateModal={handleOpenCreateModal} />
            ) : undefined
          }
        />
      ) : (
        <Flex justifyContent={'flex-end'} pb={2}>
          <AddContestButton handleOpenCreateModal={handleOpenCreateModal} />
        </Flex>
      )}
      <ContestsTable
        data={data}
        isLoading={isLoading}
        params={params}
        handleOpenUpdateModal={handleOpenUpdateModal}
        handleOpenDeleteModal={handleOpenDeleteModal}
        handleOpenChangeStatusModal={handleOpenChangeStatusModal}
      />
    </Container>
  );
}

interface AddContestButtonProps {
  handleOpenCreateModal: () => void;
}

function AddContestButton({ handleOpenCreateModal }: AddContestButtonProps) {
  return (
    <Button
      variant="contained"
      color="secondary"
      onClick={handleOpenCreateModal}
      startIcon={<Add />}
    >
      Add Contest
    </Button>
  );
}
