import { useCallback, useEffect } from 'react';
import { useParams } from 'react-router';
import { DEFAULT_INITIAL_PAGE_SIZE } from 'src/lib/constants/pagination';
import useURLSearchParams from 'src/lib/hooks/useURLSearchParams';
import useModalStore from 'src/lib/stores/useModalStore';
import { GameStatistic } from 'src/lib/types/gameStatistic';
import { GameStatisticGroup } from 'src/lib/types/statisticGroup';
import { GameStatisticGroupEmitter } from '../../events/GameStatisticGroupEmitter';
import { fetchStatisticGroupsByGame } from '../../services/fetchStatisticGroupsByGame';
import CreateStatisticGroupView from '../Create';
import DeleteStatisticGroupView from '../Delete';
import ListStatisticsView from '../ListStatistics';
import UpdateStatisticGroupView from '../Update';

export default function useGameStatisticGroups() {
  const { allSearchParams } = useURLSearchParams(initialParams);
  const { gameUuid } = useParams<{
    gameUuid: string;
  }>();

  const [openModal] = useModalStore((state) => [
    state.openModal,
    state.closeModal,
  ]);

  const handleOpenCreateStatisticGroupModal = async () => {
    openModal(<CreateStatisticGroupView gameUuid={gameUuid!} />);
  };
  const handleOpenUpdateStatisticGroupModal = async (
    group: GameStatisticGroup
  ) => {
    openModal(<UpdateStatisticGroupView gameUuid={gameUuid!} group={group} />);
  };
  const handleDeleteStatisticGroup = async (statisticGroupUuid: string) => {
    openModal(
      <DeleteStatisticGroupView statisticGroupUuid={statisticGroupUuid} />
    );
  };
  const handleOpenListGroupStatistics = async (statistics: GameStatistic[]) => {
    openModal(<ListStatisticsView statistics={statistics} />);
  };

  const params = {
    page: +(allSearchParams.page ?? initialParams.page),
    size: +(allSearchParams.size ?? initialParams.size),
    orderBy: allSearchParams.orderBy ?? initialParams.orderBy,
    sortOrder: allSearchParams.sortOrder ?? initialParams.order,
  };

  const { data, isLoading, mutate } = fetchStatisticGroupsByGame(gameUuid!, {
    ...(params.orderBy &&
      params.sortOrder && {
        order: {
          [params.orderBy]: params.sortOrder,
        },
      }),
  });

  const refetchData = useCallback(() => {
    mutate?.();
  }, []);

  useEffect(() => {
    GameStatisticGroupEmitter.on('GameStatisticGroupCreated', refetchData);
    GameStatisticGroupEmitter.on('GameStatisticGroupUpdated', refetchData);
    GameStatisticGroupEmitter.on('GameStatisticGroupDeleted', refetchData);

    return () => {
      GameStatisticGroupEmitter.off('GameStatisticGroupCreated', refetchData);
      GameStatisticGroupEmitter.off('GameStatisticGroupUpdated', refetchData);
      GameStatisticGroupEmitter.off('GameStatisticGroupDeleted', refetchData);
    };
  }, [refetchData]);

  return {
    data,
    isLoading,
    params,
    gameUuid,
    handleOpenCreateStatisticGroupModal,
    handleOpenUpdateStatisticGroupModal,
    handleDeleteStatisticGroup,
    handleOpenListGroupStatistics,
  };
}

const initialParams = {
  page: '0',
  size: DEFAULT_INITIAL_PAGE_SIZE.toString(),
  order: 'desc',
  orderBy: 'createdAt',
};

export type FetchGameStatisticGroupsQueryParams = {
  page: number;
  size: number;
  orderBy: string;
  sortOrder: string;
};
