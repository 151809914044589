import { Container } from '@mui/material';
import ChannelVideos from 'src/components/modules/ChannelContent/view/List';
import Page from 'src/template/components/Page';

export default function ChannelVideosPage() {
  return (
    <Page title="Channel Videos">
      <Container maxWidth="xl">
        <ChannelVideos />
      </Container>
    </Page>
  );
}
