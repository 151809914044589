import {
  Alert,
  Box,
  Card,
  CardActions,
  CardContent,
  CardHeader,
  MenuItem,
  Stack,
  Tooltip,
  Typography,
} from '@mui/material';
import { useSnackbar } from 'notistack';
import { useForm } from 'react-hook-form';
import Button from 'src/components/shared/Buttons/CustomButton';
import ControllerField from 'src/components/shared/Inputs/ControllerField';
import Modal from 'src/components/shared/Modal';
import { updatePaymentRequestStatus } from 'src/lib/services/paymentRequests';
import { PaymentRequest } from 'src/lib/types/paymentRequest';
import usePayoutEligibility from '../../usePayoutEligibility';
import { UpdatePaymentStatusValues } from './types';

type Props = {
  open: boolean;
  onClose: () => void;
  paymentRequest: PaymentRequest;
};

const STATUS_OPTIONS = [
  { value: 'requested', label: 'Requested' },
  { value: 'canceled', label: 'Canceled' },
  { value: 'executed', label: 'Executed' },
  { value: 'scheduled', label: 'Scheduled' },
  { value: 'rejected', label: 'Rejected' },
];

export default function UpdatePaymentRequestStatusModal({
  open,
  onClose,
  paymentRequest,
}: Props) {
  const { enqueueSnackbar } = useSnackbar();
  const { isFullyEligible, eligibilityMessage, missingRules } =
    usePayoutEligibility(paymentRequest.userUuid);
  const {
    control,
    handleSubmit,
    watch,
    formState: { isSubmitting: isLoading },
  } = useForm<UpdatePaymentStatusValues>({
    defaultValues: {
      status: undefined,
      rejectedReason: isFullyEligible
        ? 'Basic requirements met, but: '
        : 'Don’t meet basic requirements',
    },
  });
  const currentStatus = watch('status');

  const onApply = async (values: UpdatePaymentStatusValues) => {
    const res = await updatePaymentRequestStatus(paymentRequest.uuid, {
      status: values.status,
      rejectedReason: values.rejectedReason,
      updateNote: values.updateNote,
    });
    if (res.error) {
      enqueueSnackbar(res.error, { variant: 'error' });
      return;
    }
    enqueueSnackbar('Payment Request Status Updated', { variant: 'success' });

    onClose();
  };

  return (
    <Modal open={open} onClose={onClose}>
      <form onSubmit={handleSubmit(onApply)}>
        <Card
          sx={{
            width: 400,
          }}
        >
          <CardHeader title="Update Payment Request Status" />
          <CardContent>
            <Stack spacing={2}>
              <ControllerField
                name="status"
                control={control}
                label="Review Status"
                select
                fullWidth
                required
              >
                {STATUS_OPTIONS.filter(
                  (option) => option.value != paymentRequest.status
                ).map((option) => (
                  <MenuItem key={option.value} value={option.value}>
                    {option.label}
                  </MenuItem>
                ))}
              </ControllerField>
              {currentStatus === 'rejected' && (
                <ControllerField
                  control={control}
                  name="rejectedReason"
                  label="Reason"
                  fullWidth
                  required
                />
              )}
              <ControllerField
                control={control}
                name="updateNote"
                label="Update Note"
                multiline
                minRows={3}
              />
            </Stack>
            <Tooltip
              placement="left"
              title={
                <Box>
                  <Typography>Missing Rules:</Typography>
                  <ul
                    style={{
                      listStyle: 'inside',
                    }}
                  >
                    {missingRules.map((rule) => (
                      <li key={rule}>{rule}</li>
                    ))}
                  </ul>
                </Box>
              }
            >
              <Alert
                severity={isFullyEligible ? 'success' : 'error'}
                variant="outlined"
                sx={{ mt: 2 }}
              >
                {eligibilityMessage}
              </Alert>
            </Tooltip>
          </CardContent>
          <CardActions
            sx={{
              padding: 2,
              paddingTop: 0,
            }}
          >
            <Stack direction="row" justifyContent="space-between" width="100%">
              <Button color="error" onClick={onClose}>
                Cancel
              </Button>
              <Button
                type="submit"
                loading={isLoading}
                variant="contained"
                color="secondary"
              >
                Update
              </Button>
            </Stack>
          </CardActions>
        </Card>
      </form>
    </Modal>
  );
}
