import {
  Card,
  CardProps,
  CircularProgress,
  Stack,
  Table,
  TableBody,
  TableContainer,
} from '@mui/material';
import { ReactElement, ReactNode, useMemo } from 'react';
import { Order } from 'src/lib/utils/query/types';
import Scrollbar from 'src/template/components/Scrollbar';
import {
  TableHeadCustom,
  TableSelectedActions,
} from 'src/template/components/table';
import { IHeadLabel } from 'src/template/components/table/TableHeadCustom';
import Center from '../Center';
import { EldenTableRowProps } from './Widgets/EldenTableRow';

export default function EldenTable({
  children: rows,
  data,
  order,
  orderBy,
  onSort,
  loading,
  cardProps,
  hiddenMenu,
  header,
  footer,
  select,
}: EldenTableProps) {
  const headLabel = useMemo((): IHeadLabel[] => {
    if (!rows || rows?.length === 0) return [];

    return rows[0].props.children
      .map((el) =>
        el && !el.props.hide
          ? {
              id: el.props.id,
              label: el.props.label,
              align: el.props.align,
              key: el.props.id,
            }
          : null
      )
      .filter((row) => row != null || row != undefined) as IHeadLabel[];
  }, [rows]);

  const handleSort = (column: string) => {
    if (!rows) return;

    const isSortable = rows[0].props.children.find(
      (row) => row?.props.id === column
    )?.props.sortable;

    if (isSortable && onSort) {
      onSort({
        order: orderBy === column ? (order === 'asc' ? 'desc' : 'asc') : 'asc',
        orderBy: column,
      });
    }
  };

  return (
    <Card {...cardProps}>
      <Stack gap={1} pt={1}>
        <>
          {header ? (
            <Stack
              gap={1.5}
              direction="row"
              alignItems="center"
              py={1}
              px={1.5}
              width="100%"
            >
              {header}
            </Stack>
          ) : null}
          {hiddenMenu}
          <Scrollbar>
            <TableContainer sx={{ minWidth: '100%', position: 'relative' }}>
              {select
                ? select.selected.length > 0 && (
                    <TableSelectedActions
                      numSelected={select.selected.length}
                      rowCount={data?.length || 0}
                      onSelectAllRows={select.onSelectAll}
                      actions={select.selectedActions}
                    />
                  )
                : null}

              <Table size="medium">
                <TableHeadCustom
                  order={order?.toLowerCase() as 'asc' | 'desc' | undefined}
                  orderBy={orderBy}
                  headLabel={headLabel}
                  rowCount={loading ? 0 : data && data.length}
                  numSelected={select?.selected.length ?? 0}
                  onSort={handleSort}
                  onSelectAllRows={select?.onSelectAll}
                />
                <TableBody>{rows}</TableBody>
              </Table>
            </TableContainer>
          </Scrollbar>
          {loading || !data ? (
            <Center mb={1}>
              <CircularProgress />
            </Center>
          ) : null}
        </>
        {footer}
      </Stack>
    </Card>
  );
}

type EldenTableProps = {
  children: ReactElement<EldenTableRowProps>[] | undefined | null;
  order?: Order;
  orderBy?: string;
  onSort?: (params: { orderBy: string; order: Order }) => void;
  data: any[] | undefined;
  loading: boolean;
  header?: ReactNode;
  footer?: ReactNode;
  hiddenMenu?: ReactNode;
  cardProps?: CardProps;
  select?: {
    selected: string[];
    selectedActions: ReactNode;
    onSelectAll: (checked: boolean) => void;
  };
};
