import useNotify from 'src/lib/hooks/useNotify';
import useModalStore from 'src/lib/stores/useModalStore';
import { CreateChannelFormValues } from '../../components/Forms/Create/types';
import { ChannelEmitter } from '../../events/ChannelEmitter';
import { CreateChannel } from '../../services/createChannel';

export default function useCreateChannelView() {
  const closeModal = useModalStore((state) => state.closeModal);
  const notify = useNotify();

  const handleCreateChannel = async (values: CreateChannelFormValues) => {
    const { error, data } = await CreateChannel({
      name: values.name,
      description: values.description,
      type: values.type,
      userUuid: values.userUuid,
      ...(values.objectLinks && { objectLinks: values.objectLinks }),
    });

    if (error) {
      notify(error, { variant: 'error' });
      closeModal();
    } else if (data) {
      notify('Channel Created!', { variant: 'success' });
      closeModal();

      ChannelEmitter.emit('ChannelCreated', data);
    }
  };

  return {
    handleCreateChannel,
  };
}
