import { DeepPartial } from 'react-hook-form';
import { FeedItem } from 'src/lib/types/FeedItem';
import { parseSearchParams } from 'src/lib/utils/parseSearchParams';
import useSWR, { SWRResponse } from 'swr';

export type PossibleParams = {
  page: number | string;
  size: number | string;
  order: string;
  orderBy: string;
  filter: {
    [key: string]: {
      like: string;
      eq: string;
    };
  };
};

export const fetchFeed = (
  filter?: DeepPartial<PossibleParams>
): SWRResponse<FeedItem[]> => {
  const parsedParams = parseSearchParams(filter ?? {});
  const response = useSWR<FeedItem[], Error>(
    `/admin/app-video-feeds?${parsedParams}`,
    {
      revalidateOnFocus: false,
    }
  );

  return response;
};
