import { DeepPartial } from 'react-hook-form';
import { Channel } from 'src/lib/types/channel';
import { ChannelContentWithIncludedModels } from 'src/lib/types/channelContent';
import { GameVersion } from 'src/lib/types/gameVersion';
import {
  OBJECT_MODERATION_REVIEW_STATUS,
  ObjectModeration,
} from 'src/lib/types/objectModeration';
import { WithCount } from 'src/lib/types/withCount';
import { parseSearchParams } from 'src/lib/utils/parseSearchParams';
import useSWR, { SWRResponse } from 'swr';

export type ObjectModerationInfoReturn = {
  object: Channel | GameVersion | ChannelContentWithIncludedModels | null;
  moderations: WithCount<ObjectModeration>;
};

export const fetchObjectModerations = (
  objectUuid: string,
  objectType: string,
  filters?: DeepPartial<PossibleParams>,
  shouldFetch = true
): SWRResponse<ObjectModerationInfoReturn> => {
  const response = useSWR(
    shouldFetch
      ? `admin/object-moderation/${objectType}/${objectUuid}?${parseSearchParams(
          filters ?? {}
        )}`
      : null
  );

  return response;
};

type PossibleParams = {
  page: number | string;
  size: number | string;
  filter: {
    reviewStatus: OBJECT_MODERATION_REVIEW_STATUS;
  };
  order: Record<string, string>;
};
