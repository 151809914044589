import { DEFAULT_INITIAL_PAGE_SIZE } from 'src/lib/constants/pagination';
import useURLSearchParams from 'src/lib/hooks/useURLSearchParams';
import useModalStore from 'src/lib/stores/useModalStore';
import { fetchCommunications } from '../../services/fetchCommunications';

export default function useCommunicationsListView() {
  const openModal = useModalStore((state) => state.openModal);
  const { allSearchParams } = useURLSearchParams({
    page: '0',
    size: String(DEFAULT_INITIAL_PAGE_SIZE),
  });
  const { data, isLoading } = fetchCommunications({
    page: allSearchParams.page,
    size: allSearchParams.size,
  });

  async function handleSendPushNotification() {
    const PushNotification = (await import('../../components/PushNotification'))
      .default;
    openModal(<PushNotification />);
  }

  return {
    handleSendPushNotification,
    data,
    isLoading,
  };
}
