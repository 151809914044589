import { Container } from '@mui/material';
import UserRanksView from 'src/components/modules/Users/view/Rankings';
import HeaderBreadcrumbs from 'src/template/components/HeaderBreadcrumbs';
import Page from 'src/template/components/Page';

export default function UserRanksPage(): JSX.Element {
  return (
    <Page title="Rankings">
      <Container maxWidth="xl">
        <HeaderBreadcrumbs
          heading="User Rankings"
          links={[{ name: 'User Rankings' }]}
        />
        <UserRanksView />
      </Container>
    </Page>
  );
}
