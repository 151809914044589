import { TextField } from '@mui/material';
import useURLSearchParams from 'src/lib/hooks/useURLSearchParams';
import Flex from './Flex';

export default function FilterDateDisplayer(): JSX.Element {
  const { getParam } = useURLSearchParams();
  const startDate = getParam('startDate');
  const endDate = getParam('endDate');

  return (
    <Flex gap={3} mb={2}>
      <TextField
        label="Start Date"
        value={startDate ?? 'Not specified'}
        InputProps={{
          readOnly: true,
        }}
        InputLabelProps={{
          shrink: true,
        }}
        variant="outlined"
        fullWidth
        disabled
      />
      <TextField
        label="End Date"
        value={endDate ?? 'Not specified'}
        InputProps={{
          readOnly: true,
        }}
        InputLabelProps={{
          shrink: true,
        }}
        variant="outlined"
        fullWidth
        disabled
      />
    </Flex>
  );
}
