import { Box } from '@mui/material';
import Flex from 'src/components/shared/Flex';
import MagicTable from 'src/components/shared/MagicTable/MagicTable';
import useMagicTable from 'src/components/shared/MagicTable/useMagicTable';
import SectionDivider from 'src/components/shared/SectionDivider';
import { DEFAULT_INITIAL_PAGE_SIZE } from 'src/lib/constants/pagination';
import useURLSearchParams from 'src/lib/hooks/useURLSearchParams';
import { RechargeType } from 'src/lib/types/product';
import { formatUTCDate } from 'src/lib/utils/formatUTCDate';
import Label from 'src/template/components/Label';
import { fetchSubscriptions } from '../../services/fetchSubscriptions';

export default function SubscriptionsTableView(): JSX.Element {
  const magicTableProps = useMagicTable();

  const { addParam, allSearchParams } = useURLSearchParams(initialParams);
  const size = +(allSearchParams?.size ?? initialParams.size);
  const page = +(allSearchParams?.page ?? initialParams.page);
  const orderBy = allSearchParams?.orderBy ?? initialParams.orderBy;
  const sortOrder = allSearchParams?.order ?? initialParams.order;

  const {
    data: Subscriptions,
    error,
    isLoading,
  } = fetchSubscriptions({
    page,
    size,
    order: {
      [orderBy]: sortOrder,
    },
  });

  return (
    <>
      <SectionDivider section="Dates are in UTC timezone" />
      <MagicTable.Container>
        <Flex gap={2} p={2} />
        <Box px={2}>
          <MagicTable
            loading={isLoading}
            pageSize={size}
            page={page}
            paginationMode="server"
            onPageChange={(newPage) => {
              addParam('page', newPage);
            }}
            onPageSizeChange={(newPageSize) => {
              addParam('size', newPageSize);
            }}
            onSortModelChange={(model) => {
              const sortModel = model[0];
              if (sortModel && sortModel.field && sortModel.sort) {
                addParam('orderBy', sortModel.field);
                addParam('order', sortModel.sort!);
              }
            }}
            rows={Subscriptions?.body ?? []}
            hideNumberOfRows
            columns={[
              {
                field: 'name',
                headerName: 'Name',
                sortable: true,
                renderCell({ row }) {
                  // eslint-disable-next-line react/jsx-no-useless-fragment
                  return (
                    // eslint-disable-next-line react/jsx-no-useless-fragment
                    <>{row.name || row.productId}</>
                  );
                },
              },
              {
                field: 'description',
                headerName: 'Description',
                sortable: true,
                renderCell({ row }) {
                  return (
                    row.description ?? (
                      <Label variant="ghost" color="default" flex={1}>
                        null
                      </Label>
                    )
                  );
                },
              },
              {
                field: 'provider',
                headerName: 'Provider',
                sortable: true,
              },
              {
                field: 'rechargeType',
                headerName: 'Recharge Type',
                sortable: true,
              },
              {
                field: 'rechargeValue',
                headerName: 'Recharge Value',
                sortable: true,
                renderCell({ row, value }) {
                  return row.rechargeType === RechargeType.STICKY_COINS
                    ? value / 1000
                    : value;
                },
              },
              {
                field: 'priceUSD',
                headerName: 'Price',
                sortable: true,
              },
              {
                field: 'subscriptionDuration',
                headerName: 'Duration',
                sortable: true,
              },
              {
                field: 'freeTrialDuration',
                headerName: 'Trial Duration',
                sortable: true,
                renderCell({ row }) {
                  return (
                    row.freeTrialDuration ?? (
                      <Label variant="ghost" color="default" flex={1}>
                        null
                      </Label>
                    )
                  );
                },
              },
              {
                field: 'createdAt',
                headerName: 'Created At',
                sortable: true,
                valueFormatter({ value }) {
                  return formatUTCDate(value);
                },
              },
            ]}
          />
        </Box>
      </MagicTable.Container>
    </>
  );
}

const initialParams = {
  page: '0',
  size: DEFAULT_INITIAL_PAGE_SIZE.toString(),
  order: 'desc',
  orderBy: 'name',
};
