import { Add } from '@mui/icons-material';
import { Container } from '@mui/material';
import CreatePayoutCountry from 'src/components/modules/SanctionedCountries/views/Create';
import ListSanctionedCountries from 'src/components/modules/SanctionedCountries/views/List';
import Button from 'src/components/shared/Buttons/CustomButton';
import useModalStore from 'src/lib/stores/useModalStore';
import HeaderBreadcrumbs from 'src/template/components/HeaderBreadcrumbs';
import Page from 'src/template/components/Page';

export default function SanctionedCountriesPage(): JSX.Element {
  const openModal = useModalStore((state) => state.openModal);

  const handleAddCountry = () => {
    openModal(<CreatePayoutCountry />);
  };

  return (
    <Page title="Sanctioned Countries">
      <Container maxWidth="xl">
        <HeaderBreadcrumbs
          heading="Sanctioned Countries"
          links={[{ name: 'List' }]}
          action={
            <Button
              variant="contained"
              color="primary"
              onClick={handleAddCountry}
              sx={{ gap: 1 }}
            >
              <Add />
              New
            </Button>
          }
        />
        <ListSanctionedCountries />
      </Container>
    </Page>
  );
}
