import {
  Autocomplete,
  Avatar,
  Box,
  InputAdornment,
  TextField,
} from '@mui/material';
import { GridSortDirection } from '@mui/x-data-grid';
import { debounce } from 'lodash';
import Flex from 'src/components/shared/Flex';
import Link from 'src/components/shared/Link';
import MagicTable from 'src/components/shared/MagicTable/MagicTable';
import { DEFAULT_INITIAL_PAGE_SIZE } from 'src/lib/constants/pagination';
import useURLSearchParams from 'src/lib/hooks/useURLSearchParams';
import { LabelValue } from 'src/lib/types/labelValue';
import { formatUTCDate } from 'src/lib/utils/formatUTCDate';
import Routes from 'src/routes/Routes';
import Iconify from 'src/template/components/Iconify';
import Label from 'src/template/components/Label';
import { fetchUserBlocks } from '../../services/fetchUserBlocks';

export default function UserBlocksTable() {
  const { addParam, removeParam, allSearchParams } =
    useURLSearchParams(initialParams);

  const size = +(allSearchParams?.size ?? initialParams.size);
  const page = +(allSearchParams?.page ?? initialParams.page);
  const orderBy = allSearchParams?.orderBy ?? initialParams.orderBy;
  const sortOrder = allSearchParams?.order ?? initialParams.order;
  const searchBy = allSearchParams?.searchBy ?? initialParams.searchBy;

  const { data: blocks, isLoading } = fetchUserBlocks({
    page,
    size,
    order: {
      [orderBy]: sortOrder,
    },
    filter: {
      ...(searchBy &&
        allSearchParams?.query && {
          [searchBy]: {
            like: `%${allSearchParams.query}%`,
          },
        }),
    },
  });

  function handleChangeSearch(value: string, param: string = 'query') {
    if (!value) removeParam(param);
    else addParam(param, value);
  }

  const debouncedSearch = debounce(handleChangeSearch, 1000);

  return (
    <MagicTable.Container>
      <Flex gap={2} p={2}>
        <Autocomplete
          options={SEARCH_OPTIONS}
          fullWidth
          renderInput={(params) => <TextField label="Search By" {...params} />}
          getOptionLabel={(p) => p.label}
          sx={{ maxWidth: 250 }}
          defaultValue={SEARCH_OPTIONS.find((o) => o.value === searchBy)}
          key={searchBy}
          onChange={(_, value) => {
            if (!value) removeParam('searchBy');
            else addParam('searchBy', value.value);
          }}
        />
        <TextField
          fullWidth
          label="Search"
          defaultValue={allSearchParams.query}
          type="search"
          onChange={(e) => {
            const { value } = e.target;
            debouncedSearch(value);
          }}
          key={allSearchParams.query}
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <Iconify
                  icon="eva:search-fill"
                  sx={{ color: 'text.disabled', width: 20, height: 20 }}
                />
              </InputAdornment>
            ),
          }}
        />
      </Flex>
      <Box px={2}>
        <MagicTable
          loading={isLoading}
          pageSize={size}
          page={page}
          paginationMode="server"
          onPageChange={(newPage) => {
            addParam('page', newPage);
          }}
          onPageSizeChange={(newSize) => {
            addParam('size', newSize);
          }}
          onSortModelChange={(model) => {
            const sortModel = model[0];
            addParam('orderBy', sortModel.field);
            addParam('order', sortModel.sort!);
          }}
          sortModel={[
            {
              field: orderBy,
              sort: sortOrder as GridSortDirection,
            },
          ]}
          rows={blocks ?? []}
          hideNumberOfRows
          columns={[
            {
              field: 'user.name',
              headerName: 'Name',
              sortable: false,
              renderCell({ row }) {
                return (
                  <>
                    <Avatar
                      alt={row?.user?.name ?? 'user profile picture'}
                      src={row?.user?.profileImage ?? ''}
                      sx={{ mr: 2 }}
                    />

                    <Link
                      to={Routes.user(row?.user?.uuid)}
                      color={row?.user?.name ? 'inherit' : 'GrayText'}
                      underline="hover"
                    >
                      {row?.user?.name || 'empty'}
                    </Link>
                  </>
                );
              },
            },
            {
              field: 'createdByUser.name',
              headerName: 'Blocked By',
              sortable: false,
              renderCell({ row }) {
                return (
                  <>
                    <Avatar
                      alt={row?.createdByUser?.name ?? 'user profile picture'}
                      src={row?.createdByUser?.profileImage ?? ''}
                      sx={{ mr: 2 }}
                    />

                    <Link
                      to={Routes.user(row?.createdByUser?.uuid)}
                      color={row?.createdByUser?.name ? 'inherit' : 'GrayText'}
                      underline="hover"
                    >
                      {row?.createdByUser?.name || 'empty'}
                    </Link>
                  </>
                );
              },
            },
            {
              field: 'reason',
              headerName: 'Reason',
              sortable: false,
            },
            {
              field: 'active',
              headerName: 'Active',
              flex: 0,
              sortable: false,
              renderCell({ value }) {
                if (value) {
                  return <Label color="success">Active</Label>;
                }
                return <Label color="error">Inactive</Label>;
              },
            },
            {
              field: 'createdAt',
              headerName: 'Blocked At',
              sortingOrder: ['desc', 'asc'],
              flex: 1 / 2,
              sortComparator: () => 0,
              valueFormatter({ value }) {
                return value ? formatUTCDate(value) : '-';
              },
            },
          ]}
        />
      </Box>
    </MagicTable.Container>
  );
}

const initialParams = {
  page: '0',
  size: DEFAULT_INITIAL_PAGE_SIZE.toString(),
  order: 'desc',
  orderBy: 'createdAt',
  searchBy: 'user.slug',
};

const SEARCH_OPTIONS: LabelValue[] = [
  {
    label: 'Slug',
    value: 'user.slug',
  },
  {
    label: 'Name',
    value: 'user.name',
  },
  {
    label: 'Email',
    value: 'user.email',
  },
  {
    label: 'UUID',
    value: 'user.uuid',
  },
  {
    label: 'Auth ID',
    value: 'user.authId',
  },
];
