import ClubDetails from 'src/components/modules/Clubs/view/Details';
import Page from 'src/template/components/Page';

export default function ClubPage() {
  return (
    <Page title="Club">
      <ClubDetails />
    </Page>
  );
}
