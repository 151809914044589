import { Container } from '@mui/material';
import Clubs from 'src/components/modules/Clubs/view/List';
import Page from 'src/template/components/Page';

export default function ClubsPage() {
  return (
    <Page title="Clubs">
      <Container maxWidth="xl">
        <Clubs />
      </Container>
    </Page>
  );
}
