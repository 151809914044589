import useNotify from 'src/lib/hooks/useNotify';
import useModalStore from 'src/lib/stores/useModalStore';
import { AddAssetsFormValues } from '../../components/Forms/AddExternalAssets/types';
import { ClubEmitter } from '../../events/ClubEmitter';
import { addAssets } from '../../services/addAssets';

export default function useAddExternalAssetView() {
  const closeModal = useModalStore((state) => state.closeModal);
  const notify = useNotify();

  const handleAddExternalAsset = async (
    uuid: string,
    values: AddAssetsFormValues
  ) => {
    const { error, data } = await addAssets(uuid, {
      externalAssets: values.externalAssets,
    });

    if (error) {
      notify(error, { variant: 'error' });
      closeModal();
    } else if (data) {
      notify('Assets Added!', { variant: 'success' });
      closeModal();

      ClubEmitter.emit('ClubUpdated', data);
    }
  };

  return {
    handleAddExternalAsset,
  };
}
