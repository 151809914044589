import { useParams } from 'react-router';
import useModalStore from 'src/lib/stores/useModalStore';
import { fetchConsumable } from '../../../services/fetchConsumable';

export default function useConsumableDetailsView() {
  const openModal = useModalStore((state) => state.openModal);
  const { id } = useParams<'id'>();
  const { userUuid } = useParams<'userUuid'>();
  const { data, isLoading, error } = fetchConsumable(id ?? '', userUuid);

  async function showRelatedPools(revenueEventId: string) {
    const RelatedPools = (await import('../../../components/RelatedPools'))
      .default;
    openModal(<RelatedPools revenueEventId={revenueEventId} />);
  }

  return {
    data,
    isLoading,
    error,
    showRelatedPools,
  };
}
