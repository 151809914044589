import {
  Button,
  Menu,
  MenuItem,
  Stack,
  Tab,
  Tabs,
  Typography,
  styled,
} from '@mui/material';

import { useState } from 'react';
import { PROFILE_DROPDOWN_TABS } from './profileHeaderTabs';

const TabsWrapperStyle = styled('div')(({ theme }) => ({
  zIndex: 9,
  bottom: 0,
  width: '100%',
  display: 'flex',
  position: 'absolute',
  backgroundColor: theme.palette.background.paper,
  justifyContent: 'center',
}));

interface ProfileDropdownNavigationProps {
  currentTab?: string;
  onChangeTab: (
    event: React.SyntheticEvent<Element, Event> | null,
    newValue: string
  ) => void;
}

export default function ProfileDropdownNavigation({
  currentTab,
  onChangeTab,
}: ProfileDropdownNavigationProps) {
  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);
  const [openDropdown, setOpenDropdown] = useState<string | null>(null);

  const handleDropdownClick = (
    event: React.MouseEvent<HTMLElement>,
    tabValue: string
  ) => {
    setAnchorEl(event.currentTarget);
    setOpenDropdown(tabValue);
  };

  const handleMenuItemClick = (tabValue: string) => {
    onChangeTab(null, tabValue);
    setAnchorEl(null);
    setOpenDropdown(null);
  };

  const handleClose = () => {
    setAnchorEl(null);
    setOpenDropdown(null);
  };

  return (
    <TabsWrapperStyle>
      <Tabs
        allowScrollButtonsMobile
        variant="scrollable"
        scrollButtons="auto"
        value={currentTab}
      >
        {PROFILE_DROPDOWN_TABS.map((tab) =>
          'children' in tab ? (
            <Tab
              key={tab.value}
              label={
                <Stack key={tab.value}>
                  <Button
                    aria-controls={
                      openDropdown === tab.value ? 'menu' : undefined
                    }
                    aria-haspopup="true"
                    onClick={(event) => handleDropdownClick(event, tab.value)}
                    disableFocusRipple
                  >
                    <Stack direction={'row'}>
                      {tab.icon && <span>{tab.icon}</span>}
                      <Typography
                        variant="body1"
                        fontWeight={500}
                        px={1}
                        fontSize={'1rem'}
                      >
                        {tab.value}
                      </Typography>
                    </Stack>
                  </Button>
                  <Menu
                    id="menu"
                    anchorEl={anchorEl}
                    open={openDropdown === tab.value}
                    onClose={handleClose}
                  >
                    {tab.children.map((child) => (
                      <MenuItem
                        key={child.value}
                        selected={child.value === currentTab}
                        onClick={() => handleMenuItemClick(child.value)}
                        dense
                      >
                        <Stack direction={'row'} gap={1}>
                          {child.icon && <span>{child.icon}</span>}
                          <Typography variant="body1">{child.value}</Typography>
                        </Stack>
                      </MenuItem>
                    ))}
                  </Menu>
                </Stack>
              }
            />
          ) : (
            <Tab
              key={tab.value}
              value={tab.value}
              onClick={() => onChangeTab(null, tab.value)}
              label={
                <Stack direction={'row'}>
                  {tab.icon && <span>{tab.icon}</span>}
                  <Typography
                    variant="body1"
                    fontWeight={500}
                    px={1}
                    fontSize={'1rem'}
                  >
                    {tab.value}
                  </Typography>
                </Stack>
              }
            />
          )
        )}
      </Tabs>
    </TabsWrapperStyle>
  );
}
