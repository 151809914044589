import createUseTable from '../../../../../lib/utils/createUseTable';

export type OrderByOptions = 'name' | 'createdAt' | 'updatedAt';
export type SearchForOptions =
  | 'uuid'
  | 'status'
  | 'userUuid'
  | 'user.name'
  | 'user.slug'
  | 'user.email'
  | 'triggeredByUserUuid'
  | 'triggeredByUser.slug'
  | 'triggeredByUser.name'
  | 'triggeredByUser.email'
  | 'createdByUserUuid'
  | 'createdByUser.slug'
  | 'createdByUser.name'
  | 'createdByUser.email'
  | 'status';

const useUserPurgesTable = createUseTable<OrderByOptions, SearchForOptions>({
  searchFor: 'uuid',
  order: 'desc',
  orderBy: 'createdAt',
  query: '',
  page: 0,
  size: 10,
});

export default useUserPurgesTable;
