import { Avatar, Box } from '@mui/material';
import { GridSortDirection } from '@mui/x-data-grid';
import Link from 'src/components/shared/Link';
import MagicTable from 'src/components/shared/MagicTable/MagicTable';
import useURLSearchParams from 'src/lib/hooks/useURLSearchParams';
import { UserLink } from 'src/lib/types/userLink';
import { formatUTCDate } from 'src/lib/utils/formatUTCDate';
import Routes from 'src/routes/Routes';
import { FetchEntitynotesQueryParams } from './useUserLinksTable';

type UserLinksTableProps = {
  data: UserLink[];
  totalCount: number;
  isLoading: boolean;
  params: FetchEntitynotesQueryParams;
};

export default function UserLinksTable({
  data,
  isLoading,
  totalCount,
  params: { page, size, orderBy, sortOrder },
}: UserLinksTableProps) {
  const { addParam } = useURLSearchParams();

  return (
    <MagicTable.Container>
      <Box px={2}>
        <MagicTable
          loading={isLoading}
          pageSize={size}
          page={page}
          rowCount={totalCount}
          paginationMode="server"
          onPageChange={(newPage) => {
            addParam('page', newPage);
          }}
          onPageSizeChange={(newSize) => {
            addParam('size', newSize);
          }}
          onSortModelChange={(model) => {
            const sortModel = model[0];
            addParam('orderBy', sortModel.field);
            addParam('order', sortModel.sort!);
          }}
          sortModel={[
            {
              field: orderBy,
              sort: sortOrder as GridSortDirection,
            },
          ]}
          rows={data}
          columns={[
            {
              field: 'user.name',
              headerName: 'User',
              sortable: false,
              renderCell({ row }) {
                return (
                  <>
                    <Avatar
                      alt={row?.user?.name ?? 'user profile picture'}
                      src={row?.user?.profileImage ?? ''}
                      sx={{ mr: 2 }}
                    />

                    <Link
                      to={Routes.user(row?.user?.uuid)}
                      color={row?.user?.name ? 'inherit' : 'GrayText'}
                      underline="hover"
                    >
                      {row?.user?.name ||
                        row?.user?.slug ||
                        row?.user?.email ||
                        'empty'}
                    </Link>
                  </>
                );
              },
            },
            {
              field: 'url',
              headerName: 'Link',
              sortable: false,
              renderCell({ row }) {
                return (
                  <Link to={row.url} color={'GrayText'} underline="hover">
                    {row.url}
                  </Link>
                );
              },
            },

            {
              field: 'name',
              headerName: 'Name',
              sortable: false,
            },
            {
              field: 'description',
              headerName: 'Description',
              sortable: false,
            },
            {
              field: 'createdAt',
              headerName: 'Created At',
              sortingOrder: ['desc', 'asc'],
              flex: 1 / 2,
              sortComparator: () => 0,
              valueFormatter({ value }) {
                return value ? formatUTCDate(value) : '-';
              },
            },
          ]}
        />
      </Box>
    </MagicTable.Container>
  );
}
