import getAPIClient from 'src/lib/services/api/axios.config';
import { ServiceResponse } from 'src/lib/types/ServiceResponse';
import { getMessageFromError } from 'src/lib/utils/error';
import { Configuration } from '../types/Configuration';

type CreateConfigurationData = Pick<
  Configuration,
  'name' | 'platform' | 'value' | 'active'
>;

export async function createConfiguration(
  data: CreateConfigurationData
): Promise<ServiceResponse<Configuration>> {
  try {
    const api = await getAPIClient();
    const res = await api.post('admin/configurations', data);
    return {
      data: res.data,
      error: null,
    };
  } catch (e) {
    return {
      data: null,
      error: getMessageFromError(e),
    };
  }
}
