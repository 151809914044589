import { Card, CardContent } from '@mui/material';
import Modal from 'src/components/shared/Modal';
import { GameAsset } from 'src/lib/types/asset';
import AssetsTable from '../../components/assetsTable';
import useListStatistics from './useView';

interface ListAssetsModalViewProps {
  assets: GameAsset[];
}

export default function ListAssetsModalView({
  assets,
}: ListAssetsModalViewProps): JSX.Element {
  const { params, handleCloseModal } = useListStatistics();

  return (
    <Modal open onClose={handleCloseModal}>
      <Card>
        <CardContent>
          <AssetsTable data={assets ?? []} isLoading={false} params={params} />
        </CardContent>
      </Card>
    </Modal>
  );
}
