import { DEFAULT_INITIAL_PAGE_SIZE } from 'src/lib/constants/pagination';
import useURLSearchParams from 'src/lib/hooks/useURLSearchParams';
import { OBJECT_MODERATION_OBJECT_TYPE } from 'src/lib/types/objectModeration';
import useTabs from 'src/template/hooks/useTabs';
import { fetchObjectsToReview } from '../../services/fetchObjectsToReview';

export default function useChannelsView() {
  const { currentTab, onChangeTab } = useTabs(
    OBJECT_MODERATION_OBJECT_TYPE.VIDEO,
    {
      queryName: 'tab',
    }
  );
  const { allSearchParams } = useURLSearchParams(initialParams);

  const params: ListObjectsToReviewParams = {
    page: Number(allSearchParams.page) ?? initialParams.page,
    size: Number(allSearchParams.size) ?? initialParams.size,
    sortBy: allSearchParams.sortBy ?? initialParams.sortBy,
    order: allSearchParams.order ?? initialParams.order,
  };

  const { data, isLoading } = fetchObjectsToReview({
    objectType: currentTab as OBJECT_MODERATION_OBJECT_TYPE,
    page: params.page,
    size: params.size,

    order: {
      [params.sortBy]: params.order,
    },
  });

  return {
    onChangeTab,
    currentTab,
    data,
    isLoading,
    params,
  };
}

const initialParams = {
  sortBy: 'updatedAt',
  order: 'desc',
  page: '0',
  size: DEFAULT_INITIAL_PAGE_SIZE.toString(),
  searchBy: 'itemType',
  query: '',
};

export type ListObjectsToReviewParams = {
  page: number;
  size: number;
  sortBy: string;
  order: string;
  searchBy?: string;
  query?: string;
};
