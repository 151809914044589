import CardInfo from 'src/components/shared/CardInfo/CardInfo';
import Flex from 'src/components/shared/Flex';
import NumberInfo from 'src/components/shared/NumberInfo/NumberInfo';
import { formatMilliseconds } from 'src/lib/utils/formatMilliseconds';

interface PlayerStatsCardInfosProps {
  totalSessions: number;
  totalSessionTime: number;
  averageSessionDuration: number;
  averageMatches?: number;
  uniqueGames: number;
  sessionsByGame: number;
  positiveRatings: number;
  negativeRatings: number;
  averageTimeByGame: number;
  gamesByUser?: number;
  uniqueReviewers?: number;
}

export default function PlayerStatsCardInfos({
  totalSessionTime,
  averageMatches,
  averageSessionDuration,
  totalSessions,
  uniqueGames,
  sessionsByGame,
  positiveRatings,
  negativeRatings,
  averageTimeByGame,
  gamesByUser,
  uniqueReviewers,
}: PlayerStatsCardInfosProps) {
  return (
    <Flex gap={2} flexWrap={'wrap'} justifyContent={'center'}>
      <NumberInfo title="Unique Games" total={uniqueGames} />
      {!!gamesByUser && (
        <NumberInfo title="Games by user" decimals={2} total={gamesByUser} />
      )}
      <NumberInfo title="Total Sessions" total={totalSessions} />
      <NumberInfo
        title="Sessions By Game"
        total={sessionsByGame}
        decimals={2}
      />
      <CardInfo
        title="Total Session Time"
        text={formatMilliseconds(totalSessionTime, 'minutes')}
      />
      <CardInfo
        title="Average Session Time"
        text={formatMilliseconds(averageSessionDuration)}
      />
      <CardInfo
        title="Session Time By Game"
        text={formatMilliseconds(averageTimeByGame)}
      />
      <Flex gap={2} flexWrap={'wrap'} justifyContent={'center'}>
        <NumberInfo title="Positive Ratinkgs" total={positiveRatings} />
        <NumberInfo title="Negative Ratings" total={negativeRatings} />
        {!!uniqueReviewers && (
          <NumberInfo
            title="Unique Reviewers"
            decimals={2}
            total={uniqueReviewers}
          />
        )}
      </Flex>
      {/* <NumberInfo title="Average Matches" decimals={2} total={averageMatches} /> */}
    </Flex>
  );
}
