import { Close } from '@mui/icons-material';
import {
  Box,
  Card,
  CardContent,
  CardHeader,
  Divider,
  Grid,
  IconButton,
  Stack,
  TextField,
  Typography,
} from '@mui/material';
import { capitalCase } from 'change-case';
import Flex from 'src/components/shared/Flex';
import MagicTableUserCell from 'src/components/shared/MagicTable/UserCell';
import Modal from 'src/components/shared/Modal';
import { ObjectModeration } from 'src/lib/types/objectModeration';
import { formatUTCDate } from 'src/lib/utils/formatUTCDate';
import Label from 'src/template/components/Label';
import {
  colorByAIReview,
  colorByHumanReview,
  colorByReviewStatus,
} from '../../utils/utils';
import useModerationDetailsModal from './useView';

type Props = {
  moderation: ObjectModeration;
};

export default function ModerationDetailsModalView({
  moderation,
}: Props): JSX.Element {
  const { handleCloseModal } = useModerationDetailsModal();

  return (
    <Modal open onClose={handleCloseModal}>
      <Card>
        <CardHeader
          title="Moderation Details"
          action={
            <IconButton onClick={handleCloseModal}>
              <Close />
            </IconButton>
          }
        />
        <Divider
          sx={{
            mt: 2,
          }}
        />
        <CardContent>
          <Grid container spacing={2}>
            <Grid item xs={12} sm={6}>
              <Typography variant="h6" gutterBottom>
                Review Details
              </Typography>

              <Stack spacing={1}>
                <Box>
                  <Typography variant="subtitle2" color="textSecondary">
                    Review Status:
                  </Typography>
                  <Label color={colorByReviewStatus[moderation.reviewStatus]}>
                    {capitalCase(moderation.reviewStatus)}
                  </Label>
                </Box>

                <Box>
                  <Typography variant="subtitle2" color="textSecondary">
                    AI Model:
                  </Typography>
                  <Flex gap={1}>
                    {moderation.aiModel ? (
                      <Flex gap={1}>
                        {moderation.aiModel.split(',').map((model) => (
                          <Label key={model} color="default">
                            {model}
                          </Label>
                        ))}
                      </Flex>
                    ) : (
                      <Label color="default">N/A</Label>
                    )}
                  </Flex>
                </Box>

                <Box>
                  <Typography variant="subtitle2" color="textSecondary">
                    AI Review:
                  </Typography>
                  <Label
                    color={
                      moderation.aiReview
                        ? colorByAIReview[moderation.aiReview]
                        : 'default'
                    }
                  >
                    {moderation.aiReview
                      ? capitalCase(moderation.aiReview)
                      : 'N/A'}
                  </Label>
                </Box>

                <Box>
                  <Typography variant="subtitle2" color="textSecondary">
                    AI Rejection Reason:
                  </Typography>

                  {moderation.aiRejectionReason ? (
                    <Flex gap={1}>
                      {moderation.aiRejectionReason.split(',').map((reason) => (
                        <Label key={reason} color="default">
                          {reason}
                        </Label>
                      ))}
                    </Flex>
                  ) : (
                    <Label color="default">N/A</Label>
                  )}
                </Box>
              </Stack>
            </Grid>

            <Grid item xs={12} sm={6}>
              <Typography variant="h6" gutterBottom>
                Human Review Details
              </Typography>

              <Stack spacing={1}>
                <Box>
                  <Typography variant="subtitle2" color="textSecondary">
                    Admin Reviewer:
                  </Typography>
                  {moderation.adminReviewer ? (
                    <MagicTableUserCell user={moderation.adminReviewer} />
                  ) : (
                    'N/A'
                  )}
                </Box>

                <Box>
                  <Typography variant="subtitle2" color="textSecondary">
                    Human Review:
                  </Typography>
                  <Label
                    color={
                      moderation.humanReview
                        ? colorByHumanReview[moderation.humanReview]
                        : 'default'
                    }
                  >
                    {moderation.humanReview
                      ? capitalCase(moderation.humanReview)
                      : 'N/A'}
                  </Label>
                </Box>

                <Box>
                  <Typography variant="subtitle2" color="textSecondary">
                    Human Review Rejection Reason:
                  </Typography>
                  {moderation.humanReviewRejectionReason ? (
                    <Flex gap={1}>
                      {moderation.humanReviewRejectionReason
                        .split(',')
                        .map((reason) => (
                          <Label key={reason} color="default">
                            {reason}
                          </Label>
                        ))}
                    </Flex>
                  ) : (
                    <Label color="default">N/A</Label>
                  )}
                </Box>

                <Box>
                  <Typography variant="subtitle2" color="textSecondary">
                    Internal Justification:
                  </Typography>
                  <Typography variant="body1">
                    {moderation.internalJustification || 'N/A'}
                  </Typography>
                </Box>
              </Stack>
            </Grid>

            <Grid item xs={12}>
              <TextField
                label="Review Note"
                value={moderation.reviewNote || 'N/A'}
                multiline
                minRows={4}
                fullWidth
                InputProps={{
                  readOnly: true,
                }}
                variant="outlined"
                disabled
              />
            </Grid>

            <Grid item xs={12} sm={6}>
              <Box mt={2}>
                <Typography variant="subtitle2" color="textSecondary">
                  Created At:
                </Typography>
                <Typography variant="body1">
                  {formatUTCDate(moderation.createdAt)}
                </Typography>
              </Box>
            </Grid>

            <Grid item xs={12} sm={6}>
              <Box mt={2}>
                <Typography variant="subtitle2" color="textSecondary">
                  Updated At:
                </Typography>
                <Typography variant="body1">
                  {formatUTCDate(moderation.updatedAt)}
                </Typography>
              </Box>
            </Grid>
          </Grid>
        </CardContent>
      </Card>
    </Modal>
  );
}
