import getAPIClient from 'src/lib/services/api/axios.config';
import { getMessageFromError } from 'src/lib/utils/error';

export async function patchGameAiDescription(
  uuid: string,
  aiDescription: string
) {
  try {
    const api = await getAPIClient();
    const res = await api.patch(`admin/game/${uuid}/aiDescription`, {
      aiDescription,
    });
    return {
      data: res.data,
      error: null,
    };
  } catch (e) {
    return {
      data: null,
      error: getMessageFromError(e),
    };
  }
}
