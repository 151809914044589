import getAPIClient from 'src/lib/services/api/axios.config';

import { ServiceResponse } from 'src/lib/types/ServiceResponse';
import { GameCategory } from 'src/lib/types/gameCategory';
import { getMessageFromError } from 'src/lib/utils/error';

export async function UpdateGameCategory(
  data: GameCategory
): Promise<ServiceResponse<GameCategory>> {
  try {
    const api = await getAPIClient();
    const res = await api.put(`admin/game-category/${data.uuid}`, data);
    return {
      data: res.data,
      error: null,
    };
  } catch (e) {
    return {
      data: null,
      error: getMessageFromError(e),
    };
  }
}
