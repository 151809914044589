import { PaginatedResponse } from 'src/lib/types/PaginatedResponse';
import { parseSearchParams } from 'src/lib/utils/parseSearchParams';
import useSWR from 'swr';
import { UserPurge } from '../../Profile/types/UserPurge';

export const useFetchUserPurges = (filters: any, status: any): any => {
  const statusFilter =
    status === 'waiting'
      ? '&filter[status][in]=pending&filter[status][in]=running&order[status]=desc&order[createdAt]=desc'
      : `&filter[status]=${status}`;

  const { data, error } = useSWR<PaginatedResponse<UserPurge[]>, Error>(
    `/admin/user-purge?${parseSearchParams(filters)}${statusFilter}`,
    {
      refreshInterval: 5000,
    }
  );

  return {
    data: data?.body,
    meta: data?.meta,
    loading: !error && !data,
    error: error?.message,
  };
};
