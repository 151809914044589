import { Edit } from '@mui/icons-material';
import { Card } from '@mui/material';
import { useState } from 'react';
import { useDropzone } from 'react-dropzone';
import Button from 'src/components/shared/Buttons/CustomButton';
import { Collection } from 'src/lib/types/collection';
import { getImageURL } from 'src/lib/utils/nft';
import { uploadToStorage } from 'src/lib/utils/upload';
import Image from 'src/template/components/Image';
import { makeCollectionService } from '../../services/CollectionService';

type Props = {
  collection: Collection;
};
export default function CollectionCover({ collection }: Props): JSX.Element {
  const [isLoading, setIsLoading] = useState(false);
  const { getRootProps, getInputProps } = useDropzone({
    multiple: false,
    onDrop: (files) => onEditCover(files[0]),
  });

  async function onEditCover(file: File) {
    setIsLoading(true);
    const coverUrl = await uploadToStorage(collection.uuid, file);
    const collectionService = await makeCollectionService();

    await collectionService.update(collection.uuid, {
      ...collection,
      coverFileUrl: coverUrl,
    });

    setIsLoading(false);
  }
  return (
    <Card
      sx={{
        position: 'relative',
        '&:hover': {
          '& .edit-cover-button': {
            opacity: 1,
          },
        },
      }}
    >
      <Image
        src={getImageURL(collection?.coverFileUrl ?? '')}
        alt="Collection Cover Image"
        ratio="1/1"
        sx={{
          '&:hover': {
            opacity: 0.7,
          },
        }}
      />
      <Button
        loading={isLoading}
        startIcon={<Edit />}
        className="edit-cover-button"
        sx={{
          position: 'absolute',
          opacity: 0,
          height: '100%',
          width: '100%',
          color: 'white !important',
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, -50%)',
          '&:hover': {
            backgroundColor: '#00000080',
          },
        }}
        {...getRootProps()}
      >
        Edit Cover
        <input {...getInputProps()} />
      </Button>
    </Card>
  );
}
