import {
  Button as MaterialButton,
  ButtonProps,
  CircularProgress,
} from '@mui/material';
import { forwardRef, MouseEvent, useState } from 'react';

const AsyncFunction = (async () => {}).constructor;
const GeneratorFunction = (() => {}).constructor;

export interface CustomButtonProps extends ButtonProps {
  loading?: boolean;
}
const Button = forwardRef<HTMLButtonElement, CustomButtonProps>(
  ({ children, loading, disabled, onClick, ...rest }, ref): JSX.Element => {
    const [isLoading, setIsLoading] = useState(false);

    async function onClickWithLoading(e: MouseEvent<HTMLButtonElement>) {
      if (onClick) {
        const onClickIsAsync =
          (onClick instanceof AsyncFunction &&
            AsyncFunction !== Function &&
            AsyncFunction !== GeneratorFunction) === true; // Verifies if onClick is async
        if (onClickIsAsync) {
          setIsLoading(true);
          await onClick(e);
          setIsLoading(false);
        } else {
          onClick(e);
        }
      }
    }

    return (
      <MaterialButton
        onClick={(e) => onClickWithLoading(e)}
        disabled={disabled || loading || isLoading}
        ref={ref}
        {...rest}
      >
        {loading || isLoading ? (
          <CircularProgress color="inherit" size={24} />
        ) : (
          children
        )}
      </MaterialButton>
    );
  }
);

export default Button;
