import { yupResolver } from '@hookform/resolvers/yup';
import { Close } from '@mui/icons-material';
import {
  Alert,
  Card,
  CardContent,
  CardHeader,
  Stack,
  Typography,
} from '@mui/material';
import { useState } from 'react';
import { useFieldArray, useForm } from 'react-hook-form';
import SelectAssetsForContest from 'src/components/modules/GameAssets/view/SelectList';
import Button from 'src/components/shared/Buttons/CustomButton';
import Flex from 'src/components/shared/Flex';
import Modal from 'src/components/shared/Modal';
import useModalStore from 'src/lib/stores/useModalStore';
import { GameAsset } from 'src/lib/types/asset';
import * as yup from 'yup';
import { AddAssetInContestFormValues } from './types';

const validationSchema = yup.object().shape({
  assets: yup
    .array()
    .of(
      yup.object().shape({
        uuid: yup.string().required('Asset is required'),
      })
    )
    .required(),
});

type Props = {
  onSubmit: (values: AddAssetInContestFormValues) => Promise<void>;
  title: string;
  contestUuid: string;
};

export default function AddAssetInPackForm({
  onSubmit,
  title,
  contestUuid,
}: Props): JSX.Element {
  const onClose = useModalStore((state) => state.closeModal);
  const {
    control,
    handleSubmit,
    formState: { isSubmitting },
    getFieldState,
  } = useForm<AddAssetInContestFormValues>({
    resolver: yupResolver(validationSchema),
  });

  const handleCloseModal = () => {
    onClose();
  };

  const {
    fields: selectedAssets,
    remove: removeAsset,
    append: appendAsset,
  } = useFieldArray({
    control,
    name: 'assets',
  });

  const handleAddAsset = (asset: GameAsset) => {
    appendAsset({
      uuid: asset.uuid!,
      name: asset.name,
      type: asset.assetType!,
    });
  };

  const handleRemoveAsset = (uuid: string) => {
    const index = selectedAssets.findIndex((asset) => asset.uuid === uuid);
    if (index > -1) removeAsset(index);
  };

  const handleRemoveAssetByIndex = (index: number) => {
    if (selectedAssets.length > 1) removeAsset(index);
  };

  const [currentStep, setCurrentStep] = useState(1); // 1: table

  const handleNextStep = () => {
    setCurrentStep(currentStep + 1);
  };

  const handlePreviousStep = () => {
    setCurrentStep(currentStep - 1);
  };

  return (
    <Modal open onClose={handleCloseModal}>
      <Card
        sx={{
          ...(currentStep === 1
            ? {
                width: '80vw',
                minHeight: '50vh',
              }
            : {}),
          display: 'flex',
          flexDirection: 'column',
        }}
      >
        <CardHeader title={title} />
        <CardContent
          sx={{
            justifySelf: 'flex-end',
          }}
        >
          <form onSubmit={handleSubmit(onSubmit)}>
            {currentStep === 1 ? (
              <SelectAssetsForContest
                handleAddAsset={handleAddAsset}
                handleRemoveAsset={handleRemoveAsset}
                selectedAssets={selectedAssets}
                contestUuid={contestUuid}
              />
            ) : (
              <Stack mb={3} spacing={1.5}>
                {selectedAssets.map((asset, assetIndex) => (
                  <Flex
                    key={asset.id}
                    gap={6}
                    alignItems={'flex-start'}
                    justifyContent={'space-between'}
                  >
                    <Stack direction={'row'}>
                      <Stack>
                        <Typography variant="body1" noWrap>
                          {asset.name}
                        </Typography>
                        <Typography variant="subtitle2" color="grey" noWrap>
                          ({asset.type.name})
                        </Typography>
                      </Stack>
                    </Stack>

                    <Stack direction={'row'}>
                      <Button
                        onClick={() => handleRemoveAssetByIndex(assetIndex)}
                        size="large"
                      >
                        <Close />
                      </Button>
                    </Stack>
                  </Flex>
                ))}

                {getFieldState(`assets`).error?.message && (
                  <Alert severity="error">
                    {getFieldState(`assets`).error?.message}
                  </Alert>
                )}
              </Stack>
            )}
            <Stack
              direction="row"
              justifyContent="space-between"
              spacing={1}
              mt={2}
            >
              {currentStep === 2 ? (
                <Button
                  variant="contained"
                  color="error"
                  type="button"
                  onClick={handlePreviousStep}
                  size="large"
                >
                  Previous
                </Button>
              ) : (
                <Button color="error" onClick={handleCloseModal} size="large">
                  Cancel
                </Button>
              )}
              {currentStep === 1 ? (
                <Button
                  variant="contained"
                  color="secondary"
                  type="button"
                  disabled={selectedAssets.length === 0}
                  onClick={(e) => {
                    e.preventDefault();

                    handleNextStep();
                  }}
                  size="large"
                >
                  Next
                </Button>
              ) : (
                <Button
                  variant="contained"
                  color="secondary"
                  type="submit"
                  loading={isSubmitting}
                  size="large"
                >
                  Save
                </Button>
              )}
            </Stack>
          </form>
        </CardContent>
      </Card>
    </Modal>
  );
}
