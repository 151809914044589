import useNotify from 'src/lib/hooks/useNotify';
import useModalStore from 'src/lib/stores/useModalStore';
import { ClubEmitter } from '../../events/ClubEmitter';
import { DeleteClub } from '../../services/deleteClub';

export default function useDeleteClub() {
  const closeModal = useModalStore((state) => state.closeModal);
  const notify = useNotify();

  const handleDeleteClub = async (uuid: string) => {
    const { data, error } = await DeleteClub(uuid);
    if (error) {
      notify(error, { variant: 'error' });
      closeModal();
    } else {
      notify('Club deleted!', { variant: 'success' });
      closeModal();
      ClubEmitter.emit('ClubDeleted', data);
    }
  };

  return {
    handleDeleteClub,
  };
}
