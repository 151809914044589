import useNotify from 'src/lib/hooks/useNotify';
import useModalStore from 'src/lib/stores/useModalStore';
import { NftPackEmitter } from '../../../events/NftPackEmitter';
import { RemoveNftFromPack } from '../../../services/removeNftFromPack';

export default function useRemoveFromNftPack() {
  const closeModal = useModalStore((state) => state.closeModal);
  const notify = useNotify();

  const handleRemoveNftFromPack = async (uuid: string, nfts: string[]) => {
    const { data, error } = await RemoveNftFromPack(uuid, nfts);
    if (error) {
      notify(error, { variant: 'error' });
      closeModal();
    } else {
      notify('Nft removed!', { variant: 'success' });
      closeModal();
      NftPackEmitter.emit('NftPackNftsRemoved', data);
    }
  };

  return {
    handleRemoveNftFromPack,
  };
}
