import { Add } from '@mui/icons-material';
import {
  Autocomplete,
  Button,
  Card,
  CardContent,
  InputAdornment,
  Tab,
  Tabs,
  TextField,
  debounce,
} from '@mui/material';
import { GridSortDirection } from '@mui/x-data-grid';
import Flex from 'src/components/shared/Flex';
import useURLSearchParams from 'src/lib/hooks/useURLSearchParams';
import HeaderBreadcrumbs from 'src/template/components/HeaderBreadcrumbs';
import Iconify from 'src/template/components/Iconify';
import NftPacksTable from '../../../components/Table';
import useNftPacksView from './useView';

const STATUS_TABS = [
  { value: 'all', label: 'All' },
  { value: 'draft', label: 'Draft' },
  { value: 'ready', label: 'Ready' },
];

const searchOptions = [
  {
    label: 'Name',
    query: 'name',
  },
];

export default function NftPacks() {
  const {
    data,
    isLoading,
    handleOpenCreateModal,
    handleOpenUpdateModal,
    handleOpenDeleteModal,
    handleOpenChangeStatusModal,
    onChangeStatus,
    status,
    page,
    size,
    order,
    sortBy,
    searchBy,
    query,
  } = useNftPacksView();

  const { addParam, removeParam } = useURLSearchParams();

  function handleChangeSearch(value: string, param: string = 'query') {
    if (!value) removeParam(param);
    else addParam(param, value);
  }

  const debouncedSearch = debounce(handleChangeSearch, 300);

  return (
    <>
      <HeaderBreadcrumbs
        heading="NFT Packs"
        links={[{ name: 'NFT Packs' }]}
        action={
          <Button
            variant="contained"
            color="secondary"
            onClick={handleOpenCreateModal}
            startIcon={<Add />}
          >
            Add NFT Pack
          </Button>
        }
      />
      <Card>
        <Flex sx={{ px: 2, bgcolor: 'background.neutral' }}>
          <Tabs
            allowScrollButtonsMobile
            variant="scrollable"
            scrollButtons="auto"
            value={status}
            onChange={onChangeStatus}
          >
            {STATUS_TABS.map((tab) => (
              <Tab
                disableRipple
                key={tab.value}
                label={tab.label}
                value={tab.value}
              />
            ))}
          </Tabs>
        </Flex>
        <Flex gap={2} p={2}>
          <Autocomplete
            options={searchOptions}
            fullWidth
            renderInput={(params) => (
              <TextField label="Search By" {...params} />
            )}
            getOptionLabel={(p) => p.label}
            sx={{ maxWidth: 250 }}
            defaultValue={searchOptions.find((o) => o.query === searchBy)}
            key={searchBy}
            onChange={(_, value) => {
              if (!value) removeParam('searchBy');
              else addParam('searchBy', value.query);
            }}
          />

          <TextField
            fullWidth
            label="Search"
            defaultValue={query}
            type="search"
            onChange={(e) => {
              const { value } = e.target;
              debouncedSearch(value);
            }}
            key={query}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <Iconify
                    icon="eva:search-fill"
                    sx={{ color: 'text.disabled', width: 20, height: 20 }}
                  />
                </InputAdornment>
              ),
            }}
          />
        </Flex>

        <CardContent>
          <NftPacksTable
            data={data ?? []}
            isLoading={isLoading}
            page={page}
            size={size}
            sortBy={sortBy!}
            order={order as GridSortDirection}
            handleOpenUpdateModal={handleOpenUpdateModal}
            handleOpenDeleteModal={handleOpenDeleteModal}
            handleOpenChangeStatusModal={handleOpenChangeStatusModal}
          />
        </CardContent>
      </Card>
    </>
  );
}
