import { yupResolver } from '@hookform/resolvers/yup';
import { Add, Close } from '@mui/icons-material';
import {
  Alert,
  Box,
  Card,
  CardContent,
  CardHeader,
  Checkbox,
  MenuItem,
  Stack,
} from '@mui/material';
import { capitalCase } from 'change-case';
import { useEffect, useRef, useState } from 'react';
import { useFieldArray, useForm } from 'react-hook-form';
import Button from 'src/components/shared/Buttons/CustomButton';
import Flex from 'src/components/shared/Flex';
import ControllerField from 'src/components/shared/Inputs/ControllerField';
import Modal from 'src/components/shared/Modal';
import useModalStore from 'src/lib/stores/useModalStore';
import {
  ContestAwardDistributionModes,
  ContestFrequency,
} from 'src/lib/types/contest';
import * as yup from 'yup';
import { fetchStatisticsGroup } from '../../../services/fetchStatisticsGroup';
import { ContestFormValues } from './types';

const validationSchema = yup.object().shape({
  name: yup.string().required('Name is required'),
  details: yup.string().required('Details is required'),
  awardValue: yup.number().when('awardDistributionMode', {
    is: (distMode: ContestAwardDistributionModes) =>
      distMode === ContestAwardDistributionModes.FIXED,
    then: yup.number().nullable().min(1).required('Award value is required'),
    otherwise: yup.number().nullable(),
  }),
  awardDistributionMode: yup
    .string()
    .required('Award distribution mode is required'),
  entryPrice: yup.number().min(0).required('Entry price is required'),
  playerEntryLimit: yup.number().nullable().min(1),
  totalEntries: yup.number().nullable().min(1),
  startDate: yup.string().required('Start date is required'),
  endDate: yup
    .string()
    .nullable()
    .test({
      name: 'nonEmpty',
      message: 'End date is required',
      test(value) {
        return (
          value === null || (typeof value === 'string' && value.trim() !== '')
        );
      },
    }),
  frequency: yup.string().required('Frequency is required'),
  gameUuid: yup.string().required('Game is required'),
  primaryColor: yup.string().required('Primary color is required'),
  secondaryColor: yup.string().required('Secondary color is required'),
  awardWinningStatisticsGroupUuid: yup
    .string()
    .required('Statistic group is required'),
  positionAwards: yup
    .array()
    .of(
      yup.object().shape({
        value: yup.number().min(1).required('Award value is required'),
      })
    )
    .min(1)
    .when('awardDistributionMode', {
      is: (distMode: ContestAwardDistributionModes) =>
        distMode === ContestAwardDistributionModes.FIXED,
      then: yup.array().test({
        name: 'sumOfAwardsFixed',
        exclusive: true,
        message: 'The sum of all awards must be equal Award Value.',
        test: (value, { parent }) => {
          if (value![0] == null) return false;
          const sum = value!.reduce(
            (accumulator, positionAward) => accumulator + positionAward.value,
            0
          );
          return sum === parent.awardValue;
        },
      }),
      otherwise: yup.array().test({
        name: 'sumOfAwardsPercentual',
        exclusive: true,
        message: 'The sum of all awards must be 100.',
        test: (value) => {
          if (value![0] == null) return false;
          const sum = value!.reduce(
            (accumulator, positionAward) => accumulator + positionAward.value,
            0
          );
          return sum === 100;
        },
      }),
    }),
});

type Props = {
  defaultValues: ContestFormValues;
  onSubmit: (values: ContestFormValues) => Promise<void>;
  title: string;
};

export default function ContestForm({
  defaultValues,
  title,
  onSubmit,
}: Props): JSX.Element {
  const onClose = useModalStore((state) => state.closeModal);
  const {
    control,
    handleSubmit,
    formState: { isSubmitting, errors },
    setValue,
    watch,
    getFieldState,
    trigger,
    getValues,
  } = useForm<ContestFormValues>({
    defaultValues: {
      ...defaultValues,
      positionAwards: defaultValues.positionAwards || [0],
    },
    resolver: yupResolver(validationSchema),
  });

  const handleCloseModal = () => {
    onClose();
  };

  const [hasNullEndDate, setHasNullEndDate] = useState(
    defaultValues.endDate === null
  );
  const [withoutPlayerEntryLimit, setwithoutPlayerEntryLimit] = useState(
    defaultValues.playerEntryLimit === null
  );
  const [withoutTotalEntriesLimit, setwithoutTotalEntriesLimit] = useState(
    defaultValues.totalEntries === null
  );

  const firstUpdate = useRef(true);

  const distMode = watch('awardDistributionMode');
  const awardValue = watch('awardValue');
  useEffect(() => {
    if (firstUpdate.current) {
      firstUpdate.current = false;
      return;
    }

    setValue(
      'positionAwards',
      defaultPositionAwards[distMode](getValues('awardValue')!)
    );
  }, [distMode, awardValue]);

  useEffect(() => {
    const verifyAwardState = async () => {
      const isValid = await trigger('positionAwards');
      if (isValid) {
        return;
      }
      setValue(
        'positionAwards',
        defaultPositionAwards[distMode](getValues('awardValue')!)
      );
    };

    verifyAwardState();
  }, []);

  const {
    fields: selectedPositionAwards,
    remove: removePositionAward,
    append: appendPositionAward,
  } = useFieldArray({
    control,
    name: 'positionAwards',
  });

  const handleAddPositionAward = (value: number) => {
    appendPositionAward({ value });
  };

  const handleRemovePositionAwardByIndex = (index: number) => {
    if (selectedPositionAwards.length > 1) removePositionAward(index);
  };

  const [currentStep, setCurrentStep] = useState(1); // 1: table, 2: position awards

  const handleNextStep = async () => {
    const isValid = await trigger();

    if (isValid) {
      setCurrentStep(currentStep + 1);
    }
  };

  const handlePreviousStep = () => {
    setCurrentStep(currentStep - 1);
  };

  // awardWinningStatisticsGroup

  const { data: statisticsGroup } = fetchStatisticsGroup();

  return (
    <Modal open onClose={handleCloseModal}>
      <Card
        sx={{
          width: currentStep == 1 ? '600px' : '400px',
        }}
      >
        <CardHeader title={title} />
        <CardContent>
          <form onSubmit={handleSubmit(onSubmit)}>
            {currentStep == 1 && (
              <Stack>
                <Stack mb={2} spacing={1.5}>
                  <ControllerField
                    control={control}
                    name="name"
                    label="Name"
                    fullWidth
                  />
                  <ControllerField
                    control={control}
                    name="details"
                    label="Details"
                    fullWidth
                    multiline
                    rows={2}
                  />

                  <Stack direction={'row'} gap={2}>
                    <ControllerField
                      control={control}
                      name="awardDistributionMode"
                      label="Award Mode"
                      fullWidth
                      select
                    >
                      {Object.values(ContestAwardDistributionModes).map(
                        (distModeOption) => (
                          <MenuItem key={distModeOption} value={distModeOption}>
                            {capitalCase(distModeOption)}
                          </MenuItem>
                        )
                      )}
                    </ControllerField>

                    {distMode != ContestAwardDistributionModes.PERCENTUAL && (
                      <ControllerField
                        control={control}
                        name="awardValue"
                        label="Award Value"
                        fullWidth
                        type="number"
                      />
                    )}
                    <ControllerField
                      control={control}
                      name="entryPrice"
                      label="Entry Price"
                      fullWidth
                      type="number"
                    />
                  </Stack>

                  <Stack direction={'row'} gap={2}>
                    <ControllerField
                      control={control}
                      name="awardWinningStatisticsGroupUuid"
                      label="Award Winning Statistic Group"
                      fullWidth
                      select
                    >
                      {Object.values(statisticsGroup ?? []).map((group) => (
                        <MenuItem key={group.uuid} value={group.uuid}>
                          {capitalCase(group.name)}
                        </MenuItem>
                      ))}
                    </ControllerField>
                  </Stack>

                  <Stack direction={'row'} gap={2}>
                    <Box
                      sx={{
                        width: '50%',
                      }}
                    >
                      <ControllerField
                        control={control}
                        name="playerEntryLimit"
                        label="Play Limit"
                        fullWidth
                        type="number"
                        disabled={withoutPlayerEntryLimit}
                        InputLabelProps={{
                          shrink: true,
                          disableAnimation: true,
                        }}
                      />
                    </Box>
                    <Flex
                      sx={{
                        width: '50%',
                      }}
                    >
                      Unlimited Plays:
                      <Checkbox
                        onChange={(e) => {
                          setValue(
                            'playerEntryLimit',
                            e.target.checked ? null : 0
                          );
                          setwithoutPlayerEntryLimit(!withoutPlayerEntryLimit);
                        }}
                        checked={withoutPlayerEntryLimit}
                      />
                    </Flex>
                  </Stack>

                  <Stack direction={'row'} gap={2}>
                    <Box
                      sx={{
                        width: '50%',
                      }}
                    >
                      <ControllerField
                        control={control}
                        name="totalEntries"
                        label="Max Players"
                        fullWidth
                        type="number"
                        disabled={withoutTotalEntriesLimit}
                        InputLabelProps={{
                          shrink: true,
                          disableAnimation: true,
                        }}
                      />
                    </Box>
                    <Flex
                      sx={{
                        width: '50%',
                      }}
                    >
                      Unlimited Players:
                      <Checkbox
                        onChange={(e) => {
                          setValue('totalEntries', e.target.checked ? null : 0);
                          setwithoutTotalEntriesLimit(
                            !withoutTotalEntriesLimit
                          );
                        }}
                        checked={withoutTotalEntriesLimit}
                      />
                    </Flex>
                  </Stack>

                  <Stack direction={'row'} gap={2}>
                    <ControllerField
                      control={control}
                      name="frequency"
                      label="Frequency"
                      fullWidth
                      select
                    >
                      {Object.values(ContestFrequency).map((freq) => (
                        <MenuItem key={freq} value={freq}>
                          {capitalCase(freq)}
                        </MenuItem>
                      ))}
                    </ControllerField>
                    <ControllerField
                      control={control}
                      name="startDate"
                      label="Start Date"
                      fullWidth
                      type="datetime-local"
                      InputLabelProps={{ shrink: true, disableAnimation: true }}
                    />
                  </Stack>
                  <Stack direction={'row'} gap={2}>
                    <Box
                      sx={{
                        width: '50%',
                      }}
                    >
                      <ControllerField
                        control={control}
                        name="endDate"
                        label="End Date"
                        fullWidth
                        type="datetime-local"
                        disabled={hasNullEndDate}
                        InputLabelProps={{
                          shrink: true,
                          disableAnimation: true,
                        }}
                      />
                    </Box>
                    <Flex>
                      Without End Date:
                      <Checkbox
                        onChange={(e) => {
                          setValue('endDate', e.target.checked ? null : '');
                          setHasNullEndDate(!hasNullEndDate);
                        }}
                        checked={hasNullEndDate}
                      />
                    </Flex>
                  </Stack>

                  <Stack direction={'row'} gap={2}>
                    <ControllerField
                      control={control}
                      name="primaryColor"
                      label="Primary Color"
                      fullWidth
                      type="color"
                      InputLabelProps={{ shrink: true, disableAnimation: true }}
                    />
                    <ControllerField
                      control={control}
                      name="secondaryColor"
                      label="Secondary Color"
                      fullWidth
                      type="color"
                      InputLabelProps={{ shrink: true, disableAnimation: true }}
                    />
                  </Stack>
                </Stack>
              </Stack>
            )}
            {currentStep == 2 && (
              <Stack gap={2}>
                {selectedPositionAwards.map((award, index) => (
                  <Flex
                    // eslint-disable-next-line react/no-array-index-key
                    key={index}
                    justifyContent="space-between"
                    alignItems="center"
                    gap={2}
                  >
                    <ControllerField
                      control={control}
                      name={`positionAwards.${index}.value`}
                      label={`Position ${index + 1}`}
                      type="number"
                      fullWidth
                    />

                    <Button
                      onClick={() => handleRemovePositionAwardByIndex(index)}
                      size="large"
                    >
                      <Close />
                    </Button>
                  </Flex>
                ))}
                <Button onClick={() => handleAddPositionAward(0)}>
                  <Add />
                </Button>

                {getFieldState(`positionAwards`).error?.message && (
                  <Alert severity="error">
                    {getFieldState(`positionAwards`).error?.message}
                  </Alert>
                )}
              </Stack>
            )}
            <Stack
              direction="row"
              justifyContent="space-between"
              spacing={1}
              mt={2}
            >
              {currentStep === 2 ? (
                <Button
                  variant="contained"
                  color="error"
                  type="button"
                  onClick={handlePreviousStep}
                  size="large"
                >
                  Previous
                </Button>
              ) : (
                <Button color="error" onClick={handleCloseModal} size="large">
                  Cancel
                </Button>
              )}
              {currentStep === 1 ? (
                <Button
                  variant="contained"
                  color="secondary"
                  type="button"
                  onClick={(e) => {
                    e.preventDefault();

                    handleNextStep();
                  }}
                  size="large"
                >
                  Next
                </Button>
              ) : (
                <Button
                  variant="contained"
                  color="secondary"
                  type="submit"
                  loading={isSubmitting}
                  size="large"
                  disabled={selectedPositionAwards.length === 0}
                >
                  Save
                </Button>
              )}
            </Stack>
          </form>
        </CardContent>
      </Card>
    </Modal>
  );
}

const defaultPositionAwards = {
  [ContestAwardDistributionModes.PERCENTUAL]: () => [
    { value: 75 },
    { value: 20 },
    { value: 5 },
  ],
  [ContestAwardDistributionModes.FIXED]: (awardValue: number) => [
    { value: awardValue },
  ],
};
