import { Typography, TypographyProps } from '@mui/material';
import { useMemo } from 'react';

type NumericTypographyProps = TypographyProps & {
  formatDigits?: boolean;
  formatDecimal?: boolean;
  currency?: string;
  sign?: boolean;
  component?: any;
};

export default function NumericTypography({
  children,
  formatDigits = false,
  formatDecimal = false,
  currency = undefined,
  sign = false,
  ...props
}: NumericTypographyProps): JSX.Element {
  const digit = useMemo(() => {
    if (!formatDigits && !formatDecimal) return children;

    const value =
      !children || typeof children !== 'number'
        ? 0
        : currency
        ? Math.abs(Number(children))
        : children;

    return value.toLocaleString(formatDigits ? 'en-US' : undefined, {
      ...(formatDecimal
        ? {
            minimumFractionDigits: 2,
            maximumFractionDigits: 2,
          }
        : {}),
      ...(currency
        ? {
            style: 'currency',
            currency,
          }
        : {}),
    });
  }, [children]);

  return (
    <Typography
      color={sign ? (Number(children) < 0 ? 'red' : 'green') : ''}
      fontWeight="bold"
      {...props}
      sx={{
        fontVariantNumeric: 'tabular-nums',
        ...props.sx,
      }}
    >
      {sign && Number(children) > 0 ? '+' : ''}
      {digit}
    </Typography>
  );
}
