import { Avatar, Stack } from '@mui/material';
import { Channel } from 'src/lib/types/channel';
import Routes from 'src/routes/Routes';
import Flex from '../Flex';
import Link from '../Link';

type Props = {
  channel: Pick<Channel, 'uuid' | 'name' | 'backgroundImageUrl'>;
  linkUrl?: string;
};
export default function MagicTableChannelCell({
  channel,
  linkUrl,
}: Props): JSX.Element {
  const { uuid, name, backgroundImageUrl } = channel;
  return (
    <Flex component="span" gap={2}>
      <Avatar
        alt={name ?? 'channel background image'}
        src={backgroundImageUrl ?? ''}
      />
      <Stack>
        <Link
          to={linkUrl ?? Routes.channel.channel(uuid)}
          color={name !== 'empty' ? 'inherit' : 'GrayText'}
          underline="hover"
        >
          {name}
        </Link>
      </Stack>
    </Flex>
  );
}
