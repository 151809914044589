import { Autocomplete, InputAdornment, TextField } from '@mui/material';
import Iconify from 'src/template/components/Iconify';
import { FilterProps } from './types';

export function GameRatingsTableUserFilters({
  addParam,
  removeParam,
  debouncedChangeField,
  propParams,
}: FilterProps) {
  const userSearchOptions = [
    {
      label: 'User Name',
      query: 'user.name',
    },
    {
      label: 'User Slug',
      query: 'user.slug',
    },
    {
      label: 'User Email',
      query: 'user.email',
    },
    {
      label: 'User UUID',
      query: 'user.uuid',
    },
  ];
  return (
    <>
      <Autocomplete
        options={userSearchOptions}
        fullWidth
        renderInput={(params) => (
          <TextField label="Search User By" {...params} fullWidth />
        )}
        getOptionLabel={(p) => p.label}
        sx={{ maxWidth: 250 }}
        defaultValue={
          userSearchOptions.find((o) => o.query === propParams.userSearchBy) ||
          null
        }
        key={'userSearchBy'}
        onChange={(_, value) => {
          if (!value) removeParam('userSearchBy');
          else addParam('userSearchBy', value.query);
        }}
      />
      <TextField
        fullWidth
        label="Search"
        defaultValue={propParams.userQuery}
        type="search"
        onChange={(e) => {
          const { value } = e.target;
          debouncedChangeField(value, 'userQuery');
        }}
        key={'userQuery'}
        InputProps={{
          startAdornment: (
            <InputAdornment position="start">
              <Iconify
                icon="eva:search-fill"
                sx={{ color: 'text.disabled', width: 20, height: 20 }}
              />
            </InputAdornment>
          ),
        }}
      />
    </>
  );
}
