import { useCallback, useEffect } from 'react';
import { DEFAULT_INITIAL_PAGE_SIZE } from 'src/lib/constants/pagination';
import useURLSearchParams from 'src/lib/hooks/useURLSearchParams';
import useModalStore from 'src/lib/stores/useModalStore';
import { EntityNote, EntityNoteTypes } from 'src/lib/types/entityNote';
import { EntityNoteEmitter } from '../../events/EntityNoteEmitter';
import { fetchEntityNotes } from '../../services/fetchEntityNotes';
import { fetchUserEntityNotes } from '../../services/fetchUserEntityNotes';
import CreateEntityNote from '../../views/Create';
import DeleteEntityNote from '../../views/Delete';
import UpdateEntityNote from '../../views/Update';

interface UseEntityNotesTableProps {
  entityUuid?: string;
  userUuid?: string;
}

export function useEntityNotesTable({
  userUuid,
  entityUuid,
}: UseEntityNotesTableProps) {
  const [openModal] = useModalStore((state) => [
    state.openModal,
    state.closeModal,
  ]);

  const handleDeleteEntityNote = async (uuid: string) => {
    openModal(<DeleteEntityNote uuid={uuid} />);
  };

  const handleUpdateEntityNote = (entityNote: EntityNote) => {
    openModal(<UpdateEntityNote entityNote={entityNote} />);
  };

  const handleCreateEntityNote = (
    entityType: EntityNoteTypes,
    uuid: string
  ) => {
    const closureCreate = () => {
      openModal(<CreateEntityNote entityType={entityType} entityUuid={uuid} />);
    };

    return closureCreate;
  };

  const { allSearchParams } = useURLSearchParams(initialParams);
  const size = +(allSearchParams?.size ?? initialParams.size);
  const page = +(allSearchParams?.page ?? initialParams.page);
  const orderBy = entityUuid
    ? initialParams.orderBy
    : allSearchParams?.orderBy ?? initialParams.orderBy;
  const sortOrder = entityUuid
    ? initialParams?.order
    : allSearchParams?.order ?? initialParams.order;
  const searchBy = allSearchParams?.searchBy ?? initialParams.searchBy;

  const fetchData = {
    page,
    size,
    order: {
      [orderBy]: sortOrder,
    },
    filter: {
      ...(entityUuid && {
        entityUuid: {
          eq: entityUuid,
        },
      }),
      ...(searchBy &&
        allSearchParams?.query && {
          [searchBy]: {
            like: `%${allSearchParams.query}%`,
          },
        }),
    },
  };

  const { data, isLoading, mutate } = userUuid
    ? fetchUserEntityNotes(userUuid, fetchData)
    : fetchEntityNotes(fetchData);

  const refetchData = useCallback(() => {
    mutate?.();
  }, []);

  useEffect(() => {
    EntityNoteEmitter.on('entityNoteCreated', refetchData);
    EntityNoteEmitter.on('entityNoteUpdated', refetchData);
    EntityNoteEmitter.on('entityNoteDeleted', refetchData);

    return () => {
      EntityNoteEmitter.off('entityNoteCreated', refetchData);
      EntityNoteEmitter.off('entityNoteUpdated', refetchData);
      EntityNoteEmitter.off('entityNoteDeleted', refetchData);
    };
  }, [refetchData]);

  return {
    handleUpdateEntityNote,
    handleDeleteEntityNote,
    handleCreateEntityNote,
    data,
    isLoading,
    params: {
      page,
      size,
      orderBy,
      sortOrder,
      searchBy,
    },
  };
}

const initialParams = {
  page: '0',
  size: DEFAULT_INITIAL_PAGE_SIZE.toString(),
  order: 'desc',
  orderBy: 'createdAt',
  searchBy: 'users.slug',
};

export type FetchEntitynotesQueryParams = {
  page: number;
  size: number;
  orderBy: string;
  searchBy: string;
  sortOrder: string;
};
