import { ContentCopy, Edit, OpenInNew } from '@mui/icons-material';
import {
  Checkbox,
  MenuItem,
  Stack,
  TableCell,
  TableRow,
  Typography,
} from '@mui/material';
import { capitalCase } from 'change-case';
import { useState } from 'react';
import CopyToClipboard from 'react-copy-to-clipboard';
import { useNavigate } from 'react-router';
import Link from 'src/components/shared/Link';
import LinkToUser from 'src/components/shared/LinkToUser';
import MoreMenu from 'src/components/shared/MoreMenu';
import SmartTable from 'src/components/shared/SmartTable/SmartTable';
import { fetchReports } from 'src/lib/services/reports';
import createFilterStore from 'src/lib/stores/createFilterStore';
import createSelectStore from 'src/lib/stores/createSelectStore';
import { Report } from 'src/lib/types/report';
import { formatDate } from 'src/lib/utils/formatters';
import Routes from 'src/routes/Routes';
import HeaderBreadcrumbs from 'src/template/components/HeaderBreadcrumbs';
import Label, { LabelColor } from 'src/template/components/Label';
import useTabs from 'src/template/hooks/useTabs';
import ReportsFilter from './ReportsFilter';
import UpdateReportModal from './UpdateReportModal';
import {
  REPORT_COLUMNS,
  REPORT_STATUS_OPTIONS,
  SEARCH_FOR_OPTIONS,
  getReportPlatform,
} from './utils';

const useSelected = createSelectStore();

const useFilter = createFilterStore({
  orderBy: 'createdAt',
  order: 'desc',
  searchFor: 'name',
  query: '',
  page: 0,
  size: 30,
  custom: {},
});

export default function Reports() {
  const navigate = useNavigate();
  const { filter, setFilter } = useFilter();
  const { selected, toggleSelected, toggleSelectAll } = useSelected();
  const { currentTab: status, onChangeTab: onChangeStatus } = useTabs('all');
  const [reportToUpdate, setReportToUpdate] = useState<Report | null>(null);
  const {
    data: reports,
    total,
    loading,
    queryParams,
  } = fetchReports(filter, status);
  const currentTimezone = new Date()
    .toString()
    .match(/([A-Z]+[+-][0-9]+)/)?.[1];
  return (
    <>
      <HeaderBreadcrumbs heading="Reports" links={[{ name: 'Reports' }]} />
      <Stack spacing={3}>
        <Typography textAlign="center" color="text.secondary" variant="body2">
          Dates are in your local timezone. Your timezone is {currentTimezone}
        </Typography>
        <SmartTable
          data={reports}
          loading={loading}
          total={total || 0}
          tabs={REPORT_STATUS_OPTIONS}
          activeTab={status}
          onChangeTab={onChangeStatus}
          columns={REPORT_COLUMNS}
          filter={filter}
          setFilter={setFilter}
          filterModal={<ReportsFilter filter={filter} setFilter={setFilter} />}
          searchForOptions={SEARCH_FOR_OPTIONS}
          selected={selected}
          onSelectAll={() => {
            toggleSelectAll(reports?.map((t) => t.uuid) || []);
          }}
        >
          {reports &&
            reports.map((r) => {
              const { uuid: objectUuid } = getObjectFromReport(r);
              return (
                <TableRow key={r.uuid} hover>
                  <TableCell padding="checkbox">
                    <Checkbox
                      checked={selected.includes(r.uuid)}
                      onClick={() => toggleSelected(r.uuid)}
                    />
                  </TableCell>
                  <TableCell>{formatDate(r.createdAt)}</TableCell>
                  <TableCell>
                    <Label
                      variant="ghost"
                      color={
                        REPORT_STATUS_OPTIONS?.find(
                          (opt) => opt.value === r.status
                        )?.color as LabelColor | undefined
                      }
                      sx={{ textTransform: 'capitalize' }}
                    >
                      {REPORT_STATUS_OPTIONS?.find(
                        (opt) => opt.value === r.status
                      )?.label || '-'}
                    </Label>
                  </TableCell>
                  <TableCell>
                    <LinkToUser user={r.user} />
                  </TableCell>
                  <TableCell sx={{ maxWidth: 300, overflowWrap: 'break-word' }}>
                    {r.details}
                  </TableCell>
                  <TableCell>{objectCell(r)}</TableCell>
                  <TableCell>{getReportPlatform(r.platform)}</TableCell>
                  <TableCell>{r.version}</TableCell>
                  <TableCell align="right">
                    <MoreMenu>
                      <CopyToClipboard text={r.uuid}>
                        <MenuItem>
                          <ContentCopy />
                          Copy UUID
                        </MenuItem>
                      </CopyToClipboard>
                      <CopyToClipboard text={objectUuid}>
                        <MenuItem>
                          <ContentCopy />
                          Copy Object UUID
                        </MenuItem>
                      </CopyToClipboard>
                      <MenuItem
                        onClick={() => {
                          setReportToUpdate(r);
                        }}
                      >
                        <Edit />
                        Update Report
                      </MenuItem>
                      {r.collectionUuid && (
                        <MenuItem
                          onClick={() => {
                            navigate(`/collections/${r.collectionUuid}`);
                          }}
                        >
                          <OpenInNew />
                          Collection Details
                        </MenuItem>
                      )}
                    </MoreMenu>
                  </TableCell>
                </TableRow>
              );
            })}
        </SmartTable>
        {reportToUpdate && (
          <UpdateReportModal
            report={reportToUpdate}
            showModal={!!reportToUpdate}
            setShowModal={(show) => !show && setReportToUpdate(null)}
            queryParams={queryParams}
          />
        )}
      </Stack>
    </>
  );
}

const objectCell = (report: Report) => {
  const { link, type, uuid } = getObjectFromReport(report);

  return (
    <Link to={link}>
      <Typography variant="body2" color="primary">
        {capitalCase(type)}
      </Typography>
    </Link>
  );
};

const getObjectFromReport = (
  report: Report
): {
  link: string;
  uuid: string;
  type: string;
} => {
  const {
    clubUuid,
    collectionUuid,
    gameVersionUuid,
    nftUuid,
    postId,
    reportedUserUuid,
    roomId,
    messageId,
    feedItemUuid,
    reviewUuid,
    channelUuid,
    promptDialogUuid,
  } = report;

  // Determina o tipo do relatório com base na prioridade
  const reportType = clubUuid
    ? 'club'
    : collectionUuid
    ? 'collection'
    : gameVersionUuid
    ? 'game-version'
    : nftUuid
    ? 'nft'
    : postId
    ? 'post'
    : reportedUserUuid
    ? 'user'
    : messageId
    ? 'message'
    : roomId
    ? 'room'
    : feedItemUuid
    ? 'feed-item'
    : reviewUuid
    ? 'review'
    : channelUuid
    ? 'channel'
    : promptDialogUuid
    ? 'prompt-dialog'
    : '';

  // Define os valores com base no tipo do relatório
  switch (reportType) {
    case 'club':
      return {
        link: Routes.comunity.club(clubUuid!),
        uuid: clubUuid!,
        type: 'club',
      };
    case 'collection':
      return {
        link: Routes.collection(collectionUuid!),
        uuid: collectionUuid!,
        type: 'collection',
      };
    case 'game-version':
      return {
        link: Routes.game_center.game.view(report.gameVersion?.gameUuid ?? ''),
        uuid: gameVersionUuid!,
        type: 'game-version',
      };
    case 'nft':
      return {
        link: Routes.nft(nftUuid!),
        uuid: nftUuid!,
        type: 'nft',
      };
    case 'post':
      return {
        link: Routes.external.community.post(postId!),
        uuid: postId!.toString(),
        type: 'post',
      };
    case 'user':
      return {
        link: Routes.user(reportedUserUuid!),
        uuid: reportedUserUuid!,
        type: 'user',
      };
    case 'feed-item':
      return {
        link: Routes.channel.content(report.feedItem?.uuid ?? ''),
        uuid: feedItemUuid!,
        type: 'feed-item',
      };
    case 'channel':
      return {
        link: Routes.channel.channel(channelUuid!),
        uuid: channelUuid!,
        type: 'Channel',
      };
    case 'prompt-dialog':
      return {
        link: Routes.sgm.dialog(promptDialogUuid!),
        uuid: promptDialogUuid!,
        type: 'SGM Chat',
      };
    case 'review':
      return {
        link: '',
        uuid: reviewUuid!,
        type: 'review',
      };
    case 'message':
      return {
        link: Routes.comunity.chat.message(roomId!, messageId!),
        uuid: messageId!,
        type: 'Chat Message',
      };
    case 'room':
      return {
        link: Routes.comunity.chat.chat(roomId!),
        uuid: roomId!,
        type: 'Chat Room',
      };
    default:
      return {
        link: '',
        uuid: '',
        type: '',
      };
  }
};
