import getAPIClient from 'src/lib/services/api/axios.config';
import { getMessageFromError } from 'src/lib/utils/error';
import { UserPurge } from '../../Profile/types/UserPurge';

export async function PurgeUser(userPurgeUuid: UserPurge['uuid']) {
  try {
    const api = await getAPIClient();
    const res = await api.post(`admin/user-purge/start/${userPurgeUuid}`);
    return {
      data: res.data,
      error: null,
    };
  } catch (e) {
    return {
      data: null,
      error: getMessageFromError(e),
    };
  }
}
