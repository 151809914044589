import { WithTimestamp } from './WithTimestamp';

export enum SGMPromptType {
  GAME_CHAT = 'game_chat',
  GAME_DETAILS = 'game_details',
  PHASER = 'game_phaser',
  PHASER_OPEN = 'game_phaser_open',
  PHASER_AGENT = 'game_phaser_agent',
  GAME_CHAT_OPEN = 'game_chat_open',
  GAME_CHAT_AGENT = 'game_chat_agent',
  // DO NOT ADD NEW PROMPT TYPES HERE, THIS IS A LEGACY ENUM
}

export type SGMPrompt = WithTimestamp<{
  id: string;
  type: SGMPromptType;
  prompt: string;
  title: string;
  description: string;
}>;
