import useNotify from 'src/lib/hooks/useNotify';
import useModalStore from 'src/lib/stores/useModalStore';
import { SGMPromptEmitter } from '../../events/SGMPromptEmitter';
import { restoreMakerPrompt } from '../../services/restoreMakerPrompt';

export default function useRestorePromptVersion() {
  const closeModal = useModalStore((state) => state.closeModal);
  const notify = useNotify();

  const handleRestorePromptVersion = async (type: string, id: string) => {
    const { data, error } = await restoreMakerPrompt(type, id);
    if (error) {
      notify(error, { variant: 'error' });
    } else {
      notify('Prompt Restored!', { variant: 'success' });
      SGMPromptEmitter.emit('SGMVersionRestored', null);
      closeModal();
    }
  };

  return {
    handleRestorePromptVersion,
  };
}
