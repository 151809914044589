import { DeepPartial } from 'react-hook-form';
import { GameRankingPositionResult } from 'src/lib/types/gameRanking';
import { parseSearchParams } from 'src/lib/utils/parseSearchParams';
import useSWR, { SWRResponse } from 'swr';

export type PossibleParams = {
  page: number | string;
  size: number | string;
  order: Record<string, string>;
  filter: {
    [key: string]: {
      like: string;
      eq: string;
    };
  };
};

export const fetchGameRankings = (
  gameUuid: string,
  filter?: DeepPartial<PossibleParams>
): SWRResponse<FetchGameRankingsResponse> => {
  const parsedParams = parseSearchParams(filter ?? {});
  const response = useSWR<FetchGameRankingsResponse>(
    `admin/game/${gameUuid}/ranking?${parsedParams}`
  );

  return response;
};

export type FetchGameRankingsResponse = {
  gameUuid: string;
  totalUsers: number;
  playerResult: GameRankingPositionResult;
  ranking: GameRankingPositionResult[];
};
