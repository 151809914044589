import * as yup from 'yup';

export const AddNftsFormValidation = yup.object().shape({
  nftData: yup.object().shape({
    name: yup.string().required('Nft name is required'),
    price: yup.string().test({
      test: (value) => {
        if (value) return Number(value) >= 0;
        return true;
      },
      message: 'Invalid price value',
    }),
    editions: yup
      .number()
      .positive()
      .required('Number of editions is required'),
    tags: yup.string().nullable(),
  }),
  fileData: yup.array().of(
    yup.object().shape({
      url: yup.string().required('File url is required'),
      name: yup.string().required('File name is required'),
      type: yup.string().required('File type is required'),
      size: yup.number().positive().required('File size is required'),
    })
  ),
});

export type AddNftsFormValues = {
  nftData: {
    name: string;
    price: number;
    editions: number;
    tags: string;
  };
  files: File[];
};
