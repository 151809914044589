import { MenuItem, MenuItemProps } from '@mui/material';

export default function SelectOption({
  children,
  ...props
}: MenuItemProps): JSX.Element {
  return (
    <MenuItem
      {...props}
      sx={{
        mx: 1,
        my: 0.5,
        borderRadius: 0.75,
        typography: 'body2',
        ...props.sx,
      }}
    >
      {children}
    </MenuItem>
  );
}
