import { CircularProgress, CircularProgressProps } from '@mui/material';
import Center, { CenterProps } from './Center';

type Props = CircularProgressProps & {
  wrapperProps?: Omit<CenterProps, 'children'>;
};
export default function Loader({ wrapperProps, ...props }: Props): JSX.Element {
  return (
    <Center {...wrapperProps}>
      <CircularProgress {...props} />
    </Center>
  );
}
