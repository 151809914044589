import { Button, Card } from '@mui/material';
import Flex from 'src/components/shared/Flex';
// import { fetchStickyNftsTotalBalance } from 'src/lib/services/nft';
import { useState } from 'react';
import {
  fetchStickyNftsTotalBalance,
  refreshStickyNftsPreloadedTable,
} from 'src/lib/services/nft';
import Iconify from 'src/template/components/Iconify';
import Logo from 'src/template/components/Logo';
import useSettings from 'src/template/hooks/useSettings';
import AllStickyNfts from './AllStickyNftsView';
import './style.css';
import { matchingColorsThemed } from './utils';

export default function AllStickyNftsPreloaded() {
  const theme = useSettings().themeMode;
  // const theme = themeSettings.themeMode;

  const root = document.documentElement;
  root.style.setProperty(
    '--fontColor',
    theme === 'light' ? '#333333' : '#ffffff'
  );

  const { totalBalance, loading, error } = fetchStickyNftsTotalBalance();

  const align = 'right';

  const [isDisabled, setIsDisabled] = useState(false);

  const handleClick = () => {
    setIsDisabled(true); // Set button disabled

    // Simulate 1 minute wait (1000 milliseconds per second)
    setTimeout(() => {
      setIsDisabled(false); // Re-enable button after 1 minute
    }, 5000); // 60 seconds * 1000 milliseconds
  };

  const refreshPreloadedTable = async () => {
    handleClick();
    const res = await refreshStickyNftsPreloadedTable();
    try {
      alert(
        `Tried to update ${res?.data?.tried} balances\nSuccesfully updated ${res?.data.succeeded}`
      );
    } catch (err) {
      alert(`Error while refreshing: ${err}`);
      console.log(err);
    }
  };

  return (
    <>
      {/* <Card sx={{ textAlign: 'center', marginTop: 5 }}>
        <Flex
          sx={{
            textAlign: 'center',
            alignItems: 'center',
            alignContent: 'center',
            justifyContent: 'center',
            margin: '0 auto',
          }}
        >
          {`Coming soon`}
        </Flex>
        <Flex
          className="dot-flashing"
          sx={{
            textAlign: 'center',
            alignItems: 'center',
            alignContent: 'center',
            justifyContent: 'center',
            margin: '0 auto',
          }}
        >
          {''}
        </Flex>
      </Card> */}
      <AllStickyNfts realTimeData={false} />
      <Card
        sx={{
          textAlign: align,
          alignItems: align,
          alignContent: align,
          justifyContent: align,
          margin: '0 auto',
        }}
        // sx={{ textAlign: 'right' }}
      >
        <Flex
          sx={{
            textAlign: align,
            alignItems: align,
            alignContent: align,
            justifyContent: align,
            margin: '0 auto',
          }}
        >
          <Logo
            disabledLink
            sx={{
              width: '1.8rem',
              height: '1.8rem',
            }}
            className="pulse-icon"
          />
          {/* <Iconify
            icon={'%PUBLIC_URL%/favicon/sticky-admin-icon.png'}
            sx={{ fontSize: '1.0rem' }}
          /> */}
          {`Total editions on Sticky: ${
            totalBalance?.totalOwnedEditionsSticky ?? '--'
          }`}
        </Flex>
        <Flex
          sx={{
            textAlign: align,
            alignItems: align,
            alignContent: align,
            justifyContent: align,
            margin: '0 auto',
          }}
        >
          <Iconify
            icon="ph:currency-eth"
            color="#627feb"
            // icon='fa-brands:btc'
            sx={{
              fontSize: '1.5rem',
              // color: '#d38e2f', // BTC brown
              // color: '#f5931b', // BTC orange
            }}
            className="pulse-icon-wildly"
          />
          {`Total editions on the Blockchain: ${
            totalBalance?.totalOwnedEditionsBlockchain ?? '--'
          }`}
        </Flex>
        <Button
          onClick={refreshPreloadedTable}
          disabled={isDisabled}
          sx={{
            color: isDisabled
              ? matchingColorsThemed.mismatchRed[theme]
              : matchingColorsThemed.missingInfoGold[theme],
          }}
        >
          Refresh balances
        </Button>
      </Card>
    </>
  );
}
