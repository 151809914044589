import { ContentCopy } from '@mui/icons-material';
import { MenuItem } from '@mui/material';
import { GridSortDirection } from '@mui/x-data-grid';
import CopyToClipboard from 'react-copy-to-clipboard';
import MagicTable from 'src/components/shared/MagicTable/MagicTable';
import MoreMenu from 'src/components/shared/MoreMenu';
import { GameStatistic } from 'src/lib/types/gameStatistic';

interface StatisticsTableProps {
  data: GameStatistic[];
  page: number;
  size: number;
  sortBy: string;
  order: GridSortDirection;
  setPage: (page: number) => void;
  setRowsPerPage: (size: number) => void;
  setOrder: (order: GridSortDirection) => void;
  setSortBy: (sortBy: string) => void;
}

export default function StatisticsTable({
  data,
  page,
  size,
  sortBy,
  order,
  setPage,
  setRowsPerPage,
  setOrder,
  setSortBy,
}: StatisticsTableProps): JSX.Element {
  return (
    <MagicTable
      rows={data.map((nft) => nft)}
      hideNumberOfRows
      getRowId={(r) => r.uuid}
      loading={false}
      paginationMode="client"
      pageSize={size}
      page={page}
      onPageChange={(newPage) => {
        setPage(newPage);
      }}
      onPageSizeChange={(newSize) => {
        setRowsPerPage(newSize);
      }}
      sortModel={[{ field: sortBy, sort: order }]}
      onSortModelChange={(model) => {
        const currentModel = model[0];
        if (currentModel) {
          setSortBy(currentModel.field);
          if (currentModel.sort) setOrder(currentModel.sort);
        } else {
          setOrder('asc');
          setSortBy('');
        }
      }}
      columns={[
        {
          field: 'asset',
          headerName: 'Asset',
          sortable: true,
          valueFormatter({ value }) {
            return value.name;
          },
        },
        {
          field: 'action',
          headerName: 'Action',
          sortable: true,
          valueFormatter({ value }) {
            return value.name;
          },
        },
        {
          field: 'actions',
          headerName: 'Actions',
          align: 'center',
          sortable: false,
          flex: 0,
          renderCell({ row }) {
            return (
              <MoreMenu>
                <CopyToClipboard text={row.uuid}>
                  <MenuItem aria-label="copy feed uuid">
                    <ContentCopy />
                    Copy UUID
                  </MenuItem>
                </CopyToClipboard>
              </MoreMenu>
            );
          },
        },
      ]}
    />
  );
}
