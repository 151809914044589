import useNotify from 'src/lib/hooks/useNotify';
import useModalStore from 'src/lib/stores/useModalStore';
import { CreateGameFormValues } from '../../components/Forms/CreateGameForm/types';
import { GameEmitter } from '../../events/GameEmitter';
import { CreateGame } from '../../services/createGame';

export default function useCreateGameView() {
  const closeModal = useModalStore((state) => state.closeModal);
  const notify = useNotify();

  const handleCreateGame = async (values: CreateGameFormValues) => {
    const { error, data } = await CreateGame({
      name: values.game.name,
      description: values.game.description,
      gamePublisher: values.game.gamePublisher,
      gameCategoryUuid: values.game.gameCategoryUuid,
      developerName: values.game.developerName,
      url: values.version.url,
      ageRestriction: values.version.ageRestriction,
      gameModesUuids: values.version.gameModesUuids,
      imageUrl: values.version.imageUrl,
      objectLinks: values.objectLinks,
      uuid: values.game.uuid,
      version: values.version.version,
      copyrightLicense: values.game.copyrightLicense,

      // version
      hasAds: values.version.hasAds,
      rAds: values.version.rAds,
      additionalComments: values.version.additionalComments,

      // game
      secondaryLink: values.game.secondaryLink,
      publishRightsType: values.game.publishRightsType,
      hasAgreedTerms: values.game.hasAgreedTerms,
      orientation: values.game.orientation,
      isMobile: values.game.isMobile,
      isGameMakerTemplate: values.game.isGameMakerTemplate,
      gameTagsNames: values.game.gameTagsNames,

      adminsUuids: [values.game.userUuid!],
      userUuid: values.game.userUuid!,
    });

    if (error) {
      notify(error, { variant: 'error' });
    } else if (data) {
      notify('Game Created!', { variant: 'success' });
      closeModal();

      GameEmitter.emit('GameCreated', data);
    }
  };

  return {
    handleCreateGame,
  };
}
