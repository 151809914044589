import { ExpandMore, OpenInNew } from '@mui/icons-material';
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Alert,
  AlertColor,
  Card,
  CardContent,
  CardHeader,
  IconButton,
  Stack,
  Typography,
} from '@mui/material';
import Flex from 'src/components/shared/Flex';
import Link from 'src/components/shared/Link';
import Routes from 'src/routes/Routes';
import Label from 'src/template/components/Label';
import { Status } from './types';

type Props = {
  statuses: Status[];
};
export default function HealthCheckView({ statuses }: Props): JSX.Element {
  const hasStatusLoading = statuses.every((status) => status.isLoading);

  const someHasError = statuses.some((status) => Boolean(status.error));
  const notWorkingStatuses = statuses
    .map((s) => s.workers)
    .flat()
    .filter((w) => !w.isWorking);

  let alertColor: AlertColor;
  let alertText: string;

  if (hasStatusLoading) {
    alertColor = 'info';
    alertText = 'Loading Statuses...';
  } else if (notWorkingStatuses.length > 0) {
    alertColor = 'warning';
    alertText = `${notWorkingStatuses.length} workers are inactive`;
  } else if (someHasError) {
    alertColor = 'warning';
    alertText = 'One or more has errors';
  } else {
    alertColor = 'success';
    alertText = 'All Systems Operational';
  }

  return (
    <Card>
      <CardHeader
        title="API Health Check"
        action={
          <IconButton component={Link} to={Routes.system_uptime}>
            <OpenInNew />
          </IconButton>
        }
      />
      <CardContent>
        <Alert severity={alertColor} variant="outlined" sx={{ mb: 2 }}>
          {alertText}
        </Alert>
        {statuses.map((status) => (
          <Accordion key={status.name}>
            <AccordionSummary
              expandIcon={<ExpandMore />}
              aria-controls={`${status.name}-content`}
              id={`${status.name}-header`}
            >
              <Typography>{status.name}</Typography>
            </AccordionSummary>
            <AccordionDetails>
              {status.workers.length === 0 && status.error && (
                <Typography textAlign="center" color="error">
                  Error: {status.error}
                </Typography>
              )}
              {status.workers.length > 0 && (
                <Stack spacing={0.5}>
                  {status.workers.map((service) => (
                    <Flex gap={1} key={service.name}>
                      <Label
                        variant="outlined"
                        color={service.isWorking ? 'success' : 'error'}
                        sx={{
                          textTransform: 'capitalize',
                        }}
                      >
                        {service.isWorking ? 'Active' : 'Inactive'}
                      </Label>
                      <Typography>
                        {service.name}{' '}
                        <Typography
                          component="span"
                          fontSize={12}
                          color="GrayText"
                        >
                          · Last check: {service.lastChecked}
                        </Typography>
                      </Typography>
                    </Flex>
                  ))}
                </Stack>
              )}
            </AccordionDetails>
          </Accordion>
        ))}
      </CardContent>
    </Card>
  );
}
