import { Box, Container, Stack } from '@mui/material';
import Flex from 'src/components/shared/Flex';
import HeaderBreadcrumbs from 'src/template/components/HeaderBreadcrumbs';
import { BlockchainHealthCheck } from '../Home/components/HealthCheck/Blockchain';

export function BlockchainHealthCheckView(): JSX.Element {
  return (
    <Container maxWidth="xl">
      <HeaderBreadcrumbs heading="Sticky Admin" links={[{ name: 'Home' }]} />
      <Stack spacing={3}>
        <Flex gap={3} justifyContent="stretch" alignItems="flex-start">
          <Box flex={1}>
            <BlockchainHealthCheck />
          </Box>
        </Flex>
      </Stack>
    </Container>
  );
}
