import { Link as MuiLink, LinkProps as MuiLinkProps } from '@mui/material';
import { forwardRef } from 'react';
import { Link as RouterLink } from 'react-router-dom';

interface LinkProps extends MuiLinkProps {
  to: string;
  children: React.ReactNode;
}

const Link = forwardRef<HTMLAnchorElement, LinkProps>(
  ({ to, children, ...props }, ref) => (
    <MuiLink component={RouterLink} to={to} ref={ref} {...props}>
      {children}
    </MuiLink>
  )
);

export default Link;
