import { PaginatedResponse } from 'src/lib/types/PaginatedResponse';
import { BracketFilterParams } from 'src/lib/types/bracketFilterParams';
import { Fetch } from 'src/lib/types/fetch';
import {
  PaymentRequest,
  PaymentRequestStatus,
} from 'src/lib/types/paymentRequest';
import generateSearchRequest from 'src/lib/utils/generateSearchRequest';
import useSWR from 'swr';

export const fetchPaymentRequestsByUser = (
  userId: string,
  filter: BracketFilterParams,
  status: PaymentRequestStatus | 'all'
): Fetch<PaymentRequest[]> => {
  const statusFilter = status === 'all' ? '' : `&filter[status][eq]=${status}`;

  const { data, error } = useSWR<PaginatedResponse<PaymentRequest[]>, Error>(
    `admin/payment-requests/user/${userId}${generateSearchRequest(
      filter
    )}${statusFilter}`
  );

  return {
    data: data?.body,
    total: data?.meta.totalCount,
    loading: !error && !data,
    error: error?.message,
  };
};
