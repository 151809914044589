import {
  Cancel,
  Check,
  ContentCopy,
  Delete,
  Edit,
  OpenInNew,
} from '@mui/icons-material';
import {
  Autocomplete,
  Box,
  InputAdornment,
  MenuItem,
  TextField,
} from '@mui/material';
import { GridSortDirection } from '@mui/x-data-grid';
import { capitalCase } from 'change-case';
import { debounce } from 'lodash';
import CopyToClipboard from 'react-copy-to-clipboard';
import Flex from 'src/components/shared/Flex';
import Link from 'src/components/shared/Link';
import MagicTable from 'src/components/shared/MagicTable/MagicTable';
import MoreMenu from 'src/components/shared/MoreMenu';
import useURLSearchParams from 'src/lib/hooks/useURLSearchParams';
import { Contest, ContestStatus } from 'src/lib/types/contest';
import { LabelValue } from 'src/lib/types/labelValue';
import { formatUTCDate } from 'src/lib/utils/formatUTCDate';
import Routes from 'src/routes/Routes';
import Iconify from 'src/template/components/Iconify';
import Label from 'src/template/components/Label';
import { FetchContestsQueryParams } from '../../view/List/useView';

type ContestsTableProps = {
  data?: Contest[];
  isLoading: boolean;
  params: FetchContestsQueryParams;

  handleOpenUpdateModal: (contest: Contest) => void;
  handleOpenDeleteModal: (uuid: string) => void;
  handleOpenChangeStatusModal: (uuid: string, status: ContestStatus) => void;
};

export default function ContestsTable({
  data,
  isLoading,
  params: { page, size, searchBy, orderBy, sortOrder },
  handleOpenUpdateModal,
  handleOpenDeleteModal,
  handleOpenChangeStatusModal,
}: ContestsTableProps) {
  const { addParam, removeParam, allSearchParams } = useURLSearchParams();

  function handleChangeSearch(value: string, param: string = 'query') {
    if (!value) removeParam(param);
    else addParam(param, value);
  }

  const debouncedSearch = debounce(handleChangeSearch, 1000);

  return (
    <MagicTable.Container>
      <MagicTable.Tabs
        defaultTab={ContestStatus.ACTIVE}
        tabs={[
          {
            label: 'All',
            value: 'all',
          },
          ...Object.values(ContestStatus).map((contestStatus) => ({
            label: contestStatus,
            value: contestStatus,
          })),
        ]}
        tabsConfig={{ queryName: 'status' }}
        keepParamOnAll
      />
      <Flex gap={2} p={2} justifyContent={'flex-end'}>
        <Autocomplete
          options={SEARCH_OPTIONS}
          fullWidth
          renderInput={(params) => <TextField label="Search By" {...params} />}
          getOptionLabel={(p) => p.label}
          sx={{ maxWidth: 250 }}
          defaultValue={SEARCH_OPTIONS.find((o) => o.value === searchBy)}
          key={searchBy}
          onChange={(_, value) => {
            if (!value) removeParam('searchBy');
            else addParam('searchBy', value.value);
          }}
        />
        <TextField
          fullWidth
          label="Search"
          defaultValue={allSearchParams.query}
          type="search"
          onChange={(e) => {
            const { value } = e.target;
            debouncedSearch(value);
          }}
          key={allSearchParams.query}
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <Iconify
                  icon="eva:search-fill"
                  sx={{ color: 'text.disabled', width: 20, height: 20 }}
                />
              </InputAdornment>
            ),
          }}
        />
      </Flex>
      <Box px={2}>
        <MagicTable
          loading={isLoading}
          pageSize={size}
          page={page}
          paginationMode="server"
          onPageChange={(newPage) => {
            addParam('page', newPage);
          }}
          onPageSizeChange={(newSize) => {
            addParam('size', newSize);
          }}
          onSortModelChange={(model) => {
            const sortModel = model[0];
            addParam('orderBy', sortModel.field);
            addParam('order', sortModel.sort!);
          }}
          sortModel={[
            {
              field: orderBy,
              sort: sortOrder as GridSortDirection,
            },
          ]}
          rows={data ?? []}
          hideNumberOfRows
          columns={[
            {
              field: 'name',
              headerName: 'Name',
            },
            /*
            {
              field: 'details',
              headerName: 'Details',
            },
            */
            {
              field: 'status',
              headerName: 'Status',
              renderCell({ value }) {
                return (
                  <Label
                    color={
                      value === ContestStatus.ACTIVE
                        ? 'success'
                        : value === ContestStatus.CANCELED
                        ? 'error'
                        : 'warning'
                    }
                    flex={1}
                    sx={{
                      width: '100%',
                    }}
                  >
                    {capitalCase(value)}
                  </Label>
                );
              },
            },
            {
              field: 'entryPrice',
              headerName: 'Entry Price',
              valueFormatter({ value }) {
                return value ? value / 1000 : 'Free';
              },
            },
            {
              field: 'playerEntryLimit',
              headerName: 'Entry Limit',
              valueFormatter({ value }) {
                return value ?? 'Unlimited';
              },
            },
            {
              field: 'totalEntries',
              headerName: 'Players Limit',
              valueFormatter({ value }) {
                return value ?? 'Unlimited';
              },
            },
            {
              field: 'awardDistributionMode',
              headerName: 'Award Mode',
            },
            {
              field: 'awardValue',
              headerName: 'Award Value',
              valueFormatter({ value }) {
                return value / 1000;
              },
            },
            {
              field: 'frequency',
              headerName: 'Frequency',
            },
            {
              field: 'startDate',
              headerName: 'Start Date',
              sortingOrder: ['desc', 'asc'],

              valueFormatter({ value }) {
                return value ? formatUTCDate(value) : '-';
              },
            },
            {
              field: 'endDate',
              headerName: 'End Date',
              sortingOrder: ['desc', 'asc'],
              valueFormatter({ value }) {
                return value ? formatUTCDate(value) : 'Unlimited';
              },
            },
            /*
            {
              field: 'primaryColor',
              headerName: 'Primary Color',
              align: 'center',
              renderCell({ value }) {
                return <ColorDisplay color={value} />;
              },
            },
            {
              field: 'secondaryColor',
              headerName: 'Secondary Color',
              align: 'center',
              renderCell({ value }) {
                return <ColorDisplay color={value} />;
              },
            }
            {
              field: 'createdAt',
              headerName: 'Created At',
              sortingOrder: ['desc', 'asc'],

              valueFormatter({ value }) {
                return value ? formatUTCDate(value) : '-';
              },
            }, */ {
              field: 'actions',
              headerName: 'Actions',
              align: 'center',
              sortable: false,
              flex: 0,
              renderCell({ row }) {
                return (
                  <MoreMenu>
                    <CopyToClipboard text={row.uuid}>
                      <MenuItem aria-label="copy feed uuid">
                        <ContentCopy />
                        Copy UUID
                      </MenuItem>
                    </CopyToClipboard>
                    <MenuItem
                      onClick={() => {
                        handleOpenUpdateModal(row);
                      }}
                    >
                      <Edit />
                      Update
                    </MenuItem>
                    <MenuItem
                      onClick={() => {
                        handleOpenDeleteModal(row.uuid);
                      }}
                    >
                      <Delete />
                      Delete
                    </MenuItem>
                    {row.status !== ContestStatus.CANCELED && (
                      <MenuItem
                        onClick={() => {
                          handleOpenChangeStatusModal(
                            row.uuid,
                            row.status === ContestStatus.DRAFT
                              ? ContestStatus.ACTIVE
                              : ContestStatus.CANCELED
                          );
                        }}
                      >
                        {row.status === ContestStatus.DRAFT ? (
                          <Check />
                        ) : (
                          <Cancel />
                        )}
                        Mark as{' '}
                        {row.status === ContestStatus.DRAFT
                          ? 'Active'
                          : 'Canceled'}
                      </MenuItem>
                    )}

                    <Link to={Routes.game_center.contest.events(row.uuid)}>
                      <MenuItem aria-label="view events">
                        <OpenInNew />
                        View Events
                      </MenuItem>
                    </Link>

                    <Link to={Routes.game_center.contest.assets(row.uuid)}>
                      <MenuItem aria-label="view assets">
                        <OpenInNew />
                        View Assets
                      </MenuItem>
                    </Link>
                  </MoreMenu>
                );
              },
            },
          ]}
        />
      </Box>
    </MagicTable.Container>
  );
}

const SEARCH_OPTIONS: LabelValue[] = [
  {
    label: 'Name',
    value: 'name',
  },
];
