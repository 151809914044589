import { debounce } from 'lodash';
import { CommonDateStrings } from 'src/lib/constants/CommonDates';
import useURLSearchParams from 'src/lib/hooks/useURLSearchParams';
import { fetchSubscriptionsFromRevenueEventsV2 } from '../../../services/fetchSubscriptionsRevenueEventsV2';

const initialParams = {
  endDate: CommonDateStrings.sevenDaysFromNow.date,
  page: '0',
  size: '25',
};

export function useSubscriptionsFromRevenueEventsV2StateView() {
  const { allSearchParams, removeParam, addParam } =
    useURLSearchParams(initialParams);

  const { data, isLoading, error } = fetchSubscriptionsFromRevenueEventsV2({
    filter: {
      productId: allSearchParams.productId,
      ...(allSearchParams.endDate && {
        endDate: `${allSearchParams.endDate}T23:59:59.999Z`,
      }),
      userUuid: allSearchParams.userUuid,
      country: allSearchParams.country,
      productUuid: allSearchParams.productUuid,
      isFreeTrial: allSearchParams.isFreeTrial,
    },
    size: allSearchParams.size,
    page: allSearchParams.page,
  });

  function onChangeFilter(field: string, value: string | null | undefined) {
    if (!value) removeParam(field);
    else addParam(field, value);
  }

  const debouncedOnChangeFilter = debounce(onChangeFilter, 600);
  return {
    data,
    isLoading,
    error,
    onChangeFilter: debouncedOnChangeFilter,
    filter: {
      productUuid: allSearchParams.productUuid,
      productId: allSearchParams.productId,
      country: allSearchParams.country,
      endDate: allSearchParams.endDate,
      userUuid: allSearchParams.userUuid,
    },
    page: allSearchParams.page,
    size: allSearchParams.size,
  };
}
