import { TextField } from '@mui/material';
import { FilterProps } from './types';

export function GameRatingsTableDateFilters({
  // addParam,
  removeParam,
  debouncedChangeField,
  propParams,
}: FilterProps) {
  return (
    <>
      <TextField
        type="date"
        label="Start Date"
        InputLabelProps={{ shrink: true }}
        defaultValue={propParams.startDate}
        onChange={(e) => {
          const { value } = e.target;
          if (value) {
            debouncedChangeField(value, 'startDate');
          } else removeParam('startDate');
        }}
      />
      <TextField
        type="date"
        label="End Date"
        InputLabelProps={{ shrink: true }}
        defaultValue={propParams.endDate}
        onChange={(e) => {
          const { value } = e.target;
          if (value) {
            debouncedChangeField(value, 'endDate');
          } else removeParam('endDate');
        }}
      />
    </>
  );
}
