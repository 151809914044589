import { Box, Card, CardActionArea, Link, Typography } from '@mui/material';
import { BlockchainNetwork } from 'src/lib/constants/blockchain';
import { Collection } from 'src/lib/types/collection';
import getBlockExplorerUrl from 'src/lib/utils/getBlockExplorerUrl';
import Iconify from 'src/template/components/Iconify';

const iconByBlockchain: Record<BlockchainNetwork, string> = {
  [BlockchainNetwork.ETHEREUM]: 'mdi:ethereum',
  [BlockchainNetwork.POLYGON]: 'devicon-plain:polygon',
  [BlockchainNetwork.STICKY]: '',
};
const iconSizeByBlockchain: Record<BlockchainNetwork, number> = {
  [BlockchainNetwork.ETHEREUM]: 25,
  [BlockchainNetwork.POLYGON]: 20,
  [BlockchainNetwork.STICKY]: 20,
};

type Props = {
  collection: Collection;
};
export default function CollectionAddressCard({
  collection,
}: Props): JSX.Element | null {
  const { mintToken, externalWallet, blockchain } = collection;

  if (!mintToken && !externalWallet) return null;
  return (
    <Card>
      {mintToken && (
        <ActionComponent
          url={getBlockExplorerUrl(mintToken, blockchain)}
          label="Token Scan"
          iconProps={{
            icon: iconByBlockchain[blockchain],
            size: iconSizeByBlockchain[blockchain],
          }}
        />
      )}
      {externalWallet && (
        <ActionComponent
          url={getBlockExplorerUrl(externalWallet, blockchain)}
          label="Wallet Scan"
          iconProps={{
            icon: 'ion:wallet',
            size: 20,
          }}
        />
      )}
    </Card>
  );
}

type ActionComponentProps = {
  url: string;
  label: string;
  iconProps: {
    icon: string;
    size: number;
  };
};
function ActionComponent({
  url,
  label,
  iconProps,
}: ActionComponentProps): JSX.Element {
  return (
    <CardActionArea
      sx={{
        padding: 2,
        alignItems: 'center',
        justifyContent: 'center',
      }}
    >
      <Link href={url} target="_blank" underline="none">
        <Box pr={1} display="flex" alignItems="center" gap={1}>
          <Box
            display="flex"
            alignItems="center"
            justifyContent="center"
            width={25}
            height={25}
          >
            <Iconify
              width={iconProps.size}
              height={iconProps.size}
              icon={iconProps.icon}
              lineHeight={0}
            />
          </Box>
          <Typography
            variant="body1"
            textTransform="capitalize"
            fontWeight="bold"
            flex={1}
          >
            {label}
          </Typography>
          <Iconify
            width={18}
            height={18}
            icon="akar-icons:link-out"
            lineHeight={0}
          />
        </Box>
      </Link>
    </CardActionArea>
  );
}
