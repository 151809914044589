import { useCallback, useEffect } from 'react';
import { useParams } from 'react-router';
import { DEFAULT_INITIAL_PAGE_SIZE } from 'src/lib/constants/pagination';
import useURLSearchParams from 'src/lib/hooks/useURLSearchParams';
import useModalStore from 'src/lib/stores/useModalStore';
import { Contest, ContestStatus } from 'src/lib/types/contest';
import { ContestEmitter } from '../../events/ContestEmitter';
import { fetchContests } from '../../services/fetchContests';
import ChangeContestStatusView from '../ChangeStatus';
import CreateContestView from '../Create';
import DeleteContestView from '../Delete';
import UpdateContestView from '../Update';

export default function useContests() {
  const { allSearchParams } = useURLSearchParams(initialParams);
  const { gameUuid } = useParams<{ gameUuid: string }>();
  const openModal = useModalStore((state) => state.openModal);

  const handleOpenCreateModal = () => {
    openModal(<CreateContestView gameUuid={gameUuid!} />);
  };
  const handleOpenUpdateModal = (contest: Contest) => {
    openModal(<UpdateContestView contest={contest} />);
  };
  const handleOpenDeleteModal = (contestUuid: string) => {
    openModal(<DeleteContestView uuid={contestUuid} />);
  };
  const handleOpenChangeStatusModal = (
    contestUuid: string,
    status: ContestStatus
  ) => {
    openModal(<ChangeContestStatusView uuid={contestUuid} status={status} />);
  };

  const params = {
    page: +(allSearchParams.page ?? initialParams.page),
    size: +(allSearchParams.size ?? initialParams.size),
    orderBy: allSearchParams.orderBy ?? initialParams.orderBy,
    sortOrder: allSearchParams.sortOrder ?? initialParams.order,
    searchBy: allSearchParams.searchBy ?? initialParams.searchBy,
    query: allSearchParams.query ?? initialParams.query,

    status: (allSearchParams.status ?? initialParams.status) as
      | ContestStatus
      | 'all',
  };

  const { data, isLoading, mutate } = fetchContests({
    page: params.page,
    size: params.size,

    ...(params.orderBy &&
      params.sortOrder && {
        order: {
          [params.orderBy]: params.sortOrder,
        },
      }),

    filter: {
      ...(params.searchBy &&
        params.query && {
          [params.searchBy]: {
            like: `%${params.query}%`,
          },
        }),
      ...(gameUuid && {
        gameUuid: {
          eq: gameUuid,
        },
      }),
      ...(params.status &&
        params.status != 'all' && {
          status: {
            eq: params.status,
          },
        }),
    },
  });

  const refetchData = useCallback(() => {
    mutate?.();
  }, []);

  useEffect(() => {
    ContestEmitter.on('ContestCreated', refetchData);
    ContestEmitter.on('ContestUpdated', refetchData);
    ContestEmitter.on('ContestDeleted', refetchData);

    return () => {
      ContestEmitter.off('ContestCreated', refetchData);
      ContestEmitter.off('ContestUpdated', refetchData);
      ContestEmitter.off('ContestDeleted', refetchData);
    };
  }, [refetchData]);

  return {
    data,
    isLoading,
    params,
    gameUuid,
    handleOpenCreateModal,
    handleOpenUpdateModal,
    handleOpenDeleteModal,
    handleOpenChangeStatusModal,
  };
}

const initialParams = {
  page: '0',
  size: DEFAULT_INITIAL_PAGE_SIZE.toString(),
  order: 'desc',
  orderBy: 'name',
  searchBy: 'name',
  query: '',
  status: ContestStatus.ACTIVE,
};

export type FetchContestsQueryParams = {
  page: number;
  size: number;
  orderBy: string;
  searchBy: string;
  sortOrder: string;
  status: ContestStatus | 'all';
};
