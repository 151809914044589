import { Stack, TableCell, TableCellProps } from '@mui/material';
import { ReactNode } from 'react';
import Label from 'src/template/components/Label';
import { EldenTableAlignOptions } from '../EldentTableAlignOptions';

export type EldenTableCellProps = {
  id: string;
  label: string;
  align?: EldenTableAlignOptions;
  sortable?: boolean;
  children: ReactNode | string | null;
  stopPropagation?: boolean;
  hide?: boolean;
} & TableCellProps;

export default function EldenTableCell({
  sortable = false, //eslint-disable-line
  align,
  children,
  stopPropagation = false,
  hide = false,
  ...cellProps
}: EldenTableCellProps) {
  const onStopPropagation = (
    e: React.MouseEvent<HTMLTableCellElement, MouseEvent>
  ) => (stopPropagation ? e.stopPropagation() : undefined);

  return !hide ? (
    <TableCell
      align={align}
      {...cellProps}
      onClick={onStopPropagation}
      onAuxClick={onStopPropagation}
    >
      {children ?? (
        <Stack direction="column" gap={1}>
          <Label variant="ghost" color="default" flex={1}>
            null
          </Label>
        </Stack>
      )}
    </TableCell>
  ) : null;
}
