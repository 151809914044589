import { Edit } from '@mui/icons-material';
import CheckIcon from '@mui/icons-material/Check';
import { IconButton, InputAdornment, TextField, Tooltip } from '@mui/material';
import { GridColumns, GridSortDirection } from '@mui/x-data-grid';
import debounce from 'lodash/debounce';
import Button from 'src/components/shared/Buttons/CustomButton';
import Flex from 'src/components/shared/Flex';
import MagicTable from 'src/components/shared/MagicTable/MagicTable';
import SelectOption from 'src/components/shared/SelectOption';
import useURLSearchParams from 'src/lib/hooks/useURLSearchParams';
import { PaginatedResponse } from 'src/lib/types/PaginatedResponse';
import Iconify from 'src/template/components/Iconify';
import { ParsedConfiguration } from '../../types/Configuration';
import useConfigurationsTableView from './useView';

export type Props = {
  data: PaginatedResponse<ParsedConfiguration[]> | undefined;
  isLoading: boolean;
  page: number;
  size: number;
  sortBy: string;
  sortOrder: string;
};
export default function ConfigurationsTableView({
  data,
  isLoading,
  sortBy,
  page,
  size,
  sortOrder,
}: Props): JSX.Element {
  const { handleUpdateConfig, handleToggleConfigStatus } =
    useConfigurationsTableView();
  const { removeParam, addParam, removeParams, allSearchParams } =
    useURLSearchParams();
  const { searchFor } = allSearchParams;

  function handleFilter(query: string) {
    if (!searchFor) return;
    if (!query) removeParam(searchFor);
    else {
      addParam(searchFor, `%${query}%`);
      addParam('query', query);
    }
  }

  const debouncedFilter = debounce(handleFilter, 400);

  const columns: GridColumns<ParsedConfiguration> = [
    {
      field: 'name',
      headerName: 'Name',
      sortable: true,
      sortComparator: () => 0,
      sortingOrder: ['asc', 'desc'],
    },
    {
      field: 'value',
      headerName: 'Value',
      sortable: false,
    },
    {
      field: 'platform',
      headerName: 'Platform',
      sortable: true,
      sortComparator: () => 0,
      sortingOrder: ['asc', 'desc'],
    },
    {
      field: 'active',
      headerName: 'Status',
      sortable: false,
      align: 'center',
      flex: 0,
      renderCell({ row }) {
        return (
          <Button
            onClick={async () => handleToggleConfigStatus(row.uuid, row.active)}
          >
            <Tooltip title={row.active ? 'Active' : 'Inactive'} arrow>
              {row.active ? (
                <CheckIcon color="success" />
              ) : (
                <CheckIcon color="disabled" />
              )}
            </Tooltip>
          </Button>
        );
      },
    },
    {
      field: 'description',
      headerName: 'Description',
      sortable: false,
    },
    {
      field: 'createdAt',
      headerName: 'Created',
      sortable: true,
      sortComparator: () => 0,
      sortingOrder: ['asc', 'desc'],
    },
    {
      field: 'actions',
      headerName: 'Actions',
      align: 'center',
      sortable: false,
      flex: 0,
      renderCell({ row }) {
        return (
          <IconButton
            aria-label="Update config"
            onClick={() => handleUpdateConfig(row)}
          >
            <Edit color="info" />
          </IconButton>
        );
      },
    },
  ];

  return (
    <MagicTable.Container>
      <MagicTable.Tabs
        tabs={statuses}
        defaultTab="all"
        tabsConfig={{ queryName: 'active' }}
      />
      <Flex gap={2} p={2}>
        <TextField
          fullWidth
          select
          label="Search For"
          defaultValue={allSearchParams.searchFor}
          onChange={(e) => {
            addParam('searchFor', e.target.value);
          }}
          sx={{
            maxWidth: 250,
          }}
        >
          <SelectOption value="name">Name</SelectOption>
          <SelectOption value="platform">Platform</SelectOption>
          <SelectOption value="description">Description</SelectOption>
        </TextField>
        <TextField
          fullWidth
          placeholder="Search"
          defaultValue={allSearchParams.query}
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <Iconify
                  icon="eva:search-fill"
                  sx={{ color: 'text.disabled', width: 20, height: 20 }}
                />
              </InputAdornment>
            ),
          }}
          onChange={(e) => {
            const { value } = e.target;
            debouncedFilter(value);
          }}
        />
      </Flex>
      <MagicTable.Wrapper>
        <MagicTable
          loading={isLoading}
          pageSize={size}
          page={page}
          columns={columns}
          rows={data?.body ?? []}
          getRowId={(r) => r.uuid}
          rowCount={data?.meta.totalCount ?? 0}
          paginationMode="server"
          onPageChange={(newPage) => {
            addParam('page', newPage);
          }}
          onPageSizeChange={(newSize) => {
            addParam('size', newSize);
          }}
          onSortModelChange={(model) => {
            const { field, sort } = model[0];
            if (field && sort) {
              addParam('sortOrder', sort);
              addParam('sortBy', field);
            } else {
              removeParams(['sortBy', 'sortOrder']);
            }
          }}
          sortModel={[{ field: sortBy, sort: sortOrder as GridSortDirection }]}
        />
      </MagicTable.Wrapper>
    </MagicTable.Container>
  );
}

const statuses = [
  {
    value: 'all',
    label: 'All',
  },
  {
    value: 'true',
    label: 'Active',
  },
  {
    value: 'false',
    label: 'Inactive',
  },
];
