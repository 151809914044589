import { Container } from '@mui/material';
import HeaderBreadcrumbs from 'src/template/components/HeaderBreadcrumbs';
import PlayerStatsTable from '../../components/PlayerStatsTable/PlayerStatsTable';
import usePlayerStats from './useView';

export default function PlayerStats() {
  const { data, isLoading, params, generalStatsData } = usePlayerStats();
  return (
    <Container maxWidth="xl">
      <HeaderBreadcrumbs
        heading="Player Analytics"
        links={[{ name: 'Player Analytics' }]}
      />
      <PlayerStatsTable
        data={data?.rows || []}
        totalCount={data?.count || 0}
        isLoading={isLoading}
        params={params}
        generalStatsData={generalStatsData}
      />
    </Container>
  );
}
