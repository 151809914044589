import {
  Alert,
  Box,
  Card,
  CardContent,
  CircularProgress,
  Container,
  TextField,
} from '@mui/material';
import Flex from 'src/components/shared/Flex';
import { CommonDateStrings } from 'src/lib/constants/CommonDates';
import HeaderBreadcrumbs from 'src/template/components/HeaderBreadcrumbs';
import MakerStatsCardInfos from '../../components/MakerStatsCardInfos/makerStatsCardInfos';
import useMakerStatistics from './useView';

interface MakerStatisticsProps {
  userAuthId?: string;
  showHeader?: boolean;
}

export default function MakerStatistics({
  userAuthId,
  showHeader = true,
}: MakerStatisticsProps) {
  const {
    statsData,
    isStatsLoading,
    params,
    removeParam,
    debouncedChangeField,
  } = useMakerStatistics(userAuthId);
  return (
    <Container maxWidth="xl">
      {showHeader && (
        <HeaderBreadcrumbs
          heading="SGM Statistics"
          links={[{ name: 'SGM Statistics' }]}
        />
      )}

      <Card>
        <Box mb={2}>
          <Alert severity="info">
            Data is reliably available from{' '}
            {new Date(
              CommonDateStrings.sgmInfosAdded.dateTime
            ).toLocaleDateString('en-US', {
              year: 'numeric',
              month: 'long',
              day: 'numeric',
            })}{' '}
            onward.
          </Alert>
        </Box>
        <Flex gap={1} justifyContent={'flex-end'} p={2}>
          <TextField
            type="date"
            label="Start Date"
            InputLabelProps={{ shrink: true }}
            defaultValue={params.startDate}
            onChange={(e) => {
              const { value } = e.target;
              if (value) {
                debouncedChangeField(value, 'startDate');
              } else removeParam('startDate');
            }}
          />
          <TextField
            type="date"
            label="End Date"
            InputLabelProps={{ shrink: true }}
            defaultValue={params.endDate}
            onChange={(e) => {
              const { value } = e.target;
              if (value) {
                debouncedChangeField(value, 'endDate');
              } else removeParam('endDate');
            }}
          />
        </Flex>
        <CardContent>
          {isStatsLoading || !statsData ? (
            <Flex justifyContent="center" alignItems="center">
              <CircularProgress />
            </Flex>
          ) : (
            <MakerStatsCardInfos statsData={statsData} />
          )}
        </CardContent>
      </Card>
    </Container>
  );
}
