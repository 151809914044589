import { ContentCopy } from '@mui/icons-material';
import { MenuItem } from '@mui/material';
import { GridSortDirection } from '@mui/x-data-grid';
import CopyToClipboard from 'react-copy-to-clipboard';
import MagicTable from 'src/components/shared/MagicTable/MagicTable';
import MagicTableUserCell from 'src/components/shared/MagicTable/UserCell';
import MoreMenu from 'src/components/shared/MoreMenu';
import useURLSearchParams from 'src/lib/hooks/useURLSearchParams';
import { SGMChatTopUsersStatistics } from 'src/lib/types/sgmChatMessage';
import { formatDollar } from 'src/lib/utils/formatDollar';
import { formatPercentage } from 'src/lib/utils/formatPercentage';
import { FetchMakerMostActiveUsersParams } from '../../view/MostActiveUsers/useView';

type MakerMostActiveUsersTableProps = {
  data?: SGMChatTopUsersStatistics[];
  totalCount: number;
  isLoading: boolean;
  params: FetchMakerMostActiveUsersParams;
};

export default function MakerMostActiveUsersTable({
  data,
  isLoading,
  params: { page, size, orderBy, sortOrder },
  totalCount,
}: MakerMostActiveUsersTableProps) {
  const { addParam, removeParam } = useURLSearchParams();

  return (
    <MagicTable.Container>
      <MagicTable
        getRowId={(row) => row.userUuid}
        rowHeight={64}
        loading={isLoading}
        pageSize={size}
        page={page}
        paginationMode="server"
        onPageChange={(newPage) => {
          addParam('page', newPage);
        }}
        onPageSizeChange={(newSize) => {
          addParam('size', newSize);
        }}
        onSortModelChange={(model) => {
          const currentModel = model[0];
          if (currentModel) {
            addParam('orderBy', currentModel.field);
            if (currentModel.sort) addParam('order', currentModel.sort);
          } else {
            removeParam('order');
            removeParam('orderBy');
          }
        }}
        sortModel={[
          {
            field: orderBy,
            sort: sortOrder as GridSortDirection,
          },
        ]}
        rows={data ?? []}
        rowCount={totalCount}
        columns={[
          {
            field: 'user',
            headerName: 'User',
            sortable: false,
            renderCell({ row }) {
              return <MagicTableUserCell user={row.user} />;
            },
          },
          {
            field: 'totalChats',
            headerName: 'Total Chats',
            sortable: true,
          },
          {
            field: 'totalMessages',
            headerName: 'Total Messages',
            sortable: true,
          },
          {
            field: 'statistics.avgMessagePerChat',
            headerName: 'Messages per Chat',
            sortable: false,
            renderCell({ row }) {
              return row.statistics.avgMessagePerChat ?? '-';
            },
          },
          {
            field: 'statistics.totalPublished',
            headerName: 'Total Published',
            sortable: false,
            renderCell({ row }) {
              return row.statistics.totalPublished ?? '-';
            },
          },
          {
            field: 'statistics.publishedPercentage',
            headerName: 'Published %',
            sortable: false,
            renderCell({ row }) {
              return formatPercentage(row.statistics.publishedPercentage);
            },
          },
          {
            field: 'statistics.totalCost',
            headerName: 'Total Cost',
            sortable: false,
            renderCell({ row }) {
              return formatDollar(row.statistics.totalCost);
            },
          },
          {
            field: 'actions',
            headerName: 'Actions',
            align: 'center',
            sortable: false,
            flex: 0,
            renderCell({ row }) {
              return (
                <MoreMenu>
                  <CopyToClipboard text={row.userUuid}>
                    <MenuItem aria-label="copy user uuid">
                      <ContentCopy />
                      Copy UUID
                    </MenuItem>
                  </CopyToClipboard>
                </MoreMenu>
              );
            },
          },
        ]}
      />
    </MagicTable.Container>
  );
}
