import React, { ReactElement } from 'react';
import ObjectModerationTab from 'src/components/modules/Review/components/ModerationObjectsTab';
import { Club } from 'src/lib/types/club';
import { OBJECT_MODERATION_OBJECT_TYPE } from 'src/lib/types/objectModeration';
import Iconify, { IconifyProps } from 'src/template/components/Iconify';
import { v4 } from 'uuid';

const MainInfo = React.lazy(
  () => import('src/components/modules/Clubs/view/MainInfo')
);
const Members = React.lazy(
  () => import('src/components/modules/Clubs/view/ListMembers')
);
const Bans = React.lazy(
  () => import('src/components/modules/Clubs/view/ListBansHistory')
);

export enum ClubTabs {
  MAIN = 'main_info',
  MEMBERS = 'members',
  BANS = 'bans_history',
  MODERATION = 'moderation',
}

interface IClubTabs {
  value: ClubTabs;
  icon: ReactElement<IconifyProps>;
  component: (club: Club) => JSX.Element;
}

const CLUB_TABS: IClubTabs[] = [
  {
    value: ClubTabs.MAIN,
    icon: (
      <Iconify icon="material-symbols:info-outline" width={20} height={20} />
    ),
    component: () => <MainInfo />,
  },
  {
    value: ClubTabs.MEMBERS,
    icon: (
      <Iconify icon="material-symbols:person-outline" width={20} height={20} />
    ),
    component: () => <Members key={v4()} />,
  },
  {
    value: ClubTabs.BANS,
    icon: (
      <Iconify
        icon="material-symbols:person-off-outline"
        width={20}
        height={20}
      />
    ),
    component: () => <Bans key={v4()} />,
  },
  {
    value: ClubTabs.MODERATION,
    icon: <Iconify icon="mdi:shield-account" width={20} height={20} />,
    component: (club) => (
      <ObjectModerationTab
        objectType={OBJECT_MODERATION_OBJECT_TYPE.CLUB}
        objectUuid={club.uuid!}
      />
    ),
  },
];

export default CLUB_TABS;
