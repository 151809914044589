import { Box } from '@mui/material';
import TransfersRankTableView from '../../../components/Rankings/TransfersRankTable';
import useTopTransfersView from './useView';

export default function TopTransfers(): JSX.Element {
  const {
    data,
    isLoading,
    handleOpenFilter,
    currentPage,
    currentSize,
    currentRankBy,
  } = useTopTransfersView();
  return (
    <Box mx={2}>
      <TransfersRankTableView
        data={data?.body ?? []}
        isLoading={isLoading}
        handleOpenFilter={handleOpenFilter}
        stats={data?.meta}
        page={currentPage}
        size={currentSize}
        rankBy={currentRankBy}
      />
    </Box>
  );
}
