import { Container } from '@mui/material';
import Pools from 'src/components/modules/Pools/view/List/Pools';
import Page from 'src/template/components/Page';

export default function PoolsPage() {
  return (
    <Page title="Transaction Pools">
      <Container maxWidth="xl">
        <Pools />
      </Container>
    </Page>
  );
}
