import { useNavigate, useParams } from 'react-router';
import Routes from 'src/routes/Routes';
import useTabs from 'src/template/hooks/useTabs';
import { fetchChannelContentDetail } from '../../services/fetchChannelContentDetail';
import { ChannelContentTab } from './channelContentTabs';

const DEFAULT_TAB = ChannelContentTab.MAIN;

export default function useChannelContentDetailsView() {
  const { contentUuid } = useParams<'contentUuid'>();
  const navigate = useNavigate();
  if (!contentUuid) navigate(Routes.channel.contents);

  const { currentTab, onChangeTab } = useTabs(DEFAULT_TAB, {
    resetOnChange: true,
  });

  const { data: channelContentDetails } = fetchChannelContentDetail(
    contentUuid!
  );

  return {
    channelContentDetails,
    currentTab,
    onChangeTab,
  };
}
