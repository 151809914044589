import { DeepPartial } from 'react-hook-form';
import { GameSession } from 'src/lib/types/gameSession';
import { parseSearchParams } from 'src/lib/utils/parseSearchParams';
import useSWR, { SWRResponse } from 'swr';

export type PossibleParams = {
  page: number;
  size: number;
  order: Record<string, string>;
  filter: {
    createdAt: {
      gte: string;
      lte: string;
    };
  };
};

export const fetchGamesSessions = (
  filter?: DeepPartial<PossibleParams>
): SWRResponse<{ rows: GameSession[]; count: number }> => {
  const parsedParams = parseSearchParams(filter ?? {});
  const response = useSWR<{ rows: GameSession[]; count: number }, Error>(
    `admin/game-session/?${parsedParams}`
  );

  return response;
};
