import getAPIClient from 'src/lib/services/api/axios.config';
import { getMessageFromError } from 'src/lib/utils/error';

export async function ExpireCoins(uuid: string) {
  try {
    const api = await getAPIClient();
    const res = await api.post(`admin/coins/${uuid}/expire`);
    return {
      data: res.data,
      error: null,
    };
  } catch (e) {
    return {
      data: null,
      error: getMessageFromError(e),
    };
  }
}
