import { ChannelType } from 'src/lib/types/channel';
import CreateChannelForm from '../../components/Forms/Create';
import useCreateChannelView from './useView';

export default function CreateChannelView(): JSX.Element {
  const { handleCreateChannel } = useCreateChannelView();
  return (
    <CreateChannelForm
      defaultValues={{
        userUuid: '',
        name: '',
        description: '',
        backgroundImageUrl: '',
        type: ChannelType.PERSONAL,

        objectLinks: [],
        objectLinkSearch: '',
      }}
      onSubmit={handleCreateChannel}
      title="Create Channel"
    />
  );
}
