import getAPIClient from 'src/lib/services/api/axios.config';
import { ServiceResponse } from 'src/lib/types/ServiceResponse';
import { EntityNote } from 'src/lib/types/entityNote';
import { getMessageFromError } from 'src/lib/utils/error';

export type UpdateEntityNoteData = Partial<{
  note: string;
  tag: string;
}>;

export async function updateEntityNote(
  uuid: string,
  data: UpdateEntityNoteData
): Promise<ServiceResponse<EntityNote>> {
  try {
    const api = await getAPIClient();
    const res = await api.put(`admin/entity-notes/${uuid}`, data);
    return {
      data: res.data,
      error: null,
    };
  } catch (e) {
    return {
      data: null,
      error: getMessageFromError(e),
    };
  }
}
