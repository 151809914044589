import { FeedFilter, HomeFeed } from 'src/lib/types/homeFeed';
import {
  FeedDetails,
  FilterTypes,
  KeyValueFeedFilter,
  UiFeedFilter,
} from './types';

export const getFiltersObjFromUiFiltersList = (
  filtersList: UiFeedFilter[] = []
): FeedFilter =>
  filtersList.reduce((prev, filter) => {
    let key = `${filter.filterType}[${filter.attribute}]`;
    if (filter.filterType === FilterTypes.FILTER) key += `[${filter.operator}]`;
    try {
      const objValue = JSON.parse(filter.value ?? '');
      return { ...prev, ...{ [key]: objValue } };
    } catch (err) {
      return { ...prev, ...{ [key]: filter.value } };
    }
  }, {});

export const getFiltersObjFromKeyValueFiltersList = (
  filtersList: KeyValueFeedFilter[] = []
): FeedFilter =>
  filtersList.reduce((prev, filter) => {
    const { key, value } = filter || {};
    if (!key) return prev;
    try {
      const objValue = JSON.parse(value ?? '');
      return { ...prev, ...{ [key]: objValue } };
    } catch (err) {
      return { ...prev, ...{ [key]: value } };
    }
  }, {});

export const getUiFiltersListFromFiltersObj = (
  filtersObj: FeedFilter = {}
): UiFeedFilter[] =>
  Object.entries(filtersObj)
    .map(([key, value]) => {
      const data = key.split(/\[|\]/).filter((str) => !!str);
      const filterType = data[0] as FilterTypes;
      if (
        filterType === FilterTypes.FILTER ||
        filterType === FilterTypes.ORDER
      ) {
        const attributes = data.length > 2 ? data.slice(1, -1) : [data[1]];
        const operator = data.length > 2 ? data.slice(-1)[0] : undefined;

        return {
          filterType,
          attribute: attributes.join(']['),
          operator,
          value:
            value && typeof value === 'object' ? JSON.stringify(value) : value,
        };
      }
      return {};
    })
    .filter((filter) => !!Object.keys(filter).length);

export const getKeyValueFiltersListFromFiltersObj = (
  filtersObj: FeedFilter
): KeyValueFeedFilter[] =>
  Object.entries(filtersObj).map(([key, value]) => ({
    key,
    value: value && typeof value === 'object' ? JSON.stringify(value) : value,
  }));

export const getKeyValueFiltersListFromUiFiltersList: (
  list: UiFeedFilter[]
) => KeyValueFeedFilter[] = (
  filtersList: UiFeedFilter[] = []
): KeyValueFeedFilter[] =>
  filtersList.map((filter) => {
    if (!filter.filterType) return {};
    let key = `${filter.filterType}`;
    if (filter.attribute) key += `[${filter.attribute}]`;
    if (filter.filterType === FilterTypes.FILTER && filter.operator)
      key += `[${filter.operator}]`;
    return (
      getKeyValueFiltersListFromFiltersObj({ [key]: filter.value })[0] || {}
    );
  });

export const getUiFiltersListFromKeyValueFiltersList = (
  filtersList: KeyValueFeedFilter[] = []
): UiFeedFilter[] =>
  filtersList.map((filter) => {
    const { key, value } = filter || {};
    if (!key) return {};
    return getUiFiltersListFromFiltersObj({ [key]: value })[0] || {};
  });

export const getFeedDetailsFromFeed = (feed: HomeFeed): FeedDetails => {
  const {
    type,
    cardType,
    name,
    status,
    description,
    hasSeeAll,
    feedOrder,
    slug,
  } = feed;
  const { limit, offset } = feed?.filters || {};
  return {
    type,
    cardType,
    name,
    slug,
    status,
    description,
    hasSeeAll,
    feedOrder,
    limit,
    offset,
  };
};
