import { Autocomplete, Avatar, Checkbox, TextField } from '@mui/material';
import { debounce } from 'lodash';
import { Controller, UseFormReturn } from 'react-hook-form';

import CheckBoxIcon from '@mui/icons-material/CheckBox';
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import { capitalCase } from 'change-case';
import { useState } from 'react';
import { fetchChannels } from 'src/components/modules/Channels/services/fetchChannel';
import { fetchClubs } from 'src/components/modules/Clubs/services/fetchClubs';
import { fetchGames } from 'src/components/modules/Games/services/fetchGames';
import { fetchCollections } from 'src/lib/services/collections';
import {
  ObjectLink,
  ObjectLinkTypes,
  linksToCreate,
} from 'src/lib/types/objectLink';

const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;

export type ObjectLinkOption = linksToCreate & {
  label: string;
  imageUrl: string | null;
};

export const useObjectLinkSearch = (params: {
  form: UseFormReturn<any>;
  objectLinksToCreateField?: string;
  objectLinksTempField?: string;
  modelsToSearch?: ObjectLinkTypes[];
  defaultValue?: ObjectLinkOption[];
}) => {
  const {
    form,
    objectLinksToCreateField = 'objectLinks',
    objectLinksTempField = 'objectLinksTempSearch',
    modelsToSearch = [
      ObjectLinkTypes.COLLECTION,
      ObjectLinkTypes.CLUB,
      ObjectLinkTypes.CHANNEL,
      ObjectLinkTypes.GAME,
      ObjectLinkTypes.NFT,
    ],
    defaultValue = [],
  } = params;
  const [searchText, setSearchText] = useState<string>('');
  const debouncedSearch = debounce(setSearchText, 300);

  const options: ObjectLinkOption[] = [];
  let isLoading = false;

  if (modelsToSearch.includes(ObjectLinkTypes.CHANNEL)) {
    const { data: channelsData, isLoading: isLoadingChannels } = fetchChannels({
      page: 0,
      size: 5,
      filter: {
        ...(searchText && {
          name: {
            like: `%${searchText}%`,
          },
        }),
      },
      order: 'desc',
      sortBy: 'createdAt',
    });
    options.push(
      ...(channelsData?.map((item) => ({
        label: item.name,
        linksToUuid: item.uuid,
        linksToType: ObjectLinkTypes.CHANNEL,
        imageUrl: item.backgroundImageUrl,
      })) || [])
    );
    isLoading = isLoading || !isLoadingChannels;
  }

  if (modelsToSearch.includes(ObjectLinkTypes.COLLECTION)) {
    const { data: collectionsData, loading: isLoadingCollections } =
      fetchCollections({
        page: 0,
        size: 5,
        order: 'desc',
        orderBy: 'createdAt',
        query: searchText,
        searchFor: 'name',
      });
    options.push(
      ...(collectionsData?.map((item) => ({
        label: item.name,
        linksToUuid: item.uuid,
        linksToType: ObjectLinkTypes.COLLECTION,
        imageUrl: item.coverFileUrl,
      })) || [])
    );
    isLoading = isLoading || !isLoadingCollections;
  }

  if (modelsToSearch.includes(ObjectLinkTypes.CLUB)) {
    const { data: clubsData, isLoading: isLoadingClubs } = fetchClubs({
      page: 0,
      size: 5,
      filter: {
        ...(searchText && {
          name: {
            like: `%${searchText}%`,
          },
        }),
      },
      order: {
        createdAt: 'desc',
      },
    });
    options.push(
      ...(clubsData?.map((item) => ({
        label: item.name,
        linksToUuid: item.uuid,
        linksToType: ObjectLinkTypes.CLUB,
        imageUrl: item.coverFileUrl,
      })) || [])
    );
    isLoading = isLoading || !isLoadingClubs;
  }

  if (modelsToSearch.includes(ObjectLinkTypes.GAME)) {
    const { data: gamesData, isLoading: isLoadingGames } = fetchGames({
      page: 0,
      size: 5,
      filter: {
        ...(searchText && {
          name: {
            like: `%${searchText}%`,
          },
        }),
      },
      order: {
        createdAt: 'desc',
      },
    });
    options.push(
      ...(gamesData?.rows?.map((item) => ({
        label: item.name,
        linksToUuid: item.uuid,
        linksToType: ObjectLinkTypes.GAME,
        imageUrl: item.versions?.[0]?.imageUrl ?? null,
      })) || [])
    );
    isLoading = isLoading || !isLoadingGames;
  }

  const onInputChange = (event: React.ChangeEvent<{}>, newValue: any) => {
    debouncedSearch(newValue);
  };

  const selectedObjectLinks: ObjectLinkOption[] = form.watch(
    objectLinksToCreateField
  );

  return {
    form,
    objectLinksToCreateField,
    objectLinksTempField,
    options,
    isLoading,
    selectedObjectLinks,
    onInputChange,
    defaultValue,
  };
};

interface ObjectLinkComponentProps {
  form: UseFormReturn<any>;
  objectLinksToCreateField?: string;
  objectLinksTempField?: string;
  modelsToSearch?: ObjectLinkTypes[];
  defaultValue?: ObjectLinkOption[];
}

export function ObjectLinkComponent(props: ObjectLinkComponentProps) {
  const {
    form,
    objectLinksTempField,
    objectLinksToCreateField,
    options,
    isLoading,
    selectedObjectLinks,
    onInputChange,
    defaultValue,
  } = useObjectLinkSearch(props);

  return (
    <Controller
      name={objectLinksTempField}
      control={form.control}
      render={({ field }) => (
        <Autocomplete
          multiple
          id="checkboxes-object-links"
          options={options || []}
          disableCloseOnSelect
          getOptionLabel={(option) => option.label}
          renderOption={(_props, option, { selected }) => (
            <li {..._props}>
              <Checkbox
                icon={icon}
                checkedIcon={checkedIcon}
                style={{ marginRight: 8 }}
                checked={
                  selectedObjectLinks &&
                  selectedObjectLinks.some(
                    (v) => v.linksToUuid === option.linksToUuid
                  )
                }
              />
              <Avatar
                src={option.imageUrl ?? ''}
                variant="rounded"
                sx={{ mr: 2 }}
              />
              {`${option.label} (${capitalCase(option.linksToType)})`}
            </li>
          )}
          style={{ width: '100%' }}
          onChange={(e, value) => {
            form.setValue(
              objectLinksToCreateField,
              value.map((v) => ({
                linksToUuid: v.linksToUuid,
                linksToType: v.linksToType,
              }))
            );
          }}
          onInputChange={onInputChange}
          filterOptions={(x) => x}
          loading={isLoading}
          defaultValue={
            defaultValue?.length ? defaultValue : selectedObjectLinks
          }
          renderInput={(_params) => (
            <TextField
              {..._params}
              label="Object Links"
              placeholder="Select Objects To Link"
              {...field}
            />
          )}
        />
      )}
    />
  );
}

export const getDefaultObjectLinksOptions = (
  objectLinks?: ObjectLink[]
): ObjectLinkOption[] => {
  const mappedData = objectLinks?.map((objectLink) => {
    switch (objectLink.linksToType) {
      case ObjectLinkTypes.CHANNEL:
        return {
          linksToUuid: objectLink.linksToChannel?.uuid!,
          linksToType: ObjectLinkTypes.CHANNEL,
          label: objectLink.linksToChannel?.name!,
          imageUrl: objectLink.linksToChannel?.backgroundImageUrl!,
        };
      case ObjectLinkTypes.COLLECTION:
        return {
          linksToUuid: objectLink.linksToCollection?.uuid!,
          linksToType: ObjectLinkTypes.COLLECTION,
          label: objectLink.linksToCollection?.name!,
          imageUrl: objectLink.linksToCollection?.coverFileUrl!,
        };
      case ObjectLinkTypes.CLUB:
        return {
          linksToUuid: objectLink.linksToClub?.uuid!,
          linksToType: ObjectLinkTypes.CLUB,
          label: objectLink.linksToClub?.name!,
          imageUrl: objectLink.linksToClub?.coverFileUrl!,
        };
      case ObjectLinkTypes.GAME:
        return {
          linksToUuid: objectLink.linksToGame?.uuid!,
          linksToType: ObjectLinkTypes.GAME,
          label: objectLink.linksToGame?.name!,
          imageUrl: objectLink.linksToGame?.versions?.[0].imageUrl!,
        };
      case ObjectLinkTypes.NFT:
        return {
          linksToUuid: objectLink.linksToNft?.uuid!,
          linksToType: ObjectLinkTypes.NFT,
          label: objectLink.linksToNft?.name!,
          imageUrl: objectLink.linksToNft?.fileUrl!,
        };
      default:
        return {
          linksToUuid: '',
          linksToType: ObjectLinkTypes.CHANNEL,
          label: '',
          imageUrl: '',
        };
    }
  });

  return mappedData || [];
};
