import { FormControl, InputLabel, Select } from '@mui/material';
import DevicesTable from 'src/components/modules/Devices/components/DevicesTable';
import { Device } from 'src/components/modules/Devices/types/Device';
import SelectOption from 'src/components/shared/SelectOption';
import useURLSearchParams from 'src/lib/hooks/useURLSearchParams';
import {
  IncludeExcludeAll,
  IncludeExcludeOptions,
} from 'src/lib/utils/includeExcludeFilterUtils';

type Props = {
  data: Device[];
  isLoading: boolean;
};
export default function DeviceRankTableView({
  data,
  isLoading,
}: Props): JSX.Element {
  const { addParam, getParam } = useURLSearchParams();
  const AnonymousUsers =
    getParam('AnonymousUsers') ?? IncludeExcludeAll.Exclude;

  return (
    <>
      <FormControl fullWidth>
        <InputLabel id="AnonymousUsersLabel">Anonymous User</InputLabel>
        <Select
          labelId="AnonymousUsersLabel"
          name="AnonymousUsers"
          label="Anonymous User"
          fullWidth
          value={AnonymousUsers}
          onChange={(e) => {
            addParam('AnonymousUsers', e.target.value as string);
          }}
          sx={{ mb: 2 }}
        >
          {IncludeExcludeOptions.map(({ label, value }) => (
            <SelectOption key={value} value={value}>
              {label}
            </SelectOption>
          ))}
        </Select>
      </FormControl>
      <DevicesTable data={data} isLoading={isLoading} />
    </>
  );
}
