import { yupResolver } from '@hookform/resolvers/yup';
import { useForm } from 'react-hook-form';
import useModalStore from 'src/lib/stores/useModalStore';
import { GameMakerAssetCollection } from 'src/lib/types/gameMakerAsset';
import * as yup from 'yup';
import { AssetCollectionFormValues } from './types';

const validationSchema = yup.object().shape({
  name: yup.string().required('Name is required'),
});

function useAssetCollectionFormView({
  defaultValues,
  assetCollection,
}: {
  defaultValues: AssetCollectionFormValues;
  assetCollection?: GameMakerAssetCollection;
}) {
  const onClose = useModalStore((state) => state.closeModal);
  const form = useForm<AssetCollectionFormValues>({
    defaultValues,
    resolver: yupResolver(validationSchema),
  });

  const {
    formState: { isSubmitting },
  } = form;

  const handleCloseModal = () => {
    onClose();
  };

  return {
    form,
    isSubmitting,
    handleCloseModal,
  };
}

export default useAssetCollectionFormView;
