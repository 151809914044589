import ConfigurationsTableView from '../../components/Table';
import useSanctionedCountriesView from './useView';

export default function ListSanctionedCountries(): JSX.Element {
  const { data, loading, page, size, sortBy, sortOrder } =
    useSanctionedCountriesView();
  return (
    <ConfigurationsTableView
      data={data}
      isLoading={loading}
      page={page}
      size={size}
      sortBy={sortBy}
      sortOrder={sortOrder}
    />
  );
}
