import { Container } from '@mui/material';
import UserManagementEvents from 'src/components/modules/UserManagementEvents/view/ManagementEventsList';
import Page from 'src/template/components/Page';

export default function UserManagementEventsPage() {
  return (
    <Page title="Users Management Events">
      <Container maxWidth="xl">
        <UserManagementEvents />
      </Container>
    </Page>
  );
}
