import {
  Card,
  CardContent,
  CardHeader,
  MenuItem,
  Stack,
  TextField,
} from '@mui/material';
import { Controller, useForm } from 'react-hook-form';
import Button from 'src/components/shared/Buttons/CustomButton';
import Flex from 'src/components/shared/Flex';
import ControllerField from 'src/components/shared/Inputs/ControllerField';
import Modal from 'src/components/shared/Modal';
import useModalStore from 'src/lib/stores/useModalStore';
import { AppPlatforms } from 'src/lib/types/collection';
import { ConfigurationFormValues } from './types';

type Props = {
  defaultValues: ConfigurationFormValues;
  onSubmit(values: ConfigurationFormValues): Promise<void>;
  title: string;
};
export default function ConfigurationForm({
  defaultValues,
  onSubmit,
  title,
}: Props): JSX.Element {
  const closeModal = useModalStore((state) => state.closeModal);
  const {
    handleSubmit,
    formState: { isSubmitting },
    control,
  } = useForm<ConfigurationFormValues>({
    defaultValues,
  });

  const handleCancel = () => {
    closeModal();
  };

  return (
    <Modal open onClose={handleCancel} aria-labelledby="create a configuration">
      <Card
        sx={{
          width: 400,
        }}
      >
        <form onSubmit={handleSubmit(onSubmit)}>
          <CardHeader title={title} />
          <CardContent>
            <Stack spacing={1.5}>
              <ControllerField control={control} name="name" label="Name" />
              <ControllerField control={control} name="value" label="Value" />
              <Controller
                control={control}
                name="platform"
                render={({ field }) => (
                  <TextField select label="Platform" {...field}>
                    {Object.values(AppPlatforms).map((platform) => (
                      <MenuItem key={platform} value={platform}>
                        {platform}
                      </MenuItem>
                    ))}
                  </TextField>
                )}
              />
              <ControllerField
                control={control}
                name="description"
                label="Description"
                multiline
              />
            </Stack>
            <Flex width="100%" justifyContent="space-between" mt={4}>
              <Button variant="text" color="error" onClick={handleCancel}>
                Cancel
              </Button>
              <Button
                type="submit"
                loading={isSubmitting}
                variant="contained"
                color="secondary"
              >
                Save
              </Button>
            </Flex>
          </CardContent>
        </form>
      </Card>
    </Modal>
  );
}
