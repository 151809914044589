import { Box, CircularProgress, InputAdornment, Stack } from '@mui/material';
import { useCallback, useEffect } from 'react';
import InfiniteScroll from 'react-infinite-scroll-component';
import DebouncedField from 'src/components/shared/Inputs/DebouncedField';
import useNftsGridFilter from 'src/lib/hooks/useNftsGridFilter';
import { fetchNftsFromCollection } from 'src/lib/services/nft';
import { NFT } from 'src/lib/types/nft';
import Iconify from 'src/template/components/Iconify';
import { addNftsEvent } from '../../Collections/Collection/components/AddNftsModal/events';
import NFTCard from '../NFTCard';
import NFTFilter from '../NFTFilter';

type Props = {
  collectionUuid: string;
};
export default function NftsGrid({ collectionUuid }: Props) {
  const filter = useNftsGridFilter((state) => state.filter);
  const setFilter = useNftsGridFilter((state) => state.setFilter);

  const { data, setSize, hasMore, refetch } = fetchNftsFromCollection(
    collectionUuid,
    filter
  );

  const handleRefetch = useCallback(() => {
    refetch();
  }, []);

  useEffect(() => {
    addNftsEvent.on('nftAdded', handleRefetch);
    return () => {
      addNftsEvent.off('nftAdded', handleRefetch);
    };
  }, []);

  return (
    <Stack spacing={3}>
      <Stack direction="row" spacing={3} justifyContent="space-between">
        <DebouncedField
          size="small"
          onDebounceChange={(value) => setFilter({ query: value })}
          placeholder="Search nft..."
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <Iconify
                  icon="eva:search-fill"
                  sx={{ color: 'text.disabled', width: 20, height: 20 }}
                />
              </InputAdornment>
            ),
          }}
        />
        <NFTFilter />
      </Stack>
      <InfiniteScroll
        dataLength={data?.length || 0}
        next={() => {
          setSize((size) => size + 1);
        }}
        hasMore={hasMore}
        loader={
          <Stack mt={4} alignItems="center">
            <CircularProgress />
          </Stack>
        }
        style={{ overflow: 'unset' }}
      >
        <Box
          sx={{
            display: 'grid',
            gap: 3,
            gridTemplateColumns: {
              xs: 'repeat(1, 1fr)',
              sm: 'repeat(2, 1fr)',
              md: 'repeat(3, 1fr)',
              lg: 'repeat(4, 1fr)',
            },
          }}
        >
          {data.map((nft: NFT) => (
            <NFTCard key={nft.uuid} data={nft} />
          ))}
        </Box>
      </InfiniteScroll>
    </Stack>
  );
}
