import { Icon, IconProps } from '@mui/material';

export default function Editions(props: IconProps): JSX.Element {
  return (
    <Icon
      fontSize="small"
      sx={{
        display: 'flex',
        alignItems: 'center',
      }}
      {...props}
    >
      <svg
        width="100%"
        height="100%"
        viewBox="0 0 30 30"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M9.44664 13.2816C9.44664 11.7272 10.3254 10.3064 11.7162 9.61222L15.9716 7.48818C17.1248 6.91258 18.4815 6.91257 19.6346 7.48818L23.89 9.61222C25.2808 10.3064 26.1596 11.7272 26.1596 13.2816V18.5879C26.1596 20.0829 25.346 21.4594 24.0364 22.1805L19.781 24.5232C18.5495 25.2012 17.0567 25.2012 15.8252 24.5232L11.5698 22.1805C10.2602 21.4594 9.44664 20.0829 9.44664 18.5879V13.2816Z"
          stroke="currentColor"
          strokeWidth="2.39719"
          strokeLinejoin="round"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M12.9886 5.53701C12.2889 5.32901 11.5294 5.39075 10.8653 5.72225L6.60987 7.84629C5.62557 8.3376 5.00363 9.34314 5.00363 10.4432V15.7495C5.00363 16.7028 5.471 17.588 6.24144 18.1272V19.6169C6.24144 20.0839 6.30285 20.5418 6.41985 20.981L5.35017 20.3921C3.65775 19.4603 2.60645 17.6815 2.60645 15.7495V10.4432C2.60645 8.43456 3.74205 6.59852 5.53928 5.70145L9.79468 3.5774C11.2849 2.83357 13.0381 2.83357 14.5283 3.5774L15.7567 4.19056C15.6235 4.24331 15.4919 4.30167 15.3623 4.36565L12.9886 5.53701Z"
          fill="currentColor"
        />
      </svg>
    </Icon>
  );
}
