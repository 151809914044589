import { Add } from '@mui/icons-material';
import { Button, Container } from '@mui/material';
import Flex from 'src/components/shared/Flex';
import Routes from 'src/routes/Routes';
import HeaderBreadcrumbs from 'src/template/components/HeaderBreadcrumbs';
import StatisticGroupsTable from '../../components/statisticGroupsTable';
import useGameStatisticGroups from './useView';

export default function StatisticGroups() {
  const {
    data,
    isLoading,
    params,
    gameUuid,
    handleOpenCreateStatisticGroupModal,
    handleOpenUpdateStatisticGroupModal,
    handleDeleteStatisticGroup,
    handleOpenListGroupStatistics,
  } = useGameStatisticGroups();
  return (
    <Container maxWidth="xl">
      <HeaderBreadcrumbs
        heading="Statistic Groups"
        links={[
          ...(gameUuid
            ? [{ name: 'Games', href: Routes.game_center.games }]
            : []),
          { name: 'Statistic Groups' },
        ]}
        action={
          <Flex pt={2} px={2} justifyContent="flex-end">
            <Button
              variant="contained"
              color="info"
              size="medium"
              onClick={() => {
                handleOpenCreateStatisticGroupModal();
              }}
              startIcon={<Add />}
            >
              Create Statistic Group
            </Button>
          </Flex>
        }
      />
      <StatisticGroupsTable
        data={data ?? []}
        isLoading={isLoading}
        params={params}
        handleDeleteStatisticGroup={handleDeleteStatisticGroup}
        handleOpenListGroupStatistics={handleOpenListGroupStatistics}
        handleOpenUpdateStatisticGroupModal={
          handleOpenUpdateStatisticGroupModal
        }
      />
    </Container>
  );
}
