import { useCallback, useEffect, useState } from 'react';
import { useDrawer } from 'src/components/shared/CustomDrawer';
import useNotify from 'src/lib/hooks/useNotify';
import useModalStore from 'src/lib/stores/useModalStore';
import { SGMPromptType } from 'src/lib/types/sgmPrompt';
import useTabs from 'src/template/hooks/useTabs';
import CreateSGMPromptForm from '../../components/Forms/CreateSGMPrompt';
import { SGMPromptEmitter } from '../../events/SGMPromptEmitter';
import { fetchMakerPrompt } from '../../services/fetchMakerPrompt';
import { fetchMakerPromptHistory } from '../../services/fetchMakerPromptHistory';
import { fetchMakerPrompts } from '../../services/fetchMakerPrompts';
import {
  UpdateMakerPromptForm,
  updateMakerPrompt,
} from '../../services/updateMakerPrompt';
import RestorePromptVersionView from '../RestoreVersion';

const DEFAULT_TAB = SGMPromptType.GAME_CHAT;

export default function useMakerPrompts() {
  const { currentTab, onChangeTab } = useTabs(DEFAULT_TAB, {
    resetOnChange: true,
  });
  const notify = useNotify();
  const openModal = useModalStore((state) => state.openModal);

  const { data: makerPrompts, isLoading: isLoadingMakerPrompts } =
    fetchMakerPrompts();

  const {
    data: makerPromptHistory,
    isLoading: isLoadingMakerPromptHistory,
    mutate: mutatePromptHistory,
  } = fetchMakerPromptHistory(currentTab!);

  const makerPromptTypes = makerPrompts?.map((prompt) => prompt.type) ?? [];

  const { data, isLoading, mutate } = fetchMakerPrompt(
    currentTab as SGMPromptType
  );

  const [isSubmitting, setIsSubmitting] = useState(false);
  const handleUpdatePrompt = async (
    type: SGMPromptType,
    values: UpdateMakerPromptForm
  ) => {
    setIsSubmitting(true);
    try {
      const { data: promptResponse, error } = await updateMakerPrompt(
        type,
        values
      );
      if (error) {
        notify(error, { variant: 'error' });
      } else {
        notify('Prompt Updated!', { variant: 'success' });
        SGMPromptEmitter.emit('SGMPromptUpdated', null);
      }
    } catch (error: any) {
      notify(error?.message || error?.description, { variant: 'error' });
    } finally {
      setIsSubmitting(false);
    }
  };

  // View History
  const [viewHistoryId, setviewHistoryId] = useState<string | null>();
  const changeViewHisory = (versionId: string) => {
    setviewHistoryId(versionId);
  };
  const viewHistory = makerPromptHistory?.find(
    (prompt) => prompt.id === viewHistoryId
  );

  useEffect(() => {
    if (viewHistory) {
      setviewHistoryId(null);
    }
  }, [data?.id]);

  // Restore Version
  const handleRestoreVersion = async (uuid: string) => {
    openModal(<RestorePromptVersionView type={currentTab!} uuid={uuid} />);
  };

  // New Prompot
  const handleNewPrompt = async () => {
    openModal(<CreateSGMPromptForm />);
  };

  // refetch
  const refetchData = useCallback(() => {
    mutate?.();
    mutatePromptHistory?.();
    setviewHistoryId(null); // close view history
  }, []);

  useEffect(() => {
    SGMPromptEmitter.on('SGMPromptUpdated', refetchData);
    SGMPromptEmitter.on('SGMVersionRestored', refetchData);
    return () => {
      SGMPromptEmitter.off('SGMPromptUpdated', refetchData);
      SGMPromptEmitter.off('SGMVersionRestored', refetchData);
    };
  }, [refetchData]);

  // Drawer
  const {
    closeDrawer: closeTypeDrawer,
    isDrawerOpen: isTypeDrawerOpen,
    toggleDrawer: toggleTypeDrawer,
  } = useDrawer(true);

  const {
    closeDrawer: closeHistoryDrawer,
    isDrawerOpen: isHistoryDrawerOpen,
    toggleDrawer: toggleHistoryDrawer,
  } = useDrawer();

  return {
    data,
    isLoading,
    currentTab,
    onChangeTab,
    handleUpdatePrompt,
    isSubmitting,
    makerPrompts,
    isLoadingMakerPrompts,
    makerPromptHistory,
    isLoadingMakerPromptHistory,
    closeTypeDrawer,
    isTypeDrawerOpen,
    toggleTypeDrawer,
    closeHistoryDrawer,
    isHistoryDrawerOpen,
    toggleHistoryDrawer,
    viewHistory,
    changeViewHisory,
    handleRestoreVersion,
    handleNewPrompt,
  };
}
