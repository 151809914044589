import { Box, BoxProps } from '@mui/material';
import React from 'react';

export type CenterProps = BoxProps & {
  children: React.ReactNode;
};
export default function Center({ children, sx, ...rest }: CenterProps) {
  return (
    <Box
      {...rest}
      sx={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        width: '100%',
        columnFill: 'auto',
        ...sx,
      }}
    >
      {children}
    </Box>
  );
}
