import createUseTable from 'src/lib/utils/createUseTable';

export type OrderByOptions =
  | 'uuid'
  | 'amount'
  | 'currency'
  | 'value'
  | 'status'
  | 'scheduledAt'
  | 'executedAt'
  | 'createdAt';

export type SearchForOptions =
  | 'uuid'
  | 'user.name'
  | 'user.slug'
  | 'user.email';

const usePaymentsTable = createUseTable<OrderByOptions, SearchForOptions>({
  searchFor: 'user.slug',
  order: 'desc',
  orderBy: 'createdAt',
  query: '',
  page: 0,
  size: 10,
});

export default usePaymentsTable;
