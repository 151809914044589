import { useState } from 'react';
import { SubmitHandler, useForm } from 'react-hook-form';
import useNotify from 'src/lib/hooks/useNotify';
import useModalStore from 'src/lib/stores/useModalStore';
import { GameCategory } from 'src/lib/types/gameCategory';
import { uploadToStorage } from 'src/lib/utils/upload';

import { GameCategoryEmitter } from '../../../events/Emitter';
import { CreateGameCategory } from '../../../services/create';
import { UpdateGameCategory } from '../../../services/update';

type GameCategoryForm = GameCategory;

export function useCreateGameCategoryView(item?: GameCategory) {
  const notify = useNotify();
  const form = useForm<GameCategoryForm>({
    defaultValues: item,
  });

  const [isSubmitting, setIsSubmitting] = useState<boolean>(false);

  const onSubmitForm: SubmitHandler<GameCategoryForm> = async (data) => {
    setIsSubmitting(true);

    try {
      if (item) {
        const { data: gameCategory, error } = await UpdateGameCategory(data);
        if (error) {
          notify(error, { variant: 'error' });
        } else {
          notify('Game GameCategory updated!', { variant: 'success' });
          GameCategoryEmitter.emit('GameCategoryUpdated', gameCategory?.uuid);
        }
      } else {
        const { data: gameCategory, error } = await CreateGameCategory(data);
        if (error) {
          notify(error, { variant: 'error' });
        } else {
          notify('Game GameCategory created!', { variant: 'success' });
          GameCategoryEmitter.emit('GameCategoryCreated', gameCategory?.uuid);
        }
      }
    } catch (error: any) {
      notify(error?.message || error?.description, { variant: 'error' });
    } finally {
      setIsSubmitting(false);
      handleCloseModal();
    }
  };

  const onClose = useModalStore((state) => state.closeModal);

  const handleCloseModal = () => {
    onClose();
  };

  const [isUpdatingContent, setIsUpdatingContent] = useState<boolean>(false);
  const onUpdateContent = async (file: any, fieldName: keyof GameCategory) => {
    setIsUpdatingContent(true);

    try {
      const url = await uploadToStorage('game-category/content', file);

      form.setValue(fieldName, url as string);
    } finally {
      setIsUpdatingContent(false);
    }
  };

  return {
    onSubmitForm,
    form,
    handleCloseModal,
    isSubmitting,
    isUpdatingContent,
    onUpdateContent,
  };
}
