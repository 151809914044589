import { useMemo } from 'react';
import { fetchPayoutEligibility } from 'src/lib/services/paymentRequests';
import { PayoutEligibilityRules } from 'src/lib/types/payoutEligibilityRules';

export const labelByRule: Record<PayoutEligibilityRules, string> = {
  completedDaysSinceFirstNFTSale:
    '1. At least 90 days since first eligible sale on Sticky',

  minAvailable: '2. Balance of at least 2000 unlocked earned coins',

  soldAtLeastNFTEditions: '3.1. Sales of at least 50 editions of collectibles',
  soldAtLeastDifferentNFTs: '3.2. Sales of at least 10 different collectibles',
  soldToAtLeastDifferentUsers: '3.3. Sales to at least 50 different users',

  userHasProSubscription: '4. Active Pro membership',

  profilePhotoFilled: '5.1. Profile has photo',
  profileNameFilled: '5.2. Profile has name',
  profileHasAtLeastOneLink: '5.3. Profile has at least 1 link',

  nameFilled: '6.1. Payment info has name',
  emailFilled: '6.2. Payment info has email',
  addressFilled: '6.3. Payment info has address',
  phoneNumberFilled: '6.4. Payment info has phone no.',
  pictureFilled: '6.5. Payment info has photo',
  documentNumberFilled: '6.6. Payment info has ID number',
  documentFrontFilled: '6.7. Payment info has front image of ID',
  documentBackFilled: '6.8. Payment info has back image of ID',
  proofOfResidenceFilled: '6.9. Payment info has image of proof of address',
  bankNameFilled: '6.10. Payment info has bank name',
  bankAddressFilled: '6.11. Payment info has bank address',
  swiftCodeFilled: '6.12. Payment info has SWIFT code',
  ibanFilled: '6.13. Payment info has IBAN or account number',
  beneficiaryNameFilled: '6.14. Payment info has beneficiary name',
  beneficiaryAddressFilled: '6.15. Payment info has beneficiary address',

  completedDaysSinceLastRefund: '8. At least 150 days since any refund',
  notLocatedInSanctionedCountry:
    '9.1. Address not in restricted/sanctioned region',
  notBanned: '10.1. User is not suspended',
};

export const orderByRule: Record<PayoutEligibilityRules, number> = {
  completedDaysSinceFirstNFTSale: 1,
  minAvailable: 2,
  soldAtLeastNFTEditions: 3,
  soldAtLeastDifferentNFTs: 4,
  soldToAtLeastDifferentUsers: 5,
  userHasProSubscription: 6,
  profilePhotoFilled: 7,
  profileNameFilled: 8,
  profileHasAtLeastOneLink: 9,
  nameFilled: 10,
  emailFilled: 11,
  addressFilled: 12,
  phoneNumberFilled: 13,
  pictureFilled: 14,
  documentNumberFilled: 15,
  documentFrontFilled: 16,
  documentBackFilled: 17,
  proofOfResidenceFilled: 18,
  bankNameFilled: 19,
  bankAddressFilled: 20,
  swiftCodeFilled: 21,
  ibanFilled: 22,
  beneficiaryNameFilled: 23,
  beneficiaryAddressFilled: 24,
  completedDaysSinceLastRefund: 25,
  notLocatedInSanctionedCountry: 26,
  notBanned: 27,
};

export default function usePayoutEligibility(userId: string) {
  const { data, loading, error } = fetchPayoutEligibility(userId);

  const isFullyEligible = useMemo(
    () => (data ? Object.values(data).every(Boolean) : false),
    [data]
  );
  const eligibilityMessage = isFullyEligible
    ? 'User has all required rules to receive a payout'
    : 'User does not have all required rules to receive a payout';

  const missingRules: string[] = [];
  const eligibleRules: string[] = [];
  if (data) {
    Object.entries(data).forEach(([rule, value]) => {
      const isEligible = typeof value === 'boolean' ? value : value.completed;
      if (isEligible) {
        eligibleRules.push(labelByRule[rule as PayoutEligibilityRules]);
      } else {
        missingRules.push(labelByRule[rule as PayoutEligibilityRules]);
      }
    });
  }

  return {
    isFullyEligible,
    isLoading: loading,
    eligibilityMessage,
    labelByRule,
    rules: data,
    missingRules,
    eligibleRules,
    error,
  };
}
