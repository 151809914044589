import HeaderBreadcrumbs from 'src/template/components/HeaderBreadcrumbs';
import EntityNotesTable from '../../components/EntityNotesTable/EntityNotesTable';
import { useEntityNotesTable } from '../../components/EntityNotesTable/useEntityNotesTable';

export default function EntityNotes() {
  const {
    data,
    isLoading,
    params,
    handleUpdateEntityNote,
    handleDeleteEntityNote,
  } = useEntityNotesTable({});

  return (
    <>
      <HeaderBreadcrumbs heading="Users" links={[{ name: 'Users' }]} />
      <EntityNotesTable
        data={data}
        isLoading={isLoading}
        paras={params}
        handleUpdate={handleUpdateEntityNote}
        handleDelete={handleDeleteEntityNote}
      />
    </>
  );
}
