import { sgmFetcher } from 'src/lib/services/sgm/fetcher';
import { SGMPrompt } from 'src/lib/types/sgmPrompt';
import useSWR, { SWRResponse } from 'swr';

export const fetchMakerPrompts = (): SWRResponse<SGMPrompt[]> => {
  const response = useSWR<SGMPrompt[], Error>(
    `/api/admin/prompt/list`,
    sgmFetcher
  );

  return response;
};
