import { GridAlignment } from '@mui/x-data-grid';
import { capitalCase } from 'change-case';
import {
  OBJECT_MODERATION_REVIEW_STATUS,
  ObjectVisibility,
  WithObjectModerationProps,
} from 'src/lib/types/objectModeration';
import Label from 'src/template/components/Label';
import { colorByObjectVisibility, colorByReviewStatus } from '../utils/utils';

export const reviewStatusColumns = [
  {
    align: 'center' as GridAlignment,
    sortable: false,
    field: 'reviewStatus',
    headerName: 'Review Status',
    renderCell: ({ row }: { row: WithObjectModerationProps<{}> }) =>
      row.reviewStatus && (
        <Label
          variant="ghost"
          color={
            colorByReviewStatus[
              row.reviewStatus as OBJECT_MODERATION_REVIEW_STATUS
            ]
          }
          sx={{ textTransform: 'capitalize' }}
        >
          {capitalCase(row.reviewStatus ?? 'none')}
        </Label>
      ),
  },
  {
    align: 'center' as GridAlignment,
    sortable: false,
    field: 'visibility',
    headerName: 'Visibility',
    renderCell: ({ row }: { row: WithObjectModerationProps<{}> }) =>
      row.visibility && (
        <Label
          variant="filled"
          color={colorByObjectVisibility[row.visibility as ObjectVisibility]}
        >
          {capitalCase(row.visibility ?? 'none')}
        </Label>
      ),
  },
];
