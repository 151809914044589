import { Avatar } from '@mui/material';
import { GridSortDirection } from '@mui/x-data-grid';
import Link from 'src/components/shared/Link';
import MagicTable from 'src/components/shared/MagicTable/MagicTable';
import useURLSearchParams from 'src/lib/hooks/useURLSearchParams';
import { NFTForPackWithTransfersCount } from 'src/lib/types/nft';
import { getImageURL } from 'src/lib/utils/nft';
import Routes from 'src/routes/Routes';

interface NFTSForPacksTableProps {
  data: NFTForPackWithTransfersCount[];
  isLoading: boolean;
  page: number;
  size: number;
  sortBy: string;
  order: GridSortDirection;
}

export default function NFTSForPacksTable({
  data,
  isLoading,
  page,
  size,
  sortBy,
  order,
}: NFTSForPacksTableProps): JSX.Element {
  const { addParam, removeParam } = useURLSearchParams();

  return (
    <MagicTable
      rows={data}
      getRowId={(r) => r.uuid}
      loading={isLoading}
      paginationMode="server"
      pageSize={size}
      page={page}
      onPageChange={(newPage) => {
        addParam('page', newPage);
      }}
      onPageSizeChange={(newSize) => {
        addParam('size', newSize);
      }}
      sortModel={[{ field: sortBy, sort: order }]}
      onSortModelChange={(model) => {
        const currentModel = model[0];
        if (currentModel) {
          addParam('sortBy', currentModel.field);
          if (currentModel.sort) addParam('order', currentModel.sort);
        } else {
          removeParam('order');
          removeParam('sortBy');
        }
      }}
      columns={[
        {
          field: 'name',
          headerName: 'Name',
          sortable: true,
          renderCell: ({ row }) => (
            <>
              <Avatar
                alt={row.name}
                src={getImageURL(row.fileUrl)}
                variant="rounded"
                sx={{ mr: 2 }}
              />
              <Link
                to={Routes.nft(row.uuid)}
                color={row.name ? 'inherit' : 'text.secondary'}
                underline="hover"
                display="inline-block"
              >
                {row.name ?? 'empty'}{' '}
              </Link>
            </>
          ),
        },
        {
          field: 'collectionName',
          headerName: 'Collection',
          sortable: true,
          renderCell: ({ row }) => (
            <Link to={Routes.collection(row.collectionUuid)}>
              {row.collectionName}
            </Link>
          ),
        },
        {
          field: 'transactionsAmount',
          headerName: 'Total Delivered',
          sortable: true,
        },
        {
          field: 'ownedAmount',
          headerName: 'Total Owned',
          sortable: true,
        },
      ]}
    />
  );
}
