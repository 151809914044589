import { debounce } from 'lodash';
import { DEFAULT_INITIAL_PAGE_SIZE } from 'src/lib/constants/pagination';
import useURLSearchParams from 'src/lib/hooks/useURLSearchParams';
import { fetchInconsistentRevenueEvents } from '../../services/fetchInconsistentRevenueEvents';

export default function useRevenueEventsConsistencyView() {
  const { allSearchParams, removeParam, addParam } =
    useURLSearchParams(initialParams);
  const { userUuid, eventType, originalTransactionId } = allSearchParams;
  const { data, isLoading, error } = fetchInconsistentRevenueEvents(
    getPossibleParams(allSearchParams)
  );

  function onChangeFilter(field: string, value: string | null | undefined) {
    if (!value) removeParam(field);
    else addParam(field, value);
  }

  const debouncedOnChangeFilter = debounce(onChangeFilter, 600);
  return {
    data,
    isLoading,
    error,
    onChangeFilter: debouncedOnChangeFilter,
    filter: {
      originalTransactionId,
      userUuid,
      eventType,
    },
  };
}

function getPossibleParams(
  allSearchParams: Record<string, string | undefined>
): Parameters<typeof fetchInconsistentRevenueEvents>[0] {
  const {
    originalTransactionId,
    eventType,
    offerType: type,
    userUuid,
  } = allSearchParams;

  return {
    filter: {
      originalTransactionId,
      eventType,
      userUuid,
      ...(type && {
        isFreeTrial: type === 'freeTrial' ? 1 : 0,
      }),
    },
    size: 100_000,
  };
}

const initialParams = {
  page: '0',
  size: DEFAULT_INITIAL_PAGE_SIZE.toString(),
  order: 'desc',
  orderBy: 'eventDate',
};
