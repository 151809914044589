import { GameAssetEmitter } from 'src/components/modules/GameAssets/events/GameAssetEmitter';
import useNotify from 'src/lib/hooks/useNotify';
import useModalStore from 'src/lib/stores/useModalStore';
import { AddAssetInContestFormValues } from '../../components/Forms/AddAsset/types';
import { AddAssetsInContest } from '../../services/addAssetsInContest';

export default function useAddAssetInContestView() {
  const closeModal = useModalStore((state) => state.closeModal);
  const notify = useNotify();

  const handleAddAssetInContest = async (
    uuid: string,
    values: AddAssetInContestFormValues
  ) => {
    const { error } = await AddAssetsInContest({
      assets: values.assets.map((asset) => ({
        uuid: asset.uuid,
      })),
      uuid,
    });

    if (error) {
      notify(error, { variant: 'error' });
      closeModal();
    } else {
      notify('Assets added to Contest!', { variant: 'success' });
      closeModal();

      GameAssetEmitter.emit('GameAssetAdded', null);
    }
  };

  return {
    handleAddAssetInContest,
  };
}
