import { User } from './users';

export type EntityNote = {
  uuid: string;
  entityUuid: string;
  entityType: EntityNoteTypes;
  note: string;
  tagUuid: string;

  createdBy: string;
  createdByUser: User;
  users: User[];

  createdAt: string;
  updatedAt: string;

  tag?: EntityNoteTag;
};

export type EntityNoteTag = {
  uuid: string;
  name: string;
};

export enum EntityNoteTypes {
  REFUND = 'Refund',
  REVENUE_EVENTS_V2 = 'RevenueEventsV2',
  TRANSACTION_POOL = 'TransactionPool',
  USER = 'User',
}
