import { Add, FileCopyRounded } from '@mui/icons-material';
import {
  Card,
  IconButton,
  InputAdornment,
  MenuItem,
  Stack,
  TextField,
} from '@mui/material';
import { useState } from 'react';
import useDebounce from 'src/lib/hooks/useDebounce';
import { fetchLtvRegions } from 'src/lib/services/regions';
import HeaderBreadcrumbs from 'src/template/components/HeaderBreadcrumbs';
import Iconify from 'src/template/components/Iconify';
import Scrollbar from 'src/template/components/Scrollbar';
import EditOrCreateRegionModal from './CreateOrUpdateRegionModal';
import RegionsTable from './RegionsTable/RegionsTable';
import RegionsTableFooter from './RegionsTable/RegionsTableFooter';
import useRegionsTable, {
  SearchForOptions,
} from './RegionsTable/useRegionsTable';
import UploadRegionCsvModal from './UploadRegionCsvModal';

const SEARCH_OPTIONS: SearchForOptions[] = [
  'code',
  'name',
  'productFullId',
  'iosStickyValue',
  'webStickyValue',
  'androidStickyValue',
];

export default function Regions() {
  const { orderBy, order, page, query, searchFor, size } = useRegionsTable(
    (state) => state.filter
  );

  const [setQuery, setSearchFor] = useRegionsTable((state) => [
    state.setQuery,
    state.setSearchFor,
  ]);

  const {
    data: regions,
    total,
    loading,
  } = fetchLtvRegions({
    query: useDebounce(query, 300),
    page,
    order,
    orderBy,
    searchFor,
    size,
  });

  const [showAddRegion, setShowAddRegion] = useState(false);
  const [showUploadCSV, setShowUploadCSV] = useState(false);

  return (
    <>
      <HeaderBreadcrumbs
        heading="Regions"
        links={[{ name: 'Regions' }]}
        action={
          <Stack direction="row">
            <IconButton onClick={() => setShowUploadCSV(true)}>
              <FileCopyRounded />
            </IconButton>
            <IconButton onClick={() => setShowAddRegion(true)}>
              <Add />
            </IconButton>
          </Stack>
        }
      />
      <Card>
        <Stack
          spacing={2}
          direction={{ xs: 'column', sm: 'row' }}
          sx={{ py: 2.5, px: 3 }}
        >
          <TextField
            fullWidth
            select
            label="Search for"
            value={searchFor}
            onChange={(e) => setSearchFor(e.target.value as SearchForOptions)}
            SelectProps={{
              MenuProps: {
                sx: { '& .MuiPaper-root': { maxHeight: 260 } },
              },
            }}
            sx={{
              maxWidth: { sm: 240 },
              textTransform: 'capitalize',
            }}
          >
            {SEARCH_OPTIONS.map((option) => (
              <MenuItem
                key={option}
                value={option}
                sx={{
                  mx: 1,
                  my: 0.5,
                  borderRadius: 0.75,
                  typography: 'body2',
                  textTransform: 'capitalize',
                }}
              >
                {option}
              </MenuItem>
            ))}
          </TextField>

          <TextField
            fullWidth
            value={query}
            onChange={(e) => setQuery(e.target.value)}
            placeholder="Search region..."
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <Iconify
                    icon="eva:search-fill"
                    sx={{ color: 'text.disabled', width: 20, height: 20 }}
                  />
                </InputAdornment>
              ),
            }}
          />
        </Stack>

        <Scrollbar>
          <RegionsTable data={regions} loading={loading} />
        </Scrollbar>

        <RegionsTableFooter total={total || 0} page={page} rowsPerPage={size} />
      </Card>
      <EditOrCreateRegionModal
        open={showAddRegion}
        onClose={() => setShowAddRegion(false)}
      />
      <UploadRegionCsvModal
        open={showUploadCSV}
        onClose={() => setShowUploadCSV(false)}
      />
    </>
  );
}
