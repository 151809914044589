import { Check, Delete, Edit } from '@mui/icons-material';
import { MenuItem } from '@mui/material';
import { GridSortDirection } from '@mui/x-data-grid';
import { capitalCase } from 'change-case';
import Link from 'src/components/shared/Link';
import MagicTable from 'src/components/shared/MagicTable/MagicTable';
import MoreMenu from 'src/components/shared/MoreMenu';
import useURLSearchParams from 'src/lib/hooks/useURLSearchParams';
import { NftPack, NftPackStatus } from 'src/lib/types/nftPack';
import { formatUTCDate } from 'src/lib/utils/formatUTCDate';
import Routes from 'src/routes/Routes';
import Label from 'src/template/components/Label';

interface NftPacksTableProps {
  data: NftPack[];
  isLoading: boolean;
  page: number;
  size: number;
  sortBy: string;
  order: GridSortDirection;
  handleOpenUpdateModal: (nftPack: NftPack) => void;
  handleOpenDeleteModal: (uuid: string) => void;
  handleOpenChangeStatusModal: (uuid: string, status: NftPackStatus) => void;
}

export default function NftPacksTable({
  data,
  isLoading,
  page,
  size,
  sortBy,
  order,
  handleOpenUpdateModal,
  handleOpenDeleteModal,
  handleOpenChangeStatusModal,
}: NftPacksTableProps) {
  const { addParam, removeParam } = useURLSearchParams();

  return (
    <MagicTable
      rows={data}
      getRowId={(r) => r.uuid}
      loading={isLoading}
      paginationMode="server"
      pageSize={size}
      page={page}
      onPageChange={(newPage) => {
        addParam('page', newPage);
      }}
      onPageSizeChange={(newSize) => {
        addParam('size', newSize);
      }}
      sortModel={[{ field: sortBy, sort: order }]}
      onSortModelChange={(model) => {
        const currentModel = model[0];
        if (currentModel) {
          addParam('sortBy', currentModel.field);
          if (currentModel.sort) addParam('order', currentModel.sort);
        } else {
          removeParam('order');
          removeParam('sortBy');
        }
      }}
      columns={[
        {
          field: 'name',
          headerName: 'Name',
          sortable: false,
          renderCell({ value, row }) {
            return <Link to={Routes.products.nft_pack(row.uuid)}>{value}</Link>;
          },
        },
        {
          field: 'type',
          headerName: 'Type',
          sortable: false,
        },
        {
          field: 'status',
          headerName: 'Status',
          sortable: false,
          renderCell({ value }) {
            return (
              <Label
                color={value == NftPackStatus.DRAFT ? 'warning' : 'success'}
              >
                {capitalCase(value)}
              </Label>
            );
          },
        },
        {
          field: 'amountOfNftsToRecharge',
          headerName: 'Amount of NFTs to Recharge',
          sortable: false,
        },
        {
          field: 'updatedAt',
          headerName: 'Updated At',
          sortable: true,
          valueFormatter({ value }) {
            return formatUTCDate(value);
          },
        },
        {
          field: 'actions',
          headerName: 'Actions',
          align: 'center',
          sortable: false,
          flex: 0,
          renderCell({ row }) {
            return (
              <MoreMenu>
                <MenuItem
                  onClick={() => {
                    handleOpenUpdateModal(row);
                  }}
                >
                  <Edit />
                  Update
                </MenuItem>
                <MenuItem
                  onClick={() =>
                    handleOpenChangeStatusModal(
                      row.uuid,
                      row.status == NftPackStatus.DRAFT
                        ? NftPackStatus.READY
                        : NftPackStatus.DRAFT
                    )
                  }
                >
                  {row.status === NftPackStatus.DRAFT ? <Check /> : <Edit />}
                  Mark as{' '}
                  {capitalCase(
                    row.status == NftPackStatus.DRAFT
                      ? NftPackStatus.READY
                      : NftPackStatus.DRAFT
                  )}
                </MenuItem>
                <MenuItem
                  onClick={() => {
                    handleOpenDeleteModal(row.uuid);
                  }}
                >
                  <Delete />
                  Delete
                </MenuItem>
              </MoreMenu>
            );
          },
        },
      ]}
    />
  );
}
