import { Container } from '@mui/material';
import CommunicationsList from 'src/components/modules/Communications/view/List';
import Page from 'src/template/components/Page';

export default function CommunicationsPage(): JSX.Element {
  return (
    <Page title="Communication">
      <Container maxWidth="xl">
        <CommunicationsList />
      </Container>
    </Page>
  );
}
