import {
  CircularProgress,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
} from '@mui/material';
import Center from 'src/components/shared/Center';
import { Collection } from 'src/lib/types/collection';
import { TableHeadCustom } from 'src/template/components/table';
import { IHeadLabel } from 'src/template/components/table/TableHeadCustom';

import CollectionTableRow from './CollectionTableRow';
import useCollectionsTable from './useCollectionsTable';

const TABLE_HEAD: IHeadLabel[] = [
  { id: 'name', label: 'Collection' },
  { id: 'active', label: 'Status' },
  { id: 'nftsCount', label: 'NFTs' },
  { id: 'user.slug', label: 'Creator' },
  {
    id: 'reviewStatus',
    label: 'Review Status',
    align: 'center',
    sortable: false,
  },
  { id: 'visibility', label: 'Visibility', align: 'center', sortable: false },
  { id: 'blockchain', label: 'Blockchain', align: 'center' },
  { id: 'platform', label: 'Platform', align: 'center' },
  { id: 'updatedAt', label: 'Updated', align: 'right' },
  { id: 'createdAt', label: 'Created', align: 'right' },
  { id: 'actions', label: 'Actions', align: 'center' },
];

export default function CollectionsTable({
  data,
  loading,
}: {
  data: Collection[] | undefined;
  loading: boolean;
}) {
  const [order, orderBy] = useCollectionsTable((state) => [
    state.filter.order,
    state.filter.orderBy,
  ]);

  const onSort = useCollectionsTable((state) => state.onSort);

  const dense = useCollectionsTable((state) => state.dense);

  return (
    <TableContainer sx={{ minWidth: '100%', position: 'relative' }}>
      <Table size={dense ? 'small' : 'medium'}>
        <TableHeadCustom
          order={order}
          orderBy={orderBy}
          headLabel={TABLE_HEAD}
          rowCount={loading ? 0 : data && data.length}
          onSort={onSort}
        />
        <TableBody>
          {loading || !data ? (
            <TableRow>
              <TableCell colSpan={TABLE_HEAD.length + 1}>
                <Center>
                  <CircularProgress />
                </Center>
              </TableCell>
            </TableRow>
          ) : (
            data &&
            data.map((collection: Collection) => (
              <CollectionTableRow
                key={collection.uuid}
                collection={collection}
              />
            ))
          )}
        </TableBody>
      </Table>
    </TableContainer>
  );
}
