import { Button, Stack, TextField, Typography } from '@mui/material';
import { useEffect, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { SGMPrompt, SGMPromptType } from 'src/lib/types/sgmPrompt';
import { UpdateMakerPromptForm } from '../../services/updateMakerPrompt';

interface MakerPromptViewerProps {
  prompt: SGMPrompt;
  onEdit?: (type: SGMPromptType, data: UpdateMakerPromptForm) => void;
}

export function MakerPromptViewer({ prompt, onEdit }: MakerPromptViewerProps) {
  useEffect(() => {
    reset({
      prompt: prompt.prompt,
      title: prompt.title ?? prompt.type,
      description: prompt.description,
    });
  }, [prompt]);

  const { control, handleSubmit, reset } = useForm({
    defaultValues: {
      prompt: prompt.prompt,
      title: prompt.title ?? prompt.type,
      description: prompt.description,
    },
  });

  const [isEditing, setIsEditing] = useState(false);

  const handleEditClick = () => {
    setIsEditing(true);
  };

  const handleSaveClick = (data: UpdateMakerPromptForm) => {
    if (!onEdit) {
      return;
    }
    onEdit(prompt.type, data);
    setIsEditing(false);
  };

  const handleCancelClick = () => {
    reset();
    setIsEditing(false);
  };

  return (
    <>
      {isEditing ? (
        <form onSubmit={handleSubmit(handleSaveClick)}>
          <Controller
            name="title"
            control={control}
            render={({ field }) => (
              <TextField
                {...field}
                label="Title"
                fullWidth
                sx={{ marginBottom: 2 }}
              />
            )}
          />
          <Controller
            name="description"
            control={control}
            render={({ field }) => (
              <TextField
                {...field}
                label="Description"
                fullWidth
                multiline
                minRows={2}
                sx={{ marginBottom: 2 }}
              />
            )}
          />
          <Controller
            name="prompt"
            control={control}
            render={({ field }) => (
              <TextField
                {...field}
                label="Prompt"
                fullWidth
                multiline
                minRows={4}
                sx={{ marginBottom: 2 }}
              />
            )}
          />

          <Stack direction="row" spacing={2} marginTop={2}>
            <Button
              variant="outlined"
              color="error"
              onClick={handleCancelClick}
            >
              Cancel
            </Button>
            <Button type="submit" variant="contained" color="success">
              Save
            </Button>
          </Stack>
        </form>
      ) : (
        <>
          <Typography
            variant="h5"
            sx={{
              whiteSpace: 'pre-line',
              padding: '10px',
              borderRadius: '10px',
            }}
          >
            {prompt.title ?? prompt.type}
          </Typography>
          <Typography
            variant="subtitle1"
            sx={{
              whiteSpace: 'pre-line',
              padding: '10px',
              borderRadius: '10px',
            }}
          >
            {prompt.description ?? '{empty description}'}
          </Typography>
          <Typography
            variant="body1"
            sx={{
              whiteSpace: 'pre-line',
              padding: '10px',
              borderRadius: '10px',
              border: '1px solid #ddd',
            }}
          >
            {prompt.prompt}
          </Typography>
        </>
      )}

      {!isEditing && (
        <Stack direction="row" spacing={2} marginTop={2}>
          <Button variant="contained" color="info" onClick={handleEditClick}>
            Update Prompt
          </Button>
        </Stack>
      )}
    </>
  );
}
