const formatStickyCoinValue = (
  value: string | number,
  op: 'div' | 'mul' = 'div'
): number => {
  const numberValue = Number(value);
  if (Number.isNaN(numberValue)) return 0;
  if (op === 'div') return numberValue / 1000;
  return numberValue * 1000;
};

export default formatStickyCoinValue;
