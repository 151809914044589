import useModalStore from 'src/lib/stores/useModalStore';
import ChangeChannelIsStickyView from '../../view/ChangeStatus';

export default function useChannelTable() {
  const openModal = useModalStore((state) => state.openModal);

  const handleChangeChannelIsStickyModal = (
    uuid: string,
    newIsSticky: boolean
  ) => {
    openModal(<ChangeChannelIsStickyView uuid={uuid} isSticky={newIsSticky} />);
  };

  return {
    handleChangeChannelIsStickyModal,
  };
}
