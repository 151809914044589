import { Edit } from '@mui/icons-material';
import {
  Button,
  Card,
  CardActions,
  CardContent,
  CardHeader,
  IconButton,
  Stack,
  TextField,
  Tooltip,
  Typography,
} from '@mui/material';
import { useSnackbar } from 'notistack';
import { useState } from 'react';
import Modal from 'src/components/shared/Modal';
import { createOrUpdateCollection } from 'src/lib/services/collections';
import { Collection } from 'src/lib/types/collection';

export default function CollectionDescription({
  collection,
}: {
  collection: Collection;
}) {
  const [showEditDescriptionModal, setShowEditDescriptionModal] =
    useState(false);
  const [newDescription, setNewDescription] = useState(collection.description);

  const { enqueueSnackbar: notify } = useSnackbar();

  const onSave = async () => {
    const res = await createOrUpdateCollection({
      ...collection,
      description: newDescription,
    });

    if (res.data.error) {
      notify(res.data.error.message, { variant: 'error' });
    } else {
      notify('Description updated successfully', { variant: 'success' });
      setShowEditDescriptionModal(false);
    }
  };

  return (
    <>
      <Card
        sx={{
          position: 'relative',
        }}
      >
        <Tooltip title="Edit Description" placement="top">
          <IconButton
            className="edit-description-button"
            sx={{
              position: 'absolute',
              top: 20,
              right: 20,
            }}
            onClick={() => setShowEditDescriptionModal(true)}
          >
            <Edit />
          </IconButton>
        </Tooltip>
        <CardHeader title="Description" />
        <CardContent>
          <Typography
            color={collection.description ? 'text.primary' : 'text.secondary'}
          >
            {collection.description && collection.description !== ''
              ? collection.description
              : 'Empty'}
          </Typography>
        </CardContent>
      </Card>
      <Modal
        onClose={() => setShowEditDescriptionModal(false)}
        open={showEditDescriptionModal}
      >
        <Card
          sx={{
            width: '500px',
          }}
        >
          <CardHeader title="Edit Description" />
          <CardContent>
            <TextField
              multiline
              fullWidth
              placeholder="Description"
              value={newDescription}
              onChange={(e) => setNewDescription(e.target.value)}
            />
          </CardContent>
          <CardActions
            sx={{
              padding: 2,
            }}
          >
            <Stack
              direction="row"
              spacing={2}
              justifyContent="space-between"
              width="100%"
            >
              <Button
                color="error"
                onClick={() => setShowEditDescriptionModal(false)}
              >
                Cancel
              </Button>
              <Button variant="contained" color="secondary" onClick={onSave}>
                Save
              </Button>
            </Stack>
          </CardActions>
        </Card>
      </Modal>
    </>
  );
}
