import { useParams } from 'react-router';
import useModalStore from 'src/lib/stores/useModalStore';
import { fetchSubscription } from '../../../services/fetchSubscription';
import { parseDataToSubscriptionDetails } from '../../../utils/parseDataToSubscriptionDetails';

export default function useSubscriptionDetailView() {
  const { purchaseId } = useParams<'purchaseId'>();
  const { userUuid } = useParams<'userUuid'>();
  const openModal = useModalStore((state) => state.openModal);
  const { data, error, isLoading } = fetchSubscription(purchaseId!, userUuid!);

  const purchase = data ? parseDataToSubscriptionDetails(data) : undefined;

  async function showRelatedPools(revenueEventId: string) {
    const RelatedPools = (await import('../../../components/RelatedPools'))
      .default;
    openModal(<RelatedPools revenueEventId={revenueEventId} />);
  }

  return {
    purchase,
    isLoading,
    error,
    showRelatedPools,
  };
}
