import { Container } from '@mui/material';
import DevicesView from 'src/components/modules/Devices/view/Devices';
import HeaderBreadcrumbs from 'src/template/components/HeaderBreadcrumbs';
import Page from 'src/template/components/Page';

export default function DevicesPage(): JSX.Element {
  return (
    <Page title="Devices">
      <Container maxWidth="xl">
        <HeaderBreadcrumbs
          heading="Devices"
          links={[{ name: 'Devices List' }]}
        />
        <DevicesView />
      </Container>
    </Page>
  );
}
