import { useCallback } from 'react';
import { DeepPartial } from 'react-hook-form';
import { fetcher } from 'src/lib/services/api/fetcher';
import { ResponseWithStatus } from 'src/lib/types/ResponseWithStatus';
import { ChatMessage } from 'src/lib/types/chat';
import { parseSearchParams } from 'src/lib/utils/parseSearchParams';
import useSWRInfinite from 'swr/infinite';

const PAGINATION_SIZE = 50;

export type PossibleParams = {
  start: number;
  perPage: number;
};

export const fetchChatMessagesInfinite = (
  roomId: string,
  filter?: DeepPartial<PossibleParams>
) => {
  const getKey = (
    pageIndex: number,
    previousPageData: ResponseWithStatus<{ messages: ChatMessage[] }>
  ) => {
    if (previousPageData && previousPageData.response.messages.length < 50)
      return null; // Para quando a página retorna menos de 50 mensagens

    const start = pageIndex * PAGINATION_SIZE;
    const parsedParams = parseSearchParams({ ...filter, start, perPage: 50 });
    return `/admin/chats/${roomId}/messages?${parsedParams}`;
  };

  const {
    setSize,
    data,
    error,
    size,
    mutate: reload,
    isValidating,
  } = useSWRInfinite<ResponseWithStatus<{ messages: ChatMessage[] }>, Error>(
    getKey,
    fetcher
  );

  const hasReachedEnd = Boolean(
    data && data[data.length - 1].response.messages.length < PAGINATION_SIZE
  );

  const handleFetchMore = useCallback(() => {
    if (isValidating) return;
    if (!hasReachedEnd) setSize(size + 1);
  }, [isValidating, hasReachedEnd, size, setSize]);

  const dataCopy =
    data
      ?.slice()
      .reverse()
      .flatMap((d) => d.response.messages)
      .reverse() ?? [];

  return {
    messages: dataCopy,
    status: { error, isValidating, hasReachedEnd },
    handleFetchMore,
  };
};
