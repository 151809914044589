import { Edit } from '@mui/icons-material';
import { ListItemIcon, MenuItem } from '@mui/material';
import useDisclosure from 'src/lib/hooks/useDisclosure';
import { NFT } from 'src/lib/types/nft';
import NFTEditModal from '../NFTEditModal';

interface EditNFTProps {
  nft: NFT;
  handleCloseMenu: () => void;
}
export default function EditNFT({
  nft,
  handleCloseMenu,
}: EditNFTProps): JSX.Element {
  const {
    isOpen: editIsOpen,
    onClose: onCloseEdit,
    onOpen: onOpenEdit,
  } = useDisclosure();
  const handleEditDetails = () => {
    onOpenEdit();
  };
  return (
    <>
      <MenuItem color="red" onClick={handleEditDetails}>
        <ListItemIcon>
          <Edit />
        </ListItemIcon>
        Edit Details
      </MenuItem>
      <NFTEditModal
        closeActionsMenu={handleCloseMenu}
        nft={nft}
        open={editIsOpen}
        onClose={onCloseEdit}
      />
    </>
  );
}
